import { ContractIssue } from '@thought-river/negotiations-common';
import { SubmitStatus } from '../../App/App-types';
import { IAccountUser } from '../../Auth/Auth-types';
import { sortByLastUpdatedDate } from '../../IssueListPane/IssuesListPane-helpers';
import { systemUser } from '../../IssuePane/IssuePane-types';

export const getSubmitStatus = (
    issuesReportStatus: SubmitStatus,
    issuePaneStatus: SubmitStatus,
    contractNameStatus: SubmitStatus
) => {
    if (
        issuesReportStatus === SubmitStatus.PROCESSING ||
        issuePaneStatus === SubmitStatus.PROCESSING ||
        contractNameStatus === SubmitStatus.PROCESSING
    ) {
        return SubmitStatus.PROCESSING;
    }
    if (
        issuesReportStatus === SubmitStatus.FAILURE ||
        issuePaneStatus === SubmitStatus.FAILURE ||
        contractNameStatus === SubmitStatus.FAILURE
    ) {
        return SubmitStatus.FAILURE;
    }
    return SubmitStatus.SUCCESS;
};

export const getLastUpdatedIssue = (issues: ContractIssue[]) =>
    [...issues].sort(sortByLastUpdatedDate)[0];

export const getLastModifierName = (
    lastUpdatedIssue: ContractIssue | undefined,
    users: IAccountUser[]
) => {
    if (!lastUpdatedIssue) {
        return null;
    }

    const lastModifier =
        users.find((user) => user.id === lastUpdatedIssue.lastModifiedById) ??
        systemUser;
    return `${lastModifier.firstName} ${lastModifier.lastName}`;
};
