import { Component } from 'react';

import {
    DEFAULT_FILTER_PRESET,
    PresetIssueFilters,
} from '../IssueListFilters/IssueListFilters-types';
import {
    IIssueViewsDropdownProps,
    IIssueViewsDropdownState,
    IssueViewsContext,
} from './IssueViewsDropdown-types';
import { analytics } from './IssueViewsDropdown-analytics';
import { Select, SelectOption } from '@thought-river/ui-components';
import './IssueViewsDropdown.scss';
import { filtersPresets } from '../IssueListFilters/IssueListFilters-reducer';

@analytics()
class IssueViewsDropdown extends Component<
    IIssueViewsDropdownProps,
    IIssueViewsDropdownState
> {
    constructor(props: IIssueViewsDropdownProps) {
        super(props);
        this.onViewChange = this.onViewChange.bind(this);
    }

    componentDidMount() {
        const { onSelectViewCallback, selectedView } = this.props;

        const validOptions = this.getFilterPresets();

        if (
            PresetIssueFilters[selectedView] ===
                PresetIssueFilters.ContractSummary &&
            !validOptions.find(
                (optionKey) =>
                    PresetIssueFilters[optionKey] ===
                    PresetIssueFilters.ContractSummary
            )
        ) {
            onSelectViewCallback(DEFAULT_FILTER_PRESET);
        }

        if (
            PresetIssueFilters[selectedView] ===
                PresetIssueFilters.CommercialReview &&
            !validOptions.find(
                (optionKey) =>
                    PresetIssueFilters[optionKey] ===
                    PresetIssueFilters.CommercialReview
            )
        ) {
            onSelectViewCallback(DEFAULT_FILTER_PRESET);
        }
    }

    getFilterPresets(): string[] {
        const { context, featureToggles } = this.props;

        return Object.keys(PresetIssueFilters).filter((presetName) => {
            const presetConfig = filtersPresets[PresetIssueFilters[presetName]];
            if (!presetConfig) {
                return false;
            }

            if (
                [
                    PresetIssueFilters.ContractSummary,
                    PresetIssueFilters.AllLexibleQuestions,
                    PresetIssueFilters.CounterpartyResponse,

                    PresetIssueFilters.BidReport,
                    PresetIssueFilters.NegotiationReport,
                    PresetIssueFilters.FinalReportForSignatories,
                    PresetIssueFilters.FinalReportForProjectTeams,
                    PresetIssueFilters.AdeccoNDAReport,
                ].includes(PresetIssueFilters[presetName])
            ) {
                return context === IssueViewsContext.REPORT;
            }

            if (presetConfig.featureFlag) {
                const featureToggle = featureToggles.find(
                    (toggle) => toggle.feature === presetConfig.featureFlag
                );
                if (!featureToggle?.enabled) {
                    return false;
                }
            }

            return true;
        });
    }

    onViewChange(view: SelectOption) {
        const { onSelectViewCallback } = this.props;

        const selectedPreset = view.value as keyof typeof PresetIssueFilters;
        onSelectViewCallback(selectedPreset);
    }

    render() {
        const { selectedView } = this.props;

        const filterPresets = this.getFilterPresets();

        const options = filterPresets.map((option) => ({
            value: option,
            label: PresetIssueFilters[option],
        }));

        const selectedValue =
            filterPresets.includes(selectedView) || selectedView === ''
                ? selectedView
                : DEFAULT_FILTER_PRESET;

        const selectedOption = options.find(
            (option) => option.value === selectedValue
        );

        return (
            <Select
                className="issue-views-select"
                label="Views"
                value={selectedOption || undefined}
                options={options}
                onChange={this.onViewChange}
                multiple={false}
                data-testid="issue-views-dropdown"
            />
        );
    }
}

export default IssueViewsDropdown;
