import {
    IContractListToolbarActionTypes,
    IContractListToolbarReducer,
    SET_BULK_ACTIONS,
    SET_SEARCH_TERM,
    SWITCH_TOGGLE,
} from './ContractListToolbar-types';

const initialState: IContractListToolbarReducer = {
    bulkActions: false,
    searchTerm: '',
    toggles: [
        { id: 'mine', label: 'Mine', enabled: true },
        { id: 'other', label: "Others'", enabled: false },
        { id: 'unassigned', label: 'Unassigned', enabled: true },
        { id: 'negotiating', label: 'Negotiating', enabled: true },
        { id: 'signed', label: 'Signed', enabled: false },
    ],
};

const ContractListToolbarReducer = (
    state = initialState,
    action: IContractListToolbarActionTypes
): IContractListToolbarReducer => {
    switch (action.type) {
        case SET_BULK_ACTIONS:
            return {
                ...state,
                bulkActions: action.bulkActions,
            };

        case SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.searchTerm,
            };

        case SWITCH_TOGGLE:
            return {
                ...state,

                toggles: state.toggles.map((toggle) => ({
                    ...toggle,
                    enabled:
                        toggle.id === action.toggle.id
                            ? !action.toggle.enabled
                            : toggle.enabled,
                })),
            };

        default:
            return state;
    }
};

export default ContractListToolbarReducer;
