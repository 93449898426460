import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';

import { renderSeverityDropdownLabel } from '../../../IssueListPane/IssuesListPane-helpers';
import DataGridDropdown from '../DataGridDropdown/DataGridDropdown';
import { IssueSeverityLevel } from '@thought-river/negotiations-common';

const severities = [
    IssueSeverityLevel.SHOWSTOPPER,
    IssueSeverityLevel.HIGH,
    IssueSeverityLevel.MEDIUM,
    IssueSeverityLevel.LOW,
    IssueSeverityLevel.OK,
    IssueSeverityLevel.UNSCORED,
];

const EditImportanceDropdown = (
    props: GridRenderEditCellParams
): JSX.Element => (
    <DataGridDropdown
        cellParams={props}
        options={severities}
        attribute="severityLevel"
        renderLabel={renderSeverityDropdownLabel}
    />
);

export default EditImportanceDropdown;
