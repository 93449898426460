import {
    IAuthReducer,
    IAuthActionTypes,
    SET_API_URL,
    SET_API_TOKEN,
    SET_STREAMS,
    SET_TOKEN_EXPIRY,
    SET_AUTH_DETAILS,
    SET_AUTH_METADATA,
    SET_SELECTED_STREAM,
    CLEAR_LOGIN_DETAILS,
    SET_USERS,
    SET_LOGOUT,
    SET_BOOKMARK,
    SET_LOGGING_OUT,
    SET_LOGIN_SUCCESS,
    SET_LEGACY_API_TOKEN,
} from './Auth-types';

export const authInitialState: IAuthReducer = {
    accountCode: '',
    apiToken: null!, // Fixme: null checks
    apiUrl: null!, // Fixme: null checks
    audience: '',
    authUrl: '',
    bookmark: '',
    clientId: '',
    company: '',
    loggingOut: false,
    loginSuccess: false,
    logout: false,
    redirectUrl: '',
    roles: [],
    selectedStream: null!, // Fixme: null checks
    streams: [],
    tokenExpiry: null!, // Fixme: null checks
    userId: null!, // Fixme: null checks
    users: [],
    tenants: [],
    legacyApiToken: null,
};

const AuthReducer = (
    state = authInitialState,
    action: IAuthActionTypes
): IAuthReducer => {
    switch (action.type) {
        case CLEAR_LOGIN_DETAILS:
            return {
                ...state,
                apiToken: null!, // Fixme: null checks
                roles: [],
                streams: [],
                tokenExpiry: null!, // Fixme: null checks
                selectedStream: null!, // Fixme: null checks
                users: [],
                accountCode: '',
                company: '',
                legacyApiToken: null,
            };
        case SET_API_URL:
            return {
                ...state,
                apiUrl: action.apiUrl,
            };
        case SET_API_TOKEN:
            return {
                ...state,
                apiToken: action.apiToken,
            };
        case SET_STREAMS:
            return {
                ...state,
                streams: action.streams,
            };
        case SET_TOKEN_EXPIRY:
            return {
                ...state,
                tokenExpiry: action.tokenExpiry,
            };
        case SET_SELECTED_STREAM:
            return {
                ...state,
                selectedStream: action.selectedStream,
            };
        case SET_AUTH_DETAILS:
            return {
                ...state,
                authUrl: action.authUrl,
                audience: action.audience,
                clientId: action.clientId,
                redirectUrl: action.redirectUrl,
            };
        case SET_AUTH_METADATA:
            return {
                ...state,
                accountCode: action.accountCode,
                company: action.company,
                roles: action.roles,
                streams: action.streams,
                userId: action.userId,
                tenants: action.tenants ?? [],
            };
        case SET_USERS:
            return {
                ...state,
                users: action.users,
            };
        case SET_LOGGING_OUT:
            return {
                ...state,
                loggingOut: action.loggingOut,
            };
        case SET_LOGIN_SUCCESS:
            return {
                ...state,
                loginSuccess: action.loginSuccess,
            };
        case SET_LOGOUT:
            return {
                ...state,
                logout: action.logout,
            };
        case SET_BOOKMARK:
            return {
                ...state,
                bookmark: action.bookmark,
            };
        case SET_LEGACY_API_TOKEN:
            return {
                ...state,
                legacyApiToken: action.apiToken,
            };
        default:
            return state;
    }
};

export default AuthReducer;
