import { NavigationBanner, Button } from '@thought-river/ui-components';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    setHighlightedParagraphs,
    setSelectedContractIntelligenceAction,
    setSelectedParagraphAction,
    setSelectedPropertyCodeAction,
} from '../../Contract/Contract-actions';
import { setBannerSettingsAction } from '../HeatmapPane-actions';
import {
    BannerClauseType,
    BannerType,
    IBannerSettings,
} from '../HeatmapPane-types';
import { getBannerTitles } from './HeatmapPaneBanner.helpers';
import './HeatmapPaneBanner.scss';
import { trackBannerNavigation } from './HeatmapPaneBanner-analytics';
import { useAppSelector } from '@modules/common/hooks';
import { useRelatedClausesContext } from '@thought-river/negotiations-common';

interface HeatmapPaneBannerProps {
    onReprocess?: () => void;
    selectParagraph: (paragraphId: string) => void;
    actionButtons?: JSX.Element;
    onNavigation?: (paragraphId: string) => void;
    isPermanent?: boolean;
}

const HeatmapPaneBanner = ({
    onReprocess,
    selectParagraph,
    actionButtons: actionButtonsProp,
    onNavigation,
    isPermanent,
}: HeatmapPaneBannerProps) => {
    const bannerSettings = useAppSelector(
        (state) => state.heatmapPane.bannerSettings
    );
    const selectedContractIntelligence = useAppSelector(
        (state) => state.contract.selectedContractIntelligence
    );
    const selectedPropertyCode = useAppSelector(
        (state) => state.contract.selectedPropertyCode
    );
    const summaryProperties = useAppSelector(
        (state) => state.contract.summaryProperties
    );
    const lexibleProperties = useAppSelector((state) => state.app.properties);
    const selectedParagraph = useAppSelector(
        (state) => state.contract.selectedParagraph
    );

    const { shouldShowRelatedClauses } = useRelatedClausesContext();

    const dispatch = useDispatch();

    useEffect(() => {
        const paragraphToSelect =
            bannerSettings?.currentItem ?? bannerSettings?.items?.[0];
        if (!paragraphToSelect) {
            return;
        }

        selectParagraph(paragraphToSelect);
        // Note: including `selectParagraph` in the deps array would cause unintentional update
        // when the paragraphs array is changed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannerSettings?.currentItem, bannerSettings?.items]);

    const handleClose = () => {
        dispatch(setBannerSettingsAction(null!)); // Fixme: null checks
        dispatch(setSelectedParagraphAction(null));
        dispatch(setHighlightedParagraphs([]));

        dispatch(
            setSelectedContractIntelligenceAction({
                ...selectedContractIntelligence,
                highlightType: null!, // Fixme: null checks
            })
        );
        dispatch(setSelectedPropertyCodeAction(null!)); // Fixme: null checks
    };

    const handleNavigation = (paragraphId: string) => {
        selectParagraph(paragraphId);
        trackBannerNavigation();
        onNavigation?.(paragraphId);
    };

    const property = lexibleProperties.find(
        (p) => p.code === selectedPropertyCode
    );

    //A bit of a hack but it's good enough for now as I'm not sure if the related clauses toggle is here to stay
    const updatedBannerSettings: IBannerSettings | null = bannerSettings && {
        ...bannerSettings,
        items:
            !shouldShowRelatedClauses &&
            bannerSettings.clauseType === BannerClauseType.PROPERTY_RELATED
                ? []
                : bannerSettings?.items,
        clauseType: !shouldShowRelatedClauses
            ? BannerClauseType.PROPERTY_LINKED
            : bannerSettings.clauseType,
    };

    const bannerTitles = getBannerTitles(
        updatedBannerSettings!, // Fixme: null checks
        selectedContractIntelligence,
        summaryProperties,
        updatedBannerSettings!.items!, // Fixme: null checks
        property?.shortLabel || property?.name
    );
    // Fixme: null checks
    const bannerType =
        updatedBannerSettings!.type === BannerType.WARNING ? 'warning' : 'info';
    const navigationText =
        updatedBannerSettings!.clauseType === BannerClauseType.PROPERTY_LINKED
            ? 'Find linked clauses'
            : 'Find related clauses';
    const showNavigation =
        updatedBannerSettings!.type === BannerType.INFO ||
        updatedBannerSettings!.type === BannerType.FAMILIARITY_LEVEL ||
        updatedBannerSettings!.type === BannerType.RISK_LEVEL;
    const showAskQuestionsButton =
        updatedBannerSettings!.type === BannerType.REPROCESS_REQUIRED;

    const askQuestionsButton = (
        <Button variant="tertiary" size="small" onClick={onReprocess}>
            Ask new questions
        </Button>
    );

    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl', 'max'];
    const actionButtons = breakpoints.reduce(
        (buttons, breakpoint) => ({
            ...buttons,
            [breakpoint]: (
                <>
                    {actionButtonsProp}
                    {showAskQuestionsButton ? askQuestionsButton : undefined}
                </>
            ),
        }),
        {}
    );

    return (
        <div className="banner-wrapper">
            <NavigationBanner
                actionButtons={actionButtons}
                items={updatedBannerSettings?.items}
                navigationText={navigationText}
                titles={bannerTitles}
                onClose={handleClose}
                onNavigation={handleNavigation}
                type={bannerType}
                hideNavigation={!showNavigation}
                currentItem={selectedParagraph?.id}
                permanent={isPermanent}
            />
        </div>
    );
};

export default HeatmapPaneBanner;
