import { Component } from 'react';
import flagsmith, { IFlags } from 'flagsmith';

import {
    FEATURE_TOGGLES_POLLING_TIME_MS,
    IFeatureToggle,
    IFeatureToggleProviderProps,
    IFeatureToggleProviderState,
} from './FeatureToggleProvider-types';

class FeatureToggleProvider extends Component<
    IFeatureToggleProviderProps,
    IFeatureToggleProviderState
> {
    constructor(props: IFeatureToggleProviderProps) {
        super(props);

        this.handleToggleChange = this.handleToggleChange.bind(this);

        this.state = {
            initialised: false,
        };
    }

    async componentDidMount(): Promise<void> {
        const { featureToggles, setFeatureToggles } = this.props;

        setTimeout(() => {
            if (featureToggles.length) {
                this.setState({
                    initialised: true,
                });
            }
        }, 500);

        try {
            await flagsmith.init({
                api: 'https://edge.api.flagsmith.com/api/v1/',
                environmentID:
                    window.__APP_CONFIG__.featureToggle?.apiKey ?? '',
                cacheFlags: false,
                preventFetch: false,
                onChange: this.handleToggleChange,
                enableAnalytics: true,
            });
            const flags = await flagsmith.getAllFlags();
            const parsedFlags = this.parseFlags(flags);
            await setFeatureToggles(parsedFlags);

            this.setState({
                initialised: true,
            });

            flagsmith.startListening(FEATURE_TOGGLES_POLLING_TIME_MS);
        } catch {
            this.setState({
                initialised: true,
            });

            console.log('Error fetching feature toggles');
        }
    }

    componentWillUnmount(): void {
        flagsmith.stopListening();
    }

    private parseToggleValue(
        value: string | number | boolean
    ): string | number | boolean | object {
        let parsedValue = value;

        try {
            parsedValue = JSON.parse(String(value));
        } catch {
            // Do nothing
        }

        return parsedValue;
    }

    private parseFlags(flags: IFlags): IFeatureToggle[] {
        const toggles = [];

        if (flags && typeof flags === 'object') {
            for (const [feature, flag] of Object.entries(flags)) {
                toggles.push({
                    feature,
                    enabled: flag.enabled,
                    value: this.parseToggleValue(flag.value!), // Fixme: null checks
                });
            }
        }

        return toggles;
    }

    async handleToggleChange() {
        try {
            const toggles: IFeatureToggle[] = this.parseFlags(
                flagsmith.getAllFlags()
            );

            if (
                JSON.stringify(this.props.featureToggles) !==
                JSON.stringify(toggles)
            ) {
                await this.props.setFeatureToggles(toggles);
            }
        } catch {
            console.log('Error retrieving feature toggles');
        }
    }

    render() {
        const { initialised } = this.state;

        return initialised ? this.props.children : null;
    }
}

export default FeatureToggleProvider;
