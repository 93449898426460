import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { IRootState } from '../../../../../redux/reducers-types';
import {
    setHighlightIndexAction,
    setSelectedParagraphAction,
} from '../../../../Contract/Contract-actions';
import { HighlightIndexType } from '../../../../Contract/Contract-types';
import { IContractParagraph } from '../../../../Paragraph/Paragraph-types';

import ParagraphSuggestions from './ParagraphSuggestions';
import {
    IParagraphSuggestionsDispatchProps,
    IParagraphSuggestionsStateProps,
} from './ParagraphSuggestions-types';
import { setBannerSettingsAction } from '../../../../HeatmapPane/HeatmapPane-actions';
import { setSelectedPropertySuggestionAction } from '../../../../IssuesReport/IssuesReport-actions';

function mapStateToProps(state: IRootState): IParagraphSuggestionsStateProps {
    return {
        highlightedParagraphs: state.contract.highlightedParagraphs,
        issue: state.contract.selectedIssue,
        paragraphs: state.contract.paragraphs,
        streamId: state.contract.contract.streamId,
        selectedPropertySuggestionMap:
            state.issuesReport.selectedPropertySuggestionMap,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IParagraphSuggestionsDispatchProps {
    return {
        setHighlightIndex: (index: number, highlightType: HighlightIndexType) =>
            dispatch(setHighlightIndexAction(index, highlightType)),
        setSelectedParagraph: (paragraph: IContractParagraph) =>
            dispatch(setSelectedParagraphAction(paragraph)),
        setBannerSettings: (...args) =>
            dispatch(setBannerSettingsAction(...args)),
        setSelectedPropertySuggestion: (...args) =>
            dispatch(setSelectedPropertySuggestionAction(...args)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ParagraphSuggestions);
