import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { IParty } from '../Parties/Parties-types';
import { IRootState } from '../../redux/reducers-types';
import PartyContract from './PartyContract';
import { setContractScrollToPartyAction } from '../Parties/Parties-actions';
import {
    IPartyContractStateProps,
    IPartyContractDispatchProps,
} from './PartyContract-types';
import { setPartyContracWidthInPixelsAction } from './PartyContract-actions';

function mapStateToProps(state: IRootState): IPartyContractStateProps {
    return {
        parties: state.parties.parties,
        paragraphs: state.partyContract.paragraphs,
        contractScrollToParty: state.parties.contractScrollToParty,
        width: state.paneDivider.partyContractWidth,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IPartyContractDispatchProps {
    return {
        setContractScrollToParty: (party: IParty) =>
            dispatch(setContractScrollToPartyAction(party)),
        setPartyContractWidthInPixels: (widthPixels: number) =>
            dispatch(setPartyContracWidthInPixelsAction(widthPixels)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartyContract);
