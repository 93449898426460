import {
    CheckCircle,
    FileCopy,
    FilterList,
    ViewColumn,
} from '@mui/icons-material';
import { Component } from 'react';
import CustomButton from '../../CustomButton/CustomButton';
import IssueViewsDropdown from '../../IssueViewsDropdown/IssueViewsDropdown';
import { IssueViewsContext } from '../../IssueViewsDropdown/IssueViewsDropdown-types';

import {
    IIssuesReportToolbarProps,
    IIssuesReportToolbarState,
} from './IssuesReportToolbar-types';
import './IssuesReportToolbar.scss';
import { Button } from '@thought-river/ui-components';

class IssuesReportToolbar extends Component<
    IIssuesReportToolbarProps,
    IIssuesReportToolbarState
> {
    renderCopyTableButton() {
        const { onCopyTableCallback } = this.props;

        return (
            <CustomButton
                animatedButton
                callback={onCopyTableCallback}
                className="toolbar-btn"
                iconBefore={<FileCopy />}
                iconAfter={<CheckCircle />}
                textBefore="Copy Table"
                textAfter="Table Copied"
                data-testid="copy-table-btn"
            />
        );
    }

    render() {
        const {
            featureToggles,
            onShowEditColumnsCallback,
            onShowFiltersCallback,
            onSelectViewCallback,
            selectedView,
        } = this.props;

        return (
            <div className="issues-report-toolbar">
                <IssueViewsDropdown
                    context={IssueViewsContext.REPORT}
                    featureToggles={featureToggles}
                    onSelectViewCallback={onSelectViewCallback}
                    selectedView={selectedView}
                />
                {this.renderCopyTableButton()}
                <Button
                    variant="tertiary"
                    className="toolbar-btn"
                    onClick={() => onShowFiltersCallback()}
                    startIcon={<FilterList />}
                    data-testid="filters-btn"
                >
                    <span>Filters</span>
                </Button>
                <Button
                    variant="tertiary"
                    className="toolbar-btn"
                    onClick={() => onShowEditColumnsCallback()}
                    startIcon={<ViewColumn />}
                    data-testid="columns-btn"
                >
                    <span>Edit Columns</span>
                </Button>
            </div>
        );
    }
}

export default IssuesReportToolbar;
