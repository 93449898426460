import { CopyToClipboardButton } from '@modules/common/components/CopyToClipboardButton';
import styles from './ListItem.module.scss';

interface ListItemProps {
    name: string;
    value: React.ReactNode;
    nameWidth?: number;
    copyableName?: boolean;
    copyableValue?: boolean;
}

const ListItem = ({
    name,
    value,
    nameWidth = 130,
    copyableValue = true,
    copyableName,
}: ListItemProps) => (
    <div className={styles.item}>
        <div
            title={name}
            className={styles.name}
            style={{ width: `${nameWidth}px` }}
        >
            <div className={styles.ellipsis}>{name}</div>
            {copyableName && (
                <CopyToClipboardButton textToCopy={name.toString()} />
            )}
        </div>
        <div className={styles.value}>
            {value || '-'}
            {copyableValue && (
                <CopyToClipboardButton textToCopy={value?.toString() || ''} />
            )}
        </div>
    </div>
);

export default ListItem;
