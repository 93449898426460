import {
    MutableRefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { calculateOffsetsBetweenElements } from '../helpers';
import { ClauseOffsets } from '../types';

export const useClausesOffsets = (
    documentPageRef: MutableRefObject<HTMLDivElement | null>
) => {
    const clausesRef = useRef<HTMLElement[]>([]);
    const [offsets, setOffsets] = useState<Map<number, ClauseOffsets>>(
        new Map()
    );

    const getClauseElByIndex = useCallback(
        (paragraphIndex: number) => clausesRef.current[paragraphIndex],
        []
    );

    /**
     * Intended usage in DocumentViewer: ref={registerClauseElement(paragraphIndex)}
     * The nested function is the callback that would normally be set as the `ref` prop
     */
    const registerClauseElement = useCallback(
        (paragraphIndex: number) => (node: unknown) => {
            if (node && !getClauseElByIndex(paragraphIndex)) {
                clausesRef.current[paragraphIndex] = node as HTMLElement;

                setOffsets((offsets) => {
                    const updatedMap = new Map(offsets);
                    updatedMap.set(
                        paragraphIndex,
                        calculateOffsetsBetweenElements(
                            // Non-null assertion: When this function is called, documentPageRef can't be null
                            documentPageRef.current!,
                            node as HTMLElement
                        )
                    );
                    return updatedMap;
                });
            }
        },
        [documentPageRef, getClauseElByIndex]
    );

    const recalculateOffsets = useCallback(() => {
        if (!documentPageRef.current) {
            return;
        }

        setOffsets((offsets) => {
            const updatedMap = new Map(offsets);
            [...offsets.keys()].forEach((paragraphIndex) =>
                updatedMap.set(
                    paragraphIndex,
                    calculateOffsetsBetweenElements(
                        documentPageRef.current!,
                        getClauseElByIndex(paragraphIndex)
                    )
                )
            );
            return updatedMap;
        });
    }, [documentPageRef, getClauseElByIndex]);

    /**
     * Set up a ResizeObserver to recalculate clauses offsets when document page
     * is resized
     */
    useEffect(() => {
        if (!documentPageRef.current) {
            return;
        }

        const observer = new ResizeObserver((entries) => {
            if (entries.length > 0) {
                recalculateOffsets();
            }
        });

        observer.observe(documentPageRef.current);

        return () => observer.disconnect();
    }, [documentPageRef, getClauseElByIndex, recalculateOffsets]);

    return { registerClauseElement, offsets, clausesRef };
};
