import { useEffect, useMemo, useState } from 'react';
import {
    GetReportError,
    useGetReport,
} from '@thought-river/negotiations-common/dist/api/contractContent';
import { ContractsReport } from '@thought-river/negotiations-common/dist/api/contractContent';
import { DEFAULT_REACT_QUERY_REFETCH_INTERVAL } from '@modules/common/types';
import {
    REPORTS_MAX_CELL_COUNT,
    getReprocessingContractsCount,
} from '@modules/reports/portfolioReports';
import { ReportDefinitionOut } from '@thought-river/negotiations-common/dist/api/playbookManager';
import { PropertiesDict } from '../../../context/LexiblePropertiesProvider';

export const useGetReportData = (
    reportId: string,
    reportDefinition?: ReportDefinitionOut,
    propertiesDict?: PropertiesDict
): {
    reportData: ContractsReport | undefined;
    reportLoading: boolean;
    reportError: GetReportError | null;
    updateReportDataFetchInterval: (interval: number) => void;
} => {
    const [reportDataRefetchInterval, setReportDataRefetchInterval] = useState(
        DEFAULT_REACT_QUERY_REFETCH_INTERVAL
    );

    //Get report data hook
    const {
        data: reportData,
        isLoading: reportLoading,
        error: reportError,
    } = useGetReport(
        {
            queryParams: {
                report_definition_id: reportId,
                error_if_limit_exceeded: false,
                max_number_of_cells: REPORTS_MAX_CELL_COUNT,
            },
        },
        {
            refetchInterval: reportDataRefetchInterval,
        }
    );

    //Change report data refetch interval back to 10 minutes if there are no contracts being reprocessed
    useEffect(() => {
        if (!reportData?.rows) {
            return;
        }

        const reprocessingContractsCount =
            getReprocessingContractsCount(reportData);

        if (reprocessingContractsCount === 0) {
            setReportDataRefetchInterval(DEFAULT_REACT_QUERY_REFETCH_INTERVAL);
        }
    }, [reportData]);

    /**
     * NOTE: It is possible for a previously deleted property to still be present in
     * the columns list. We need to filter preselected columns to ones that are in
     * the report or are known properties
     * See: https://thoughtriver.atlassian.net/browse/EN-6554
     */
    const modifiedReportData = useMemo<ContractsReport | undefined>(() => {
        if (!reportData) {
            return undefined;
        }

        return {
            ...reportData,
            columns: reportData.columns.filter(
                (column) =>
                    reportDefinition?.columns.includes(column.field) ||
                    propertiesDict?.[column.field]
            ),
        };
    }, [propertiesDict, reportData, reportDefinition?.columns]);

    return {
        reportLoading,
        reportError,
        reportData: modifiedReportData,
        updateReportDataFetchInterval: setReportDataRefetchInterval,
    };
};
