import { IDisplayContract } from '../Contract/Contract-types';
import { BULK_ACTION_LIMIT } from '../ContractListTable/ContractListTable-types';
import { SET_BULK_ACTIONS } from '../ContractListToolbar/ContractListToolbar-types';
import {
    APPEND_CONTRACTS,
    IContractListActionTypes,
    IContractListReducer,
    SET_CONTRACT_VERSIONS,
    SET_CONTRACTS,
    UPDATE_CONTRACT_IN_LIST,
    SET_REQUESTED_PAGES,
    SET_SHOW_LOADER,
    PREPEND_CONTRACT,
    SET_CURRENT_PAGE,
    SET_HAS_NEXT_PAGE,
    SET_SELECTED_CONTRACT_IDS,
    SET_SELECT_ALL_CONTRACTS,
    SET_FETCHING_CONTRACTS,
} from './ContractList-types';

export const contractListInitialState: IContractListReducer = {
    contracts: [],
    currentPage: 1,
    hasNextPage: false,
    fetchingContracts: false,
    nextPage: 1,
    polling: false,
    requestedPages: [],
    selectAll: false,
    selectedContractIds: [],
    showLoader: false,
};

const ContractListReducer = (
    state = contractListInitialState,
    action: IContractListActionTypes
): IContractListReducer => {
    let contracts: IDisplayContract[];
    let requestedPages: number[];

    switch (action.type) {
        case SET_BULK_ACTIONS:
            if (!action.bulkActions) {
                return {
                    ...state,
                    selectAll: false,
                    selectedContractIds: [],
                };
            }

            return {
                ...state,
            };

        case SET_CONTRACTS:
            return {
                ...state,
                contracts: action.contracts,
            };

        case APPEND_CONTRACTS:
            return {
                ...state,
                contracts: state.contracts.concat(action.contracts),
            };

        case SET_SELECTED_CONTRACT_IDS:
            return {
                ...state,
                selectedContractIds: action.selectedContractIds,
            };

        case SET_CONTRACT_VERSIONS:
            contracts = [...state.contracts];

            return {
                ...state,
                contracts: contracts.map((contract) => {
                    if (contract.id === action.contractId) {
                        const versions = action.versions;
                        const latestVersion =
                            versions && versions.length
                                ? versions[versions.length - 1]
                                : null!; // Fixme: null checks
                        return {
                            ...contract,
                            versions,
                            latestVersion,
                        };
                    } else {
                        return contract;
                    }
                }),
            };

        case UPDATE_CONTRACT_IN_LIST:
            contracts = [...state.contracts];

            return {
                ...state,
                contracts: contracts.map((contract) => {
                    if (contract.id === action.contractId) {
                        return {
                            ...contract,
                            ...action.fields,
                        };
                    } else {
                        return contract;
                    }
                }),
            };

        case SET_CURRENT_PAGE:
            requestedPages = state.requestedPages;

            return {
                ...state,
                currentPage: action.currentPage,
                requestedPages: action.currentPage === 1 ? [1] : requestedPages,
            };

        case SET_REQUESTED_PAGES:
            return {
                ...state,
                requestedPages: action.requestedPages,
            };

        case SET_SHOW_LOADER:
            return {
                ...state,
                showLoader: action.showLoader,
            };

        case PREPEND_CONTRACT:
            return {
                ...state,
                contracts: [action.contract, ...state.contracts],
            };

        case SET_HAS_NEXT_PAGE:
            return {
                ...state,
                hasNextPage: action.hasNextPage,
            };

        case SET_SELECT_ALL_CONTRACTS:
            return {
                ...state,
                selectAll: action.selectAll,
                selectedContractIds: action.selectAll
                    ? action.selectableContractIds.slice(0, BULK_ACTION_LIMIT)
                    : [],
            };

        case SET_FETCHING_CONTRACTS:
            return {
                ...state,
                fetchingContracts: action.fetchingContracts,
            };

        default:
            return state;
    }
};

export default ContractListReducer;
