import { Domain } from '@mui/icons-material';
import { Component, ReactElement } from 'react';

import Parties from '../Parties/Parties-container';
import { PartiesType } from '../Parties/Parties-types';
import { IPartySidebarProps } from './PartySidebar-types';

const { OWN_PARTIES, COUNTER_PARTIES, DISCARDED } = PartiesType;

type TitleType<T> = T extends 'title'
    ? string
    : T extends 'element'
    ? JSX.Element
    : never;

class PartySidebar extends Component<IPartySidebarProps> {
    getTitle<T extends 'title' | 'element'>(
        partyType: PartiesType,
        titleType: T
    ): TitleType<T> {
        let titles: { title?: string; element?: ReactElement } = {};

        switch (partyType) {
            case OWN_PARTIES:
                titles = {
                    title: 'Own Party',
                    element: (
                        <span>
                            Names used to refer to your company. Select the
                            option that denotes your company’s legal name and
                            click the building icon{' '}
                            <Domain className="building-icon" /> to mark it as
                            the company formal name.
                        </span>
                    ),
                };
                break;
            case COUNTER_PARTIES:
                titles = {
                    title: 'Counter Party',
                    element: (
                        <span>
                            Names used to refer to ‘them'. Select the option
                            that denotes the company’s legal name and click the
                            building icon <Domain className="building-icon" />{' '}
                            to mark it as the company formal name. This name
                            will display on the negotiation dashboard in the
                            Counterparty column.
                        </span>
                    ),
                };
                break;
            case DISCARDED:
                titles = {
                    title: 'Discarded',
                    element: (
                        <span>Names not considered to be party names</span>
                    ),
                };
                break;
            default:
                throw new Error('Invalid party type');
        }

        return titles[titleType] as TitleType<T>;
    }

    render() {
        const { expandedGroup, width } = this.props;
        const { getTitle } = this;

        const customStyles = {
            width: `${width}%`,
        };

        return (
            <div className="party-sidebar" style={customStyles}>
                <div className="title-wrapper">
                    <span className="title">Party names</span>
                </div>
                <div className="sidebar-content">
                    <Parties
                        type={OWN_PARTIES}
                        title={getTitle(OWN_PARTIES, 'title')}
                        subtitle={getTitle(OWN_PARTIES, 'element')}
                        flipWith={COUNTER_PARTIES}
                        nextType={COUNTER_PARTIES}
                        discardTo={DISCARDED}
                        overlay={true}
                    />
                    <Parties
                        type={COUNTER_PARTIES}
                        title={getTitle(COUNTER_PARTIES, 'title')}
                        subtitle={getTitle(COUNTER_PARTIES, 'element')}
                        flipWith={OWN_PARTIES}
                        prevType={OWN_PARTIES}
                        nextType={DISCARDED}
                        discardTo={DISCARDED}
                        overlay={true}
                    />
                    <Parties
                        type={DISCARDED}
                        title={getTitle(DISCARDED, 'title')}
                        subtitle={getTitle(DISCARDED, 'element')}
                        collapsible={true}
                        prevType={COUNTER_PARTIES}
                        restoreByDefault={true}
                        discardTo={DISCARDED}
                    />
                    {expandedGroup === DISCARDED ? (
                        <div className="parties-placeholder" />
                    ) : null}
                </div>
            </div>
        );
    }
}

export default PartySidebar;
