import { Component, Fragment } from 'react';

import {
    IContractPollingProviderProps,
    IContractPollingProviderState,
} from './ContractPollingProvider-types';
import { IContractVersion, UploadStatus } from '../Contract/Contract-types';
import { mapContract, mapVersion } from '../App/App-mappers';
import AssessmentResource from '../../resources/AssessmentResource';
import VersionResource from '../../resources/VersionResource';
import ContractResource from '../../resources/ContractResource';
import { PartiesType } from '../Parties/Parties-types';
import { sleep } from '../App/App-helpers';
import { fetchContractStatus } from '../Contract/Contract-helpers';
import {
    IProcessingContract,
    IReprocessingContract,
} from './ContractPollingProvider-types';
import dayjs from 'dayjs';
import { fetchGetProcessingSteps } from '@thought-river/negotiations-common/dist/api/contractManagement';
import { FEATURE_TOGGLE_CONTRACTS_TREE } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import {
    checkHasContractFailedProcessing,
    isProcessingStepComplete,
} from '@thought-river/negotiations-common';

const { COMPLETED, FAILED, PROCESSING } = UploadStatus;

class ContractPollingProvider extends Component<
    IContractPollingProviderProps,
    IContractPollingProviderState
> {
    async componentDidMount() {
        const {
            setTimedOutProcessingContractIds,
            setPollingProcessing,
            setPollingReprocessing,
        } = this.props;

        setTimedOutProcessingContractIds([]);
        setPollingProcessing(false);
        setPollingReprocessing(false);

        if (this.props.processingContracts.length) {
            this.pollProcessingContracts();
        }

        if (this.props.reprocessingContracts.length) {
            this.pollReprocessingContracts();
        }
    }

    async componentWillUnmount() {
        const {
            setPollingProcessing,
            setPollingReprocessing,
            setTimedOutProcessingContractIds,
        } = this.props;

        setPollingProcessing(false);
        setPollingReprocessing(false);
        setTimedOutProcessingContractIds([]);
    }

    async componentDidUpdate(prevProps: IContractPollingProviderProps) {
        const {
            processingContracts,
            reprocessingContracts,
            setPollingProcessing,
            setPollingReprocessing,
        } = this.props;

        if (
            !this.props.pollingProcessing &&
            !prevProps.processingContracts.length &&
            processingContracts.length
        ) {
            await this.pollProcessingContracts();
        }

        if (
            !this.props.pollingReprocessing &&
            !prevProps.reprocessingContracts.length &&
            reprocessingContracts.length
        ) {
            await this.pollReprocessingContracts();
        }

        if (
            this.props.pollingProcessing &&
            prevProps.processingContracts.length &&
            !processingContracts.length
        ) {
            setPollingProcessing(false);
        }

        if (
            this.props.pollingReprocessing &&
            prevProps.reprocessingContracts.length &&
            !reprocessingContracts.length
        ) {
            setPollingReprocessing(false);
        }
    }

    async contractHasProcessed(contract: IProcessingContract): Promise<void> {
        const { updateContract, updateProcessingContract, featureToggles } =
            this.props;
        const { contractId, stream } = contract;

        const isContractsTreeEnabled = !!featureToggles.find(
            (toggle) => toggle.feature === FEATURE_TOGGLE_CONTRACTS_TREE
        )?.enabled;

        const updatedVersions = (
            await VersionResource.getVersions(contractId, stream)
        ).data.map((version, index) => mapVersion(version, index));
        const latestVersion = updatedVersions.find(
            (version) => version.isLatest
        );

        if (!latestVersion) {
            return;
        }

        let error = '';

        if (isContractsTreeEnabled) {
            const steps = await fetchGetProcessingSteps({
                pathParams: { uuid: latestVersion.id },
            });

            if (steps.length) {
                const hasFailedProcessing =
                    checkHasContractFailedProcessing(steps);
                if (hasFailedProcessing) {
                    throw new Error('Failed to process version');
                }

                const completedStepsCount = steps.filter(
                    isProcessingStepComplete
                ).length;

                updateProcessingContract({
                    ...contract,
                    percentageProgress: Math.round(
                        (completedStepsCount / steps.length) * 100
                    ),
                });

                const allStepsComplete = completedStepsCount === steps.length;
                if (allStepsComplete) {
                    this.onSuccessfulProcessing(contract, latestVersion);
                }
            }
        } else {
            const { setTimedOutProcessingContractId, unsetProcessingContract } =
                this.props;

            updateProcessingContract({
                ...contract,
                status: latestVersion.uploadStatusDescription,
            });

            switch (latestVersion.uploadStatus) {
                case COMPLETED:
                    if (
                        latestVersion?.assessmentIds.length ||
                        latestVersion?.requirePartyConfirmation !== null
                    ) {
                        this.onSuccessfulProcessing(contract, latestVersion);
                    }
                    break;
                case FAILED:
                    error = 'Failed to process version';
                    break;
                case PROCESSING:
                    if (
                        this.timeHasExpired(latestVersion.createdAt, 2, 'hours')
                    ) {
                        unsetProcessingContract(contractId);
                        setTimedOutProcessingContractId(contractId);
                    }
                    break;
            }
        }

        const fullContract = await ContractResource.getContract(
            contractId,
            stream
        );

        const contractStatus = await fetchContractStatus(contractId);

        const updatedContract = mapContract(
            fullContract.data,
            updatedVersions,
            contractStatus[0]
        );

        updateContract(contractId, updatedContract);

        if (error) {
            throw new Error(error);
        }
    }

    async contractHasReprocessed(
        contract: IReprocessingContract
    ): Promise<{ assessmentIds: string[]; reprocessDone: boolean }> {
        const { assessmentCount, contractId, stream, versionId, queuedAt } =
            contract;

        let reprocessDone = false;

        const version = mapVersion(
            (await VersionResource.getVersion(contractId, versionId, stream))
                .data,
            0
        );

        if (version.uploadStatus === FAILED) {
            throw new Error('Failed to reprocess version');
        }

        const assessments = await AssessmentResource.getAssessments(
            contractId,
            versionId,
            stream
        );
        const assessmentIds = assessments.data.map(
            (assessment) => assessment.id
        );

        if (
            assessmentIds.length > assessmentCount &&
            version.uploadStatus === COMPLETED
        ) {
            const contractStatus = await fetchContractStatus(contractId);
            const updatedContract = mapContract(
                (await ContractResource.getContract(contractId, stream)).data,
                [],
                contractStatus[0]
            );

            if (!updatedContract.reprocessRequired) {
                reprocessDone = true;
            }
        } else if (this.timeHasExpired(queuedAt)) {
            throw new Error('Failed to reprocess version');
        }

        return {
            assessmentIds,
            reprocessDone,
        };
    }

    private isPartiallyAssessed(version: IContractVersion): boolean {
        if (!version) {
            return true;
        }
        const partyTypes = version.parties.map((party) => party.type);
        return (
            !partyTypes.includes(PartiesType.COUNTER_PARTIES) ||
            !partyTypes.includes(PartiesType.OWN_PARTIES)
        );
    }

    async legacyContractHasReprocessed(
        contract: IReprocessingContract
    ): Promise<boolean> {
        const {
            setFailedReprocessedContract,
            setSuccessfulReprocessedContract,
        } = this.props;
        const { contractId, versionId } = contract;
        const contractsResponse = await ContractResource.getContracts(null, {
            'contract-ids': contractId,
        });
        const updatedContract = contractsResponse.data[0];
        let reprocessDone = false;

        if (!updatedContract) {
            throw new Error('Contract not found');
        }

        const versions =
            contractsResponse?.included?.map((version, index) =>
                mapVersion(version, index)
            ) ?? [];
        const currentVersion = versions.find(
            (version) => version.id === versionId
        );
        const contractStatus = await fetchContractStatus(contractId);

        if (currentVersion?.uploadStatus === FAILED) {
            setFailedReprocessedContract(contract);
            throw new Error('Failed to reprocess version');
        }

        const mappedContract = mapContract(
            updatedContract,
            versions,
            contractStatus[0]
        );

        if (
            mappedContract &&
            mappedContract.streamCode &&
            mappedContract.streamId
        ) {
            setSuccessfulReprocessedContract(
                contract,
                this.isPartiallyAssessed(currentVersion!) // Fixme: null checks
            );
            reprocessDone = true;
        }

        return reprocessDone;
    }

    async onSuccessfulProcessing(
        contract: IProcessingContract,
        version: IContractVersion
    ) {
        const { unsetProcessingContract, setSuccessfulProcessedContract } =
            this.props;

        unsetProcessingContract(contract.contractId);
        setSuccessfulProcessedContract(
            contract,
            this.isPartiallyAssessed(version)
        );
    }

    async onSuccessfulReprocessing(
        contract: IReprocessingContract,
        assessmentIds: string[]
    ) {
        const {
            updateContract,
            unsetReprocessingContract,
            setSuccessfulReprocessedContract,
        } = this.props;
        const { contractId, stream } = contract;

        const updatedVersions = (
            await VersionResource.getVersions(contractId, stream)
        ).data.map((version, index) =>
            mapVersion(version, index, assessmentIds)
        );
        const contractStatus = await fetchContractStatus(contractId);

        const updatedContract = mapContract(
            (await ContractResource.getContract(contractId, stream)).data,
            updatedVersions,
            contractStatus[0]
        );

        updateContract(contractId, updatedContract);
        unsetReprocessingContract(contractId);
        setSuccessfulReprocessedContract(
            contract,
            this.isPartiallyAssessed(updatedContract.latestVersion)
        );
    }

    async pollProcessingContracts(intervalMs: number = 3500) {
        const {
            processingContracts,
            unsetProcessingContract,
            setPollingProcessing,
            setFailedProcessedContract,
        } = this.props;

        if (processingContracts.length) {
            if (!this.props.pollingProcessing) {
                setPollingProcessing(true);
            }

            for (const contract of processingContracts) {
                const { contractId } = contract;

                try {
                    await this.contractHasProcessed(contract);
                } catch (error) {
                    unsetProcessingContract(contractId);
                    setFailedProcessedContract(contract);
                }
            }

            await sleep(intervalMs);
            this.pollProcessingContracts();
        }
    }

    async pollReprocessingContracts(intervalMs: number = 3500) {
        const {
            reprocessingContracts,
            setPollingReprocessing,
            unsetReprocessingContract,
            setFailedReprocessedContract,
        } = this.props;

        if (reprocessingContracts.length) {
            if (!this.props.pollingReprocessing) {
                setPollingReprocessing(true);
            }

            for (const contract of reprocessingContracts) {
                let assessmentIds: string[] = [];
                let reprocessDone = false;

                const { contractId, stream, streamId } = contract;

                try {
                    if (stream && streamId) {
                        const contractStatus =
                            await this.contractHasReprocessed(contract);
                        reprocessDone = contractStatus.reprocessDone;
                        assessmentIds = contractStatus.assessmentIds;
                    } else {
                        reprocessDone = await this.legacyContractHasReprocessed(
                            contract
                        );
                    }
                } catch (error) {
                    unsetReprocessingContract(contractId);
                    setFailedReprocessedContract(contract);
                }

                if (reprocessDone) {
                    await this.onSuccessfulReprocessing(
                        contract,
                        assessmentIds
                    );
                }
            }
            await sleep(intervalMs);
            this.pollReprocessingContracts();
        }
    }

    private timeHasExpired(
        timestamp: string,
        duration: number = 1,
        units: any = 'days'
    ) {
        return (
            timestamp &&
            dayjs(timestamp).isBefore(dayjs().subtract(duration, units))
        );
    }

    render() {
        return <Fragment />;
    }
}

export default ContractPollingProvider;
