import { Button, ButtonGroup, Tooltip } from '@thought-river/ui-components';
import { BulkActionsProps } from './types';

const BULK_ACTION_MAX_ITEMS = 30;

const BulkActions = ({ selectedCount, actions }: BulkActionsProps) => {
    if (!selectedCount) {
        return null;
    }

    const isDisabled = selectedCount > BULK_ACTION_MAX_ITEMS;

    return (
        <Tooltip
            title={
                isDisabled
                    ? 'You can only sign or reanalyze 30 contracts or less at one time'
                    : ''
            }
        >
            <ButtonGroup>
                {actions.map((action) => (
                    <Button
                        key={action.label}
                        startIcon={action.icon}
                        variant="secondary"
                        onClick={action.onClick}
                        disabled={isDisabled}
                    >
                        {action.label}
                    </Button>
                ))}
            </ButtonGroup>
        </Tooltip>
    );
};

export default BulkActions;
