import { useLayoutEffect, useRef } from 'react';
import { SectionBarProps, SectionBarsProps } from './types';
import classNames from 'classnames';
import { useDebounce } from 'use-debounce';

import styles from './DocumentContents.module.scss';

const INDENTATION_PER_LEVEL = 10;

const SectionBar = ({
    section,
    level,
    currentParagraphIndex,
}: SectionBarProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const isCurrentSection = currentParagraphIndex === section.paragraph_index;

    useLayoutEffect(() => {
        if (ref.current && isCurrentSection) {
            ref.current.scrollIntoView();
        }
    }, [currentParagraphIndex, isCurrentSection]);

    return (
        <>
            <div
                ref={ref}
                className={classNames(styles.sectionBar, {
                    [styles.current]:
                        currentParagraphIndex === section.paragraph_index,
                })}
                style={{
                    width: `calc(100% - ${level * INDENTATION_PER_LEVEL}px)`,
                }}
            />
            {Array.isArray(section.children)
                ? section.children.map((childSection) => (
                      <SectionBar
                          key={childSection.paragraph_uuid}
                          level={level + 1}
                          section={childSection}
                          currentParagraphIndex={currentParagraphIndex}
                      />
                  ))
                : null}
        </>
    );
};

const SectionBars = ({
    topLevelSections,
    onHover,
    currentParagraphIndex,
}: SectionBarsProps) => {
    const [debouncedParagraphIndex] = useDebounce(currentParagraphIndex, 200);

    return (
        <div className={styles.sectionBarsWrapper}>
            <div className={styles.sectionBars} onMouseEnter={onHover}>
                {topLevelSections.map((section) => (
                    <SectionBar
                        key={section.paragraph_uuid}
                        section={section}
                        level={0}
                        currentParagraphIndex={debouncedParagraphIndex}
                    />
                ))}
            </div>
        </div>
    );
};

export default SectionBars;
