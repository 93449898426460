import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { IRootState } from '../../../redux/reducers-types';
import SummaryProperties from './SummaryProperties';
import {
    ISummaryPropertiesDispatchProps,
    ISummaryPropertiesStateProps,
} from './SummaryProperties-types';
import {
    setHighlightedParagraphs,
    setHighlightedTextAction,
    setSelectedContractIntelligenceAction,
    setSelectedIssueAction,
    setSelectedParagraphAction,
    setSelectedPropertyCodeAction,
    setSummaryPropertiesAction,
    updateSummaryPropertyAction,
} from '../../Contract/Contract-actions';
import { IContractParagraph } from '../../Paragraph/Paragraph-types';
import { ISummaryProperty } from '../SummaryPane-types';
import { setBannerSettingsAction } from '../../HeatmapPane/HeatmapPane-actions';
import { IBannerSettings } from '../../HeatmapPane/HeatmapPane-types';

import { ISelectedContractIntelligence } from '../../Contract/Contract-types';
import { ContractIssue } from '@thought-river/negotiations-common';

function mapStateToProps(state: IRootState): ISummaryPropertiesStateProps {
    return {
        bannerSettings: state.heatmapPane.bannerSettings,
        featureToggles: state.featureToggles.toggles,
        highlightedText: state.contract.highlightedText,
        issues: state.contract.issues,
        paragraphs: state.contract.paragraphs,
        properties: state.contract.summaryProperties,
        selectedContractIntelligence:
            state.contract.selectedContractIntelligence,
        selectedParagraph: state.contract.selectedParagraph,
        selectedPropertyCode: state.contract.selectedPropertyCode,
        selectedTemplate: state.contract.selectedPropertiesTemplate,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): ISummaryPropertiesDispatchProps {
    return {
        setHighlightedParagraphs: (paragraphs: IContractParagraph[]) =>
            dispatch(setHighlightedParagraphs(paragraphs)),
        setHighlightedText: (text: string) =>
            dispatch(setHighlightedTextAction(text)),
        setBannerSettings: (settings: IBannerSettings) =>
            dispatch(setBannerSettingsAction(settings)),
        setSelectedContractIntelligence: (
            intelligence: ISelectedContractIntelligence
        ) => dispatch(setSelectedContractIntelligenceAction(intelligence)),
        setSelectedParagraph: (...args) =>
            dispatch(setSelectedParagraphAction(...args)),
        setSelectedIssue: (selectedIssue: ContractIssue) =>
            dispatch(setSelectedIssueAction(selectedIssue)),
        setSelectedPropertyCode: (code: string) =>
            dispatch(setSelectedPropertyCodeAction(code)),
        setSummaryProperties: (properties: ISummaryProperty[]) =>
            dispatch(setSummaryPropertiesAction(properties)),
        updateSummaryProperty: (property: ISummaryProperty) =>
            dispatch(updateSummaryPropertyAction(property)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryProperties);
