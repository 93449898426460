import {
    FamiliarityLevel,
    IssueSeverityLevel,
    IssueStatus,
} from '@thought-river/negotiations-common';
import { IssueDisplay } from '../IssueListPane/IssueListPane-types';
import {
    IssuesTableHeader,
    SortOrder,
} from '../IssuesReport/HybridReportDataGrid/HybridReportDataGrid-types';
import {
    contractSummaryColumns,
    commercialReviewColumns,
    counterpartyReponseColumns,
    issueColumns,
    coverSheetColumns,
    bidReportColumns,
    negotiationReportColumns,
    finalReportForSignatoriesColumns,
    finalReportForProjectTeamsColumns,
    adeccoNDAReportColumns,
} from '../IssuesReport/IssuesReport-reducer';
import {
    CHANGE_ACTIVE_ISSUE_FILTERS,
    DEFAULT_FILTER_PRESET,
    IIssueFiltersValues,
    IIssueListFiltersActionTypes,
    IIssueListFiltersReducer,
    IssuesSortOption,
    PresetIssueFilters,
    RESET_ACTIVE_ISSUE_FILTERS,
    SET_ISSUES_SORT_BY,
    SET_SELECTED_FILTER_PRESET,
    UPDATE_ACTIVE_ISSUE_FILTER_VALUES,
} from './IssueListFilters-types';
import {
    FEATURE_TOGGLE_ISSUES_REPORT_ADECCO_VIEWS,
    FEATURE_TOGGLE_ISSUES_REPORT_RSBG_VIEWS,
} from '../FeatureToggleProvider/FeatureToggleProvider-types';

export const filtersPresets: Record<PresetIssueFilters, IIssueFiltersValues> = {
    [PresetIssueFilters.AllOpen]: {
        status: [IssueStatus.OPEN],
        severity: [
            IssueSeverityLevel.SHOWSTOPPER,
            IssueSeverityLevel.HIGH,
            IssueSeverityLevel.MEDIUM,
            IssueSeverityLevel.LOW,
        ],
        themes: [],
        categories: [],
        displays: [],
        columns: [...issueColumns],
        familiarity: [],
        summaryPropertiesOnly: false,
    },
    [PresetIssueFilters.Priority]: {
        status: [IssueStatus.OPEN],
        severity: [IssueSeverityLevel.SHOWSTOPPER, IssueSeverityLevel.HIGH],
        themes: [],
        categories: [],
        displays: [],
        columns: [...issueColumns],
        familiarity: [],
        summaryPropertiesOnly: false,
    },

    [PresetIssueFilters.NotDetected]: {
        status: [],
        severity: [
            IssueSeverityLevel.SHOWSTOPPER,
            IssueSeverityLevel.HIGH,
            IssueSeverityLevel.MEDIUM,
            IssueSeverityLevel.LOW,
            IssueSeverityLevel.OK,
        ],
        themes: [],
        categories: [],
        displays: [IssueDisplay.NOT_DETECTED],
        columns: [...issueColumns],
        familiarity: [],
        summaryPropertiesOnly: false,
    },

    [PresetIssueFilters.AllClosed]: {
        status: [IssueStatus.CLOSED],
        severity: [
            IssueSeverityLevel.SHOWSTOPPER,
            IssueSeverityLevel.HIGH,
            IssueSeverityLevel.MEDIUM,
            IssueSeverityLevel.LOW,
            IssueSeverityLevel.OK,
        ],
        themes: [],
        categories: [],
        displays: [],
        columns: [...issueColumns],
        familiarity: [],
        summaryPropertiesOnly: false,
    },

    [PresetIssueFilters.AllIssues]: {
        status: [IssueStatus.OPEN, IssueStatus.CLOSED, IssueStatus.IGNORED],
        severity: [
            IssueSeverityLevel.SHOWSTOPPER,
            IssueSeverityLevel.HIGH,
            IssueSeverityLevel.MEDIUM,
            IssueSeverityLevel.LOW,
            IssueSeverityLevel.OK,
        ],
        themes: [],
        categories: [],
        displays: [],
        columns: [...issueColumns],
        familiarity: [],
        summaryPropertiesOnly: false,
    },
    [PresetIssueFilters.AllLexibleQuestions]: {
        status: [],
        severity: [],
        themes: [],
        categories: [],
        displays: [],
        columns: [...contractSummaryColumns],
        familiarity: [],
        summaryPropertiesOnly: false,
    },
    [PresetIssueFilters.ContractSummary]: {
        status: [],
        severity: [],
        themes: [],
        categories: [],
        displays: [],
        columns: [...contractSummaryColumns],
        familiarity: [],
        summaryPropertiesOnly: true,
    },
    [PresetIssueFilters.CounterpartyResponse]: {
        status: [IssueStatus.OPEN],
        severity: [],
        themes: [],
        categories: [],
        displays: [],
        columns: [...counterpartyReponseColumns],
        familiarity: [],
        summaryPropertiesOnly: false,
    },
    [PresetIssueFilters.CommercialReview]: {
        status: [IssueStatus.OPEN],
        severity: [IssueSeverityLevel.SHOWSTOPPER, IssueSeverityLevel.HIGH],
        themes: [],
        categories: [],
        displays: [],
        columns: [...commercialReviewColumns],
        familiarity: [
            undefined!, // Also show issues that are HIGH or SHOWSTOPPER but haven't been detected // Fixme: null checks
            FamiliarityLevel.UNFAMILIAR,
            FamiliarityLevel.UNCOMMON,
        ],
        summaryPropertiesOnly: false,
        issuesReportOptions: {
            defaultSortModel: [
                {
                    field: IssuesTableHeader.FAMILIARITY,
                    sort: SortOrder.DESC,
                },
            ],
        },
    },
    [PresetIssueFilters.CoverSheet]: {
        status: [IssueStatus.OPEN, IssueStatus.CLOSED, IssueStatus.IGNORED],
        severity: [],
        themes: [],
        categories: [],
        displays: [],
        columns: [...coverSheetColumns],
        familiarity: [],
        summaryPropertiesOnly: false,
        issuesReportOptions: {
            defaultPageSize: 100,
            defaultSortModel: [
                {
                    field: IssuesTableHeader.PROPERTY_THEME,
                    sort: SortOrder.ASC,
                },
                {
                    field: IssuesTableHeader.IMPORTANCE,
                    sort: SortOrder.DESC,
                },
            ],
        },
    },
    [PresetIssueFilters.BidReport]: {
        status: [IssueStatus.OPEN],
        severity: [
            IssueSeverityLevel.SHOWSTOPPER,
            IssueSeverityLevel.HIGH,
            IssueSeverityLevel.OK,
        ],
        themes: [],
        categories: [],
        displays: [],
        columns: [...bidReportColumns],
        familiarity: [],
        summaryPropertiesOnly: false,
        issuesReportOptions: {
            defaultPageSize: 100,
            defaultSortModel: [
                {
                    field: IssuesTableHeader.PROPERTY_THEME,
                    sort: SortOrder.ASC,
                },
                {
                    field: IssuesTableHeader.IMPORTANCE,
                    sort: SortOrder.DESC,
                },
            ],
        },
        featureFlag: FEATURE_TOGGLE_ISSUES_REPORT_RSBG_VIEWS,
    },
    [PresetIssueFilters.NegotiationReport]: {
        status: [IssueStatus.OPEN],
        severity: [
            IssueSeverityLevel.SHOWSTOPPER,
            IssueSeverityLevel.HIGH,
            IssueSeverityLevel.MEDIUM,
            IssueSeverityLevel.LOW,
            IssueSeverityLevel.OK,
        ],
        themes: [],
        categories: [],
        displays: [],
        columns: [...negotiationReportColumns],
        familiarity: [],
        summaryPropertiesOnly: false,
        issuesReportOptions: {
            defaultPageSize: 100,
            defaultSortModel: [
                {
                    field: IssuesTableHeader.PROPERTY_THEME,
                    sort: SortOrder.ASC,
                },
                {
                    field: IssuesTableHeader.IMPORTANCE,
                    sort: SortOrder.DESC,
                },
            ],
        },
        featureFlag: FEATURE_TOGGLE_ISSUES_REPORT_RSBG_VIEWS,
    },
    [PresetIssueFilters.FinalReportForSignatories]: {
        status: [IssueStatus.OPEN],
        severity: [
            IssueSeverityLevel.SHOWSTOPPER,
            IssueSeverityLevel.HIGH,
            IssueSeverityLevel.OK,
        ],
        themes: [],
        categories: [],
        displays: [],
        columns: [...finalReportForSignatoriesColumns],
        familiarity: [],
        summaryPropertiesOnly: false,
        issuesReportOptions: {
            defaultPageSize: 100,
            defaultSortModel: [
                {
                    field: IssuesTableHeader.PROPERTY_THEME,
                    sort: SortOrder.ASC,
                },
                {
                    field: IssuesTableHeader.IMPORTANCE,
                    sort: SortOrder.DESC,
                },
            ],
        },
        featureFlag: FEATURE_TOGGLE_ISSUES_REPORT_RSBG_VIEWS,
    },
    [PresetIssueFilters.FinalReportForProjectTeams]: {
        status: [IssueStatus.OPEN],
        severity: [
            IssueSeverityLevel.SHOWSTOPPER,
            IssueSeverityLevel.HIGH,
            IssueSeverityLevel.MEDIUM,
            IssueSeverityLevel.LOW,
            IssueSeverityLevel.OK,
        ],
        themes: [],
        categories: [],
        displays: [],
        columns: [...finalReportForProjectTeamsColumns],
        familiarity: [],
        summaryPropertiesOnly: false,
        issuesReportOptions: {
            defaultPageSize: 100,
            defaultSortModel: [
                {
                    field: IssuesTableHeader.PROPERTY_THEME,
                    sort: SortOrder.ASC,
                },
                {
                    field: IssuesTableHeader.IMPORTANCE,
                    sort: SortOrder.DESC,
                },
            ],
        },
        featureFlag: FEATURE_TOGGLE_ISSUES_REPORT_RSBG_VIEWS,
    },
    [PresetIssueFilters.AdeccoNDAReport]: {
        status: [IssueStatus.OPEN],
        severity: [
            IssueSeverityLevel.SHOWSTOPPER,
            IssueSeverityLevel.HIGH,
            IssueSeverityLevel.MEDIUM,
            IssueSeverityLevel.LOW,
            IssueSeverityLevel.OK,
        ],
        themes: [],
        categories: [],
        displays: [],
        columns: [...adeccoNDAReportColumns],
        familiarity: [],
        summaryPropertiesOnly: false,
        issuesReportOptions: {
            defaultPageSize: 100,
            defaultSortModel: [
                {
                    field: IssuesTableHeader.LINKED_CLAUSES,
                    sort: SortOrder.ASC,
                },
            ],
        },
        featureFlag: FEATURE_TOGGLE_ISSUES_REPORT_ADECCO_VIEWS,
    },
};

export const issueListFiltersinitialState: IIssueListFiltersReducer = {
    activeIssueFilters: {
        ...filtersPresets[PresetIssueFilters[DEFAULT_FILTER_PRESET]],
    },
    lastValidPreset: DEFAULT_FILTER_PRESET,
    selectedFilterPreset: DEFAULT_FILTER_PRESET,
    sortBy: IssuesSortOption.CLAUSE,
};

const IssueListFiltersReducer = (
    state = issueListFiltersinitialState,
    action: IIssueListFiltersActionTypes
): IIssueListFiltersReducer => {
    switch (action.type) {
        case RESET_ACTIVE_ISSUE_FILTERS:
            return {
                ...issueListFiltersinitialState,
            };
        case UPDATE_ACTIVE_ISSUE_FILTER_VALUES:
            return {
                ...state,
                selectedFilterPreset: '',
                activeIssueFilters: {
                    ...action.activeIssueFilters,
                },
            };
        case CHANGE_ACTIVE_ISSUE_FILTERS:
            return {
                ...state,
                selectedFilterPreset: action.selectedFilter,
                activeIssueFilters: {
                    ...filtersPresets[
                        PresetIssueFilters[action.selectedFilter]
                    ],
                },
                lastValidPreset: action.selectedFilter,
            };
        case SET_ISSUES_SORT_BY:
            return {
                ...state,
                sortBy: action.sortBy,
            };
        case SET_SELECTED_FILTER_PRESET:
            return {
                ...state,
                selectedFilterPreset: action.selectedFilterPreset,
            };
        default:
            return state;
    }
};

export default IssueListFiltersReducer;
