import { useClausesSidebarContext } from '@modules/reports/common/components/ClausesSidebar/ClausesSidebarProvider';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Button, Tooltip } from '@thought-river/ui-components';
import { useSelector } from 'react-redux';
import { FEATURE_TOGGLE_TEST_CONTRACT_REPORT_CLAUSES_HTML } from '../../../../../../components/FeatureToggleProvider/FeatureToggleProvider-types';
import { IRootState } from '../../../../../../redux/reducers-types';

import styles from './Cell.module.scss';

const LinkedClausesCell = (params: GridRenderCellParams) => {
    const featureToggles = useSelector(
        (state: IRootState) => state.featureToggles.toggles
    );
    const clausesHtmlEnabled = !!featureToggles.find(
        (toggle) =>
            toggle.feature === FEATURE_TOGGLE_TEST_CONTRACT_REPORT_CLAUSES_HTML
    )?.enabled;

    const { contract, paragraphs, selectedPropertyCode, selectedParagraph } =
        useSelector((state: IRootState) => state.contract);

    const clauses = params.row['property_linked_clauses'] || [];

    const { openClausesSidebar, htmlBody, openContractSidebar } =
        useClausesSidebarContext();

    const propertyCode = params.row['property_dfcode'];

    const handleClauseClick = (paragraphId: string) => {
        const contractId = contract.id;
        const stream = contract.streamCode;

        const linkedParagraphs = paragraphs.filter((p) =>
            clauses.some((clause: any) => clause.uuid === p.id)
        );
        const linkedParagraphIds = linkedParagraphs.map((p) => p.id);

        const propertyLabel = params.row['property_name'];

        if (clausesHtmlEnabled) {
            openContractSidebar(paragraphId, linkedParagraphIds, propertyCode);
        } else {
            openClausesSidebar({
                contractId,
                stream,
                propertyCode,
                propertyLabel,
                paragraphs: linkedParagraphs,
            });
        }
    };

    return (
        <div className={styles.clausesWrapper}>
            {clauses.length
                ? clauses.map((clause: any) => {
                      const htmlParagraph =
                          htmlBody &&
                          htmlBody.querySelector(
                              `[data-ppl-index="${clause.paragraph_index}"]`
                          );

                      const isClauseSelected =
                          selectedPropertyCode === propertyCode &&
                          selectedParagraph?.id === clause.uuid;

                      return (
                          <Tooltip
                              key={clause.uuid}
                              title={
                                  <div
                                      className={styles.clauseTooltipContent}
                                      dangerouslySetInnerHTML={{
                                          __html:
                                              htmlParagraph?.outerHTML ??
                                              'Could not find the paragraph contents',
                                      }}
                                  />
                              }
                              classes={{ tooltip: styles.clauseTooltip }}
                          >
                              <Button
                                  //this id is used in the component that controls closing slide out sidebar
                                  id={`clause-btn-${clause.uuid}`}
                                  variant="secondary"
                                  key={clause.uuid}
                                  onClick={() => handleClauseClick(clause.uuid)}
                                  className={
                                      isClauseSelected
                                          ? styles.highlightedClause
                                          : undefined
                                  }
                              >
                                  {clause.display_number}
                              </Button>
                          </Tooltip>
                      );
                  })
                : '-'}
        </div>
    );
};

export default LinkedClausesCell;
