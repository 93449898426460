import without from 'lodash/without';
import { ReportDefinitionOut } from '@thought-river/negotiations-common/dist/api/playbookManager';
import { useMemo } from 'react';
import { ReportFilters } from '@modules/reports/common';
import { getOrderedSelectedFields } from '@modules/reports/portfolioReports/helpers';

interface UseGetReportConfigReturnValue {
    selectedColumns: string[];
    selectedFilters: ReportFilters;
    visibleColumns: string[];
}

/**
 * Takes in report definition and returns selected columns, selected filters, and visible columns
 */
export const useGetReportConfig = (
    reportDefinition?: ReportDefinitionOut
): UseGetReportConfigReturnValue => {
    const memoizedConfig = useMemo(() => {
        // When accesing reports/{reportId}/edit directly via url reportDefinition is undefined
        if (!reportDefinition) {
            return {
                selectedColumns: [],
                selectedFilters: {},
                visibleColumns: [],
            };
        }

        let selectedColumns = getOrderedSelectedFields(reportDefinition);

        // Filter out grouped by columns, which will be called __COLUMN_NAME__,
        // Also filter out the actions column
        // e.g. __row_group_by_columns_group__
        selectedColumns = selectedColumns.filter(
            (column) =>
                (column.slice(0, 2) !== '__' || column.slice(-2) !== '__') &&
                column !== 'actions'
        );

        const selectedFilters: ReportFilters = {};
        reportDefinition?.filters?.items.forEach(
            (f) => (selectedFilters[f.field] = f.value ?? '')
        );

        const invisibleColumns: string[] = [];
        Object.entries(
            reportDefinition?.config?.columns?.columnVisibilityModel || {}
        ).forEach((value) => {
            if (!value[1]) {
                invisibleColumns.push(value[0]);
            }
        });
        const visibleColumns = without(selectedColumns, ...invisibleColumns);

        return {
            selectedColumns,
            selectedFilters,
            visibleColumns,
        };
    }, [reportDefinition]);

    return memoizedConfig;
};
