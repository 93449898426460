import { useAppSelector } from '@modules/common/hooks';
import {
    useGetContractIdsFromId,
    useGetContractVersionSections,
} from '@thought-river/negotiations-common/dist/api/contractContent';
import {
    useGetFiles,
    useGetProcessingHistory,
} from '@thought-river/negotiations-common/dist/api/contractManagement';
import { useGetMe } from '@thought-river/negotiations-common/dist/api/landlord';
import { useEffect, useState } from 'react';

export const useGetDiagnosticsData = (
    contractUuid: string,
    versionUuid: string
) => {
    const accountCode = useAppSelector((state) => state.auth.accountCode);

    const featureToggles = useAppSelector(
        (state) => state.featureToggles.toggles
    );

    const { data: processingHistory } = useGetProcessingHistory({
        pathParams: { uuid: versionUuid },
        queryParams: { tenant_code: accountCode },
    });

    const { data: contractIds } = useGetContractIdsFromId({
        pathParams: { ambiguousContractId: contractUuid },
    });

    const { data: files } = useGetFiles({
        pathParams: { uuid: versionUuid },
    });

    const { data: usersInfo } = useGetMe({});

    const { data: sectionsBlob } = useGetContractVersionSections({
        pathParams: { contractVersionUuid: versionUuid },
        queryParams: { output_format: 'text' },
    });

    const [sections, setSections] = useState('');
    useEffect(() => {
        (async () => {
            //@ts-ignore
            const sections = await sectionsBlob?.text();
            setSections(sections);
        })();
    }, [sectionsBlob]);

    return {
        processingHistory,
        files,
        contractIds,
        usersInfo,
        sections,
        featureToggles,
    };
};
