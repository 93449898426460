import { useEffect, useState } from 'react';
import { LoadingDataGrid } from '@thought-river/ui-components';
import styles from './ViewReportPage.module.scss';
import {
    useGetReportDefinition,
    useUpdateReportDefinition,
} from '@thought-river/negotiations-common/dist/api/playbookManager';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { ReportDefinitionUpdate } from '@thought-river/negotiations-common/dist/api/playbookManager';
import { ErrorBoundary } from '@modules/common/components/ErrorBoundary';
import {
    ErrorBanner,
    ReportConfigChangeHandler,
    ReportViewError,
    Toolbar,
    updateTanStackQueryReportDataRow,
    updateTanStackQueryReportDefinitionsData,
    useLexibleContext,
    useReportsAnalytics,
    useGetReportData,
    ReportDataGrid,
} from '@modules/reports/portfolioReports';
import { ClausesSidebarProvider } from '@modules/reports/common';

//Refetch interval used if there are any contracts in the report data that are reprocessing
//This it to make sure that the user doesn't have to wait a long time to see most recent data
const REPORT_DATA_REPROCESSING_CONTRACTS_REFETCH_INTERVAL = 60000; //1 minute

const ViewReportPage = () => {
    const { id: reportId } = useParams<{ id: string }>();
    const queryClient = useQueryClient();

    const { trackViewReport } = useReportsAnalytics();

    const [showErrorBanner, setShowErrorBanner] = useState(false);

    const { propertiesDictLoading, propertiesDictError, propertiesDict } =
        useLexibleContext();

    //Get report definition hook
    const {
        data: reportDefinition,
        isLoading: reportDefinitionLoading,
        error: reportDefinitionError,
    } = useGetReportDefinition({
        pathParams: { reportDefinitionId: reportId },
    });

    const {
        reportData,
        reportLoading,
        reportError,
        updateReportDataFetchInterval,
    } = useGetReportData(reportId, reportDefinition, propertiesDict);

    //Update report definition hook
    const {
        mutate: updateReportDefinition,
        isLoading: updateReportDefinitionLoading,
        error: updateReportDefinitionError,
    } = useUpdateReportDefinition({});

    // Record view report analytics event
    useEffect(() => {
        if (!reportData) {
            return;
        }
        trackViewReport();
    }, [reportData, trackViewReport]);

    useEffect(() => {
        setShowErrorBanner(false);
    }, [reportId]);

    useEffect(() => {
        if (reportData?.info?.truncated) {
            setShowErrorBanner(true);
        }
    }, [reportData]);

    const sendRequestUpdateReportDefinition = (
        body: ReportDefinitionUpdate
    ) => {
        if (!reportDefinition) {
            return;
        }

        updateReportDefinition(
            {
                pathParams: {
                    reportDefinitionId: reportId,
                },
                body,
            },
            {
                onSuccess: (reportDefinition) => {
                    updateTanStackQueryReportDefinitionsData(
                        queryClient,
                        reportDefinition
                    );
                },
            }
        );
    };

    /**
     * Handler trigerred when any of the config params we're interested in changes
     * It will modify the config based on the provided path and value, and store the updated
     * config object in the database
     */
    const handleConfigChange: ReportConfigChangeHandler = (updatedConfig) => {
        sendRequestUpdateReportDefinition({
            config: updatedConfig,
        });
    };

    const handleReprocessContract = (contractId: string) => {
        if (!reportDefinition || !reportData) {
            return;
        }

        const updatedRow = reportData.rows.find(
            (r) => r.contract_id === contractId
        );
        if (!updatedRow) {
            return;
        }

        updateTanStackQueryReportDataRow(queryClient, reportId, {
            ...updatedRow,
            high_level_contract_status: 'processing',
        });
        updateReportDataFetchInterval(
            REPORT_DATA_REPROCESSING_CONTRACTS_REFETCH_INTERVAL
        );
    };

    const loading =
        reportLoading || reportDefinitionLoading || propertiesDictLoading;
    const error = reportDefinitionError || reportError || propertiesDictError;

    return (
        <ClausesSidebarProvider showContractLink>
            <div className={styles.viewReportPage}>
                {showErrorBanner && (
                    <ErrorBanner onClose={() => setShowErrorBanner(false)} />
                )}
                <Toolbar
                    reportDefinition={reportDefinition}
                    reportUpdateInProgress={updateReportDefinitionLoading}
                    reportUpdateError={updateReportDefinitionError}
                    onNameChange={(name) =>
                        sendRequestUpdateReportDefinition({ name })
                    }
                />
                <div className={styles.grid}>
                    <ErrorBoundary>
                        {loading ? (
                            <LoadingDataGrid />
                        ) : error ? (
                            <ReportViewError />
                        ) : (
                            reportDefinition &&
                            reportData && (
                                <ReportDataGrid
                                    reportDefinition={reportDefinition}
                                    reportData={reportData}
                                    onReprocessContract={
                                        handleReprocessContract
                                    }
                                    onConfigChange={handleConfigChange}
                                />
                            )
                        )}
                    </ErrorBoundary>
                </div>
            </div>
        </ClausesSidebarProvider>
    );
};

export default ViewReportPage;
