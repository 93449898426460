import {
    ADD_PARAGRAPH_HEIGHT_IN_PIXELS,
    IHeatmapPaneActionTypes,
    IHeatmapPaneReducer,
    SET_CONTRACT_SCROLL_HEIGHT_IN_PIXELS,
    SET_CONTRACT_VISIBLE_HEIGHT_IN_PIXELS,
    SET_BANNER_SETTINGS,
    SET_PARAGRAPH_HEIGHTS_IN_PIXELS,
} from './HeatmapPane-types';

const initialState: IHeatmapPaneReducer = {
    contractScrollHeightInPixels: 0,
    contractVisibleHeightInPixels: 0,
    paragraphHeightsInPixels: [],
    bannerSettings: null,
};

const HeatmapPaneReducer = (
    state = initialState,
    action: IHeatmapPaneActionTypes
): IHeatmapPaneReducer => {
    switch (action.type) {
        case ADD_PARAGRAPH_HEIGHT_IN_PIXELS:
            return {
                ...state,
                paragraphHeightsInPixels: [
                    ...state.paragraphHeightsInPixels,
                    action.paragraphHeightInPixels,
                ],
            };
        case SET_CONTRACT_SCROLL_HEIGHT_IN_PIXELS:
            return {
                ...state,
                contractScrollHeightInPixels:
                    action.contractScrollHeightInPixels,
            };
        case SET_CONTRACT_VISIBLE_HEIGHT_IN_PIXELS:
            return {
                ...state,
                contractVisibleHeightInPixels:
                    action.contractVisibleHeightInPixels,
            };
        case SET_PARAGRAPH_HEIGHTS_IN_PIXELS:
            return {
                ...state,
                paragraphHeightsInPixels: action.paragraphHeightsInPixels,
            };
        case SET_BANNER_SETTINGS:
            return {
                ...state,
                bannerSettings: action.settings,
            };
        default:
            return state;
    }
};

export default HeatmapPaneReducer;
