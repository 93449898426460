import {
    IHeatmapSearchBarActionTypes,
    IHeatmapSearchBarReducer,
    SET_MATCHES_COUNT,
    SET_MATCH_INDEX,
    SET_SEARCH_TERM,
} from './HeatmapSearchBar-types';

const initialState: IHeatmapSearchBarReducer = {
    searchTerm: '',
    matchesCount: 0,
    matchIndex: 0,
};

const HeatmapSearchBarReducer = (
    state = initialState,
    action: IHeatmapSearchBarActionTypes
): IHeatmapSearchBarReducer => {
    switch (action.type) {
        case SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.searchTerm,
            };
        case SET_MATCHES_COUNT:
            return {
                ...state,
                matchesCount: action.matchesCount,
            };
        case SET_MATCH_INDEX:
            return {
                ...state,
                matchIndex: action.matchIndex,
            };
        default:
            return state;
    }
};

export default HeatmapSearchBarReducer;
