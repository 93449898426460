import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IRootState } from '../../redux/reducers-types';
import { setSelectAllContractsAction } from '../ContractList/ContractList-actions';
import { setReprocessingContractAction } from '../ContractPollingProvider/ContractPollingProvider-actions';
import { hideDialogAction } from '../ModalDialog/ModalDialog-actions';
import BulkReprocessModal from './BulkReprocessModal';
import {
    IBulkReprocessModalDispatchProps,
    IBulkReprocessModalStateProps,
} from './BulkReprocessModal-types';

function mapStateToProps(state: IRootState): IBulkReprocessModalStateProps {
    return {
        userId: state.auth.userId,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch
): IBulkReprocessModalDispatchProps {
    return {
        closeModal: () => dispatch(hideDialogAction()),
        setReprocessingContract: (
            contractId: string,
            contractName: string,
            versionId: string,
            stream: string,
            streamId: string,
            assessmentCount: number,
            userId: string
        ) =>
            dispatch(
                setReprocessingContractAction(
                    contractId,
                    contractName,
                    versionId,
                    stream,
                    streamId,
                    assessmentCount,
                    userId
                )
            ),
        setSelectAllContracts: (
            selectAll: boolean,
            selectableContractIds?: string[]
        ) =>
            dispatch(
                setSelectAllContractsAction(selectAll, selectableContractIds)
            ),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkReprocessModal);
