import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';

import { IRootState } from '../../redux/reducers-types';
import DeleteVersionModal from './DeleteVersionModal';
import {
    IDeleteVersionModalDispatchProps,
    IDeleteVersionModalStateProps,
} from './DeleteVersionModal-types';
import { hideDialogAction } from '../ModalDialog/ModalDialog-actions';
import { setContractsAction } from '../ContractList/ContractList-actions';
import { IDisplayContract } from '../Contract/Contract-types';
import { updateContract } from '../ContractList/ContractList-helpers';
import {
    setReloadContractAction,
    setVersionsAction,
} from '../Contract/Contract-actions';
import {
    unsetProcessingContractAction,
    unsetReprocessingContractAction,
} from '../ContractPollingProvider/ContractPollingProvider-actions';

function mapStateToProps(state: IRootState): IDeleteVersionModalStateProps {
    return {
        contracts: state.contractList.contracts,
        selectedVersion: state.contract.selectedVersion,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch
): IDeleteVersionModalDispatchProps {
    return {
        closeModal: () => dispatch(hideDialogAction()),
        setContracts: (contracts: IDisplayContract[]) =>
            dispatch(setContractsAction(contracts)),
        setReloadContract: (reload: boolean) =>
            dispatch(setReloadContractAction(reload)),
        updateContract: (
            contractId: string,
            fields: Partial<IDisplayContract>
        ) => updateContract(contractId, fields)(dispatch),
        unsetProcessingContract: (contractId: string) =>
            dispatch(unsetProcessingContractAction(contractId)),
        unsetReprocessingContract: (contractId: string) =>
            dispatch(unsetReprocessingContractAction(contractId)),
        setVersions: (...args) => dispatch(setVersionsAction(...args)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DeleteVersionModal));
