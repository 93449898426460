import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { setSelectedPartyAction } from '../Party/Party-actions';
import { IParty, PartiesType } from '../Parties/Parties-types';
import { IRootState } from '../../redux/reducers-types';
import PartyParagraph from './PartyParagraph';
import {
    IPartyParagraphDispatchProps,
    IPartyParagraphStateProps,
} from './PartyParagraph-types';
import {
    setHighlightedPartyAction,
    setPartyToAddAction,
} from './PartyParagraph-actions';
import {
    setExpandedGroupAction,
    setScrollToPartyAction,
    setDraggedPartyAction,
} from '../Parties/Parties-actions';
import { setHighlightingAction } from '../PartyCorrection/PartyCorrection-actions';

function mapStateToProps(state: IRootState): IPartyParagraphStateProps {
    return {
        expandedGroup: state.parties.expandedGroup,
        highlightedParty: state.parties.highlightedParty,
        highlighting: state.partyCorrection.highlighting,
        partyToAdd: state.parties.partyToAdd,
        selectedParty: state.parties.selectedParty,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IPartyParagraphDispatchProps {
    return {
        setExpandedGroup: (group: PartiesType) =>
            dispatch(setExpandedGroupAction(group)),
        setHighlightedParty: (party: IParty) =>
            dispatch(setHighlightedPartyAction(party)),
        setSelectedParty: (party: IParty) =>
            dispatch(setSelectedPartyAction(party)),
        setPartyToAdd: (partyToAdd: string) =>
            dispatch(setPartyToAddAction(partyToAdd)),
        setScrollToParty: (party: IParty) =>
            dispatch(setScrollToPartyAction(party)),
        setDraggedParty: (party: IParty) =>
            dispatch(setDraggedPartyAction(party)),
        setHighlighting: (highlighting: boolean) =>
            dispatch(setHighlightingAction(highlighting)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartyParagraph);
