import * as React from 'react';
import { Add, Clear, ExpandMore, SwapVert } from '@mui/icons-material';
import { toast } from 'react-toastify';

import { IPartiesProps, PartiesType, IParty } from './Parties-types';
import Party from '../Party/Party-container';
import find from 'lodash/find';

class Parties extends React.Component<IPartiesProps> {
    private ref: React.RefObject<any>;

    constructor(props: IPartiesProps) {
        super(props);

        this.ref = React.createRef();

        this.onCollapseToggle = this.onCollapseToggle.bind(this);
        this.renderCollapseToggle = this.renderCollapseToggle.bind(this);
        this.onFlipAll = this.onFlipAll.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onCollapsedDragOver = this.onCollapsedDragOver.bind(this);
    }

    componentDidUpdate() {
        const { parties, scrollToParty, type, setScrollToParty } = this.props;

        if (scrollToParty) {
            const partyIsInGroup = parties.filter(
                (party) => party.type === type && party.id === scrollToParty.id
            ).length;

            if (partyIsInGroup) {
                this.scrollTo(scrollToParty.id);
                setScrollToParty(null!); // Fixme: null checks
            }
        }
    }

    scrollTo(selectedPartyId: number) {
        const $scrollTarget = document.getElementById(
            `party-${selectedPartyId}`
        );

        if ($scrollTarget) {
            const offsetTop = $scrollTarget.offsetTop;
            const targetOffset = offsetTop - 10;

            if (typeof this.ref.current.scrollTo === 'function') {
                this.ref.current.scrollTo({
                    left: 0,
                    top: targetOffset,
                    behavior: 'smooth',
                });
            } else {
                this.ref.current.scrollTop = targetOffset;
            }
        }
    }

    onCollapseToggle() {
        const expandedGroup =
            this.props.expandedGroup === this.props.type
                ? null
                : this.props.type;
        this.props.setExpandedGroup(expandedGroup!); // Fixme: null checks
    }

    async onDrop(event: any) {
        const {
            draggedParty,
            parties,
            type,
            updateParty,
            setSelectedParty,
            setScrollToParty,
            addParty,
        } = this.props;

        event.preventDefault();

        const party = draggedParty && find(parties, { id: draggedParty.id });

        if (party) {
            party.type = type;
            await updateParty(party);
            await setSelectedParty(party);
            await setScrollToParty(party);
        } else if (draggedParty) {
            await addParty(draggedParty.name, type);
        }
    }

    onDragOver(event: any) {
        event.preventDefault();
    }

    onCollapsedDragOver(event: any) {
        const { draggedParty, type, expandedGroup, setExpandedGroup } =
            this.props;

        event.preventDefault();

        if (draggedParty && expandedGroup !== type) {
            setExpandedGroup(type);
        }
    }

    onFlipAll(typeA: PartiesType, typeB: PartiesType) {
        this.props.flipGroups(typeA, typeB);
    }

    async onAddParty(partyToAdd: string, type: PartiesType, parties: IParty[]) {
        if (find(parties, { name: partyToAdd })) {
            toast.warn('This party already exists');
        } else {
            await this.props.addParty(partyToAdd, type);
        }
    }

    renderPartiesOverlay(title: string, type: PartiesType) {
        const { partyToAdd, parties } = this.props;

        return (
            <div
                className="parties-overlay droppable"
                onClick={() => {
                    this.onAddParty(partyToAdd, type, parties);
                }}
                onDragOver={this.onDragOver}
                onDrop={this.onDrop}
            >
                <div className="overlay-content">
                    <Add className="overlay-add-icon" />
                    <span className="overlay-text">Add to {title}</span>
                </div>
            </div>
        );
    }

    renderCollapseToggle(expandedGroup: PartiesType, type: PartiesType) {
        const isExpanded = expandedGroup === type;

        return (
            <div
                className={`collapse-toggle ${isExpanded ? 'collapse' : ''}`}
                onClick={this.onCollapseToggle}
            >
                {isExpanded ? (
                    <Clear className="toggle-icon" />
                ) : (
                    <ExpandMore className="toggle-icon" />
                )}
            </div>
        );
    }

    renderFlipAll(type: PartiesType, flipWith: PartiesType) {
        return (
            <div
                className="flip-all-btn"
                onClick={() => {
                    this.onFlipAll(type, flipWith);
                }}
            >
                <span className="pseudo-link">Flip all</span>{' '}
                <SwapVert className="flip-all-icon" />
            </div>
        );
    }

    render() {
        const {
            title,
            subtitle,
            collapsible,
            flipWith,
            expandedGroup,
            type,
            parties,
            prevType,
            nextType,
            discardTo,
            restoreByDefault,
            partyToAdd,
            compact,
            draggedParty,
        } = this.props;

        const classList =
            `parties-group ${type} ` +
            `${collapsible ? 'collapsible' : ''} ` +
            `${expandedGroup === type ? 'expanded' : ''} ` +
            `${
                expandedGroup && expandedGroup !== type ? 'hide-on-mobile' : ''
            } ` +
            `${compact ? 'compact' : ''}`;

        const filteredParties = parties.filter((party) => party.type === type);

        const isValidDropzone =
            draggedParty && !find(filteredParties, { id: draggedParty.id });

        if (collapsible && !filteredParties.length) {
            if (expandedGroup === type) {
                this.props.setExpandedGroup(null!); // Fixme: null checks
            }
            return null;
        }

        return (
            <div className={classList} onDragOver={this.onCollapsedDragOver}>
                <div className="parties-title">{title}</div>
                <div className="parties-subtitle">{subtitle}</div>
                <div className="parties-content">
                    <ul className="parties-list" ref={this.ref}>
                        {filteredParties.map((party) => (
                            <Party
                                party={party}
                                prevType={prevType}
                                nextType={nextType}
                                discardTo={discardTo}
                                restoreByDefault={restoreByDefault}
                                key={`party-${party.id}`}
                            />
                        ))}
                    </ul>
                    {flipWith ? this.renderFlipAll(type, flipWith) : null}
                    {partyToAdd || isValidDropzone
                        ? this.renderPartiesOverlay(title, type)
                        : null}
                </div>
                {this.renderCollapseToggle(expandedGroup, type)}
            </div>
        );
    }
}

export default Parties;
