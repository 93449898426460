import { Component } from 'react';

import { ILinkedClausesDropdownProps } from './LinkedClausesDropdown-types';
import { mapClauseNumber } from '../App/App-mappers';
import './LinkedClausesDropdown.scss';
import { IContractParagraph } from '../Paragraph/Paragraph-types';
import { Select, SelectOption } from '@thought-river/ui-components';
import { ParagraphClause } from '@thought-river/negotiations-common';

export default class LinkedClausesDropdown extends Component<ILinkedClausesDropdownProps> {
    constructor(props: ILinkedClausesDropdownProps) {
        super(props);

        this.mapParagraphToClauseOption =
            this.mapParagraphToClauseOption.bind(this);
        this.onClickSelectedClause = this.onClickSelectedClause.bind(this);
        this.onSelectClauseOption = this.onSelectClauseOption.bind(this);
        this.sortClauseNumbers = this.sortClauseNumbers.bind(this);
    }

    private sortClauseNumbers(
        clauseA: ParagraphClause,
        clauseB: ParagraphClause
    ) {
        return clauseA.index - clauseB.index;
    }

    private onClickSelectedClause(option: SelectOption) {
        const { onClickSelectedClause } = this.props;

        onClickSelectedClause?.(option.value);
    }

    private async onSelectClauseOption(options: SelectOption[]) {
        const { paragraphs, onSelectOption } = this.props;

        const newValues = options.map((o) => o.value);
        const newClauseNumbers = paragraphs
            .filter((p) => newValues.includes(p.originUuid))
            .map(mapClauseNumber);

        onSelectOption(newClauseNumbers.sort(this.sortClauseNumbers));
    }

    private mapParagraphToClauseOption(
        paragraph: IContractParagraph
    ): SelectOption {
        return {
            label: paragraph.text.slice(0, 75),
            tagLabel: paragraph.clauseNumber,
            labelPrefix: paragraph.clauseNumber.includes('Para')
                ? paragraph.clauseNumber
                : '',
            value: paragraph.originUuid,
        };
    }

    private getSelectedClauseOptions(): SelectOption[] {
        const { selectedClauseNumbers, paragraphs } = this.props;

        const selectedClauseOptions: SelectOption[] = [];
        const selectedOriginUuids = selectedClauseNumbers.map(
            (clause) => clause.originUuid
        );

        for (const paragraph of paragraphs) {
            if (selectedOriginUuids.includes(paragraph.originUuid)) {
                selectedClauseOptions.push(
                    this.mapParagraphToClauseOption(paragraph)
                );
            }
        }

        return selectedClauseOptions;
    }

    render() {
        const { onCloseCallback, paragraphs } = this.props;

        return (
            <Select
                className="issue-linked-clauses-dropdown"
                data-testid="issue-linked-clauses-dropdown"
                label="Linked Clauses"
                multiple
                limitTags={6}
                onClose={onCloseCallback}
                options={paragraphs.map(this.mapParagraphToClauseOption)}
                onChange={this.onSelectClauseOption}
                placeholder="Search or select contract clauses"
                value={this.getSelectedClauseOptions()}
                onChipClick={this.onClickSelectedClause}
                disableCloseOnSelect
            />
        );
    }
}
