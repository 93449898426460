import { IFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import { PresetIssueFilters } from '../IssueListFilters/IssueListFilters-types';

export enum IssueViewsContext {
    CONTRACT = 'CONTRACT',
    REPORT = 'REPORT',
}

export interface IIssueViewsDropdownState {}

export interface IIssueViewsDropdownStateProps {}

export interface IIssueViewsDropdownReducer {}

export interface IIssueViewsDropdownDispatchProps {}

export interface IIssueViewsDropdownProps
    extends IIssueViewsDropdownDispatchProps,
        IIssueViewsDropdownStateProps {
    context: IssueViewsContext;
    featureToggles: IFeatureToggle[];
    selectedView: keyof typeof PresetIssueFilters | '';
    onSelectViewCallback: (option: keyof typeof PresetIssueFilters) => void;
}

export type IIssueViewsDropdownActionTypes = any;
