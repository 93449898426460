import {
    ADD_PROCESSING_CONTRACT,
    IAddProcessingContractAction,
    IProcessingContract,
    IUnsetProcessingContractAction,
    UNSET_PROCESSING_CONTRACT,
    SET_REPROCESSING_CONTRACT,
    UNSET_REPROCESSING_CONTRACT,
    ISetReprocessingContractAction,
    IUnsetReprocessingContractAction,
    ISetTimedOutProcessingContractIdAction,
    SET_TIMED_OUT_PROCESSING_CONTRACT_ID,
    ISetTimedOutProcessingContractIdsAction,
    SET_TIMED_OUT_PROCESSING_CONTRACT_IDS,
    ISetPollingProcessingContractAction,
    SET_POLLING_PROCESSING_CONTRACTS,
    ISetPollingReprocessingContractAction,
    SET_POLLING_REPROCESSING_CONTRACTS,
    IReprocessingContract,
    ISetFailedReprocessedContractAction,
    SET_FAILED_REPROCESSED_CONTRACT,
    ISetFailedProcessedContractAction,
    SET_FAILED_PROCESSED_CONTRACT,
    SET_SUCCESSFUL_PROCESSED_CONTRACT,
    ISetSuccessfulProcessedContractAction,
    SET_SUCCESSFUL_REPROCESSED_CONTRACT,
    ISetSuccessfulReprocessedContractAction,
    UNSET_NOTIFIED_CONTRACT,
    IUnsetNotifiedContractAction,
    IUpdateProcessingContract,
    UPDATE_PROCESSING_CONTRACT,
    IUnsetSuccessfulReprocessedContractAction,
    UNSET_SUCCESSFUL_REPROCESSED_CONTRACT,
} from './ContractPollingProvider-types';

export function unsetProcessingContractAction(
    contractId: string
): IUnsetProcessingContractAction {
    return {
        type: UNSET_PROCESSING_CONTRACT,
        contractId,
    };
}

export function addProcessingContractAction(
    contract: IProcessingContract
): IAddProcessingContractAction {
    return {
        type: ADD_PROCESSING_CONTRACT,
        contract,
    };
}

export function setReprocessingContractAction(
    contractId: string,
    contractName: string,
    versionId: string,
    stream: string,
    streamId: string,
    assessmentCount: number,
    userId: string
): ISetReprocessingContractAction {
    return {
        type: SET_REPROCESSING_CONTRACT,
        contractId,
        contractName,
        versionId,
        stream,
        streamId,
        assessmentCount,
        userId,
    };
}

export function unsetReprocessingContractAction(
    contractId: string
): IUnsetReprocessingContractAction {
    return {
        type: UNSET_REPROCESSING_CONTRACT,
        contractId,
    };
}

export function setTimedOutProcessingContractIdAction(
    timedOutProcessingContractId: string
): ISetTimedOutProcessingContractIdAction {
    return {
        type: SET_TIMED_OUT_PROCESSING_CONTRACT_ID,
        timedOutProcessingContractId,
    };
}

export function setTimedOutProcessingContractIdsAction(
    timedOutProcessingContractIds: string[]
): ISetTimedOutProcessingContractIdsAction {
    return {
        type: SET_TIMED_OUT_PROCESSING_CONTRACT_IDS,
        timedOutProcessingContractIds,
    };
}

export function setPollingProcessingAction(
    pollingProcessing: boolean
): ISetPollingProcessingContractAction {
    return {
        type: SET_POLLING_PROCESSING_CONTRACTS,
        pollingProcessing,
    };
}

export function setPollingReprocessingAction(
    pollingReprocessing: boolean
): ISetPollingReprocessingContractAction {
    return {
        type: SET_POLLING_REPROCESSING_CONTRACTS,
        pollingReprocessing,
    };
}

export function setFailedReprocessedContractAction(
    contract: IReprocessingContract
): ISetFailedReprocessedContractAction {
    return {
        type: SET_FAILED_REPROCESSED_CONTRACT,
        contract,
    };
}

export function setFailedProcessedContractAction(
    contract: IProcessingContract
): ISetFailedProcessedContractAction {
    return {
        type: SET_FAILED_PROCESSED_CONTRACT,
        contract,
    };
}

export function setSuccessfulReprocessedContractAction(
    contract: IReprocessingContract,
    isPartiallyAssessed: boolean
): ISetSuccessfulReprocessedContractAction {
    return {
        type: SET_SUCCESSFUL_REPROCESSED_CONTRACT,
        contract,
        isPartiallyAssessed,
    };
}

export function unsetSuccessfulReprocessedContractAction(
    contractId: string
): IUnsetSuccessfulReprocessedContractAction {
    return {
        type: UNSET_SUCCESSFUL_REPROCESSED_CONTRACT,
        contractId,
    };
}

export function setSuccessfulProcessedContractAction(
    contract: IProcessingContract,
    isPartiallyAssessed: boolean
): ISetSuccessfulProcessedContractAction {
    return {
        type: SET_SUCCESSFUL_PROCESSED_CONTRACT,
        contract,
        isPartiallyAssessed,
    };
}

export function unsetNotifiedContractAction(
    contractId: string
): IUnsetNotifiedContractAction {
    return {
        type: UNSET_NOTIFIED_CONTRACT,
        contractId,
    };
}

export function updateProcessingContractAction(
    contract: IProcessingContract
): IUpdateProcessingContract {
    return {
        type: UPDATE_PROCESSING_CONTRACT,
        contract,
    };
}
