import { Action } from 'redux';
import {
    IDisplayContract,
    IVersionBusinessStatus,
} from '../Contract/Contract-types';
import { IFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import { IModalDialogButton } from '../ModalDialog/ModalDialog-types';
import { ToggleElement } from '../ToggleBar/ToggleBar-types';

export const SET_BULK_ACTIONS = 'SET_BULK_ACTIONS';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SWITCH_TOGGLE = 'SWITCH_TOGGLE';

export interface IContractListToolbarState {
    showUploadContractModal: boolean;
}
export interface IContractListToolbarStateProps {
    bulkActions: boolean;
    businessStatuses: IVersionBusinessStatus[] | null;
    searchTerm: string;
    contracts: IDisplayContract[];
    selectedContractIds: string[];
    toggles: ToggleElement[];
    featureToggles: IFeatureToggle[];
}

export interface IContractListToolbarDispatchProps {
    loadContracts: (page?: number) => void;
    setRequestedPages: (requestedPages: number[]) => void;
    setSearchTerm: (value: string) => void;
    switchToggle: (toggle: ToggleElement) => void;
    setBulkActions: (bulkActions: boolean) => void;
    showDialog: (
        title: string | JSX.Element,
        showCloseButton: boolean,
        component: JSX.Element,
        buttons: IModalDialogButton[],
        height: string | null | undefined,
        customClassname?: string,
        closeOnBlur?: boolean
    ) => void;
    setSelectAllContracts: (selectAll: boolean) => void;
}

export interface IContractListToolbarProps
    extends IContractListToolbarDispatchProps,
        IContractListToolbarStateProps {}

export interface IContractListToolbarReducer {
    bulkActions: boolean;
    searchTerm: string;
    toggles: ToggleElement[];
}

export interface ISwitchToggleAction extends Action {
    type: typeof SWITCH_TOGGLE;
    toggle: ToggleElement;
}

export interface ISetSearchTermAction extends Action {
    type: typeof SET_SEARCH_TERM;
    searchTerm: string;
}

export interface ISetBulkActionsAction extends Action {
    type: typeof SET_BULK_ACTIONS;
    bulkActions: boolean;
}

export type IContractListToolbarActionTypes =
    | ISwitchToggleAction
    | ISetSearchTermAction
    | ISetBulkActionsAction;
