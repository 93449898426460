import { Replay } from '@mui/icons-material';
import {
    Icon,
    IconButton,
    OverflowMenu,
    OverflowMenuItem,
} from '@thought-river/ui-components';
import ContractResource from '../../../../resources/ContractResource';

export const REPROCESSING_CONTRACTS_LIMIT = 30;

interface SimplifiedContractMenuProps {
    contractId: string;
    dealType: string;
    isContractReprocessing: boolean;
    reprocessingContractsCount: number;
    onReprocessContract: () => void;
}

//This is a temporary ContractMenu to be used in portfolio reports ontaining only the reprocess contract option
//If we want the full contract menu in portfolio reports we should refactor ContractMenu and use that instead
const SimplifiedContractMenu = ({
    contractId,
    dealType,
    onReprocessContract,
    isContractReprocessing,
    reprocessingContractsCount,
}: SimplifiedContractMenuProps) => {
    const reprocessingContractsLimitReached =
        reprocessingContractsCount >= REPROCESSING_CONTRACTS_LIMIT;
    const reprocessContractDisabled =
        reprocessingContractsLimitReached || isContractReprocessing;

    const tooltipTitle = (() => {
        if (isContractReprocessing) {
            return 'This contract is already undergoing analysis.';
        }
        if (reprocessingContractsLimitReached) {
            return 'You’ve reached the maximum number of contracts. Wait for a review to complete and try again.';
        }
    })();

    const menuItems: OverflowMenuItem[][] = [
        [
            {
                label: 'Reanalyze Contract',
                disabled: reprocessContractDisabled,
                icon: <Replay />,
                onClick: () => {
                    ContractResource.reprocess([contractId], dealType);
                    onReprocessContract();
                },
                tooltipTitle: tooltipTitle,
            },
        ],
    ];

    return (
        <OverflowMenu
            button={
                <IconButton size="small">
                    <Icon type="more-vert" />
                </IconButton>
            }
            menuItems={menuItems}
        />
    );
};

export default SimplifiedContractMenu;
