import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';

import { IRootState } from '../../redux/reducers-types';
import SummaryPane from './SummaryPane';
import {
    ISummaryPaneDispatchProps,
    ISummaryPaneStateProps,
    ISummaryProperty,
} from './SummaryPane-types';
import {
    setHighlightedParagraphs,
    setHighlightedTextAction,
    setPropertyTemplatesAction,
    setSelectedContractIntelligenceAction,
    setSelectedPropertiesTemplateAction,
    setSelectedPropertyCodeAction,
    setSummaryPropertiesAction,
} from '../Contract/Contract-actions';
import { IBannerSettings } from '../HeatmapPane/HeatmapPane-types';
import { setBannerSettingsAction } from '../HeatmapPane/HeatmapPane-actions';
import { ISelectedContractIntelligence } from '../Contract/Contract-types';
import { SummaryPanelTemplateOut } from '@thought-river/negotiations-common/dist/api/playbookManager';
import { IContractParagraph } from '../Paragraph/Paragraph-types';

function mapStateToProps(state: IRootState): ISummaryPaneStateProps {
    return {
        assessment: state.contract.assessment,
        contract: state.contract.contract,
        highlightedText: state.contract.highlightedText,
        featureToggles: state.featureToggles.toggles,
        issues: state.contract.issues,
        propertyTemplatesLoaded: state.contract.propertyTemplatesLoaded,
        lexibleProperties: state.app.properties,
        paragraphs: state.contract.paragraphs,
        properties: state.contract.properties,
        propertyTemplates: state.contract.propertyTemplates,
        policies: state.contract.policies,
        selectedContractIntelligence:
            state.contract.selectedContractIntelligence,
        selectedPropertiesTemplate: state.contract.selectedPropertiesTemplate,
        selectedPropertyCode: state.contract.selectedPropertyCode,
        selectedVersion: state.contract.selectedVersion,
        summaryProperties: state.contract.summaryProperties,
        themes: state.app.themes,
        users: state.auth.users,
        width: state.paneDivider.sidebarWidth,
        widthPixels: state.contractSidebar.widthPixels,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): ISummaryPaneDispatchProps {
    return {
        setBannerSettings: (settings: IBannerSettings) =>
            dispatch(setBannerSettingsAction(settings)),
        setHighlightedText: (text: string) =>
            dispatch(setHighlightedTextAction(text)),
        setHighlightedParagraphs: (paragraphs: IContractParagraph[]) =>
            dispatch(setHighlightedParagraphs(paragraphs)),
        setPropertyTemplates: (propertyTemplates: SummaryPanelTemplateOut[]) =>
            dispatch(setPropertyTemplatesAction(propertyTemplates)),
        setSelectedContractIntelligence: (
            intelligence: ISelectedContractIntelligence
        ) => dispatch(setSelectedContractIntelligenceAction(intelligence)),
        setSelectedPropertiesTemplate: (template: SummaryPanelTemplateOut) =>
            dispatch(setSelectedPropertiesTemplateAction(template)),
        setSelectedPropertyCode: (code: string) =>
            dispatch(setSelectedPropertyCodeAction(code)),
        setSummaryProperties: (properties: ISummaryProperty[]) =>
            dispatch(setSummaryPropertiesAction(properties)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SummaryPane));
