import {
    IFeatureToggle,
    ISetFeatureTogglesAction,
    SET_FEATURE_TOGGLES,
} from './FeatureToggleProvider-types';

export function setFeatureTogglesAction(
    toggles: IFeatureToggle[]
): ISetFeatureTogglesAction {
    return {
        type: SET_FEATURE_TOGGLES,
        toggles,
    };
}
