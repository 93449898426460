import { GridValueGetterParams } from '@mui/x-data-grid-premium';
import dayjs from 'dayjs';
import { ContractStatusFilterOption } from '../../types';

export const valueGetter = (params: GridValueGetterParams) => {
    if (params.field === 'duration') {
        return params.row.upload_date_epoch;
    }

    if (params.field === 'progress') {
        const resolvedIssues = params.row.issues_resolved;
        const totalIssues = params.row.issues_total;
        const resolvedRatio =
            totalIssues === 0 ? 100 : (100 / totalIssues) * resolvedIssues;
        return resolvedRatio;
    }

    if (params.field === 'version') {
        return params.row.version.number;
    }

    if (params.colDef.type === 'date' || params.colDef.type === 'dateTime') {
        return dayjs(params.value).toDate();
    }

    if (params.field === 'owner') {
        return params.value.name;
    }

    if (params.field === 'reviewers') {
        return Array.isArray(params.value)
            ? params.value.map((reviewer) => reviewer.name)
            : params.value;
    }

    if (params.field === 'deal_type') {
        return params.value.name;
    }

    if (params.field === 'status') {
        const statuses = [];

        if (params.row.is_signed) {
            statuses.push(ContractStatusFilterOption.Signed);
        }

        if (params.row.is_template) {
            statuses.push(ContractStatusFilterOption.Template);
        }

        return statuses;
    }

    return params.value;
};
