import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import IssuesReportEditColumnsModal from './IssuesReportEditColumnsModal';
import { IRootState } from '../../../redux/reducers-types';
import {
    IIssuesReportEditColumnsModalDispatchProps,
    IIssuesReportEditColumnsModalStateProps,
} from './IssuesReportEditColumnsModal-types';
import { setIssuesTableColumnsAction } from '../IssuesReport-actions';
import { IIssuesTableColumn } from '../IssuesReport-types';
import { hideDialogAction } from '../../ModalDialog/ModalDialog-actions';
import { setSelectedFilterPresetAction } from '../../IssueListFilters/IssueListFilters-actions';
import { PresetIssueFilters } from '../../IssueListFilters/IssueListFilters-types';

function mapStateToProps(
    state: IRootState
): IIssuesReportEditColumnsModalStateProps {
    return {
        columns: state.issuesReport.columns,
        featureToggles: state.featureToggles.toggles,
        selectedFilterPreset: state.issueListFilters.selectedFilterPreset,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IIssuesReportEditColumnsModalDispatchProps {
    return {
        closeModal: () => dispatch(hideDialogAction()),
        setColumns: (columns: IIssuesTableColumn[]) =>
            dispatch(setIssuesTableColumnsAction(columns)),
        setSelectedFilterPreset: (
            selectedFilterPreset: keyof typeof PresetIssueFilters | ''
        ) => dispatch(setSelectedFilterPresetAction(selectedFilterPreset)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IssuesReportEditColumnsModal);
