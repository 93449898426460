import { Component } from 'react';

import {
    IDropdownGroupProps,
    IDropdownGroupState,
    IDropdownInfo,
} from './DropdownGroup-types';
import Dropdown from './Dropdown/Dropdown';
import { IDropdownOption } from './Dropdown/Dropdown-types';
import { Add, Delete } from '@mui/icons-material';
import './DropdownGroup.scss';

class DropdownGroup extends Component<
    IDropdownGroupProps,
    IDropdownGroupState
> {
    constructor(props: IDropdownGroupProps) {
        super(props);

        const { selectedIds } = this.props;

        this.state = {
            dropdowns: selectedIds.length
                ? selectedIds.map((selectedId, index) => ({
                      dropdownId: index,
                      selectedId,
                  }))
                : [this.getDefaultDropdown(0)],
        };

        this.addDropdown = this.addDropdown.bind(this);
        this.onSelectOption = this.onSelectOption.bind(this);
    }

    getDefaultDropdown(index: number): IDropdownInfo {
        return {
            dropdownId: index,
            selectedId: null!, // Fixme: null checks
        };
    }

    removeDropdown(dropdownId: number) {
        const dropdowns = [...this.state.dropdowns].filter(
            (dropdown) => dropdown.dropdownId !== dropdownId
        );

        this.setState({
            dropdowns,
        });

        const selectedIds = dropdowns.map((dropdown) => dropdown.selectedId);

        this.props.onSelectCallback(selectedIds);
    }

    addDropdown() {
        const dropdowns = [...this.state.dropdowns];

        dropdowns.push(this.getDefaultDropdown(dropdowns.length));

        this.setState({
            dropdowns,
        });
    }

    onSelectOption(option: IDropdownOption<string>, dropdownId: number) {
        const dropdowns = [...this.state.dropdowns];
        const selectedIds = [];

        for (const [index, dropdown] of dropdowns.entries()) {
            if (dropdown.dropdownId === dropdownId) {
                dropdowns[index] = { ...dropdown, selectedId: option.value };
            }
            selectedIds.push(dropdowns[index].selectedId);
        }

        this.setState({
            dropdowns,
        });

        this.props.onSelectCallback(selectedIds);
    }

    render() {
        const { dropdowns } = this.state;
        const { options, placeholder, className, selectedIds } = this.props;

        const optionsRemaining = [...options].filter(
            (option) => !selectedIds.includes(option.value)
        ).length;
        let selectedId: string;

        return (
            <div className="dropdown-group">
                <div className="dropdowns">
                    {dropdowns.map((dropdown, index) => {
                        selectedId = dropdown.selectedId;
                        return (
                            <div
                                className="dropdown-wrapper"
                                key={`dropdown-wrapper-${index}`}
                            >
                                <Dropdown
                                    onSelectCallback={(
                                        option: IDropdownOption<string>
                                    ) =>
                                        this.onSelectOption(
                                            option,
                                            dropdown.dropdownId
                                        )
                                    }
                                    options={options.filter(
                                        (option) =>
                                            !selectedIds.includes(option.value)
                                    )}
                                    selectedOption={
                                        selectedId
                                            ? options.filter(
                                                  (option) =>
                                                      option.value ===
                                                      selectedId
                                              )[0]
                                            : null! // Fixme: null checks
                                    }
                                    placeholder={placeholder}
                                    className={className}
                                    searchPlaceholder=""
                                    searchable={false}
                                />
                                {dropdowns.length > 1 ? (
                                    <Delete
                                        className="delete-dropdown-icon"
                                        onClick={() =>
                                            this.removeDropdown(
                                                dropdown.dropdownId
                                            )
                                        }
                                    />
                                ) : null}
                            </div>
                        );
                    })}
                </div>
                {optionsRemaining &&
                optionsRemaining > dropdowns.length - selectedIds.length ? (
                    <span
                        className="add-dropdown-label"
                        onClick={this.addDropdown}
                    >
                        <Add className="dropdown-add-icon" />
                        <span className="dropdown-add-label">Another</span>
                    </span>
                ) : null}
            </div>
        );
    }
}

export default DropdownGroup;
