import {
    ISetBulkActionsAction,
    ISetSearchTermAction,
    ISwitchToggleAction,
    SET_BULK_ACTIONS,
    SET_SEARCH_TERM,
    SWITCH_TOGGLE,
} from './ContractListToolbar-types';
import { ToggleElement } from '../ToggleBar/ToggleBar-types';

export function switchToggleAction(toggle: ToggleElement): ISwitchToggleAction {
    return {
        type: SWITCH_TOGGLE,
        toggle,
    };
}

export function setSearchTermAction(searchTerm: string): ISetSearchTermAction {
    return {
        type: SET_SEARCH_TERM,
        searchTerm,
    };
}

export function setBulkActionsAction(
    bulkActions: boolean
): ISetBulkActionsAction {
    return {
        type: SET_BULK_ACTIONS,
        bulkActions,
    };
}
