import { analyticsService } from '../../services/Analytics/Analytics';
import {
    EVENT_ACTION_DOWNLOAD_CONTRACT_PRIMARY,
    EVENT_ACTION_INITIATE_SET_PARTIES_FLOW,
    EVENT_ACTION_SIGN,
    EVENT_ACTION_UNSIGN,
} from '../../services/Analytics/Analytics-types';
import { IDisplayContract } from '../Contract/Contract-types';

export const useAnalytics = (contract: IDisplayContract) => {
    const payload = {
        ContractId: contract?.id,
        VersionId: contract?.latestVersion?.id,
        VersionNumber: contract?.latestVersion?.versionNumber,
    };

    const trackDownloadClick = () => {
        analyticsService.recordEvent(
            EVENT_ACTION_DOWNLOAD_CONTRACT_PRIMARY,

            payload
        );
    };

    const trackEditPartiesClick = () => {
        analyticsService.recordEvent(
            EVENT_ACTION_INITIATE_SET_PARTIES_FLOW,

            payload
        );
    };

    const trackSignContractClick = () => {
        analyticsService.recordEvent(EVENT_ACTION_SIGN, payload);
    };

    const trackUnsignContractClick = () => {
        analyticsService.recordEvent(
            EVENT_ACTION_UNSIGN,

            payload
        );
    };

    return {
        trackDownloadClick,
        trackEditPartiesClick,
        trackSignContractClick,
        trackUnsignContractClick,
    };
};
