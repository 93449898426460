import { ContractIDs } from '@thought-river/negotiations-common/dist/api/contractContent';
import { ListItem } from '../ListItem';

interface VersionIdsListProps {
    contractIds: ContractIDs[];
    versionUuid: string;
}

const VersionIdsList = ({ contractIds, versionUuid }: VersionIdsListProps) => {
    const versionIds = contractIds?.find(
        (ids) => ids.df_client.deal_versions.version_uuid === versionUuid
    );
    return (
        <>
            <ListItem
                name="Contract ID"
                value={versionIds?.df_client.deal_files.contract_id}
            />
            <ListItem
                name="Document ID"
                value={versionIds?.df_client.deal_files.document_id}
            />
            <ListItem
                name="Kernel ID"
                value={versionIds?.df_client.deal_files.kernel_id}
            />
            <ListItem
                name="Version ID"
                value={versionIds?.df_client.deal_files.version_id}
            />
            <ListItem
                name="Contract UUID"
                value={versionIds?.df_client.deals.contract_uuid}
            />
            <ListItem
                name="Document UUID"
                value={versionIds?.df_client.deal_files.document_uuid}
            />
            <ListItem
                name="Kernel UUID"
                value={versionIds?.kernel_api?.kernelapi_contract.kernel_uuid}
            />
            <ListItem
                name="Version UUID"
                value={versionIds?.df_client.deal_versions.version_uuid}
            />
            <ListItem
                name="Elastic UUID"
                value={
                    versionIds?.frequency_analysis?.contracts_to_process
                        .elastic_uuid
                }
            />
        </>
    );
};

export default VersionIdsList;
