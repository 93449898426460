import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { IRootState } from '../../redux/reducers-types';
import { IAccountUser } from '../Auth/Auth-types';
import { setReviewedParagraphsAction } from '../Contract/Contract-actions';
import { hideDialogAction } from '../ModalDialog/ModalDialog-actions';
import InternalSharingModal from './InternalSharingModal';
import {
    resetInternalSharingFiltersAction,
    setInternalSharingMessageAction,
    setInternalSharingReviewersAction,
} from './InternalSharingModal-actions';
import {
    IInternalSharingModalDispatchProps,
    IInternalSharingModalStateProps,
} from './InternalSharingModal-types';

function mapStateToProps(state: IRootState): IInternalSharingModalStateProps {
    return {
        contract: state.contract.contract,
        paragraphs: state.contract.paragraphs,
        versions: state.contract.versions,
        selectedVersion: state.contract.selectedVersion,
        message: state.internalSharing.message,
        users: state.auth.users,
        userId: state.auth.userId,
        reviewers: state.internalSharing.reviewers,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IInternalSharingModalDispatchProps {
    return {
        closeModal: () => dispatch(hideDialogAction()),
        resetInternalSharingFilters: () =>
            dispatch(resetInternalSharingFiltersAction()),
        setInternalSharingMessage: (message: string) =>
            dispatch(setInternalSharingMessageAction(message)),
        setInternalSharingReviewers: (reviewers: IAccountUser[]) =>
            dispatch(setInternalSharingReviewersAction(reviewers)),
        setReviewedParagraphs: (
            reviewed: boolean,
            paragraphIndices: number[]
        ) => dispatch(setReviewedParagraphsAction(reviewed, paragraphIndices)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InternalSharingModal);
