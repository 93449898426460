import { Action } from 'redux';
import { ParagraphCommonality } from 'types/thoughtriver';
import { IReviewStream } from '../../resources/AuthResource-types';
import { DocumentOcrMode } from '../../resources/DocumentResource-types';
import { IAccountUser } from '../Auth/Auth-types';
import { IVersionBusinessStatus } from '../Contract/Contract-types';
import { IFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import { IIssueCategory } from '../IssueListPane/IssueListPane-types';
import {
    FamiliarityLevel,
    LexibleTheme,
} from '@thought-river/negotiations-common';

export const APP_NAME = 'Negotiations';
export const DASHBOARD_ROUTE = '/';
export const SET_PROPERTY_DETAILS_LOGIN_COUNT =
    'SET_PROPERTY_DETAILS_LOGIN_COUNT';
export const SET_BUSINESS_STATUSES = 'SET_BUSINESS_STATUSES';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_DEFAULT_OCR_MODE = 'SET_DEFAULT_OCR_MODE';
export const SET_DEFAULT_STREAM = 'SET_DEFAULT_STREAM';
export const SET_GENERIC_THEMES = 'SET_GENERIC_THEMES';
export const SET_LEXIBLE_PROPERTIES = 'SET_LEXIBLE_PROPERTIES';
export const EXTERNAL_COMMENTS = 'External Comments';
export const INTERNAL_GUIDANCE = 'Internal Guidance';
export const SUGGESTION = 'Drafting Option';
export const SUGGESTIONS = 'Drafting Options';
export const SUGGESTIONS_SUGGESTED_TEXT =
    'Drafting options are not available for manually created issues';
export const NONE_IDENTIFIED_OPTION = 'None Identified';

export const AppMenuItem = {
    HOME: 'Negotiations',
    USERS: 'Users',
    API_KEYS: 'API Keys',
    ORGANIZATIONS: 'Organizations',
    REFERENCE_NAME_TEMPLATES: 'Reference Name Templates',
    PLAYBOOK: 'Playbook Management',
    AUTHORING: 'Authoring',
    TRAINING: 'Training',
    POLICIES: 'Policies',
    SUGGESTIONS,
    REVIEW_STREAMS: 'Review Streams',
    LEXIBLE_CONTRACT_TYPES: 'Lexible Contract Types',
    PORTFOLIOS: 'Portfolios',
    KNOWLEDGE_BASE: 'Knowledge Base',
    TOUR: 'Take A Tour',
    HELP: 'Get Help',
};
export interface ILexibleProperty {
    code: string;
    id: string;
    description: string;
    themeId: string;
    themeName: string;
    name: string;
    parentId: string | null;
    valueType: ValueType;
    value?: string;
    shortLabel: string;
}

export enum ValueType {
    DATE = 'date',
    DISCRETE = 'discrete',
    DURATION = 'duration',
    INTEGER = 'integer',
    LOCATION = 'location',
    MONEY = 'money',
    PERCENT = 'percent',
    TEXT = 'text',
    YES_NO_CAT = 'yesno_cat',
}

export enum InputType {
    TEXT = 'text',
    DROPDOWN = 'dropdown',
    SEARCHABLE_DROPDOWN = 'searchable_dropdown',
    DATE = 'date',
}

export enum AppContext {
    DASHBOARD = 'dashboard',
    HEATMAP = 'heatmap',
}

export const getFamiliarityLevel = (
    commonality: ParagraphCommonality | undefined
) => {
    switch (commonality) {
        case 'neverSeenBefore':
            return FamiliarityLevel.UNFAMILIAR;
        case 'uncommon':
            return FamiliarityLevel.UNCOMMON;
        case 'common':
            return FamiliarityLevel.COMMON;
        case 'template':
            return FamiliarityLevel.TEMPLATE;
    }
};

export enum OperatingSystem {
    ANDROID = 'android',
    BLACKBERRY = 'blackberry',
    IPAD = 'ipad',
    IPHONE = 'iphone',
    IPOD = 'ipod',
    LINUX = 'linux',
    MAC = 'mac',
    WINDOWS = 'windows',
}

export enum MenuDirection {
    left = 'left',
    top = 'top',
    right = 'right',
    bottom = 'bottom',
}

export enum SubmitStatus {
    PROCESSING = 'processing',
    SUCCESS = 'success',
    FAILURE = 'failure',
}

export const ValueTypesDictionary = {
    '1': ValueType.DISCRETE,
    '2': ValueType.INTEGER,
    '3': ValueType.PERCENT,
    '4': ValueType.DATE,
    '5': ValueType.MONEY,
    '6': ValueType.TEXT,
    '7': ValueType.DURATION,
    '8': ValueType.LOCATION,
};

export interface IAppStateProps {
    featureToggles: IFeatureToggle[];
    loggingOut: boolean;
    token: string;
    tokenExpiry: string;
    users: IAccountUser[];
}

export interface IAppReducer {
    businessStatuses: IVersionBusinessStatus[] | null;
    categories: IIssueCategory[];
    defaultOcrMode: DocumentOcrMode;
    defaultStream: IReviewStream;
    properties: ILexibleProperty[];
    propertyDetailsLoginCount: number; // keeps track of a user's logins since the release of the Property Details feature
    themes: LexibleTheme[];
}

export interface ISetPropertyDetailsLoginCountAction extends Action {
    type: typeof SET_PROPERTY_DETAILS_LOGIN_COUNT;
    count: number;
}

export interface ISetBusinessStatusesAction extends Action {
    type: typeof SET_BUSINESS_STATUSES;
    businessStatuses: IVersionBusinessStatus[];
}

export interface ISetGenericThemesAction extends Action {
    type: typeof SET_GENERIC_THEMES;
    themes: LexibleTheme[];
}

export interface ISetDefaultStreamAction extends Action {
    type: typeof SET_DEFAULT_STREAM;
    defaultStream: IReviewStream;
}

export interface ISetDefaultOcrModeAction extends Action {
    type: typeof SET_DEFAULT_OCR_MODE;
    defaultOcrMode: DocumentOcrMode;
}

export interface ISetCategoriesAction extends Action {
    type: typeof SET_CATEGORIES;
    categories: IIssueCategory[];
}

export interface ISetLexiblePropertiesAction extends Action {
    type: typeof SET_LEXIBLE_PROPERTIES;
    properties: ILexibleProperty[];
}

export type IAppActionTypes =
    | ISetPropertyDetailsLoginCountAction
    | ISetBusinessStatusesAction
    | ISetCategoriesAction
    | ISetDefaultOcrModeAction
    | ISetDefaultStreamAction
    | ISetGenericThemesAction
    | ISetLexiblePropertiesAction;
