import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import Paragraph from './Paragraph';
import {
    IParagraphDispatchProps,
    IParagraphStateProps,
} from './Paragraph-types';
import {
    setHighlightIndexAction,
    setSelectedParagraphAction,
} from '../Contract/Contract-actions';
import { IRootState } from '../../redux/reducers-types';
import { HighlightIndexType } from '../Contract/Contract-types';
import { addParagraphHeightInPixelsAction } from '../HeatmapPane/HeatmapPane-actions';

function mapStateToProps(state: IRootState): IParagraphStateProps {
    return {
        activeTab: state.contractSidebar.activeTab,
        activeIssueFilters: state.issueListFilters.activeIssueFilters,
        contract: state.contract.contract,
        contractSidebarTab: state.contractSidebar.activeTab,
        featureToggles: state.featureToggles.toggles,
        highlighting: state.partyCorrection.highlighting,
        bannerSettings: state.heatmapPane.bannerSettings,
        selectedContractIntelligence:
            state.contract.selectedContractIntelligence,
        selectedIssue: state.contract.selectedIssue,
        selectedParagraph: state.contract.selectedParagraph,
        selectedPropertyCode: state.contract.selectedPropertyCode,
        summaryProperties: state.contract.summaryProperties,
        selectedVersion: state.contract.selectedVersion,
        searchTerm: state.searchBar.searchTerm,
        highlightedText: state.contract.highlightedText,
        highlightStartIndex: state.contract.highlightStartIndex,
        highlightEndIndex: state.contract.highlightEndIndex,
        highlightedParagraphs: state.contract.highlightedParagraphs,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IParagraphDispatchProps {
    return {
        setHighlightIndex: (index: number, highlightType: HighlightIndexType) =>
            dispatch(setHighlightIndexAction(index, highlightType)),
        addParagraphHeightInPixels: (height: number) =>
            dispatch(addParagraphHeightInPixelsAction(height)),
        setSelectedParagraph: (...args) =>
            dispatch(setSelectedParagraphAction(...args)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Paragraph);
