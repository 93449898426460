import {
    CHANGE_ACTIVE_ISSUE_FILTERS,
    IChangeActiveIssueFiltersAction,
    IIssueFiltersValues,
    IResetActiveIssueFiltersAction,
    ISetActiveIssueFiltersAction,
    ISetIssuesSortByAction,
    ISetSelectedFilterPresetAction,
    IssuesSortOption,
    PresetIssueFilters,
    RESET_ACTIVE_ISSUE_FILTERS,
    SET_ISSUES_SORT_BY,
    SET_SELECTED_FILTER_PRESET,
    UPDATE_ACTIVE_ISSUE_FILTER_VALUES,
} from './IssueListFilters-types';

export function resetActiveIssueFiltersAction(): IResetActiveIssueFiltersAction {
    return {
        type: RESET_ACTIVE_ISSUE_FILTERS,
    };
}

export function updateActiveIssueFiltersAction(
    activeIssueFilters: IIssueFiltersValues
): ISetActiveIssueFiltersAction {
    return {
        type: UPDATE_ACTIVE_ISSUE_FILTER_VALUES,
        activeIssueFilters,
    };
}

export function setSelectedIssueFiltersAction(
    selectedFilter: keyof typeof PresetIssueFilters
): IChangeActiveIssueFiltersAction {
    return {
        type: CHANGE_ACTIVE_ISSUE_FILTERS,
        selectedFilter,
    };
}

export function setIssuesSortByAction(
    sortBy: IssuesSortOption
): ISetIssuesSortByAction {
    return {
        type: SET_ISSUES_SORT_BY,
        sortBy,
    };
}

export function setSelectedFilterPresetAction(
    selectedFilterPreset: keyof typeof PresetIssueFilters | ''
): ISetSelectedFilterPresetAction {
    return {
        type: SET_SELECTED_FILTER_PRESET,
        selectedFilterPreset,
    };
}
