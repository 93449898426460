import { Action } from 'redux';
import { ILexibleProperty } from '../App/App-types';
import { IFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import {
    IIssueCategory,
    IssueDisplay,
} from '../IssueListPane/IssueListPane-types';
import { IssuesTableHeader } from '../IssuesReport/HybridReportDataGrid/HybridReportDataGrid-types';
import { GridSortModel } from '@mui/x-data-grid';
import {
    FamiliarityLevel,
    IssueSeverityLevel,
    IssueStatus,
    LexibleTheme,
} from '@thought-river/negotiations-common';
import { SelectOption } from '@thought-river/ui-components';

export const RESET_ACTIVE_ISSUE_FILTERS = 'RESET_ACTIVE_ISSUE_FILTERS';
export const UPDATE_ACTIVE_ISSUE_FILTER_VALUES =
    'UPDATE_ACTIVE_ISSUE_FILTER_VALUES';
export const CHANGE_ACTIVE_ISSUE_FILTERS = 'CHANGE_ACTIVE_ISSUE_FILTERS';
export const SET_ISSUES_SORT_BY = 'SET_ISSUES_SORT_BY';
export const SET_SELECTED_FILTER_PRESET = 'SET_SELECTED_FILTER_PRESET';

export const DEFAULT_FILTER_PRESET = 'AllIssues';

export enum PresetIssueFilters {
    AllClosed = 'All Closed Issues',
    AllIssues = 'All Issues',
    AllLexibleQuestions = 'All Lexible Questions',
    AllOpen = 'All Open Issues',
    CommercialReview = 'Commercial Review',
    CounterpartyResponse = 'Counterparty Response',
    CoverSheet = 'Cover Sheet',
    ContractSummary = 'Customized Checklist',
    NotDetected = 'Only Not Detected Issues',
    Priority = 'Priority Issues',
    // RSBG custom views - controlled by a FF
    BidReport = 'Bid Report',
    NegotiationReport = 'Negotiation Report',
    FinalReportForSignatories = 'Final Contract Report For Signatories',
    FinalReportForProjectTeams = 'Final Contract Report For Project Teams',
    // Adecco custom views - controlled by a FF
    AdeccoNDAReport = 'TAG Report Template',
}

export enum FiltersContext {
    IssuesList = 'IssuesList',
    IssuesReport = 'IssuesReport',
}

export enum IssuesSortOption {
    CLAUSE = 'clause',
    FAMILIARITY = 'familiarity',
    SEVERITY = 'importance',
    STATUS = 'status',
    ISSUE_SUMMARY_TITLE = 'card_name',
}

interface IIssuesReportViewOptions {
    defaultPageSize?: number;
    defaultSortModel?: GridSortModel;
}

export interface IIssueFiltersValues {
    categories: IIssueCategory[];
    columns: IssuesTableHeader[];
    displays: IssueDisplay[];
    familiarity: FamiliarityLevel[];
    severity: IssueSeverityLevel[];
    status: IssueStatus[];
    themes: LexibleTheme[];
    summaryPropertiesOnly: boolean;
    issuesReportOptions?: IIssuesReportViewOptions;
    // If specified, the view will only be available if the given feature flag is enabled
    featureFlag?: string;
}

export type IIssueFilterPresets = {
    [key in PresetIssueFilters]: IIssueFiltersValues;
};

export interface IIssueListFiltersState {
    selectedCategoryValues: SelectOption[];
    selectedDisplayValues: SelectOption[];
    selectedFamiliarityValues: SelectOption[];
    selectedSeverityValues: SelectOption[];
    selectedStatusValues: SelectOption[];
    selectedThemeValues: SelectOption[];
}

export interface IIssueListFiltersStateProps {
    activeIssueFilters: IIssueFiltersValues;
    themes: LexibleTheme[];
    categories: IIssueCategory[];
    featureToggles: IFeatureToggle[];
    sidebarWidthInPixels: number;
    sortBy: IssuesSortOption;
    properties: ILexibleProperty[];
}

export interface IIssueListFiltersReducer {
    activeIssueFilters: IIssueFiltersValues;
    selectedFilterPreset: keyof typeof PresetIssueFilters | '';
    lastValidPreset: keyof typeof PresetIssueFilters;
    sortBy: IssuesSortOption;
}

export interface ISetIssuesSortByAction extends Action {
    type: typeof SET_ISSUES_SORT_BY;
    sortBy: IssuesSortOption;
}

export interface IIssueListFiltersDispatchProps {
    updateActiveIssueFilters: (activeIssueFilters: IIssueFiltersValues) => void;
    setIssuesSortByOption: (sortBy: IssuesSortOption) => void;
}

export interface IIssueListFiltersProps
    extends IIssueListFiltersDispatchProps,
        IIssueListFiltersStateProps {
    show: boolean;
    onClose: () => void;
    context: FiltersContext;
}

export interface IResetActiveIssueFiltersAction extends Action {
    type: typeof RESET_ACTIVE_ISSUE_FILTERS;
}

export interface ISetActiveIssueFiltersAction extends Action {
    type: typeof UPDATE_ACTIVE_ISSUE_FILTER_VALUES;
    activeIssueFilters: IIssueFiltersValues;
}

export interface IChangeActiveIssueFiltersAction extends Action {
    type: typeof CHANGE_ACTIVE_ISSUE_FILTERS;
    selectedFilter: keyof typeof PresetIssueFilters;
}

export interface ISetSelectedFilterPresetAction extends Action {
    type: typeof SET_SELECTED_FILTER_PRESET;
    selectedFilterPreset: keyof typeof PresetIssueFilters | '';
}

export interface IIssueFilterIconProps {
    icon: IssueSeverityLevel;
}

export type IIssueListFiltersActionTypes =
    | IChangeActiveIssueFiltersAction
    | IResetActiveIssueFiltersAction
    | ISetActiveIssueFiltersAction
    | ISetIssuesSortByAction
    | ISetSelectedFilterPresetAction;
