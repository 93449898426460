import { SelectedDataProps } from './SelectedData.types';
import styles from './SelectedData.module.scss';
import classNames from 'classnames';
import SelectedDataElement from './SelectedDataElement/SelectedDataElement';
import {
    DraggableList,
    DraggableListItem,
} from '@modules/common/components/DraggableList';

const SelectedData = ({
    allowHidingColumns,
    selectedData,
    onDataReorder,
    onDataRemove,
    onDataVisibilityChange,
    onDataClick,
}: SelectedDataProps) => {
    const draggableListItems = selectedData
        .filter((d) => d.isVisible)
        .map((data) => ({
            id: data.id,
            content: (
                <SelectedDataElement
                    allowHidingColumn={allowHidingColumns}
                    data={data}
                    onDataVisibilityChange={onDataVisibilityChange}
                    onDataRemove={onDataRemove}
                />
            ),
        }));

    const invisibleItems = selectedData
        .filter((d) => !d.isVisible)
        .map((data) => (
            //Need this wrapper div to match the html structure of draggable list items
            <div
                className={classNames(styles.item, styles.invisibleItem)}
                key={data.id}
                onClick={() => onDataClick(data.id)}
            >
                <SelectedDataElement
                    allowHidingColumn={allowHidingColumns}
                    data={data}
                    onDataVisibilityChange={onDataVisibilityChange}
                    onDataRemove={onDataRemove}
                />
            </div>
        ));

    const handleDataReorder = (items: DraggableListItem[]) => {
        const ids = items.map((item) => item.id);
        const reorderedData = [...selectedData].sort(
            (a, b) => ids.indexOf(a.id) - ids.indexOf(b.id)
        );
        onDataReorder(reorderedData);
    };

    return (
        <div className={styles.wrapper}>
            <DraggableList
                items={draggableListItems}
                onReorder={handleDataReorder}
                classes={{ list: styles.list, item: styles.item }}
                onItemClick={(itemId) => onDataClick(itemId)}
            />
            {invisibleItems.length ? (
                <div className={styles.seperator} />
            ) : null}
            <div className={styles.list}>{invisibleItems}</div>
        </div>
    );
};

export default SelectedData;
