import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';

import Contract from './Contract';
import {
    HighlightIndexType,
    IContractAssesssment,
    IContractDispatchProps,
    IContractPolicy,
    IContractProperty,
    IContractStateProps,
    IContractVersion,
    IDisplayContract,
    IVersionBusinessStatus,
} from './Contract-types';
import { IRootState } from '../../redux/reducers-types';
import { IContractParagraph } from '../Paragraph/Paragraph-types';
import {
    setAssessmentAction,
    setContractAction,
    setFormatsAction,
    setIssuesAction,
    setLoadedAction,
    setParagraphsAction,
    setPropertiesAction,
    setRedlinedTextAction,
    setSelectedIssueAction,
    setReloadContractAction,
    setSelectedVersionAction,
    setThemesAction,
    setUnscoredThemesAction,
    setVersionsAction,
    setHighlightIndexAction,
    setSelectedParagraphAction,
    setNewVersionProcessedAction,
    setReferringPageAction,
    setPoliciesAction,
    setPropertyTemplatesLoadedAction,
    setSummaryPropertiesAction,
    setSelectedPropertiesTemplateAction,
    setPropertyTemplatesAction,
    clearContractAction,
} from './Contract-actions';
import { setPaneWidthAction } from '../PaneDivider/PaneDivider-actions';
import { ContractPane } from './Contract-types';
import { ContractSidebarTab } from '../ContractSidebar/ContractSidebar-types';
import { setContractSidebarTabAction } from '../ContractSidebar/ContractSidebar-actions';
import { resetActiveIssueFiltersAction } from '../IssueListFilters/IssueListFilters-actions';
import {
    addProcessingContractAction,
    setReprocessingContractAction,
    unsetNotifiedContractAction,
    unsetReprocessingContractAction,
    unsetSuccessfulReprocessedContractAction,
} from '../ContractPollingProvider/ContractPollingProvider-actions';
import { IProcessingContract } from '../ContractPollingProvider/ContractPollingProvider-types';
import {
    setIssuesReportSortModelAction,
    setIssuesTableColumnsAction,
} from '../IssuesReport/IssuesReport-actions';
import { ISummaryProperty } from '../SummaryPane/SummaryPane-types';
import {
    defaultColumns,
    initialSortModel,
} from '../IssuesReport/IssuesReport-reducer';
import { setBannerSettingsAction } from '../HeatmapPane/HeatmapPane-actions';
import { IBannerSettings } from '../HeatmapPane/HeatmapPane-types';
import { SummaryPanelTemplateOut } from '@thought-river/negotiations-common/dist/api/playbookManager';
import {
    ContractIssue,
    LexibleTheme,
} from '@thought-river/negotiations-common';
import { FileFormat } from '@modules/common/types';
import {
    setBusinessStatusesAction,
    setCategoriesAction,
    setGenericThemesAction,
    setLexiblePropertiesAction,
} from '../App/App-actions';
import { ILexibleProperty } from '../App/App-types';
import { IIssueCategory } from '../IssueListPane/IssueListPane-types';

function mapStateToProps(state: IRootState): IContractStateProps {
    return {
        accountCode: state.auth.accountCode,
        apiToken: state.auth.apiToken,
        categories: state.app.categories,
        contract: state.contract.contract,
        contractId: state.contract.id,
        featureToggles: state.featureToggles.toggles,
        formats: state.contract.formats,
        heatmapWidth: state.paneDivider.heatmapWidth,
        issues: state.contract.issues,
        loaded: state.contract.loaded,
        lexibleProperties: state.app.properties,
        newVersionProcessed: state.contract.newVersionProcessed,
        reload: state.contract.reload,
        paragraphs: state.contract.paragraphs,
        processingContracts:
            state.contractPollingProvider?.processingContracts ?? [],
        properties: state.contract.properties,
        reprocessingContracts:
            state.contractPollingProvider?.reprocessingContracts ?? [],
        selectedPropertiesTemplate: state.contract.selectedPropertiesTemplate,
        selectedVersion: state.contract.selectedVersion,
        sidebarWidth: state.paneDivider.sidebarWidth,
        streams: state.auth.streams,
        successfulProcessedContracts:
            state.contractPollingProvider?.successfulProcessedContracts ?? [],
        successfulReprocessedContracts:
            state.contractPollingProvider?.successfulReprocessedContracts ?? [],
        themes: state.app.themes,
        userId: state.auth.userId,
        versions: state.contract.versions,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IContractDispatchProps {
    return {
        setBusinessStatuses: (businessStatuses: IVersionBusinessStatus[]) =>
            dispatch(setBusinessStatusesAction(businessStatuses)),
        setCategories: (categories: IIssueCategory[]) =>
            dispatch(setCategoriesAction(categories)),
        setGenericThemes: (themes: LexibleTheme[]) =>
            dispatch(setGenericThemesAction(themes)),
        setLexibleProperties: (properties: ILexibleProperty[]) =>
            dispatch(setLexiblePropertiesAction(properties)),
        addProcessingContract: (contract: IProcessingContract) =>
            dispatch(addProcessingContractAction(contract)),
        resetActiveIssueFilters: () =>
            dispatch(resetActiveIssueFiltersAction()),
        resetIssuesReportSortOrder: () =>
            dispatch(setIssuesReportSortModelAction(initialSortModel)),
        setAssessment: (assessment: IContractAssesssment) =>
            dispatch(setAssessmentAction(assessment)),
        setContract: (contract: IDisplayContract) =>
            dispatch(setContractAction(contract)),
        clearContract: () => dispatch(clearContractAction()),
        setContractSidebarTab: (contractSidebarTab: ContractSidebarTab) =>
            dispatch(setContractSidebarTabAction(contractSidebarTab)),
        setFormats: (formats: FileFormat[]) =>
            dispatch(setFormatsAction(formats)),
        setHighlightIndex: (index: number, highlightType: HighlightIndexType) =>
            dispatch(setHighlightIndexAction(index, highlightType)),
        resetIssuesTableColumns: () =>
            dispatch(setIssuesTableColumnsAction(defaultColumns)),
        setIssues: (issues: ContractIssue[]) =>
            dispatch(setIssuesAction(issues)),
        setPropertyTemplatesLoaded: (loaded: boolean) =>
            dispatch(setPropertyTemplatesLoadedAction(loaded)),
        setBannerSettings: (settings: IBannerSettings) =>
            dispatch(setBannerSettingsAction(settings)),
        setNewVersionProcessed: (newVersionProcessed: boolean) =>
            dispatch(setNewVersionProcessedAction(newVersionProcessed)),
        setPaneWidth: (pane: ContractPane, width: number) =>
            dispatch(setPaneWidthAction(pane, width)),
        setParagraphs: (paragraphs: IContractParagraph[]) =>
            dispatch(setParagraphsAction(paragraphs)),
        setPolicies: (policies: IContractPolicy[]) =>
            dispatch(setPoliciesAction(policies)),
        setProperties: (properties: IContractProperty[]) =>
            dispatch(setPropertiesAction(properties)),
        setPropertyTemplates: (propertyTemplates: SummaryPanelTemplateOut[]) =>
            dispatch(setPropertyTemplatesAction(propertyTemplates)),
        setReferringPage: (referringPage: string) =>
            dispatch(setReferringPageAction(referringPage)),
        setReloadContract: (reload: boolean) =>
            dispatch(setReloadContractAction(reload)),
        setThemes: (themes: LexibleTheme[]) =>
            dispatch(setThemesAction(themes)),
        setUnscoredThemes: (unscoredThemes: LexibleTheme[]) =>
            dispatch(setUnscoredThemesAction(unscoredThemes)),
        setVersions: (versions: IContractVersion[]) =>
            dispatch(setVersionsAction(versions)),
        setRedlinedText: (redlinedText: string) =>
            dispatch(setRedlinedTextAction(redlinedText)),
        setReprocessingContract: (
            contractId: string,
            contractName: string,
            versionId: string,
            stream: string,
            streamId: string,
            assessmentCount: number,
            userId: string
        ) =>
            dispatch(
                setReprocessingContractAction(
                    contractId,
                    contractName,
                    versionId,
                    stream,
                    streamId,
                    assessmentCount,
                    userId
                )
            ),
        setSelectedIssue: (selectedIssue: ContractIssue) =>
            dispatch(setSelectedIssueAction(selectedIssue)),
        setSelectedParagraph: (...args) =>
            dispatch(setSelectedParagraphAction(...args)),
        setSelectedPropertiesTemplate: (template: SummaryPanelTemplateOut) =>
            dispatch(setSelectedPropertiesTemplateAction(template)),
        setSelectedVersion: (version: IContractVersion) =>
            dispatch(setSelectedVersionAction(version)),
        setSummaryProperties: (summaryProperties: ISummaryProperty[]) =>
            dispatch(setSummaryPropertiesAction(summaryProperties)),
        setLoaded: (loaded: boolean) => dispatch(setLoadedAction(loaded)),
        unsetNotifiedContract: (contractId: string) =>
            dispatch(unsetNotifiedContractAction(contractId)),
        unsetReprocessingContract: (contractId: string) =>
            dispatch(unsetReprocessingContractAction(contractId)),
        unsetSuccessfulReprocessedContract: (contractId) =>
            dispatch(unsetSuccessfulReprocessedContractAction(contractId)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Contract));
