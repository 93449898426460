import { analyticsService } from '../../services/Analytics/Analytics';
import { EVENT_ACTION_ADD_SELECTION_NEW_ISSUE } from '../../services/Analytics/Analytics-types';

export function analytics(target: any) {
    const onAddIssue = target.prototype.onAddIssue;

    target.prototype.onAddIssue = function () {
        analyticsService.recordEvent(EVENT_ACTION_ADD_SELECTION_NEW_ISSUE);

        onAddIssue.apply(this, arguments);
    };
}
