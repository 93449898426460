import { DocumentOcrMode } from '../../resources/DocumentResource-types';
import {
    IAppReducer,
    IAppActionTypes,
    SET_BUSINESS_STATUSES,
    SET_DEFAULT_OCR_MODE,
    SET_DEFAULT_STREAM,
    SET_GENERIC_THEMES,
    SET_CATEGORIES,
    SET_LEXIBLE_PROPERTIES,
    SET_PROPERTY_DETAILS_LOGIN_COUNT,
} from './App-types';

export const appInitialState: IAppReducer = {
    businessStatuses: null,
    categories: [],
    defaultOcrMode: DocumentOcrMode.AUTODETECT,
    defaultStream: null!, // Fixme: null checks
    propertyDetailsLoginCount: 0,
    properties: [],
    themes: [],
};

const AppReducer = (
    state = appInitialState,
    action: IAppActionTypes
): IAppReducer => {
    switch (action.type) {
        case SET_BUSINESS_STATUSES:
            return {
                ...state,
                businessStatuses: action.businessStatuses,
            };
        case SET_CATEGORIES:
            return {
                ...state,
                categories: action.categories,
            };
        case SET_DEFAULT_OCR_MODE:
            return {
                ...state,
                defaultOcrMode: action.defaultOcrMode,
            };
        case SET_DEFAULT_STREAM:
            return {
                ...state,
                defaultStream: action.defaultStream,
            };
        case SET_LEXIBLE_PROPERTIES:
            return {
                ...state,
                properties: action.properties,
            };
        case SET_GENERIC_THEMES:
            return {
                ...state,
                themes: action.themes,
            };
        case SET_PROPERTY_DETAILS_LOGIN_COUNT:
            return {
                ...state,
                propertyDetailsLoginCount: action.count,
            };
        default:
            return state;
    }
};

export default AppReducer;
