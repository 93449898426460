import { Button, TextField, Dialog } from '@thought-river/ui-components';
import { UnlinkButtonProps } from './UnlinkButton.types';
import { Cancel, LinkOff, PlusOne, Star } from '@mui/icons-material';
import styles from './UnlinkButton.module.scss';
import { Popper } from '@mui/material';
import { cloneElement, useState } from 'react';
import classNames from 'classnames';
import { FEATURE_TOGGLE_RELEASE_UNLINK_WITH_FEEDBACK } from '../../../../components/FeatureToggleProvider/FeatureToggleProvider-types';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../redux/reducers-types';

const UnlinkButton = ({ onClick }: UnlinkButtonProps) => {
    const [popperAnchorEl, setPopperAnchorEl] = useState<null | HTMLElement>(
        null
    );

    const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
    const [feedback, setFeedback] = useState('');

    const featureToggles = useSelector(
        (state: IRootState) => state.featureToggles.toggles
    );
    const unlinkWithFeedbackEnabled = !!featureToggles.find(
        (toggle) =>
            toggle.feature === FEATURE_TOGGLE_RELEASE_UNLINK_WITH_FEEDBACK
    )?.enabled;

    const items = [
        {
            icon: <LinkOff className={styles.unlinkIcon} />,
            label: 'Unlink without feedback',
            onClick,
        },
        {
            icon: <PlusOne />,
            label: 'Reason 1',
            onClick,
        },
        {
            icon: <Star />,
            label: 'Reason 2',
            onClick,
        },
        {
            icon: <Cancel />,
            label: 'Reason 3',
            onClick,
        },
        {
            icon: <Star />,
            label: 'Other',
            onClick: () => {
                setFeedbackDialogOpen(true);
            },
        },
    ];

    const closeDialog = () => {
        setFeedbackDialogOpen(false);
        setFeedback('');
    };

    return (
        <>
            <Button
                variant="tertiary"
                className={classNames(
                    styles.button,
                    popperAnchorEl && styles.hideButton
                )}
                onClick={onClick}
                startIcon={<LinkOff className={styles.icon} />}
                onMouseEnter={(event) =>
                    unlinkWithFeedbackEnabled &&
                    setPopperAnchorEl(event.currentTarget)
                }
            >
                Unlink
            </Button>
            <Popper
                open={Boolean(popperAnchorEl)}
                anchorEl={popperAnchorEl}
                placement="bottom-start"
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -30],
                        },
                    },
                ]}
                onMouseLeave={() => setPopperAnchorEl(null)}
                style={{ zIndex: '999' }}
            >
                <div className={styles.popperContent}>
                    {items.map((item, index) => (
                        <>
                            <div
                                className={styles.listItem}
                                key={index}
                                onClick={() => {
                                    item.onClick?.();
                                    setPopperAnchorEl(null);
                                }}
                            >
                                {cloneElement(item.icon, {
                                    className: classNames(
                                        styles.icon,
                                        item.icon.props.className
                                    ),
                                })}
                                <div>{item.label}</div>
                            </div>
                            {index === 0 ? (
                                <div className={styles.divider} />
                            ) : (
                                ''
                            )}
                        </>
                    ))}
                </div>
            </Popper>
            <Dialog
                open={feedbackDialogOpen}
                dialogTitle="Provide additional feedback"
                dialogContent={
                    <TextField
                        multiline
                        rows={8}
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        placeholder="Feedback..."
                    />
                }
                dialogActions={
                    <>
                        <Button variant="secondary" onClick={closeDialog}>
                            Cancel
                        </Button>
                        <Button onClick={onClick}>Send feedback</Button>
                    </>
                }
                onClose={closeDialog}
            />
        </>
    );
};

export default UnlinkButton;
