import { ButtonProps, IconButton, Tooltip } from '@thought-river/ui-components';
import { Info } from '@mui/icons-material';

const LexiblePropertyDetailsButton = ({ className, onClick }: ButtonProps) => (
    <Tooltip title="Lexible property details" placement="left">
        <IconButton
            className={className}
            onClick={(event) => {
                event.stopPropagation();
                onClick?.(event);
            }}
        >
            <svg width={0} height={0}>
                <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
                    <stop offset={0} stopColor="rgba(83,178,244,1)" />
                    <stop offset={1} stopColor="rgba(137,138,244,1)" />
                </linearGradient>
            </svg>
            <Info style={{ fill: 'url(#linearColors)' }} />
        </IconButton>
    </Tooltip>
);

export default LexiblePropertyDetailsButton;
