import { ContractVersionReportColumn } from '@thought-river/negotiations-common/dist/api/contractContent';
import { removeHtmlTags } from '@modules/common/helpers';
import { GridValueFormatterParams } from '@mui/x-data-grid-premium';
import { getIssueSeverityLevelLabel } from '@thought-river/negotiations-common';
import { DEFAULT_DATE_FORMAT } from '@thought-river/ui-components';
import dayjs from 'dayjs';

/**
 * The values returned from this function will be used when displaying the data
 * in a cell (unless overriden by a custom cell component) and when exporting to CSV/Excel
 */
export const valueFormatter = (
    column: ContractVersionReportColumn,
    params: GridValueFormatterParams
) => {
    if (column.type === 'date' || column.type === 'dateTime') {
        return dayjs(params.value).format(DEFAULT_DATE_FORMAT);
    }

    if ((column.type as string) === 'html') {
        const parsedValue = removeHtmlTags(params.value ?? '');
        if (parsedValue.trim() === '') {
            return '-';
        }
        return parsedValue;
    }

    if (
        params.field === 'contract_sections' ||
        params.field === 'contract_sub_sections'
    ) {
        return params.value?.join(', ') || '-';
    }

    if (column.field === 'issue_severity_level') {
        return params.value ? getIssueSeverityLevelLabel(params.value) : '-';
    }

    if (params.field === 'property_linked_clauses') {
        if (!params.value || params.value.length === 0) {
            return '-';
        }
        return params.value.map((clause: any) => clause).join(', ');
    }

    return params.value || '-';
};
