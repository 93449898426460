import { Component } from 'react';

import {
    IIssuePaneDescriptionTabProps,
    IIssuePaneDescriptionTabState,
} from './IssuePaneDescriptionTab-types';
import './IssuePaneDescriptionTab.scss';
import { IIssueCategory } from '../../../IssueListPane/IssueListPane-types';
import { motion } from 'framer-motion';
import IssuePaneNotes from './IssuePaneNotes/IssuePaneNotes-container';
import LinkedClausesDropdown from '../../../LinkedClausesDropdown/LinkedClausesDropdown';
import { animationProps } from '../IssuePaneTabs-helpers';
import {
    capitalize,
    ParagraphClause,
} from '@thought-river/negotiations-common';
import { Select, SelectOption } from '@thought-river/ui-components';
import isEqual from 'lodash/isEqual';

function mapCategoryValuesIntoMultiSelectValues(category: IIssueCategory) {
    return {
        value: category.id,
        label: capitalize(category.name),
    };
}

export default class IssuePaneDescriptionTab extends Component<
    IIssuePaneDescriptionTabProps,
    IIssuePaneDescriptionTabState
> {
    constructor(props: IIssuePaneDescriptionTabProps) {
        super(props);

        this.onSelectCategory = this.onSelectCategory.bind(this);
        this.onSelectLinkedClause = this.onSelectLinkedClause.bind(this);
    }

    private onSelectCategory(options: SelectOption[]) {
        const { categories, form, setIssueForm } = this.props;

        const newValues = options.map((o) => o.value);
        const newCategories = categories.filter((category) =>
            newValues.includes(category.id)
        );

        setIssueForm({ ...form, categories: newCategories });
    }

    private onSelectLinkedClause(clauseNumbers: ParagraphClause[]) {
        const { form, setIssueForm } = this.props;

        setIssueForm({
            ...form,
            clauseNumbers,
        });
    }

    render() {
        const {
            categories,
            debouncedSaveIssue,
            form,
            formInitialised,
            issue,
            paragraphs,
            onClickSelectedClause,
            saveIssue,
        } = this.props;

        return (
            <div className="issue-pane-description-tab">
                <motion.div
                    {...animationProps(0.1)}
                    className="issue-linked-clauses"
                >
                    <LinkedClausesDropdown
                        paragraphs={paragraphs}
                        onSelectOption={this.onSelectLinkedClause}
                        onClickSelectedClause={onClickSelectedClause}
                        onCloseCallback={() => {
                            if (
                                !issue ||
                                isEqual(issue.clauseNumbers, form.clauseNumbers)
                            ) {
                                return;
                            }

                            saveIssue();
                        }}
                        selectedClauseNumbers={form.clauseNumbers}
                    />
                </motion.div>
                <motion.div
                    {...animationProps(0.2)}
                    className="issue-categories"
                >
                    <Select
                        className="issue-categories-dropdown"
                        data-testid="issue-categories-dropdown"
                        disableCloseOnSelect
                        label="Categories"
                        multiple
                        limitTags={6}
                        placeholder="Search or select categories"
                        onClose={() => {
                            if (
                                !issue ||
                                isEqual(issue.categories, form.categories)
                            ) {
                                return;
                            }

                            saveIssue();
                        }}
                        onChange={this.onSelectCategory}
                        options={categories.map(
                            mapCategoryValuesIntoMultiSelectValues
                        )}
                        value={form.categories.map(
                            mapCategoryValuesIntoMultiSelectValues
                        )}
                    />
                </motion.div>
                <motion.div {...animationProps(0.3)} className="issue-notes">
                    <IssuePaneNotes
                        debouncedSaveIssue={debouncedSaveIssue}
                        formInitialised={formInitialised}
                        saveIssue={saveIssue}
                    />
                </motion.div>
            </div>
        );
    }
}
