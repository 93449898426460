import {
    Badge,
    Tooltip,
    Avatar,
    AvatarGroup,
} from '@thought-river/ui-components';
import { ReportCellProps } from './ReportCell.types';
import PropertyAnswerCell from './PropertyAnswerCell';
import styles from '../../../ViewReportPage.module.scss';
import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import {
    PARTIES_STRING_SEPERATOR,
    useLexibleContext,
} from '@modules/reports/portfolioReports';
import { ContractLink } from '@modules/common/components/ContractLink';
import { extractColumnField } from '@modules/reports/common/helpers/extractColumnField';

const ReportCell = ({ params }: ReportCellProps) => {
    const { propertiesDict } = useLexibleContext();
    const column = params.colDef;
    const field = extractColumnField(column.field);

    /*Return an empty element in the group row cells except the cell of the grouped field*/
    if (
        params.rowNode.type === 'group' &&
        params.rowNode.groupingField !== field
    ) {
        return <></>;
    }

    if (field === 'contract_name') {
        return (
            <div
                className={classNames(
                    styles.cellContent,
                    styles.contractNameCell
                )}
            >
                {/* Don't render the link if we're grouping by contract name */}
                {params.rowNode.type === 'group' ? (
                    <Tooltip title={params.value}>
                        <div>{params.value}</div>
                    </Tooltip>
                ) : (
                    <>
                        {params.row.high_level_contract_status ===
                            'processing' && (
                            <Tooltip title="ThoughtRiver is reviewing this contract">
                                <CircularProgress size={14} />
                            </Tooltip>
                        )}
                        <Tooltip
                            title={params.value}
                            className={styles.cellContent}
                        >
                            <ContractLink
                                dealType={params.row.deal_type_code}
                                contractId={params.row.contract_id}
                                versionId={params.row.version_id}
                                linkText={params.value}
                            />
                        </Tooltip>
                    </>
                )}
            </div>
        );
    }

    if (field === 'status') {
        const badgeType = () => {
            switch (params.value.toLowerCase()) {
                case 'signed':
                    return 'status-signed';
                case 'negotiating':
                    return 'status-negotiating';
            }
        };
        return <Badge type={badgeType()} label={params.value} />;
    }

    if (field === 'deal_type') {
        return (
            <Tooltip title={params.value} className={styles.cellContent}>
                <div>{params.value}</div>
            </Tooltip>
        );
    }

    if (field === 'counter_party' || field === 'own_party') {
        if (params.value) {
            const tokens = (params.value as string).split(
                PARTIES_STRING_SEPERATOR
            );
            let label;
            if (tokens.length > 1) {
                label = (
                    <AvatarGroup
                        iconOptions={{ size: 'small' }}
                        labels={tokens}
                    />
                );
            } else {
                label = <Avatar label={tokens[0]} showLabel />;
            }
            return (
                <ContractLink
                    dealType={params.row.deal_type_code}
                    contractId={params.row.contract_id}
                    versionId={params.row.version_id}
                    linkText={label}
                />
            );
        }
        const label =
            field === 'counter_party'
                ? 'Counterparty name not set'
                : 'Organization name not set';
        return (
            <Tooltip title={label}>
                <ContractLink
                    dealType={params.row.deal_type_code}
                    contractId={params.row.contract_id}
                    versionId={params.row.version_id}
                    linkText={label}
                />
            </Tooltip>
        );
    }

    const cellProperty = propertiesDict?.[field];
    // Cell is showing a property answer
    if (cellProperty) {
        return <PropertyAnswerCell property={cellProperty} params={params} />;
    }

    if (column.type === 'date') {
        //Ensures we see dates formatted using the default date format
        return params.formattedValue;
    }

    return params.value;
};

export default ReportCell;
