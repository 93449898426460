import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';

import ContractListTable from './ContractListTable';
import {
    IContractListTableDispatchProps,
    IContractListTableStateProps,
} from './ContractListTable-types';
import { IRootState } from '../../redux/reducers-types';
import { showDialogAction } from '../ModalDialog/ModalDialog-actions';
import { loadContracts } from '../ContractList/ContractList-helpers';
import { updateContract } from '../ContractList/ContractList-helpers';
import {
    setContractsAction,
    setHasNextPageAction,
    setRequestedPagesAction,
    setSelectAllContractsAction,
    setSelectedContractIdsAction,
    setFetchingContractsAction,
    updateContractInListAction,
} from '../ContractList/ContractList-actions';
import { IDisplayContract } from '../Contract/Contract-types';
import { setReferringPageAction } from '../Contract/Contract-actions';
import { IModalDialogButton } from '../ModalDialog/ModalDialog-types';

function mapStateToProps(state: IRootState): IContractListTableStateProps {
    return {
        bulkActions: state.contractListToolbar.bulkActions,
        contracts: state.contractList.contracts,
        currentPage: state.contractList.currentPage,
        fetchingContracts: state.contractList.fetchingContracts,
        hasNextPage: state.contractList.hasNextPage,
        nextPage: state.contractList.nextPage,
        processingContracts:
            state.contractPollingProvider?.processingContracts ?? [],
        reprocessingContracts:
            state.contractPollingProvider?.reprocessingContracts ?? [],
        selectAll: state.contractList.selectAll,
        selectedContractIds: state.contractList.selectedContractIds,
        showLoader: state.contractList.showLoader,
        streams: state.auth.streams,
        timedOutProcessingContractIds:
            state.contractPollingProvider?.timedOutProcessingContractIds ?? [],
        userId: state.auth.userId,
        users: state.auth.users,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch
): IContractListTableDispatchProps {
    return {
        setFetchingContracts: (fetchingContracts: boolean) =>
            dispatch(setFetchingContractsAction(fetchingContracts)),

        loadContracts: (page?: number) => loadContracts(dispatch, page),
        showDialog: (
            title: string | JSX.Element,
            showCloseButton: boolean,
            component: JSX.Element,
            buttons: IModalDialogButton[],
            height: string | null | undefined,
            customClassname: string = '',
            closeOnBlur: boolean = true
        ) =>
            dispatch(
                showDialogAction(
                    title,
                    showCloseButton,
                    component,
                    buttons,
                    height,
                    customClassname,
                    closeOnBlur
                )
            ),

        updateContract: (
            contractId: string,
            fields: Partial<IDisplayContract>
        ) => updateContract(contractId, fields)(dispatch),

        updateContractInList: (
            contractId: string,
            fields: Partial<IDisplayContract>
        ) => dispatch(updateContractInListAction(contractId, fields)),

        setHasNextPage: (hasNextPage: boolean) =>
            dispatch(setHasNextPageAction(hasNextPage)),

        setReferringPage: (referringPage: string) =>
            dispatch(setReferringPageAction(referringPage)),

        setRequestedPages: (requestedPages: number[]) =>
            dispatch(setRequestedPagesAction(requestedPages)),

        setContracts: (contracts: IDisplayContract[]) =>
            dispatch(setContractsAction(contracts)),

        setSelectAllContracts: (
            selectAll: boolean,
            selectableContractIds?: string[]
        ) =>
            dispatch(
                setSelectAllContractsAction(selectAll, selectableContractIds)
            ),

        setSelectedContractIds: (contractIds: string[]) =>
            dispatch(setSelectedContractIdsAction(contractIds)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ContractListTable));
