import { analyticsService } from '../../services/Analytics/Analytics';
import {
    EVENT_ACTION_BULK_IGNORE,
    EVENT_ACTION_SHARE_INTERNALLY,
} from '../../services/Analytics/Analytics-types';
import { IInternalSharingModalProps } from './InternalSharingModal-types';

export function analytics() {
    return (target: any) => {
        const shareInternally = target.prototype.shareInternally;
        const bulkIgnore = target.prototype.bulkIgnore;

        target.prototype.shareInternally = function () {
            const { contract } = this.props as IInternalSharingModalProps;

            analyticsService.recordEvent(
                EVENT_ACTION_SHARE_INTERNALLY,

                {
                    ContractId: contract?.id,
                    Stream: contract?.streamCode,
                    VersionId: contract?.latestVersion?.id,
                    VersionNumber: contract?.latestVersion?.versionNumber,
                }
            );

            shareInternally.apply(this, arguments);
        };

        target.prototype.bulkIgnore = function () {
            analyticsService.recordEvent(EVENT_ACTION_BULK_IGNORE);

            bulkIgnore.apply(this, arguments);
        };
    };
}
