import { Tabs } from '@mui/material';
import { Component } from 'react';
import {
    ICustomColorPickerProps,
    ICustomColorPickerState,
} from './CustomColorPicker-types';
import './CustomColorPicker.scss';
import { Tab } from '@thought-river/ui-components';

const colors = {
    dark_gray: '#172b4d',
    dark_blue: '#0747a6',
    dark_teal: '#0747a6',
    dark_green: '#006644',
    dark_orange: '#ff991f',
    dark_red: '#bf2600',
    dark_purple: '#403294',
    light_gray: '#97a0af',
    blue: '#4c9aff',
    teal: '#00b8d9',
    green: '#36b37e',
    yellow: '#ffc400',
    red: '#ff5630',
    purple: '#6554c0',
    white: 'white',
    light_blue: '#b3d4ff',
    light_teal: '#b3f5ff',
    light_green: '#abf5d1',
    light_yellow: '#fff0b3',
    light_red: '#ffbdad',
    light_purple: '#eae6ff',
};

export default class CustomColorPicker extends Component<
    ICustomColorPickerProps,
    ICustomColorPickerState
> {
    constructor(props: ICustomColorPickerProps) {
        super(props);

        this.state = {
            activeStyle: 'color',
            selectedTabIndex: 0,
        };
    }

    componentDidMount(): void {
        this.setState({
            activeStyle: 'color',
            selectedTabIndex: 0,
        });
    }

    onTabChange = (index: number) => {
        this.setState({
            activeStyle: index === 0 ? 'color' : 'bgcolor',
            selectedTabIndex: index,
        });
    };

    render() {
        const { callback } = this.props;
        const { activeStyle, selectedTabIndex } = this.state;

        const rows = [0, 1, 2];
        const colorsPerRow = 7;
        const colorKeys = Object.keys(colors);

        return (
            <div className="custom-color-picker">
                <Tabs
                    className="color-picker-tabs"
                    value={selectedTabIndex}
                    onChange={(_, index: number) => this.onTabChange(index)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{
                        className: 'tab-indicator',
                    }}
                >
                    <Tab label="Text" className="tab" />
                    <Tab label="Highlight" className="tab" />
                </Tabs>
                {rows.map((row) => (
                    <div className="color-row" key={`color-row-${row}`}>
                        {colorKeys
                            .slice(row * colorsPerRow, (row + 1) * colorsPerRow)
                            .map((key) => (
                                <span
                                    className="color-option-wrapper"
                                    key={key}
                                    onClick={() =>
                                        callback(activeStyle, colors[key])
                                    }
                                >
                                    <span
                                        className="color-option"
                                        style={{
                                            background: colors[key],
                                        }}
                                    />
                                </span>
                            ))}
                    </div>
                ))}
            </div>
        );
    }
}
