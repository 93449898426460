import { tokenHasExpired } from '../components/Auth/Auth-helpers';
import { reduxStore } from '../redux/store';

import {
    ERROR_BAD_REQUEST,
    ERROR_NOT_FOUND,
    ERROR_SERVER,
    ERROR_UNAUTHORIZED,
    ERROR_UNKNOWN,
    IRequestOptions,
    Method,
    requestOptions,
} from './api-types';
import {
    clearLoginDetailsAction,
    setLogoutAction,
} from '../components/Auth//Auth-actions';
import axios, { AxiosResponse } from 'axios';
import { IRootState } from '../redux/reducers-types';

class Api {
    statusToErrorType(status: number) {
        let errorType;

        switch (status) {
            case 400: {
                errorType = ERROR_BAD_REQUEST;
                break;
            }
            case 401: {
                errorType = ERROR_UNAUTHORIZED;
                break;
            }
            case 404: {
                errorType = ERROR_NOT_FOUND;
                break;
            }
            case 500: {
                errorType = ERROR_SERVER;
                break;
            }
            default: {
                errorType = ERROR_UNKNOWN;
            }
        }

        return errorType;
    }

    async request(
        method: Method,
        url: string,
        options: IRequestOptions = requestOptions
    ): Promise<Response | null> {
        const { auth } = reduxStore.store.getState() as IRootState;
        const { apiToken, legacyApiToken, tokenExpiry, selectedStream } = auth;
        let { apiUrl } = auth;

        const {
            payload,
            authorize,
            baseUrl,
            noRedirect,
            includeStream,
            authHeaderKey,
            streamCode,
            headers,
            abortSignal,
        } = options;

        if (authorize && tokenHasExpired(tokenExpiry)) {
            reduxStore.store.dispatch(clearLoginDetailsAction());
            reduxStore.store.dispatch(setLogoutAction(true));
            return null;
        }

        apiUrl = baseUrl ? baseUrl : apiUrl;

        const fetchOptions: any = {
            method,
            headers: {
                Accept: 'application/json',
                ...headers,
            },
            signal: abortSignal,
        };

        if (authorize) {
            if (authHeaderKey) {
                fetchOptions.headers[authHeaderKey] = `Bearer ${
                    legacyApiToken ?? apiToken
                }`;
            }

            if (includeStream) {
                fetchOptions.headers['x-assume-account'] = streamCode
                    ? streamCode
                    : selectedStream.accountCode;
            }
        }

        if (noRedirect) {
            fetchOptions.headers['X-No-Redirect'] = 'true';
        }

        if (payload) {
            switch (method) {
                case Method.GET:
                    fetchOptions.qs = payload;
                    break;

                default:
                    if (payload instanceof FormData) {
                        fetchOptions.body = payload;
                    } else {
                        fetchOptions.body = JSON.stringify(payload);
                    }
                    break;
            }
        }

        const response = await fetch(`${apiUrl}${url}`, fetchOptions);

        if (response.ok) {
            return response;
        } else {
            throw new Error(this.statusToErrorType(response.status));
        }
    }

    async axiosRequest(
        method: Method,
        url: string,
        options: IRequestOptions = requestOptions,
        onUploadProgress: CallableFunction
    ): Promise<AxiosResponse | null> {
        const { auth } = reduxStore.store.getState() as IRootState;
        const {
            apiToken,
            legacyApiToken,
            apiUrl,
            tokenExpiry,
            selectedStream,
        } = auth;

        const {
            abortSignal,
            baseUrl,
            payload,
            authorize,
            noRedirect,
            includeStream,
            authHeaderKey,
            streamCode,
            headers,
        } = options;

        if (authorize && tokenHasExpired(tokenExpiry)) {
            reduxStore.store.dispatch(clearLoginDetailsAction());
            reduxStore.store.dispatch(setLogoutAction(true));
            return null;
        }

        const axiosOptions = {
            url,
            method,
            baseURL: baseUrl || apiUrl,
            headers: {
                Accept: 'application/json',
                ...headers,
            },
            data: payload,
            onUploadProgress: (progressEvent: ProgressEvent) => {
                onUploadProgress(progressEvent);
            },
        };

        if (authorize) {
            if (authHeaderKey) {
                axiosOptions.headers[authHeaderKey] = `Bearer ${
                    legacyApiToken ?? apiToken
                }`;
            }

            if (includeStream) {
                axiosOptions.headers['x-assume-account'] = streamCode
                    ? streamCode
                    : selectedStream.accountCode;
            }
        }

        if (noRedirect) {
            axiosOptions.headers['X-No-Redirect'] = 'true';
        }

        if (payload && Method.POST && payload instanceof FormData) {
            axiosOptions['data'] = payload;
        }

        if (abortSignal) {
            axiosOptions['signal'] = abortSignal;
        }

        const response = await axios(axiosOptions);

        if (String(response.status).startsWith('2')) {
            return response;
        } else {
            throw new Error(this.statusToErrorType(response.status));
        }
    }
}

// eslint-disable-next-line
export default new Api();
