import { Component } from 'react';
import { toast } from 'react-toastify';
import {
    IColumnsOrderData,
    IIssuesReportProps,
    IIssuesReportState,
    IIssuesTableColumn,
} from './IssuesReport-types';
import './IssuesReport.scss';
import IssuesReportDetails from './IssuesReportDetails/IssuesReportDetails';
import IssuesReportToolbar from './IssuesReportToolbar/IssuesReportToolbar';
import IssueListFilters from '../IssueListFilters/IssueListFilters-container';
import {
    DEFAULT_FILTER_PRESET,
    FiltersContext,
    IIssueFiltersValues,
    PresetIssueFilters,
} from '../IssueListFilters/IssueListFilters-types';
import IssuesReportEditColumnsModal from './IssuesReportEditColumnsModal/IssuesReportEditColumnsModal-container';
import { IContractProperty } from '../Contract/Contract-types';
import { analytics } from './IssuesReport-analytics';
import {
    arrayDifferenceResolver,
    resolvePropertyInputType,
} from '../App/App-helpers';
import ClausesSidebar from '../ClausesSidebar/ClausesSidebar';
import { IIssueRequestPayload } from '../../resources/IssueResource-types';
import { resolveSeverityLevelValue } from '../IssueListPane/IssuesListPane-helpers';
import IssueResource from '../../resources/IssueResource';
import { SubmitStatus, ValueType } from '../App/App-types';
import PropertyResource from '../../resources/PropertyResource';
import { ComponentReference } from '../../services/Analytics/Analytics-types';
import {
    commercialReviewColumns,
    defaultColumns,
    initialSortModel,
} from './IssuesReport-reducer';
import HybridReportDataGrid from './HybridReportDataGrid/HybridReportDataGrid';
import {
    IContractReportRow,
    IssuesTableHeader,
} from './HybridReportDataGrid/HybridReportDataGrid-types';
import HybridReportCopyableTable from './HybridReportCopyableTable/HybridReportCopyableTable-container';
import { ISummaryProperty } from '../SummaryPane/SummaryPane-types';
import { mapClauseNumber } from '../App/App-mappers';
import { NO_VALUE } from '../../services/legacyHTMLParser-types';
import {
    linkUnlinkPropertyParagraphs,
    resolvePropertyValue,
} from '../../services/legacyHTMLParser-helpers';
import { syncContractData } from '../Contract/Contract-helpers';
import SuggestionsSidebar from '../SuggestionsSidebar/SuggestionsSidebar';
import { IContractParagraph } from '../Paragraph/Paragraph-types';
import { filtersPresets } from '../IssueListFilters/IssueListFilters-reducer';
import dayjs from 'dayjs';
import {
    ContractIssue,
    ParagraphClause,
} from '@thought-river/negotiations-common';
import {
    fetchGetSuggestionsForProperties,
    SuggestionsV01,
} from '@thought-river/negotiations-common/dist/api/playbookManager';

@analytics()
class IssuesReport extends Component<IIssuesReportProps, IIssuesReportState> {
    constructor(props: IIssuesReportProps) {
        super(props);

        const paragraphsById = {};

        props.paragraphs.forEach((paragraph) => {
            paragraphsById[paragraph.id] = paragraph;
        });

        this.state = {
            columnsOrderData: {},
            isClausesSidebarOpen: false,
            showFilters: false,
            slideOutParagraphUuids: [],
            slideOutSelectedClause: null,
            slideOutTitle: '',
            slideOutRowId: null,
            paragraphsById,
            rows: [],
            rowsLoaded: false,
            suggestions: [],
        };

        this.closeClausesSidebar = this.closeClausesSidebar.bind(this);
        this.closeSuggestionsSidebar = this.closeSuggestionsSidebar.bind(this);
        this.copyIssuesTable = this.copyIssuesTable.bind(this);
        this.saveIssue = this.saveIssue.bind(this);
        this.saveProperty = this.saveProperty.bind(this);
        this.showClausesSidebar = this.showClausesSidebar.bind(this);
        this.showSuggestionsSidebar = this.showSuggestionsSidebar.bind(this);
        this.showEditColumnsModal = this.showEditColumnsModal.bind(this);
        this.toggleShowFilters = this.toggleShowFilters.bind(this);
        this.setResizedColumn = this.setResizedColumn.bind(this);
        this.setColumnsOrderData = this.setColumnsOrderData.bind(this);
        this.handleSelectedViewChange =
            this.handleSelectedViewChange.bind(this);
        this.handleSuggestionSelected =
            this.handleSuggestionSelected.bind(this);
    }

    componentDidMount(): void {
        const {
            columns,
            selectedFilterPreset,
            setContractSidebarWidthInPixels,
            setIssuesTableColumns,
            setSelectedIssueFilters,
            setSortModel,
            activeFilters,
        } = this.props;

        setContractSidebarWidthInPixels(480);

        let updatedColumns = [...columns];

        if (!columns.length || columns.length !== defaultColumns.length) {
            updatedColumns = [...defaultColumns];
            setIssuesTableColumns(updatedColumns);
            setSelectedIssueFilters(DEFAULT_FILTER_PRESET);
        }

        if (
            PresetIssueFilters[selectedFilterPreset] ===
            PresetIssueFilters.CommercialReview
        ) {
            updatedColumns = this.updateColumnVisibility(updatedColumns);

            this.manuallyReorderColumns(commercialReviewColumns);
        }

        setIssuesTableColumns(updatedColumns);

        const rows = this.getHybridDataGridRows();
        this.setTableRows(rows);

        setSortModel(
            activeFilters.issuesReportOptions?.defaultSortModel ??
                initialSortModel
        );
    }

    componentDidUpdate(prevProps: IIssuesReportProps): void {
        const {
            columns,
            downloadPdf,
            setDownloadPdf,
            sortModel,
            setSortModel,
            summaryProperties,
        } = this.props;

        if (!prevProps.downloadPdf && downloadPdf) {
            setDownloadPdf(false);
        }

        // If all of the sorted columns are no longer visible, reset to default sorting
        if (JSON.stringify(prevProps.columns) !== JSON.stringify(columns)) {
            const visibleColumnLabels = columns
                .filter((column) => column.active)
                .map((column) => column.label);

            let isSortedColumnVisible = false;
            for (const sortOption of sortModel) {
                if (
                    visibleColumnLabels.includes(
                        sortOption.field as IssuesTableHeader
                    )
                ) {
                    isSortedColumnVisible = true;
                    break;
                }
            }

            if (!isSortedColumnVisible) {
                setSortModel(initialSortModel);
            }
        }

        if (!prevProps.summaryProperties.length && summaryProperties.length) {
            const rows = this.getHybridDataGridRows();
            this.setTableRows(rows);
        }
    }

    async setTableRows(rows: IContractReportRow[]) {
        const { streamId } = this.props;

        const rowsWithPropertyId = rows.filter(
            (row) => !!row.lexiblePropertyId
        );

        if (rowsWithPropertyId.length) {
            try {
                const propertyIds = rowsWithPropertyId.map(
                    (row) => row.lexiblePropertyId ?? ''
                );

                const response = (await fetchGetSuggestionsForProperties({
                    pathParams: {
                        dealTypeUuid: streamId,
                    },
                    body: { property_uuids: propertyIds },
                })) as SuggestionsV01;

                this.setState({
                    rows: rows.map((row) => {
                        if (row.selectedSuggestion) {
                            return row;
                        }

                        const propertyId = row.lexiblePropertyId;
                        const firstSuggestion = response.find(
                            (s) => s.property_uuid === propertyId
                        )?.content;

                        return {
                            ...row,
                            selectedSuggestion: firstSuggestion ?? null,
                        };
                    }),
                    rowsLoaded: true,
                    suggestions: response,
                });
            } catch {
                //
            }
        }
    }

    async updateTableColumns() {
        const { columns, setIssuesTableColumns } = this.props;

        let updatedColumns = !columns.length ? defaultColumns : columns;

        updatedColumns = this.updateColumnVisibility(updatedColumns);
        setIssuesTableColumns([...updatedColumns]);
    }

    private async closeClausesSidebar() {
        this.setState({
            slideOutParagraphUuids: [],
            slideOutSelectedClause: null!, // Fixme: null checks
            slideOutTitle: '',
            isClausesSidebarOpen: false,
        });
    }

    private async closeSuggestionsSidebar() {
        this.setState({
            slideOutRowId: null,
        });
    }

    private async copyIssuesTable() {
        if (!navigator.clipboard) {
            console.warn('Clipboard not supported');
            return;
        }

        try {
            const permissionQuery = await navigator.permissions.query({
                //@ts-ignore
                name: 'clipboard-write',
            });
            const haveClipboardWritePermissions =
                permissionQuery.state !== 'denied';

            if (!haveClipboardWritePermissions) {
                console.warn("Don't have clipboard write permissions");
                return;
            }
        } catch (err) {
            console.warn(err);
            return;
        }

        const table = document.querySelector('.hybrid-report-table')?.outerHTML;
        if (!table) {
            return;
        }

        const blob = new Blob([table], { type: 'text/html' });

        navigator.clipboard.write([new ClipboardItem({ [blob.type]: blob })]);
    }

    private getClausesSidebarParagraphs() {
        const { paragraphs } = this.props;

        const { slideOutParagraphUuids } = this.state;

        return slideOutParagraphUuids.length
            ? paragraphs.filter((paragraph) =>
                  slideOutParagraphUuids.includes(paragraph.originUuid)
              )
            : [];
    }

    private async saveIssue(issue: ContractIssue): Promise<void> {
        const {
            contract,
            issues,
            setIssues,
            setIssuesReportSavedStatus,
            userId,
        } = this.props;

        const payload: IIssueRequestPayload = {
            data: {
                attributes: {
                    'category-uuids': issue.categories.map(
                        (category) => category.id
                    ),
                    description: issue.description,
                    notes: issue.notes,
                    'paragraph-origin-uuids': issue.clauseNumbers.map(
                        (clause) => clause.originUuid
                    ),
                    'severity-level': resolveSeverityLevelValue(
                        issue.severityLevel
                    ),
                    status: issue.status,
                    title: issue.title,
                },
            },
        };

        const updatedIssue = {
            ...issue,
            lastModifiedById: userId,
            updatedAt: dayjs()
                .subtract(dayjs().utcOffset(), 'minutes')
                .format(),
        };

        try {
            setIssues(
                issues.map((i) => (i.id === updatedIssue.id ? updatedIssue : i))
            );
            const rows = this.getHybridDataGridRows();
            this.setTableRows(rows);
            setIssuesReportSavedStatus(SubmitStatus.PROCESSING);
            await IssueResource.updateIssue(
                contract.id,
                contract.latestVersion?.id,
                issue.id,
                contract.streamCode,
                payload,
                ComponentReference.ISSUES_REPORT
            );
            setIssuesReportSavedStatus(SubmitStatus.SUCCESS);
        } catch {
            setIssuesReportSavedStatus(SubmitStatus.FAILURE);
        }
    }

    private async saveProperty(
        prevRow: IContractReportRow,
        row: IContractReportRow,
        entityPropertyValue: string
    ): Promise<void> {
        const {
            properties,
            setIssuesReportSavedStatus,
            summaryProperties,
            updateSummaryProperty,
        } = this.props;

        const {
            current: linkedParagraphs,
            added: newParagraphs,
            removed: removedParagraphs,
            emptied: unlink,
        } = arrayDifferenceResolver(
            prevRow.linkedParagraphClauses,
            row.linkedParagraphClauses,
            'originUuid'
        );

        const contractProperty = properties.find(
            (property) => property.code === row.propertyCode
        );
        const summaryProperty = summaryProperties.find(
            (property) => property.code === row.propertyCode
        );

        const value = resolvePropertyValue(
            contractProperty?.valueType!, // Fixme: null checks
            linkedParagraphs.length,
            'value',
            entityPropertyValue
        );

        if (summaryProperty) {
            updateSummaryProperty({
                ...summaryProperty,
                linkedParagraphIds: row.linkedParagraphClauses.map(
                    (clause) => clause.id
                ),
                paragraphOriginUuids: row.linkedParagraphClauses.map(
                    (clause) => clause.originUuid
                ),
                value: value!, // Fixme: null checks
            });

            const rows = this.getHybridDataGridRows();
            this.setTableRows(rows);
        }

        try {
            setIssuesReportSavedStatus(SubmitStatus.PROCESSING);

            await linkUnlinkPropertyParagraphs({
                code: row.propertyCode,
                linkValue: value!, // Fixme: null checks
                unlinkValue: NO_VALUE,
                unlink,
                newParagraphs,
                removedParagraphs,
            });

            syncContractData();

            setIssuesReportSavedStatus(SubmitStatus.SUCCESS);
        } catch {
            setIssuesReportSavedStatus(SubmitStatus.FAILURE);
        }
    }

    sendPredictionFeedbackHybridReport = async (
        row: IContractReportRow,
        feedback: -1 | 1
    ) => {
        const {
            assessment,
            contract,
            properties,
            selectedVersion,
            setContractProperties,
            userId,
        } = this.props;

        const property = properties.find((p) => p.id === row.propertyId);

        if (property) {
            const options = {
                contractId: contract.id,
                versionId: selectedVersion?.id ?? '',
                propertyId: row.propertyId!, // Fixme: null checks
                assessmentId: assessment.id,
                stream: contract.streamCode,
                feedback,
                referenceComponent: ComponentReference.ISSUES_REPORT,
            };

            const updatedProperty = {
                ...property,
                feedback: [
                    ...property.feedback,
                    {
                        userId,
                        vote: feedback,
                    },
                ],
            };

            try {
                const updatedProperties = properties.map((p) =>
                    p.id === property.id ? updatedProperty : p
                );
                setContractProperties(updatedProperties);
                return PropertyResource.sendFeedback(options);
            } catch {
                setContractProperties(properties);
                toast.error('Error sending predication feedback');
            }
        }
    };

    private setColumnsOrderData(columnsOrderData: IColumnsOrderData = {}) {
        if (
            JSON.stringify(this.state.columnsOrderData) !==
            JSON.stringify(columnsOrderData)
        ) {
            this.setState({
                columnsOrderData,
            });
        }
    }

    private setResizedColumn(field: IssuesTableHeader, width: number) {
        const { columns, setIssuesTableColumns } = this.props;

        const updatedColumns = [...columns];

        for (const column of updatedColumns) {
            if (column.label === field) {
                column.width = width;
            }
        }

        setIssuesTableColumns(updatedColumns);
    }

    private showClausesSidebar(
        paragraphUuids: string[],
        selectedClause: ParagraphClause,
        title: string
    ) {
        this.setState({
            slideOutParagraphUuids: paragraphUuids,
            slideOutSelectedClause: selectedClause,
            slideOutTitle: title,
            isClausesSidebarOpen: true,
        });
    }

    private async showSuggestionsSidebar(rowId: number) {
        this.setState({
            slideOutRowId: rowId,
        });
    }

    private handleSuggestionSelected(suggestionText: string) {
        const { setSelectedPropertySuggestion } = this.props;
        const { slideOutRowId, rows } = this.state;

        const targetRow = rows.find((row) => row.rowId === slideOutRowId);

        if (!targetRow || !targetRow.lexiblePropertyId) {
            return;
        }

        const updatedRows = rows.map((row) => {
            if (row.rowId === slideOutRowId) {
                return { ...row, selectedSuggestion: suggestionText };
            }
            return row;
        });

        setSelectedPropertySuggestion(
            targetRow.lexiblePropertyId,
            suggestionText
        );

        this.setState({
            rows: updatedRows,
        });
    }

    private showEditColumnsModal() {
        this.props.showDialog(
            null!, // Fixme: null checks
            false,
            <IssuesReportEditColumnsModal />,
            [],
            '500px',
            'issues-report-modal-wrapper'
        );
    }

    private toggleShowFilters() {
        this.setState({
            showFilters: !this.state.showFilters,
        });
    }

    private manuallyReorderColumns(orderedColumns: string[]) {
        const columnOrderData = {};

        orderedColumns.forEach((column, index) => {
            columnOrderData[column] = index;
        });

        this.setColumnsOrderData(columnOrderData);
    }

    private async handleSelectedViewChange(
        newSelectedFilterPreset: keyof typeof PresetIssueFilters
    ) {
        const {
            columns,
            setIssuesTableColumns,
            setSelectedIssueFilters,
            setSortModel,
        } = this.props;

        setSelectedIssueFilters(newSelectedFilterPreset);
        const updatedColumns = this.updateColumnVisibility(
            columns,
            newSelectedFilterPreset
        );
        setIssuesTableColumns(updatedColumns);

        const newFiltersPreset =
            filtersPresets[PresetIssueFilters[newSelectedFilterPreset]];
        setSortModel(
            newFiltersPreset?.issuesReportOptions?.defaultSortModel ??
                initialSortModel
        );
        this.manuallyReorderColumns(newFiltersPreset.columns);
    }

    private updateColumnVisibility(
        currentColumns: IIssuesTableColumn[],
        newSelectedFilterPreset?: keyof typeof PresetIssueFilters
    ): IIssuesTableColumn[] {
        const { selectedFilterPreset } = this.props;

        const selectedPresetName =
            newSelectedFilterPreset ?? selectedFilterPreset;
        const presetConfig: IIssueFiltersValues =
            filtersPresets[PresetIssueFilters[selectedPresetName]];

        const updatedColumns: IIssuesTableColumn[] = [];

        currentColumns.forEach((column) => {
            const active = presetConfig.columns.includes(column.label);

            updatedColumns.push({
                ...column,
                active,
            });
        });

        return updatedColumns;
    }

    private mapIssueToRow(
        issue: ContractIssue,
        property: IContractProperty,
        index: number
    ): IContractReportRow {
        const { selectedPropertySuggestionMap } = this.props;

        return {
            rowId: index,
            feedback: property?.feedback ?? [],
            severityLevel: issue.severityLevel,
            categories: issue.categories,
            clausesEditable:
                !property || property?.valueType === ValueType.DISCRETE,
            description: issue.description,
            familiarity: issue.organizationFamiliarity?.frequencyLevel!, // Fixme: null checks
            issueId: issue.id,
            notes: issue.notes,
            title: issue.title,
            issueUpdatedAt: issue.updatedAt,
            linkedParagraphClauses: issue.clauseNumbers,
            missingSuggestionLocations: issue.missingSuggestionLocations,
            nonAssessedThemeIds: issue.nonAssessedThemeIds,
            lexiblePropertyId: issue.lexiblePropertyId,
            paragraphOriginUuids: issue.paragraphOriginUuids,
            propertyCode: property?.code ?? '',
            propertyId: property?.id ?? '',
            propertyLabel: property?.shortLabel ?? '',
            propertyName: property?.name ?? '',
            propertyQuestion: property?.question ?? '',
            propertyOptions: [],
            propertyInputType: resolvePropertyInputType(
                property?.valueType,
                []
            ),
            propertyValueType: property?.valueType ?? null,
            propertyValue: property?.value ?? '',
            propertyTheme: property?.theme,
            selectedSuggestion:
                selectedPropertySuggestionMap[issue.lexiblePropertyId ?? ''] ??
                null,
            status: issue.status,
        };
    }

    private mapSummaryPropertyToRow(
        property: ISummaryProperty,
        index: number
    ): IContractReportRow {
        const { selectedPropertySuggestionMap } = this.props;

        const { paragraphsById } = this.state;

        const clauses: ParagraphClause[] = [];
        let lowestOrgFrequencyParagraph: IContractParagraph | undefined;

        property.linkedParagraphIds.forEach((id) => {
            const paragraph = paragraphsById[id];
            if (paragraph) {
                clauses.push(mapClauseNumber(paragraph));

                if (lowestOrgFrequencyParagraph) {
                    if (
                        paragraph.organisationFamiliarity.frequency <
                        lowestOrgFrequencyParagraph.organisationFamiliarity
                            .frequency
                    ) {
                        lowestOrgFrequencyParagraph = paragraph;
                    }
                } else {
                    lowestOrgFrequencyParagraph = paragraph;
                }
            }
        });

        return {
            categories: [],
            clausesEditable: property.valueType === ValueType.DISCRETE,
            description: '',
            familiarity:
                lowestOrgFrequencyParagraph?.organisationFamiliarity
                    .frequencyLevel!, // Fixme: null checks
            feedback: [],
            issueId: null,
            issueUpdatedAt: null!, // Fixme: null checks
            linkedParagraphClauses: clauses,
            missingSuggestionLocations: [],
            nonAssessedThemeIds: [],
            lexiblePropertyId: property.lexiblePropertyId,
            notes: '',
            paragraphOriginUuids: clauses.map((number) => number.originUuid),
            propertyCode: property.code,
            propertyId: property.id,
            propertyLabel: property.shortLabel,
            propertyName: property.name,
            propertyQuestion: property.question,
            propertyOptions: property.options,
            propertyInputType: property.inputType,
            propertyValueType: property.valueType,
            propertyValue: property.value,
            propertyTheme: property.theme,
            rowId: index,
            selectedSuggestion:
                selectedPropertySuggestionMap[property.id ?? ''] ?? null,
            severityLevel: property.severityLevel,
            status: null,
            title: '',
        };
    }

    getHybridDataGridRows(): IContractReportRow[] {
        const { issues, properties, summaryProperties } = this.props;

        const rows: IContractReportRow[] = [];
        const uniquePropertyIds: string[] = [];
        const propertyCodeToPropertyMap: {
            [propertyCode: string]: IContractProperty;
        } = {};
        const propertyCodeToIssueMap: {
            [propertyCode: string]: ContractIssue;
        } = {};

        properties.forEach((property) => {
            propertyCodeToPropertyMap[property.code] = property;
        });

        issues.forEach((issue) => {
            propertyCodeToIssueMap[issue.propertyCode ?? ''] = issue;
        });

        let rowIndex = 0;

        summaryProperties
            .sort((propertyA, propertyB) => propertyA.order - propertyB.order)
            .forEach((property) => {
                const matchingContractIssue =
                    propertyCodeToIssueMap[property.code];
                const matchingContractProperty =
                    propertyCodeToPropertyMap[property.code];

                if (matchingContractIssue) {
                    rows.push(
                        this.mapIssueToRow(
                            matchingContractIssue,
                            matchingContractProperty,
                            rowIndex
                        )
                    );
                } else {
                    rows.push(this.mapSummaryPropertyToRow(property, rowIndex));
                }
                uniquePropertyIds.push(property.id!); // Fixme: null checks
                rowIndex++;
            });

        issues.forEach((issue) => {
            const property =
                propertyCodeToPropertyMap[issue.propertyCode ?? ''];

            if (!uniquePropertyIds.includes(property?.id)) {
                rows.push(this.mapIssueToRow(issue, property, rowIndex));
                rowIndex++;
            }
        });

        return rows;
    }

    render() {
        const {
            activeFilters,
            columns,
            contract,
            featureToggles,
            issues,
            lastValidPreset,
            propertyTemplatesLoaded,
            paragraphs,
            properties,
            selectedFilterPreset,
            setSortModel,
            sidebarWidth,
            sortModel,
            userId,
            summaryProperties,
            selectedVersion,
        } = this.props;

        const {
            columnsOrderData,
            isClausesSidebarOpen,
            rows,
            rowsLoaded,
            slideOutParagraphUuids,
            slideOutSelectedClause,
            slideOutTitle,
            slideOutRowId,
            showFilters,
            suggestions,
        } = this.state;

        const summaryPropertyCodes = summaryProperties.map((p) => p.code);

        if (!selectedVersion) {
            return null;
        }

        const slideOutRow = rows.find((r) => r.rowId === slideOutRowId) ?? null;

        return (
            <div className="issues-report">
                <IssuesReportDetails version={selectedVersion} />
                <IssuesReportToolbar
                    featureToggles={featureToggles}
                    onCopyTableCallback={this.copyIssuesTable}
                    onSelectViewCallback={this.handleSelectedViewChange}
                    onShowEditColumnsCallback={this.showEditColumnsModal}
                    onShowFiltersCallback={this.toggleShowFilters}
                    selectedView={selectedFilterPreset}
                />
                <HybridReportDataGrid
                    activeFilters={activeFilters}
                    columns={columns}
                    columnsOrderData={columnsOrderData}
                    contract={contract}
                    issues={issues}
                    rows={
                        propertyTemplatesLoaded
                            ? (rows as IContractReportRow[])
                            : []
                    }
                    featureToggles={featureToggles}
                    lastValidView={PresetIssueFilters[lastValidPreset]}
                    loading={!propertyTemplatesLoaded || !rowsLoaded}
                    onSaveIssue={this.saveIssue}
                    onSaveProperty={this.saveProperty}
                    onSendFeedback={this.sendPredictionFeedbackHybridReport}
                    onSelectClause={this.showClausesSidebar}
                    onSelectSuggestion={this.showSuggestionsSidebar}
                    onSortChange={setSortModel}
                    onResizeColumn={this.setResizedColumn}
                    onReorderColumns={this.setColumnsOrderData}
                    paragraphs={paragraphs}
                    properties={properties}
                    sortModel={sortModel}
                    selectedView={PresetIssueFilters[selectedFilterPreset]}
                    summaryPropertyCodes={summaryPropertyCodes}
                    version={selectedVersion}
                    userId={userId}
                />
                <HybridReportCopyableTable
                    activeFilters={activeFilters}
                    columns={columns}
                    contract={contract}
                    columnsOrderData={columnsOrderData}
                    rows={rows as IContractReportRow[]}
                    featureToggles={featureToggles}
                    lastValidView={PresetIssueFilters[lastValidPreset]}
                    sortModel={sortModel}
                    summaryPropertyCodes={summaryPropertyCodes}
                    unformattedCopy={true}
                    version={selectedVersion}
                />
                <IssueListFilters
                    context={FiltersContext.IssuesReport}
                    onClose={this.toggleShowFilters}
                    show={showFilters}
                />
                <ClausesSidebar
                    paragraphs={this.getClausesSidebarParagraphs()}
                    onCloseCallback={this.closeClausesSidebar}
                    selectedParagraphOriginUuid={
                        slideOutSelectedClause?.originUuid ?? null
                    }
                    show={
                        !!slideOutParagraphUuids.length && isClausesSidebarOpen
                    }
                    title={slideOutTitle}
                    uniqueId={slideOutTitle.replace(/\s+/, '').toLowerCase()}
                    width={sidebarWidth}
                />
                <SuggestionsSidebar
                    contract={contract}
                    row={slideOutRow}
                    onClose={this.closeSuggestionsSidebar}
                    onSuggestionSelected={this.handleSuggestionSelected}
                    show={!!slideOutRow}
                    width={sidebarWidth}
                    suggestions={suggestions}
                />
            </div>
        );
    }
}

export default IssuesReport;
