import { combineReducers } from 'redux';
import AppReducer from '../components/App/App-reducer';
import AuthReducer from '../components/Auth/Auth-reducer';
import ContractListReducer from '../components/ContractList/ContractList-reducer';
import ContractListToolbarReducer from '../components/ContractListToolbar/ContractListToolbar-reducer';
import ContractReducer from '../components/Contract/Contract-reducer';
import HeatmapSearchBarReducer from '../components/HeatmapSearchBar/HeatmapSearchBar-reducer';
import InternalSharingModalReducer from '../components/InternalSharingModal/InternalSharingModal-reducer';
import ModalDialogReducer from '../components/ModalDialog/ModalDialog-reducer';
import PaneDividerReducer from '../components/PaneDivider/PaneDivider-reducer';
import PartiesReducer from '../components/Parties/Parties-reducer';
import PartyContractReducer from '../components/PartyContract/PartyContract-reducer';
import PartyCorrectionReducer from '../components/PartyCorrection/PartyCorrection-reducer';
import { IRootState } from './reducers-types';
import ContractSidebarReducer from '../components/ContractSidebar/ContractSidebar-reducer';
import IssuePaneReducer from '../components/IssuePane/IssuePane-reducer';
import IssueListFiltersReducer from '../components/IssueListFilters/IssueListFilters-reducer';
import HeatmapPaneReducer from '../components/HeatmapPane/HeatmapPane-reducer';
import ContractPollingProviderReducer from '../components/ContractPollingProvider/ContractPollingProvider-reducer';
import FeatureToggleProviderReducer from '../components/FeatureToggleProvider/FeatureToggleProvider-reducer';
import IssuesReportReducer from '../components/IssuesReport/IssuesReport-reducer';

const rootReducer = combineReducers<IRootState>({
    app: AppReducer,
    auth: AuthReducer,
    contract: ContractReducer,
    contractList: ContractListReducer,
    contractListToolbar: ContractListToolbarReducer,
    contractPollingProvider: ContractPollingProviderReducer,
    contractSidebar: ContractSidebarReducer,
    featureToggles: FeatureToggleProviderReducer,
    heatmapPane: HeatmapPaneReducer,
    internalSharing: InternalSharingModalReducer,
    issueModal: IssuePaneReducer,
    issueListFilters: IssueListFiltersReducer,
    issuePane: IssuePaneReducer,
    issuesReport: IssuesReportReducer,
    modalDialog: ModalDialogReducer,
    paneDivider: PaneDividerReducer,
    parties: PartiesReducer,
    partyContract: PartyContractReducer,
    partyCorrection: PartyCorrectionReducer,
    searchBar: HeatmapSearchBarReducer,
});

export default rootReducer;
