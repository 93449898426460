import {
    HIDE_DIALOG,
    IHideDialogAction,
    IModalDialogButton,
    IShowDialogAction,
    IUpdateAutoCloseAction,
    IUpdateButtonsAction,
    SHOW_DIALOG,
    UPDATE_AUTO_CLOSE,
    UPDATE_BUTTONS,
} from './ModalDialog-types';

export function showDialogAction(
    title: string | JSX.Element,
    showCloseButton: boolean = true,
    component: JSX.Element,
    buttons: IModalDialogButton[] = [],
    height: string | null | undefined = undefined,
    customClassname: string = '',
    closeOnBlur: boolean = true
): IShowDialogAction {
    return {
        type: SHOW_DIALOG,
        title,
        showCloseButton,
        buttons,
        component,
        height,
        customClassname,
        closeOnBlur,
    };
}

export function hideDialogAction(): IHideDialogAction {
    return {
        type: HIDE_DIALOG,
    };
}

export function updateAutoCloseAction(
    autoClose: boolean
): IUpdateAutoCloseAction {
    return {
        type: UPDATE_AUTO_CLOSE,
        autoClose,
    };
}

export function updateButtonsAction(
    buttons: IModalDialogButton[]
): IUpdateButtonsAction {
    return {
        type: UPDATE_BUTTONS,
        buttons,
    };
}
