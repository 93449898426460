import { Action } from 'redux';
import { IAccountUser } from '../Auth/Auth-types';
import { IContractVersion, IDisplayContract } from '../Contract/Contract-types';
import { IContractParagraph } from '../Paragraph/Paragraph-types';

export const SET_INTERNAL_SHARING_MESSAGE = 'SET_INTERNAL_SHARING_MESSAGE';
export const SET_INTERNAL_SHARING_REVIEWERS = 'SET_INTERNAL_SHARING_REVIEWERS';
export const SET_INTERNAL_SHARING_FILTERS = 'SET_INTERNAL_SHARING_FILTERS';
export const RESET_INTERNAL_SHARING_FILTERS = 'RESET_INTERNAL_SHARING_FILTERS';

export interface IInternalSharingModalState {
    submitting: boolean;
}

export interface IInternalSharingModalStateProps {
    contract: IDisplayContract;
    message: string;
    paragraphs: IContractParagraph[];
    reviewers: IAccountUser[];
    selectedVersion: IContractVersion | null;
    userId: string;
    users: IAccountUser[];
    versions: IContractVersion[];
}

export interface IInternalSharingModalReducer {
    message: string;
    reviewers: IAccountUser[];
}

export interface IInternalSharingModalDispatchProps {
    closeModal: () => void;
    resetInternalSharingFilters: () => void;
    setInternalSharingMessage: (messsage: string) => void;
    setInternalSharingReviewers: (reviewers: IAccountUser[]) => void;
    setReviewedParagraphs: (
        reviewed: boolean,
        paragraphIndices: number[]
    ) => void;
}

export interface IInternalSharingModalProps
    extends IInternalSharingModalDispatchProps,
        IInternalSharingModalStateProps {}

export interface ISetInternalSharingMessageAction extends Action {
    type: typeof SET_INTERNAL_SHARING_MESSAGE;
    message: string;
}

export interface ISetInternalSharingReviewersAction extends Action {
    type: typeof SET_INTERNAL_SHARING_REVIEWERS;
    reviewers: IAccountUser[];
}

export interface IResetInternalSharingFilters extends Action {
    type: typeof RESET_INTERNAL_SHARING_FILTERS;
}

export type IInternalSharingModalActionTypes =
    | ISetInternalSharingMessageAction
    | ISetInternalSharingReviewersAction
    | IResetInternalSharingFilters;
