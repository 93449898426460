import { Dispatch } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { IRootState } from '../../../redux/reducers-types';
import { setIssueFormAction } from '../IssuePane-actions';
import { IIssueForm } from '../IssuePane-types';
import IssuePaneTabs from './IssuePaneTabs';
import {
    IIssuePaneTabsDispatchProps,
    IIssuePaneTabsStateProps,
} from './IssuePaneTabs-types';

function mapStateToProps(state: IRootState): IIssuePaneTabsStateProps {
    return {
        form: state.issuePane.form,
        issue: state.contract.selectedIssue,
        paragraphs: state.contract.paragraphs,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IIssuePaneTabsDispatchProps {
    return {
        setIssueForm: (form: IIssueForm) => dispatch(setIssueFormAction(form)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssuePaneTabs);
