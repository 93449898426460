import { modulo } from '@modules/common/helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { extractLeadingElements, scrollToMatch } from './helpers';
import { UseSearchMatchHighlightParams } from './types';
import Mark from 'mark.js';
import debounce from 'lodash/debounce';

/**
 * Hook encapsulating the navigation logic in ContractSearchProvider
 * It tracks the currently highlighted DOM nodes and facilitates
 * navigation between them
 */
export const useSearchNavigation = () => {
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [highlightedElements, setHighlightedElements] = useState<
        HTMLElement[]
    >([]);

    const handleHighlightDone = useCallback(
        (searchTerm: string, highlightedElements: HTMLElement[]) => {
            const leadingElements = extractLeadingElements(
                highlightedElements,
                searchTerm
            );

            setHighlightedElements(leadingElements);

            if (leadingElements[0]) {
                scrollToMatch(leadingElements[0]);
                setHighlightedIndex(0);
            } else {
                setHighlightedIndex(-1);
            }
        },
        []
    );

    const navigate = useCallback(
        (delta: -1 | 1) => {
            const newIndex = modulo(
                highlightedIndex + delta,
                highlightedElements.length
            );
            setHighlightedIndex(newIndex);

            const element = highlightedElements[newIndex];
            if (element) {
                scrollToMatch(element);
            }
        },
        [highlightedElements, highlightedIndex]
    );

    return {
        onHighlightDone: handleHighlightDone,
        navigate,
        highlightedMatchIndex: highlightedIndex,
        totalMatches: highlightedElements.length,
    };
};

export const useSearchMatchHighlight = ({
    searchTerm,
    onHighlightDone,
}: UseSearchMatchHighlightParams) => {
    const [markInstance, setMarkInstance] = useState<typeof Mark | null>(null);
    const [contentElement, setContentElement] = useState<HTMLElement | null>(
        null
    );

    const registerContentElement = useCallback(
        (node: HTMLElement) => {
            if (node !== contentElement) {
                setContentElement(node);
            }
        },
        [contentElement]
    );

    useEffect(() => {
        if (contentElement) {
            setMarkInstance(new Mark(contentElement));
        }
    }, [contentElement]);

    const highlightMatches = useCallback(
        (searchTerm: string) => {
            if (!markInstance) {
                return;
            }

            markInstance.unmark();
            if (searchTerm !== '') {
                const elements: HTMLElement[] = [];

                markInstance.mark(searchTerm, {
                    separateWordSearch: false,
                    acrossElements: true,
                    each: (element: HTMLElement) => {
                        elements.push(element);
                    },
                    done: () => {
                        onHighlightDone(searchTerm, elements);
                    },
                });
            } else {
                onHighlightDone(searchTerm, []);
            }
        },
        [markInstance, onHighlightDone]
    );

    const debouncedHighlightMatches = useMemo(
        () =>
            debounce((searchTerm: string) => highlightMatches(searchTerm), 300),
        [highlightMatches]
    );

    useEffect(() => {
        if (searchTerm === '') {
            // Clear highlights without delay
            highlightMatches('');
            debouncedHighlightMatches.cancel();
        } else {
            debouncedHighlightMatches(searchTerm);
        }
    }, [debouncedHighlightMatches, highlightMatches, searchTerm]);

    return {
        registerContentElement,
    };
};
