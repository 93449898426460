import { ContentCopy } from '@mui/icons-material';
import { IconButton, Tooltip } from '@thought-river/ui-components';
import { useEffect, useState } from 'react';

const CopyToClipboardButton = ({ textToCopy }: { textToCopy: string }) => {
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (copied) {
                setCopied(false);
            }
        }, 1500);

        return () => clearTimeout(timeout);
    }, [copied]);

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(textToCopy);
        setCopied(true);
    };

    const tooltipTitle = copied ? 'Copied' : 'Copy to clipboard';

    return (
        <Tooltip title={tooltipTitle}>
            <IconButton onClick={handleCopyToClipboard} size="small">
                <ContentCopy />
            </IconButton>
        </Tooltip>
    );
};

export default CopyToClipboardButton;
