import { ContractVersionReportRow } from '@thought-river/negotiations-common/dist/api/contractContent';
import { GridValueOptionsParams } from '@mui/x-data-grid-premium';
import { issueSeverityOptions } from '@thought-river/negotiations-common';

export const valueOptions = (
    params: GridValueOptionsParams,
    rows: ContractVersionReportRow[]
) => {
    if (params.field === 'issue_severity_level') {
        return issueSeverityOptions;
    }

    if (params.field === 'contract_primary_section') {
        const primarySectionsSet = new Set(
            rows.reduce<string[]>((sections, row) => {
                if (row.contract_primary_section) {
                    sections.push(row.contract_primary_section);
                }
                return sections;
            }, [])
        );
        return [...primarySectionsSet];
    }

    if (params.field === 'contract_sub_sections') {
        const subSectionsSet = new Set(
            rows.reduce<string[]>((sections, row) => {
                if (row.contract_sub_sections) {
                    sections.push(...row.contract_sub_sections);
                }
                return sections;
            }, [])
        );
        return [...subSectionsSet];
    }
};
