import { ContractIssue } from '@thought-river/negotiations-common';
import { analyticsService } from '../../services/Analytics/Analytics';
import {
    EVENT_ACTION_COPY_ISSUES_TABLE,
    EVENT_ACTION_VIEW_ISSUES_REPORT,
    EVENT_ACTION_VIEW_LINKED_CLAUSE,
} from '../../services/Analytics/Analytics-types';
import { IIssuesReportProps } from './IssuesReport-types';

export function analytics() {
    return (target: any) => {
        const componentDidMount = target.prototype.componentDidMount;
        const showClausesSidebar = target.prototype.showClausesSidebar;
        const copyIssuesTable = target.prototype.copyIssuesTable;

        target.prototype.componentDidMount = function () {
            const { contract, selectedVersion } = this
                .props as IIssuesReportProps;

            analyticsService.recordEvent(
                EVENT_ACTION_VIEW_ISSUES_REPORT,

                {
                    ContractId: contract?.id,
                    Stream: contract?.streamCode,
                    VersionId: selectedVersion?.id,
                    VersionNumber: selectedVersion?.versionNumber,
                }
            );
            componentDidMount.apply(this, arguments);
        };

        target.prototype.showClausesSidebar = function (issue: ContractIssue) {
            const { contract, selectedVersion } = this
                .props as IIssuesReportProps;

            analyticsService.recordEvent(
                EVENT_ACTION_VIEW_LINKED_CLAUSE,

                {
                    ContractId: contract?.id,
                    Stream: contract?.streamCode,
                    VersionId: selectedVersion?.id,
                    VersionNumber: selectedVersion?.versionNumber,
                    IssueId: issue?.id,
                }
            );

            showClausesSidebar.apply(this, arguments);
        };

        target.prototype.copyIssuesTable = function () {
            const { contract, selectedVersion } = this
                .props as IIssuesReportProps;

            analyticsService.recordEvent(
                EVENT_ACTION_COPY_ISSUES_TABLE,

                {
                    ContractId: contract?.id,
                    Stream: contract?.streamCode,
                    VersionId: selectedVersion?.id,
                    VersionNumber: selectedVersion?.versionNumber,
                }
            );

            copyIssuesTable.apply(this, arguments);
        };
    };
}
