import * as React from 'react';
import { toast } from 'react-toastify';

import ContractListToolbar from '../ContractListToolbar/ContractListToolbar-container';
import ContractListTable from '../ContractListTable/ContractListTable-container';

import {
    IContractListProps,
    IContractListState,
    NOTIFICATION_TYPE_FAILED_PROCESSING,
    NOTIFICATION_TYPE_FAILED_REPROCESSING,
    NOTIFICATION_TYPE_SUCCESSFUL_PROCESSING,
    NOTIFICATION_TYPE_SUCCESSFUL_REPROCESSING,
    PAGE_TITLE_CONTRACT_LIST,
} from './ContractList-types';
import { analytics } from './ContractList-analytics';
import {
    IProcessingContract,
    IReprocessingContract,
} from '../ContractPollingProvider/ContractPollingProvider-types';

@analytics()
class ContractList extends React.Component<
    IContractListProps,
    IContractListState
> {
    constructor(props: IContractListProps) {
        super(props);

        this.state = {
            downloading: false,
        };
    }

    async componentDidMount() {
        document.title = PAGE_TITLE_CONTRACT_LIST;

        this.handleNotifications(true);
    }

    private handleNotifications(
        initial: boolean = false,
        type: string | null = null
    ) {
        const {
            successfulProcessedContracts,
            successfulReprocessedContracts,
            failedProcessedContracts,
            failedReprocessedContracts,
            unsetNotifiedContract,
        } = this.props;

        let contract: IProcessingContract | IReprocessingContract;
        let message: string;

        if (initial || type === NOTIFICATION_TYPE_SUCCESSFUL_PROCESSING) {
            for (contract of successfulProcessedContracts) {
                unsetNotifiedContract(contract.contractId);
            }
        }

        if (initial || type === NOTIFICATION_TYPE_SUCCESSFUL_REPROCESSING) {
            for (contract of successfulReprocessedContracts) {
                unsetNotifiedContract(contract.contractId);
            }
        }

        if (initial || type === NOTIFICATION_TYPE_FAILED_PROCESSING) {
            for (contract of failedProcessedContracts) {
                unsetNotifiedContract(contract.contractId);
            }
        }

        if (initial || type === NOTIFICATION_TYPE_FAILED_REPROCESSING) {
            for (contract of failedReprocessedContracts) {
                message = `Contract ${contract.contractName} failed to reprocess`;

                toast.error(message);

                unsetNotifiedContract(contract.contractId);
            }
        }
    }

    render() {
        return (
            <div className="contract-list">
                <div className="contract-list-section-top">
                    <div className="contract-list-toolbar-wrapper">
                        <ContractListToolbar />
                    </div>
                </div>
                <div className="contract-list-section-bottom">
                    <ContractListTable />
                </div>
            </div>
        );
    }
}

export default ContractList;
