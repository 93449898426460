import { useHistory, useParams } from 'react-router-dom';
import {
    useGetReportDefinition,
    useUpdateReportDefinition,
} from '@thought-river/negotiations-common/dist/api/playbookManager';
import {
    ReportDefinitionIn,
    ReportDefinitionOut,
} from '@thought-river/negotiations-common/dist/api/playbookManager';
import {
    ReportColumn,
    ReportData,
    ReportFilters as BEReportFilters,
    ReportCellCount,
} from '@thought-river/negotiations-common/dist/api/contractContent';
import { useGetReportCellCount } from '@thought-river/negotiations-common/dist/api/contractContent';
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
    GenericReportConfigPage,
    ReportFilters,
} from '@modules/reports/common';
import {
    BaseReportDefinitionRequestBody,
    ColumnSelection,
    ErrorBanner,
    REPORTS_MAX_CELL_COUNT,
    filterComponents,
    getIsColumnHideable,
    reportColumnLabels,
    resetTanStackQueryReportData,
    updateTanStackQueryReportDefinitionsData,
    useGetBaseReportDefinitionRequestBody,
    useLexibleContext,
    useReportsAnalytics,
} from '@modules/reports/portfolioReports';
import { useGetReportConfig } from './hooks/useGetReportConfig';

const EditReportPage = () => {
    const { id: reportId } = useParams<{ id: string }>();
    const queryClient = useQueryClient();
    const history = useHistory();

    const { propertiesDict } = useLexibleContext();

    const { mutate: getReportCellCount, isLoading: getReportCellCountLoading } =
        useGetReportCellCount({});
    const {
        mutate: updateReportDefinition,
        isLoading: updateReportDefinitionLoading,
    } = useUpdateReportDefinition({});

    const { data: reportDefinition } = useGetReportDefinition({
        pathParams: { reportDefinitionId: reportId },
    });

    const { trackApplyReportChanges, trackCancelReportChanges } =
        useReportsAnalytics();

    const [showBanner, setShowBanner] = useState(false);

    const {
        selectedColumns: preselectedColumns,
        selectedFilters: preselectedFilters,
        visibleColumns: predefinedVisibleColumns,
    } = useGetReportConfig(reportDefinition);

    const [selectedColumns, setSelectedColumns] = useState<
        (ReportColumn | string)[]
    >([]);
    const [selectedFilters, setSelectedFilters] = useState<ReportFilters>({});
    const [visibleColumns, setVisibleColumns] = useState<
        (ReportColumn | string)[]
    >([]);

    useEffect(() => {
        /**
         * NOTE: It is possible for a previously deleted property to still be present in
         * the columns list. We need to filter preselected columns to ones that are in
         * the report or are known properties
         * See: https://thoughtriver.atlassian.net/browse/EN-6554
         */
        const filteredColumns = preselectedColumns.filter(
            (column) =>
                reportDefinition?.columns.includes(column) ||
                propertiesDict[column]
        );
        setSelectedColumns(filteredColumns);
    }, [preselectedColumns, propertiesDict, reportDefinition?.columns]);

    useEffect(() => {
        setSelectedFilters(preselectedFilters);
    }, [preselectedFilters]);

    useEffect(() => {
        setVisibleColumns(predefinedVisibleColumns);
    }, [predefinedVisibleColumns]);

    const reportDefinitionRequestBody = useGetBaseReportDefinitionRequestBody(
        selectedColumns,
        selectedFilters,
        visibleColumns
    );

    const handleUpdateReportDefinitionSuccess = (data: ReportDefinitionOut) => {
        if (!reportDefinition) {
            return;
        }

        updateTanStackQueryReportDefinitionsData(queryClient, data);
        resetTanStackQueryReportData(
            queryClient,
            reportDefinition.id_str ?? ''
        );
        history.push(`/reports/${data.id_str}`);
    };

    const updateReport = (
        reportDefinitionRequestBody: BaseReportDefinitionRequestBody
    ) => {
        if (!reportDefinition) {
            return;
        }

        const requestBody: ReportDefinitionIn = {
            ...reportDefinition,
            columns: reportDefinitionRequestBody.columns,
            properties: reportDefinitionRequestBody.properties,
            //TODO: need to combing this with reportDefinition.config otherwise we lose other config like sorting, filters, etc...
            config: reportDefinitionRequestBody.config,
            filters: reportDefinitionRequestBody.filters,
        };

        setShowBanner(false);
        updateReportDefinition(
            {
                body: requestBody,
                pathParams: {
                    reportDefinitionId: reportId,
                },
            },
            {
                onSuccess: handleUpdateReportDefinitionSuccess,
                onError: () => toast.error(`Error updating report!`),
            }
        );
    };

    const handleApply = () => {
        const reportCellCountBody: ReportData = {
            columns: reportDefinitionRequestBody.columns as ReportColumn[],
            properties: reportDefinitionRequestBody.properties,
            filters: reportDefinitionRequestBody.filters as BEReportFilters,
        };

        trackApplyReportChanges(reportDefinitionRequestBody);
        getReportCellCount(
            {
                body: reportCellCountBody,
            },
            {
                onSuccess: (data: ReportCellCount) => {
                    if (data.cell_count >= REPORTS_MAX_CELL_COUNT) {
                        setShowBanner(true);
                        return;
                    }
                    updateReport(reportDefinitionRequestBody);
                },
                onError: (error) => console.error(error),
            }
        );
    };

    const getColumnName = (column: string) =>
        reportColumnLabels[column] || propertiesDict[column]?.short_label;

    return (
        <>
            {showBanner && <ErrorBanner onClose={() => setShowBanner(false)} />}
            <GenericReportConfigPage
                selectedColumns={selectedColumns}
                selectedFilters={selectedFilters}
                visibleColumns={visibleColumns}
                applyBtnDisabled={
                    getReportCellCountLoading || updateReportDefinitionLoading
                }
                columnFilterComponents={filterComponents}
                onCancelBtnClick={() => {
                    history.push(`/reports/${reportId}`);
                    trackCancelReportChanges();
                }}
                getColumnLabel={getColumnName}
                getIsColumnHideable={getIsColumnHideable}
                onApplyBtnClick={handleApply}
                onSelectedColumnsChange={setSelectedColumns}
                onSelectedFiltersChange={setSelectedFilters}
                onVisibleColumnsChange={setVisibleColumns}
                ColumnSelection={ColumnSelection}
            />
        </>
    );
};

export default EditReportPage;
