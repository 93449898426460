import { GridValueOptionsParams } from '@mui/x-data-grid-premium';
import { ContractStatusFilterOption } from '../../types';

export const valueOptions = (params: GridValueOptionsParams) => {
    if (params.field === 'status') {
        return [
            ContractStatusFilterOption.Signed,
            ContractStatusFilterOption.Template,
        ];
    }
};
