import { DragIndicator } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { Component } from 'react';
import { snakeCaseToLabel } from '../App/App-helpers';
import {
    DualListType,
    IDualItem,
    IDualListEditorProps,
    IDualListEditorState,
} from './DualListEditor-types';

import './DualListEditor.scss';
class DualListEditor extends Component<
    IDualListEditorProps,
    IDualListEditorState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            items: [...this.props.items],
            draggedItem: null,
        };

        this.onDragDrop = this.onDragDrop.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.renderDraggableLabel = this.renderDraggableLabel.bind(this);
    }

    componentDidUpdate(prevProps: IDualListEditorProps): void {
        const { items } = this.props;

        if (JSON.stringify(prevProps.items) !== JSON.stringify(items)) {
            this.setState({
                items,
            });
        }
    }

    deleteItem(itemLabel: string) {
        this.setState({
            items: this.state.items.filter((item) => item.label !== itemLabel),
        });
    }

    private onDragOver(event: any) {
        event.preventDefault();
    }

    private onDragStart(_: any, itemLabel: any) {
        this.setState({
            draggedItem: itemLabel,
        });
    }

    private onDragDrop(event: any) {
        event.preventDefault();

        const { onChange } = this.props;
        const { items, draggedItem } = this.state;

        const dropTarget = event.target;
        let dropTargetClass = event.target.classList;

        if (!dropTargetClass.contains('list')) {
            try {
                dropTargetClass = dropTarget.closest('.list')?.classList ?? '';
            } catch {
                //
            }
        }

        const updatedItems = [];

        for (const item of items) {
            const itemCopy = { ...item };
            const expectedTarget = itemCopy.active
                ? DualListType.INACTIVE
                : DualListType.ACTIVE;

            if (
                itemCopy.label === draggedItem &&
                dropTargetClass.contains(expectedTarget)
            ) {
                itemCopy.active = !itemCopy.active;
            }

            updatedItems.push(itemCopy);
        }

        onChange(updatedItems);

        this.setState({
            items: updatedItems,
            draggedItem: null,
        });
    }

    private renderList(label: string, items: IDualItem[], type: DualListType) {
        const { loading } = this.props;

        return (
            <div
                className={`list ${type}`}
                onDragOver={this.onDragOver}
                onDrop={this.onDragDrop}
            >
                <div className="list-title">{label}</div>
                <div className="list-items">
                    {loading
                        ? this.renderSkeleton()
                        : items.map(this.renderDraggableLabel)}
                </div>
            </div>
        );
    }

    private renderSkeleton() {
        const { skeletonItems = 10 } = this.props;

        const skeletons = [];
        for (let i = 0; i < skeletonItems; i++) {
            skeletons.push(
                <Skeleton key={`skeleton-item-${i}`} className="skeleton-row" />
            );
        }
        return skeletons;
    }

    swapItems(): void {
        const { onChange } = this.props;
        const { items } = this.state;

        items.map((item) => {
            item.active = !item.active;
            return item;
        });

        onChange(items);

        this.setState({
            items,
        });
    }

    private renderDraggableLabel(item: IDualItem): JSX.Element {
        const { itemRenderer } = this.props;

        return (
            <div
                className="draggable-label"
                draggable
                key={item.label}
                onDragStart={(event) => this.onDragStart(event, item.label)}
            >
                <DragIndicator className="drag-icon" />
                <span className="drag-label">
                    {itemRenderer
                        ? itemRenderer(item)
                        : snakeCaseToLabel(item.label)}
                </span>
            </div>
        );
    }

    render() {
        const { firstListTitle, secondListTitle } = this.props;

        const { items } = this.state;

        const firstListItems = [];
        const secondListItems = [];

        for (const item of items) {
            if (item.active) {
                secondListItems.push(item);
            } else {
                firstListItems.push(item);
            }
        }

        return (
            <div className="dual-list-editor">
                {this.renderList(
                    firstListTitle,
                    firstListItems,
                    DualListType.INACTIVE
                )}
                {this.renderList(
                    secondListTitle,
                    secondListItems,
                    DualListType.ACTIVE
                )}
            </div>
        );
    }
}

export default DualListEditor;
