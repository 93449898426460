import { useAuthContext } from '@modules/auth/context/AuthProvider';
import { useEffect } from 'react';
import SplashLoader from '../../../../components/SplashLoader/SplashLoader';

const LogoutPage = () => {
    const { logout } = useAuthContext();

    useEffect(() => {
        logout();
    }, [logout]);

    return <SplashLoader message="Signing out..." />;
};

export default LogoutPage;
