import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { IRootState } from '../../redux/reducers-types';
import IssueListFilters from './IssueListFilters';
import {
    IIssueFiltersValues,
    IIssueListFiltersDispatchProps,
    IIssueListFiltersStateProps,
    IssuesSortOption,
} from './IssueListFilters-types';
import {
    setIssuesSortByAction,
    updateActiveIssueFiltersAction,
} from './IssueListFilters-actions';

function mapStateToProps(state: IRootState): IIssueListFiltersStateProps {
    return {
        activeIssueFilters: state.issueListFilters.activeIssueFilters,
        themes: state.app.themes,
        categories: state.app.categories,
        featureToggles: state.featureToggles.toggles,
        sidebarWidthInPixels: state.contractSidebar.widthPixels,
        sortBy: state.issueListFilters.sortBy,
        properties: state.app.properties,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IIssueListFiltersDispatchProps {
    return {
        updateActiveIssueFilters: (activeIssueFilters: IIssueFiltersValues) =>
            dispatch(updateActiveIssueFiltersAction(activeIssueFilters)),
        setIssuesSortByOption: (sortBy: IssuesSortOption) =>
            dispatch(setIssuesSortByAction(sortBy)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueListFilters);
