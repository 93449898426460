import { ReportFilters } from '@modules/reports/common';

export const defaultSelectedColumns: string[] = [
    'contract_name',
    'status',
    'deal_type',
    'own_party',
    'counter_party',
];

export const defaultSelectedFilters: ReportFilters = {
    status: ['Signed'],
};
