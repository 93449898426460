import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    setApiTokenAction,
    setAuthMetadataAction,
    setLegacyApiTokenAction,
    setTokenExpiryAction,
} from '../../../../components/Auth/Auth-actions';
import { auth0Client } from '../../auth0Client';
import { Button } from '@thought-river/ui-components';
import SplashLoader from '../../../../components/SplashLoader/SplashLoader';
import { useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { UserTenant } from '../../../../components/Auth/Auth-types';
import {
    TenantUsersOut,
    UserOut,
} from '@thought-river/negotiations-common/dist/api/landlord';
import { IReviewStream } from '../../../../resources/AuthResource-types';
import { fetchGetDealTypes } from '@thought-river/negotiations-common/dist/api/playbookManager';
import { fetchUsers } from '../../../../components/Auth/Auth-helpers';

import styles from './AuthCallback.module.scss';

const removeAuthParams = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('code');
    url.searchParams.delete('state');

    window.history.replaceState({}, '', url);
};

const getUserDealTypes = async (
    tenantUser: UserOut
): Promise<IReviewStream[]> => {
    const userDealTypesUuids = tenantUser.deal_types.map((dt) => dt.uuid);

    const dealTypes = await fetchGetDealTypes({});
    const userDealTypes = dealTypes.filter((dealType) =>
        userDealTypesUuids.includes(dealType.uuid)
    );

    const mappedDealTypes = userDealTypes.map((dealType) => ({
        streamId: dealType.uuid,
        accountCode: dealType.code ?? '',
        accountName: dealType.name ?? '',
        isPrimary: false,
    }));

    return mappedDealTypes;
};

interface TokenWithExp extends Record<string, unknown> {
    exp: string;
}

const AuthCallback = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const handleRedirectCallback = useCallback(async () => {
        await auth0Client.handleRedirectCallback();
        removeAuthParams();

        try {
            const token = await auth0Client.getTokenSilently();
            dispatch(setApiTokenAction(token));

            const decodedToken = jwtDecode<TokenWithExp>(token);
            dispatch(
                setTokenExpiryAction(
                    new Date(Number(decodedToken.exp) * 1000).toISOString()
                )
            );

            const legacyApiTokenResponse = await fetch(
                `${window.__APP_CONFIG__.landlordApiUrl}/v1_0/negotiations-jwt`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const legacyApiToken = (
                await legacyApiTokenResponse.text()
            ).replaceAll('"', '');
            dispatch(setLegacyApiTokenAction(legacyApiToken));

            const user = await auth0Client.getUser();
            if (!user) {
                throw new Error('Could not get user from auth service');
            }

            const tenantCode = user['http://thoughtriver.net/accountCode'];

            const meResponse = await fetch(
                `${window.__APP_CONFIG__.landlordApiUrl}/v1_0/me`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const tenantUsers = (await meResponse.json()) as TenantUsersOut;
            const selectedTenantUser = tenantUsers.find(
                (tenantUser) => tenantUser.tenant.account_code === tenantCode
            );

            if (!selectedTenantUser) {
                throw new Error('Could not get selected tenant user');
            }

            const userRoles =
                selectedTenantUser.roles.map((role) => role.name) ?? [];

            const userDealTypes = await getUserDealTypes(selectedTenantUser);

            const tenants: UserTenant[] = tenantUsers
                .filter(
                    (tenantUser) =>
                        tenantUser.tenant.azure_region ===
                        window.__APP_CONFIG__.auth0.region
                )
                .filter(
                    (tenantUser) =>
                        !tenantUser.roles.some(
                            (role) => role.name === 'email-user'
                        )
                )
                .map((tenantUser) => ({
                    accountCode: tenantUser.tenant.account_code,
                    companyName: tenantUser.tenant.company_name,
                }));

            const users = await fetchUsers();
            const currentUser = users?.find(
                (u) => u.email.toLowerCase() === user.email?.toLowerCase()
            );

            dispatch(
                setAuthMetadataAction(
                    userRoles,
                    userDealTypes,
                    currentUser?.id ?? '',
                    tenantCode,
                    selectedTenantUser.tenant.company_name,
                    tenants
                )
            );

            history.replace('/');
        } catch (error) {
            console.error(error);
            dispatch(setApiTokenAction(''));
            setIsError(true);
            setIsLoading(false);
        }
    }, [dispatch, history]);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        if (searchParams.has('code') && searchParams.has('state')) {
            handleRedirectCallback();
        } else {
            setIsError(true);
            setIsLoading(false);
        }
    }, [handleRedirectCallback]);

    const handleTryAgainClick = () => {
        setIsLoading(true);
        history.replace('/');
    };

    return (
        <div className={styles.container}>
            {isLoading && <SplashLoader message="Loading..." />}

            {!isLoading && isError && (
                <div className={styles.error}>
                    Something went wrong.
                    <Button onClick={handleTryAgainClick}>Try again</Button>
                </div>
            )}
        </div>
    );
};

export default AuthCallback;
