import {
    IssueSeverityLevel,
    IssueStatus,
} from '@thought-river/negotiations-common';
import { IssueStateInVersion } from '../IssueListPane/IssueListPane-types';
import {
    IIssueForm,
    IIssuePaneActionTypes,
    IIssuePaneReducer,
    SET_ISSUE_FORM,
    SET_ISSUE_FORM_STATUS,
} from './IssuePane-types';

const defaultForm: IIssueForm = {
    categories: [],
    clauseNumbers: [],
    createdAt: '',
    description: '',
    issueId: '',
    lastModifier: null!, // Fixme: null checks
    missingSuggestionLocations: [],
    notes: '',
    reviewers: [],
    severityLevel: IssueSeverityLevel.HIGH,
    status: IssueStatus.OPEN,
    title: '',
    updatedAt: '',
    stateLabel: IssueStateInVersion.NEW,
};

const initialState: IIssuePaneReducer = {
    form: defaultForm,
    submitStatus: null!, // Fixme: null checks
};

const IssuePaneReducer = (
    state = initialState,
    action: IIssuePaneActionTypes
): IIssuePaneReducer => {
    switch (action.type) {
        case SET_ISSUE_FORM: {
            return {
                ...state,
                form: action.form,
            };
        }
        case SET_ISSUE_FORM_STATUS: {
            return {
                ...state,
                submitStatus: action.status,
            };
        }
        default:
            return state;
    }
};

export default IssuePaneReducer;
