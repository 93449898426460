import { IRootState } from 'src/redux/reducers-types';
import { reduxStore } from '../redux/store';
import { ILexiblePropertyUpdateParams } from './legacyHTMLParser-types';

const CSRF_TOKEN_EXPIRY_MS = 7200000; //2 hours

export default class LegacyHTMLParser {
    private static instance: LegacyHTMLParser;
    private static document: Document;
    private static versionId: string;
    private static documentId: string;
    private static csrfToken: string;

    async loadLegacyHtml(contractId: string, streamId: string): Promise<void> {
        const rawHtml = await this.getHtml(contractId, streamId);

        if (!rawHtml) {
            return;
        }

        const parser = new DOMParser();

        LegacyHTMLParser.document = parser.parseFromString(
            rawHtml as string,
            'text/html'
        );

        this.parseHTML();

        setTimeout(() => {
            this.loadLegacyHtml(contractId, streamId);
        }, CSRF_TOKEN_EXPIRY_MS);
    }

    static getInstance(): LegacyHTMLParser {
        if (!LegacyHTMLParser.instance) {
            LegacyHTMLParser.instance = new LegacyHTMLParser();
        }

        return LegacyHTMLParser.instance;
    }

    private async getHtml(
        contractId: string,
        streamId: string
    ): Promise<string | void> {
        const { auth } = reduxStore.store.getState() as IRootState;

        const { apiToken, legacyApiToken } = auth;

        const legacyUIUrl = window.__APP_CONFIG__.legacyUIUrl;

        const tokenUrl = `${legacyUIUrl}/?jwt=${legacyApiToken ?? apiToken}`;
        const htmlUrl = `${legacyUIUrl}/login/swap/${streamId}?bookmark=${encodeURIComponent(
            `/contract/apiview/${contractId}`
        )}`;

        //get the neccesary cookies to perform the next request
        await fetch(tokenUrl, {
            method: 'GET',
            credentials: 'include',
            redirect: 'manual',
        }).catch(function (err) {
            console.error(err + ' url: ' + tokenUrl);
        });

        //fetch the html
        return await fetch(htmlUrl, {
            method: 'GET',
            redirect: 'follow',
            credentials: 'include',
        })
            .then((response) => response.text())
            .catch(function (err) {
                console.error(err + ' url: ' + htmlUrl);
            });
    }

    private parseHTML() {
        const document = LegacyHTMLParser.document;
        const scripts = document.scripts;

        LegacyHTMLParser.versionId =
            scripts[2].text
                .match("version_id: '\\d+'")?.[0]
                .split(': ')[1]
                .replaceAll("'", '') ?? '';
        LegacyHTMLParser.documentId =
            scripts[2].text
                .match("document_id: '\\d+'")?.[0]
                .split(': ')[1]
                .replaceAll("'", '') ?? '';
        LegacyHTMLParser.csrfToken =
            scripts[9].text
                .match('var csrf = "[a-z0-9]+"')?.[0]
                .split('= ')[1]
                .replaceAll('"', '') ?? '';
    }

    async editLexibleProperties(
        params: ILexiblePropertyUpdateParams[]
    ): Promise<unknown> {
        const { legacyUIUrl } = window.__APP_CONFIG__;

        const editUrl = `${legacyUIUrl}/property/edit/${LegacyHTMLParser.versionId}`;

        const formData = new FormData();

        params.forEach((param, index) => {
            formData.append(`dfcode[${index}]`, param.dfCode);
            formData.append(`value[${index}]`, param.value);
            formData.append(
                `paragraphId[${index}]`,
                param.paragraphIndex || ''
            ); // The 0-based index of the paragraph in the document
        });

        formData.append('csrf', LegacyHTMLParser.csrfToken);
        formData.append('file_id', LegacyHTMLParser.documentId);

        return await fetch(editUrl, {
            method: 'POST',
            credentials: 'include',
            body: formData,
        })
            .then((res) => res.json())
            .catch(function (err) {
                console.error(err + ' url: ' + editUrl);
            });
    }
}
