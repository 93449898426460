import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';

import IssuesReport from './IssuesReport';
import { IRootState } from '../../redux/reducers-types';
import {
    IIssuesReportDispatchProps,
    IIssuesReportStateProps,
    IIssuesTableColumn,
} from './IssuesReport-types';
import { showDialogAction } from '../ModalDialog/ModalDialog-actions';
import { IModalDialogButton } from '../ModalDialog/ModalDialog-types';
import {
    IIssueFiltersValues,
    PresetIssueFilters,
} from '../IssueListFilters/IssueListFilters-types';
import { setSelectedIssueFiltersAction } from '../IssueListFilters/IssueListFilters-actions';
import {
    setDownloadPdfAction,
    setIssuesReportSavedStatusAction,
    setIssuesReportSortModelAction,
    setIssuesTableColumnsAction,
    setSelectedPropertySuggestionAction,
} from '../IssuesReport/IssuesReport-actions';
import { setContractSidebarWidthInPixelsAction } from '../ContractSidebar/ContractSidebar-actions';
import {
    setIssuesAction,
    setPropertiesAction,
    updateSummaryPropertyAction,
} from '../Contract/Contract-actions';
import { SubmitStatus } from '../App/App-types';
import { IContractProperty } from '../Contract/Contract-types';
import { ISummaryProperty } from '../SummaryPane/SummaryPane-types';
import { updateActiveIssueFiltersAction } from '../IssueListFilters/IssueListFilters-actions';
import { GridSortModel } from '@mui/x-data-grid';
import { ContractIssue } from '@thought-river/negotiations-common';

function mapStateToProps(state: IRootState): IIssuesReportStateProps {
    return {
        activeFilters: state.issueListFilters.activeIssueFilters,
        assessment: state.contract.assessment,
        contract: state.contract.contract,
        columns: state.issuesReport.columns,
        downloadPdf: state.issuesReport.downloadPdf,
        featureToggles: state.featureToggles.toggles,
        issues: state.contract.issues,
        lastValidPreset: state.issueListFilters.lastValidPreset,
        propertyTemplatesLoaded: state.contract.propertyTemplatesLoaded,
        paragraphs: state.contract.paragraphs,
        properties: state.contract.properties,
        selectedFilterPreset: state.issueListFilters.selectedFilterPreset,
        selectedPropertiesTemplate: state.contract.selectedPropertiesTemplate,
        selectedVersion: state.contract.selectedVersion,
        sidebarWidth: state.contractSidebar.widthPixels,
        sortModel: state.issuesReport.sortModel,
        summaryProperties: state.contract.summaryProperties,
        userId: state.auth.userId,
        streamId: state.contract.contract.streamId,
        selectedPropertySuggestionMap:
            state.issuesReport.selectedPropertySuggestionMap,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IIssuesReportDispatchProps {
    return {
        showDialog: (
            title: string | JSX.Element,
            showCloseButton: boolean,
            component: JSX.Element,
            buttons: IModalDialogButton[],
            height: string,
            customClassname: string
        ) =>
            dispatch(
                showDialogAction(
                    title,
                    showCloseButton,
                    component,
                    buttons,
                    height,
                    customClassname
                )
            ),
        setActiveFilters: (activeFilters: IIssueFiltersValues) =>
            dispatch(updateActiveIssueFiltersAction(activeFilters)),
        setContractProperties: (properties: IContractProperty[]) =>
            dispatch(setPropertiesAction(properties)),
        setContractSidebarWidthInPixels: (width: number) =>
            dispatch(setContractSidebarWidthInPixelsAction(width)),
        setDownloadPdf: (downloadPdf: boolean) =>
            dispatch(setDownloadPdfAction(downloadPdf)),
        setIssues: (issues: ContractIssue[]) =>
            dispatch(setIssuesAction(issues)),
        setIssuesReportSavedStatus: (status: SubmitStatus) =>
            dispatch(setIssuesReportSavedStatusAction(status)),
        setSelectedIssueFilters: (
            selectedFilterPreset: keyof typeof PresetIssueFilters
        ) => dispatch(setSelectedIssueFiltersAction(selectedFilterPreset)),
        setSortModel: (sortModel: GridSortModel) =>
            dispatch(setIssuesReportSortModelAction(sortModel)),
        setIssuesTableColumns: (columns: IIssuesTableColumn[]) =>
            dispatch(setIssuesTableColumnsAction(columns)),
        updateSummaryProperty: (summaryProperty: ISummaryProperty) =>
            dispatch(updateSummaryPropertyAction(summaryProperty)),
        setSelectedPropertySuggestion: (...args) =>
            dispatch(setSelectedPropertySuggestionAction(...args)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(IssuesReport));
