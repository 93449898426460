import { SummaryPanelTemplateOut } from '@thought-river/negotiations-common/dist/api/playbookManager';
import { IContractParagraph } from '../Paragraph/Paragraph-types';
import { ISummaryProperty } from '../SummaryPane/SummaryPane-types';
import {
    HighlightIndexType,
    IContractAssesssment,
    IContractPolicy,
    IContractProperty,
    IContractVersion,
    IDisplayContract,
    ISetAssessmentAction,
    ISetContractAction,
    ISetFormatsAction,
    ISetHighlightIndexAction,
    ISetHighlightedParagraphsAction,
    ISetIssuesAction,
    ISetLatestVersionAction,
    ISetPropertyTemplatesLoadedAction,
    ISetLoadedAction,
    ISetNewVersionProcessedAction,
    ISetParagraphsAction,
    ISetPoliciesAction,
    ISetProcessingVersionIdAction,
    ISetPropertiesAction,
    ISetRedlinedTextAction,
    ISetReferringPageAction,
    ISetReloadContractAction,
    ISetReviewedParagraphsAction,
    ISetReviewedPropertyAction,
    ISetScrollTopAction,
    ISetSelectedIssueAction,
    ISetSelectedMissingRiskAction,
    ISetSelectedParagraphAction,
    ISetSelectedPropertyCodeAction,
    ISetSelectedVersionAction,
    ISetThemesAction,
    ISetTotalResolvedIssuesAction,
    ISetTotalSignificantIssuesAction,
    ISetUnscoredThemesAction,
    ISetVersionsAction,
    IUpdateContractAction,
    IUpdateContractDetailsAction,
    SET_ASSESSMENT,
    SET_CONTRACT,
    SET_FORMATS_ACTION,
    SET_HIGHLIGHT_INDEX,
    SET_HIGHLIGHTED_PARAGRAPHS,
    SET_ISSUES,
    SET_LATEST_VERSION,
    SET_PROPERTY_TEMPLATES_LOADED,
    SET_LOADED,
    SET_NEW_VERSION_PROCESSED,
    SET_PARAGRAPHS,
    SET_POLICIES,
    SET_PROCESSING_VERSION_ID,
    SET_PROPERTIES,
    SET_REDLINED_TEXT,
    SET_REFERRING_PAGE,
    SET_RELOAD_CONTRACT_ACTION,
    SET_REVIEWED_PARAGRAPHS,
    SET_REVIEWED_PROPERTY,
    SET_SCROLL_TOP,
    SET_SELECTED_ISSUE,
    SET_SELECTED_MISSING_RISK,
    SET_SELECTED_PARAGRAPH,
    SET_SELECTED_PROPERTY_CODE,
    SET_SELECTED_VERSION,
    SET_THEMES,
    SET_TOTAL_RESOLVED_ISSUES,
    SET_TOTAL_SIGNIFICANT_ISSUES,
    SET_UNSCORED_THEMES,
    SET_VERSIONS,
    UPDATE_CONTRACT,
    UPDATE_CONTRACT_DETAILS,
    ISetSummaryPropertiesAction,
    SET_SUMMARY_PROPERTIES,
    UPDATE_CONTRACT_PROPERTY,
    IUpdateContractPropertyAction,
    UPDATE_SUMMARY_PROPERTY,
    IUpdateSummaryPropertyAction,
    UPDATE_ISSUE,
    IUpdateIssueAction,
    SET_SELECTED_PROPERTIES_TEMPLATE,
    ISetSelectedPropertiesTemplate,
    SET_PROPERTY_TEMPLATES,
    ISetPropertyTemplatesAction,
    CLEAR_CONTRACT,
    IClearContractAction,
    ISelectedContractIntelligence,
    SET_SELECTED_CONTRACT_INTELLIGENCE,
    ISetSelectedContractIntelligenceAction,
    ISetHighlightedTextAction,
    SET_HIGHLIGHTED_TEXT,
} from './Contract-types';
import {
    ContractIssue,
    LexibleTheme,
} from '@thought-river/negotiations-common';
import { FileFormat } from '@modules/common/types';

export function setAssessmentAction(
    assessment: IContractAssesssment
): ISetAssessmentAction {
    return {
        type: SET_ASSESSMENT,
        assessment,
    };
}

export function setContractAction(
    contract: IDisplayContract
): ISetContractAction {
    return {
        type: SET_CONTRACT,
        contract,
    };
}

export function clearContractAction(): IClearContractAction {
    return {
        type: CLEAR_CONTRACT,
    };
}

export function setFormatsAction(formats: FileFormat[]): ISetFormatsAction {
    return {
        type: SET_FORMATS_ACTION,
        formats,
    };
}

export function setHighlightedTextAction(
    text: string
): ISetHighlightedTextAction {
    return {
        type: SET_HIGHLIGHTED_TEXT,
        text,
    };
}

export function setHighlightIndexAction(
    index: number,
    highlightType: HighlightIndexType
): ISetHighlightIndexAction {
    return {
        type: SET_HIGHLIGHT_INDEX,
        index,
        highlightType,
    };
}

export function setHighlightedParagraphs(
    paragraphs: IContractParagraph[]
): ISetHighlightedParagraphsAction {
    return {
        type: SET_HIGHLIGHTED_PARAGRAPHS,
        highlightedParagraphs: paragraphs,
    };
}

export function setIssuesAction(issues: ContractIssue[]): ISetIssuesAction {
    return {
        type: SET_ISSUES,
        issues,
    };
}

export function updateIssueAction(issue: ContractIssue): IUpdateIssueAction {
    return {
        type: UPDATE_ISSUE,
        issue,
    };
}

export function setLatestVersionAction(
    latestVersion: IContractVersion
): ISetLatestVersionAction {
    return {
        type: SET_LATEST_VERSION,
        latestVersion,
    };
}

export function setPropertyTemplatesLoadedAction(
    propertyTemplatesLoaded: boolean
): ISetPropertyTemplatesLoadedAction {
    return {
        type: SET_PROPERTY_TEMPLATES_LOADED,
        propertyTemplatesLoaded,
    };
}

export function setLoadedAction(loaded: boolean): ISetLoadedAction {
    return {
        type: SET_LOADED,
        loaded,
    };
}

export function setParagraphAction(
    selectedParagraph: IContractParagraph
): ISetSelectedParagraphAction {
    return {
        type: SET_SELECTED_PARAGRAPH,
        selectedParagraph,
    };
}

export function setParagraphsAction(
    paragraphs: IContractParagraph[]
): ISetParagraphsAction {
    return {
        type: SET_PARAGRAPHS,
        paragraphs,
    };
}

export function setPoliciesAction(
    policies: IContractPolicy[]
): ISetPoliciesAction {
    return {
        type: SET_POLICIES,
        policies,
    };
}

export function setProcessingVersionIdAction(
    processingVersionId: string
): ISetProcessingVersionIdAction {
    return {
        type: SET_PROCESSING_VERSION_ID,
        processingVersionId,
    };
}

export function setPropertiesAction(
    properties: IContractProperty[]
): ISetPropertiesAction {
    return {
        type: SET_PROPERTIES,
        properties,
    };
}

export function setRedlinedTextAction(
    redlinedText: string
): ISetRedlinedTextAction {
    return {
        type: SET_REDLINED_TEXT,
        redlinedText,
    };
}

export function setReloadContractAction(
    reload: boolean
): ISetReloadContractAction {
    return {
        type: SET_RELOAD_CONTRACT_ACTION,
        reload,
    };
}

export function setReviewedParagraphsAction(
    reviewed: boolean,
    paragraphIndices?: number[]
): ISetReviewedParagraphsAction {
    return {
        type: SET_REVIEWED_PARAGRAPHS,
        reviewed,
        paragraphIndices,
    };
}

export function setReviewedPropertyAction(
    index: number,
    reviewed: boolean
): ISetReviewedPropertyAction {
    return {
        type: SET_REVIEWED_PROPERTY,
        index,
        reviewed,
    };
}

export function setScrollTopAction(scrollTop: number): ISetScrollTopAction {
    return {
        type: SET_SCROLL_TOP,
        scrollTop,
    };
}

export function setSelectedIssueAction(
    selectedIssue: ContractIssue
): ISetSelectedIssueAction {
    return {
        type: SET_SELECTED_ISSUE,
        selectedIssue,
    };
}

export function setSelectedMisingRiskAction(
    selectedMissingRisk: IContractProperty
): ISetSelectedMissingRiskAction {
    return {
        type: SET_SELECTED_MISSING_RISK,
        selectedMissingRisk,
    };
}

export function setSelectedParagraphAction(
    selectedParagraph: IContractParagraph | null
): ISetSelectedParagraphAction {
    return {
        type: SET_SELECTED_PARAGRAPH,
        selectedParagraph,
    };
}

export function setSelectedVersionAction(
    selectedVersion: IContractVersion
): ISetSelectedVersionAction {
    return {
        type: SET_SELECTED_VERSION,
        selectedVersion,
    };
}

export function setThemesAction(themes: LexibleTheme[]): ISetThemesAction {
    return {
        type: SET_THEMES,
        themes,
    };
}

export function setTotalResolvedIssuesAction(
    total: number
): ISetTotalResolvedIssuesAction {
    return {
        type: SET_TOTAL_RESOLVED_ISSUES,
        total,
    };
}

export function setTotalSignificantIssuesAction(
    total: number
): ISetTotalSignificantIssuesAction {
    return {
        type: SET_TOTAL_SIGNIFICANT_ISSUES,
        total,
    };
}

export function setVersionsAction(
    versions: IContractVersion[]
): ISetVersionsAction {
    return {
        type: SET_VERSIONS,
        versions,
    };
}

export function setUnscoredThemesAction(
    unscoredThemes: LexibleTheme[]
): ISetUnscoredThemesAction {
    return {
        type: SET_UNSCORED_THEMES,
        unscoredThemes,
    };
}

export function updateContractAction(
    contractId: string,
    fields: Partial<IDisplayContract>
): IUpdateContractAction {
    return {
        type: UPDATE_CONTRACT,
        contractId,
        fields,
    };
}

export function updateContractDetailsAction(
    contractName: string,
    reviewerIds: string[],
    negotiatorId: string
): IUpdateContractDetailsAction {
    return {
        type: UPDATE_CONTRACT_DETAILS,
        contractName,
        reviewerIds,
        negotiatorId,
    };
}

export function updateContractPropertyAction(
    property: IContractProperty
): IUpdateContractPropertyAction {
    return {
        type: UPDATE_CONTRACT_PROPERTY,
        property,
    };
}

export function updateSummaryPropertyAction(
    property: ISummaryProperty
): IUpdateSummaryPropertyAction {
    return {
        type: UPDATE_SUMMARY_PROPERTY,
        property,
    };
}

export function setNewVersionProcessedAction(
    newVersionProcessed: boolean
): ISetNewVersionProcessedAction {
    return {
        type: SET_NEW_VERSION_PROCESSED,
        newVersionProcessed,
    };
}

export function setReferringPageAction(
    referringPage: string
): ISetReferringPageAction {
    return {
        type: SET_REFERRING_PAGE,
        referringPage,
    };
}

export function setSelectedPropertyCodeAction(
    selectedPropertyCode: string | null
): ISetSelectedPropertyCodeAction {
    return {
        type: SET_SELECTED_PROPERTY_CODE,
        selectedPropertyCode,
    };
}

export function setSummaryPropertiesAction(
    summaryProperties: ISummaryProperty[]
): ISetSummaryPropertiesAction {
    return {
        type: SET_SUMMARY_PROPERTIES,
        summaryProperties,
    };
}

export function setSelectedPropertiesTemplateAction(
    selectedPropertiesTemplate: SummaryPanelTemplateOut
): ISetSelectedPropertiesTemplate {
    return {
        type: SET_SELECTED_PROPERTIES_TEMPLATE,
        selectedPropertiesTemplate,
    };
}

export function setPropertyTemplatesAction(
    propertyTemplates: SummaryPanelTemplateOut[]
): ISetPropertyTemplatesAction {
    return {
        type: SET_PROPERTY_TEMPLATES,
        propertyTemplates,
    };
}

export function setSelectedContractIntelligenceAction(
    intelligence: ISelectedContractIntelligence
): ISetSelectedContractIntelligenceAction {
    return {
        type: SET_SELECTED_CONTRACT_INTELLIGENCE,
        intelligence,
    };
}
