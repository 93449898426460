import {
    ContractSidebarTab,
    IContractSidebarActionTypes,
    IContractSidebarReducer,
    SET_ACTIVE_CONTRACT_SIDEBAR_TAB,
    SET_CONTRACT_SIDEBAR_WIDTH_IN_PIXELS,
} from './ContractSidebar-types';

const initialState: IContractSidebarReducer = {
    activeTab: ContractSidebarTab.OVERVIEW,
    widthPixels: 0,
};

const ContractSidebarReducer = (
    state = initialState,
    action: IContractSidebarActionTypes
): IContractSidebarReducer => {
    switch (action.type) {
        case SET_ACTIVE_CONTRACT_SIDEBAR_TAB:
            return {
                ...state,
                activeTab: action.activeTab,
            };

        case SET_CONTRACT_SIDEBAR_WIDTH_IN_PIXELS:
            return {
                ...state,
                widthPixels: action.widthPixels,
            };
        default:
            return state;
    }
};

export default ContractSidebarReducer;
