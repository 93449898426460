import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';

import { IRootState } from '../../redux/reducers-types';
import DeleteContractModal from './DeleteContractModal';
import {
    IDeleteContractModalDispatchProps,
    IDeleteContractModalStateProps,
} from './DeleteContractModal-types';
import { hideDialogAction } from '../ModalDialog/ModalDialog-actions';
import { setContractsAction } from '../ContractList/ContractList-actions';
import { IDisplayContract } from '../Contract/Contract-types';
import {
    unsetProcessingContractAction,
    unsetReprocessingContractAction,
} from '../ContractPollingProvider/ContractPollingProvider-actions';

function mapStateToProps(state: IRootState): IDeleteContractModalStateProps {
    return {
        contracts: state.contractList.contracts,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch
): IDeleteContractModalDispatchProps {
    return {
        setContracts: (contracts: IDisplayContract[]) =>
            dispatch(setContractsAction(contracts)),
        closeModal: () => dispatch(hideDialogAction()),
        unsetProcessingContract: (contractId: string) =>
            dispatch(unsetProcessingContractAction(contractId)),
        unsetReprocessingContract: (contractId: string) =>
            dispatch(unsetReprocessingContractAction(contractId)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DeleteContractModal));
