import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import {
    setSelectedPartyAction,
    updatePartyAction,
    removePartyAction,
} from './Party-actions';
import { IParty } from '../Parties/Parties-types';
import { IPartyDispatchProps, IPartyStateProps } from './Party-types';
import { IRootState } from '../../redux/reducers-types';
import Party from './Party';
import { setHighlightedPartyAction } from '../PartyParagraph/PartyParagraph-actions';
import {
    setDraggedPartyAction,
    setScrollToPartyAction,
    setContractScrollToPartyAction,
} from '../Parties/Parties-actions';

function mapStateToProps(state: IRootState): IPartyStateProps {
    return {
        highlightedParty: state.parties.highlightedParty,
        selectedParty: state.parties.selectedParty,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IPartyDispatchProps {
    return {
        setHighlightedParty: (party: IParty) =>
            dispatch(setHighlightedPartyAction(party)),
        setSelectedParty: (party: IParty) =>
            dispatch(setSelectedPartyAction(party)),
        setDraggedParty: (party: IParty) =>
            dispatch(setDraggedPartyAction(party)),
        updateParty: (party: IParty) => dispatch(updatePartyAction(party)),
        removeParty: (party: IParty) => dispatch(removePartyAction(party)),
        setScrollToParty: (party: IParty) =>
            dispatch(setScrollToPartyAction(party)),
        setContractScrollToParty: (party: IParty) =>
            dispatch(setContractScrollToPartyAction(party)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Party);
