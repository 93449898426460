import { Component } from 'react';
import { Close } from '@mui/icons-material';

import { analytics } from './IssuesReportEditColumnsModal-analytics';
import {
    IIssuesReportEditColumnsModalProps,
    IIssuesReportEditColumnsModalState,
} from './IssuesReportEditColumnsModal-types';
import DualListEditor from '../../DualListEditor/DualListEditor';
import { IDualItem } from '../../DualListEditor/DualListEditor-types';
import {
    IIssuesTableColumn,
    IssuesReportPropertyLabels,
} from '../IssuesReport-types';
import { snakeCaseToLabel } from '../../App/App-helpers';
import { filtersPresets } from '../../IssueListFilters/IssueListFilters-reducer';
import { PresetIssueFilters } from '../../IssueListFilters/IssueListFilters-types';
import { EXTERNAL_COMMENTS, INTERNAL_GUIDANCE } from '../../App/App-types';
import { IssuesTableHeader } from '../HybridReportDataGrid/HybridReportDataGrid-types';
import { Button } from '@thought-river/ui-components';

@analytics()
class IssuesReportEditColumnsModal extends Component<
    IIssuesReportEditColumnsModalProps,
    IIssuesReportEditColumnsModalState
> {
    constructor(props: IIssuesReportEditColumnsModalProps) {
        super(props);

        const { columns } = props;

        this.state = {
            columns: [...columns],
        };

        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleSaveChanges = this.handleSaveChanges.bind(this);
        this.onListChanged = this.onListChanged.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.issueColumnToDualListItemMapper =
            this.issueColumnToDualListItemMapper.bind(this);
    }

    private handleCloseModal() {
        this.props.closeModal();
    }

    private handleSaveChanges() {
        const { setColumns, setSelectedFilterPreset, selectedFilterPreset } =
            this.props;
        const { columns } = this.state;

        setColumns(columns);

        const activeColumns = columns.filter((column) => column.active);
        const activeColumnLabels = activeColumns.map((column) => column.label);

        const presetColumns = [
            ...(filtersPresets[PresetIssueFilters[selectedFilterPreset]]
                ?.columns ?? []),
        ];

        if (activeColumnLabels.sort().join() !== presetColumns.sort().join()) {
            setSelectedFilterPreset('');
        }

        this.handleCloseModal();
    }

    private onListChanged(items: IDualItem[]) {
        const columns = items.map(this.dualListItemToIssueColumnMapper);
        this.setState({ columns });
    }

    private dualListItemToIssueColumnMapper(
        item: IDualItem
    ): IIssuesTableColumn {
        return {
            label: item.label as IssuesTableHeader,
            active: item.active,
            width: item.data?.width,
        };
    }

    private issueColumnToDualListItemMapper(
        issueColumn: IIssuesTableColumn
    ): IDualItem {
        return {
            label: issueColumn.label,
            active: issueColumn.active,
            data: issueColumn,
        };
    }

    private renderItem(item: IDualItem) {
        if (item.label === IssuesTableHeader.DESCRIPTION) {
            return INTERNAL_GUIDANCE;
        }

        if (item.label === IssuesTableHeader.NOTES) {
            return EXTERNAL_COMMENTS;
        }

        if (IssuesReportPropertyLabels[item.label]) {
            return IssuesReportPropertyLabels[item.label];
        }

        return snakeCaseToLabel(item.label);
    }

    render() {
        const { columns } = this.state;

        return (
            <div className="issues-report-modal issues-report-edit-columns-modal">
                <div className="issues-report-modal-header">
                    <div className="title">Manage Columns</div>
                    <Close
                        className="close-btn"
                        onClick={this.handleCloseModal}
                    />
                </div>
                <DualListEditor
                    firstListTitle="Available"
                    secondListTitle="In Use"
                    items={columns.map(this.issueColumnToDualListItemMapper)}
                    onChange={this.onListChanged}
                    itemRenderer={this.renderItem}
                />
                <div className="modal-footer issues-report-modal-footer">
                    <Button variant="secondary" onClick={this.handleCloseModal}>
                        Cancel
                    </Button>
                    <Button onClick={this.handleSaveChanges}>Save</Button>
                </div>
            </div>
        );
    }
}

export default IssuesReportEditColumnsModal;
