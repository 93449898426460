import { RouteComponentProps } from 'react-router-dom';
import { IReviewStream } from '../../resources/AuthResource-types';
import { IParty } from '../Parties/Parties-types';
import { IDisplayContract } from '../Contract/Contract-types';
import { IProcessingContract } from '../ContractPollingProvider/ContractPollingProvider-types';
import { IPreviewFile } from '../WizardFileUploader/WizardFileUploader-types';
import { IFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import { SelectOption } from '@thought-river/ui-components';

export enum ProcessStatus {
    FAILURE = 'failure',
    PROCESSING = 'processing',
    SUCCESS = 'success',
}
export interface IContractUploadWizardModalState {
    activeStep: number;
    discardedParties: IParty[];
    deletingFile: boolean;
    files: IPreviewFile[];
    isUploading: boolean;
    parties: IParty[];
    reciprocalParties: IParty[];
    stepLabels: string[];
    selectedStream: SelectOption;
    submitting: boolean;
    uploadedFiles: IUploadedFile[];
}

export interface IContractUploadWizardModalStateProps {
    openContract?: IDisplayContract;
    contracts: IDisplayContract[];
    defaultStream: IReviewStream;
    failedProcessedContracts: IProcessingContract[];
    featureToggles: IFeatureToggle[];
    streams: IReviewStream[];
    successfulProcessedContracts: IProcessingContract[];
    userId: string;
}

export interface IUploadedFile {
    contractId: string;
    fileId: string;
    filename: string;
    processStatus: ProcessStatus;
    streamCode: string;
    streamId: string;
    versionId: string;
}

export interface IContractUploadWizardModalReducer {}

export interface IContractUploadWizardModalDispatchProps {
    addProcessingContract: (contract: IProcessingContract) => void;
    prependContract: (contract: IDisplayContract) => void;
    setContracts: (contracts: IDisplayContract[]) => void;
    setDefaultStream: (defaultStream: IReviewStream) => void;
    setLocalParties: (parties: IParty[]) => void;
    setReprocessingContract: (
        contractId: string,
        contractName: string,
        versionId: string,
        stream: string,
        streamId: string,
        assessmentCount: number,
        userId: string
    ) => void;
    unsetNotifiedContract: (contractId: string) => void;
    unsetProcessingContract: (contractId: string) => void;
}

export interface IContractUploadWizardModalProps
    extends IContractUploadWizardModalDispatchProps,
        IContractUploadWizardModalStateProps,
        RouteComponentProps {
    open: boolean;
    onClose: () => void;
    existingContractId?: string;
    existingStreamId?: string;
}

export type IContractUploadWizardModalActionTypes = any;
