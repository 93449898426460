import { analyticsService } from '../../services/Analytics/Analytics';
import {
    EVENT_ACTION_SWAPS_PARTIES,
    EVENT_ACTION_UPLOAD_NEGOTIATION,
    EVENT_ACTION_UPLOAD_REVISION,
    EVENT_ACTION_CONFIRMS_PARTIES,
    EVENT_ACTION_INITIATE_SET_PARTIES_WORKFLOW,
} from '../../services/Analytics/Analytics-types';
import {
    IContractUploadWizardModalProps,
    IContractUploadWizardModalState,
} from './ContractUploadWizardModal-types';

export function analytics() {
    return (target: any) => {
        const swapParties = target.prototype.swapParties;
        const onFileChange = target.prototype.onFileChange;
        const editParties = target.prototype.editParties;
        const onConfirmParties = target.prototype.onConfirmParties;

        target.prototype.onFileChange = async function (files: any[]) {
            const { existingContractId } = this
                .props as IContractUploadWizardModalProps;
            const { selectedStream } = this
                .state as IContractUploadWizardModalState;

            const eventAction = existingContractId
                ? EVENT_ACTION_UPLOAD_REVISION
                : EVENT_ACTION_UPLOAD_NEGOTIATION;

            if (files) {
                analyticsService.recordEvent(
                    eventAction,

                    {
                        'Number of contracts': files.length,
                        Stream: selectedStream?.value ?? '',
                    }
                );
            }

            onFileChange.apply(this, arguments);
        };

        target.prototype.swapParties = function () {
            const { uploadedFiles } = this.state;

            const uploadedFile = uploadedFiles[0];

            if (uploadedFile) {
                analyticsService.recordEvent(
                    EVENT_ACTION_SWAPS_PARTIES,

                    {
                        ContractId: uploadedFile.contractId,
                        Stream: uploadedFile.streamCode,
                        VersionId: uploadedFile.versionId,
                        VersionNumber: uploadedFile.versionNumber,
                    }
                );
            }

            swapParties.apply(this, arguments);
        };

        target.prototype.editParties = function () {
            const { uploadedFiles } = this.state;

            const uploadedFile = uploadedFiles[0];

            if (uploadedFile) {
                analyticsService.recordEvent(
                    EVENT_ACTION_INITIATE_SET_PARTIES_WORKFLOW,

                    {
                        ContractId: uploadedFile.contractId,
                        Stream: uploadedFile.streamCode,
                        VersionId: uploadedFile.versionId,
                        VersionNumber: uploadedFile.versionNumber,
                    }
                );
            }

            editParties.apply(this, arguments);
        };

        target.prototype.onConfirmParties = function () {
            const { activeStep, uploadedFiles } = this.state;

            const uploadedFile = uploadedFiles[0];

            if (activeStep === 2 && uploadedFile) {
                analyticsService.recordEvent(
                    EVENT_ACTION_CONFIRMS_PARTIES,

                    {
                        ContractId: uploadedFile.contractId,
                        Stream: uploadedFile.streamCode,
                        VersionId: uploadedFile.versionId,
                        VersionNumber: uploadedFile.versionNumber,
                    }
                );
            }

            onConfirmParties.apply(this, arguments);
        };
    };
}
