import * as React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Link, Notes } from '@mui/icons-material';

import { ILinkPopoverProps, ILinkPopoverState } from './LinkPopover-types';
import './LinkPopover.scss';

export default class LinkPopover extends React.Component<
    ILinkPopoverProps,
    ILinkPopoverState
> {
    constructor(props: ILinkPopoverProps) {
        super(props);

        this.state = {
            linkUrl: props.linkUrl,
            linkText: props.linkText,
        };
    }

    onConfirm = (event: React.KeyboardEvent) => {
        const { onConfirm } = this.props;
        const { linkUrl, linkText } = this.state;

        if (event.key === 'Enter') {
            onConfirm(linkUrl, linkText);
        }
    };

    render() {
        const { linkUrl, linkText } = this.state;

        return (
            <div
                className="link-popover"
                onClick={(event) => event.stopPropagation()}
                onKeyDown={this.onConfirm}
            >
                <TextField
                    className="link-input"
                    onChange={(event) =>
                        this.setState({ linkUrl: event.target.value })
                    }
                    placeholder="Paste or type link"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Link className="link-icon" />
                            </InputAdornment>
                        ),
                    }}
                    value={linkUrl}
                    autoFocus={true}
                />
                <TextField
                    className="link-input"
                    onChange={(event) =>
                        this.setState({ linkText: event.target.value })
                    }
                    placeholder="Text to display"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Notes className="link-icon" />
                            </InputAdornment>
                        ),
                    }}
                    value={linkText}
                />
            </div>
        );
    }
}
