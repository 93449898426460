/**
 * Multiple `mark` elements might refer to the same match when it is across elements,
 * this function returns an array of the leading (or the only) elements
 */
export const extractLeadingElements = (
    elements: HTMLElement[],
    searchTerm: string
) => {
    const leadingElements: HTMLElement[] = [];

    let currentMatch = '';
    let currentElements: HTMLElement[] = [];

    elements.forEach((element) => {
        const elementText = element.innerText.toLowerCase();

        if (elementText === searchTerm.toLowerCase()) {
            leadingElements.push(element);
            currentMatch = '';
            currentElements = [];
            return;
        }

        currentMatch += elementText;
        currentElements.push(element);

        if (currentMatch === searchTerm.toLowerCase()) {
            leadingElements.push(currentElements[0]);
            currentMatch = '';
            currentElements = [];
            return;
        }
    });

    if (currentElements[0]) {
        leadingElements.push(currentElements[0]);
    }

    return leadingElements;
};

export const scrollToMatch = (element: HTMLElement) =>
    element.scrollIntoView({
        behavior: 'smooth',
    });
