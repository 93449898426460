import { SectionsTreeChildNodeJSON } from '@thought-river/negotiations-common/dist/api/contractContent';

export const getParagraphIndexesFromSectionsTree = (
    nodes: SectionsTreeChildNodeJSON[]
) => {
    const uuids: string[] = [];

    const traverse = (node: SectionsTreeChildNodeJSON) => {
        uuids.push(String(node.paragraph_index));
        if (Array.isArray(node.children)) {
            node.children.forEach((child) => traverse(child));
        }
    };

    nodes.forEach((node) => traverse(node));

    return uuids;
};
