import { IContractNameProps } from './ContractName-types';
import ContractResource from '../../../resources/ContractResource';
import { useDispatch } from 'react-redux';
import { updateContractAction } from '../../Contract/Contract-actions';
import { toast } from 'react-toastify';
import { SubmitStatus } from '../../App/App-types';
import { EditableField } from '@modules/common/components/EditableField';

const ContractName = ({
    contract,
    onContractNameFocus,
    onSubmitStatusChange,
}: IContractNameProps) => {
    const dispatch = useDispatch();

    const handleChange = async (value: string) => {
        try {
            onSubmitStatusChange(SubmitStatus.PROCESSING);
            await ContractResource.patchContract(
                contract.id,
                value,
                contract.streamCode
            );

            dispatch(
                updateContractAction(contract.id, {
                    ...contract,
                    contractName: value,
                })
            );

            toast.success('The contract has been updated successfully.');
            onSubmitStatusChange(SubmitStatus.SUCCESS);
        } catch (error: any) {
            toast.error(error.message);
            onSubmitStatusChange(SubmitStatus.FAILURE);
        }
    };

    return (
        <EditableField
            initialValue={contract.contractName}
            onChange={handleChange}
            onFocus={onContractNameFocus}
            placeholder="Contract Name"
        />
    );
};

export default ContractName;
