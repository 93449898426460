import { cloneElement, Component } from 'react';

import { ISquareButtonProps, SquareButtonTheme } from './SquareButton-types';
import { Link } from 'react-router-dom';

class SquareButton extends Component<ISquareButtonProps> {
    renderIcon(icon: JSX.Element) {
        let className = 'square-button-icon';

        if (icon.props.className) {
            className = `${icon.props.className} ${className}`;
        }

        return cloneElement(icon, { ...icon.props, className });
    }

    render() {
        const { colourTheme, icon, targetRoute } = this.props;

        const theme = colourTheme ? colourTheme : SquareButtonTheme.LIGHT;

        return (
            <div className={`square-button ${theme}`}>
                {targetRoute ? (
                    <Link className="square-button-link" to={targetRoute}>
                        {this.renderIcon(icon)}
                    </Link>
                ) : (
                    this.renderIcon(icon)
                )}
            </div>
        );
    }
}

export default SquareButton;
