import { Component } from 'react';
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
    Lock,
    Save,
} from '@mui/icons-material';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { toast } from 'react-toastify';
import {
    IIssueForm,
    IIssuePaneProps,
    IIssuePaneState,
    IssueFormField,
    systemUser,
} from './IssuePane-types';
import {
    HighlightIndexType,
    IContractProperty,
} from '../Contract/Contract-types';
import {
    IssueCreationType,
    IssuePrivacySetting,
    IssueStateInVersion,
} from '../IssueListPane/IssueListPane-types';
import IssueResource from '../../resources/IssueResource';
import { mapClauseNumber, mapIssue } from '../App/App-mappers';
import { arrayDifferenceResolver } from '../App/App-helpers';
import { IIssueRequestPayload } from '../../resources/IssueResource-types';
import { getResourceIdFromResponse } from '../../resources/resource-helpers';
import { syncContractData } from '../Contract/Contract-helpers';
import { analytics } from './IssuePane-analytics';
import { SubmitStatus } from '../App/App-types';
import { resolveSeverityLevelValue } from '../IssueListPane/IssuesListPane-helpers';
import Voting from '../Voting/Voting';
import PropertyDetails from '../PropertyDetails/PropertyDetails';
import PropertyResource from '../../resources/PropertyResource';
import { ComponentReference } from '../../services/Analytics/Analytics-types';
import {
    linkUnlinkPropertyParagraphs,
    resolvePropertyValue,
} from '../../services/legacyHTMLParser-helpers';
import { ISummaryProperty } from '../SummaryPane/SummaryPane-types';
import { NO_VALUE } from '../../services/legacyHTMLParser-types';
import './IssuePane.scss';
import IssuePaneTabs from './IssuePaneTabs/IssuePaneTabs-container';
import { ContractSidebarTab } from '../ContractSidebar/ContractSidebar-types';
import {
    Badge,
    Button,
    Drawer,
    TextField,
    Tooltip,
} from '@thought-river/ui-components';
import { BannerClauseType, BannerType } from '../HeatmapPane/HeatmapPane-types';
import dayjs from 'dayjs';
import {
    ContractIssue,
    IssueSeverityDropdown,
    IssueSeverityLevel,
    IssueStatus,
    IssueStatusDropdown,
    ParagraphClause,
} from '@thought-river/negotiations-common';
import { LexiblePropertyDetailsButton } from '@modules/common/components/LexiblePropertyDetailsButton';
import debounce from 'lodash/debounce';
import find from 'lodash/find';

//TODO: make this into a functional component
@analytics()
class IssuePane extends Component<IIssuePaneProps, IIssuePaneState> {
    constructor(props: IIssuePaneProps) {
        super(props);

        this.state = {
            changedFields: [],
            detectChanges: false,
            formInitialised: false,
            property: null!, // Fixme: null checks
            showPropertyDetails: false,
            submitAttempted: false,
            submitting: false,
            originalFilteredIssues: props.sortedFilteredIssues,
        };

        this.handleGoToIssuesList = this.handleGoToIssuesList.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onClickSelectedClause = this.onClickSelectedClause.bind(this);
        this.saveIssue = this.saveIssue.bind(this);
        this.sendPredictionFeedback = this.sendPredictionFeedback.bind(this);
        this.toggleShowPropertyDetails =
            this.toggleShowPropertyDetails.bind(this);
    }

    async componentDidUpdate(prevProps: IIssuePaneProps) {
        const {
            isIssuePaneOpen,
            highlightedParagraphs,
            form,
            issue,
            properties,
            setIssueForm,
            setFormStatus,
            sortedFilteredIssues,
        } = this.props;

        let state = { ...this.state };

        if (
            (!prevProps.isIssuePaneOpen && isIssuePaneOpen) ||
            prevProps.issue?.id !== issue?.id
        ) {
            const property = issue?.lexiblePropertyId
                ? properties.find(
                      (property) =>
                          property.nonAssessedPropertyId ===
                          issue.lexiblePropertyId
                  )
                : null;

            state = {
                ...state,
                formInitialised: false,
                submitAttempted: false,
                detectChanges: false,
                property: property!, // Fixme: null checks
            };
            this.setState(state);
            setIssueForm(this.populateForm(issue));
            setFormStatus(SubmitStatus.SUCCESS);
            this.setState({
                formInitialised: true,
            });
        }

        if (!prevProps.isIssuePaneOpen && isIssuePaneOpen) {
            this.setState({
                originalFilteredIssues: sortedFilteredIssues,
            });
        }

        if (
            issue?.id &&
            JSON.stringify(prevProps.form) !== JSON.stringify(form)
        ) {
            if (state.detectChanges) {
                this.detectChangedFields(prevProps.form, form);
            } else {
                this.setState({
                    detectChanges: true,
                });
            }
        }

        if (
            !prevProps.isIssuePaneOpen &&
            isIssuePaneOpen &&
            issue &&
            issue.clauseNumbers.length &&
            !highlightedParagraphs.length
        ) {
            this.onClickSelectedClause(issue.clauseNumbers[0].originUuid);
        }
    }

    private async detectChangedFields(
        prevForm: IIssueForm,
        form: IIssueForm
    ): Promise<void> {
        const { changedFields } = this.state;

        let changedField: IssueFormField | null = null;

        for (const field in form) {
            if (prevForm[field] !== form[field]) {
                changedField = field as IssueFormField;
            }
        }

        if (changedField && !changedFields.includes(changedField)) {
            this.setState({
                changedFields: [...changedFields, changedField],
            });
        }
    }

    private goToNextIssue(delta: -1 | 1) {
        const {
            issue: currentIssue,
            // selectedParagraph,
            setSelectedIssue,
            issues: originalIssues,
            setBannerSettings,
        } = this.props;

        const { originalFilteredIssues } = this.state;

        if (!currentIssue) {
            return;
        }

        const currentIssueIndex = originalFilteredIssues.findIndex(
            (issue) => issue.id === currentIssue.id
        );

        if (currentIssueIndex !== -1) {
            let nextIndex = currentIssueIndex + delta;

            if (nextIndex >= originalFilteredIssues.length) {
                nextIndex = 0;
            } else if (nextIndex < 0) {
                nextIndex = originalFilteredIssues.length - 1;
            }

            const nextIssueId = originalFilteredIssues[nextIndex].id;
            const nextIssue = originalIssues.find(
                (originalIssue) => originalIssue.id === nextIssueId
            );
            const noLinkedClauses = !nextIssue?.paragraphOriginUuids.length;

            if (nextIssue) {
                setSelectedIssue(nextIssue);
                setBannerSettings({
                    type: BannerType.INFO,
                    clauseType: noLinkedClauses
                        ? BannerClauseType.PROPERTY_RELATED
                        : BannerClauseType.PROPERTY_LINKED,
                    items: noLinkedClauses
                        ? nextIssue.relatedParagraphIds
                        : nextIssue.paragraphIds,
                });
            }
        }
    }

    private onClickSelectedClause(paragraphOriginUuid: string) {
        const { paragraphs, setSelectedParagraph, setHighlightIndex } =
            this.props;

        const paragraph = find(paragraphs, { originUuid: paragraphOriginUuid });

        if (paragraph) {
            setHighlightIndex(paragraph.index, HighlightIndexType.START);
            setHighlightIndex(paragraph.index, HighlightIndexType.END);
            setSelectedParagraph(paragraph);
        }
    }

    private onClose() {
        const {
            setHighlightIndex,
            setSelectedIssue,
            setSelectedParagraph,
            setBannerSettings,
            setSelectedPropertyCode,
            closeIssuePane,
        } = this.props;

        closeIssuePane();
        setHighlightIndex(-1, HighlightIndexType.START);
        setHighlightIndex(-1, HighlightIndexType.END);
        setSelectedParagraph(null);
        setSelectedPropertyCode(null!); // Fixme: null checks
        setSelectedIssue(null!); // Fixme: null checks
        setBannerSettings(null!); // Fixme: null checks
    }

    async saveProperty(
        property: IContractProperty,
        allClauseNumbers: ParagraphClause[],
        paragraphIds: string[],
        paragraphOriginUuids: string[]
    ) {
        const {
            form,
            summaryProperties,
            updateContractProperty,
            updateSummaryProperty,
        } = this.props;

        const propertyPrevClauseNumbers = allClauseNumbers.filter((clause) =>
            property.paragraphOriginUuids.includes(clause.originUuid)
        );

        const {
            current: linkedParagraphs,
            added: newParagraphs,
            removed: removedParagraphs,
            emptied: unlink,
        } = arrayDifferenceResolver(
            propertyPrevClauseNumbers,
            form.clauseNumbers,
            'originUuid'
        );

        const value = resolvePropertyValue(
            property.valueType,
            linkedParagraphs.length,
            'value',
            null! // Fixme: null checks
        );

        const summaryProperty = summaryProperties.find(
            (sp) => sp.code === property.code
        );

        const updatedSummaryProperty: ISummaryProperty | null = summaryProperty
            ? {
                  ...summaryProperty,
                  linkedParagraphIds: paragraphIds,
                  paragraphOriginUuids,
                  value: value!, // Fixme: null checks
              }
            : null;

        if (updatedSummaryProperty) {
            updateSummaryProperty(updatedSummaryProperty);
        }

        const updatedContractProperty: IContractProperty = {
            ...property,
            paragraphIds,
            paragraphOriginUuids,
            value: resolvePropertyValue(
                property.valueType,
                linkedParagraphs.length,
                'answer',
                null! // Fixme: null checks
            )!, // Fixme: null checks,
        };

        this.setState({
            property: updatedContractProperty,
        });

        updateContractProperty(updatedContractProperty);

        await linkUnlinkPropertyParagraphs({
            code: property.code,
            linkValue: value!, // Fixme: null checks
            unlinkValue: NO_VALUE,
            unlink,
            newParagraphs,
            removedParagraphs,
        });

        syncContractData();
    }

    private async saveIssue() {
        const {
            contract,
            form,
            issues,
            paragraphs,
            properties,
            setIssueForm,
            setIssues,
            setSelectedIssue,
            setFormStatus,
            lexibleProperties,
            categories,
            themes,
        } = this.props;
        const { property } = this.state;

        const {
            id: contractId,
            latestVersion: { id: versionId },
            streamCode: stream,
        } = contract;

        this.setState({
            submitAttempted: true,
        });

        if (!form.title.trim().length) {
            return;
        }

        await this.setState({
            submitting: true,
        });

        setFormStatus(SubmitStatus.PROCESSING);

        const updatedParagraphOriginUuids: string[] = [];
        const updatedParagraphIds: string[] = [];

        form.clauseNumbers.forEach((clause) => {
            updatedParagraphOriginUuids.push(clause.originUuid);
            updatedParagraphIds.push(clause.id);
        });

        const payload: IIssueRequestPayload = {
            data: {
                attributes: {
                    'category-uuids': form.categories.map((cat) => cat.id),
                    'creation-type': IssueCreationType.MANUAL,
                    description: form.description,
                    notes: form.notes,
                    'paragraph-origin-uuids': updatedParagraphOriginUuids,
                    privacy: IssuePrivacySetting.INTERNAL,
                    'severity-level': resolveSeverityLevelValue(
                        form.severityLevel
                    ),
                    'issue-type': 'Significant',
                    status: form.status,
                    title: form.title,
                    'summary-of-our-position': '',
                    'summary-of-resolution': '',
                    'summary-of-their-position': '',
                },
            },
        };

        try {
            const clauseNumbers = paragraphs.map(mapClauseNumber);
            let response;

            if (form.issueId) {
                await IssueResource.updateIssue(
                    contractId,
                    versionId,
                    form.issueId,
                    stream,
                    payload,
                    ComponentReference.ISSUE_PANE
                );
                response = await IssueResource.getIssue(
                    contractId,
                    versionId,
                    form.issueId,
                    stream
                );
            } else {
                const newIssueResponse = await IssueResource.createIssue(
                    contractId,
                    versionId,
                    stream,
                    payload
                );
                const newIssueId = getResourceIdFromResponse(newIssueResponse);
                response = await IssueResource.getIssue(
                    contractId,
                    versionId,
                    newIssueId!, // Fixme: null checks
                    stream
                );
            }

            await this.setState({
                submitting: false,
                changedFields: [],
            });

            const newIssue = mapIssue(
                response.data,
                clauseNumbers,
                properties,
                paragraphs,
                lexibleProperties,
                categories,
                themes,
                contract.latestVersion
            );

            setIssueForm(this.populateForm(newIssue));
            setSelectedIssue(newIssue);

            if (form.issueId) {
                setIssues(
                    issues.map((issue) =>
                        issue.id === newIssue.id ? newIssue : issue
                    )
                );
            } else {
                setIssues([newIssue, ...issues]);
            }

            if (property) {
                this.saveProperty(
                    property,
                    clauseNumbers,
                    updatedParagraphIds,
                    updatedParagraphOriginUuids
                );
            }

            setFormStatus(SubmitStatus.SUCCESS);
        } catch (error) {
            await this.setState({
                submitting: false,
                changedFields: [],
            });

            setFormStatus(SubmitStatus.FAILURE);
        }

        if (!form.issueId) {
            this.onClose();
        }
    }

    private debouncedSaveIssue = debounce(async () => {
        if (this.state.changedFields.length) {
            this.saveIssue();
        }
    }, 4000);

    private populateForm(issue: ContractIssue | null = null): IIssueForm {
        const { highlightedParagraphs, userId, users } = this.props;
        const description = issue ? issue.description : '';
        const defaultReviewers = issue?.reviewerIds.length
            ? users.filter((user) => issue.reviewerIds.includes(user.id))
            : users.filter((user) => user.id === userId);
        const lastModifiedById = issue ? issue.lastModifiedById : userId;
        const lastModifier =
            users.find((user) => user.id === lastModifiedById) ?? systemUser;

        return {
            categories: issue?.categories ?? [],
            clauseNumbers: issue
                ? issue.clauseNumbers
                : highlightedParagraphs.map(mapClauseNumber),
            createdAt: (issue ? dayjs(issue.createdAt) : dayjs()).format(
                'DD MMM YYYY'
            ),
            description,
            issueId: issue ? issue.id : '',
            lastModifier,
            missingSuggestionLocations: issue?.missingSuggestionLocations ?? [],
            notes: issue?.notes ?? '',
            reviewers: defaultReviewers,
            severityLevel: issue
                ? issue.severityLevel
                : IssueSeverityLevel.HIGH,
            status: issue ? issue.status : IssueStatus.OPEN,
            title: issue?.title ?? '',
            updatedAt: (issue ? dayjs(issue.updatedAt) : dayjs()).format(
                'DD MMM YYYY'
            ),
            stateLabel: issue?.stateLabel ?? IssueStateInVersion.NEW,
        };
    }

    private handleGoToIssuesList(): void {
        const { setContractSidebarTab } = this.props;

        this.onClose();

        setTimeout(() => {
            setContractSidebarTab(ContractSidebarTab.ISSUES);
        }, 0);
    }

    private renderIssueNavigation(): JSX.Element {
        const { originalFilteredIssues: issues } = this.state;

        const disabled = issues.length === 1;

        return (
            <>
                <Button
                    variant="secondary"
                    data-id="nav-control-prev"
                    onClick={() => this.goToNextIssue(-1)}
                    startIcon={<KeyboardArrowLeft />}
                    disabled={disabled}
                >
                    Previous
                </Button>
                <Button
                    variant="secondary"
                    data-id="nav-control-next"
                    onClick={() => this.goToNextIssue(1)}
                    endIcon={<KeyboardArrowRight />}
                    disabled={disabled}
                >
                    Next
                </Button>
            </>
        );
    }

    private renderLockedVersionIcon(
        versionIsLocked: boolean = false
    ): React.ReactNode {
        return (
            versionIsLocked && (
                <Tooltip
                    placement="bottom"
                    title="Older version - editing disabled"
                >
                    <Lock className="locked-version-icon" />
                </Tooltip>
            )
        );
    }

    private async sendPredictionFeedback(feedback: -1 | 1): Promise<void> {
        const {
            assessment,
            contract,
            selectedVersion,
            updateContractProperty,
            userId,
        } = this.props;
        const { property } = this.state;

        const options = {
            contractId: contract.id,
            versionId: selectedVersion?.id ?? '',
            propertyId: property.id,
            assessmentId: assessment.id,
            stream: contract.streamCode,
            feedback,
            referenceComponent: ComponentReference.ISSUE_PANE,
        };

        const updatedProperty = {
            ...property,
            feedback: [
                ...property.feedback,
                {
                    userId,
                    vote: feedback,
                },
            ],
        };

        try {
            this.setState({
                property: updatedProperty,
            });

            updateContractProperty(updatedProperty);

            return PropertyResource.sendFeedback(options);
        } catch {
            this.setState({
                property,
            });

            updateContractProperty(property);

            toast.error('Error sending predication feedback');
        }
    }

    private renderVoting() {
        const { issue, userId } = this.props;

        const { property } = this.state;

        let votedUp = false;
        let votedDown = false;

        property?.feedback.forEach((feedback) => {
            if (feedback.userId === userId) {
                if (feedback.vote === 1) {
                    votedUp = true;
                } else if (feedback.vote === -1) {
                    votedDown = true;
                }
            }
        });

        return (
            property &&
            issue?.creationType === IssueCreationType.AUTO && (
                <Voting
                    votedUp={votedUp}
                    votedDown={votedDown}
                    onVoteCallback={this.sendPredictionFeedback}
                    tooltipPlacement="bottom"
                />
            )
        );
    }

    private renderIssueTitle() {
        const { form, formStatus, issue, selectedVersion, setIssueForm } =
            this.props;

        const { submitAttempted } = this.state;

        const showError = submitAttempted && !form.title.trim().length;

        const versionIsLocked = selectedVersion && !selectedVersion.isLatest;

        return (
            <TextField
                data-id="issue-title"
                error={showError}
                helperText={showError && 'Field is required.'}
                fullWidth
                required
                onChange={({ target: { value } }) => {
                    setIssueForm({ ...form, title: value });
                    if (issue) {
                        this.debouncedSaveIssue();
                    }
                }}
                onBlur={() => {
                    if (issue && issue.title !== form.title) {
                        this.saveIssue();
                    }
                }}
                value={form.title}
                readOnly={
                    versionIsLocked || formStatus === SubmitStatus.PROCESSING
                }
                placeholder="Enter issue name"
                size="large"
            />
        );
    }

    private toggleShowPropertyDetails() {
        this.setState({
            showPropertyDetails: !this.state.showPropertyDetails,
        });
    }

    render() {
        const {
            issue,
            policies,
            selectedVersion,
            width,
            setIssueForm,
            form,
            isIssuePaneOpen,
        } = this.props;

        const { formInitialised, property, showPropertyDetails, submitting } =
            this.state;

        const versionIsLocked = !!selectedVersion && !selectedVersion.isLatest;

        const showPropertyDetailsButton = !!issue?.lexiblePropertyId;

        const onStatusChanged = async (status: IssueStatus) => {
            await setIssueForm({ ...form, [IssueFormField.STATUS]: status });
            if (form.issueId) {
                this.saveIssue();
            }
        };

        const onSeverityChanged = async (level: IssueSeverityLevel) => {
            await setIssueForm({
                ...form,
                [IssueFormField.SEVERITY_LEVEL]: level,
            });
            if (form.issueId) {
                this.saveIssue();
            }
        };

        return (
            <Drawer
                open={isIssuePaneOpen}
                width={width}
                onClose={this.onClose}
                title={
                    <div
                        className="issue-pane-header"
                        data-id="issue-pane-header"
                    >
                        <div className="issue-pane-header-badges">
                            {form.stateLabel && (
                                <Badge type="pink" label={form.stateLabel} />
                            )}
                            {this.renderLockedVersionIcon(versionIsLocked)}
                        </div>
                        {this.renderVoting()}
                    </div>
                }
                footerContent={
                    <div className="issue-pane-actions">
                        {issue ? (
                            this.renderIssueNavigation()
                        ) : (
                            <Button
                                onClick={() => this.saveIssue()}
                                disabled={submitting || versionIsLocked}
                                startIcon={<Save />}
                            >
                                Create
                            </Button>
                        )}
                    </div>
                }
            >
                <div className="issue-pane" data-id="issue-pane">
                    <div className="issue-title-wrapper">
                        {this.renderIssueTitle()}
                        {showPropertyDetailsButton && (
                            <LexiblePropertyDetailsButton
                                onClick={this.toggleShowPropertyDetails}
                            />
                        )}
                    </div>
                    <div
                        className="issue-pane-dropdowns"
                        data-id="issue-pane-dropdowns"
                    >
                        <IssueSeverityDropdown
                            severityLevel={form.severityLevel}
                            disabled={versionIsLocked}
                            onSeverityChange={(value) =>
                                onSeverityChanged(value)
                            }
                        />
                        <IssueStatusDropdown
                            status={form.status}
                            disabled={versionIsLocked}
                            onStatusChange={(value) => onStatusChanged(value)}
                        />
                    </div>
                    <IssuePaneTabs
                        debouncedSaveIssue={this.debouncedSaveIssue}
                        formInitialised={formInitialised}
                        lockedVersion={versionIsLocked}
                        onClickSelectedClause={this.onClickSelectedClause}
                        propertyDetailsPaneOpen={showPropertyDetails}
                        saveIssue={this.saveIssue}
                        submitAttempted={this.state.submitAttempted}
                        toggleShowPropertyDetails={
                            this.toggleShowPropertyDetails
                        }
                    />
                    <PropertyDetails
                        policies={policies}
                        property={property}
                        open={showPropertyDetails}
                        width={width}
                        onClose={this.toggleShowPropertyDetails}
                    />
                </div>
            </Drawer>
        );
    }
}

export default IssuePane;
