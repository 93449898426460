import { DataGrid } from '@thought-river/ui-components';
import { ContractsDataGridProps } from './types';
import {
    GridInitialState,
    GridRowParams,
    GridValueGetterParams,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import { useGetColumns, usePersistDataGridState } from './hooks';
import { NegotiationCell } from './cells';
import { ContractMenu } from '@modules/common/components/ContractMenu';
import { ContractInfo, FileFormat } from '@modules/common/types';
import {
    ProcessingStep,
    Reviewer,
} from '@thought-river/negotiations-common/dist/api/contractManagement';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
    checkHasContractFailedProcessing,
    isProcessingStepComplete,
} from '@thought-river/negotiations-common';
import { useLocalStorageConfig } from './hooks/useLocalStorageConfig';

const ContractsDataGrid = ({
    data,
    rowSelectionModel,
    onSelectedRowsChange,
    toolbarLeftContent,
}: ContractsDataGridProps) => {
    const apiRef = useGridApiRef();

    const { config, onConfigChange } = useLocalStorageConfig();

    const [orderedFields, setOrderedFields] = useState<string[]>(
        config.columns?.orderedFields ?? []
    );
    const [expandedRowIds, setExpandedRowIds] = useState<Set<string>>(
        new Set()
    );

    useEffect(() => {
        const unsubscribe = apiRef.current.subscribeEvent(
            'rowExpansionChange',
            (params) => {
                if (params.childrenExpanded) {
                    setExpandedRowIds((existingIds) => {
                        const newIds = new Set(existingIds);
                        newIds.add(String(params.id));
                        return newIds;
                    });
                } else {
                    setExpandedRowIds((existingIds) => {
                        const newIds = new Set(existingIds);
                        newIds.delete(String(params.id));
                        return newIds;
                    });
                }
            }
        );

        return () => unsubscribe();
    }, [apiRef]);

    const initialState: GridInitialState = useMemo(
        () => ({
            pinnedColumns: {
                right: ['status', 'actions'],
            },
            sorting: { sortModel: [{ field: 'upload_date', sort: 'desc' }] },
            ...config,
        }),
        [config]
    );

    const getActionsColumnActions = useCallback(
        (params: GridRowParams) => {
            const processingSteps = params.row
                .processing_steps as ProcessingStep[];
            const hasFinishedProcessing =
                !!processingSteps.length &&
                processingSteps.every(isProcessingStepComplete);
            const hasFailedProcessing =
                checkHasContractFailedProcessing(processingSteps);

            const contractId = params.row.contract_uuid;
            const isOnlyVersion =
                data?.rows.filter((row) => row.contract_uuid === contractId)
                    .length === 1;

            const contractInfo: ContractInfo = {
                name: params.row.version_name,
                uuid: contractId,
                versionUuid: params.row.version.uuid,
                versionNumber: params.row.version.number,
                isLatestVersion: params.row.hierarchy?.length === 1,
                isOnlyVersion,
                dealTypeCode: params.row.deal_type.code,
                dealTypeUuid: params.row.deal_type.uuid,
                documentUuid: params.row.document_uuid,
                fileFormats: [
                    FileFormat.DOCX,
                    ...(params.row.pdf_available ? [FileFormat.PDF] : []),
                ],
                ownerId: params.row.owner.uuid,
                reviewerIds: params.row.reviewers.map((r: Reviewer) => r.uuid),
                hasFinishedProcessing,
                hasFailedProcessing,
                isSigned: params.row.is_signed,
                isTemplate: params.row.is_template,
            };
            return [
                <ContractMenu key={params.id} contractInfo={contractInfo} />,
            ];
        },
        [data?.rows]
    );

    const columns = useGetColumns(
        data?.columns ?? [],
        orderedFields,
        getActionsColumnActions
    );

    const persistDataGridStateProps = usePersistDataGridState({
        onConfigChange,
        onOrderedFieldsChange: setOrderedFields,
        apiRef,
    });

    const getTreeDataPath: DataGridProProps['getTreeDataPath'] = useCallback(
        (row) => row.hierarchy,
        []
    );

    return (
        <DataGrid
            apiRef={apiRef}
            rows={data?.rows ?? []}
            columns={columns}
            treeData
            isGroupExpandedByDefault={(node) =>
                expandedRowIds.has(String(node.id))
            }
            groupingColDef={{
                headerName: 'Negotiation',
                disableColumnMenu: false,
                sortable: true,
                filterable: true,
                renderCell: NegotiationCell,
                minWidth: 200,
                flex: 1,
                valueGetter: (params: GridValueGetterParams) =>
                    params.row.version_name,
                aggregable: false,
            }}
            checkboxSelection
            checkboxSelectionVisibleOnly
            isRowSelectable={(params: GridRowParams) =>
                params.row.hierarchy.length === 1
            }
            getTreeDataPath={getTreeDataPath}
            initialState={initialState}
            disableRowSelectionOnClick
            hideFooterSelectedRowCount
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={onSelectedRowsChange}
            slotProps={{
                toolbar: {
                    copyTableProps: { hide: true },
                    exportProps: { hide: true },
                    leftContent: toolbarLeftContent,
                },
            }}
            {...persistDataGridStateProps}
        />
    );
};

export default memo(ContractsDataGrid);
