import {
    ReportColumn,
    ReportFilter,
} from '@thought-river/negotiations-common/dist/api/contractContent';
import { useGroupSelectedColumns } from './useGroupSelectedColumns';
import { ReportDefinitionIn } from '@thought-river/negotiations-common/dist/api/playbookManager';
import { ReportFilters } from '@modules/reports/common/components';

export interface BaseReportDefinitionRequestBody
    extends Pick<
        ReportDefinitionIn,
        'columns' | 'filters' | 'properties' | 'config'
    > {}

export const useGetBaseReportDefinitionRequestBody = (
    selectedColumns: (ReportColumn | string)[],
    selectedFilters: ReportFilters,
    visibleColumns: (ReportColumn | string)[]
) => {
    const { properties, contractMetadata } =
        useGroupSelectedColumns(selectedColumns);

    const filters: ReportFilter[] = Object.entries(selectedFilters).map(
        ([key, value]) => {
            if (
                key === 'counter_party' ||
                key === 'own_party' ||
                key === 'status' ||
                key === 'deal_type'
            )
                return {
                    field: key as ReportColumn,
                    operator: 'isAnyOf',
                    value: value,
                };
            else {
                return {
                    field: key as ReportColumn,
                    operator: 'contains',
                    value: value,
                };
            }
        }
    );

    const columnVisibilityModel = {};
    selectedColumns.forEach((c) => {
        if (!visibleColumns.includes(c)) {
            columnVisibilityModel[c] = false;
        }
    });

    return {
        columns: contractMetadata,
        properties: properties,
        filters: { items: filters },
        config: {
            columns: {
                orderedFields: selectedColumns,
                columnVisibilityModel: columnVisibilityModel,
            },
        },
    };
};
