import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import Parties from './Parties';

import {
    setExpandedGroupAction,
    flipGroupsAction,
    setScrollToPartyAction,
    setDraggedPartyAction,
} from './Parties-actions';
import {
    IPartiesDispatchProps,
    PartiesType,
    IPartiesStateProps,
    IParty,
} from './Parties-types';
import { IRootState } from '../../redux/reducers-types';
import {
    addPartyAction,
    updatePartyAction,
    setSelectedPartyAction,
} from '../Party/Party-actions';

function mapStateToProps(state: IRootState): IPartiesStateProps {
    return {
        expandedGroup: state.parties.expandedGroup,
        parties: state.parties.parties,
        partyToAdd: state.parties.partyToAdd,
        selectedParty: state.parties.selectedParty,
        scrollToParty: state.parties.scrollToParty,
        draggedParty: state.parties.draggedParty,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IPartiesDispatchProps {
    return {
        addParty: (partyToAdd: string, partyType: PartiesType) =>
            dispatch(addPartyAction(partyToAdd, partyType)),
        flipGroups: (typeA: PartiesType, typeB: PartiesType) =>
            dispatch(flipGroupsAction(typeA, typeB)),
        setExpandedGroup: (group: PartiesType) =>
            dispatch(setExpandedGroupAction(group)),
        setScrollToParty: (party: IParty) =>
            dispatch(setScrollToPartyAction(party)),
        setSelectedParty: (party: IParty) =>
            dispatch(setSelectedPartyAction(party)),
        setDraggedParty: (party: IParty) =>
            dispatch(setDraggedPartyAction(party)),
        updateParty: (party: IParty) => dispatch(updatePartyAction(party)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Parties);
