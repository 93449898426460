import {
    CalendarMonth,
    FormatListBulleted,
    Label,
    Numbers,
    QuestionAnswerOutlined,
    TextFieldsOutlined,
} from '@mui/icons-material';
import styles from './ColumnIcon.module.scss';
import { ReportColumn } from '@thought-river/negotiations-common/dist/api/contractContent';

interface ColumnIconProps {
    column: ReportColumn | string;
}
const ColumnIcon = ({ column }: ColumnIconProps) => {
    switch (column) {
        case 'contract_name':
        case 'counter_party':
        case 'own_party':
            return <TextFieldsOutlined className={styles.icon} />;
        case 'deal_type':
            return <FormatListBulleted className={styles.icon} />;
        case 'last_processed_date':
        case 'upload_date':
            return <CalendarMonth className={styles.icon} />;
        case 'status':
            return <Label className={styles.icon} />;
        case 'version':
            return <Numbers className={styles.icon} />;
        default:
            return <QuestionAnswerOutlined className={styles.icon} />;
    }
};

export default ColumnIcon;
