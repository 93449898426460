import * as React from 'react';
import { Link } from 'react-router-dom';
import {
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';

import logo from '../../assets/logo.svg';
import {
    AccountBalance,
    BusinessCenter,
    Chat,
    DeviceHub,
    ErrorOutline,
    Folder,
    FolderSpecial,
    HelpOutline,
    HomeOutlined,
    Language,
    Layers,
    LiveHelp,
    Menu,
    School,
} from '@mui/icons-material';
import {
    IMobileMenuProps,
    IMobileMenuState,
    MenuItemType,
    IMenuListItem,
} from './MobileMenu-types';
import { UserRole } from '../Auth/Auth-types';
import { AppMenuItem } from '../App/App-types';
import { doesIntersect } from '../App/App-helpers';
import { getLegacyUIUrl } from '@modules/common/helpers';

export default class MobileMenu extends React.Component<
    IMobileMenuProps,
    IMobileMenuState
> {
    constructor(props: IMobileMenuProps) {
        super(props);

        this.state = {
            open: false,
        };

        this.filterMenuItem = this.filterMenuItem.bind(this);
        this.renderMenuItem = this.renderMenuItem.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
    }

    private toggleDrawer(open: boolean) {
        return (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            this.setState({ ...this.state, open });
        };
    }

    filterMenuItem(menuItem: IMenuListItem): boolean {
        const { roles } = this.props;

        if (menuItem.requiredRoles) {
            return doesIntersect(menuItem.requiredRoles, roles);
        }

        return true;
    }

    private renderMenuItem(
        menuItem: IMenuListItem,
        index: number
    ): JSX.Element {
        const { link, label, icon, suffix } = menuItem;
        const { url = '', target = '_self' } = link ?? {};

        const updatedUrl = url.replace('{reviewUrl}', getLegacyUIUrl());
        const component = ~updatedUrl.search(/^(?:http(?:s?):\/\/)|(?:mailto:)/)
            ? 'a'
            : Link;

        if (menuItem.type === MenuItemType.DIVIDER) {
            return (
                <Divider
                    key={`${MenuItemType.DIVIDER}_${index}`}
                    className="divider"
                />
            );
        }

        return (
            <ListItem
                key={label}
                className="list-item"
                button
                component={component}
                to={updatedUrl}
                href={updatedUrl}
                target={target}
            >
                <ListItemIcon className="icon">{icon}</ListItemIcon>
                <ListItemText className="text" primary={label} />
                {suffix}
            </ListItem>
        );
    }

    render() {
        const menuItems: IMenuListItem[] = [
            {
                icon: <HomeOutlined />,
                label: AppMenuItem.HOME,
                link: { url: '/' },
            },
            {
                type: MenuItemType.DIVIDER,
            },
            {
                icon: <AccountBalance />,
                label: AppMenuItem.ORGANIZATIONS,
                link: { url: '{reviewUrl}/admin#/organisations' },
                requiredRoles: [UserRole.LEGAL_ADMIN, UserRole.AUTHOR],
            },
            {
                icon: <Layers />,
                label: AppMenuItem.REFERENCE_NAME_TEMPLATES,
                link: { url: '{reviewUrl}/reference_name_templates#/' },
                requiredRoles: [UserRole.LEGAL_ADMIN, UserRole.AUTHOR],
            },
            {
                icon: <DeviceHub />,
                label: AppMenuItem.AUTHORING,
                link: { url: '{reviewUrl}/lexible' },
                requiredRoles: [UserRole.AUTHOR],
            },
            {
                icon: <School />,
                label: AppMenuItem.TRAINING,
                link: { url: '{reviewUrl}/training' },
                requiredRoles: [UserRole.AUTHOR],
            },
            {
                icon: <FolderSpecial />,
                label: AppMenuItem.POLICIES,
                link: { url: '{reviewUrl}/admin#/policies' },
                requiredRoles: [UserRole.AUTHOR],
            },
            {
                icon: <Chat />,
                label: AppMenuItem.SUGGESTIONS,
                link: { url: '{reviewUrl}/admin#/suggestions' },
                requiredRoles: [UserRole.AUTHOR],
            },
            {
                icon: <Language />,
                label: AppMenuItem.REVIEW_STREAMS,
                link: { url: '{reviewUrl}/admin#/accounts' },
                requiredRoles: [UserRole.AUTHOR],
            },
            {
                icon: <BusinessCenter />,
                label: AppMenuItem.LEXIBLE_CONTRACT_TYPES,
                link: { url: '{reviewUrl}/lexible/#/contract-types' },
                requiredRoles: [UserRole.AUTHOR],
            },
            {
                icon: <Folder />,
                label: AppMenuItem.PORTFOLIOS,
                link: { url: '{reviewUrl}/admin#/portfolios' },
                requiredRoles: [UserRole.AUTHOR],
            },
            { type: MenuItemType.DIVIDER },
            {
                icon: <HelpOutline />,
                label: AppMenuItem.KNOWLEDGE_BASE,
                link: {
                    target: '_blank',
                    url: 'https://support.thoughtriver.com',
                },
            },
            {
                icon: <LiveHelp />,
                label: AppMenuItem.TOUR,
                link: { url: '#' },
            },
            {
                icon: <ErrorOutline />,
                label: AppMenuItem.HELP,
                link: {
                    target: '_blank',
                    url: 'https://support.thoughtriver.com/kb-tickets/new',
                },
            },
        ];

        const filteredMenuItems = menuItems.filter(this.filterMenuItem);

        return (
            <React.Fragment>
                <IconButton
                    className="mobile-menu-burger-button"
                    onClick={this.toggleDrawer(true)}
                >
                    <Menu />
                </IconButton>
                <Drawer
                    anchor="left"
                    open={this.state.open}
                    onClose={this.toggleDrawer(false)}
                    className="mobile-menu-drawer"
                >
                    <List>
                        <ListItem
                            className="logo-wrapper list-item"
                            button
                            component={Link}
                            to="/"
                        >
                            <img
                                className="logo"
                                src={logo}
                                alt="ThoughtRiver Logo"
                            />
                        </ListItem>
                        {filteredMenuItems.map((menuItem, index) =>
                            this.renderMenuItem(menuItem, index)
                        )}
                    </List>
                </Drawer>
            </React.Fragment>
        );
    }
}
