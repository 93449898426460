import { Component } from 'react';

import { ISplashLoaderProps, SplashLoaderType } from './SplashLoader-types';
import './SplashLoader.scss';

class SplashLoader extends Component<ISplashLoaderProps> {
    render() {
        const {
            message,
            type = SplashLoaderType.LOADER,
            size = 'medium',
        } = this.props;

        return (
            <div
                className={`splash-loader-wrapper ${size}`}
                data-id="splash-loader-wrapper"
            >
                <div
                    className="splash-loader-content"
                    data-id="splash-loader-content"
                >
                    {type === SplashLoaderType.LOADER ? (
                        <>
                            <span className="loader" />
                            <span
                                className="loader-text"
                                data-id="splash-loader-message"
                            >
                                {message}
                            </span>
                        </>
                    ) : null}
                    {type === SplashLoaderType.ERROR ? (
                        <span
                            className="error-message"
                            data-id="splash-loader-message"
                        >
                            {message}
                        </span>
                    ) : null}
                    {type === SplashLoaderType.MESSAGE ? (
                        <span
                            className="message"
                            data-id="splash-loader-message"
                        >
                            {message}
                        </span>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default SplashLoader;
