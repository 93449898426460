import { RouteComponentProps } from 'react-router-dom';
import { IFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider-types';

export interface IHeaderTabsState {
    selectedTabIndex: number;
}

export interface IHeaderTabsStateProps {
    featureToggles: IFeatureToggle[];
}

export interface IHeaderTabsReducer {}

export interface IHeaderTabsDispatchProps {}

export interface IHeaderTabsProps
    extends IHeaderTabsDispatchProps,
        IHeaderTabsStateProps,
        RouteComponentProps {
    tabs: IHeaderTab[];
    colorTheme?: TabColorTheme;
}

export interface IHeaderTab {
    target: string;
    label: JSX.Element | string;
}

export enum TabColorTheme {
    LIGHT = 'light',
    DARK = 'dark',
}

export type IHeaderTabsActionTypes = any;
