import React, { useContext, useMemo } from 'react';
import { useState } from 'react';
import { IssuePaneContextValue, IssuePaneProviderProps } from './types';

export const IssuePaneContext = React.createContext<IssuePaneContextValue>({
    isIssuePaneOpen: false,
    openIssuePane: () => {},
    closeIssuePane: () => {},
});

export const useIssuePaneContext = () => {
    const context = useContext(IssuePaneContext);
    return context;
};

export const IssuePaneProvider = ({ children }: IssuePaneProviderProps) => {
    const [isIssuePaneOpen, setIsIssuePaneOpen] = useState(false);

    const value = useMemo<IssuePaneContextValue>(
        () => ({
            isIssuePaneOpen,
            openIssuePane: () => {
                setIsIssuePaneOpen(true);
            },
            closeIssuePane: () => {
                setIsIssuePaneOpen(false);
            },
        }),
        [isIssuePaneOpen]
    );

    return (
        <IssuePaneContext.Provider value={value}>
            {children}
        </IssuePaneContext.Provider>
    );
};
