import { Avatar, AvatarGroup } from '@thought-river/ui-components';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import ProgressCell from './ProgressCell';
import styles from './Cell.module.scss';
import StatusCell from './StatusCell';
import DurationCell from './DurationCell';

const Cell = (params: GridRenderCellParams) => {
    const { value, formattedValue, field } = params;

    if (field === 'counterparty' && value) {
        return <Avatar label={value} size="small" showLabel />;
    }

    if (field === 'owner' && value) {
        return <Avatar label={value} size="small" showLabel />;
    }

    if (field === 'reviewers' && value.length) {
        if (value.length === 1) {
            return <Avatar label={value[0]} size="small" showLabel />;
        }

        return (
            <AvatarGroup
                labels={value}
                iconOptions={{ size: 'small' }}
                max={2}
            />
        );
    }

    if (field === 'progress') {
        return <ProgressCell {...params} />;
    }

    if (field === 'deal_type') {
        return <div className={styles.ellipsis}>{value}</div>;
    }

    if (field === 'status') {
        return <StatusCell {...params} />;
    }

    if (field === 'duration') {
        return <DurationCell {...params} />;
    }

    return formattedValue || value || '-';
};

export default Cell;
