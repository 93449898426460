import { useAppSelector } from '@modules/common/hooks';
import { Close, PushPin } from '@mui/icons-material';
import {
    SectionsTreeRootNodeJSON,
    useGetContractVersionSections,
} from '@thought-river/negotiations-common/dist/api/contractContent';
import { IconButton } from '@thought-river/ui-components';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { Resizable } from 're-resizable';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FEATURE_TOGGLE_RELEASE_SECTION_OUTLINE_LEFT_PANEL } from '../../../../../components/FeatureToggleProvider/FeatureToggleProvider-types';
import SectionOutline from '../../SectionOutline';
import SectionBars from './SectionBars';
import { DocumentContentsProps } from './types';

import styles from './DocumentContents.module.scss';
import { calculateOffsetsBetweenElements } from '../helpers';
import { getParagraphIndexesFromSectionsTree } from '../../SectionOutline/SectionOutline.helpers';

// Element used to imitate Document Viewer padding when section outline flag is off
const PaddingPlaceholder = () => (
    <div
        style={{
            width: '40px',
            flexShrink: 0,
        }}
    />
);

const DocumentContents = ({
    clausesRef,
    documentWrapperRef,
    onSectionClick,
}: DocumentContentsProps) => {
    const { versionId } = useParams<{ versionId: string }>();

    const featureToggles = useAppSelector(
        (state) => state.featureToggles.toggles
    );

    const [isHovered, setIsHovered] = useState(false);
    const [isPinned, setIsPinned] = useState(false);
    const [currentParagraphIndex, setCurrentParagraphIndex] = useState<
        number | null
    >(null);

    const { data } = useGetContractVersionSections<SectionsTreeRootNodeJSON>(
        {
            pathParams: {
                contractVersionUuid: versionId,
            },
            queryParams: {
                output_format: 'json',
            },
        },
        {}
    );
    const topLevelSections = data?.children ?? [];
    const sectionsParagraphIndexes =
        getParagraphIndexesFromSectionsTree(topLevelSections);

    useEffect(() => {
        const documentWrapper = documentWrapperRef.current;
        if (!documentWrapper) {
            return;
        }

        const handleScroll = () => {
            if (!clausesRef.current) {
                return;
            }

            let maxScroll = Number.NEGATIVE_INFINITY;
            let maxIndex = null;

            clausesRef.current.forEach((clauseEl) => {
                if (!clauseEl) {
                    return;
                }

                const paragraphIndex = Number(
                    clauseEl.getAttribute('data-ppl-index')
                );
                if (
                    !sectionsParagraphIndexes.includes(String(paragraphIndex))
                ) {
                    return;
                }

                const { top } = calculateOffsetsBetweenElements(
                    documentWrapper,
                    clauseEl
                );

                const scrollFromTopEdge = top - documentWrapper.scrollTop;
                if (scrollFromTopEdge <= 145 && scrollFromTopEdge > maxScroll) {
                    maxScroll = scrollFromTopEdge;
                    maxIndex = paragraphIndex;
                }
            });

            setCurrentParagraphIndex(maxIndex);
        };

        documentWrapper.addEventListener('scroll', handleScroll);
        handleScroll();

        return () =>
            documentWrapper.removeEventListener('scroll', handleScroll);
    }, [clausesRef, documentWrapperRef, sectionsParagraphIndexes]);

    const handleToggleClick = () => {
        if (isPinned) {
            setIsPinned(false);
            setIsHovered(false);
        } else {
            setIsPinned(true);
        }
    };

    const isExpanded = isHovered || isPinned;

    const isFlagEnabled = !!featureToggles.find(
        (toggle) =>
            toggle.feature === FEATURE_TOGGLE_RELEASE_SECTION_OUTLINE_LEFT_PANEL
    )?.enabled;
    if (!isFlagEnabled) {
        return <PaddingPlaceholder />;
    }

    if (!topLevelSections.length) {
        return <PaddingPlaceholder />;
    }

    return (
        <div className={styles.wrapper}>
            <SectionBars
                topLevelSections={topLevelSections}
                onHover={() => setIsHovered(true)}
                currentParagraphIndex={currentParagraphIndex}
            />

            <AnimatePresence>
                {isExpanded && (
                    <motion.div
                        initial={{ opacity: 0, x: -10 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -10 }}
                        className={classNames(styles.panelWrapper, {
                            [styles.pinned]: isPinned,
                        })}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        <Resizable
                            enable={{
                                top: false,
                                right: isPinned,
                                bottom: false,
                                left: false,
                                topRight: false,
                                bottomRight: false,
                                bottomLeft: false,
                                topLeft: false,
                            }}
                            minWidth={170}
                            maxWidth={300}
                            defaultSize={{ width: 170, height: '100%' }}
                        >
                            <div className={styles.panel}>
                                <IconButton
                                    className={styles.toggle}
                                    onClick={handleToggleClick}
                                >
                                    {isPinned ? <Close /> : <PushPin />}
                                </IconButton>

                                <div className={styles.outline}>
                                    <SectionOutline
                                        topLevelSections={topLevelSections}
                                        onSectionClick={onSectionClick}
                                        currentParagraphIndex={
                                            currentParagraphIndex
                                        }
                                    />
                                </div>
                            </div>
                        </Resizable>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default DocumentContents;
