import { IRootState } from '../../../../../redux/reducers-types';
import { reduxStore } from '../../../../../redux/store';
import { analyticsService } from '../../../../../services/Analytics/Analytics';
import {
    EVENT_ACTION_DESCRIPTION_COPIED,
    EVENT_ACTION_EXTERNAL_NOTES_COPIED,
} from '../../../../../services/Analytics/Analytics-types';
import { themesFromIssueParagraphs } from '../../../../App/App-helpers';

export function analytics() {
    return (target: any) => {
        const onCopyDescription = target.prototype.onCopyDescription;

        target.prototype.onCopyDescription = function () {
            const { contract } = reduxStore.store.getState() as IRootState;

            analyticsService.recordEvent(
                EVENT_ACTION_DESCRIPTION_COPIED,

                {
                    ContractId: contract.contract?.id,
                    Stream: contract.contract?.streamCode,
                    VersionId: contract.selectedVersion?.id,
                    VersionNumber: contract.selectedVersion?.versionNumber,
                    IssueId: contract.selectedIssue?.id,
                    IssueCreationType: contract.selectedIssue?.creationType,
                    LegalThemes: contract.selectedIssue
                        ? themesFromIssueParagraphs(
                              contract.selectedIssue,
                              contract.paragraphs
                          )
                        : [],
                }
            );

            onCopyDescription.apply(this, arguments);
        };

        const onCopyNotes = target.prototype.onCopyNotes;

        target.prototype.onCopyNotes = function () {
            const { contract } = reduxStore.store.getState() as IRootState;

            analyticsService.recordEvent(
                EVENT_ACTION_EXTERNAL_NOTES_COPIED,

                {
                    ContractId: contract.contract?.id,
                    Stream: contract.contract?.streamCode,
                    VersionId: contract.selectedVersion?.id,
                    VersionNumber: contract.selectedVersion?.versionNumber,
                    IssueId: contract.selectedIssue?.id,
                    IssueCreationType: contract.selectedIssue?.creationType,
                    LegalThemes: contract.selectedIssue
                        ? themesFromIssueParagraphs(
                              contract.selectedIssue,
                              contract.paragraphs
                          )
                        : [],
                }
            );

            onCopyNotes.apply(this, arguments);
        };
    };
}
