import { analyticsService } from '../../../../services/Analytics/Analytics';
import {
    EVENT_ACTION_ANALYTICS_REPORT_EXPORT_TO_CSV_NEW,
    EVENT_ACTION_ANALYTICS_REPORT_EXPORT_TO_EXCEL,
    EVENT_ACTION_ANALYTICS_REPORT_PAGE_NAVIGATION_NEW,
    EVENT_ACTION_ANALYTICS_REPORT_PREVIEW_LINKED_CLAUSES_NEW,
    EVENT_ACTION_REPORTS_APPLY_CHANGES,
    EVENT_ACTION_REPORTS_CANCEL_EDIT_REPORT,
    EVENT_ACTION_REPORTS_EDIT_REPORT,
    EVENT_ACTION_VIEW_EXISTING_ANALYTICS_REPORT_NEW,
} from '../../../../services/Analytics/Analytics-types';
import { useLocation, useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { ReportDefinitionIn } from '@thought-river/negotiations-common/dist/api/playbookManager';

export const useReportsAnalytics = () => {
    const location = useLocation<{ viewReportTriggerLocation?: string }>();
    const params = useParams<{ id: string }>();

    const trackViewReport = useCallback(() => {
        analyticsService.recordEvent(
            EVENT_ACTION_VIEW_EXISTING_ANALYTICS_REPORT_NEW,

            {
                TriggerLocation:
                    location.state?.viewReportTriggerLocation ?? 'Link',
                AnalyticsReportId: params.id,
            }
        );
    }, [location.state?.viewReportTriggerLocation, params]);

    const trackPreviewLinkedClauses = () => {
        analyticsService.recordEvent(
            EVENT_ACTION_ANALYTICS_REPORT_PREVIEW_LINKED_CLAUSES_NEW,

            {
                AnalyticsReportId: params.id,
            }
        );
    };

    const trackReportExport = (exportOption: string | undefined) => {
        let eventLabel;
        switch (exportOption) {
            case 'csv':
                eventLabel = EVENT_ACTION_ANALYTICS_REPORT_EXPORT_TO_CSV_NEW;
                break;
            case 'excel':
                eventLabel = EVENT_ACTION_ANALYTICS_REPORT_EXPORT_TO_EXCEL;
                break;
        }
        if (!eventLabel) {
            return;
        }

        analyticsService.recordEvent(eventLabel, {
            AnalyticsReportId: params.id,
        });
    };

    const trackPageNavigation = () => {
        analyticsService.recordEvent(
            EVENT_ACTION_ANALYTICS_REPORT_PAGE_NAVIGATION_NEW,

            {
                AnalyticsReportId: params.id,
            }
        );
    };

    const trackApplyReportChanges = (
        reportDefinition: Partial<ReportDefinitionIn>
    ) => {
        analyticsService.recordEvent(
            EVENT_ACTION_REPORTS_APPLY_CHANGES,

            {
                AnalyticsReportId: params.id,
                ColumnFields: reportDefinition.columns?.concat(
                    reportDefinition.properties ?? []
                ),
                FilterFields: reportDefinition.filters?.items.map(
                    (i) => i.field
                ),
            }
        );
    };

    const trackCancelReportChanges = () => {
        analyticsService.recordEvent(
            EVENT_ACTION_REPORTS_CANCEL_EDIT_REPORT,

            {
                AnalyticsReportId: params.id,
            }
        );
    };

    const trackEditReportButton = () => {
        analyticsService.recordEvent(
            EVENT_ACTION_REPORTS_EDIT_REPORT,

            {
                AnalyticsReportId: params.id,
            }
        );
    };

    return {
        trackViewReport,
        trackPreviewLinkedClauses,
        trackReportExport,
        trackPageNavigation,
        trackApplyReportChanges,
        trackCancelReportChanges,
        trackEditReportButton,
    };
};
