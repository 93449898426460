import { InputType, ValueType } from '../../App/App-types';
import {
    IContractFeedback,
    IContractProperty,
    IContractVersion,
    IDisplayContract,
} from '../../Contract/Contract-types';
import { IFeatureToggle } from '../../FeatureToggleProvider/FeatureToggleProvider-types';
import {
    IIssueFiltersValues,
    PresetIssueFilters,
} from '../../IssueListFilters/IssueListFilters-types';
import { IIssueCategory } from '../../IssueListPane/IssueListPane-types';
import { IContractParagraph } from '../../Paragraph/Paragraph-types';
import { IColumnsOrderData, IIssuesTableColumn } from '../IssuesReport-types';
import { SelectOption } from '@thought-river/ui-components';
import { GridSortModel } from '@mui/x-data-grid';
import {
    ContractIssue,
    FamiliarityLevel,
    IssueSeverityLevel,
    IssueStatus,
    ParagraphClause,
} from '@thought-river/negotiations-common';

export interface IContractReportRow {
    rowId: number;
    feedback: IContractFeedback[]; // User Feedback
    severityLevel: IssueSeverityLevel | null; // Importance
    categories: IIssueCategory[]; // Internal Notes
    description: string; // Internal Notes
    familiarity: FamiliarityLevel;
    issueId: string | null;
    notes: string; // External Comments
    title: string; // Summary
    issueUpdatedAt: string; // Last Updated
    linkedParagraphClauses: ParagraphClause[]; // Linked Clauses
    missingSuggestionLocations: ParagraphClause[];
    nonAssessedThemeIds: string[];
    lexiblePropertyId: string | null;
    paragraphOriginUuids: string[];
    propertyValue: string; // *new* Lexible Answer
    propertyCode: string; // *new* Property Code
    propertyId: string | null;
    propertyLabel: string; // *new* Property Label
    propertyName: string; // *new* Property Label
    propertyQuestion: string; // *new* Lexible Question
    propertyOptions: SelectOption[];
    propertyInputType: InputType;
    propertyValueType: ValueType;
    propertyTheme?: { id: string; name: string };
    selectedSuggestion: string | null;
    status: IssueStatus | null; // Status
    clausesEditable: boolean;
}

export enum SortOrder {
    ASC = 'asc',
    DESC = 'desc',
}

export enum IssuesTableHeader {
    STATUS = 'status',
    IMPORTANCE = 'importance',
    FAMILIARITY = 'familiarity',
    LINKED_CLAUSES = 'linked_clauses',
    LINKED_CLAUSES_TEXT = 'linked_clauses_text',
    SUGGESTIONS = 'drafting_options',
    SUMMARY = 'summary',
    DESCRIPTION = 'description',
    LAST_UPDATED = 'last_updated',
    USER_FEEDBACK = 'user_feedback',
    NOTES = 'external_notes',
    PROPERTY_LABEL = 'property_label',
    PROPERTY_NAME = 'property_name',
    PROPERTY_CODE = 'property_code',
    PROPERTY_QUESTION = 'property_question',
    PROPERTY_ANSWER = 'property_answer',
    PROPERTY_THEME = 'property_theme',
}

export interface IHybridReportDataGridState {
    expandedClausesIssueIds: string[];
}

export interface IHybridReportDataGridProps {
    activeFilters: IIssueFiltersValues;
    columns: IIssuesTableColumn[];
    columnsOrderData: IColumnsOrderData;
    contract: IDisplayContract;
    featureToggles: IFeatureToggle[];
    issues: ContractIssue[];
    loading: boolean;
    rows: IContractReportRow[];
    onSendFeedback: (
        row: IContractReportRow,
        feedback: 1 | -1
    ) => Promise<void>;
    onSaveIssue: (issue: ContractIssue) => Promise<void>;
    onSaveProperty: (
        prevRow: IContractReportRow,
        row: IContractReportRow,
        entityPropertyValue: string,
        entityPropertyAnswer: string
    ) => void;
    onSelectClause: (
        paragraphUuids: string[],
        selectedClause: ParagraphClause,
        title: string
    ) => void;
    onSelectSuggestion: (rowId: number) => void;
    onSortChange: (sortModel: GridSortModel) => void;
    onResizeColumn: (field: IssuesTableHeader, width: number) => void;
    onReorderColumns: (columnsOrderData: IColumnsOrderData) => void;
    paragraphs: IContractParagraph[];
    properties: IContractProperty[];
    summaryPropertyCodes: string[];
    selectedView: PresetIssueFilters;
    lastValidView: PresetIssueFilters;
    sortModel: GridSortModel;
    userId: string;
    version: IContractVersion;
}

export interface IHeaderCell {
    defaultSort: SortOrder;
    id: IssuesTableHeader;
    label: string;
}

export type IHybridReportDataGridActionTypes = any;
