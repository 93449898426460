import { IRootState } from '../../redux/reducers-types';
import { reduxStore } from '../../redux/store';
import { analyticsService } from '../../services/Analytics/Analytics';
import { EVENT_ACTION_VIEW_CONTRACT } from '../../services/Analytics/Analytics-types';

export function analytics() {
    return (target: any) => {
        const componentDidMount = target.prototype.componentDidMount;

        target.prototype.componentDidMount = function () {
            const { contract: contractState } =
                reduxStore.store.getState() as IRootState;
            const { contract, selectedVersion } = contractState || {};

            analyticsService.recordEvent(
                EVENT_ACTION_VIEW_CONTRACT,

                {
                    ContractId: contract?.id,
                    Stream: contract?.streamCode,
                    VersionId: selectedVersion?.id,
                    VersionNumber: selectedVersion?.versionNumber,
                }
            );

            componentDidMount.apply(this, arguments);
        };
    };
}
