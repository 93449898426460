import { Button, Icon, Dialog } from '@thought-river/ui-components';
import { IAccountUser } from '../../../../../components/Auth/Auth-types';
import styles from './ConfirmSetOwnerDialog.module.scss';

interface ConfirmSetOwnerDialogProps {
    onClose: (shouldSetOwner: boolean) => void;
    newOwner: IAccountUser;
}

const ConfirmSetOwnerDialog = ({
    onClose,
    newOwner: { firstName, lastName },
}: ConfirmSetOwnerDialogProps) => (
    <Dialog
        open
        dialogTitle={
            <div className={styles.title}>
                <Icon type="warning" className={styles.icon} />
                Warning!
            </div>
        }
        dialogContent={`Setting ${firstName} ${lastName} as an owner will remove this status from you and can't be undone. Do you want to proceed?`}
        dialogActions={
            <>
                <Button variant="secondary" onClick={() => onClose(false)}>
                    Cancel
                </Button>
                <Button onClick={() => onClose(true)}>Set as Owner</Button>
            </>
        }
        onClose={() => onClose(false)}
    />
);

export default ConfirmSetOwnerDialog;
