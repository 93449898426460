import styles from './HistoryPanel.module.scss';
import { Restore } from '@mui/icons-material';
import VersionsList from './VersionsList';
import { useAppSelector } from '@modules/common/hooks';
import { Drawer } from '@thought-river/ui-components';

export interface HistoryPanelProps {
    open: boolean;
    onClose: () => void;
    isReportTab: boolean;
}

const HistoryPanel = ({ open, onClose, isReportTab }: HistoryPanelProps) => {
    const versions = useAppSelector((state) => state.contract.versions);
    const orderedVersions = [...versions].reverse();

    return (
        <Drawer
            width={500}
            open={open}
            onClose={onClose}
            noPadding
            title={
                <div className={styles.title}>
                    <Restore fontSize="small" />
                    <div>History</div>
                </div>
            }
            variant="temporary"
        >
            <VersionsList
                versions={orderedVersions}
                onClose={onClose}
                isReportTab={isReportTab}
            />
        </Drawer>
    );
};

export default HistoryPanel;
