import { useSelector } from 'react-redux';
import { FEATURE_TOGGLE_REPORTS_BETA_2 } from '../../../../components/FeatureToggleProvider/FeatureToggleProvider-types';
import { IRootState } from '../../../../redux/reducers-types';

export const useBetaReports2Enabled = () => {
    const featureToggles = useSelector(
        (state: IRootState) => state.featureToggles.toggles
    );
    return !!featureToggles.find(
        (toggle) => toggle.feature === FEATURE_TOGGLE_REPORTS_BETA_2
    )?.enabled;
};
