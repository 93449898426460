import { forwardRef } from 'react';
import styles from './ContractLink.module.scss';
import { ContractLinkProps } from './ContractLink.types';
import { Link } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import { getContractPath } from '@modules/common/helpers';
import classNames from 'classnames';

const ContractLink = forwardRef<HTMLAnchorElement, ContractLinkProps>(
    (props, ref) => {
        const {
            linkText,
            contractId = '',
            dealType = '',
            versionId = '',
            disabled,
            customHref,
            ...rest
        } = props;

        const contractLink = customHref
            ? customHref
            : getContractPath(dealType, contractId, versionId);

        return (
            <Link
                target="_blank"
                rel="noopener noreferrer"
                to={contractLink}
                className={classNames(
                    styles.wrapper,
                    disabled && styles.disabled
                )}
                ref={ref}
                component={MuiLink}
                {...rest}
            >
                {linkText}
            </Link>
        );
    }
);

export default ContractLink;
