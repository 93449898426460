import * as React from 'react';

import {
    ContractIntelligenceType,
    HighlightIndexType,
} from '../Contract/Contract-types';
import {
    IContractParagraph,
    IParagraphProps,
    IParagraphState,
} from './Paragraph-types';
import {
    getFilteredIssuesForParagraph,
    tagTerm,
    getHighestSeverityFromIssues,
} from './Paragraph-helpers';
import ParagraphWidget from '../ParagraphWidget/ParagraphWidget-container';
import PropertyLinkWidget from '../PropertyLinkWidget/PropertyLinkWidget-container';
import { BannerClauseType } from '../HeatmapPane/HeatmapPane-types';
import { ContractSidebarTab } from '../ContractSidebar/ContractSidebar-types';
import { ErrorBoundary } from '@modules/common/components/ErrorBoundary';
import { FamiliarityLevel } from '@thought-river/negotiations-common';

class Paragraph extends React.Component<IParagraphProps, IParagraphState> {
    private paragraphRef: React.RefObject<any>;

    constructor(props: IParagraphProps) {
        super(props);

        this.paragraphRef = React.createRef();
    }

    componentDidMount() {
        const { addParagraphHeightInPixels, paragraph } = this.props;
        const height = this.paragraphRef.current.clientHeight;

        addParagraphHeightInPixels(
            paragraph.table ? height / paragraph.table.cellsInRow : height
        );
    }

    async onSelectParagraph(paragraph: IContractParagraph, selected: boolean) {
        const {
            setHighlightIndex,
            setSelectedParagraph,
            selectedPropertyCode,
            selectedContractIntelligence,
        } = this.props;

        if (
            selectedPropertyCode ||
            selectedContractIntelligence.highlightType
        ) {
            return;
        }

        if (selected) {
            setSelectedParagraph(null);
            setHighlightIndex(-1, HighlightIndexType.START);
            setHighlightIndex(-1, HighlightIndexType.END);
        } else {
            setSelectedParagraph(paragraph);
        }
    }

    renderStyledParagraph(
        html: string,
        classes: string,
        searchTerm: string,
        highlightedText: string = ''
    ): JSX.Element {
        if (searchTerm) {
            let updatedHtml = '';
            const htmlSplitByTags = html.split(/(<[^>]*>)/gm);

            for (const html of htmlSplitByTags) {
                updatedHtml += html.startsWith('<')
                    ? html
                    : tagTerm(html, searchTerm);
            }

            html = updatedHtml;
        }

        if (highlightedText && html.includes(highlightedText)) {
            html = tagTerm(html, highlightedText, 'highlighted-text');
        }

        return (
            <div
                className={`paragraph-content ${classes}`}
                dangerouslySetInnerHTML={{ __html: html }}
            />
        );
    }

    getHighlightType() {
        const {
            activeIssueFilters,
            paragraph,
            selectedContractIntelligence,
            contract,
        } = this.props;

        const { filtered: activeFilteredIssues } =
            getFilteredIssuesForParagraph(activeIssueFilters, paragraph);
        const filteredSeverity =
            getHighestSeverityFromIssues(activeFilteredIssues);
        const unfilteredSeverity = getHighestSeverityFromIssues(
            paragraph.issues
        );

        if (
            selectedContractIntelligence.type === ContractIntelligenceType.RISK
        ) {
            if (selectedContractIntelligence.highlightType) {
                return unfilteredSeverity;
            } else if (!selectedContractIntelligence.highlightType) {
                return filteredSeverity;
            }
        } else if (
            selectedContractIntelligence.type ===
            ContractIntelligenceType.FAMILIARITY
        ) {
            // If contract is a template, override its familiarity to Standard
            return contract.isTemplate
                ? FamiliarityLevel.TEMPLATE
                : paragraph.organisationFamiliarity.frequencyLevel;
        }
    }

    getParagraphClassName(isHighlighted: boolean) {
        const {
            bannerSettings,
            paragraph,
            selectedIssue,
            selectedContractIntelligence,
            selectedPropertyCode,
            summaryProperties,
            selectedParagraph,
        } = this.props;

        const selectedProperty = summaryProperties.find(
            (p) => p.code === selectedPropertyCode
        );

        const classes = [];

        if (isHighlighted) {
            classes.push('highlighted');
        }

        const paragraphLinkedToSelectedIssue = selectedIssue?.clauseNumbers
            .map((clause) => clause.originUuid)
            .includes(paragraph.originUuid);
        const paragraphRelatedToSelectedProperty =
            selectedProperty?.relatedParagraphIds.includes(paragraph.id);
        const paragraphLinkedToSelectedProperty =
            selectedProperty?.linkedParagraphIds.includes(paragraph.id);

        const highlightType = this.getHighlightType();

        classes.push(highlightType);

        if (
            (selectedIssue && !paragraphLinkedToSelectedIssue) ||
            (!selectedIssue &&
                !selectedProperty &&
                !selectedContractIntelligence.highlightType &&
                selectedParagraph &&
                paragraph.id !== selectedParagraph.id) ||
            (selectedProperty &&
                bannerSettings?.clauseType ===
                    BannerClauseType.PROPERTY_RELATED &&
                !paragraphRelatedToSelectedProperty) ||
            (selectedProperty &&
                bannerSettings?.clauseType ===
                    BannerClauseType.PROPERTY_LINKED &&
                !paragraphLinkedToSelectedProperty) ||
            (selectedContractIntelligence.highlightType &&
                selectedContractIntelligence.highlightType !== highlightType)
        ) {
            classes.push('half-opacity');
        }

        return classes.join(' ');
    }

    render() {
        const {
            activeTab,
            highlightedText,
            highlightedParagraphs,
            paragraph,
            searchTerm,
            selectedParagraph,
            onMouseDown,
            onMouseUp,
            selectedVersion,
        } = this.props;

        const highlightedParagraphIds = highlightedParagraphs.map(
            (paragraph) => paragraph.id
        );
        const isHighlighted = highlightedParagraphIds.includes(paragraph.id);
        const isFirstHighlighted = highlightedParagraphIds[0] === paragraph.id;
        const selectedParagraphId = selectedParagraph
            ? selectedParagraph.id
            : null;
        const selected = paragraph.id === selectedParagraphId;
        const versionIsLocked = selectedVersion && !selectedVersion.isLatest;

        return (
            <div
                className={`paragraph-wrapper ${this.getParagraphClassName(
                    isHighlighted
                )}`}
                data-paragraph={paragraph.id}
                ref={this.paragraphRef}
                onClick={() => {
                    this.onSelectParagraph(paragraph, selected);
                }}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
            >
                <div className="marker" />
                {this.renderStyledParagraph(
                    paragraph.html,
                    paragraph.classes,
                    searchTerm,
                    highlightedText
                )}
                {isFirstHighlighted &&
                    !versionIsLocked &&
                    activeTab === ContractSidebarTab.ISSUES && (
                        <ParagraphWidget />
                    )}
                {!versionIsLocked &&
                    activeTab === ContractSidebarTab.OVERVIEW && (
                        <ErrorBoundary>
                            <PropertyLinkWidget paragraph={paragraph} />
                        </ErrorBoundary>
                    )}
            </div>
        );
    }
}

export default Paragraph;
