import {
    IParty,
    PartiesType,
    PartyVariantType,
    VARIANTS,
    FORMAL_NAMES,
    REFERENCE_NAMES,
} from './Parties-types';
import { IPartyGroups } from 'types/thoughtriver';
import { escapeRegex } from '../App/App-helpers';

export function alphabetizeParties(partyA: IParty, partyB: IParty) {
    return partyA.name
        ? partyA.name.localeCompare(partyB.name, undefined, {
              numeric: true,
              sensitivity: 'base',
          })
        : 0;
}

export function mapPartiesToOverrides(partyGroups: IPartyGroups): IParty[] {
    const parties: IParty[] = [];
    let index = 0;
    let variant;
    let variantGroup;

    for (const type in partyGroups) {
        if (type === PartiesType.DISCARDED) {
            for (const party in partyGroups[type]) {
                parties.push({
                    id: index,
                    name: partyGroups[type][party],
                    type,
                    originalType: type,
                    variantType: PartyVariantType.REFERENCE,
                });

                index++;
            }
        }

        if (type === PartiesType.RECIPROCAL) {
            for (variantGroup in partyGroups[type]) {
                for (variant in partyGroups[type][variantGroup][VARIANTS]) {
                    parties.push({
                        id: index,
                        name: partyGroups[type][variantGroup][VARIANTS][
                            variant
                        ]['name'],
                        type,
                        originalType: type,
                        variantType: PartyVariantType.REFERENCE,
                    });

                    index++;
                }
            }
        }

        if (
            type === PartiesType.OWN_PARTIES ||
            type === PartiesType.COUNTER_PARTIES
        ) {
            for (const group of partyGroups[type]) {
                for (variantGroup in group[FORMAL_NAMES]) {
                    for (variant in group[FORMAL_NAMES][variantGroup][
                        VARIANTS
                    ]) {
                        parties.push({
                            id: index,
                            name: group[FORMAL_NAMES][variantGroup][VARIANTS][
                                variant
                            ]['name'],
                            type,
                            originalType: type,
                            variantType: PartyVariantType.FORMAL,
                        });

                        index++;
                    }
                }

                for (variantGroup in group[REFERENCE_NAMES]) {
                    for (variant in group[REFERENCE_NAMES][variantGroup][
                        VARIANTS
                    ]) {
                        parties.push({
                            id: index,
                            name: group[REFERENCE_NAMES][variantGroup][
                                VARIANTS
                            ][variant]['name'],
                            type,
                            originalType: type,
                            variantType: PartyVariantType.REFERENCE,
                        });

                        index++;
                    }
                }
            }
        }
    }

    return parties.sort(alphabetizeParties);
}

export function sortParties(parties: IParty[]): IParty[] {
    const partiesCopy = [...parties];

    partiesCopy.sort(
        (partyA, partyB) => partyB.name.length - partyA.name.length
    );

    return partiesCopy;
}

export function reIndex(party: IParty, index: number) {
    party.id = index;
    return party;
}

export function getMatchPattern(terms: string[]): string {
    return terms.map((term) => escapeRegex(term)).join('|');
}
