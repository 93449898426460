export interface IDualListEditorState {
    items: IDualItem[];
    draggedItem: any;
}

export interface IDualItem {
    label: string;
    active: boolean;
    data?: any;
}

export enum DualListType {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export interface IDualListEditorStateProps {}

export interface IDualListEditorReducer {}

export interface IDualListEditorDispatchProps {}

export interface IDualListEditorProps
    extends IDualListEditorDispatchProps,
        IDualListEditorStateProps {
    firstListTitle: string;
    secondListTitle: string;
    items: IDualItem[];
    loading?: boolean;
    onChange(items: IDualItem[]): void;
    itemRenderer?(item: IDualItem): React.ReactNode;
    skeletonItems?: number;
}

export type IDualListEditorActionTypes = any;
