import { IProperties } from 'types/thoughtriver';
import { mockProperties } from '../mocks/properties';
import Api from '../services/api';
import { Method, requestOptions } from '../services/api-types';
import {
    IFeedbackOptions,
    IPropertyResource,
    IPropertyResourceStatic,
} from './PropertyResource-types';

const PropertyResource: IPropertyResourceStatic = class PropertyResource
    implements IPropertyResource
{
    static async getProperties(
        contractId: string,
        versionId: string,
        assessmentId: string,
        stream: string
    ): Promise<IProperties> {
        const url = `/contracts/${contractId}/versions/${versionId}/assessments/${assessmentId}/properties`;

        const options = {
            ...requestOptions,
            streamCode: stream,
        };

        return await (await Api.request(Method.GET, url, options))!.json();
    }

    static async getAllProperties(): Promise<IProperties> {
        return new Promise((resolve) => resolve(mockProperties));
    }

    static async sendFeedback(feedbackOptions: IFeedbackOptions): Promise<any> {
        const {
            contractId,
            referenceComponent,
            versionId,
            assessmentId,
            propertyId,
            stream,
            feedback,
        } = feedbackOptions;
        const url = `/contracts/${contractId}/versions/${versionId}/assessments/${assessmentId}/properties/${propertyId}/feedbacks`;

        const options = {
            ...requestOptions,
            streamCode: stream,
            payload: {
                data: {
                    attributes: {
                        vote: feedback,
                    },
                },
            },
            noRedirect: true,
            headers: {
                ...requestOptions.headers,
                'x-reference-component': referenceComponent,
            },
        };

        return Api.request(Method.POST, url, options);
    }
};

export default PropertyResource;
