import dayjs from 'dayjs';
import {
    IIssueFiltersValues,
    PresetIssueFilters,
} from '../../IssueListFilters/IssueListFilters-types';
import {
    IssueDisplay,
    statusSortOrder,
} from '../../IssueListPane/IssueListPane-types';
import { IContractReportRow } from './HybridReportDataGrid-types';
import {
    GridRenderEditCellParams,
    useGridApiContext,
} from '@mui/x-data-grid-pro';
import {
    IssueSeverityLevel,
    capitalize,
    familiarityLevelSortOrder,
    issueSeveritySortOrder,
    ParagraphClause,
} from '@thought-river/negotiations-common';

export function formatClausesForSorting(
    clauseNumbers: ParagraphClause[]
): string {
    return clauseNumbers.map((clauseNumber) => clauseNumber.index).join(',');
}

export function sortByClauseAsc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    const rowAFirstClause: string | undefined =
        rowA.linkedParagraphClauses[0]?.clauseNumber;
    const rowBFirstClause: string | undefined =
        rowB.linkedParagraphClauses[0]?.clauseNumber;

    if (!rowAFirstClause) {
        return -1;
    }

    if (!rowBFirstClause) {
        return 1;
    }

    return Number(rowAFirstClause) - Number(rowBFirstClause);
}

export function sortByClauseDesc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    const rowAFirstClause: string | undefined =
        rowA.linkedParagraphClauses[0]?.clauseNumber;
    const rowBFirstClause: string | undefined =
        rowB.linkedParagraphClauses[0]?.clauseNumber;

    if (!rowAFirstClause && !rowBFirstClause) {
        return 0;
    }

    if (!rowAFirstClause) {
        return 1;
    }

    if (!rowBFirstClause) {
        return -1;
    }

    return Number(rowBFirstClause) - Number(rowAFirstClause);
}

export function sortBySuggestion(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    const suggestionA = rowA.selectedSuggestion ?? '';
    const suggestionB = rowB.selectedSuggestion ?? '';

    return suggestionA.localeCompare(suggestionB, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
}

export function sortByDescriptionAsc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    return rowA.description.localeCompare(rowB.description, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
}

export function sortByDescriptionDesc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    return rowB.description.localeCompare(rowA.description, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
}

export function sortByFamiliarityDesc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    const rowAFamiliarityValue =
        familiarityLevelSortOrder[rowA.familiarity] ?? -1;
    const rowBFamiliarityValue =
        familiarityLevelSortOrder[rowB.familiarity] ?? -1;

    return rowBFamiliarityValue - rowAFamiliarityValue;
}

export function sortByFamiliarityAsc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    const rowAFamiliarityValue =
        familiarityLevelSortOrder[rowA.familiarity] ?? -1;
    const rowBFamiliarityValue =
        familiarityLevelSortOrder[rowB.familiarity] ?? -1;

    return rowAFamiliarityValue - rowBFamiliarityValue;
}

export function sortByNotesAsc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    return rowA.notes.localeCompare(rowB.notes, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
}

export function sortByNotesDesc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    return rowB.notes.localeCompare(rowA.notes, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
}

export function sortByLastUpdatedDateDesc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    if (dayjs(rowB.issueUpdatedAt).isAfter(rowA.issueUpdatedAt)) {
        return -1;
    }

    if (dayjs(rowB.issueUpdatedAt).isBefore(rowA.issueUpdatedAt)) {
        return 1;
    }

    return 0;
}

export function sortByLastUpdatedDateAsc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    if (dayjs(rowA.issueUpdatedAt).isBefore(rowB.issueUpdatedAt)) {
        return -1;
    }

    if (dayjs(rowA.issueUpdatedAt).isAfter(rowB.issueUpdatedAt)) {
        return 1;
    }

    return 0;
}

export function sortBySeverityAsc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    const rowASeverityValue = issueSeveritySortOrder[rowA.severityLevel!]; // Fixme: null checks
    const rowBSeverityValue = issueSeveritySortOrder[rowB.severityLevel!]; // Fixme: null checks

    return rowBSeverityValue - rowASeverityValue;
}

export function sortBySeverityDesc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    const rowASeverityValue = issueSeveritySortOrder[rowA.severityLevel!]; // Fixme: null checks
    const rowBSeverityValue = issueSeveritySortOrder[rowB.severityLevel!]; // Fixme: null checks

    return rowASeverityValue - rowBSeverityValue;
}

export function sortByStatusAsc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    const rowAStatusValue = statusSortOrder[rowA.status!]; // Fixme: null checks
    const rowBStatusValue = statusSortOrder[rowB.status!]; // Fixme: null checks

    return rowBStatusValue - rowAStatusValue;
}

export function sortByStatusDesc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    const rowAStatusValue = statusSortOrder[rowA.status!]; // Fixme: null checks
    const rowBStatusValue = statusSortOrder[rowB.status!]; // Fixme: null checks

    return rowAStatusValue - rowBStatusValue;
}

export function sortBySummaryDesc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    return rowB.title.localeCompare(rowA.title, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
}

export function sortBySummaryAsc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    return rowA.title.localeCompare(rowB.title, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
}

export function sortByPropertyLabelDesc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    return rowB.propertyLabel.localeCompare(rowA.propertyLabel, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
}

export function sortByPropertyLabelAsc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    return rowA.propertyLabel.localeCompare(rowB.propertyLabel, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
}

export function sortByPropertyNameDesc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    return rowB.propertyName.localeCompare(rowA.propertyName, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
}

export function sortByPropertyNameAsc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    return rowA.propertyName.localeCompare(rowB.propertyName, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
}

export function sortByPropertyCodeDesc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    return rowB.propertyCode.localeCompare(rowA.propertyCode, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
}

export function sortByPropertyCodeAsc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    return rowA.propertyCode.localeCompare(rowB.propertyCode, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
}

export function sortByPropertyQuestionDesc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    return rowB.propertyQuestion.localeCompare(
        rowA.propertyQuestion,
        undefined,
        { numeric: true, sensitivity: 'base' }
    );
}

export function sortByPropertyQuestionAsc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    return rowA.propertyQuestion.localeCompare(
        rowB.propertyQuestion,
        undefined,
        { numeric: true, sensitivity: 'base' }
    );
}

export function sortByPropertyAnswerDesc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    return rowB.propertyValue?.localeCompare(rowA.propertyValue, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
}

export function sortByPropertyAnswerAsc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    return rowA.propertyValue?.localeCompare(rowB.propertyValue, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
}

export function sortByPropertyThemeDesc(
    rowA: IContractReportRow,
    rowB: IContractReportRow
): number {
    return (
        rowB.propertyTheme?.name.localeCompare(rowA.propertyTheme?.name!) ?? 0 // Fixme: null checks
    );
}

export function sortByPropertyThemeAsc(
    rowA: IContractReportRow,
    rowB: IContractReportRow,
    selectedFilterPreset?: PresetIssueFilters
): number {
    /**
     * There's a specific customer requirement (RSBG) to push issues with theme
     * "Liability" or "Exclusion of Liability" to the top of the report
     */
    if (
        selectedFilterPreset &&
        [
            PresetIssueFilters.BidReport,
            PresetIssueFilters.NegotiationReport,
            PresetIssueFilters.FinalReportForSignatories,
            PresetIssueFilters.FinalReportForProjectTeams,
        ].includes(selectedFilterPreset)
    ) {
        const liabilityThemes = ['Liability', 'Exclusion of Liability'];
        if (
            liabilityThemes.includes(rowA.propertyTheme?.name ?? '') &&
            !liabilityThemes.includes(rowB.propertyTheme?.name ?? '')
        ) {
            return -1;
        } else if (
            !liabilityThemes.includes(rowA.propertyTheme?.name ?? '') &&
            liabilityThemes.includes(rowB.propertyTheme?.name ?? '')
        ) {
            return 1;
        }
    }

    return (
        rowA.propertyTheme?.name.localeCompare(rowB.propertyTheme?.name!) ?? 0
    );
}

export function filterRow(
    row: IContractReportRow,
    activeIssueFilters: IIssueFiltersValues,
    propertyCodes: string[]
) {
    const {
        severity = [],
        status = [],
        themes = [],
        categories = [],
        displays = [],
        familiarity = [],
        summaryPropertiesOnly = false,
    } = activeIssueFilters;

    if (
        displays.length &&
        ((!displays.includes(IssueDisplay.PRESENT) &&
            row.paragraphOriginUuids.length) ||
            (!displays.includes(IssueDisplay.NOT_DETECTED) &&
                !row.paragraphOriginUuids.length))
    ) {
        return false;
    }

    // Fixme: null checks
    if (severity.length && !severity.includes(row.severityLevel!)) {
        return false;
    }

    // Fixme: null checks
    if (status.length && !status.includes(row.status!)) {
        return false;
    }

    if (familiarity.length && !familiarity.includes(row.familiarity)) {
        return false;
    }

    if (
        themes.length &&
        !themes.some((t) => row.propertyTheme?.id.includes(t.id))
    ) {
        return false;
    }

    if (
        categories.length &&
        !categories.some((filteredCategory) =>
            row.categories.find(
                (issueCategory) => issueCategory.id === filteredCategory.id
            )
        )
    ) {
        return false;
    }

    if (summaryPropertiesOnly && !propertyCodes.includes(row.propertyCode)) {
        return false;
    }

    return true;
}

export function resolveSeverityLevelValue(severity: IssueSeverityLevel) {
    return severity === IssueSeverityLevel.OK ? 'OK' : capitalize(severity);
}

export function renderSeverityDropdownLabel(severity: IssueSeverityLevel) {
    return capitalize(
        severity === IssueSeverityLevel.UNSCORED ? 'None' : severity
    );
}

export async function goToNextCell(
    apiRef: ReturnType<typeof useGridApiContext>,
    props: GridRenderEditCellParams,
    save: boolean = true
) {
    const { id, field } = props;

    const currentColumnIndex = apiRef.current.getColumnIndex(field);

    const visibleColumns = apiRef.current.getAllColumns();

    const nextColumn = visibleColumns[currentColumnIndex + 1];

    if (nextColumn) {
        await apiRef.current.setCellFocus(id, nextColumn.field);
        const cellMode = nextColumn.editable ? 'edit' : 'view';
        await apiRef.current.setCellMode(id, nextColumn.field, cellMode);

        if (save) {
            commitCellChanges(apiRef, props);
        }
    }
}

export async function commitCellChanges(
    apiRef: ReturnType<typeof useGridApiContext>,
    props: GridRenderEditCellParams
) {
    const { id, value, field } = props;

    await apiRef.current.setEditCellValue({
        id,
        field,
        value,
    });

    await apiRef.current.commitCellChange({ id, field });
    await apiRef.current.setCellMode(id, field, 'view');
}
