import { connect } from 'react-redux';

import { IRootState } from '../../redux/reducers-types';
import UserIcon from './UserIcon';
import { IUserIconDispatchProps, IUserIconStateProps } from './UserIcon-types';

function mapStateToProps(state: IRootState): IUserIconStateProps {
    return {
        featureToggles: state.featureToggles.toggles,
    };
}

function mapDispatchToProps(): IUserIconDispatchProps {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(UserIcon);
