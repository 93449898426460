import { useSelector } from 'react-redux';
import {
    getLastModifierName,
    getLastUpdatedIssue,
    getSubmitStatus,
} from './SaveStatus-helpers';
import { IRootState } from '../../../redux/reducers-types';
import { IAccountUser } from '../../Auth/Auth-types';
import { SubmitStatus } from '../../App/App-types';
import { SecondaryNavigationSaveStatusProps } from './SaveStatus-types';
import { ContractIssue, SaveStatus } from '@thought-river/negotiations-common';

const SecondaryNavigationSaveStatus = ({
    contractNameSubmitStatus,
}: SecondaryNavigationSaveStatusProps) => {
    const users = useSelector<IRootState, IAccountUser[]>(
        (state) => state.auth.users
    );
    const issuesReportStatus = useSelector<IRootState, SubmitStatus>(
        (state) => state.issuesReport.savedStatus
    );
    const issuePaneStatus = useSelector<IRootState, SubmitStatus>(
        (state) => state.issuePane.submitStatus
    );
    const issues = useSelector<IRootState, ContractIssue[]>(
        (state) => state.contract.issues
    );

    const submitStatus = getSubmitStatus(
        issuesReportStatus,
        issuePaneStatus,
        contractNameSubmitStatus
    );

    const lastUpdatedIssue = getLastUpdatedIssue(issues);

    const lastModifierName = getLastModifierName(lastUpdatedIssue, users);

    return (
        <SaveStatus
            updatedBy={lastModifierName ?? 'Unknown'}
            status={submitStatus}
            updatedAt={lastUpdatedIssue?.updatedAt}
        />
    );
};

export default SecondaryNavigationSaveStatus;
