export const VERSION_STATE_UPLOADING = 'Uploading current document';
export const VERSION_STATE_QUEUED = 'Document queued for conversion';
export const VERSION_STATE_CONVERTED =
    'Document converted and context boundaries resolved';
export const VERSION_STATE_PROCESSING =
    'Document has been queued for processing on Fathom';
export const VERSION_STATE_AUTODETECTS = 'Preparing to process autodetects...';
export const VERSION_STATE_SUCCESS =
    'Contract has been successfully processed.';

export interface IUploadProgressBarState {}

export interface IUploadProgressBarProps {
    state: string;
    percentageProgress?: number;
}

export const uploadStateRatios = {
    [VERSION_STATE_UPLOADING]: 0,
    [VERSION_STATE_QUEUED]: 0.1,
    [VERSION_STATE_CONVERTED]: 0.2,
    [VERSION_STATE_PROCESSING]: 0.8,
    [VERSION_STATE_AUTODETECTS]: 0.9,
    [VERSION_STATE_SUCCESS]: 1,
};
