import { IAccountUser } from '../Auth/Auth-types';
import {
    HighlightIndexType,
    IContractAssesssment,
    IContractPolicy,
    IContractProperty,
    IContractVersion,
    IDisplayContract,
} from '../Contract/Contract-types';
import {
    IIssueCategory,
    IssueStateInVersion,
} from '../IssueListPane/IssueListPane-types';
import { IContractParagraph } from '../Paragraph/Paragraph-types';
import { ILexibleProperty, SubmitStatus } from '../App/App-types';
import { IFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import { ISummaryProperty } from '../SummaryPane/SummaryPane-types';
import { ContractSidebarTab } from '../ContractSidebar/ContractSidebar-types';
import { IBannerSettings } from '../HeatmapPane/HeatmapPane-types';
import {
    ContractIssue,
    IssueSeverityLevel,
    IssueStatus,
    LexibleTheme,
    ParagraphClause,
} from '@thought-river/negotiations-common';
import { setSelectedParagraphAction } from '../Contract/Contract-actions';
import { IssuePaneContextValue } from '@modules/common/context/IssuePaneProvider/types';

export const SET_ISSUE_FORM = 'SET_ISSUE_FORM';
export const SET_ISSUE_FORM_STATUS = 'SET_ISSUE_FORM_STATUS';
export const SET_SHOW_ISSUE_MODAL = 'SET_SHOW_ISSUE_MODAL';

export const systemUser: IAccountUser = {
    email: 'N/A',
    firstName: 'System',
    lastName: '',
    id: 'N/A',
    streamCodes: [],
};

export enum IssueFormField {
    CATEGORIES = 'categories',
    CLAUSE_NUMBERS = 'clauseNumbers',
    DESCRIPTION = 'description',
    SEVERITY_LEVEL = 'severityLevel',
    STATUS = 'status',
    TITLE = 'title',
}

export interface IIssuePaneEditableField {
    categories: boolean;
    description: boolean;
    title: boolean;
}

export interface IClauseOption {
    clauseNumber: string;
    id: string;
    index: number;
    indexLabel: string;
    originUuid: string;
    text: string;
}

export interface IIssueForm {
    categories: IIssueCategory[];
    clauseNumbers: ParagraphClause[];
    createdAt: string;
    description: string;
    issueId: string;
    lastModifier: IAccountUser;
    missingSuggestionLocations: ParagraphClause[];
    notes: string;
    reviewers: IAccountUser[];
    severityLevel: IssueSeverityLevel;
    stateLabel: IssueStateInVersion | string;
    status: IssueStatus;
    title: string;
    updatedAt: string;
}

export interface IIssuePaneState {
    changedFields: IssueFormField[];
    detectChanges: boolean;
    formInitialised: boolean;
    property: IContractProperty;
    showPropertyDetails: boolean;
    submitAttempted: boolean;
    submitting: boolean;
    originalFilteredIssues: ContractIssue[];
}

export interface IIssuePaneStateProps {
    activeContractTab: ContractSidebarTab;
    assessment: IContractAssesssment;
    categories: IIssueCategory[];
    contract: IDisplayContract;
    featureToggles: IFeatureToggle[];
    highlightedParagraphs: IContractParagraph[];
    form: IIssueForm;
    formStatus: SubmitStatus;
    issue: ContractIssue | null;
    issues: ContractIssue[];
    lexibleProperties: ILexibleProperty[];
    paragraphs: IContractParagraph[];
    policies: IContractPolicy[];
    properties: IContractProperty[];
    propertyDetailsLoginCount: number;
    selectedVersion: IContractVersion | null;
    selectedParagraph: IContractParagraph | null;
    summaryProperties: ISummaryProperty[];
    themes: LexibleTheme[];
    userId: string;
    users: IAccountUser[];
    width: number;
}

export interface IIssuePaneReducer {
    form: IIssueForm;
    submitStatus: SubmitStatus;
}

export interface IIssuePaneDispatchProps {
    setContractSidebarTab: (contractSidebarTab: ContractSidebarTab) => void;
    setBannerSettings: (bannerSettings: IBannerSettings) => void;
    setContract: (contract: IDisplayContract) => void;
    setHighlightIndex: (
        index: number,
        highlightType: HighlightIndexType
    ) => void;
    setIssueForm: (form: IIssueForm) => void;
    setIssues: (issue: ContractIssue[]) => void;
    setContractProperties: (properties: IContractProperty[]) => void;
    setSelectedIssue: (selectedIssue: ContractIssue) => void;
    setSelectedPropertyCode: (code: string) => void;
    setSelectedParagraph: typeof setSelectedParagraphAction;
    setFormStatus: (status: SubmitStatus) => void;
    updateContractProperty: (contractProperty: IContractProperty) => void;
    updateSummaryProperty: (summaryProperty: ISummaryProperty) => void;
}

export interface ISetIssueFormAction {
    type: typeof SET_ISSUE_FORM;
    form: IIssueForm;
}

export interface ISetIssueFormStatusAction {
    type: typeof SET_ISSUE_FORM_STATUS;
    status: SubmitStatus;
}

export interface IIssuePaneProps
    extends IIssuePaneDispatchProps,
        IIssuePaneStateProps,
        IssuePaneContextValue {
    sortedFilteredIssues: ContractIssue[];
}

export type IIssuePaneActionTypes =
    | ISetIssueFormAction
    | ISetIssueFormStatusAction;
