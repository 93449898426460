import { Select, SelectOption } from '@thought-river/ui-components';
import { useState, useEffect } from 'react';
import { NONE_IDENTIFIED_OPTION } from '../../../App/App-types';

interface MultiSelectDropdownProps {
    helperText: React.ReactNode;
    label: string;
    onChange: (value: string) => void;
    onOpen: () => void;
    options?: SelectOption[];
    value: string;
    disabled: boolean;
}

const MultiSelect = ({
    helperText,
    label,
    onChange,
    onOpen,
    options,
    value,
    disabled,
}: MultiSelectDropdownProps) => {
    const [currentValue, setCurrentValue] = useState(value);
    const [allOptions, setAllOptions] = useState<SelectOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        const selectedOptionsTemp: SelectOption[] = [];
        const allOptionsTemp: SelectOption[] = [...(options ?? [])];
        if (currentValue) {
            const values = currentValue.split(', ');
            values.forEach((value) => {
                const option = options?.find(
                    (option) => option.label === value
                );
                if (option) {
                    selectedOptionsTemp.push(option);
                } else {
                    const customOption = { value: value, label: value };
                    allOptionsTemp.push(customOption);
                    selectedOptionsTemp.push(customOption);
                }
            });
        }
        setAllOptions(allOptionsTemp);
        setSelectedOptions(selectedOptionsTemp);
    }, [currentValue, options]);

    const handleMultiSelectChange = (newOptions: SelectOption[]) => {
        if (!newOptions.length) {
            setCurrentValue(NONE_IDENTIFIED_OPTION);
        } else {
            setCurrentValue(newOptions.map((o) => o.label).join(', '));
        }
    };

    const updateProperty = () => {
        if (value === currentValue) {
            return;
        }
        onChange(selectedOptions.map((option) => option.label).join(', '));
    };

    return (
        <Select
            data-testid="editable-property-dropdown"
            disableCloseOnSelect
            value={selectedOptions}
            options={allOptions}
            onChange={(option: SelectOption[]) =>
                handleMultiSelectChange(option)
            }
            multiple
            limitTags={1}
            freeSolo
            label={label}
            onClose={updateProperty}
            onOpen={onOpen}
            helperText={helperText}
            disabled={disabled}
        />
    );
};

export default MultiSelect;
