import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from 'react-beautiful-dnd';
import classNames from 'classnames';

import { DraggableListProps } from './DraggableList.types';
import './DraggableList.scss';
import { Icon } from '@thought-river/ui-components';

const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const DraggableList = ({
    classes,
    items,
    onReorder,
    onItemClick,
}: DraggableListProps) => {
    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        onReorder?.(reorderedItems);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={classNames(classes?.list)}
                    >
                        {items.map((item, index) => (
                            <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                            >
                                {(provided, snapshot) => (
                                    <div
                                        className={classNames(
                                            'item',
                                            classes?.item,
                                            snapshot.isDragging && 'is-dragging'
                                        )}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        style={{
                                            ...provided.draggableProps.style,
                                        }}
                                        onClick={() => onItemClick?.(item.id)}
                                    >
                                        <span
                                            className="drag-handle"
                                            {...provided.dragHandleProps}
                                        >
                                            <Icon
                                                type="drag-indicator"
                                                className="drag-handle-icon"
                                            />
                                        </span>
                                        {item.content}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DraggableList;
