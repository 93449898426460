import * as React from 'react';
import { FilterList } from '@mui/icons-material';
import {
    ContractIntelligenceType,
    HighlightIndexType,
} from '../Contract/Contract-types';
import {
    IIssueListPaneProps,
    IIssueListPaneState,
    IssuePrivacySetting,
} from './IssueListPane-types';
import IssueListFilters from '../IssueListFilters/IssueListFilters-container';
import HeatmapScrollbar from '../HeatmapScrollbar/HeatmapScrollbar-container';
import { analytics } from './IssueListPane-analytics';
import {
    FiltersContext,
    IssuesSortOption,
    PresetIssueFilters,
} from '../IssueListFilters/IssueListFilters-types';
import IssueViewsDropdown from '../IssueViewsDropdown/IssueViewsDropdown';
import IssueCard from './IssueCard';
import { IIssueData } from 'types/thoughtriver';
import { mapClauseNumber, mapIssue } from '../App/App-mappers';
import IntelligenceBars from '../IntelligenceBars/IntelligenceBars';
import { IssueViewsContext } from '../IssueViewsDropdown/IssueViewsDropdown-types';
import {
    Button,
    Icon,
    IconButton,
    Tooltip,
} from '@thought-river/ui-components';
import {
    IssueCreationType,
    IssueFormValues,
    IssueSeverityLevel,
    IssueStatus,
    CreateNewIssue,
} from '@thought-river/negotiations-common';
import IssueResource from '../../resources/IssueResource';
import { toast } from 'react-toastify';
import { getResourceIdFromResponse } from '../../resources/resource-helpers';
import { IIssueRequestPayload } from '../../resources/IssueResource-types';
import { resolveSeverityLevelValue } from './IssuesListPane-helpers';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
    VariableSizeList as List,
    ListChildComponentProps,
} from 'react-window';

const { SHOWSTOPPER, HIGH, MEDIUM, LOW, OK } = IssueSeverityLevel;

const IssueRow = ({ index, data, style }: ListChildComponentProps) => {
    const rowRef = React.useRef<HTMLDivElement>(null);
    const issue = data.issues[index];

    React.useEffect(() => {
        if (rowRef?.current) {
            data.setRowHeight(index, rowRef.current.children[0].clientHeight);
        }
    }, [data, index]);

    return (
        <div style={style} key={index} ref={rowRef} data-index={index}>
            <IssueCard
                issue={issue}
                versionIsLocked={data.versionIsLocked}
                onIssueUpdated={data.onIssueUpdated}
                width={data.width}
            />
        </div>
    );
};

@analytics()
class IssueListPane extends React.Component<
    IIssueListPaneProps,
    IIssueListPaneState
> {
    private readonly issuesListRef: React.RefObject<any>;
    private rowHeights: React.MutableRefObject<any>;

    constructor(props: IIssueListPaneProps) {
        super(props);

        this.state = {
            showFilters: false,
        };

        this.handleSelectView = this.handleSelectView.bind(this);
        this.onAddIssue = this.onAddIssue.bind(this);
        this.onIssueUpdated = this.onIssueUpdated.bind(this);
        this.toggleFiltersMenu = this.toggleFiltersMenu.bind(this);
        this.saveIssue = this.saveIssue.bind(this);

        this.issuesListRef = React.createRef();
        this.rowHeights = React.createRef();
        this.rowHeights.current = {};
    }

    componentDidMount() {
        const { setHighlightIndex, setSelectedParagraph, isIssuePaneOpen } =
            this.props;

        if (!isIssuePaneOpen) {
            setSelectedParagraph(null);
            setHighlightIndex(-1, HighlightIndexType.START);
            setHighlightIndex(-1, HighlightIndexType.END);
        }
    }

    getRowHeight = (index: number) =>
        this.rowHeights.current[index] + 12 || 115;

    onAddIssue() {
        const { openIssuePane } = this.props;

        openIssuePane();
    }

    onIssueUpdated(issueData: IIssueData) {
        const {
            contract,
            issues,
            setIssues,
            paragraphs,
            properties,
            lexibleProperties,
            categories,
            themes,
        } = this.props;

        const clauseNumbers = paragraphs.map(mapClauseNumber);

        const newIssue = mapIssue(
            issueData,
            clauseNumbers,
            properties,
            paragraphs,
            lexibleProperties,
            categories,
            themes,
            contract.latestVersion
        );

        setIssues(
            issues.map((issue) => (issue.id === newIssue.id ? newIssue : issue))
        );
    }

    toggleFiltersMenu() {
        this.setState({
            showFilters: !this.state.showFilters,
        });
    }

    getActiveFiltersCount(): number {
        const {
            activeIssueFilters: {
                severity,
                status,
                themes,
                categories,
                displays,
                familiarity,
            },
        } = this.props;

        return (
            +!!severity.length +
            +!!status.length +
            +!!themes.length +
            +!!categories.length +
            +!!displays.length +
            +!!familiarity.length
        );
    }

    async saveIssue(issue: IssueFormValues) {
        const {
            issues,
            contract,
            setIssues,
            properties,
            paragraphs,
            lexibleProperties,
            categories,
            themes,
        } = this.props;

        const { status, severityLevel, title } = issue;

        const {
            id: contractId,
            latestVersion: { id: versionId },
            streamCode: stream,
        } = contract;

        if (title.trim() === '') {
            return;
        }

        const payload: IIssueRequestPayload = {
            data: {
                attributes: {
                    'issue-type': 'Significant',
                    'creation-type': IssueCreationType.MANUAL,
                    privacy: IssuePrivacySetting.INTERNAL,
                    'severity-level': resolveSeverityLevelValue(severityLevel),
                    status: status,
                    title: title,
                },
            },
        };

        try {
            const newIssueResponse = await IssueResource.createIssue(
                contractId,
                versionId,
                stream,
                payload
            );
            const newIssueId =
                getResourceIdFromResponse(newIssueResponse) ?? '';
            const response = await IssueResource.getIssue(
                contractId,
                versionId,
                newIssueId,
                stream
            );

            const newIssue = mapIssue(
                response.data,
                [],
                properties,
                paragraphs,
                lexibleProperties,
                categories,
                themes,
                contract.latestVersion
            );

            setIssues([newIssue, ...issues]);
        } catch {
            toast.error('Error creating issue');
        }
    }

    renderCreateNewIssueCard() {
        const { selectedVersion } = this.props;

        const versionIsLocked = !!selectedVersion && !selectedVersion.isLatest;

        if (!versionIsLocked) {
            return <CreateNewIssue onSaveIssue={this.saveIssue} />;
        }
    }

    handleSelectView(option: keyof typeof PresetIssueFilters) {
        const { setSelectedIssueFilters, setIssuesSortByOption, sortBy } =
            this.props;

        const value = PresetIssueFilters[option];

        if (value === PresetIssueFilters.CommercialReview) {
            setIssuesSortByOption(IssuesSortOption.FAMILIARITY);
        } else if (sortBy === IssuesSortOption.FAMILIARITY) {
            setIssuesSortByOption(IssuesSortOption.CLAUSE);
        }

        setSelectedIssueFilters(option);
    }

    setRowHeight = (index: number, size: number) => {
        if (this.issuesListRef.current) {
            this.rowHeights.current = {
                ...this.rowHeights.current,
                [index]: size,
            };
            //@ts-ignore
            this.issuesListRef.current.resetAfterIndex?.(index);
        }
    };

    render() {
        const {
            assessment,
            contract,
            featureToggles,
            filteredIssues,
            issues,
            paragraphs,
            selectedFilterPreset,
            selectedContractIntelligence,
            selectedVersion,
            setSelectedContractIntelligence,
        } = this.props;

        const issuseToResolve = issues.filter(
            (issue) =>
                issue.status !== IssueStatus.IGNORED &&
                [SHOWSTOPPER, HIGH, MEDIUM, LOW, OK].includes(
                    issue.severityLevel
                )
        );
        const totalResolvedIssues = issuseToResolve.filter(
            (issue) => issue.status === IssueStatus.CLOSED
        ).length;
        const totalIssuseToResolve = issuseToResolve.length;

        const versionIsLocked = !!selectedVersion && !selectedVersion.isLatest;
        const activeFiltersCount = this.getActiveFiltersCount();

        return (
            <div className="sidebar-pane issue-list-pane">
                <div className="filters-toolbar">
                    <IssueViewsDropdown
                        context={IssueViewsContext.CONTRACT}
                        featureToggles={featureToggles}
                        onSelectViewCallback={this.handleSelectView}
                        selectedView={selectedFilterPreset}
                    />
                    <div className="right-aligned-elements">
                        <Tooltip
                            title={
                                versionIsLocked &&
                                'Add issue disabled for older versions'
                            }
                        >
                            <IconButton
                                disabled={versionIsLocked}
                                onClick={this.onAddIssue}
                                data-testid="issue-list-add-filter-button"
                            >
                                <Icon type="add" />
                            </IconButton>
                        </Tooltip>
                        <div className="filters-button-wrapper">
                            <Button
                                variant="secondary"
                                className="filters-button"
                                onClick={this.toggleFiltersMenu}
                                startIcon={<FilterList />}
                                data-testid="issue-list-filters-button"
                            >
                                <span>Filter & Sort</span>
                                <span className="active-filters-indicator">
                                    ({activeFiltersCount})
                                </span>
                            </Button>
                            <IssueListFilters
                                show={this.state.showFilters}
                                onClose={this.toggleFiltersMenu}
                                context={FiltersContext.IssuesList}
                            />
                        </div>
                    </div>
                </div>

                <div className="intelligence-bars-wrapper">
                    <IntelligenceBars
                        assessment={assessment}
                        featureToggles={featureToggles}
                        issues={issues}
                        selectedContractIntelligence={
                            selectedContractIntelligence
                        }
                        onContractIntelligenceChange={
                            setSelectedContractIntelligence
                        }
                        contract={contract}
                    />
                    {selectedContractIntelligence.type ===
                        ContractIntelligenceType.RISK && (
                        <div className="resolved-issues-progress">
                            <span className="resolved-issues-ratio">
                                {totalResolvedIssues}/{totalIssuseToResolve}
                            </span>
                            <span>resolved</span>
                        </div>
                    )}
                </div>

                <div className="contents-wrapper">
                    <HeatmapScrollbar paragraphs={paragraphs} />
                    <div className="issue-list">
                        {issues.length ? (
                            <>
                                {this.renderCreateNewIssueCard()}
                                <div style={{ height: '100%' }}>
                                    <AutoSizer className="autosizer">
                                        {({ height, width }: any) => (
                                            <List
                                                ref={this.issuesListRef}
                                                height={height}
                                                width={width}
                                                itemSize={this.getRowHeight}
                                                itemCount={
                                                    filteredIssues.length
                                                }
                                                overscanCount={5}
                                                itemData={{
                                                    issues: filteredIssues,
                                                    setRowHeight:
                                                        this.setRowHeight,
                                                    versionIsLocked:
                                                        versionIsLocked,
                                                    onIssueUpdated:
                                                        this.onIssueUpdated,
                                                    width,
                                                }}
                                            >
                                                {IssueRow}
                                            </List>
                                        )}
                                    </AutoSizer>
                                </div>

                                {!filteredIssues.length ? (
                                    <div className="no-matches">
                                        <div className="no-matches-placeholder">
                                            No issues match the current
                                            selection and/or filters.
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        ) : (
                            <div className="content">
                                {this.renderCreateNewIssueCard()}
                                <p>No issues available.</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default IssueListPane;
