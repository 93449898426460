import { ContractMenuProps } from './ContractMenu.types';
import {
    Delete,
    Draw,
    GetApp,
    Grading,
    NoteAdd,
    People,
    Person,
    Replay,
} from '@mui/icons-material';
import DeleteContractModal from '../DeleteContractModal/DeleteContractModal-container';
import DeleteVersionModal from '../DeleteVersionModal/DeleteVersionModal-container';
import { BusinessStatus } from '../Contract/Contract-types';
import ContractUploadWizardModal from '../ContractUploadWizardModal/ContractUploadWizardModal-container';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../redux/reducers-types';
import { reprocessContract } from '../Contract/Contract-helpers';
import {
    usePostSetTemplate,
    usePostSignContract,
    useDeleteUnsetTemplate,
    useDeleteUnsignContract,
} from '@thought-river/negotiations-common/dist/api/contractContent';
import { showDialogAction } from '../ModalDialog/ModalDialog-actions';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Icon,
    IconButton,
    OverflowMenu,
    OverflowMenuItem,
} from '@thought-river/ui-components';
import { toast } from 'react-toastify';
import { useAnalytics } from './ContractMenu.analytics';
import ShareNegotiationModal from '../ShareNegotiationModal/ShareNegotiationModal-container';
import { FileFormat } from '@modules/common/types';
import { downloadContract } from '@modules/common/helpers';

const ContractMenu = ({
    deleteVersionOnly,
    formats,
    contract,
    onOpen,
    onContractUpdated,
    hideDownloadsSection,
    additionalItems,
}: ContractMenuProps) => {
    const [downloading, setDownloading] = useState(false);
    const [showUploadContractModal, setShowUploadContractModal] =
        useState(false);

    const businessStatuses = useSelector(
        (state: IRootState) => state.app.businessStatuses
    );

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const {
        trackDownloadClick,
        trackEditPartiesClick,
        trackSignContractClick,
        trackUnsignContractClick,
    } = useAnalytics(contract);

    const { mutateAsync: setTemplate, isLoading: setTemplateLoading } =
        usePostSetTemplate();
    const { mutateAsync: unsetTemplate, isLoading: unsetTemplateLoading } =
        useDeleteUnsetTemplate();

    const { mutateAsync: signContract, isLoading: signContractLoading } =
        usePostSignContract({});
    const { mutateAsync: unsignContract, isLoading: unsignContractLoading } =
        useDeleteUnsignContract({});

    const handleDownloadFile = async (format: FileFormat = FileFormat.DOCX) => {
        if (downloading) {
            return;
        }

        setDownloading(true);

        trackDownloadClick();
        await downloadContract(
            contract.latestVersion.id,
            format,
            contract.contractName
        );

        setDownloading(false);
    };

    const editParties = () => {
        trackEditPartiesClick();
        history.push({
            pathname: `/stream/${contract.streamCode}/contract/${contract.id}/parties`,
            state: { prevPath: location.pathname },
        });
    };

    const handleAddPeopleClick = () => {
        dispatch(
            showDialogAction(
                'Add People',
                true,
                <ShareNegotiationModal
                    contract={contract}
                    version={contract.latestVersion}
                />,
                [],
                null,
                'share-negotiation-modal-wrapper',
                true
            )
        );
    };

    const handleSuccessfulContractUpdate = (
        toastMessage: string,
        isSigned?: boolean,
        isTemplate?: boolean
    ) => {
        onContractUpdated?.(contract.id, {
            isSigned: isSigned ?? contract.isSigned,
            isTemplate: isTemplate ?? contract.isTemplate,
        });
        toast.success(toastMessage);
    };

    const handleTemplateUpdate = () => {
        if (contract.isTemplate) {
            unsetTemplate(
                {
                    pathParams: {
                        contractUuid: contract.id,
                        contractVersion: contract.latestVersion.versionNumber,
                    },
                },
                {
                    onSuccess: () =>
                        handleSuccessfulContractUpdate(
                            'Contract removed as template successfully!',
                            null!, // Fixme: null checks
                            false
                        ),
                    onError: () => {
                        toast.error(`Error removing contract as template!`);
                    },
                }
            );
        } else {
            setTemplate(
                {
                    pathParams: {
                        contractUuid: contract.id,
                        contractVersion: contract.latestVersion.versionNumber,
                    },
                },
                {
                    onSuccess: () =>
                        handleSuccessfulContractUpdate(
                            'Contract marked as template successfully!',
                            null!, // Fixme: null checks
                            true
                        ),
                    onError: () =>
                        toast.error(`Error marking contract as template!`),
                }
            );
        }
    };

    const handleSignUpdate = async () => {
        if (contract.isSigned) {
            trackUnsignContractClick();
            unsignContract(
                {
                    pathParams: {
                        contractUuid: contract.id,
                        contractVersion: contract.latestVersion.versionNumber,
                    },
                    body: {
                        status: businessStatuses?.find(
                            (status) => status.label === BusinessStatus.RECEIVED
                        )?.id,
                    },
                },
                {
                    onSuccess: () =>
                        handleSuccessfulContractUpdate(
                            'Contract marked as unsigned successfully!',
                            false,
                            null! // Fixme: null checks
                        ),
                    onError: () =>
                        toast.error(`Error marking contract as unsigned!`),
                }
            );
        } else {
            trackSignContractClick();
            signContract(
                {
                    pathParams: {
                        contractUuid: contract.id,
                        contractVersion: contract.latestVersion.versionNumber,
                    },
                },
                {
                    onSuccess: () =>
                        handleSuccessfulContractUpdate(
                            'Contract marked as signed successfully!',
                            true,
                            null! // Fixme: null checks
                        ),
                    onError: () =>
                        toast.error(`Error marking contract as signed!`),
                }
            );
        }
    };

    const getMenuItems = (): OverflowMenuItem[][] => {
        if (deleteVersionOnly) {
            return [
                [
                    {
                        label: 'Delete Version',
                        icon: <Delete />,
                        onClick: openDeleteVersionModal,
                        type: 'destructive',
                    },
                ],
            ];
        }

        const menuItems: OverflowMenuItem[][] = [
            [
                {
                    label: 'Reanalyze Contract',
                    icon: <Replay />,
                    onClick: () => reprocessContract(contract),
                },
                {
                    label: 'Set Parties',
                    icon: <People />,
                    onClick: editParties,
                    disabled: !contract.latestVersion?.isLatest,
                },
                {
                    label: contract?.isSigned ? 'Unsign' : 'Sign',
                    icon: <Draw />,
                    onClick: handleSignUpdate,
                    disabled:
                        contract.reprocessRequired || contract?.isTemplate,
                    loading: signContractLoading || unsignContractLoading,
                },
                {
                    label: 'Add People',
                    icon: <Person />,
                    onClick: handleAddPeopleClick,
                },
                {
                    label: contract?.isTemplate
                        ? 'Remove as Template'
                        : 'Set as Template',
                    icon: <Grading />,
                    onClick: handleTemplateUpdate,
                    disabled:
                        contract.reprocessRequired ||
                        Boolean(contract?.isSigned),
                    loading: setTemplateLoading || unsetTemplateLoading,
                },
                ...(additionalItems ? additionalItems : []),
            ],
            [
                {
                    label: 'Download as Word',
                    icon: <GetApp />,
                    onClick: () => handleDownloadFile(FileFormat.DOCX),
                    disabled: contract.reprocessRequired,
                },
            ],
            [
                {
                    label: 'Add Version',
                    icon: <NoteAdd />,
                    onClick: () => setShowUploadContractModal(true),
                    disabled: contract.reprocessRequired,
                },
            ],
        ];

        menuItems.push([
            {
                label: 'Delete Negotiation',
                icon: <Delete />,
                onClick: openDeleteContractModal,
                type: 'destructive',
            },
        ]);

        if (formats?.includes(FileFormat.PDF)) {
            const downloadOption = {
                label: 'Download as PDF',
                icon: <GetApp />,
                onClick: () => handleDownloadFile(FileFormat.PDF),
                disabled: contract.reprocessRequired,
            };

            //insert in the 2nd section after 'Download as Word' item
            menuItems[1].splice(1, 0, downloadOption);
        }

        if (contract.versions.length >= 2) {
            //insert in the section after 'Delete Negotiation' item
            menuItems[menuItems.length - 1].splice(0, 0, {
                label: 'Delete Version',
                icon: <Delete />,
                onClick: openDeleteVersionModal,
                type: 'destructive',
            });
        }

        if (hideDownloadsSection) {
            menuItems.splice(1, 1);
        }

        return menuItems;
    };

    const openDeleteContractModal = () =>
        dispatch(
            showDialogAction(
                'Delete negotiation',
                true,
                <DeleteContractModal contract={contract} />,
                [],
                null
            )
        );

    const openDeleteVersionModal = () =>
        dispatch(
            showDialogAction(
                'Delete version',
                true,
                <DeleteVersionModal
                    contract={contract}
                    version={contract.latestVersion}
                />,
                [],
                null
            )
        );

    const menuItems = getMenuItems();

    return (
        <>
            <OverflowMenu
                button={
                    <IconButton
                        onClick={() => {
                            onOpen?.();
                        }}
                        size="medium"
                        data-testid="contract-menu"
                    >
                        <Icon type="more-vert" />
                    </IconButton>
                }
                menuItems={menuItems}
            />
            <ContractUploadWizardModal
                open={showUploadContractModal}
                onClose={() => setShowUploadContractModal(false)}
                existingContractId={contract.id}
                existingStreamId={contract.streamId}
            />
        </>
    );
};

export default ContractMenu;
