import { Tooltip } from '@thought-river/ui-components';
import { IIssueFamiliarityProps } from './IssueFamiliarity-types';
import './IssueFamiliarity.scss';
import { Remove } from '@mui/icons-material';
import {
    FamiliarityLevel,
    ParagraphClause,
} from '@thought-river/negotiations-common';

const IssueFamiliarity = ({
    paragraphs,
    onSelectParagraph,
}: IIssueFamiliarityProps) => {
    const renderFamiliarityBar = (paragraph: ParagraphClause) => {
        const familiarity = paragraph.organisationFamiliarity;
        const tooltipTitle =
            paragraph.organisationFamiliarity.frequencyLevel ===
            FamiliarityLevel.TEMPLATE
                ? 'Paragraph wording exactly matches a template contract for this deal type'
                : `Similar wording is found in ${familiarity.frequency}% of signed contracts in this deal type`;

        return (
            <Tooltip
                title={tooltipTitle}
                className={`familiarity-bar ${familiarity.frequencyLevel}`}
            >
                <div style={{ width: `${familiarity.frequency}%` }}>
                    {familiarity.frequency === 0 && <Remove />}
                </div>
            </Tooltip>
        );
    };

    return (
        <div className="issue-familiarity">
            {paragraphs.map((paragraph) => (
                <div key={paragraph.id} className="paragraph-familiarity">
                    <div
                        className="paragraph-number"
                        onClick={() => onSelectParagraph(paragraph.id)}
                    >
                        {paragraph.clauseNumber}
                    </div>
                    {renderFamiliarityBar(paragraph)}
                </div>
            ))}
            <div className="familiarity-range-axis">
                <span>Never seen before</span>
                <span>Standard</span>
            </div>
        </div>
    );
};

export default IssueFamiliarity;
