import './Voting.scss';
import { IVotingProps } from './Voting-types';
import { ThumbDown, ThumbUp } from '@mui/icons-material';
import './Voting.scss';
import { IconButton, Tooltip } from '@thought-river/ui-components';

const Voting = (props: IVotingProps): JSX.Element => {
    const { votedUp, votedDown, onVoteCallback, tooltipPlacement } = props;

    const voted = votedUp || votedDown;

    const onVote = (vote: 1 | -1) => {
        if (!voted) {
            onVoteCallback(vote);
        }
    };

    return (
        <div className="voting">
            {(!voted || votedUp) && (
                <Tooltip
                    title="Good prediction"
                    placement={tooltipPlacement || 'top'}
                >
                    <IconButton
                        className={`vote-up ${votedUp ? 'active' : ''} ${
                            voted ? 'voted' : ''
                        }`}
                        onClick={() => onVote(1)}
                        size="medium"
                    >
                        <ThumbUp className="vote-icon" />
                    </IconButton>
                </Tooltip>
            )}
            {(!voted || votedDown) && (
                <Tooltip
                    title="Incorrect prediction"
                    placement={tooltipPlacement || 'top-end'}
                >
                    <IconButton
                        className={`vote-down ${votedDown ? 'active' : ''} ${
                            voted ? 'voted' : ''
                        }`}
                        onClick={() => onVote(-1)}
                        size="medium"
                    >
                        <ThumbDown className="vote-icon" />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
};

export default Voting;
