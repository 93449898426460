import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IRootState } from '../../redux/reducers-types';
import ContractListToolbar from './ContractListToolbar';
import {
    setBulkActionsAction,
    setSearchTermAction,
    switchToggleAction,
} from './ContractListToolbar-actions';
import {
    IContractListToolbarDispatchProps,
    IContractListToolbarStateProps,
} from './ContractListToolbar-types';
import { ToggleElement } from '../ToggleBar/ToggleBar-types';
import { showDialogAction } from '../ModalDialog/ModalDialog-actions';
import { loadContracts } from '../ContractList/ContractList-helpers';
import {
    setRequestedPagesAction,
    setSelectAllContractsAction,
} from '../ContractList/ContractList-actions';
import { IModalDialogButton } from '../ModalDialog/ModalDialog-types';

function mapStateToProps(state: IRootState): IContractListToolbarStateProps {
    return {
        bulkActions: state.contractListToolbar.bulkActions,
        businessStatuses: state.app.businessStatuses,
        contracts: state.contractList.contracts,
        searchTerm: state.contractListToolbar.searchTerm,
        selectedContractIds: state.contractList.selectedContractIds,
        toggles: state.contractListToolbar.toggles,
        featureToggles: state.featureToggles.toggles,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch
): IContractListToolbarDispatchProps {
    return {
        switchToggle: (toggle: ToggleElement) =>
            dispatch(switchToggleAction(toggle)),

        loadContracts: (page?: number) => {
            loadContracts(dispatch, page);
        },

        setSearchTerm: (value: string) => dispatch(setSearchTermAction(value)),

        setRequestedPages: (requestedPages: number[]) =>
            dispatch(setRequestedPagesAction(requestedPages)),

        setBulkActions: (bulkActions: boolean) =>
            dispatch(setBulkActionsAction(bulkActions)),

        showDialog: (
            title: string | JSX.Element,
            showCloseButton: boolean,
            component: JSX.Element,
            buttons: IModalDialogButton[],
            height: string | null | undefined,
            customClassname: string = '',
            closeOnBlur: boolean = true
        ) =>
            dispatch(
                showDialogAction(
                    title,
                    showCloseButton,
                    component,
                    buttons,
                    height,
                    customClassname,
                    closeOnBlur
                )
            ),

        setSelectAllContracts: (
            selectAll: boolean,
            selectableContractIds?: string[]
        ) =>
            dispatch(
                setSelectAllContractsAction(selectAll, selectableContractIds)
            ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContractListToolbar);
