import { TextField, TextFieldProps } from '@thought-river/ui-components';
import { useEffect, useState } from 'react';
import styles from './EditableField.module.scss';

interface Props extends Pick<TextFieldProps, 'onFocus' | 'placeholder'> {
    initialValue: string;
    onChange: (value: string) => void;
}

const EditableField = ({
    initialValue,
    placeholder,
    onChange,
    onFocus,
}: Props) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const handleFieldBlur = async () => {
        if (!value) {
            setValue(initialValue);
            return;
        }

        if (value === initialValue) {
            return;
        }

        onChange(value);
    };

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    return (
        <TextField
            classes={{ root: styles.field }}
            onBlur={handleFieldBlur}
            onFocus={onFocus}
            onChange={(e) => setValue(e.target.value)}
            placeholder={placeholder}
            size="large"
            value={value}
            fitContent
        />
    );
};

export default EditableField;
