import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    Properties,
    PropertyOut,
} from '@thought-river/negotiations-common/dist/api/playbookManager';
import { useGetProperties } from '@thought-river/negotiations-common/dist/api/playbookManager';
import { LexibleTheme } from '@thought-river/negotiations-common';
import LexibleThemeResource from '../../../../../resources/LexibleThemeResource';
import { mapLexibleTheme } from '../../../../../components/App/App-mappers';
import { LexibleContextValue, LexiblePropertiesProviderProps } from './types';

const LexibleContext = React.createContext<LexibleContextValue | null>(null);

export const useLexibleContext = () => {
    const context = useContext(LexibleContext);
    if (!context) {
        throw new Error(
            'useLexibleContext caller must be wrapped in LexibleProvider'
        );
    }
    return context;
};

export const LexibleProvider = ({
    children,
}: LexiblePropertiesProviderProps) => {
    const [themes, setThemes] = useState<LexibleTheme[]>([]);
    const {
        data: propertiesDict,
        isLoading: propertiesDictLoading,
        error: propertiesDictError,
    } = useGetProperties(
        {
            queryParams: { response_format: 'dict' },
        },
        { staleTime: Infinity, cacheTime: Infinity }
    );

    const { data: properties } = useGetProperties(
        {},
        { staleTime: Infinity, cacheTime: Infinity }
    );

    useEffect(() => {
        //TODO: replace with the new  python endpoint when available
        LexibleThemeResource.getLexibleThemes().then((themes) =>
            setThemes(themes.data.map(mapLexibleTheme))
        );
    }, []);

    const value = useMemo<LexibleContextValue>(
        () => ({
            propertiesDictLoading,
            propertiesDictError,
            // safe casting as we're asking for a dict format in the query
            propertiesDict: (propertiesDict ?? {}) as Record<
                string,
                PropertyOut
            >,
            properties: properties as Properties,
            themes: themes,
        }),
        [
            propertiesDictLoading,
            propertiesDictError,
            propertiesDict,
            properties,
            themes,
        ]
    );

    return (
        <LexibleContext.Provider value={value}>
            {children}
        </LexibleContext.Provider>
    );
};
