import classNames from 'classnames';
import React, {
    HTMLAttributes,
    createElement,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
} from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../redux/reducers-types';
import ParagraphWidget from '../../../../../components/ParagraphWidget/ParagraphWidget-container';
import { ContractSidebarTab } from '../../../../../components/ContractSidebar/ContractSidebar-types';
import PropertyLinkWidget from '../../../../../components/PropertyLinkWidget/PropertyLinkWidget-container';
import { createPortal } from 'react-dom';
import { IDocumentClauseProps } from './DocumentClause.types';
import DocumentClauseMarker from './DocumentClauseMarker';
import { getHighlightType } from '../helpers';
import scrollIntoView from 'scroll-into-view';

import styles from './DocumentClause.module.scss';

const DocumentClause = forwardRef(
    (
        {
            children,
            tagName,
            onSelectionStart,
            onSelectionEnd,
            paragraphs,
            documentPageRef,
            inSidebar,
            scrollToParagraphId,
            onScrollToParagraphEnd,
            offsets,
            ...htmlAttributes
        }: IDocumentClauseProps,
        forwardedRef
    ) => {
        const contractState = useSelector(
            (state: IRootState) => state.contract
        );
        const activeTab = useSelector(
            (state: IRootState) => state.contractSidebar.activeTab
        );
        const activeIssueFilters = useSelector(
            (state: IRootState) => state.issueListFilters.activeIssueFilters
        );
        const {
            contract,
            highlightedParagraphs,
            selectedVersion,
            selectedContractIntelligence,
        } = contractState;

        const ref = useRef<HTMLElement | null>(null);
        useImperativeHandle(forwardedRef, () => ref.current);

        const paragraphIndex = Number(htmlAttributes['data-ppl-index']);
        const contractParagraph = paragraphs.find(
            (p) => p.location === paragraphIndex
        );

        const highlightedParagraphIds = highlightedParagraphs.map(
            (paragraph) => paragraph.id
        );
        const isHighlighted =
            !!contractParagraph &&
            highlightedParagraphIds.includes(contractParagraph.id);
        const isFirstHighlighted =
            highlightedParagraphIds[0] === contractParagraph?.id;
        const isVersionLocked = selectedVersion && !selectedVersion.isLatest;
        const shouldShowParagraphWidget =
            !inSidebar &&
            isFirstHighlighted &&
            !isVersionLocked &&
            activeTab === ContractSidebarTab.ISSUES;
        const shouldShowPropertyLinkWidget =
            !inSidebar &&
            !!contractParagraph &&
            !isVersionLocked &&
            activeTab === ContractSidebarTab.OVERVIEW;
        const highlightType = contractParagraph
            ? getHighlightType(
                  contractParagraph,
                  contract,
                  activeIssueFilters,
                  selectedContractIntelligence
              )
            : null;

        // Scroll to paragraph when it's been selected
        useEffect(() => {
            if (!isFirstHighlighted || !ref.current) {
                return;
            }

            scrollIntoView(ref.current, {
                time: 300,
                align: {
                    top: 0,
                    lockX: true,
                    topOffset: 80,
                },
            });
        }, [inSidebar, isFirstHighlighted]);

        // Handle "requests" to scroll to paragraph when `scrollToParagraphId` prop is provided
        useEffect(() => {
            if (ref.current && scrollToParagraphId === contractParagraph?.id) {
                scrollIntoView(ref.current, {
                    align: {
                        top: 0,
                        lockX: true,
                        topOffset: 80,
                    },
                });
                onScrollToParagraphEnd(scrollToParagraphId);
            }
        }, [
            contractParagraph?.id,
            inSidebar,
            onScrollToParagraphEnd,
            scrollToParagraphId,
        ]);

        const className = classNames(
            htmlAttributes.className,
            styles.wrapper,
            isHighlighted && styles.highlighted,
            highlightType && styles[highlightType]
        );

        const augmentedStyleProp = {
            // Keep original styles prop
            ...htmlAttributes.style,
            // Set the CSS variable used in stylesheet
            '--doc-padding-left': htmlAttributes.style?.paddingLeft,
            paddingLeft: undefined,
        } as React.CSSProperties;
        const augmentedProps: HTMLAttributes<HTMLElement> = {
            ...htmlAttributes,
            className,
            style: augmentedStyleProp,
            onMouseDown: () => onSelectionStart(paragraphIndex),
            onMouseUp: (e) => {
                // If the element clicked was button (paragraph or property link widget), ignore it
                if (e.target instanceof HTMLButtonElement) {
                    return;
                }
                onSelectionEnd(paragraphIndex);
            },
        };

        return (
            <>
                {createElement(tagName, { ...augmentedProps, ref }, children)}
                {contractParagraph && !inSidebar && (
                    <DocumentClauseMarker
                        activeIssueFilters={activeIssueFilters}
                        contract={contract}
                        contractParagraph={contractParagraph}
                        documentContentRef={documentPageRef}
                        selectedContractIntelligence={
                            selectedContractIntelligence
                        }
                        isHalfOpacity={
                            highlightedParagraphs.length > 0 && !isHighlighted
                        }
                        offsets={offsets}
                        clauseHeight={ref.current?.clientHeight ?? 0}
                    />
                )}
                {/**
                 * Both paragraph and property link widgets are rendered in portals to prevent
                 * modifying the DOM structure
                 */}
                {shouldShowParagraphWidget &&
                    documentPageRef.current &&
                    offsets &&
                    createPortal(
                        <ParagraphWidget topOffset={offsets.top} />,
                        documentPageRef.current
                    )}
                {shouldShowPropertyLinkWidget &&
                    documentPageRef.current &&
                    offsets &&
                    createPortal(
                        <PropertyLinkWidget
                            paragraph={contractParagraph}
                            topOffset={offsets.top}
                            isHighlighted={isHighlighted}
                        />,
                        documentPageRef.current
                    )}
            </>
        );
    }
);

export default DocumentClause;
