import { Component } from 'react';

import {
    IHybridReportCopyableTableProps,
    IHybridReportCopyableTableState,
} from './HybridReportCopyableTable-types';
import './HybridReportCopyableTable.scss';
import logo from '../../../assets/report/logo-dark-small.png';
import {
    FEATURE_TOGGLE_ISSUES_REPORT_BRANDING,
    IBrandDetails,
} from '../../FeatureToggleProvider/FeatureToggleProvider-types';
import DOMPurify from 'dompurify';
import {
    filterRow,
    sortByClauseDesc,
    sortByDescriptionDesc,
    sortByFamiliarityDesc,
    sortByLastUpdatedDateDesc,
    sortByNotesDesc,
    sortByPropertyAnswerDesc,
    sortByPropertyCodeDesc,
    sortByPropertyLabelDesc,
    sortByPropertyNameDesc,
    sortByPropertyQuestionDesc,
    sortByPropertyThemeDesc,
    sortBySeverityDesc,
    sortByStatusDesc,
    sortBySuggestion,
    sortBySummaryDesc,
} from '../HybridReportDataGrid/HybridReportDataGrid-helpers';
import {
    IContractReportRow,
    IHeaderCell,
    IssuesTableHeader,
    SortOrder,
} from '../HybridReportDataGrid/HybridReportDataGrid-types';
import { PresetIssueFilters } from '../../IssueListFilters/IssueListFilters-types';
import { IssuesReportPropertyLabels } from '../IssuesReport-types';
import { IContractParagraph } from '../../Paragraph/Paragraph-types';
import { EXTERNAL_COMMENTS, INTERNAL_GUIDANCE } from '../../App/App-types';
import { DEFAULT_DATE_FORMAT } from '@thought-river/ui-components';
import dayjs from 'dayjs';
import {
    IssueSeverityLevel,
    IssueStatus,
    getIssueStatusLabel,
    capitalize,
    FamiliarityLevel,
    getFamiliarityLevelLabel,
    ParagraphClause,
} from '@thought-river/negotiations-common';

class HybridReportCopyableTable extends Component<
    IHybridReportCopyableTableProps,
    IHybridReportCopyableTableState
> {
    private getBrandDetails(brandDetails?: IBrandDetails): IBrandDetails {
        const details = {
            logoPrefix: brandDetails?.logoPrefix ?? 'Created with',
            signUpPrefix: brandDetails?.signUpPrefix ?? 'No account?',
            signUpUrl:
                brandDetails?.signUpUrl ??
                'https://content.thoughtriver.com/signup-free-trial',
            signUpText: brandDetails?.signUpText ?? 'Sign up now.',
        };

        for (const key in details) {
            details[key] = String(details[key]);
        }

        return details;
    }

    private getComparator<Key extends keyof any>(
        order: SortOrder,
        orderBy: IssuesTableHeader
    ): (
        a: { [key in Key]: number | string },
        b: { [key in Key]: number | string }
    ) => number {
        let comparator: (a: any, b: any) => number;

        switch (orderBy) {
            case IssuesTableHeader.STATUS:
                comparator = sortByStatusDesc;
                break;
            case IssuesTableHeader.IMPORTANCE:
                comparator = sortBySeverityDesc;
                break;
            case IssuesTableHeader.FAMILIARITY:
                comparator = sortByFamiliarityDesc;
                break;
            case IssuesTableHeader.LINKED_CLAUSES:
                comparator = sortByClauseDesc;
                break;
            case IssuesTableHeader.LINKED_CLAUSES_TEXT:
                comparator = sortByClauseDesc;
                break;
            case IssuesTableHeader.SUGGESTIONS:
                comparator = (a, b) => -sortBySuggestion(a, b);
                break;
            case IssuesTableHeader.SUMMARY:
                comparator = sortBySummaryDesc;
                break;
            case IssuesTableHeader.DESCRIPTION:
                comparator = sortByDescriptionDesc;
                break;
            case IssuesTableHeader.LAST_UPDATED:
                comparator = sortByLastUpdatedDateDesc;
                break;
            case IssuesTableHeader.NOTES:
                comparator = sortByNotesDesc;
                break;
            case IssuesTableHeader.PROPERTY_LABEL:
                comparator = sortByPropertyLabelDesc;
                break;
            case IssuesTableHeader.PROPERTY_NAME:
                comparator = sortByPropertyNameDesc;
                break;
            case IssuesTableHeader.PROPERTY_CODE:
                comparator = sortByPropertyCodeDesc;
                break;
            case IssuesTableHeader.PROPERTY_QUESTION:
                comparator = sortByPropertyQuestionDesc;
                break;
            case IssuesTableHeader.PROPERTY_ANSWER:
                comparator = sortByPropertyAnswerDesc;
                break;
            case IssuesTableHeader.PROPERTY_THEME:
                comparator = sortByPropertyThemeDesc;
                break;
            default:
                comparator = sortByClauseDesc;
        }

        return order === SortOrder.DESC
            ? (a, b) => comparator(a, b)
            : (a, b) => -comparator(a, b);
    }

    private renderBrandNestedTable() {
        const { featureToggles } = this.props;

        let details = this.getBrandDetails();

        const signUpFeatureToggle = featureToggles.find(
            (toggle) => toggle.feature === FEATURE_TOGGLE_ISSUES_REPORT_BRANDING
        );

        if (signUpFeatureToggle?.enabled && signUpFeatureToggle.value) {
            details = this.getBrandDetails(
                signUpFeatureToggle.value as IBrandDetails
            );
        }

        return (
            <div className="branding">
                <span>{details.logoPrefix}</span>
                &nbsp;&nbsp;
                <span>
                    <img src={logo} alt="ThoughtRiver logo" />
                </span>
                &nbsp;&nbsp;
                <span>{details.signUpPrefix}</span>
                &nbsp;&nbsp;
                <span>
                    <a
                        className="sign-up-url"
                        target="_blank"
                        href={details.signUpUrl}
                        style={{
                            color: '#5099fd',
                        }}
                        rel="noopener noreferrer"
                    >
                        {details.signUpText}
                    </a>
                </span>
            </div>
        );
    }

    private renderClauseNumber(
        clause: ParagraphClause,
        isLast: boolean = false
    ) {
        return (
            <span className="clause-number" key={clause.index}>
                {clause.clauseNumber}
                {!isLast ? <span className="separator">,&nbsp;</span> : null}
            </span>
        );
    }

    private renderImportanceCell(importance: IssueSeverityLevel) {
        const label =
            importance === IssueSeverityLevel.UNSCORED
                ? 'None'
                : capitalize(importance);

        return label;
    }

    private renderLastUpdatedCell(date: string) {
        return dayjs(date).format(DEFAULT_DATE_FORMAT);
    }

    private renderStatusCell(status: IssueStatus) {
        const label = getIssueStatusLabel(status);
        return label;
    }

    private renderFamiliarityCell(familiarity: FamiliarityLevel) {
        return familiarity ? getFamiliarityLevelLabel(familiarity) : '';
    }

    private renderLinkedClausesCell(contractRow: IContractReportRow) {
        const { linkedParagraphClauses: clauses } = contractRow;

        if (!clauses.length) {
            return null;
        }

        const clausesPerGroup = 4;
        const rowCount = Math.ceil(clauses.length / clausesPerGroup);
        const sortedClauses = clauses.sort(this.sortClauseNumbers);

        const groups = [];

        for (let i = 0; i < rowCount; i++) {
            const clausesToSkip = i ? i * clausesPerGroup : 0;

            groups.push(
                <div key={`linked-clause-group-${i}`}>
                    {sortedClauses
                        .slice(clausesToSkip, clausesToSkip + clausesPerGroup)
                        .map((clause, index) => (
                            <span key={`clause-${i}-${clause.index}`}>
                                {this.renderClauseNumber(
                                    clause,
                                    index + 1 === clauses.length
                                )}
                            </span>
                        ))}
                </div>
            );
        }

        return groups;
    }

    private renderLinkedClausesTextCell(
        linkedParagraphs: IContractParagraph[]
    ) {
        return linkedParagraphs.length
            ? linkedParagraphs.map((paragraph) => (
                  <div className="linked-clause-text" key={paragraph.id}>
                      <div>{paragraph.clauseNumber}</div>
                      <div>{paragraph.text}</div>
                      <br />
                  </div>
              ))
            : '-';
    }

    private renderSuggestionsCell(row: IContractReportRow) {
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                        this.normalizeHtmlTags(row.selectedSuggestion || '-')
                    ),
                }}
            />
        );
    }

    private sortClauseNumbers(
        clauseA: ParagraphClause,
        clauseB: ParagraphClause
    ) {
        return clauseA.index - clauseB.index;
    }

    private stableSort(array: any[], comparator: (a: any, b: any) => number) {
        const stabilizedThis = array.map(
            (el, index) => [el, index] as [any, number]
        );
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    private normalizeHtmlTags(html: string = '') {
        return html
            .replace(/<ins>/gm, '<u>')
            .replace(/<\/ins>/gm, '</u>')
            .replace(/<del>/gm, '<s>')
            .replace(/<\/del>/gm, '</s>');
    }

    private renderCell(
        activeColumns: IssuesTableHeader[],
        row: IContractReportRow,
        header: IssuesTableHeader,
        linkedParagraphs: IContractParagraph[]
    ): React.ReactNode {
        if (!activeColumns.includes(header)) {
            return null;
        }

        const key = `cell-${header}-${row.rowId}`;

        switch (header) {
            case IssuesTableHeader.STATUS:
                return (
                    <td
                        className="hybrid-report-table-cell min-width"
                        key={key}
                    >
                        {/* Fixme: null checks */}
                        {this.renderStatusCell(row.status!)}
                    </td>
                );
            case IssuesTableHeader.IMPORTANCE:
                return (
                    <td
                        className="hybrid-report-table-cell min-width"
                        key={key}
                    >
                        {/* Fixme: null checks */}
                        {this.renderImportanceCell(row.severityLevel!)}
                    </td>
                );
            case IssuesTableHeader.LINKED_CLAUSES:
                return (
                    <td className="hybrid-report-table-cell" key={key}>
                        {this.renderLinkedClausesCell(row)}
                    </td>
                );
            case IssuesTableHeader.FAMILIARITY:
                return (
                    <td className="hybrid-report-table-cell" key={key}>
                        {this.renderFamiliarityCell(row.familiarity)}
                    </td>
                );
            case IssuesTableHeader.LINKED_CLAUSES_TEXT:
                return (
                    <td className="hybrid-report-table-cell" key={key}>
                        {this.renderLinkedClausesTextCell(linkedParagraphs)}
                    </td>
                );
            case IssuesTableHeader.SUGGESTIONS:
                return (
                    <td className="hybrid-report-table-cell" key={key}>
                        {this.renderSuggestionsCell(row)}
                    </td>
                );
            case IssuesTableHeader.SUMMARY:
                return (
                    <td className="hybrid-report-table-cell" key={key}>
                        {row.title}
                    </td>
                );
            case IssuesTableHeader.DESCRIPTION:
                return (
                    <td
                        className="hybrid-report-table-cell"
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                this.normalizeHtmlTags(row.description)
                            ),
                        }}
                        key={key}
                    />
                );
            case IssuesTableHeader.NOTES:
                return (
                    <td
                        className="hybrid-report-table-cell"
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                this.normalizeHtmlTags(row.notes)
                            ),
                        }}
                        key={key}
                    />
                );
            case IssuesTableHeader.LAST_UPDATED:
                return (
                    <td
                        className="hybrid-report-table-cell min-width"
                        key={key}
                    >
                        {this.renderLastUpdatedCell(row.issueUpdatedAt)}
                    </td>
                );
            case IssuesTableHeader.PROPERTY_LABEL:
                return (
                    <td
                        className="hybrid-report-table-cell min-width"
                        key={key}
                    >
                        {row.propertyLabel}
                    </td>
                );
            case IssuesTableHeader.PROPERTY_NAME:
                return (
                    <td
                        className="hybrid-report-table-cell min-width"
                        key={key}
                    >
                        {row.propertyName}
                    </td>
                );
            case IssuesTableHeader.PROPERTY_CODE:
                return (
                    <td
                        className="hybrid-report-table-cell min-width"
                        key={key}
                    >
                        {row.propertyCode}
                    </td>
                );
            case IssuesTableHeader.PROPERTY_QUESTION:
                return (
                    <td
                        className="hybrid-report-table-cell min-width"
                        key={key}
                    >
                        {row.propertyQuestion}
                    </td>
                );
            case IssuesTableHeader.PROPERTY_ANSWER:
                return (
                    <td
                        className="hybrid-report-table-cell min-width"
                        key={key}
                    >
                        {row.propertyValue}
                    </td>
                );
            case IssuesTableHeader.PROPERTY_THEME:
                return (
                    <td
                        className="hybrid-report-table-cell min-width"
                        key={key}
                    >
                        {row.propertyTheme?.name || '-'}
                    </td>
                );
            default:
                return null;
        }
    }

    render() {
        const {
            activeFilters,
            columns,
            copyingTable,
            lastValidView,
            paragraphs,
            rows,
            sortModel,
            columnsOrderData,
            summaryPropertyCodes,
        } = this.props;

        const filteredRows: IContractReportRow[] = rows.filter((row) =>
            filterRow(row, activeFilters, summaryPropertyCodes)
        );

        const activeColumns: IssuesTableHeader[] = [];

        const headers: IHeaderCell[] = [
            {
                id: IssuesTableHeader.STATUS,
                label: 'Status',
                defaultSort: SortOrder.DESC,
            },
            {
                id: IssuesTableHeader.IMPORTANCE,
                label: 'Importance',
                defaultSort: SortOrder.DESC,
            },
            {
                id: IssuesTableHeader.FAMILIARITY,
                label: 'Familiarity',
                defaultSort: SortOrder.DESC,
            },
            {
                id: IssuesTableHeader.LINKED_CLAUSES,
                label: 'Linked Clauses',
                defaultSort: SortOrder.ASC,
            },
            {
                id: IssuesTableHeader.LINKED_CLAUSES_TEXT,
                label: 'Linked Clauses Text',
                defaultSort: SortOrder.ASC,
            },
            {
                id: IssuesTableHeader.SUGGESTIONS,
                label: 'Drafting Options',
                defaultSort: SortOrder.ASC,
            },
            {
                id: IssuesTableHeader.SUMMARY,
                label: 'Summary',
                defaultSort: SortOrder.ASC,
            },
            {
                id: IssuesTableHeader.DESCRIPTION,
                label: INTERNAL_GUIDANCE,
                defaultSort: SortOrder.ASC,
            },
            {
                id: IssuesTableHeader.NOTES,
                label: EXTERNAL_COMMENTS,
                defaultSort: SortOrder.ASC,
            },
            {
                id: IssuesTableHeader.LAST_UPDATED,
                label: 'Last Updated',
                defaultSort: SortOrder.DESC,
            },
            {
                id: IssuesTableHeader.PROPERTY_LABEL,
                label: IssuesReportPropertyLabels[
                    IssuesTableHeader.PROPERTY_LABEL
                ],
                defaultSort: SortOrder.ASC,
            },
            {
                id: IssuesTableHeader.PROPERTY_NAME,
                label: IssuesReportPropertyLabels[
                    IssuesTableHeader.PROPERTY_NAME
                ],
                defaultSort: SortOrder.ASC,
            },
            {
                id: IssuesTableHeader.PROPERTY_CODE,
                label: IssuesReportPropertyLabels[
                    IssuesTableHeader.PROPERTY_CODE
                ],
                defaultSort: SortOrder.ASC,
            },
            {
                id: IssuesTableHeader.PROPERTY_QUESTION,
                label: IssuesReportPropertyLabels[
                    IssuesTableHeader.PROPERTY_QUESTION
                ],
                defaultSort: SortOrder.ASC,
            },
            {
                id: IssuesTableHeader.PROPERTY_ANSWER,
                label: IssuesReportPropertyLabels[
                    IssuesTableHeader.PROPERTY_ANSWER
                ],
                defaultSort: SortOrder.ASC,
            },
            {
                id: IssuesTableHeader.PROPERTY_THEME,
                label: IssuesReportPropertyLabels[
                    IssuesTableHeader.PROPERTY_THEME
                ],
                defaultSort: SortOrder.ASC,
            },
        ];

        if (lastValidView === PresetIssueFilters.ContractSummary) {
            const linkedClausesColumnIndex = headers.findIndex(
                (header) => header.id === IssuesTableHeader.LINKED_CLAUSES
            );

            if (linkedClausesColumnIndex !== -1) {
                const [linkedCausesColumn] = headers.splice(
                    linkedClausesColumnIndex,
                    1
                );
                headers.push(linkedCausesColumn);
            }
        }

        for (const column of columns) {
            if (column.active) {
                activeColumns.push(column.label);
            }
        }

        headers.sort(
            (headerA, headerB) =>
                (columnsOrderData[headerA.id] ?? 1) -
                (columnsOrderData[headerB.id] ?? 1)
        );

        const linkedParagraphs = (paragraphOriginUuids: string[]) =>
            paragraphOriginUuids.length
                ? paragraphs.filter((paragraph) =>
                      paragraphOriginUuids.includes(paragraph.originUuid)
                  )
                : [];

        return (
            <div className="hybrid-report-table-wrapper">
                <table
                    id="hybrid-report-table"
                    className={[
                        'hybrid-report-table',
                        copyingTable ? 'copying' : '',
                    ].join(' ')}
                >
                    <thead>
                        <tr>
                            {headers.map((header, index) =>
                                activeColumns.includes(header.id) ? (
                                    <th
                                        className="hybrid-report-table-header"
                                        key={`hybrid-report-table-header-${index}`}
                                    >
                                        {header.label}
                                    </th>
                                ) : null
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {this.stableSort(
                            filteredRows,
                            this.getComparator(
                                sortModel[0]?.sort as SortOrder,
                                sortModel[0]?.field as IssuesTableHeader
                            )
                        ).map((row) => (
                            <tr
                                className="hybrid-report-table-row"
                                key={row.rowId}
                            >
                                {headers.map((header) =>
                                    this.renderCell(
                                        activeColumns,
                                        row,
                                        header.id,
                                        linkedParagraphs(
                                            row.paragraphOriginUuids
                                        )
                                    )
                                )}
                            </tr>
                        ))}
                        <tr className="hybrid-report-table-row">
                            <td
                                colSpan={activeColumns.length}
                                className="hybrid-report-branding-cell"
                            >
                                <br />
                                {this.renderBrandNestedTable()}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default HybridReportCopyableTable;
