import { useState } from 'react';
import * as React from 'react';
import { Menu } from '@mui/material';

import { ICustomToolbarOptionProps } from './CustomToolbarOption-types';
import './CustomToolbarOption.scss';
import { Tooltip } from '@thought-river/ui-components';

export const CustomToolbarOption = (props: ICustomToolbarOptionProps) => {
    const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);

    const {
        selector,
        tooltipValue = null,
        options = [],
        callback,
        classes = '',
        clickable = true,
    } = props;

    let label = props.label;

    const handleLabelClick = (event: React.MouseEvent) => {
        preventDefaults(event);

        if (selector) {
            // @ts-ignore
            document.querySelector(selector).click();
        }

        if (callback) {
            callback(event);
        }

        if (options) {
            setMenuAnchor(event.currentTarget);
        }
    };

    const preventDefaults = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
    };

    if (typeof label !== 'string') {
        label = React.cloneElement(label, {
            ...label.props,
            onClick: (event: React.MouseEvent) => {
                event.stopPropagation();
                handleLabelClick(event);
            },
        });
    }

    const toolbarLabel = (
        <span
            className={`toolbar-label-wrapper ${clickable ? 'clickable' : ''}`}
            onClick={handleLabelClick}
        >
            <span className="toolbar-label">{label}</span>
        </span>
    );

    return (
        <div className={`custom-toolbar-option ${classes}`}>
            {tooltipValue ? (
                <Tooltip title={tooltipValue} placement="top">
                    {toolbarLabel}
                </Tooltip>
            ) : (
                toolbarLabel
            )}
            {options.length ? (
                <Menu
                    anchorEl={menuAnchor}
                    autoFocus={false}
                    className={`custom-toolbar-dropdown ${classes}`}
                    elevation={1}
                    onClose={() => setMenuAnchor(null)}
                    open={Boolean(menuAnchor)}
                    MenuListProps={{
                        className: 'custom-toolbar-list',
                    }}
                    PaperProps={{
                        style: {
                            transform: 'translateY(25%)',
                        },
                    }}
                >
                    {options.map((option, index) => (
                        <div key={`toolbar-option-${index}`}>{option}</div>
                    ))}
                </Menu>
            ) : null}
        </div>
    );
};
