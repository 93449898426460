import {
    ContractIssue,
    LexibleTheme,
} from '@thought-river/negotiations-common';
import { ILexibleProperty } from '../App/App-types';
import {
    IContractProperty,
    IDisplayContract,
} from '../Contract/Contract-types';
import { ContractSidebarTab } from '../ContractSidebar/ContractSidebar-types';
import { IIssueCategory } from '../IssueListPane/IssueListPane-types';
import { IIssueForm } from '../IssuePane/IssuePane-types';
import { IContractParagraph } from '../Paragraph/Paragraph-types';
import { IssuePaneContextValue } from '@modules/common/context/IssuePaneProvider/types';
import { setSelectedIssueAction } from '../Contract/Contract-actions';

export enum LinkStatus {
    LINKED = 'linked',
    NOT_LINKED = 'notLinked',
    MIXED = 'mixed',
}

export interface IParagraphWidgetStateProps {
    categories: IIssueCategory[];
    contract: IDisplayContract;
    form: IIssueForm;
    highlightedParagraphs: IContractParagraph[];
    issues: ContractIssue[];
    lexibleProperties: ILexibleProperty[];
    paragraphs: IContractParagraph[];
    properties: IContractProperty[];
    selectedIssue: ContractIssue;
    themes: LexibleTheme[];
}

export interface IParagraphWidgetProps
    extends IParagraphWidgetDispatchProps,
        IParagraphWidgetStateProps,
        IssuePaneContextValue {
    topOffset?: number;
}

export interface IParagraphWidgetDispatchProps {
    setContractSidebarTab: (tab: ContractSidebarTab) => void;
    setIssues: (issue: ContractIssue[]) => void;
    setIssueForm: (form: IIssueForm) => void;
    setSelectedIssue: typeof setSelectedIssueAction;
}
