import { ContractVersionReportColumn } from '@thought-river/negotiations-common/dist/api/contractContent';
import { getDefaultSortComparator } from '@modules/reports/common';
import { GridComparatorFn, GridSortCellParams } from '@mui/x-data-grid-premium';
import { issueSeveritySortOrder } from '@thought-river/negotiations-common';

const issueImportanceSortComparator: GridComparatorFn = (v1, v2) => {
    if (!issueSeveritySortOrder[v1] && !issueSeveritySortOrder[v2]) {
        return 0;
    }

    if (!issueSeveritySortOrder[v1]) {
        return -1;
    }

    if (!issueSeveritySortOrder[v2]) {
        return 1;
    }

    return issueSeveritySortOrder[v2] - issueSeveritySortOrder[v1];
};

const findMaxParagraphNumberGroupedCell = (params: GridSortCellParams) => {
    const children1 = params.api.getRowGroupChildren({
        groupId: params.id,
    });
    return children1.reduce((max: number, id: string) => {
        const numbers: number[] =
            params.api.getRow(id).contract_section_paragraph_numbers;
        return Math.max(Math.max(...(numbers || [0])), max);
    }, 0);
};

const contractSectionsSortComparator: GridComparatorFn = (
    _,
    __,
    cellParams1,
    cellParams2
) => {
    let v1;
    let v2;

    if (cellParams1.api.state.rowGrouping.model.length) {
        v1 = findMaxParagraphNumberGroupedCell(cellParams1);
        v2 = findMaxParagraphNumberGroupedCell(cellParams2);
    } else {
        const cell1Numbers = cellParams1.api.getRow(
            cellParams1.id
        ).contract_section_paragraph_numbers;
        const cell2Numbers = cellParams2.api.getRow(
            cellParams2.id
        ).contract_section_paragraph_numbers;
        v1 = cell1Numbers?.[cell1Numbers.length - 1];
        v2 = cell2Numbers?.[cell2Numbers.length - 1];
    }

    if (!v1 && !v2) {
        return 0;
    }

    if (!v1) {
        return -1;
    }

    if (!v2) {
        return 1;
    }

    return v1 - v2;
};

export const getSortComparator = (column: ContractVersionReportColumn) => {
    if (column.field === 'issue_severity_level') {
        return issueImportanceSortComparator;
    }

    if (
        [
            'contract_sections',
            'contract_primary_section',
            'contract_sub_sections',
            'property_linked_clauses',
        ].includes(column.field)
    ) {
        return contractSectionsSortComparator;
    }

    return getDefaultSortComparator(column.type);
};
