import { List, ListItem } from '@mui/material';
import classNames from 'classnames';
import { SelectDataProps } from './SelectData.types';
import styles from './SelectData.module.scss';
import { ReportConfigPageMiddleSectionActiveView } from '@modules/reports/common';

const SelectData = ({
    middleSectionActiveView,
    selectedColumnsCount,
    selectedFiltersCount,
    onMiddleSectionActiveViewChange,
}: SelectDataProps) => (
    <List className={styles.list}>
        <ListItem
            className={classNames(
                styles.item,
                middleSectionActiveView ===
                    ReportConfigPageMiddleSectionActiveView.COLUMNS_LIST &&
                    styles.selected
            )}
            key="columns"
            onClick={() =>
                onMiddleSectionActiveViewChange(
                    ReportConfigPageMiddleSectionActiveView.COLUMNS_LIST
                )
            }
        >
            <span className={styles.label}>Columns</span>
            <span className={styles.count}>{selectedColumnsCount}</span>
        </ListItem>
        <ListItem
            className={classNames(
                styles.item,
                middleSectionActiveView !==
                    ReportConfigPageMiddleSectionActiveView.COLUMNS_LIST &&
                    styles.selected
            )}
            key="filters"
            onClick={() =>
                onMiddleSectionActiveViewChange(
                    ReportConfigPageMiddleSectionActiveView.FILTERS_LIST
                )
            }
        >
            <span className={styles.label}>Filters</span>
            <span className={styles.count}>{selectedFiltersCount}</span>
        </ListItem>
    </List>
);

export default SelectData;
