import { IRootState } from '../../redux/reducers-types';
import { reduxStore } from '../../redux/store';
import { analyticsService } from '../../services/Analytics/Analytics';
import {
    EVENT_ACTION_ISSUE_CARD_OPENED,
    EVENT_ACTION_ADD_NEW_ISSUE,
} from '../../services/Analytics/Analytics-types';
import { IssueCreationType } from './IssueListPane-types';
import { themesFromIssueParagraphs } from '../App/App-helpers';
import { ContractIssue } from '@thought-river/negotiations-common';

export function analytics() {
    return (target: any) => {
        const onSelectIssue = target.prototype.onSelectIssue;
        const onAddIssue = target.prototype.onAddIssue;

        target.prototype.onSelectIssue = function (
            _: any,
            issue: ContractIssue
        ) {
            const {
                contract: { contract, selectedVersion, paragraphs },
            } = reduxStore.store.getState() as IRootState;

            analyticsService.recordEvent(
                EVENT_ACTION_ISSUE_CARD_OPENED,

                {
                    ContractId: contract?.id,
                    Stream: contract?.streamCode,
                    VersionId: selectedVersion?.id,
                    VersionNumber: selectedVersion?.versionNumber,
                    IssueId: issue?.id,
                    PropertyId: issue.lexiblePropertyId,
                    IssueCreationType: issue?.creationType,
                    LegalThemes: themesFromIssueParagraphs(issue, paragraphs),
                }
            );

            onSelectIssue.apply(this, arguments);
        };

        target.prototype.onAddIssue = function () {
            const {
                contract: { contract, selectedVersion },
            } = reduxStore.store.getState() as IRootState;

            analyticsService.recordEvent(
                EVENT_ACTION_ADD_NEW_ISSUE,

                {
                    ContractId: contract?.id,
                    Stream: contract?.streamCode,
                    VersionId: selectedVersion?.id,
                    VersionNumber: selectedVersion?.versionNumber,
                    IssueCreationType: IssueCreationType.MANUAL,
                }
            );

            onAddIssue.apply(this, arguments);
        };
    };
}
