import {
    ContractSidebarTab,
    ISetActiveContractSiebarTabAction,
    ISetContractSidebarWidthInPixelsAction,
    SET_ACTIVE_CONTRACT_SIDEBAR_TAB,
    SET_CONTRACT_SIDEBAR_WIDTH_IN_PIXELS,
} from './ContractSidebar-types';

export function setContractSidebarTabAction(
    activeTab: ContractSidebarTab
): ISetActiveContractSiebarTabAction {
    return {
        type: SET_ACTIVE_CONTRACT_SIDEBAR_TAB,
        activeTab,
    };
}

export function setContractSidebarWidthInPixelsAction(
    widthPixels: number
): ISetContractSidebarWidthInPixelsAction {
    return {
        type: SET_CONTRACT_SIDEBAR_WIDTH_IN_PIXELS,
        widthPixels,
    };
}
