import {
    FamiliarityLevel,
    FamiliarityType,
    Ratio,
    getFamiliarityLevelBadgeType,
    getFamiliarityLevelLabel,
} from '@thought-river/negotiations-common';
import { formatRatio } from './formatRatio';
import { FamiliarityTotals } from '../IntelligenceBars.types';
import { Badge } from '@thought-river/ui-components';
import { IFamiliarity } from '../../../components/Contract/Contract-types';

export function getFamiliarityTotals(
    familiarity: IFamiliarity,
    type: FamiliarityType
): FamiliarityTotals {
    return {
        [FamiliarityLevel.UNFAMILIAR]:
            familiarity[type][FamiliarityLevel.UNFAMILIAR],
        [FamiliarityLevel.UNCOMMON]:
            familiarity[type][FamiliarityLevel.UNCOMMON],
        [FamiliarityLevel.COMMON]: familiarity[type][FamiliarityLevel.COMMON],
        [FamiliarityLevel.TEMPLATE]:
            familiarity[type][FamiliarityLevel.TEMPLATE],
    };
}

const TemplateFamiliarityTooltip = ({
    totals,
}: {
    totals: FamiliarityTotals;
}) => (
    <div className="fragmented-bar-tooltip-content">
        The wording in {totals[FamiliarityLevel.TEMPLATE]} paragraph
        {totals[FamiliarityLevel.TEMPLATE] > 1 ? 's' : ''} is{' '}
        <Badge
            type="familiarity-template"
            label={getFamiliarityLevelLabel(FamiliarityLevel.TEMPLATE)}
        />{' '}
        and is an exact match to the template contract for this deal type
    </div>
);

const Tooltip = ({
    familiarity,
    familiarityLevel,
    totals,
}: {
    familiarity: IFamiliarity;
    familiarityLevel: FamiliarityLevel;
    totals: FamiliarityTotals;
}) => (
    <div className="fragmented-bar-tooltip-content">
        The wording in {totals[familiarityLevel]} paragraph
        {totals[familiarityLevel] > 1 ? 's' : ''} is{' '}
        <Badge
            type={getFamiliarityLevelBadgeType(familiarityLevel)}
            label={getFamiliarityLevelLabel(familiarityLevel)}
        />{' '}
        when compared with {familiarity.totalOrganization} signed contract
        {familiarity.totalOrganization > 1 ? 's' : ''} in this deal type
    </div>
);

export const getOrgFamiliarityRatios = (
    familiarity: IFamiliarity,
    isContractTemplate: boolean,
    contractDealType: string
) => {
    const organizationFamiliarityTotals = getFamiliarityTotals(
        familiarity,
        FamiliarityType.ORGANIZATION
    );
    const organizationClauses = Object.values(
        organizationFamiliarityTotals
    ).reduce((valueA, valueB) => valueA + valueB, 0);

    // If contract is a template, show all paragraphs as Standard
    // by overriding the ratios
    if (isContractTemplate) {
        return [
            formatRatio(
                1,
                1,
                FamiliarityLevel.TEMPLATE,
                <div className="fragmented-bar-tooltip-content">
                    This contract is a template for {contractDealType} deal
                    type.
                </div>
            ),
        ];
    }

    const ratios: Ratio[] = [
        formatRatio(
            organizationClauses,
            organizationFamiliarityTotals[FamiliarityLevel.TEMPLATE],
            FamiliarityLevel.TEMPLATE,
            <TemplateFamiliarityTooltip
                totals={organizationFamiliarityTotals}
            />
        ),
        formatRatio(
            organizationClauses,
            organizationFamiliarityTotals[FamiliarityLevel.COMMON],
            FamiliarityLevel.COMMON,
            <Tooltip
                familiarity={familiarity}
                familiarityLevel={FamiliarityLevel.COMMON}
                totals={organizationFamiliarityTotals}
            />
        ),
        formatRatio(
            organizationClauses,
            organizationFamiliarityTotals[FamiliarityLevel.UNCOMMON],
            FamiliarityLevel.UNCOMMON,
            <Tooltip
                familiarity={familiarity}
                familiarityLevel={FamiliarityLevel.UNCOMMON}
                totals={organizationFamiliarityTotals}
            />
        ),
        formatRatio(
            organizationClauses,
            organizationFamiliarityTotals[FamiliarityLevel.UNFAMILIAR],
            FamiliarityLevel.UNFAMILIAR,
            <Tooltip
                familiarity={familiarity}
                familiarityLevel={FamiliarityLevel.UNFAMILIAR}
                totals={organizationFamiliarityTotals}
            />
        ),
    ];

    return ratios;
};
