import { Component } from 'react';
import {
    ICustomEmojiPickerProps,
    ICustomEmojiPickerState,
} from './CustomEmojiPicker-types';
import './CustomEmojiPicker.scss';

const emojis = [
    '😀',
    '😁',
    '😂',
    '😃',
    '😉',
    '😋',
    '😎',
    '😍',
    '😗',
    '🤗',
    '🤔',
    '😣',
    '😫',
    '😴',
    '😌',
    '🤓',
    '😛',
    '😜',
    '😠',
    '😇',
    '😷',
    '😈',
    '👻',
    '😺',
    '😸',
    '😹',
    '😻',
    '😼',
    '😽',
    '🙀',
    '🙈',
    '🙉',
    '🙊',
    '👼',
    '👮',
    '🕵',
    '💂',
    '👳',
    '🎅',
    '👸',
    '👰',
    '👲',
    '🙍',
    '🙇',
    '🚶',
    '🏃',
    '💃',
    '⛷',
    '🏂',
    '🏌',
    '🏄',
    '🚣',
    '🏊',
    '⛹',
    '🏋',
    '🚴',
    '👫',
    '💪',
    '👈',
    '👉',
    '👉',
    '👆',
    '🖕',
    '👇',
    '🖖',
    '🤘',
    '🖐',
    '👌',
    '👍',
    '👎',
    '✊',
    '👊',
    '👏',
    '🙌',
    '🙏',
    '🐵',
    '🐶',
    '🐇',
    '🐥',
    '🐸',
    '🐌',
    '🐛',
    '🐜',
    '🐝',
    '🍉',
    '🍄',
    '🍔',
    '🍤',
    '🍨',
    '🍪',
    '🎂',
    '🍰',
    '🍾',
    '🍷',
    '🍸',
    '🍺',
    '🌍',
    '🚑',
    '⏰',
    '🌙',
    '🌝',
    '🌞',
    '⭐',
    '🌟',
    '🌠',
    '🌨',
    '🌩',
    '⛄',
    '🔥',
    '🎄',
    '🎈',
    '🎉',
    '🎊',
    '🎁',
    '🎗',
    '🏀',
    '🏈',
    '🎲',
    '🔇',
    '🔈',
    '📣',
    '🔔',
    '🎵',
    '🎷',
    '💰',
    '🖊',
    '📅',
    '✅',
    '❎',
    '💯',
];

export default class CustomEmojiPicker extends Component<
    ICustomEmojiPickerProps,
    ICustomEmojiPickerState
> {
    render() {
        const { callback } = this.props;

        const rows = [];

        for (let i = 0; i < 17; i++) {
            rows.push(i);
        }

        const emojisPerRow = 8;

        return (
            <div className="custom-emoji-picker">
                {rows.map((row) => (
                    <div className="emoji-row" key={`emoji-row-${row}`}>
                        {emojis
                            .slice(row * emojisPerRow, (row + 1) * emojisPerRow)
                            .map((emoji, index) => (
                                <span
                                    className="emoji-option-wrapper"
                                    key={`emoji-${index}`}
                                    onClick={() => callback(emoji)}
                                >
                                    <span className="emoji-option">
                                        {emoji}
                                    </span>
                                </span>
                            ))}
                    </div>
                ))}
            </div>
        );
    }
}
