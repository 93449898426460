import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import styles from './ProgressCell.module.scss';

const ProgressCell = ({ row }: GridRenderCellParams) => {
    const resolvedIssues = row.issues_resolved;
    const totalIssues = row.issues_total;
    const resolvedRatio =
        totalIssues === 0 ? 100 : (100 / totalIssues) * resolvedIssues;
    const unresolvedRatio = 100 - resolvedRatio;

    if (!resolvedIssues && !totalIssues) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.progressBar}>
                <div
                    className={styles.resolved}
                    style={{ width: `${resolvedRatio}%` }}
                />
                <div
                    className={styles.unresolved}
                    style={{ width: `${unresolvedRatio}%` }}
                />
            </div>
            <span>
                {resolvedIssues}/{totalIssues}
            </span>
        </div>
    );
};
export default ProgressCell;
