export enum SplashLoaderType {
    ERROR = 'error',
    LOADER = 'loader',
    MESSAGE = 'message',
}

export interface ISplashLoaderState {}

export interface ISplashLoaderStateProps {}

export interface ISplashLoaderReducer {}

export interface ISplashLoaderDispatchProps {}

export interface ISplashLoaderProps
    extends ISplashLoaderDispatchProps,
        ISplashLoaderStateProps {
    message: string | JSX.Element;
    type?: SplashLoaderType;
    size?: 'small' | 'medium';
}

export type ISplashLoaderActionTypes = any;
