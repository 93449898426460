import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { IRootState } from '../../redux/reducers-types';
import {
    setIssuesAction,
    setSelectedIssueAction,
} from '../Contract/Contract-actions';
import ParagraphWidget from './ParagraphWidget';
import {
    IParagraphWidgetDispatchProps,
    IParagraphWidgetStateProps,
} from './ParagraphWidget-types';
import { ContractSidebarTab } from '../ContractSidebar/ContractSidebar-types';
import { setContractSidebarTabAction } from '../ContractSidebar/ContractSidebar-actions';
import { IIssueForm } from '../IssuePane/IssuePane-types';
import { setIssueFormAction } from '../IssuePane/IssuePane-actions';
import { ContractIssue } from '@thought-river/negotiations-common';
import { IssuePaneContext } from '@modules/common/context/IssuePaneProvider';
import { withContext } from '../withContext';

function mapStateToProps(state: IRootState): IParagraphWidgetStateProps {
    return {
        categories: state.app.categories,
        contract: state.contract.contract,
        form: state.issuePane.form,
        highlightedParagraphs: state.contract.highlightedParagraphs,
        issues: state.contract.issues,
        lexibleProperties: state.app.properties,
        paragraphs: state.contract.paragraphs,
        properties: state.contract.properties,
        selectedIssue: state.contract.selectedIssue,
        themes: state.contract.themes,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IParagraphWidgetDispatchProps {
    return {
        setContractSidebarTab: (tab: ContractSidebarTab) =>
            dispatch(setContractSidebarTabAction(tab)),
        setIssues: (issues: ContractIssue[]) =>
            dispatch(setIssuesAction(issues)),
        setIssueForm: (form: IIssueForm) => dispatch(setIssueFormAction(form)),
        setSelectedIssue: (issue: ContractIssue) =>
            dispatch(setSelectedIssueAction(issue)),
    };
}

export default withContext(IssuePaneContext)(
    connect(mapStateToProps, mapDispatchToProps)(ParagraphWidget)
);
