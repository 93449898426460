import { AxiosResponse } from 'axios';

export function getRelationship(resource: any, relationship: string) {
    return resource.data.relationships[relationship].data;
}

export function getAttribute(resource: any, attribute: string) {
    return resource.data.attributes[attribute];
}

export function getLatestRelationshipId(items: Array<{ id: string }>) {
    return [...items].pop()?.id;
}

export function getResourceIdFromResponse(response: Response) {
    return response.headers.get('Location')?.split('/').pop();
}

export function getResourceIdFromAxiosResponse(response: AxiosResponse) {
    return response.headers.location.split('/').pop();
}
