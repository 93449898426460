import { toast } from 'react-toastify';

import { IParagraph } from 'types/thoughtriver';
import {
    setAssessmentAction,
    setHighlightIndexAction,
    setIssuesAction,
    setParagraphsAction,
    setPropertiesAction,
    setSelectedParagraphAction,
} from './Contract-actions';
import {
    HighlightIndexType,
    IContractProperty,
    IDisplayContract,
    LOCKED_VERSION_MESSAGE,
} from './Contract-types';
import { reduxStore } from '../../redux/store';
import { getParagraphsDictionary } from '../App/App-helpers';
import { IRootState } from '../../redux/reducers-types';
import { IContractParagraph } from '../Paragraph/Paragraph-types';
import IssueResource from '../../resources/IssueResource';
import PropertyResource from '../../resources/PropertyResource';
import {
    mapAssessment,
    mapClauseNumber,
    mapIssue,
    mapProperty,
} from '../App/App-mappers';
import AssessmentResource from '../../resources/AssessmentResource';
import lodash from 'lodash';
import ContractResource from '../../resources/ContractResource';
import { ReprocessAnalysisType } from '../../resources/ContractResource-types';
import { setReprocessingContractAction } from '../ContractPollingProvider/ContractPollingProvider-actions';
import { fetchGetStatuses } from '@thought-river/negotiations-common/dist/api/contractContent';
import dayjs from 'dayjs';
import {
    ContractIssue,
    IssueStatus,
    LexibleTheme,
    ParagraphClause,
} from '@thought-river/negotiations-common';
import { IIssueRequestPayload } from '../../resources/IssueResource-types';

const { IGNORED, CLOSED } = IssueStatus;

export function paragraphIsResolved(issues: ContractIssue[]): boolean {
    return (
        Boolean(issues.length) &&
        issues.every((issue) => [IGNORED, CLOSED].includes(issue.status))
    );
}

export function filterRelevantThemes(
    theme: LexibleTheme,
    properties: IContractProperty[]
) {
    return lodash.find(properties, {
        relevantForContract: true,
        nonAssessedThemeId: theme.id,
    });
}

export function sortParagraphs(paragraphA: IParagraph, paragraphB: IParagraph) {
    return (
        parseInt(paragraphA.attributes.index, 10) -
        parseInt(paragraphB.attributes.index, 10)
    );
}

export function calculateDaysElapsed(dateA: any, dateB: any) {
    return Math.round(Math.abs(dayjs(dateA).diff(dayjs(dateB), 'day')));
}

export function showLockedVersionMessage() {
    toast.info(LOCKED_VERSION_MESSAGE);
}

export function resetSelectedParagraph() {
    const dispatch = reduxStore.store.dispatch;
    dispatch(setSelectedParagraphAction(null));
    dispatch(setHighlightIndexAction(-1, HighlightIndexType.START));
    dispatch(setHighlightIndexAction(-1, HighlightIndexType.END));
}

export async function syncContractData() {
    const dispatch = reduxStore.store.dispatch;
    const rootState = reduxStore.store.getState() as IRootState;
    const contract = rootState.contract.contract;

    if (!contract || !contract?.latestVersion) {
        return;
    }

    const themes = rootState.app.themes;
    const lexibleProperties = rootState.app.properties;
    const categories = rootState.app.categories;
    const paragraphs: IContractParagraph[] = rootState.contract.paragraphs;
    const updatedProperties: IContractProperty[] = [];
    const updatedParagraphs: IContractParagraph[] = [];
    const updatedIssues: ContractIssue[] = [];
    const updatedClauseNumbers: ParagraphClause[] = [];

    const existingAssessment = rootState.contract.assessment;

    const assessmentsResponse = await AssessmentResource.getAssessments(
        contract.id,
        contract.latestVersion.id,
        contract.streamCode
    );

    //If we cancelled the request
    if (!assessmentsResponse) {
        return;
    }

    const assessments = assessmentsResponse.data.map(mapAssessment);
    const assessment = assessments[assessments.length - 1];

    /**
     * Due to some backend issue, the familiarity returned in the new assessment tends to be incorrect,
     * so we only extract its ID and ignore the remaining data
     */
    dispatch(setAssessmentAction({ ...existingAssessment, id: assessment.id }));

    const [issuesResponse, propertyResponse] = await Promise.all([
        IssueResource.getIssues(
            contract.id,
            contract.latestVersion.id,
            contract.streamCode
        ),
        PropertyResource.getProperties(
            contract.id,
            contract.latestVersion.id,
            assessment.id,
            contract.streamCode
        ),
    ]);

    //If there are no properties in the response that means we tried to fetch the properties using an old assessment
    //This happens when the assessments response takes longer to return and in that time a new assessment was created in the database
    //It's okay to cancel this function in this case because it will be called again where it will ultimately return us the latest assessment
    if (propertyResponse.data.length === 0) {
        return;
    }

    const paragraphsDictionary = getParagraphsDictionary(paragraphs);
    const paragraphToPropertyIdsDictionary: {
        [paragraphId: string]: IContractProperty[];
    } = {};

    propertyResponse.data.forEach((property) => {
        const mappedProperty = mapProperty(
            property,
            themes,
            paragraphsDictionary
        );
        updatedProperties.push(mappedProperty);

        mappedProperty.paragraphIds.forEach((paragraphId) => {
            if (paragraphToPropertyIdsDictionary[paragraphId]) {
                paragraphToPropertyIdsDictionary[paragraphId].push(
                    mappedProperty
                );
            } else {
                paragraphToPropertyIdsDictionary[paragraphId] = [
                    mappedProperty,
                ];
            }
        });
    });

    paragraphs.forEach((paragraph) => {
        const paragraphProperties =
            paragraphToPropertyIdsDictionary[paragraph.id] ?? [];
        const themeIds: string[] = [];
        const propertyIds: string[] = [];

        paragraphProperties.forEach((property) => {
            propertyIds.push(property.id);
            themeIds.push(property.nonAssessedThemeId);
        });

        const mappedParagraph = {
            ...paragraph,
            propertyIds,
            properties: paragraphProperties,
            themeIds,
        };

        updatedClauseNumbers.push(mapClauseNumber(mappedParagraph));
        updatedParagraphs.push(mappedParagraph);
    });

    issuesResponse.data.forEach((issue) => {
        updatedIssues.push(
            mapIssue(
                issue,
                updatedClauseNumbers,
                updatedProperties,
                updatedParagraphs,
                lexibleProperties,
                categories,
                themes,
                contract.latestVersion
            )
        );
    });

    dispatch(setPropertiesAction(updatedProperties));
    dispatch(setParagraphsAction(updatedParagraphs));
    dispatch(setIssuesAction(updatedIssues));
}

export async function reprocessContract(
    contract: IDisplayContract,
    analysisType: ReprocessAnalysisType = ReprocessAnalysisType.COMPLETE,
    notify: boolean = true
) {
    const dispatch = reduxStore.store.dispatch;
    const {
        auth: { userId },
    } = (await reduxStore.store.getState()) as IRootState;

    try {
        const {
            id: contractId,
            streamCode,
            streamId,
            versionIds,
            contractName,
        } = contract;

        const versionId = versionIds[versionIds.length - 1];

        const assessments = await AssessmentResource.getAssessments(
            contractId,
            versionId,
            streamCode
        );

        await ContractResource.reprocess(
            [contractId],
            streamCode,
            analysisType
        );

        dispatch(
            setReprocessingContractAction(
                contractId,
                contractName,
                versionId,
                streamCode,
                streamId,
                assessments.data.length,
                userId
            )
        );

        if (notify) {
            toast.success('Contract queued for reprocessing');
        }
    } catch (error) {
        toast.error('Error reprocessing contract');
    }
}

export function fetchContractStatus(contractId: string) {
    const store = reduxStore.store.getState() as IRootState;
    const token = store.auth.apiToken;

    return fetchGetStatuses({
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: [{ contract_uuid: contractId }],
    });
}

export const closeIssue = async (
    contract: IDisplayContract,
    issueId: string
) => {
    const { id: contractId, latestVersion, streamCode: stream } = contract;

    const payload: IIssueRequestPayload = {
        data: {
            attributes: {
                status: IssueStatus.CLOSED,
            },
        },
    };

    await IssueResource.updateIssue(
        contractId,
        latestVersion.id,
        issueId,
        stream,
        payload,
        'Close All Issues'
    );
};

export function createProcessingNotifications(
    closedCount: number,
    updatedCount: number,
    newCount: number
) {
    if (closedCount) {
        toast.info(
            `${closedCount} issues from the previous version are no longer found. Issues moved to Closed status. You can reopen issues at any time`
        );
    }

    if (updatedCount) {
        toast.info(
            `${updatedCount} issues from the previous version have updates. You can review these on issues labeled Updated.`
        );
    }

    if (newCount) {
        toast.info(
            `${newCount} new issues from the previous version have been created. You can review these on issues labeled New.`
        );
    }
}
