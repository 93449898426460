import { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ViewReportPage } from './components';
import styles from './ContractReportPage.module.scss';
import { ReportView, reportViewsConfig } from './consts';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-premium';
import { useGetAvailableViews } from './components/ViewReportPage/hooks';
import { ContractReportPageProps } from './ContractReportPage.types';

const ContractReportPage = ({ documentHTML }: ContractReportPageProps) => {
    const availableViews = useGetAvailableViews();
    const [selectedReportView, setSelectedReportView] = useState<ReportView>(
        availableViews[0].value
    );

    const [selectedColumns, setSelectedColumns] = useState<string[]>(
        reportViewsConfig[selectedReportView]?.orderedFields ?? []
    );

    const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>(
        reportViewsConfig[selectedReportView]?.filterModel
    );

    const [sortModel, setSortModel] = useState<GridSortModel | undefined>(
        reportViewsConfig[selectedReportView]?.sortModel
    );

    const handleReportViewChange = (view: ReportView) => {
        const selectedColumns = reportViewsConfig[view].orderedFields;
        setSelectedColumns(selectedColumns);
        setSelectedReportView(view);
        setFilterModel(reportViewsConfig[view].filterModel);
        setSortModel(reportViewsConfig[view].sortModel);
    };

    return (
        <div className={styles.reportsPage}>
            <Switch>
                <Route
                    path="/stream/:dealType/contract/:contractId/version/:versionId/report"
                    exact
                    render={() => (
                        <ViewReportPage
                            selectedReportView={selectedReportView}
                            onReportViewChange={handleReportViewChange}
                            selectedColumns={selectedColumns}
                            filterModel={filterModel}
                            onFilterModelChange={setFilterModel}
                            sortModel={sortModel}
                            onSortModelChange={setSortModel}
                            documentHTML={documentHTML}
                        />
                    )}
                />
                {/* <Route
                    path="/stream/:dealType/contract/:contractId/version/:versionId/report/edit"
                    render={() => (
                        <EditReportPage
                            selectedColumns={selectedColumns}
                            onSelectedColumnsChange={setSelectedColumns}
                            filterModel={filterModel}
                            onFilterModelChange={setFilterModel}
                        />
                    )}
                /> */}
            </Switch>
        </div>
    );
};
export default ContractReportPage;
