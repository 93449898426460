import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Dialog } from '@thought-river/ui-components';
import ContractResource from '../../../../resources/ContractResource';
import { DeleteContractDialogProps } from './DeleteContractDialog.types';

const DeleteContractDialog = ({
    open,
    onClose,
    contractInfo,
}: DeleteContractDialogProps) => {
    const [submitting, setSubmitting] = useState(false);

    const { dealTypeCode, name, uuid } = contractInfo;

    const onSubmit = () => {
        setSubmitting(true);
        try {
            ContractResource.deleteContract(uuid, dealTypeCode);
            toast.success('Contract deleted successfully!');
        } catch (error) {
            toast.error('Error deleting contract');
            setSubmitting(false);
            return;
        }

        setSubmitting(false);

        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            dialogTitle="Delete negotiation"
            dialogContent={
                <div>
                    Delete all contract versions of "{name}
                    "? <br /> <br />
                    You will not be able to recover them.
                </div>
            }
            dialogActions={
                <>
                    <Button onClick={onClose} variant="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={onSubmit}
                        disabled={submitting}
                        variant="destructive"
                    >
                        Delete
                    </Button>
                </>
            }
        />
    );
};

export default DeleteContractDialog;
