import { RouteComponentProps } from 'react-router-dom';
import { Action } from 'redux';
import {
    IContractAssesssment,
    IContractProperty,
    IContractVersion,
    IDisplayContract,
} from '../Contract/Contract-types';
import { IFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import {
    IIssueFiltersValues,
    PresetIssueFilters,
} from '../IssueListFilters/IssueListFilters-types';
import { IModalDialogButton } from '../ModalDialog/ModalDialog-types';
import { IContractParagraph } from '../Paragraph/Paragraph-types';
import { SubmitStatus } from '../App/App-types';
import { ISummaryProperty } from '../SummaryPane/SummaryPane-types';
import {
    IContractReportRow,
    IssuesTableHeader,
} from './HybridReportDataGrid/HybridReportDataGrid-types';
import {
    SuggestionV01 as Suggestion,
    SummaryPanelTemplateOut,
} from '@thought-river/negotiations-common/dist/api/playbookManager';
import { GridSortModel } from '@mui/x-data-grid';
import {
    setIssuesReportSortModelAction,
    setSelectedPropertySuggestionAction,
} from './IssuesReport-actions';
import {
    ContractIssue,
    ParagraphClause,
} from '@thought-river/negotiations-common';

export const SET_ISSUES_TABLE_COLUMNS = 'SET_ISSUES_TABLE_COLUMNS';
export const SET_DOWNLOAD_PDF = 'SET_DOWNLOAD_PDF';
export const SET_ISSUES_REPORT_SAVED_STATUS = 'SET_ISSUES_REPORT_SAVED_STATUS';
export const SET_ISSUES_REPORT_SORT_MODEL = 'SET_ISSUES_REPORT_SORT_ORDER';
export const SET_SELECTED_PROPERTY_SUGGESTION =
    'SET_SELECTED_PROPERTY_SUGGESTION';

export interface IColumnsOrderData {
    [column: string]: number;
}

export type SelectedPropertySuggestionMap = Record<string, string>;

export interface IIssuesTableColumn {
    active: boolean;
    label: IssuesTableHeader;
    width: number;
}

export interface IIssuesReportState {
    isClausesSidebarOpen: boolean;
    showFilters: boolean;
    slideOutParagraphUuids: string[];
    slideOutSelectedClause: ParagraphClause | null;
    slideOutTitle: string;
    slideOutRowId: number | null;
    columnsOrderData: IColumnsOrderData;
    paragraphsById: { [id: string]: IContractParagraph };
    rows: IContractReportRow[];
    rowsLoaded: boolean;
    suggestions: Suggestion[];
}

export const IssuesReportPropertyLabels = {
    [IssuesTableHeader.PROPERTY_LABEL]: 'Lexible Label',
    [IssuesTableHeader.PROPERTY_NAME]: 'Lexible Name',
    [IssuesTableHeader.PROPERTY_CODE]: 'Lexible Code',
    [IssuesTableHeader.PROPERTY_QUESTION]: 'Lexible Question',
    [IssuesTableHeader.PROPERTY_ANSWER]: 'Lexible Answer',
    [IssuesTableHeader.PROPERTY_THEME]: 'Lexible Theme',
};

export interface IIssuesReportStateProps {
    activeFilters: IIssueFiltersValues;
    assessment: IContractAssesssment;
    columns: IIssuesTableColumn[];
    contract: IDisplayContract;
    downloadPdf: boolean;
    featureToggles: IFeatureToggle[];
    issues: ContractIssue[];
    selectedPropertiesTemplate: SummaryPanelTemplateOut;
    propertyTemplatesLoaded: boolean;
    paragraphs: IContractParagraph[];
    properties: IContractProperty[];
    selectedFilterPreset: keyof typeof PresetIssueFilters | '';
    lastValidPreset: keyof typeof PresetIssueFilters;
    selectedVersion: IContractVersion | null;
    sidebarWidth: number;
    sortModel: GridSortModel;
    summaryProperties: ISummaryProperty[];
    userId: string;
    streamId: string;
    selectedPropertySuggestionMap: SelectedPropertySuggestionMap;
}

export interface IIssuesReportReducer {
    columns: IIssuesTableColumn[];
    downloadPdf: boolean;
    sortModel: GridSortModel;
    savedStatus: SubmitStatus;
    copyingTable: boolean;
    selectedPropertySuggestionMap: SelectedPropertySuggestionMap;
}

export interface IIssuesReportDispatchProps {
    showDialog: (
        title: string | JSX.Element,
        showCloseButton: boolean,
        component: JSX.Element,
        buttons: IModalDialogButton[],
        height: string,
        customClassname: string
    ) => void;
    setActiveFilters: (activeFilters: IIssueFiltersValues) => void;
    setContractProperties: (properties: IContractProperty[]) => void;
    setContractSidebarWidthInPixels: (widthPixels: number) => void;
    setDownloadPdf: (downloadPdf: boolean) => void;
    setIssues: (issue: ContractIssue[]) => void;
    setIssuesReportSavedStatus: (status: SubmitStatus) => void;
    setSelectedIssueFilters: (
        selectedFilterPreset: keyof typeof PresetIssueFilters
    ) => void;
    setSortModel: typeof setIssuesReportSortModelAction;
    setIssuesTableColumns: (columns: IIssuesTableColumn[]) => void;
    updateSummaryProperty: (summaryProperty: ISummaryProperty) => void;
    setSelectedPropertySuggestion: typeof setSelectedPropertySuggestionAction;
}

export interface ISetActiveIssuesTableColumnsAction extends Action {
    type: typeof SET_ISSUES_TABLE_COLUMNS;
    columns: IIssuesTableColumn[];
}

export interface ISetDownloadPdfAction extends Action {
    type: typeof SET_DOWNLOAD_PDF;
    downloadPdf: boolean;
}

export interface ISetIssuesReportSavedStatusAction extends Action {
    type: typeof SET_ISSUES_REPORT_SAVED_STATUS;
    status: SubmitStatus;
}

export interface ISetIssuesReportSortModelAction extends Action {
    type: typeof SET_ISSUES_REPORT_SORT_MODEL;
    sortModel: GridSortModel;
}

export interface ISetSelectedPropertySuggestionAction extends Action {
    type: typeof SET_SELECTED_PROPERTY_SUGGESTION;
    propertyId: string;
    suggestionText: string;
}

export interface IIssuesReportProps
    extends IIssuesReportDispatchProps,
        IIssuesReportStateProps,
        RouteComponentProps {}

export type IIssuesReportActionTypes =
    | ISetActiveIssuesTableColumnsAction
    | ISetDownloadPdfAction
    | ISetIssuesReportSavedStatusAction
    | ISetIssuesReportSortModelAction
    | ISetSelectedPropertySuggestionAction;
