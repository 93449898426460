import { IRootState } from '../../../redux/reducers-types';
import { reduxStore } from '../../../redux/store';
import { analyticsService } from '../../../services/Analytics/Analytics';
import { EVENT_ACTION_EDIT_ISSUES_REPORT_COLUMN } from '../../../services/Analytics/Analytics-types';
import { snakeCaseToLabel } from '../../App/App-helpers';
import { IIssuesReportEditColumnsModalState } from './IssuesReportEditColumnsModal-types';

export function analytics() {
    return (target: any) => {
        const handleSaveChanges = target.prototype.handleSaveChanges;

        target.prototype.handleSaveChanges = function () {
            const { columns = [] } = this
                .state as IIssuesReportEditColumnsModalState;
            const {
                contract: { contract, selectedVersion },
            } = reduxStore.store.getState() as IRootState;

            const activeColumnNames: string[] = [];

            for (const column of columns) {
                if (column.active) {
                    activeColumnNames.push(snakeCaseToLabel(column.label));
                }
            }

            analyticsService.recordEvent(
                EVENT_ACTION_EDIT_ISSUES_REPORT_COLUMN,

                {
                    ContractId: contract?.id,
                    Stream: contract?.streamCode,
                    VersionId: selectedVersion?.id,
                    VersionNumber: selectedVersion?.versionNumber,
                    'Column Names': activeColumnNames,
                }
            );

            handleSaveChanges.apply(this, arguments);
        };
    };
}
