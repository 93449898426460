import { Badge } from '@thought-river/ui-components';
import {
    ContractIntelligenceType,
    ISelectedContractIntelligence,
} from '../../Contract/Contract-types';
import { ISummaryProperty } from '../../SummaryPane/SummaryPane-types';
import {
    BannerClauseType,
    BannerType,
    IBannerSettings,
} from '../HeatmapPane-types';
import {
    FamiliarityLevel,
    IssueSeverityLevel,
    getFamiliarityLevelBadgeType,
    getFamiliarityLevelLabel,
    getIssueSeverityLevelBadgeType,
    getIssueSeverityLevelLabel,
} from '@thought-river/negotiations-common';

export const getBannerTitles = (
    bannerSettings: IBannerSettings,
    selectedContractIntelligence: ISelectedContractIntelligence,
    summaryProperties: ISummaryProperty[],
    paragraphIds: string[],
    selectedPropertyShortLabel?: string
) => {
    const bannerType = bannerSettings.type;

    if (bannerType === BannerType.WARNING) {
        return {
            xs: 'Links removed',
            sm: 'All links removed',
            md: 'All linked clauses removed',
            lg: `All linked clauses have been removed`,
            xl: `All clauses linked to ${selectedPropertyShortLabel} have been removed`,
            max: `All clauses linked to ${selectedPropertyShortLabel} have been automatically removed`,
        };
    }

    if (bannerType === BannerType.REPROCESS_REQUIRED) {
        const totalPropertiesToReprocess = summaryProperties.filter(
            (property) => property.reprocessRequired
        ).length;

        return {
            xs: `Ask ${totalPropertiesToReprocess} questions`,
            sm: `Ask ${totalPropertiesToReprocess} questions`,
            md: `Ask ${totalPropertiesToReprocess} Lexible questions`,
            lg: `${totalPropertiesToReprocess} Lexible questions need asking of the contract`,
            xl: `${totalPropertiesToReprocess} Lexible questions need asking of the contract`,
            max: `${totalPropertiesToReprocess} Lexible questions need asking of the contract`,
        };
    }

    const highlightType = selectedContractIntelligence.highlightType;
    if (bannerType === BannerType.INFO && highlightType) {
        const icon =
            selectedContractIntelligence.type ===
            ContractIntelligenceType.RISK ? (
                <Badge
                    type={getIssueSeverityLevelBadgeType(
                        highlightType as IssueSeverityLevel
                    )}
                    label={getIssueSeverityLevelLabel(
                        highlightType as IssueSeverityLevel
                    )}
                />
            ) : (
                <Badge
                    type={getFamiliarityLevelBadgeType(
                        highlightType as FamiliarityLevel
                    )}
                    label={getFamiliarityLevelLabel(
                        highlightType as FamiliarityLevel
                    )}
                />
            );

        const idsCount = paragraphIds?.length;

        return {
            xs: <div className="banner-title-text">{icon} clauses</div>,
            sm: (
                <div className="banner-title-text">
                    {idsCount} {icon} clauses
                </div>
            ),
            md: (
                <div className="banner-title-text">
                    {idsCount} clauses are {icon}
                </div>
            ),
            lg: (
                <div className="banner-title-text">
                    {idsCount} clauses with a{' '}
                    {selectedContractIntelligence.type} level of {icon}
                </div>
            ),
            xl: (
                <div className="banner-title-text">
                    There are {idsCount} clauses with a{' '}
                    {selectedContractIntelligence.type} level of {icon}
                </div>
            ),
            max: (
                <div className="banner-title-text">
                    There are {idsCount} clauses with a{' '}
                    {selectedContractIntelligence.type} level of {icon}
                </div>
            ),
        };
    }

    const idsCount = bannerSettings.items?.length;

    // @TODO: These have been updated to show even if there's no property (e.g. when issue without a property is selected)
    // Need to find out if we can show `This issue has n related clauses` copy
    if (bannerSettings.clauseType === BannerClauseType.PROPERTY_RELATED) {
        return {
            xs: `Related clause${idsCount === 1 ? '' : 's'}`,
            sm: `${idsCount} related clause${idsCount === 1 ? '' : 's'}`,
            md: `${idsCount} related clause${idsCount === 1 ? '' : 's'}`,
            lg: `${idsCount} related clause${idsCount === 1 ? '' : 's'}`,
            xl: `${selectedPropertyShortLabel ?? 'This issue'} has ${
                idsCount ?? 0
            } related clause${idsCount === 1 ? '' : 's'}`,
            max: `${selectedPropertyShortLabel ?? 'This issue'} has ${
                idsCount ?? 0
            } related clause${idsCount === 1 ? '' : 's'}`,
        };
    } else {
        return {
            xs: `Linked clause${idsCount === 1 ? '' : 's'}`,
            sm: `${idsCount} linked clause${idsCount === 1 ? '' : 's'}`,
            md: `${idsCount} linked clause${idsCount === 1 ? '' : 's'}`,
            lg: `${idsCount} linked clause${idsCount === 1 ? '' : 's'}`,
            xl: `${selectedPropertyShortLabel ?? 'This issue'} has ${
                idsCount ?? 0
            } linked clause${idsCount === 1 ? '' : 's'}`,
            max: `${selectedPropertyShortLabel ?? 'This issue'} has ${
                idsCount ?? 0
            } linked clause${idsCount === 1 ? '' : 's'}`,
        };
    }
};
