import { GridSortModel } from '@mui/x-data-grid';
import { SubmitStatus } from '../App/App-types';

import {
    IIssuesTableColumn,
    ISetActiveIssuesTableColumnsAction,
    ISetDownloadPdfAction,
    ISetIssuesReportSavedStatusAction,
    ISetIssuesReportSortModelAction,
    ISetSelectedPropertySuggestionAction,
    SET_DOWNLOAD_PDF,
    SET_ISSUES_REPORT_SAVED_STATUS,
    SET_ISSUES_REPORT_SORT_MODEL,
    SET_ISSUES_TABLE_COLUMNS,
    SET_SELECTED_PROPERTY_SUGGESTION,
} from './IssuesReport-types';

export function setIssuesTableColumnsAction(
    columns: IIssuesTableColumn[]
): ISetActiveIssuesTableColumnsAction {
    return {
        type: SET_ISSUES_TABLE_COLUMNS,
        columns,
    };
}

export function setDownloadPdfAction(
    downloadPdf: boolean
): ISetDownloadPdfAction {
    return {
        type: SET_DOWNLOAD_PDF,
        downloadPdf,
    };
}

export function setIssuesReportSavedStatusAction(
    status: SubmitStatus
): ISetIssuesReportSavedStatusAction {
    return {
        type: SET_ISSUES_REPORT_SAVED_STATUS,
        status,
    };
}

export function setIssuesReportSortModelAction(
    sortModel: GridSortModel
): ISetIssuesReportSortModelAction {
    return {
        type: SET_ISSUES_REPORT_SORT_MODEL,
        sortModel,
    };
}

export function setSelectedPropertySuggestionAction(
    propertyId: string,
    suggestionText: string
): ISetSelectedPropertySuggestionAction {
    return {
        type: SET_SELECTED_PROPERTY_SUGGESTION,
        propertyId,
        suggestionText,
    };
}
