import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import Auth from './Auth';
import { IAuthDispatchProps, IAuthStateProps } from './Auth-types';
import { IRootState } from '../../redux/reducers-types';
import {
    setApiTokenAction,
    setApiUrlAction,
    setAuthDetailsAction,
    setAuthMetadataAction,
    setTokenExpiryAction,
    setSelectedStreamAction,
    clearLoginDetailsAction,
    setLogoutAction,
    setBookmarkAction,
    setLoggingOutAction,
    setLoginSuccessAction,
} from './Auth-actions';
import { IReviewStream } from '../../resources/AuthResource-types';
import { setPropertyDetailsLoginCountAction } from '../App/App-actions';

function mapStateToProps(state: IRootState): IAuthStateProps {
    return {
        accountCode: state.auth.accountCode,
        apiToken: state.auth.apiToken,
        audience: state.auth.audience,
        authUrl: state.auth.authUrl,
        bookmark: state.auth.bookmark,
        clientId: state.auth.clientId,
        featureToggles: state.featureToggles.toggles,
        loginSuccess: state.auth.loginSuccess,
        logout: state.auth.logout,
        propertyDetailsLoginCount: state.app.propertyDetailsLoginCount,
        redirectUrl: state.auth.redirectUrl,
        tokenExpiry: state.auth.tokenExpiry,
        userId: state.auth.userId,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): IAuthDispatchProps {
    return {
        clearLoginDetails: () => dispatch(clearLoginDetailsAction()),

        setPropertyDetailsLoginCount: (count: number) =>
            dispatch(setPropertyDetailsLoginCountAction(count)),

        setApiUrl: (apiUrl: string) => dispatch(setApiUrlAction(apiUrl)),

        setApiToken: (apiToken: string) =>
            dispatch(setApiTokenAction(apiToken)),

        setAuthDetails: (...args) => dispatch(setAuthDetailsAction(...args)),

        setAuthMetadata: (...args) => dispatch(setAuthMetadataAction(...args)),

        setBookmark: (bookmark: string) =>
            dispatch(setBookmarkAction(bookmark)),

        setLoggingOut: (loggingOut: boolean) =>
            dispatch(setLoggingOutAction(loggingOut)),

        setLoginSuccess: (loginSuccess: boolean) =>
            dispatch(setLoginSuccessAction(loginSuccess)),

        setLogout: (logout: boolean) => dispatch(setLogoutAction(logout)),

        setSelectedStream: (selectedStream: IReviewStream) =>
            dispatch(setSelectedStreamAction(selectedStream)),

        setTokenExpiry: (tokenExpiry: string) =>
            dispatch(setTokenExpiryAction(tokenExpiry)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
