import { ContractsReport } from '@thought-river/negotiations-common/dist/api/contractContent';
import { ReportDefinitionOut } from '@thought-river/negotiations-common/dist/api/playbookManager';
import { ReportConfigChangeHandler } from '@modules/reports/portfolioReports';

//A unique token to use to join/split own parties and counter parties to help with filtering
//Filtering on lists doesn't work ootb so instead of rewriting filter operators to work on lists
//We are converting a list of strings to a string joined with this token so we can tokenize that string later on
export const PARTIES_STRING_SEPERATOR = '&&&&&';

export interface ReportDataGridProps {
    reportDefinition: ReportDefinitionOut;
    reportData: ContractsReport;
    onReprocessContract: (contractId: string) => void;
    onConfigChange: ReportConfigChangeHandler;
}
