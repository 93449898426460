import { CollapseList } from '@modules/common/components/CollapseList';
import { useGetDiagnosticsData } from './hooks/useGetDiagnosticsData';
import styles from './DiagnosticsPage.module.scss';
import { VersionIdsList } from './components/VersionIdsList';
import { UserInfoList } from './components/UserInfoList';
import { FilesList } from './components/FilesList';
import { ProcessingHistoryTable } from './components/ProcessingHistoryTable';
import { useParams } from 'react-router-dom';
import { SectionsOutline } from './components/SectionsOutline';
import FlagsList from './components/FlagsList';

const DiagnosticsPage = () => {
    const { contractUuid, versionUuid } = useParams<{
        contractUuid: string;
        versionUuid: string;
    }>();

    const {
        contractIds,
        files,
        processingHistory,
        usersInfo,
        sections,
        featureToggles,
    } = useGetDiagnosticsData(contractUuid, versionUuid);

    return (
        <div className={styles.wrapper}>
            <CollapseList
                title="Processing history"
                classes={{ title: styles.listTitle, root: styles.collapseList }}
            >
                <ProcessingHistoryTable
                    processingHistory={processingHistory || []}
                />
            </CollapseList>
            <CollapseList
                title="Sections outline"
                classes={{ title: styles.listTitle, root: styles.collapseList }}
            >
                <SectionsOutline sections={sections as string} />
            </CollapseList>
            <CollapseList
                title="Files"
                classes={{ title: styles.listTitle, root: styles.collapseList }}
            >
                <FilesList files={files || []} versionUuid={versionUuid} />
            </CollapseList>
            <CollapseList
                title="Contract IDs"
                classes={{ title: styles.listTitle, root: styles.collapseList }}
            >
                <VersionIdsList
                    contractIds={contractIds || []}
                    versionUuid={versionUuid}
                />
            </CollapseList>
            <CollapseList
                title="User info"
                classes={{ title: styles.listTitle, root: styles.collapseList }}
            >
                <UserInfoList usersInfo={usersInfo || []} />
            </CollapseList>
            <CollapseList
                title="Flags info"
                classes={{ title: styles.listTitle, root: styles.collapseList }}
            >
                <FlagsList featureToggles={featureToggles} />
            </CollapseList>
        </div>
    );
};

export default DiagnosticsPage;
