import { CircularProgress } from '@mui/material';
import {
    queryClient,
    ProcessingStepsList,
} from '@thought-river/negotiations-common';
import {
    ContractTree,
    ContractTreeRow,
    ProcessingStep,
} from '@thought-river/negotiations-common/dist/api/contractManagement';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';

import styles from './ContractProcessingSteps.module.scss';
import {
    getContractRow,
    getHasPartyDetectionStarted,
    getRelevantProcessingSteps,
} from './helpers';

// Specifies how long it should wait before invoking the callback
const NOTIFY_DELAY = 4500;

interface ContractProcessingStepsProps {
    contractId: string;
    onAwaitingPartyConfirmation: () => void;
}

const ContractProcessingSteps = ({
    contractId,
    onAwaitingPartyConfirmation,
}: ContractProcessingStepsProps) => {
    const [processingSteps, setProcessingSteps] = useState<ProcessingStep[]>(
        []
    );
    const [notifyTimeout, setNotifyTimeout] =
        useState<ReturnType<typeof setTimeout>>();

    const handlePartyDetectionStarted = useCallback(() => {
        setNotifyTimeout(setTimeout(onAwaitingPartyConfirmation, NOTIFY_DELAY));
    }, [onAwaitingPartyConfirmation]);

    const handleContractRowUpdate = useCallback(
        (contractRow?: ContractTreeRow) => {
            if (!contractRow) {
                return;
            }

            const processingSteps = getRelevantProcessingSteps(contractRow);
            setProcessingSteps(processingSteps);

            const hasPartyDetectionStarted =
                getHasPartyDetectionStarted(contractRow);

            if (hasPartyDetectionStarted) {
                handlePartyDetectionStarted();
                return;
            }
        },
        [handlePartyDetectionStarted]
    );

    useEffect(() => {
        if (notifyTimeout) {
            return;
        }

        const queryData = queryClient.getQueryData<ContractTree>(['contracts']);
        const contractRow = getContractRow(contractId, queryData);

        handleContractRowUpdate(contractRow);

        const unsubscribe = queryClient.getQueryCache().subscribe((event) => {
            if (
                event.type === 'updated' &&
                event.action.type === 'success' &&
                isEqual(event.query.queryKey, ['contracts'])
            ) {
                const contractRow = getContractRow(
                    contractId,
                    event.action.data
                );

                handleContractRowUpdate(contractRow);
            }
        });

        return () => {
            unsubscribe();
        };
    }, [contractId, handleContractRowUpdate, notifyTimeout]);

    useEffect(() => {
        return () => {
            clearTimeout(notifyTimeout);
        };
    }, [notifyTimeout]);

    if (!processingSteps.length) {
        return <CircularProgress className={styles.loading} />;
    }

    return <ProcessingStepsList steps={processingSteps} showDescriptions />;
};

export default ContractProcessingSteps;
