import {
    ISetPartyContractWidthInPixelsAction,
    ISetPartyParagraphsAction,
    SET_PARTY_CONTRACT_WIDTH_IN_PIXELS,
    SET_PARTY_PARAGRAPHS,
} from './PartyContract-types';
import { IContractParagraph } from '../Paragraph/Paragraph-types';

export function setPartyParagraphsAction(
    paragraphs: IContractParagraph[]
): ISetPartyParagraphsAction {
    return {
        type: SET_PARTY_PARAGRAPHS,
        paragraphs,
    };
}
export function setPartyContracWidthInPixelsAction(
    widthPixels: number
): ISetPartyContractWidthInPixelsAction {
    return {
        type: SET_PARTY_CONTRACT_WIDTH_IN_PIXELS,
        widthPixels,
    };
}
