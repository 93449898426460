import { Dispatch } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { IRootState } from '../../../../redux/reducers-types';
import { setIssueFormAction } from '../../IssuePane-actions';
import { IIssueForm } from '../../IssuePane-types';

import IssuePaneDescriptionTab from './IssuePaneDescriptionTab';
import {
    IIssuePaneDescriptionTabDispatchProps,
    IIssuePaneDescriptionTabStateProps,
} from './IssuePaneDescriptionTab-types';

function mapStateToProps(
    state: IRootState
): IIssuePaneDescriptionTabStateProps {
    return {
        categories: state.app.categories,
        featureToggles: state.featureToggles.toggles,
        form: state.issuePane.form,
        issue: state.contract.selectedIssue,
        paragraphs: state.contract.paragraphs,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IIssuePaneDescriptionTabDispatchProps {
    return {
        setIssueForm: (form: IIssueForm) => dispatch(setIssueFormAction(form)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IssuePaneDescriptionTab);
