import { Action } from 'redux';

import { IContractVersion, IDisplayContract } from '../Contract/Contract-types';
import { RouteComponentProps } from 'react-router';
import { IModalDialogButton } from '../ModalDialog/ModalDialog-types';
import { ISetBulkActionsAction } from '../ContractListToolbar/ContractListToolbar-types';
import { IVersionData } from 'types/thoughtriver';
import {
    IProcessingContract,
    IReprocessingContract,
} from '../ContractPollingProvider/ContractPollingProvider-types';
import { IFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider-types';

export const APPEND_CONTRACTS = 'APPEND_CONTRACTS';
export const UPDATE_CONTRACT_IN_LIST = 'UPDATE_CONTRACT_IN_LIST';
export const SET_CONTRACT_VERSIONS = 'SET_CONTRACT_VERSIONS';
export const SET_REQUESTED_PAGES = 'SET_REQUESTED_PAGES';
export const SET_SHOW_LOADER = 'SET_SHOW_LOADER';
export const PREPEND_CONTRACT = 'PREPEND_CONTRACT';
export const SET_CONTRACTS = 'SET_CONTRACTS';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_HAS_NEXT_PAGE = 'SET_HAS_NEXT_PAGE';
export const SET_SELECTED_CONTRACT_IDS = 'SET_SELECTED_CONTRACT_IDS';
export const SET_SELECT_ALL_CONTRACTS = 'SET_SELECT_ALL_CONTRACTS';
export const SET_FETCHING_CONTRACTS = 'SET_FETCHING_CONTRACTS';
export const PAGE_TITLE_CONTRACT_LIST = 'ThoughtRiver Negotiations - Dashboard';
export const NOTIFICATION_TYPE_SUCCESSFUL_PROCESSING =
    'NOTIFICATION_TYPE_SUCCESSFUL_PROCESSING';
export const NOTIFICATION_TYPE_SUCCESSFUL_REPROCESSING =
    'NOTIFICATION_TYPE_SUCCESSFUL_REPROCESSING';
export const NOTIFICATION_TYPE_FAILED_PROCESSING =
    'NOTIFICATION_TYPE_FAILED_PROCESSING';
export const NOTIFICATION_TYPE_FAILED_REPROCESSING =
    'NOTIFICATION_TYPE_FAILED_REPROCESSING';

export interface IGroupedVersion {
    [contractId: string]: IVersionData[];
}
export interface IContractListState {
    downloading: boolean;
}

export interface IContractListStateProps {
    contracts: IDisplayContract[];
    failedProcessedContracts: IProcessingContract[];
    failedReprocessedContracts: IReprocessingContract[];
    featureToggles: IFeatureToggle[];
    nextPage: number;
    showLoader: boolean;
    successfulProcessedContracts: IProcessingContract[];
    successfulReprocessedContracts: IReprocessingContract[];
    userId: string;
}

export interface IContractListReducer {
    contracts: IDisplayContract[];
    currentPage: number;
    hasNextPage: boolean;
    fetchingContracts: boolean;
    nextPage: number;
    polling: boolean;
    selectAll: boolean;
    selectedContractIds: string[];
    requestedPages: number[];
    showLoader: boolean;
}

export interface IContractListDispatchProps {
    showDialog: (
        title: string | JSX.Element,
        showCloseButton: boolean,
        component: JSX.Element,
        buttons: IModalDialogButton[],
        height: string | null | undefined,
        customClassname?: string,
        closeOnBlur?: boolean
    ) => void;
    setReprocessingContract: (
        contractId: string,
        contractName: string,
        versionId: string,
        stream: string,
        streamId: string,
        assessmentCount: number,
        userId: string
    ) => void;
    unsetNotifiedContract: (contractId: string) => void;
}

export interface IContractListProps
    extends IContractListDispatchProps,
        IContractListStateProps,
        RouteComponentProps {}

export interface ISetContractsAction extends Action {
    type: typeof SET_CONTRACTS;
    contracts: IDisplayContract[];
}

export interface IAppendContractsAction extends Action {
    type: typeof APPEND_CONTRACTS;
    contracts: IDisplayContract[];
}

export interface ISetContractVersionsAction extends Action {
    type: typeof SET_CONTRACT_VERSIONS;
    contractId: string;
    versions: IContractVersion[];
}

export interface IUpdateContractInListAction extends Action {
    type: typeof UPDATE_CONTRACT_IN_LIST;
    contractId: string;
    fields: Partial<IDisplayContract>;
}

export interface ISetCurrentPageAction extends Action {
    type: typeof SET_CURRENT_PAGE;
    currentPage: number;
}

export interface ISetRequestedPagesAction extends Action {
    type: typeof SET_REQUESTED_PAGES;
    requestedPages: number[];
}

export interface ISetShowLoaderAction extends Action {
    type: typeof SET_SHOW_LOADER;
    showLoader: boolean;
}

export interface IPrependContractAction extends Action {
    type: typeof PREPEND_CONTRACT;
    contract: IDisplayContract;
}

export interface ISetHasNextPageAction extends Action {
    type: typeof SET_HAS_NEXT_PAGE;
    hasNextPage: boolean;
}

export interface ISetSelectedContractIdsAction extends Action {
    type: typeof SET_SELECTED_CONTRACT_IDS;
    selectedContractIds: string[];
}

export interface ISetSelectAllContractsAction extends Action {
    type: typeof SET_SELECT_ALL_CONTRACTS;
    selectAll: boolean;
    selectableContractIds: string[];
}

export interface ISetFetchingContractsAction extends Action {
    type: typeof SET_FETCHING_CONTRACTS;
    fetchingContracts: boolean;
}

export type IContractListActionTypes =
    | ISetContractsAction
    | IAppendContractsAction
    | ISetContractVersionsAction
    | IUpdateContractInListAction
    | ISetRequestedPagesAction
    | ISetShowLoaderAction
    | IPrependContractAction
    | ISetCurrentPageAction
    | ISetHasNextPageAction
    | ISetSelectedContractIdsAction
    | ISetSelectAllContractsAction
    | ISetBulkActionsAction
    | ISetFetchingContractsAction;
