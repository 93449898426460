import { Action } from 'redux';

export const SHOW_DIALOG = 'SHOW_DIALOG';
export const HIDE_DIALOG = 'HIDE_DIALOG';
export const UPDATE_BUTTONS = 'UPDATE_BUTTONS';
export const UPDATE_AUTO_CLOSE = 'UPDATE_AUTO_CLOSE';
export const DEFAULT_MODAL_WIDTH = '90%';

export interface IModalDialogState {}

export interface IModalDialogStateProps {
    autoClose: boolean;
    buttons: IModalDialogButton[];
    closeOnBlur?: boolean;
    component: JSX.Element;
    customClassname?: string;
    height: string | null | undefined;
    showCloseButton: boolean;
    title: string | JSX.Element;
    visible: boolean;
}

export interface IModalDialogReducer extends IModalDialogStateProps {}

export interface IModalDialogDispatchProps {
    showDialog: (
        title: string | JSX.Element,
        showCloseButton: boolean,
        component: JSX.Element,
        buttons: IModalDialogButton[],
        height?: string | null | undefined
    ) => void;
    updateButtons: (buttons: IModalDialogButton[]) => void;
    updateAutoClose: (autoClose: boolean) => void;
    hideDialog: () => void;
}

export interface IModalDialogProps
    extends IModalDialogDispatchProps,
        IModalDialogStateProps {}

export interface IShowDialogAction extends Action {
    type: typeof SHOW_DIALOG;

    title: string | JSX.Element;
    showCloseButton: boolean;
    buttons: IModalDialogButton[];
    component: JSX.Element;
    height: string | null | undefined;
    customClassname: string;
    closeOnBlur: boolean;
}

export interface IUpdateButtonsAction extends Action {
    type: typeof UPDATE_BUTTONS;
    buttons: IModalDialogButton[];
}

export interface IHideDialogAction extends Action {
    type: typeof HIDE_DIALOG;
}

export interface IUpdateAutoCloseAction extends Action {
    type: typeof UPDATE_AUTO_CLOSE;
    autoClose: boolean;
}

export interface IModalDialogButton<T = any> {
    label: string | JSX.Element;
    type: ModalDialogButtonType;
    enabled: boolean;

    callback?: (button: IModalDialogButton<T>) => void;
    data?: T;
}

export enum ModalDialogButtonType {
    Positive,
    Neutral,
    Negative,
}

export type IModalDialogActionTypes =
    | IShowDialogAction
    | IHideDialogAction
    | IUpdateButtonsAction
    | IUpdateAutoCloseAction;
