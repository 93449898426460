import './RtfLogo.scss';
import { InsertDriveFile } from '@mui/icons-material';

const RtfLogo = (): JSX.Element => (
    <div className="rtf-logo">
        <InsertDriveFile className="file-icon" />
        <div className="file-type-label">RTF</div>
    </div>
);

export default RtfLogo;
