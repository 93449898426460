import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IDocumentViewerProps } from './types';
import { HighlightIndexType } from '../../../../components/Contract/Contract-types';
import htmlReactParser, {
    Element as HTMLReactParserElement,
    domToReact,
    attributesToProps,
} from 'html-react-parser';
import DocumentClause from './DocumentClause';
import { useDispatch } from 'react-redux';
import HeatmapPaneBanner from '../../../../components/HeatmapPane/HeatmapPaneBanner/HeatmapPaneBanner';
import {
    setHighlightIndexAction,
    setSelectedParagraphAction,
} from '../../../../components/Contract/Contract-actions';
import { loadGoogleFonts } from './helpers';
import { useAppSelector } from '@modules/common/hooks';
import { useContractSearchContext } from '@modules/common/context/ContractSearchProvider';
import DocumentContents from './DocumentContents';
import { useClausesOffsets } from './hooks/useClausesOffsets';
import classNames from 'classnames';

import styles from './DocumentViewer.module.scss';

const DocumentViewer = ({
    documentHTML,
    onReprocess,
    inSidebar,
    bannerActionButtons,
}: IDocumentViewerProps) => {
    const dispatch = useDispatch();

    const bannerSettings = useAppSelector(
        (state) => state.heatmapPane.bannerSettings
    );
    const selectedParagraph = useAppSelector(
        (state) => state.contract.selectedParagraph
    );
    const contract = useAppSelector((state) => state.contract.contract);
    const paragraphs = useAppSelector((state) => state.contract.paragraphs);

    const [processedDocumentHTML, setProcessedDocumentHTML] = useState<
        string | null
    >(null);
    const [selectionStartIndex, setSelectionStartIndex] = useState<number>(-1);
    // We need a separate state for a paragraph to scroll to, since we might want to scroll
    // to the currently selected paragraph again (and the useEffect would not fire)
    const [scrollToParagraphId, setScrollToParagraphId] = useState<
        string | null
    >(null);

    const documentPageRef = useRef<HTMLDivElement>(null);
    const documentWrapperRef = useRef<HTMLDivElement>(null);

    const { registerContentElement } = useContractSearchContext();
    const { registerClauseElement, offsets, clausesRef } =
        useClausesOffsets(documentPageRef);

    // Process the document HTML
    useEffect(() => {
        const parser = new DOMParser();
        const styleSheet = new CSSStyleSheet();
        const parsedHtml = parser.parseFromString(documentHTML, 'text/html');
        const documentBody = parsedHtml.querySelector('body');

        for (const style of [
            ...parsedHtml.getElementsByTagName('style'),
        ].reverse()) {
            if (!style.outerText.includes('body')) {
                styleSheet.insertRule(style.outerText);
            }
        }

        if (document.adoptedStyleSheets) {
            document.adoptedStyleSheets.push(styleSheet);
        }

        // The only links we expect to see in the output are the ones to Google Fonts
        // We want to add them to the page's head
        const fontLinks = parsedHtml.querySelectorAll('link');
        const listenersCleanup = loadGoogleFonts(fontLinks, contract);

        setProcessedDocumentHTML(documentBody?.innerHTML ?? '');

        return listenersCleanup;
    }, [contract, documentHTML]);

    const resetSelectedParagraph = useCallback(() => {
        dispatch(setSelectedParagraphAction(null));
        dispatch(setHighlightIndexAction(-1, HighlightIndexType.START));
        dispatch(setHighlightIndexAction(-1, HighlightIndexType.END));
    }, [dispatch]);

    // Reset selected paragraph when component unmounts
    useEffect(
        () => () => {
            resetSelectedParagraph();
        },
        [resetSelectedParagraph]
    );

    const handleSelectionStart = (startIndex: number) => {
        setSelectionStartIndex(startIndex);
    };

    const handleSelectionEnd = useCallback(
        (endIndex: number) => {
            if (inSidebar) {
                return;
            }

            const resolvedStartIndex = Math.min(selectionStartIndex, endIndex);
            const resolvedEndIndex = Math.max(selectionStartIndex, endIndex);

            const contractParagraph = paragraphs.find(
                (p) => p.location === resolvedStartIndex
            );
            if (!contractParagraph) {
                return;
            }

            const selectedCount = resolvedEndIndex - resolvedStartIndex + 1;

            if (
                selectedCount === 1 &&
                contractParagraph.id === selectedParagraph?.id
            ) {
                // The selected paragraph was clicked again, reset selection
                resetSelectedParagraph();
            } else {
                dispatch(setSelectedParagraphAction(contractParagraph));
                dispatch(
                    setHighlightIndexAction(
                        resolvedStartIndex - 1,
                        HighlightIndexType.START
                    )
                );
                dispatch(
                    setHighlightIndexAction(
                        resolvedEndIndex - 1,
                        HighlightIndexType.END
                    )
                );
            }
        },
        [
            dispatch,
            inSidebar,
            paragraphs,
            resetSelectedParagraph,
            selectedParagraph?.id,
            selectionStartIndex,
        ]
    );

    const handleSelectParagraph = useCallback(
        (paragraphId: string) => {
            const selectedParagraph = paragraphs.find(
                (paragraph) => paragraph.id === paragraphId
            );

            if (selectedParagraph) {
                dispatch(
                    setHighlightIndexAction(
                        selectedParagraph.index,
                        HighlightIndexType.START
                    )
                );
                dispatch(
                    setHighlightIndexAction(
                        selectedParagraph.index,
                        HighlightIndexType.END
                    )
                );
                dispatch(setSelectedParagraphAction(selectedParagraph));
            } else {
                resetSelectedParagraph();
            }
        },
        [dispatch, paragraphs, resetSelectedParagraph]
    );

    // Callback called when paragraphs are navigated between using Banner
    const handleNavigation = (paragraphId: string) => {
        if (paragraphId === selectedParagraph?.id) {
            setScrollToParagraphId(paragraphId);
        }
    };

    const handleScrollToParagraphDone = useCallback(
        (paragraphId: string) => {
            if (paragraphId === scrollToParagraphId) {
                setScrollToParagraphId(null);
            }
        },
        [scrollToParagraphId]
    );

    const handleWrapperClick = (event: React.MouseEvent) => {
        // Do nothing if it was the document page that was clicked
        if (
            !documentPageRef.current ||
            documentPageRef.current.contains(event.target as Node)
        ) {
            return;
        }

        resetSelectedParagraph();
    };

    // Processed document HTML parsed to a React element
    const documentContent = useMemo(() => {
        if (!processedDocumentHTML) {
            return null;
        }

        return htmlReactParser(processedDocumentHTML, {
            replace: (domNode) => {
                // Check to satisfy TS type checking
                if (!(domNode instanceof HTMLReactParserElement)) {
                    return;
                }

                const paragraphIndex = domNode.attribs['data-ppl-index']
                    ? Number(domNode.attribs['data-ppl-index'])
                    : undefined;

                if (paragraphIndex !== undefined) {
                    return (
                        <DocumentClause
                            {...attributesToProps(domNode.attribs)}
                            tagName={domNode.name}
                            onSelectionStart={handleSelectionStart}
                            onSelectionEnd={handleSelectionEnd}
                            paragraphs={paragraphs}
                            documentPageRef={documentPageRef}
                            inSidebar={inSidebar}
                            scrollToParagraphId={scrollToParagraphId}
                            onScrollToParagraphEnd={handleScrollToParagraphDone}
                            ref={registerClauseElement(paragraphIndex)}
                            offsets={offsets.get(paragraphIndex)}
                        >
                            {domToReact(domNode.children)}
                        </DocumentClause>
                    );
                }
            },
        });
    }, [
        processedDocumentHTML,
        handleSelectionEnd,
        paragraphs,
        inSidebar,
        scrollToParagraphId,
        handleScrollToParagraphDone,
        registerClauseElement,
        offsets,
    ]);

    return (
        <div className={styles.container}>
            {bannerSettings && (
                <HeatmapPaneBanner
                    onReprocess={onReprocess}
                    selectParagraph={handleSelectParagraph}
                    actionButtons={bannerActionButtons}
                    onNavigation={handleNavigation}
                    isPermanent={inSidebar}
                />
            )}
            <div className={styles.outlineContainer}>
                {!inSidebar && (
                    <DocumentContents
                        clausesRef={clausesRef}
                        documentWrapperRef={documentWrapperRef}
                        onSectionClick={(paragraphId) => {
                            setScrollToParagraphId(paragraphId);
                        }}
                    />
                )}
                <div
                    ref={documentWrapperRef}
                    className={classNames(
                        styles.documentWrapper,
                        inSidebar && styles.noPadding
                    )}
                    onClick={handleWrapperClick}
                >
                    <div className={styles.documentPage} ref={documentPageRef}>
                        <div
                            ref={(node) => {
                                if (node) {
                                    registerContentElement(node);
                                }
                            }}
                        >
                            {documentContent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DocumentViewer;
