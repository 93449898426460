import { ReportView } from '@modules/reports/contractReports/consts';
import { useSelector } from 'react-redux';
import { FEATURE_TOGGLE_GA_CONTRACT_REPORTS } from '../../../../../../components/FeatureToggleProvider/FeatureToggleProvider-types';
import { IRootState } from '../../../../../../redux/reducers-types';

export const useGetAvailableViews = (): {
    label: string;
    value: ReportView;
}[] => {
    const featureToggles = useSelector(
        (state: IRootState) => state.featureToggles.toggles
    );

    const gaReportsEnabled = featureToggles.find(
        (toggle) => toggle.feature === FEATURE_TOGGLE_GA_CONTRACT_REPORTS
    )?.enabled;

    return [
        //Commenting out other views as we don't need them for the initial release
        {
            label: 'All issues',
            value: 'all-issues',
        },
        // {
        //     label: 'All open issues',
        //     value: 'open-issues',
        // },
        // {
        //     label: 'All closed issues',
        //     value: 'closed-issues',
        // },
        // {
        //     label: 'Priority issues',
        //     value: 'priority-issues',
        // },
        // {
        //     label: 'Not detected issues',
        //     value: 'not-detected-issues',
        // },
        // {
        //     label: 'All lexible questions',
        //     value: 'lexible-questions',
        // },
        // {
        //     label: 'Customized checklist',
        //     value: 'customized-checklist',
        // },
        // {
        //     label: 'Commercial review',
        //     value: 'commercial-review',
        // },
        // {
        //     label: 'Counterparty response',
        //     value: 'counterparty-response',
        // },
        // {
        //     label: 'Cover sheet',
        //     value: 'cover-sheet',
        // },
        ...(gaReportsEnabled
            ? [
                  {
                      label: 'G&A Comments',
                      value: 'ga-comments' as ReportView,
                  },
              ]
            : []),
    ];
};
