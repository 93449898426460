import * as React from 'react';

import { ISearchBarProps, ISearchBarState } from './SearchBar-types';
import { Cancel, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Icon, Tooltip } from '@thought-river/ui-components';

class SearchBar extends React.Component<ISearchBarProps, ISearchBarState> {
    private readonly inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: ISearchBarProps) {
        super(props);

        this.state = {
            expanded: false,
        };

        this.inputRef = React.createRef();

        this.onToggleExpanded = this.onToggleExpanded.bind(this);
        this.onCloseSearchBar = this.onCloseSearchBar.bind(this);
    }

    async componentDidUpdate(_: ISearchBarProps, prevState: ISearchBarState) {
        const { searchTerm, onSearchTermChange } = this.props;

        const { expanded } = this.state;

        if (!prevState.expanded && expanded) {
            if (searchTerm) {
                onSearchTermChange('');
            }
            this.inputRef.current?.focus();
        }

        if (prevState.expanded && !expanded) {
            onSearchTermChange('');
        }
    }

    onToggleExpanded() {
        if (!this.state.expanded && this.props.disabled) {
            return;
        }

        this.setState({ expanded: !this.state.expanded });
    }

    onCloseSearchBar() {
        this.setState({ expanded: false });
    }

    render() {
        const { expanded } = this.state;

        const {
            searchTerm,
            showMatches,
            matchesCount,
            matchIndex,
            onSearchTermChange,
            onPreviousResult,
            onNextResult,
            placeholder,
            disabled,
            classes,
        } = this.props;

        const searchBarClasses =
            'search-bar' +
            ` ${showMatches ? 'show-matches' : ''}` +
            ` ${disabled ? 'disabled' : ''}` +
            ` ${classes ? classes : ''}`;

        return (
            <div className={searchBarClasses}>
                <span className="search-icon-wrapper">
                    <Tooltip
                        title="Search"
                        placement="bottom-start"
                        classes={{ popper: 'search-icon-tooltip-popper' }}
                    >
                        <Icon
                            type="search"
                            className="search-icon"
                            onClick={this.onToggleExpanded}
                        />
                    </Tooltip>
                </span>
                {expanded ? (
                    <div className="search-form">
                        <input
                            className="search-input form-control"
                            placeholder={placeholder ? placeholder : 'Search'}
                            ref={this.inputRef}
                            value={searchTerm}
                            onChange={(event) =>
                                onSearchTermChange(event.target.value)
                            }
                        />
                        <span className="search-controls">
                            {showMatches && matchesCount ? (
                                <span className="matches-wrapper">
                                    <span className="matches">
                                        {(matchIndex ?? 0) + 1}/{matchesCount}
                                    </span>
                                </span>
                            ) : null}

                            {showMatches ? (
                                <span className="search-nav-wrapper">
                                    <span className="search-nav-icon-wrapper">
                                        <ExpandLess
                                            className="search-nav-icon"
                                            onClick={onPreviousResult}
                                        />
                                    </span>
                                    <span className="search-nav-icon-wrapper">
                                        <ExpandMore
                                            className="search-nav-icon"
                                            onClick={onNextResult}
                                        />
                                    </span>
                                </span>
                            ) : null}

                            <Cancel
                                className="close-search-icon"
                                onClick={this.onCloseSearchBar}
                            />
                        </span>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default SearchBar;
