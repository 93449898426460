import * as React from 'react';
import { useGridApiContext } from '@mui/x-data-grid-pro';

import './DataGridTextInput.scss';
import { IDataGridTextInputProps } from './DataGridTextInput-types';
import RichTextEditor from '../../../RichTextEditor/RichTextEditor';
import { IContractReportRow } from '../HybridReportDataGrid-types';
import {
    commitCellChanges,
    goToNextCell,
} from '../HybridReportDataGrid-helpers';
import { TextField } from '@mui/material';

const DataGridTextInput = (props: IDataGridTextInputProps): JSX.Element => {
    const {
        attribute,
        cellParams: { id, value, field, row },
        multiline = false,
        placeholder = '',
        preventLinebreaks = false,
        enableRichText = false,
    } = props;
    const apiRef = useGridApiContext();

    const reportRow = value as IContractReportRow;

    let preventEdit = false;

    const handleValueChange = (event: React.ChangeEvent<any>) => {
        if (preventEdit) {
            return;
        }

        const newRow = { ...reportRow, [attribute]: event.target.value };

        apiRef.current.setEditCellValue({
            id,
            field,
            value: newRow,
        });
    };

    const onKeyDown = async (event: React.KeyboardEvent<any>) => {
        event.stopPropagation();

        if (event.key === 'Escape') {
            event.preventDefault();
            apiRef.current.setCellMode(id, field, 'view');
        }

        if (event.key === 'Tab') {
            event.preventDefault();

            goToNextCell(apiRef, props.cellParams);
            return;
        }

        if (event.key === 'Enter') {
            preventEdit = preventLinebreaks;
        }

        if (!event.ctrlKey && !event.shiftKey && event.key === 'Enter') {
            commitCellChanges(apiRef, props.cellParams);
        } else if (preventEdit) {
            preventEdit = false;
        }
    };

    if (enableRichText) {
        const rowElement = document.querySelector(`[data-id='${row.id}']`);
        // @ts-ignore
        const offsetTop = rowElement ? rowElement.offsetTop + 22 : 0;

        return (
            <RichTextEditor
                autoFocus={true}
                onBlur={() => {}}
                onChange={(value: string) => {
                    const event = {
                        target: {
                            value,
                        },
                    } as unknown as React.ChangeEvent<any>;
                    handleValueChange(event);
                }}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                reset={false}
                popoverOffsetLeft={apiRef.current.getScrollPosition()?.left}
                popoverOffsetTop={offsetTop}
                value={reportRow[attribute]}
            />
        );
    }

    return (
        <TextField
            autoFocus={true}
            className="data-grid-text-input"
            data-id={`data-grid-text-input-${field}`}
            fullWidth={true}
            onChange={handleValueChange}
            onKeyDown={onKeyDown}
            multiline={multiline}
            value={reportRow[attribute]}
            onFocus={({ currentTarget }: React.FocusEvent<any>) =>
                currentTarget.setSelectionRange(
                    currentTarget.value.length,
                    currentTarget.value.length
                )
            }
            InputProps={{
                disableUnderline: true,
            }}
        />
    );
};

export default DataGridTextInput;
