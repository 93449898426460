import { IRootState } from '../../../redux/reducers-types';
import { reduxStore } from '../../../redux/store';
import { analyticsService } from '../../../services/Analytics/Analytics';
import { EVENT_ACTION_FA_BANNER_NAVIGATION } from '../../../services/Analytics/Analytics-types';
import { ContractIntelligenceType } from '../../Contract/Contract-types';

export const trackBannerNavigation = () => {
    const state = reduxStore.store.getState() as IRootState;
    if (
        state.contract.selectedContractIntelligence.type ===
        ContractIntelligenceType.FAMILIARITY
    ) {
        analyticsService.recordEvent(
            EVENT_ACTION_FA_BANNER_NAVIGATION,

            {
                ContractId: state.contract.contract?.id,
                VersionId: state.contract.selectedVersion?.id,
                VersionNumber: state.contract.selectedVersion?.versionNumber,
            }
        );
    }
};
