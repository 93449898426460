export function animationProps(delay: number) {
    return {
        initial: { opacity: 0, scale: 0 },
        animate: { opacity: 1, scale: 1 },
        transition: {
            duration: 0.5,
            delay: delay,
            ease: [0, 0.71, 0.2, 1.01],
        },
    };
}
