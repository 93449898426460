import { Checkbox, Tooltip } from '@thought-river/ui-components';
import { ErrorOutline } from '@mui/icons-material';
import styles from './ContractMetadataColumnsList.module.scss';
import { useGetPossibleReportColumns } from '@thought-river/negotiations-common/dist/api/contractContent';
import { ReportColumn } from '@thought-river/negotiations-common/dist/api/contractContent';
import without from 'lodash/without';
import { ContractMetadataColumnsListProps } from './ContractMetadataColumnsList.types';
import {
    columnInformationTooltipTitle,
    getIsColumnHideable,
    reportColumnLabels,
} from '@modules/reports/portfolioReports';
import { CollapseList } from '@modules/common/components/CollapseList';
import { ColumnIcon } from '@modules/reports/common/components/ColumnIcon';

const ContractMetadataColumnsList = ({
    searchTerm,
    selectedColumns,
    onSelectedColumnsChange,
}: ContractMetadataColumnsListProps) => {
    const { data: availableColumns } = useGetPossibleReportColumns({});
    const sortedAvailableColumns = [...(availableColumns ?? [])].sort((a, b) =>
        reportColumnLabels[a].localeCompare(reportColumnLabels[b])
    );

    const filteredColumns = sortedAvailableColumns.filter((c) =>
        reportColumnLabels[c].toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSelectedColumnsOnChange = (
        column: ReportColumn | string,
        checked: boolean
    ) => {
        if (checked) {
            onSelectedColumnsChange([...selectedColumns, column]);
        } else {
            onSelectedColumnsChange([...without(selectedColumns, column)]);
        }
    };

    return (
        <CollapseList title="Contract metadata">
            <>
                {filteredColumns.map((column) => (
                    <Checkbox
                        className={styles.checkbox}
                        key={column}
                        checked={selectedColumns.includes(column)}
                        onChange={(_, checked) =>
                            handleSelectedColumnsOnChange(column, checked)
                        }
                        label={
                            <div className={styles.label}>
                                <ColumnIcon column={column} />
                                <div>{reportColumnLabels[column]}</div>
                                <Tooltip
                                    className={styles.informationTooltip}
                                    title={
                                        columnInformationTooltipTitle[column]
                                    }
                                >
                                    <ErrorOutline />
                                </Tooltip>
                            </div>
                        }
                        disabled={!getIsColumnHideable(column)}
                    />
                ))}
            </>
        </CollapseList>
    );
};

export default ContractMetadataColumnsList;
