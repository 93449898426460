import { Component } from 'react';

import {
    IToggleBarProps,
    IToggleBarState,
    ToggleElement,
} from './ToggleBar-types';

class ToggleBar extends Component<IToggleBarProps, IToggleBarState> {
    private static enabledClassName(element: ToggleElement) {
        return element.enabled ? 'enabled' : 'disabled';
    }

    render() {
        const { elements, elementClick } = this.props;

        return (
            <div className="toggle-bar">
                {elements.map((element) => (
                    <button
                        className={`toggle-bar-button ${ToggleBar.enabledClassName(
                            element
                        )}`}
                        key={element.id.toString()}
                        onClick={() => elementClick(element)}
                    >
                        {element.label}
                    </button>
                ))}
            </div>
        );
    }
}

export default ToggleBar;
