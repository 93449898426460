import { analyticsService } from '../../../../services/Analytics/Analytics';
import {
    EVENT_ACTION_COPY_ISSUES_TABLE,
    EVENT_ACTION_EXPORT_CONTRACT_REPORT,
} from '../../../../services/Analytics/Analytics-types';

export const useReportsAnalytics = (
    contractId: string,
    versionId: string,
    versionNumber: number,
    selectedColumns: string[]
) => {
    const trackCopyTableButton = () => {
        analyticsService.recordEvent(
            EVENT_ACTION_COPY_ISSUES_TABLE,

            {
                ContractId: contractId,
                VersionId: versionId,
                VersionNumber: versionNumber,
            }
        );
    };

    const trackReportExport = (exportOption: string) => {
        analyticsService.recordEvent(
            EVENT_ACTION_EXPORT_CONTRACT_REPORT,

            {
                ContractId: contractId,
                VersionId: versionId,
                VersionNumber: versionNumber,
                SelectedColumns: selectedColumns,
                Format: exportOption,
            }
        );
    };

    return {
        trackCopyTableButton,
        trackReportExport,
    };
};
