import { ColumnsListProps } from './ColumnsList.types';
import { Search } from '@thought-river/ui-components';
import styles from './ColumnsList.module.scss';
import { useState } from 'react';

const ColumnsList = ({ onSearchTermChanged, children }: ColumnsListProps) => {
    const [searchTerm, setSearchTerm] = useState('');

    return (
        <div className={styles.wrapper}>
            <Search
                alwaysExpanded
                value={searchTerm}
                onChange={(term) => {
                    setSearchTerm(term);
                    onSearchTermChanged(term);
                }}
                fullWidth
            />
            <div className={styles.lists}>{children}</div>
        </div>
    );
};

export default ColumnsList;
