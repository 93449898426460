import { useState, useEffect } from 'react';

import {
    DataGridPro as DataGrid,
    GridCellEditCommitParams,
    GridCellParams,
    GridColDef,
    GridColumnResizeParams,
    GridRenderCellParams,
    GridRenderEditCellParams,
    GridRowsProp,
    GridSortModel,
    GridValueGetterParams,
    GridValueSetterParams,
    useGridApiRef,
} from '@mui/x-data-grid-pro';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import DOMPurify from 'dompurify';

import {
    IContractReportRow,
    IHybridReportDataGridProps,
    IssuesTableHeader,
    SortOrder,
} from './HybridReportDataGrid-types';
import { hyphenateSnakeCase, isValidDate } from '../../App/App-helpers';
import {
    filterRow,
    sortByClauseAsc,
    sortByDescriptionAsc,
    sortByFamiliarityAsc,
    sortByLastUpdatedDateAsc,
    sortByNotesAsc,
    sortByPropertyAnswerAsc,
    sortByPropertyCodeAsc,
    sortByPropertyLabelAsc,
    sortByPropertyNameAsc,
    sortByPropertyQuestionAsc,
    sortByPropertyThemeAsc,
    sortBySeverityAsc,
    sortByStatusAsc,
    sortBySummaryAsc,
    sortBySuggestion,
} from './HybridReportDataGrid-helpers';
import './HybridReportDataGrid.scss';
import IssuesReportClause from '../IssuesReportClause/IssuesReportClause';
import EditStatusDropdown from './EditStatusDropdown/EditStatusDropdown';
import EditImportanceDropdown from './EditImportanceDropdown/EditImportanceDropdown';
import EditSummaryInput from './EditSummaryInput/EditSummaryInput';
import EditDescriptionInput from './EditDescriptionInput/EditDescriptionInput';
import EditLinkedClausesDropdown from './EditLinkedClausesDropdown/EditLinkedClausesDropdown';
import Voting from '../../Voting/Voting';
import DataGridPagination from '../../DataGridPagination/DataGridPagination';
import HybridReportDataGridToolbar from '../HybridReportDataGridToolbar/HybridReportDataGridToolbar';
import { PresetIssueFilters } from '../../IssueListFilters/IssueListFilters-types';
import { IssuesReportPropertyLabels } from '../IssuesReport-types';
import {
    EXTERNAL_COMMENTS,
    INTERNAL_GUIDANCE,
    ValueType,
} from '../../App/App-types';
import {
    DEFAULT_DATE_FORMAT,
    Icon,
    Tooltip,
} from '@thought-river/ui-components';
import dayjs from 'dayjs';
import {
    ContractIssue,
    IssueSeverityLevel,
    getIssueSeverityLevelIconType,
    getIssueStatusLabel,
    getIssueStatusIconType,
    capitalize,
    getFamiliarityLevelLabel,
    getFamiliarityLevelIconType,
    ParagraphClause,
} from '@thought-river/negotiations-common';
import { analyticsService } from '../../../services/Analytics/Analytics';
import { EVENT_ACTION_EDIT_LINKED_CLAUSES_TEMP } from '../../../services/Analytics/Analytics-types';

const HybridReportDataGrid = (props: IHybridReportDataGridProps) => {
    const apiRef = useGridApiRef();

    const {
        activeFilters,
        loading = false,
        onSelectClause,
        onSelectSuggestion,
        paragraphs,
        rows,
        contract,
        version,
    } = props;

    const issuesReportPadding = 40;
    const [expandedClausesRowIds, setExpandedClausesRowIds] = useState<
        string[]
    >([]);
    const [dataGridWidth, setDataGridWidth] = useState<number | null>(null);
    const [dataGridLeftScroll, setDataGridLeftScroll] = useState(0);

    useEffect(() => {
        apiRef.current.subscribeEvent(
            'cellModeChange',
            (_params, event) => {
                event.defaultMuiPrevented = true;
            },
            {
                isFirst: true,
            }
        );

        apiRef.current.subscribeEvent('rowsScroll', () => {
            setDataGridLeftScroll(apiRef.current.getScrollPosition().left);
        });

        window.addEventListener('resize', onWindowResize);
        setDataGridWidth(window.innerWidth - issuesReportPadding);

        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, [apiRef]);

    const getHeaders = (): GridColDef[] => {
        const { columnsOrderData, lastValidView } = props;

        const headers = [
            {
                editable: true,
                field: IssuesTableHeader.STATUS,
                headerName: 'Status',
                width: getColumnWidth(IssuesTableHeader.STATUS),
                renderCell: renderStatusCell,
                renderEditCell: (props: GridRenderEditCellParams) => (
                    <EditStatusDropdown {...props} />
                ),
                sortComparator: (
                    rowA: IContractReportRow,
                    rowB: IContractReportRow
                ) => sortByStatusAsc(rowA, rowB),
                valueGetter: (params: GridValueGetterParams) =>
                    params.row as IContractReportRow,
                valueSetter: (params: GridValueSetterParams) => ({
                    ...params.row,
                    status: (params.value as IContractReportRow).status,
                }),
            },
            {
                editable: true,
                field: IssuesTableHeader.IMPORTANCE,
                headerName: 'Importance',
                width: getColumnWidth(IssuesTableHeader.IMPORTANCE),
                renderCell: renderImportanceCell,
                renderEditCell: (props: GridRenderEditCellParams) => (
                    <EditImportanceDropdown {...props} />
                ),
                sortComparator: (
                    rowA: IContractReportRow,
                    rowB: IContractReportRow
                ) => sortBySeverityAsc(rowA, rowB),
                valueGetter: (params: GridValueGetterParams) =>
                    params.row as IContractReportRow,
                valueSetter: (params: GridValueSetterParams) => ({
                    ...params.row,
                    severityLevel: (params.value as IContractReportRow)
                        .severityLevel,
                }),
            },
            {
                editable: false,
                field: IssuesTableHeader.FAMILIARITY,
                headerName: 'Familiarity',
                width: getColumnWidth(IssuesTableHeader.FAMILIARITY),
                renderCell: renderFamiliarityCell,
                sortComparator: (
                    rowA: IContractReportRow,
                    rowB: IContractReportRow
                ) => sortByFamiliarityAsc(rowA, rowB),
                valueGetter: (params: GridValueGetterParams) =>
                    params.row as IContractReportRow,
            },
            {
                editable: true,
                field: IssuesTableHeader.LINKED_CLAUSES,
                headerName: 'Linked Clauses',
                width: getColumnWidth(IssuesTableHeader.LINKED_CLAUSES),
                renderCell: renderLinkedClausesCell,
                renderEditCell: (props: GridRenderEditCellParams) => {
                    const extendedProps = { ...props, paragraphs };
                    return <EditLinkedClausesDropdown {...extendedProps} />;
                },
                sortComparator: (
                    rowA: IContractReportRow,
                    rowB: IContractReportRow
                ) => sortByClauseAsc(rowA, rowB),
                valueGetter: (params: GridValueGetterParams) =>
                    params.row as IContractReportRow,
                valueSetter: (params: GridValueSetterParams) => ({
                    ...params.row,
                    linkedParagraphClauses: (params.value as IContractReportRow)
                        .linkedParagraphClauses,
                    paragraphOriginUuids: (params.value as IContractReportRow)
                        .paragraphOriginUuids,
                }),
            },
            {
                editable: false,
                field: IssuesTableHeader.LINKED_CLAUSES_TEXT,
                headerName: 'Linked Clauses Text',
                width: getColumnWidth(IssuesTableHeader.LINKED_CLAUSES_TEXT),
                renderCell: renderLinkedClausesTextCell,
                sortComparator: (
                    rowA: IContractReportRow,
                    rowB: IContractReportRow
                ) => sortByClauseAsc(rowA, rowB),
                valueGetter: (params: GridValueGetterParams) =>
                    params.row as IContractReportRow,
            },
            {
                editable: false,
                field: IssuesTableHeader.SUGGESTIONS,
                headerName: 'Drafting options',
                width: getColumnWidth(IssuesTableHeader.SUGGESTIONS),
                renderCell: renderSuggestionsCell,
                sortComparator: sortBySuggestion,
                valueGetter: (params: GridValueGetterParams) =>
                    params.row as IContractReportRow,
            },
            {
                editable: true,
                field: IssuesTableHeader.SUMMARY,
                headerName: 'Summary',
                width: getColumnWidth(IssuesTableHeader.SUMMARY),
                renderCell: (params: GridRenderCellParams) => params.row.title,
                renderEditCell: (props: GridRenderEditCellParams) => (
                    <EditSummaryInput {...props} />
                ),
                sortComparator: (
                    rowA: IContractReportRow,
                    rowB: IContractReportRow
                ) => sortBySummaryAsc(rowA, rowB),
                valueGetter: (params: GridValueGetterParams) =>
                    params.row as IContractReportRow,
                valueSetter: (params: GridValueSetterParams) => ({
                    ...params.row,
                    title: (params.value as IContractReportRow).title,
                }),
            },
            {
                editable: true,
                field: IssuesTableHeader.DESCRIPTION,
                headerName: INTERNAL_GUIDANCE,
                width: getColumnWidth(IssuesTableHeader.DESCRIPTION),
                renderCell: (params: GridRenderCellParams) => (
                    <div
                        className="rte-editor"
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(params.row.description),
                        }}
                    />
                ),
                renderEditCell: (props: GridRenderEditCellParams) => {
                    const extendedProps = {
                        ...props,
                        enableRichText: true,
                        type: 'description',
                        placeholder: INTERNAL_GUIDANCE,
                    };
                    return <EditDescriptionInput {...extendedProps} />;
                },
                sortComparator: (
                    rowA: IContractReportRow,
                    rowB: IContractReportRow
                ) => sortByDescriptionAsc(rowA, rowB),
                valueGetter: (params: GridValueGetterParams) =>
                    params.row as IContractReportRow,
                valueSetter: (params: GridValueSetterParams) => ({
                    ...params.row,
                    description: (params.value as IContractReportRow)
                        .description,
                }),
            },
            {
                editable: true,
                field: IssuesTableHeader.NOTES,
                headerName: EXTERNAL_COMMENTS,
                width: getColumnWidth(IssuesTableHeader.NOTES),
                renderCell: (params: GridRenderCellParams) => (
                    <div
                        className="rte-editor"
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(params.row.notes),
                        }}
                    />
                ),
                renderEditCell: (props: GridRenderEditCellParams) => {
                    const extendedProps = {
                        ...props,
                        enableRichText: true,
                        type: 'notes',
                        placeholder: EXTERNAL_COMMENTS,
                    };
                    return <EditDescriptionInput {...extendedProps} />;
                },
                sortComparator: (
                    rowA: IContractReportRow,
                    rowB: IContractReportRow
                ) => sortByNotesAsc(rowA, rowB),
                valueGetter: (params: GridValueGetterParams) =>
                    params.row as IContractReportRow,
                valueSetter: (params: GridValueSetterParams) => ({
                    ...params.row,
                    notes: (params.value as IContractReportRow).notes,
                }),
            },
            {
                field: IssuesTableHeader.LAST_UPDATED,
                headerName: 'Last Updated',
                width: getColumnWidth(IssuesTableHeader.LAST_UPDATED),
                renderCell: renderLastUpdatedCell,
                sortComparator: (
                    rowA: IContractReportRow,
                    rowB: IContractReportRow
                ) => sortByLastUpdatedDateAsc(rowA, rowB),
                valueGetter: (params: GridValueGetterParams) =>
                    params.row as IContractReportRow,
            },
            {
                field: IssuesTableHeader.PROPERTY_LABEL,
                headerName:
                    IssuesReportPropertyLabels[
                        IssuesTableHeader.PROPERTY_LABEL
                    ],
                width: getColumnWidth(IssuesTableHeader.PROPERTY_LABEL),
                renderCell: (params: GridRenderCellParams) =>
                    params.row.propertyLabel,
                sortComparator: (
                    rowA: IContractReportRow,
                    rowB: IContractReportRow
                ) => sortByPropertyLabelAsc(rowA, rowB),
                valueGetter: (params: GridValueGetterParams) =>
                    params.row as IContractReportRow,
            },
            {
                field: IssuesTableHeader.PROPERTY_NAME,
                headerName:
                    IssuesReportPropertyLabels[IssuesTableHeader.PROPERTY_NAME],
                width: getColumnWidth(IssuesTableHeader.PROPERTY_NAME),
                renderCell: (params: GridRenderCellParams) =>
                    params.row.propertyName,
                sortComparator: (
                    rowA: IContractReportRow,
                    rowB: IContractReportRow
                ) => sortByPropertyNameAsc(rowA, rowB),
                valueGetter: (params: GridValueGetterParams) =>
                    params.row as IContractReportRow,
            },
            {
                field: IssuesTableHeader.PROPERTY_CODE,
                headerName:
                    IssuesReportPropertyLabels[IssuesTableHeader.PROPERTY_CODE],
                width: getColumnWidth(IssuesTableHeader.PROPERTY_CODE),
                renderCell: (params: GridRenderCellParams) =>
                    params.row.propertyCode,
                sortComparator: (
                    rowA: IContractReportRow,
                    rowB: IContractReportRow
                ) => sortByPropertyCodeAsc(rowA, rowB),
                valueGetter: (params: GridValueGetterParams) =>
                    params.row as IContractReportRow,
            },
            {
                field: IssuesTableHeader.PROPERTY_QUESTION,
                headerName:
                    IssuesReportPropertyLabels[
                        IssuesTableHeader.PROPERTY_QUESTION
                    ],
                width: getColumnWidth(IssuesTableHeader.PROPERTY_QUESTION),
                renderCell: (params: GridRenderCellParams) =>
                    params.row.propertyQuestion,
                sortComparator: (
                    rowA: IContractReportRow,
                    rowB: IContractReportRow
                ) => sortByPropertyQuestionAsc(rowA, rowB),
                valueGetter: (params: GridValueGetterParams) =>
                    params.row as IContractReportRow,
            },
            {
                field: IssuesTableHeader.PROPERTY_ANSWER,
                headerName:
                    IssuesReportPropertyLabels[
                        IssuesTableHeader.PROPERTY_ANSWER
                    ],
                width: getColumnWidth(IssuesTableHeader.PROPERTY_ANSWER),
                renderCell: (params: GridRenderCellParams) => {
                    const row: IContractReportRow = params.row;
                    let answer = row.propertyValue;

                    if (answer && row.propertyValueType === ValueType.DATE) {
                        if (
                            // @ts-ignore
                            !isNaN(answer) &&
                            // @ts-ignore
                            dayjs.unix(answer).isValid()
                        ) {
                            answer = dayjs
                                // @ts-ignore
                                .unix(answer)
                                .format(DEFAULT_DATE_FORMAT);
                        } else if (isValidDate(answer)) {
                            answer = dayjs(answer).format(DEFAULT_DATE_FORMAT);
                        }
                    }

                    return answer || '-';
                },
                sortComparator: (
                    rowA: IContractReportRow,
                    rowB: IContractReportRow
                ) => sortByPropertyAnswerAsc(rowA, rowB),
                valueGetter: (params: GridValueGetterParams) =>
                    params.row as IContractReportRow,
            },
            {
                field: IssuesTableHeader.PROPERTY_THEME,
                headerName:
                    IssuesReportPropertyLabels[
                        IssuesTableHeader.PROPERTY_THEME
                    ],
                width: getColumnWidth(IssuesTableHeader.PROPERTY_THEME),
                renderCell: (params: GridRenderCellParams) =>
                    params.row.propertyTheme?.name || '-',
                sortComparator: (
                    rowA: IContractReportRow,
                    rowB: IContractReportRow
                ) => sortByPropertyThemeAsc(rowA, rowB, lastValidView),
                valueGetter: (params: GridValueGetterParams) =>
                    params.row as IContractReportRow,
            },
            {
                sortable: false,
                editable: false,
                field: IssuesTableHeader.USER_FEEDBACK,
                headerName: '',
                minWidth: 0,
                maxWidth: 0,
                width: 0,
                renderCell: renderUserFeedbackCell,
            },
        ];

        if (lastValidView === PresetIssueFilters.ContractSummary) {
            const [linkedCausesColumn] = headers.splice(2, 1);
            headers.push(linkedCausesColumn);
        }

        headers.sort(
            (headerA, headerB) =>
                (columnsOrderData[headerA.field] ?? 1) -
                (columnsOrderData[headerB.field] ?? 1)
        );

        return headers;
    };

    const getColumnWidth = (columnLabel: IssuesTableHeader): number => {
        const columnWidthPercentage =
            props.columns.find((column) => column.label === columnLabel)
                ?.width ?? 15; // Percentange

        const padding = 16;
        return (dataGridWidth! / 100) * columnWidthPercentage - padding; // Fixme: null checks
    };

    const handleCellClick = (params: GridCellParams) => {
        if (params.isEditable) {
            apiRef.current.setCellMode(params.id, params.field, 'edit');
        }
    };

    const onCellEditCommit = async (params: GridCellEditCommitParams) => {
        const { issues, rows } = props;

        const row = params.value as IContractReportRow;
        const prevIssue = issues.find((i) => i.id === row.issueId);
        const prevRow = rows.find((i) => i.rowId === row.rowId);
        let field = params.field;

        switch (params.field) {
            case IssuesTableHeader.SUMMARY:
                field = 'title';
                break;
            case IssuesTableHeader.LINKED_CLAUSES:
                field = 'linkedParagraphClauses';
                break;
            case IssuesTableHeader.IMPORTANCE:
                field = 'severityLevel';
                break;
            case IssuesTableHeader.STATUS:
                field = 'status';
                break;
            case IssuesTableHeader.DESCRIPTION:
                field = 'description';
                break;
            case IssuesTableHeader.NOTES:
                field = 'notes';
                break;
        }

        if (
            row[field] &&
            JSON.stringify(prevRow![field]) !== JSON.stringify(row[field]) // Fixme: null checks
        ) {
            let clausesUpdated = false;
            const entityPropertyValue: {
                value: string;
                answer: string;
            } = {
                value: null!, // Fixme: null checks
                answer: null!, // Fixme: null checks
            };

            if (params.field === IssuesTableHeader.LINKED_CLAUSES) {
                clausesUpdated = true;
            }

            if (row.issueId) {
                const updatedIssue = {
                    ...prevIssue,
                    [field]: row[field],
                } as ContractIssue;

                if (clausesUpdated) {
                    updatedIssue.clauseNumbers = row.linkedParagraphClauses;
                }

                props.onSaveIssue(updatedIssue);
            }

            if (row.propertyCode && clausesUpdated) {
                props.onSaveProperty(
                    prevRow!, // Fixme: null checks
                    row,
                    entityPropertyValue.value,
                    entityPropertyValue.answer
                );

                analyticsService.recordEvent(
                    EVENT_ACTION_EDIT_LINKED_CLAUSES_TEMP,

                    {
                        ContractId: contract.id,
                        VersionId: version.id,
                        VersionNumber: version.versionNumber,
                    }
                );
            }
        }
    };

    const onSortChange = (sortModel: GridSortModel) => {
        props.onSortChange(sortModel);
    };

    const onWindowResize = () => {
        setDataGridWidth(window.innerWidth - issuesReportPadding);
    };

    const renderFamiliarityCell = (params: GridRenderCellParams) => {
        const familiarity = params.row.familiarity;

        return familiarity ? (
            <div className="familiarity-cell">
                <Icon type={getFamiliarityLevelIconType(familiarity)} />
                <span className="familiarity-label">
                    {getFamiliarityLevelLabel(familiarity)}
                </span>
            </div>
        ) : (
            '-'
        );
    };

    const renderImportanceCell = (params: GridRenderCellParams) => {
        const importance = params.row.severityLevel;

        const label =
            importance === IssueSeverityLevel.UNSCORED
                ? 'None'
                : capitalize(importance);

        return (
            <div className="importance-cell">
                <Icon type={getIssueSeverityLevelIconType(importance)} />
                <span className="importance-label">{label}</span>
            </div>
        );
    };

    const renderLastUpdatedCell = (params: GridRenderCellParams) =>
        dayjs(params.row.updatedAt).format(DEFAULT_DATE_FORMAT);

    const renderLinkedClausesCell = (params: GridRenderCellParams) => {
        const row = params.row as IContractReportRow;

        const { issueId, linkedParagraphClauses: clauses, rowId } = row;

        const expanded = expandedClausesRowIds.includes(String(rowId));
        const visibleClauses = expanded ? clauses : clauses.slice(0, 3);
        const extraClauses = clauses.slice(3);

        return (
            <div
                className={[
                    'linked-clauses-cell',
                    expanded ? 'expanded' : 'collapsed',
                    clauses.length ? '' : 'missing-data-cell',
                ].join(' ')}
            >
                {visibleClauses.length
                    ? visibleClauses
                          .sort(sortClauseNumbers)
                          .map((clause, index) => (
                              <IssuesReportClause
                                  key={`issues-report-clause-${row.issueId}-${index}`}
                                  clause={clause}
                                  isLast={index + 1 === visibleClauses.length}
                                  paragraphUuids={row.paragraphOriginUuids}
                                  title={row.title || row.propertyName}
                                  onSelectClause={onSelectClause}
                              />
                          ))
                    : '-'}
                {extraClauses.length && !expanded
                    ? renderNumberedChip(extraClauses.length)
                    : null}
                {extraClauses.length ? (
                    expanded ? (
                        <KeyboardArrowUp
                            className="toggle-clauses-arrow"
                            onClick={() => toggleExpandedClauses(issueId!)} // Fixme: null checks
                        />
                    ) : (
                        <KeyboardArrowDown
                            className="toggle-clauses-arrow"
                            onClick={() => toggleExpandedClauses(issueId!)} // Fixme: null checks
                        />
                    )
                ) : null}
            </div>
        );
    };

    const renderLinkedClausesTextCell = (params: GridRenderCellParams) => {
        const linkedParagraphs = params.row.paragraphOriginUuids.length
            ? paragraphs.filter((paragraph) =>
                  params.row.paragraphOriginUuids.includes(paragraph.originUuid)
              )
            : [];

        return (
            <div
                className={`linked-clauses-text-cell ${
                    linkedParagraphs.length
                        ? ''
                        : 'linked-clauses-placeholder-cell'
                }`}
            >
                {linkedParagraphs.length
                    ? linkedParagraphs.map((paragraph) => (
                          <div
                              className="linked-clause-text"
                              key={paragraph.id}
                          >
                              <div>{paragraph.clauseNumber}</div>
                              <div>{paragraph.text}</div>
                          </div>
                      ))
                    : '-'}
            </div>
        );
    };

    const renderSuggestionsCell = (params: GridRenderCellParams) => {
        const suggestionText = params.row.selectedSuggestion;

        const placeholderId = `suggestion-placeholder-${params.id}`;

        const cellContent = suggestionText ? (
            <div
                className="suggestions-cell"
                onClick={() => onSelectSuggestion(params.row.rowId)}
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(suggestionText),
                }}
            />
        ) : (
            <Tooltip
                className="suggestions-cell missing-data-cell"
                enterDelay={500}
                title={
                    'This property has not been configured with suggestions. ' +
                    'If you think that it should be, please contact your administrator.'
                }
                PopperProps={{
                    anchorEl: document.getElementById(placeholderId),
                }}
            >
                <div className="suggestion-placeholder" id={placeholderId}>
                    -
                </div>
            </Tooltip>
        );

        return cellContent;
    };

    const renderNumberedChip = (number: number) => (
        <span className="chip numbered-chip">
            <span className="chip-plus">+</span>
            <span className="chip-number">{number}</span>
        </span>
    );

    const renderStatusCell = (params: GridRenderCellParams) => {
        const status = params.row.status;

        const label = getIssueStatusLabel(status);

        return (
            <div className="status-cell">
                <Icon type={getIssueStatusIconType(status)} />
                <span className="status-label">{label}</span>
            </div>
        );
    };

    const renderUserFeedbackCell = (params: GridRenderCellParams) => {
        const { onSendFeedback, userId } = props;

        let votedUp = false;
        let votedDown = false;

        const row = params.row as IContractReportRow;

        for (const feedback of row.feedback) {
            if (feedback.userId === userId) {
                if (feedback.vote === 1) {
                    votedUp = true;
                } else if (feedback.vote === -1) {
                    votedDown = true;
                }
            }
        }

        return (
            <div
                className="user-feedback-cell-content"
                style={{
                    left: `${dataGridLeftScroll + dataGridWidth!}px`, // Fixme: null checks
                    position: 'absolute',
                    top: '50%',
                    transform: 'translate(-125%, -50%)',
                }}
            >
                <Voting
                    votedDown={votedDown}
                    votedUp={votedUp}
                    onVoteCallback={async (vote: 1 | -1) => {
                        onSendFeedback(row as IContractReportRow, vote);
                    }}
                />
            </div>
        );
    };

    const sortClauseNumbers = (
        clauseA: ParagraphClause,
        clauseB: ParagraphClause
    ) => clauseA.index - clauseB.index;

    const toggleExpandedClauses = (rowId: string) => {
        let expandedRowIds = expandedClausesRowIds;

        if (expandedRowIds.includes(rowId)) {
            expandedRowIds = expandedRowIds.filter(
                (expandedRowId) => expandedRowId !== rowId
            );
        } else {
            expandedRowIds.push(rowId);
        }

        setExpandedClausesRowIds(expandedClausesRowIds);
    };

    const onColumnOrderChange = () => {
        const newColumnOrder = {};

        apiRef.current.getAllColumns().forEach((column, order) => {
            newColumnOrder[column.field] = order;
        });

        props.onReorderColumns(newColumnOrder);
    };

    const visibleColumns = () => {
        const columnVisibility = {};
        props.columns.map(
            (column) => (columnVisibility[column.label] = column.active)
        );
        return columnVisibility;
    };

    const tableRows: GridRowsProp = rows.filter((row) =>
        filterRow(row, props.activeFilters, props.summaryPropertyCodes)
    );

    const onResize = (containerSize: GridColumnResizeParams) => {
        const columnWidth = containerSize.width;
        const padding = 16;
        const columnWidthPercentage =
            ((columnWidth + padding) / dataGridWidth!) * 100; // Fixme: null checks

        props.onResizeColumn(
            containerSize.colDef.field as IssuesTableHeader,
            columnWidthPercentage
        );
    };

    const defaultPageSize =
        activeFilters.issuesReportOptions?.defaultPageSize || 25;

    const isEditableField = (column: string) =>
        column === IssuesTableHeader.STATUS ||
        column === IssuesTableHeader.IMPORTANCE ||
        column === IssuesTableHeader.LINKED_CLAUSES ||
        column === IssuesTableHeader.DESCRIPTION ||
        column === IssuesTableHeader.NOTES ||
        column === IssuesTableHeader.SUMMARY ||
        column === IssuesTableHeader.SUGGESTIONS;

    return (
        <div className="hybrid-report-data-grid-wrapper">
            {getHeaders().length ? (
                <DataGrid
                    onColumnOrderChange={onColumnOrderChange}
                    apiRef={apiRef}
                    className="issues-report-data-grid"
                    columns={getHeaders()}
                    getRowId={(row: IContractReportRow) =>
                        row.issueId || row.propertyCode
                    }
                    columnVisibilityModel={visibleColumns()}
                    components={{
                        Pagination: () => (
                            <DataGridPagination
                                defaultPageSize={defaultPageSize}
                                summaryLabel={'rows'}
                            />
                        ),
                        Toolbar: () => (
                            <HybridReportDataGridToolbar {...props} />
                        ),
                    }}
                    data-id="issues-report-data-grid"
                    density="compact"
                    disableColumnMenu
                    disableColumnPinning
                    disableVirtualization
                    getCellClassName={(params: GridCellParams) =>
                        `table-cell ${hyphenateSnakeCase(
                            params.field
                        )} rte-enabled ${
                            !isEditableField(params.field)
                                ? 'non-editable-cell'
                                : ''
                        }`
                    }
                    getRowClassName={() => 'table-row'}
                    // @ts-ignore
                    getRowHeight={() => 'auto'}
                    rows={tableRows}
                    sortingOrder={[SortOrder.DESC, SortOrder.ASC, null]}
                    onSortModelChange={onSortChange}
                    onCellEditCommit={onCellEditCommit}
                    onCellClick={handleCellClick}
                    onColumnWidthChange={onResize}
                    pagination
                    sortModel={props.sortModel}
                    initialState={{
                        sorting: {
                            sortModel: props.sortModel,
                        },
                        pagination: {
                            pageSize: defaultPageSize,
                        },
                    }}
                    loading={loading}
                />
            ) : (
                'No columns selected for this report.'
            )}
        </div>
    );
};

export default HybridReportDataGrid;
