import * as React from 'react';
import {
    convertToRaw,
    EditorState,
    Modifier,
    ContentState,
    RichUtils,
    SelectionState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'draft-js/dist/Draft.css';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import {
    getSelectionText,
    getEntityRange,
    getSelectionEntity,
    toggleCustomInlineStyle,
} from 'draftjs-utils';
import {
    onDraftEditorCopy,
    onDraftEditorCut,
    handleDraftEditorPastedText,
} from 'draftjs-conductor';

import {
    ILinkData,
    IRichTextEditorProps,
    IRichTextEditorState,
} from './RichTextEditor-types';
import './RichTextEditor.scss';
import rangy from 'rangy';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
    Add,
    FormatAlignLeft,
    FormatBold,
    FormatClear,
    FormatColorText,
    FormatItalic,
    FormatListBulleted,
    FormatListNumbered,
    FormatSize,
    FormatStrikethrough,
    FormatUnderlined,
    KeyboardArrowDown,
    Link,
    LinkOff,
    MoreHoriz,
    SentimentSatisfiedAlt,
} from '@mui/icons-material';
import { CustomToolbarOption } from './CustomToolbarOption/CustomToolbarOption';
import CustomColorPicker from './CustomColorPicker/CustomColorPicker';
import CustomEmojiPicker from './CustomEmojiPicker/CustomEmojiPicker';
import LinkPopover from './LinkPopover/LinkPopover';
import CustomPopover from './CustomPopover/CustomPopover';

export default class RichTextEditor extends React.Component<
    IRichTextEditorProps,
    IRichTextEditorState
> {
    private wrapperRef: React.RefObject<HTMLDivElement>;

    constructor(props: IRichTextEditorProps) {
        super(props);

        this.state = {
            colorPickerModalAnchor: null,
            currentEntity: null,
            defaultValue: '',
            editing: !!props.editing,
            editorState: EditorState.createEmpty(),
            emojiPickerModalAnchor: null,
            formattingModalAnchor: null,
            linkData: {
                link: '',
                selectionText: '',
                text: '',
                targetOption: '',
            },
            linkModalAnchor: null,
            listModalAnchor: null,
            prevMarkup: null,
            textStylesModalAnchor: null,
            typingHistory: '',
            widthPixels: 0,
        };

        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        const { autoFocus = false } = this.props;

        this.setDefaultValue();

        if (autoFocus) {
            this.wrapperRef.current?.focus();
        }

        this.setState({
            widthPixels: this.wrapperRef.current?.clientWidth!, // Fixme: null checks
        });

        window.addEventListener('resize', this.onWindowResize);
    }

    componentDidUpdate(
        prevProps: IRichTextEditorProps,
        prevState: IRichTextEditorState
    ) {
        const { reset } = this.props;

        if (prevProps.reset !== reset) {
            this.setDefaultValue();
        }

        if (prevState.widthPixels !== this.wrapperRef.current?.clientWidth) {
            this.setState({
                widthPixels: this.wrapperRef.current?.clientWidth!, // Fixme: null checks
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    private getCurrentLinkValues(): ILinkData {
        const { currentEntity, editorState } = this.state;

        const contentState = editorState.getCurrentContent();
        const currentValues = {
            link: '',
            targetOption: '_blank',
            text: '',
            selectionText: '',
        };

        if (
            currentEntity &&
            // @ts-ignore
            contentState.getEntity(currentEntity).type === 'LINK'
        ) {
            const entityRange =
                currentEntity && getEntityRange(editorState, currentEntity);

            currentValues.link =
                currentEntity &&
                // @ts-ignore
                (contentState.getEntity(currentEntity)?.data?.url ?? '');
            currentValues.text = entityRange && entityRange.text;
        }

        currentValues.selectionText = getSelectionText(editorState) || '';

        if (
            currentValues.selectionText?.length &&
            !currentValues.text?.length
        ) {
            currentValues.text = currentValues.selectionText;
        }

        return currentValues;
    }

    isFocused = () => this.state.editing;

    private onAddLink(linkUrl: string, linkText: string) {
        const { editorState } = this.state;
        const { currentEntity } = this.state;
        let selection = editorState.getSelection();

        if (currentEntity) {
            const entityRange = getEntityRange(editorState, currentEntity);
            const isBackward = selection.getIsBackward();

            if (isBackward) {
                selection = selection.merge({
                    anchorOffset: entityRange.end,
                    focusOffset: entityRange.start,
                });
            } else {
                selection = selection.merge({
                    anchorOffset: entityRange.start,
                    focusOffset: entityRange.end,
                });
            }
        }

        const entityKey = editorState
            .getCurrentContent()
            .createEntity('LINK', 'MUTABLE', {
                url: !/^https?:\/\//i.test(linkUrl)
                    ? `http://${linkUrl}`
                    : linkUrl,
                targetOption: '_blank',
            })
            .getLastCreatedEntityKey();

        let contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            selection,
            `${linkText}`,
            editorState.getCurrentInlineStyle(),
            entityKey
        );

        let newEditorState = EditorState.push(
            editorState,
            contentState,
            'insert-characters'
        );

        selection = newEditorState.getSelection().merge({
            anchorOffset: selection.get('anchorOffset') + linkText.length,
            focusOffset: selection.get('anchorOffset') + linkText.length,
        });

        newEditorState = EditorState.acceptSelection(newEditorState, selection);

        contentState = Modifier.insertText(
            newEditorState.getCurrentContent(),
            selection,
            '',
            newEditorState.getCurrentInlineStyle(),
            undefined
        );

        const newState = EditorState.push(
            newEditorState,
            contentState,
            'insert-characters'
        );

        this.onEditorStateUpdate(newState);

        this.setState({
            currentEntity: null,
        });
    }

    private onRemoveLink() {
        const { currentEntity, editorState } = this.state;

        let selection = editorState.getSelection();

        if (currentEntity) {
            const entityRange = getEntityRange(editorState, currentEntity);
            const isBackward = selection.getIsBackward();

            if (isBackward) {
                selection = selection.merge({
                    anchorOffset: entityRange.end,
                    focusOffset: entityRange.start,
                });
            } else {
                selection = selection.merge({
                    anchorOffset: entityRange.start,
                    focusOffset: entityRange.end,
                });
            }

            const newState = RichUtils.toggleLink(editorState, selection, null);

            this.onEditorStateUpdate(newState);
        }
    }

    onWindowResize = () => {
        this.setState({
            widthPixels: this.wrapperRef.current?.clientWidth!, // Fixme: null checks
        });
    };

    private onEditorStateUpdate = (editorState: EditorState) => {
        const { onChange, onSelection, selectionAsString } = this.props;

        const { editing } = this.state;

        const nextState = {
            editorState,
            currentEntity: getSelectionEntity(editorState),
        };

        this.setState(nextState);

        if (editing) {
            const rawContentState = convertToRaw(
                editorState.getCurrentContent()
            );
            const markup = draftToHtml(rawContentState);
            const { prevMarkup } = this.state;

            // Only call onChange prop if the markup has changed
            if (markup !== prevMarkup) {
                onChange(markup);
                this.setState({ prevMarkup: markup });
            }

            if (onSelection) {
                onSelection(
                    selectionAsString
                        ? document.getSelection()?.toString() ?? ''
                        : rangy.getSelection().toHtml()
                );
            }
        }
    };

    renderAlignmentDropdown() {
        return (
            <CustomToolbarOption
                selector={''}
                label={
                    <div className="custom-toolbar-mock-dropdown">
                        <FormatAlignLeft className="mock-dropdown-icon toolbar-icon" />
                        <KeyboardArrowDown className="mock-dropdown-icon toolbar-icon" />
                    </div>
                }
                tooltipValue={<span>Alignment</span>}
                options={[
                    <CustomToolbarOption
                        key="left-align"
                        selector={'.rdw-option-wrapper[title="Left"]'}
                        label="Left Align"
                        tooltipValue={null}
                    />,
                    <CustomToolbarOption
                        key="right-align"
                        selector={'.rdw-option-wrapper[title="Right"]'}
                        label="Right Align"
                        tooltipValue={null}
                    />,
                    <CustomToolbarOption
                        key="center-align"
                        selector={'.rdw-option-wrapper[title="Center"]'}
                        label="Center Align"
                        tooltipValue={null}
                    />,
                    <CustomToolbarOption
                        key="justify"
                        selector={'.rdw-option-wrapper[title="Justify"]'}
                        label="Justify"
                        tooltipValue={null}
                    />,
                ]}
            />
        );
    }

    private renderBoldFormat(icon: boolean = true, classes: string = '') {
        return (
            <CustomToolbarOption
                key="bold"
                classes={`${classes || 'bold'}`}
                selector={'.rdw-option-wrapper[title="Bold"]'}
                label={icon ? <FormatBold className="toolbar-icon" /> : 'Bold'}
                tooltipValue={icon ? <span>Bold</span> : null}
            />
        );
    }

    private renderBulletList(icon: boolean = true, classes: string = '') {
        return (
            <CustomToolbarOption
                key="bullet-list"
                classes={`${classes || 'bullet-list'}`}
                selector={'.rdw-option-wrapper[title="Unordered"]'}
                label={
                    icon ? (
                        <FormatListBulleted className="toolbar-icon" />
                    ) : (
                        'Bullet List'
                    )
                }
                tooltipValue={icon ? <span>Bullet List</span> : null}
            />
        );
    }

    private renderColorPicker() {
        const { colorPickerModalAnchor, editorState } = this.state;
        const { popoverOffsetLeft, popoverOffsetTop } = this.props;

        return (
            <>
                <CustomToolbarOption
                    callback={(event) =>
                        this.setState({
                            colorPickerModalAnchor: event.currentTarget,
                        })
                    }
                    selector={''}
                    label={<FormatColorText className="toolbar-icon" />}
                    tooltipValue={<span>Text color</span>}
                />
                <CustomPopover
                    open={Boolean(colorPickerModalAnchor)}
                    anchor={colorPickerModalAnchor!} // Fixme: null checks
                    onClose={() =>
                        this.setState({ colorPickerModalAnchor: null })
                    }
                    offsetLeft={popoverOffsetLeft}
                    offsetTop={popoverOffsetTop}
                >
                    {colorPickerModalAnchor ? (
                        <CustomColorPicker
                            callback={(
                                style: 'color' | 'bgcolor',
                                color: string
                            ) => {
                                const newState = toggleCustomInlineStyle(
                                    editorState,
                                    style,
                                    color
                                );
                                if (newState) {
                                    this.onEditorStateUpdate(newState);
                                }
                            }}
                        />
                    ) : null}
                </CustomPopover>
            </>
        );
    }

    private renderEmojiPicker() {
        const { emojiPickerModalAnchor, editorState } = this.state;
        const { popoverOffsetLeft, popoverOffsetTop } = this.props;

        return (
            <>
                <CustomToolbarOption
                    callback={(event) =>
                        this.setState({
                            emojiPickerModalAnchor: event.currentTarget,
                        })
                    }
                    classes="emoji-control"
                    selector={''}
                    label={
                        <SentimentSatisfiedAlt className="toolbar-icon emoji-icon" />
                    }
                    tooltipValue={<span>Emojis</span>}
                />
                <CustomPopover
                    open={Boolean(emojiPickerModalAnchor)}
                    anchor={emojiPickerModalAnchor!} // Fixme: null checks
                    onClose={() =>
                        this.setState({ emojiPickerModalAnchor: null })
                    }
                    offsetLeft={popoverOffsetLeft}
                    offsetTop={popoverOffsetTop}
                    position="right"
                >
                    {emojiPickerModalAnchor ? (
                        <CustomEmojiPicker
                            callback={(emoji: string) => {
                                const contentState = Modifier.replaceText(
                                    editorState.getCurrentContent(),
                                    editorState.getSelection(),
                                    emoji,
                                    editorState.getCurrentInlineStyle()
                                );
                                const newState = EditorState.push(
                                    editorState,
                                    contentState,
                                    'insert-characters'
                                );

                                if (newState) {
                                    this.onEditorStateUpdate(newState);
                                }
                            }}
                        />
                    ) : null}
                </CustomPopover>
            </>
        );
    }

    private renderDivider() {
        return (
            <CustomToolbarOption
                classes="divider-wrapper"
                selector={''}
                label={<span className="divider" />}
                tooltipValue={null}
                clickable={false}
            />
        );
    }

    private renderItalicFormat(icon: boolean = true, classes: string = '') {
        return (
            <CustomToolbarOption
                key="italic"
                classes={`${classes || 'italic'}`}
                selector={'.rdw-option-wrapper[title="Italic"]'}
                label={
                    icon ? <FormatItalic className="toolbar-icon" /> : 'Italic'
                }
                tooltipValue={icon ? <span>Italic</span> : null}
            />
        );
    }

    private renderUnderlineFormat(icon: boolean = true, classes: string = '') {
        return (
            <CustomToolbarOption
                key="underline"
                classes={`${classes || 'underline'}`}
                selector={'.rdw-option-wrapper[title="Underline"]'}
                label={
                    icon ? (
                        <FormatUnderlined className="toolbar-icon" />
                    ) : (
                        'Underline'
                    )
                }
                tooltipValue={icon ? <span>Underline</span> : null}
            />
        );
    }

    private renderStrikethroughFormat(
        icon: boolean = true,
        classes: string = ''
    ) {
        return (
            <CustomToolbarOption
                key="strikethrough"
                classes={`${classes || 'strikethrough'}`}
                selector={'.rdw-option-wrapper[title="Strikethrough"]'}
                label={
                    icon ? (
                        <FormatStrikethrough className="toolbar-icon" />
                    ) : (
                        'Strikethrough'
                    )
                }
                tooltipValue={icon ? <span>Strikethrough</span> : null}
            />
        );
    }

    private renderClearFormatting(icon: boolean = true, classes: string = '') {
        return (
            <CustomToolbarOption
                key="clear-formatting"
                classes={`${classes || 'clear-formatting'}`}
                selector={'.rdw-option-wrapper[title="Remove"]'}
                label={
                    icon ? (
                        <FormatClear className="toolbar-icon" />
                    ) : (
                        'Clear Formatting'
                    )
                }
                tooltipValue={icon ? <span>Clear Formatting</span> : null}
            />
        );
    }

    private renderMoreFormattingDropdown() {
        const { formattingModalAnchor } = this.state;
        const { popoverOffsetLeft, popoverOffsetTop } = this.props;

        return (
            <>
                <CustomToolbarOption
                    classes="formatting-dropdown"
                    selector={''}
                    label={<MoreHoriz className="toolbar-icon" />}
                    callback={async (event) => {
                        await this.setState({
                            formattingModalAnchor: event.currentTarget,
                        });
                    }}
                    tooltipValue={<span>More formatting</span>}
                />

                <CustomPopover
                    open={Boolean(formattingModalAnchor)}
                    anchor={formattingModalAnchor!} // Fixme: null checks
                    onClose={() =>
                        this.setState({ formattingModalAnchor: null })
                    }
                    offsetLeft={popoverOffsetLeft}
                    offsetTop={popoverOffsetTop}
                    position="center"
                >
                    <div className="toolbar-menu">
                        {this.renderBoldFormat(false, 'dropdown-bold')}
                        {this.renderItalicFormat(false, 'dropdown-italic')}
                        {this.renderUnderlineFormat(
                            false,
                            'dropdown-underline'
                        )}
                        {this.renderStrikethroughFormat(
                            false,
                            'dropdown-strikethrough'
                        )}
                        {this.renderClearFormatting(
                            false,
                            'dropdown-clear-formatting'
                        )}
                    </div>
                </CustomPopover>
            </>
        );
    }

    private renderListDropdown() {
        const { listModalAnchor } = this.state;
        const { popoverOffsetLeft, popoverOffsetTop } = this.props;

        return (
            <>
                <CustomToolbarOption
                    classes="list-dropdown"
                    selector={''}
                    label={<MoreHoriz className="toolbar-icon" />}
                    callback={async (event) => {
                        await this.setState({
                            listModalAnchor: event.currentTarget,
                        });
                    }}
                    tooltipValue={<span>Lists</span>}
                />

                <CustomPopover
                    open={Boolean(listModalAnchor)}
                    anchor={listModalAnchor!} // Fixme: null checks
                    onClose={() => this.setState({ listModalAnchor: null })}
                    offsetLeft={popoverOffsetLeft}
                    offsetTop={popoverOffsetTop}
                    position="center"
                >
                    <div className="toolbar-menu">
                        {this.renderNumberedList(
                            false,
                            'dropdown-numbered-list'
                        )}
                        {this.renderBulletList(false, 'dropdown-bullet-list')}
                    </div>
                </CustomPopover>
            </>
        );
    }

    private renderNumberedList(icon: boolean = true, classes: string = '') {
        return (
            <CustomToolbarOption
                key="numbered-list"
                classes={`${classes || 'numbered-list'}`}
                selector={'.rdw-option-wrapper[title="Ordered"]'}
                label={
                    icon ? (
                        <FormatListNumbered className="toolbar-icon" />
                    ) : (
                        'Numbered List'
                    )
                }
                tooltipValue={icon ? <span>Numbered List</span> : null}
            />
        );
    }

    private renderTextStylesDropdown() {
        const { textStylesModalAnchor } = this.state;
        const { popoverOffsetLeft, popoverOffsetTop } = this.props;

        return (
            <>
                <CustomToolbarOption
                    classes="text-styles-dropdown"
                    callback={async (event) => {
                        await this.setState({
                            textStylesModalAnchor: event.currentTarget,
                        });
                    }}
                    selector={''}
                    label={
                        <div className="custom-toolbar-mock-dropdown">
                            <FormatSize className="mock-dropdown-icon toolbar-icon" />
                            <KeyboardArrowDown className="mock-dropdown-icon toolbar-icon" />
                        </div>
                    }
                    tooltipValue={<span>Text Styles</span>}
                />
                <CustomPopover
                    open={Boolean(textStylesModalAnchor)}
                    anchor={textStylesModalAnchor!} // Fixme: null checks
                    onClose={() =>
                        this.setState({ textStylesModalAnchor: null })
                    }
                    offsetLeft={popoverOffsetLeft}
                    offsetTop={popoverOffsetTop}
                    position="center"
                >
                    <div className="toolbar-menu">
                        <CustomToolbarOption
                            key="normal"
                            selector={
                                '.block-type-options .rdw-option-wrapper:nth-of-type(1)'
                            }
                            label="Normal"
                            tooltipValue={null}
                        />
                        <CustomToolbarOption
                            key="h1"
                            selector={
                                '.block-type-options .rdw-option-wrapper:nth-of-type(2)'
                            }
                            label="H1"
                            tooltipValue={null}
                        />
                        <CustomToolbarOption
                            key="h2"
                            selector={
                                '.block-type-options .rdw-option-wrapper:nth-of-type(3)'
                            }
                            label="H2"
                            tooltipValue={null}
                        />
                        <CustomToolbarOption
                            key="h3"
                            selector={
                                '.block-type-options .rdw-option-wrapper:nth-of-type(4)'
                            }
                            label="H3"
                            tooltipValue={null}
                        />
                        <CustomToolbarOption
                            key="h4"
                            selector={
                                '.block-type-options .rdw-option-wrapper:nth-of-type(5)'
                            }
                            label="H4"
                            tooltipValue={null}
                        />
                        <CustomToolbarOption
                            key="h5"
                            selector={
                                '.block-type-options .rdw-option-wrapper:nth-of-type(6)'
                            }
                            label="H5"
                            tooltipValue={null}
                        />
                        <CustomToolbarOption
                            key="h6"
                            selector={
                                '.block-type-options .rdw-option-wrapper:nth-of-type(7)'
                            }
                            label="H6"
                            tooltipValue={null}
                        />
                        <CustomToolbarOption
                            key="Blockquote"
                            selector={
                                '.block-type-options .rdw-option-wrapper:nth-of-type(8)'
                            }
                            label="Blockquote"
                            tooltipValue={null}
                        />
                    </div>
                </CustomPopover>
            </>
        );
    }

    renderShowMoreControl(controls: JSX.Element[]) {
        return (
            <>
                <CustomToolbarOption
                    classes="show-more-wrapper"
                    selector={''}
                    label={
                        <div className="custom-toolbar-mock-dropdown">
                            <Add className="mock-dropdown-icon toolbar-icon" />
                            <KeyboardArrowDown className="mock-dropdown-icon toolbar-icon" />
                        </div>
                    }
                    tooltipValue={<span>Alignment</span>}
                    options={controls}
                />
            </>
        );
    }

    private renderLinkControl() {
        const { linkModalAnchor } = this.state;
        const { popoverOffsetLeft, popoverOffsetTop } = this.props;

        const currentLinkValues = this.getCurrentLinkValues();

        return (
            <>
                <CustomToolbarOption
                    callback={(event) => {
                        this.setState({
                            linkModalAnchor: event.currentTarget,
                        });
                    }}
                    selector={''}
                    label={<Link className="toolbar-icon" />}
                    tooltipValue={<span>Link</span>}
                />
                <CustomPopover
                    open={Boolean(linkModalAnchor)}
                    anchor={linkModalAnchor!} // Fixme: null checks
                    onClose={() => this.setState({ linkModalAnchor: null })}
                    offsetLeft={popoverOffsetLeft}
                    offsetTop={popoverOffsetTop}
                    position="center-right"
                >
                    {linkModalAnchor ? (
                        <LinkPopover
                            linkText={currentLinkValues.text}
                            linkUrl={currentLinkValues.link}
                            onConfirm={(linkUrl: string, linkText: string) => {
                                this.onAddLink(linkUrl, linkText);
                                setTimeout(() => {
                                    this.setState({ linkModalAnchor: null });
                                }, 0);
                            }}
                        />
                    ) : null}
                </CustomPopover>
            </>
        );
    }

    private renderRemoveLinkControl() {
        return (
            <CustomToolbarOption
                callback={() => this.onRemoveLink()}
                selector={''}
                label={<LinkOff className="toolbar-icon" />}
                tooltipValue={<span>Remove Link</span>}
            />
        );
    }

    private normalizeInput = (html: string = '') =>
        html
            .replace(/<s(\s)?(style="[a-zA-Z0-9_:\s(,);-]+")?>/gm, '<del$1$2>')
            .replace(/<\/s>/gm, '</del>')
            .replace(/<u(\s)?(style="[a-zA-Z0-9_:\s(,);-]+")?>/gm, '<ins$1$2>')
            .replace(/<\/u>/gm, '</ins>')
            .replace(
                /<strong\s+(style="[a-zA-Z0-9_:\s(,);-]+")>(<em>)?(<del>)?(<ins>)?([^</]+)(<\/ins>)?(<\/del>)?(<\/em>)?<\/strong>/gm,
                '<strong>$2$3$4<span $1>$5</span>$6$7$8</strong>'
            )
            .replace(
                /<em\s+(style="[a-zA-Z0-9_:\s(,);-]+")>(<del>)?(<ins>)?([^</]+)(<\/ins>)?(<\/del>)?<\/em>/gm,
                '<em>$2$3<span $1>$4</span>$5$6</em>'
            )
            .replace(
                /<del\s+(style="[a-zA-Z0-9_:\s(,);-]+")>(<ins>)?([^</]+)(<\/ins>)?<\/del>/gm,
                '<del>$2<span $1>$3</span>$4</del>'
            )
            .replace(
                /<ins\s+(style="[a-zA-Z0-9_:\s(,);-]+")>([^</]+)<\/ins>/gm,
                '<ins><span $1>$2</span></ins>'
            );

    private setDefaultValue = () => {
        const { value } = this.props;

        const blocksFromHtml = htmlToDraft(this.normalizeInput(value));
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        const rawContentState = convertToRaw(editorState.getCurrentContent());

        this.setState({
            defaultValue: JSON.stringify(rawContentState),
            editorState,
        });
    };

    private autoAddList(type: 'ordered' | 'unordered') {
        const { editorState } = this.state;

        const currentContent = editorState.getCurrentContent();

        const selection = editorState.getSelection();
        const blockKey = selection.getStartKey();
        const block = currentContent.getBlockForKey(blockKey);

        const blockSelection = new SelectionState({
            anchorKey: blockKey,
            anchorOffset: 0,
            focusKey: blockKey,
            focusOffset: block.getLength(),
        });

        const newContent = Modifier.replaceText(
            currentContent,
            blockSelection,
            ''
        );

        const newEditorState = EditorState.push(
            editorState,
            newContent,
            'insert-characters'
        );

        const nextEditorState = RichUtils.toggleBlockType(
            newEditorState,
            `${type}-list-item`
        );

        const currentSelection = nextEditorState.getSelection();
        const stateWithContentAndSelection = EditorState.forceSelection(
            nextEditorState,
            currentSelection
        );

        this.onEditorStateUpdate(stateWithContentAndSelection);
    }

    private removeEmptyBlock = () => {
        const { editorState } = this.state;

        const newEditorState = RichUtils.toggleBlockType(
            editorState,
            'unstyled'
        );

        if (newEditorState) {
            this.onEditorStateUpdate(newEditorState);
        }
    };

    private insertEmptyBlock = () => {
        const { editorState } = this.state;

        const currentContent = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        const textWithEntity = Modifier.splitBlock(currentContent, selection);

        let newEditorState = EditorState.push(
            editorState,
            textWithEntity,
            'split-block'
        );

        newEditorState = RichUtils.toggleBlockType(newEditorState, 'unstyled');

        this.onEditorStateUpdate(newEditorState);
    };

    private onKeyDown = (event: React.KeyboardEvent) => {
        const { editorState, typingHistory, linkModalAnchor } = this.state;

        const lastThreeCharacters = `${typingHistory}${event.key}`.slice(-3);

        this.setState({
            typingHistory: lastThreeCharacters,
        });

        if (lastThreeCharacters === '1. ') {
            this.autoAddList('ordered');
        } else if (lastThreeCharacters.slice(-2) === '- ') {
            this.autoAddList('unordered');
        }

        const selection = editorState.getSelection();

        let isList = false;
        let isBlockquote = false;

        if (selection) {
            const currentContent = editorState.getCurrentContent();
            const blockStartKey = selection.getStartKey();
            const block = currentContent.getBlockForKey(blockStartKey);
            const blockType = block.getType();
            isList = blockType.indexOf('list') !== -1;
            isBlockquote = blockType.indexOf('blockquote') !== -1;

            if (
                event.key === 'Backspace' &&
                (isList || isBlockquote) &&
                block.getText() === '' &&
                !currentContent.hasText()
            ) {
                this.removeEmptyBlock();
            }

            if ((event.ctrlKey || event.shiftKey) && event.key === 'Enter') {
                this.insertEmptyBlock();
            }
        }

        // prevents calling the on key down event for the Enter key if currently editing a list
        if (event.key === 'Enter' && (isList || linkModalAnchor)) {
            event.stopPropagation();
            return;
        }

        if (event.key === 'Tab' && linkModalAnchor) {
            event.stopPropagation();
            return;
        }

        if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    };

    private getToolbarResponsiveClasses() {
        const { widthPixels } = this.state;

        let classes = '';

        if (widthPixels >= 325) {
            classes +=
                ' show-numbered-list show-bullet-list hide-list-dropdown';
        }

        if (widthPixels >= 350) {
            classes += ' show-bold';
        }

        if (widthPixels >= 375) {
            classes += ' show-italic';
        }

        if (widthPixels >= 400) {
            classes += ' show-underline';
        }

        if (widthPixels >= 425) {
            classes += ' show-strikethrough';
        }

        if (widthPixels >= 450) {
            classes += ' show-clear-formatting hide-formatting-dropdown';
        }

        return classes;
    }

    private handlePastedText = (
        _text: string,
        html: string | null,
        editorState: EditorState
    ) => {
        const newState = handleDraftEditorPastedText(html!, editorState); // Fixme: null checks

        if (newState) {
            this.onEditorStateUpdate(newState);
            return true;
        }

        return false;
    };

    render() {
        const {
            onBlur,
            onSelection,
            placeholder,
            showToolbarOnFocus = false,
            onKeyUp,
        } = this.props;
        const { editorState, editing } = this.state;

        const toolbarResponsiveClasses = this.getToolbarResponsiveClasses();

        return (
            <div
                className="rte-container"
                ref={this.wrapperRef}
                onKeyDown={this.onKeyDown}
                onKeyUp={onKeyUp}
            >
                <Editor
                    editorState={editorState}
                    toolbarClassName={`rte-toolbar ${
                        showToolbarOnFocus ? 'show-on-focus' : ''
                    } ${toolbarResponsiveClasses}`}
                    wrapperClassName={`rte-editor ${editing ? 'editing' : ''}`}
                    editorClassName="editor"
                    handlePastedText={this.handlePastedText}
                    onEditorStateChange={this.onEditorStateUpdate}
                    toolbarCustomButtons={[
                        this.renderTextStylesDropdown(),
                        this.renderDivider(),
                        this.renderBoldFormat(),
                        this.renderItalicFormat(),
                        this.renderUnderlineFormat(),
                        this.renderStrikethroughFormat(),
                        this.renderClearFormatting(),
                        this.renderMoreFormattingDropdown(),
                        this.renderDivider(),
                        this.renderColorPicker(),
                        this.renderDivider(),
                        this.renderBulletList(),
                        this.renderNumberedList(),
                        this.renderListDropdown(),
                        this.renderDivider(),
                        this.renderLinkControl(),
                        this.renderRemoveLinkControl(),
                        this.renderDivider(),
                        this.renderEmojiPicker(),
                    ]}
                    toolbar={{
                        options: [
                            'blockType',
                            'inline',
                            'list',
                            'textAlign',
                            'remove',
                        ],
                        inline: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                            ],
                            bold: { className: 'hidden' },
                            italic: { className: 'hidden' },
                            underline: { className: 'hidden' },
                            strikethrough: { className: 'hidden' },
                        },
                        blockType: {
                            inDropdown: false,
                            options: [
                                'Normal',
                                'H1',
                                'H2',
                                'H3',
                                'H4',
                                'H5',
                                'H6',
                                'Blockquote',
                            ],
                            className: 'block-type-options',
                            component: undefined,
                            dropdownClassName: undefined,
                        },
                        list: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['unordered', 'ordered'],
                            unordered: { className: undefined },
                            ordered: { className: undefined },
                            indent: { className: undefined },
                            outdent: { className: undefined },
                        },
                        textAlign: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['left', 'center', 'right', 'justify'],
                            left: { className: undefined },
                            center: { className: undefined },
                            right: { className: undefined },
                            justify: { className: undefined },
                        },
                        remove: {
                            className: undefined,
                            component: undefined,
                        },
                    }}
                    // @ts-ignore
                    onCut={onDraftEditorCut}
                    // @ts-ignore
                    onCopy={onDraftEditorCopy}
                    onBlur={() => {
                        this.setState({
                            editing: false,
                        });
                        if (onSelection) {
                            onSelection('');
                        }
                        if (onBlur) {
                            onBlur();
                        }
                    }}
                    onFocus={() =>
                        this.setState({
                            editing: true,
                        })
                    }
                    placeholder={placeholder}
                />
            </div>
        );
    }
}
