import { connect } from 'react-redux';
import { IRootState } from '../../redux/reducers-types';
import HelpMenu from './HelpMenu';

import { IHelpMenuStateProps } from './HelpMenu-types';

function mapStateToProps(state: IRootState): IHelpMenuStateProps {
    return {
        toggles: state.featureToggles.toggles,
    };
}

export default connect(mapStateToProps)(HelpMenu);
