import { Suspense, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
    createTheme,
    StyledEngineProvider,
    ThemeProvider,
} from '@mui/material';
import Header from '../Header/Header-container';
import Contract from '../Contract/Contract-container';
import ContractList from '../ContractList/ContractList-container';
import ModalDialog from '../ModalDialog/ModalDialog-container';
import Auth from '../Auth/Auth-container';
import PartyCorrection from '../PartyCorrection/PartyCorrection-container';
import { isAutoLogoutPage, isRedirectionPage } from '../Auth/Auth-helpers';
import ContractPollingProvider from '../ContractPollingProvider/ContractPollingProvider-container';
import FeatureToggleProvider from '../FeatureToggleProvider/FeatureToggleProvider-container';

import SplashLoader from '../SplashLoader/SplashLoader';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
    FEATURE_TOGGLE_API_KEYS_PAGE,
    FEATURE_TOGGLE_CONTRACTS_TREE,
} from '../FeatureToggleProvider/FeatureToggleProvider-types';
import { TRComponentsProvider } from '@thought-river/ui-components';
import { ReportsPage } from '@modules/reports/portfolioReports';
import { ApiKeysPage } from '@modules/settings/components';
import ContractsTree from '@modules/contractsTree/ContractsTree';
import { ReactQueryDevtoolsProduction } from '@modules/common/consts';
import { queryClient, setApiConfig } from '@thought-river/negotiations-common';
import { DiagnosticsPage } from '@modules/diagnostics';
import ToastContainer from './ToastContainer';
import { useAppSelector } from '@modules/common/hooks';
import AuthCallback from '@modules/auth/components/AuthCallback';
import AuthProvider from '@modules/auth/context/AuthProvider';
import LogoutPage from '@modules/auth/components/LogoutPage';

const App = () => {
    const featureToggles = useAppSelector(
        (state) => state.featureToggles.toggles
    );
    const { loggingOut, apiToken, legacyApiToken, selectedStream } =
        useAppSelector((state) => state.auth);
    const [showDevTools, setShowDevTools] = useState(false);

    useEffect(() => {
        //@ts-ignore
        window.toggleDevtools = () => setShowDevTools(true);
        console.time('load');
    }, []);

    useEffect(() => {
        if (!apiToken && !legacyApiToken) {
            return;
        }

        setApiConfig({
            apiToken: legacyApiToken ?? apiToken,
            apiUrl: window.__APP_CONFIG__.api2Url,
        });

        // Set token for legacy UI
        if (legacyApiToken) {
            const legacyUIUrl = window.__APP_CONFIG__.legacyUIUrl;
            const tokenUrl = `${legacyUIUrl}/?jwt=${legacyApiToken}`;
            fetch(tokenUrl, { credentials: 'include' });
        }
    }, [apiToken, legacyApiToken, selectedStream]);

    const authRedirect = isRedirectionPage();
    const autoLogout = isAutoLogoutPage();

    const apiKeysPageEnabled = featureToggles.find(
        (toggle) => toggle.feature === FEATURE_TOGGLE_API_KEYS_PAGE
    )?.enabled;

    const contractsTreeEnabled = featureToggles.find(
        (toggle) => toggle.feature === FEATURE_TOGGLE_CONTRACTS_TREE
    )?.enabled;

    const theme = createTheme({
        components: {
            MuiLinearProgress: {
                styleOverrides: {
                    root: {
                        borderRadius: '8px',
                        height: '5px',
                    },
                    barColorPrimary: {
                        backgroundColor: '#5099fd',
                    },
                    colorPrimary: {
                        backgroundColor: '#c4c4c4',
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        color: '#5099fd',
                        textUnderlineOffset: '4px',
                    },
                },
            },
        },
        typography: {
            fontFamily: ['Lato', 'sans-serif'].join(','),
        },
    });

    const loading = autoLogout || authRedirect || loggingOut;

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <FeatureToggleProvider>
                    <AuthProvider>
                        <Switch>
                            <Route
                                path="/auth-callback"
                                exact
                                component={AuthCallback}
                            />
                            <Route
                                path="/logout"
                                exact
                                component={LogoutPage}
                            />
                            <Route path="*">
                                <Auth>
                                    <QueryClientProvider client={queryClient}>
                                        <TRComponentsProvider
                                            flagsmith={{
                                                environmentKey:
                                                    window.__APP_CONFIG__
                                                        .featureToggle
                                                        ?.apiKey ?? '',
                                            }}
                                        >
                                            <div className="app-container">
                                                {loading ? (
                                                    <SplashLoader
                                                        message={
                                                            loggingOut
                                                                ? 'Signing out...'
                                                                : 'Loading...'
                                                        }
                                                    />
                                                ) : (
                                                    <div className="app">
                                                        <Header />
                                                        <main className="main">
                                                            {apiKeysPageEnabled && (
                                                                <Route
                                                                    path={
                                                                        '/settings/api-keys'
                                                                    }
                                                                    component={
                                                                        ApiKeysPage
                                                                    }
                                                                />
                                                            )}
                                                            <Route
                                                                path={
                                                                    '/diagnostics/contract/:contractUuid/version/:versionUuid'
                                                                }
                                                                component={
                                                                    DiagnosticsPage
                                                                }
                                                            />
                                                            <Route
                                                                path={[
                                                                    '/stream/:stream/contract/:contractId([0-9a-f-]{36})/version/:versionId/paragraph/:paragraphUuid([0-9a-f-]{36})',
                                                                    '/stream/:stream/contract/:contractId([0-9a-f-]{36})/version/:versionId/property/:propertyUuid([0-9a-f-]{36})',
                                                                    '/stream/:stream/contract/:contractId([0-9a-f-]{36})/version/:versionId/report',
                                                                    '/stream/:stream/contract/:contractId([0-9a-f-]{36})/version/:versionId',
                                                                ]}
                                                                component={
                                                                    Contract
                                                                }
                                                            />
                                                            <Route
                                                                path="/stream/:stream/contract/:id([0-9a-f-]{36})/parties"
                                                                component={
                                                                    PartyCorrection
                                                                }
                                                            />
                                                            <Route
                                                                exact
                                                                path="/"
                                                                component={
                                                                    contractsTreeEnabled
                                                                        ? ContractsTree
                                                                        : ContractList
                                                                }
                                                            />
                                                            <Route
                                                                path={[
                                                                    '/analytics-report/:reportId',
                                                                    '/analytics-report',
                                                                ]}
                                                            >
                                                                <Redirect to="/reports" />
                                                            </Route>
                                                            <Route
                                                                path={
                                                                    '/reports'
                                                                }
                                                                component={
                                                                    ReportsPage
                                                                }
                                                            />
                                                        </main>
                                                    </div>
                                                )}
                                                <ToastContainer />
                                                <ModalDialog />
                                                <ContractPollingProvider />
                                            </div>
                                            <ReactQueryDevtools
                                                initialIsOpen={false}
                                            />
                                            {showDevTools && (
                                                <Suspense fallback={null}>
                                                    <ReactQueryDevtoolsProduction />
                                                </Suspense>
                                            )}
                                        </TRComponentsProvider>
                                    </QueryClientProvider>
                                </Auth>
                            </Route>
                        </Switch>
                    </AuthProvider>
                </FeatureToggleProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
