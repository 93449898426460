import { ICategories } from 'types/thoughtriver';
import Api from '../services/api';
import { Method, requestOptions } from '../services/api-types';

export default class CategoryResource {
    static async getAllCategories(): Promise<ICategories> {
        const options = {
            ...requestOptions,
            includeStream: false,
        };

        return await (await Api.request(
            Method.GET,
            '/issue-categories',
            options
        ))!.json();
    }
}
