import {
    ReportFilters,
    SelectedDataItem,
} from '../GenericReportConfigPage.types';

export const useGetSelectedData = (
    selectedColumns: string[],
    selectedFilters: ReportFilters,
    visibleColumns: string[],
    getColumnName?: (column: string) => string,
    getIsColumnHideable?: (column: string) => boolean
) => {
    const selectedData: SelectedDataItem[] = [];

    selectedColumns.forEach((column) => {
        selectedData.push({
            id: column,
            columnName: getColumnName?.(column) || column,
            isVisible: visibleColumns.includes(column),
            filters: selectedFilters?.[column] || [],
            isHideable: getIsColumnHideable?.(column) || true,
        });
    });

    Object.keys(selectedFilters).forEach((column) => {
        if (selectedColumns.includes(column)) {
            return; //Already added to the list in the above forEach so return
        }
        selectedData.push({
            id: column,
            columnName: getColumnName?.(column) || column,
            isVisible: visibleColumns.includes(column),
            filters: selectedFilters[column],
            isHideable: getIsColumnHideable?.(column) || true,
        });
    });

    return selectedData;
};
