import { PARTIES_STRING_SEPERATOR } from '@modules/reports/portfolioReports';
import { GridValueFormatterParams } from '@mui/x-data-grid-premium';
import { DEFAULT_DATE_FORMAT } from '@thought-river/ui-components';
import dayjs from 'dayjs';

export const valueFormatter = (
    params: GridValueFormatterParams,
    columnType: string
) => {
    //Format the Date object using the default date format
    if (columnType === 'date') {
        return dayjs(params.value).format(DEFAULT_DATE_FORMAT);
    }
    //Replace the seperator token for display purposes
    if (params.field === 'own_party' || params.field === 'counter_party') {
        return (params.value as string)?.replace(
            PARTIES_STRING_SEPERATOR,
            ', '
        );
    }
    return params.value;
};
