import { Action } from 'redux';

import { IContractParagraph } from '../Paragraph/Paragraph-types';
import { IParty } from '../Parties/Parties-types';

export const SET_PARTY_PARAGRAPHS = 'SET_PARTY_PARAGRAPHS';
export const SET_PARTY_OCCURRENCES = 'SET_PARTY_OCCURRENCES';
export const SET_PARTY_CONTRACT_WIDTH_IN_PIXELS =
    'SET_PARTY_CONTRACT_WIDTH_IN_PIXELS';

export interface IPartyContractStateProps {
    paragraphs: IContractParagraph[];
    parties: IParty[];
    contractScrollToParty: IParty;
    width: number;
}

export interface IPartyContractProps
    extends IPartyContractStateProps,
        IPartyContractDispatchProps {}

export interface IPartyContractDispatchProps {
    setContractScrollToParty: (party: IParty) => void;
    setPartyContractWidthInPixels: (widthPixels: number) => void;
}

export interface IPartyContractReducer {
    paragraphs: IContractParagraph[];
    widthPixels: number;
}

export interface ISetPartyParagraphsAction extends Action {
    type: typeof SET_PARTY_PARAGRAPHS;
    paragraphs: IContractParagraph[];
}

export interface ISetPartyContractWidthInPixelsAction extends Action {
    type: typeof SET_PARTY_CONTRACT_WIDTH_IN_PIXELS;
    widthPixels: number;
}

export type IPartyContractActionTypes =
    | ISetPartyParagraphsAction
    | ISetPartyContractWidthInPixelsAction;
