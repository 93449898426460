import dayjs from 'dayjs';
import {
    ADD_PROCESSING_CONTRACT,
    IContractPollingProviderActionTypes,
    IContractPollingProviderReducer,
    IProcessingContract,
    IReprocessingContract,
    SET_FAILED_PROCESSED_CONTRACT,
    SET_FAILED_REPROCESSED_CONTRACT,
    SET_POLLING_PROCESSING_CONTRACTS,
    SET_POLLING_REPROCESSING_CONTRACTS,
    SET_REPROCESSING_CONTRACT,
    SET_SUCCESSFUL_PROCESSED_CONTRACT,
    SET_SUCCESSFUL_REPROCESSED_CONTRACT,
    SET_TIMED_OUT_PROCESSING_CONTRACT_ID,
    SET_TIMED_OUT_PROCESSING_CONTRACT_IDS,
    UNSET_NOTIFIED_CONTRACT,
    UNSET_PROCESSING_CONTRACT,
    UNSET_REPROCESSING_CONTRACT,
    UNSET_SUCCESSFUL_REPROCESSED_CONTRACT,
    UPDATE_PROCESSING_CONTRACT,
} from './ContractPollingProvider-types';
import find from 'lodash/find';

export const contractPollingProviderInitialState: IContractPollingProviderReducer =
    {
        failedProcessedContracts: [],
        failedReprocessedContracts: [],
        pollingProcessing: false,
        pollingReprocessing: false,
        processingContracts: [],
        reprocessingContracts: [],
        successfulProcessedContracts: [],
        successfulReprocessedContracts: [],
        timedOutProcessingContractIds: [],
    };

const ContractPollingProviderReducer = (
    state = contractPollingProviderInitialState,
    action: IContractPollingProviderActionTypes
): IContractPollingProviderReducer => {
    let processingContracts: IProcessingContract[];
    let reprocessingContracts: IReprocessingContract[];
    let reprocessingContract: IReprocessingContract;

    switch (action.type) {
        case ADD_PROCESSING_CONTRACT:
            processingContracts = [...state.processingContracts];

            if (
                action.contract.stream &&
                !find(processingContracts, {
                    contractId: action.contract.contractId,
                })
            ) {
                processingContracts.push(action.contract);
            }

            return {
                ...state,
                processingContracts,
            };

        case UNSET_PROCESSING_CONTRACT:
            return {
                ...state,
                processingContracts: [...state.processingContracts].filter(
                    (contract) => contract.contractId !== action.contractId
                ),
            };

        case UPDATE_PROCESSING_CONTRACT:
            processingContracts = [...state.processingContracts];

            for (const [
                index,
                contract,
            ] of state.processingContracts.entries()) {
                if (contract.contractId === action.contract.contractId) {
                    processingContracts[index] = action.contract;
                }
            }

            return {
                ...state,
                processingContracts,
            };

        case SET_SUCCESSFUL_PROCESSED_CONTRACT:
            const successfulProcessedContracts = [
                ...state.successfulProcessedContracts,
            ];

            if (
                !successfulProcessedContracts.find(
                    (contract) =>
                        contract.contractId === action.contract.contractId
                )
            ) {
                successfulProcessedContracts.push({
                    ...action.contract,
                    isPartiallyAssessed: action.contract.isPartiallyAssessed,
                });
            }
            return {
                ...state,
                successfulProcessedContracts,
            };

        case SET_SUCCESSFUL_REPROCESSED_CONTRACT:
            const successfulReprocessedContracts = [
                ...state.successfulReprocessedContracts,
            ];

            if (
                !successfulReprocessedContracts.find(
                    (contract) =>
                        contract.contractId === action.contract.contractId
                )
            ) {
                successfulReprocessedContracts.push({
                    ...action.contract,
                });
            }
            return {
                ...state,
                successfulReprocessedContracts,
            };
        case UNSET_SUCCESSFUL_REPROCESSED_CONTRACT:
            return {
                ...state,
                successfulReprocessedContracts:
                    state.successfulReprocessedContracts.filter(
                        (contract) => contract.contractId !== action.contractId
                    ),
            };

        case SET_FAILED_PROCESSED_CONTRACT:
            const failedProcessedContracts = [
                ...state.failedProcessedContracts,
            ];

            if (
                !failedProcessedContracts.find(
                    (contract) =>
                        contract.contractId === action.contract.contractId
                )
            ) {
                failedProcessedContracts.push(action.contract);
            }
            return {
                ...state,
                failedProcessedContracts,
            };

        case SET_FAILED_REPROCESSED_CONTRACT:
            const failedReprocessedContracts = [
                ...state.failedReprocessedContracts,
            ];

            if (
                !failedReprocessedContracts.find(
                    (contract) =>
                        contract.contractId === action.contract.contractId
                )
            ) {
                failedReprocessedContracts.push(action.contract);
            }
            return {
                ...state,
                failedReprocessedContracts,
            };

        case SET_REPROCESSING_CONTRACT:
            reprocessingContracts = [...state.reprocessingContracts];

            reprocessingContract = find(reprocessingContracts, {
                contractId: action.contractId,
            })!; // Fixme: null checks

            if (!reprocessingContract) {
                reprocessingContracts.push({
                    contractId: action.contractId,
                    contractName: action.contractName,
                    versionId: action.versionId,
                    stream: action.stream,
                    streamId: action.streamId,
                    assessmentCount: action.assessmentCount,
                    queuedAt: dayjs().format(),
                });
            }

            return {
                ...state,
                reprocessingContracts,
            };
        case UNSET_REPROCESSING_CONTRACT:
            return {
                ...state,
                reprocessingContracts: [...state.reprocessingContracts].filter(
                    (contract) => contract.contractId !== action.contractId
                ),
            };

        case SET_TIMED_OUT_PROCESSING_CONTRACT_ID:
            const contractId = action.timedOutProcessingContractId;
            const timedOutProcessingContractIds = [
                ...state.timedOutProcessingContractIds,
            ];

            if (!state.timedOutProcessingContractIds.includes(contractId)) {
                timedOutProcessingContractIds.push(contractId);
            }

            return {
                ...state,
                timedOutProcessingContractIds,
            };
        case SET_TIMED_OUT_PROCESSING_CONTRACT_IDS:
            return {
                ...state,
                timedOutProcessingContractIds:
                    action.timedOutProcessingContractIds,
            };

        case SET_POLLING_PROCESSING_CONTRACTS:
            return {
                ...state,
                pollingProcessing: action.pollingProcessing,
            };
        case SET_POLLING_REPROCESSING_CONTRACTS:
            return {
                ...state,
                pollingReprocessing: action.pollingReprocessing,
            };

        case UNSET_NOTIFIED_CONTRACT:
            return {
                ...state,
                failedProcessedContracts: [
                    ...state.failedProcessedContracts,
                ].filter(
                    (contract) => contract.contractId !== action.contractId
                ),
                failedReprocessedContracts: [
                    ...state.failedReprocessedContracts,
                ].filter(
                    (contract) => contract.contractId !== action.contractId
                ),
                successfulProcessedContracts: [
                    ...state.successfulProcessedContracts,
                ].filter(
                    (contract) => contract.contractId !== action.contractId
                ),
                successfulReprocessedContracts: [
                    ...state.successfulReprocessedContracts,
                ].filter(
                    (contract) => contract.contractId !== action.contractId
                ),
            };
        default:
            return state;
    }
};

export default ContractPollingProviderReducer;
