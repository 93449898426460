import {
    FLIP_GROUPS,
    IPartiesActionTypes,
    IPartiesReducer,
    IParty,
    PartyVariantType,
    RESET_SELECTIONS,
    SET_CONTRACT_SCROLL_TO_PARTY,
    SET_DRAGGED_PARTY,
    SET_EXPANDED_GROUP,
    SET_LOCAL_PARTIES,
    SET_PARTIES,
    SET_SCROLL_TO_PARTY,
} from './Parties-types';
import {
    ADD_PARTY,
    REMOVE_PARTY,
    SET_SELECTED_PARTY,
    UPDATE_PARTY,
} from '../Party/Party-types';
import {
    SET_HIGHLIGHTED_PARTY,
    SET_PARTY_TO_ADD,
} from '../PartyParagraph/PartyParagraph-types';
import { alphabetizeParties, reIndex } from './Parties-helpers';

const initialState: IPartiesReducer = {
    contractScrollToParty: null!, // Fixme: null checks
    draggedParty: null!, // Fixme: null checks
    expandedGroup: null!, // Fixme: null checks
    highlightedParty: null!, // Fixme: null checks
    localParties: [],
    parties: [],
    partyToAdd: '',
    scrollToParty: null!, // Fixme: null checks
    selectedParty: null!, // Fixme: null checks
};

const PartiesReducer = (
    state = initialState,
    action: IPartiesActionTypes
): IPartiesReducer => {
    let parties: IParty[];
    let party: IParty | undefined;

    switch (action.type) {
        case ADD_PARTY:
            parties = [...state.parties];
            const name = action.partyToAdd;
            const type = action.partyType;
            const id = parties.length;

            if (name) {
                party = {
                    id,
                    name,
                    type,
                    originalType: null!, // Fixme: null checks
                    variantType: PartyVariantType.REFERENCE,
                };

                parties.push(party);
            }

            parties = parties.sort(alphabetizeParties).map(reIndex);

            return {
                ...state,
                parties,
                selectedParty: party!, // Fixme: null checks
                scrollToParty: party!, // Fixme: null checks
                partyToAdd: null!, // Fixme: null checks
            };
        case FLIP_GROUPS:
            const { typeA, typeB } = action;

            parties = [...state.parties].map((party) => {
                if (party.type === typeA) {
                    party = { ...party, type: typeB };
                } else if (party.type === typeB) {
                    party = { ...party, type: typeA };
                }
                return party;
            });

            return {
                ...state,
                parties,
            };
        case REMOVE_PARTY:
            const partyToRemove = action.party;

            parties = [...state.parties].filter(
                (party) => party.id !== partyToRemove.id
            );
            parties = parties.sort(alphabetizeParties).map(reIndex);

            return {
                ...state,
                parties,
            };
        case RESET_SELECTIONS:
            return {
                ...state,
                draggedParty: null!, // Fixme: null checks
                expandedGroup: null!, // Fixme: null checks
                highlightedParty: null!, // Fixme: null checks
                partyToAdd: null!, // Fixme: null checks
                selectedParty: null!, // Fixme: null checks
            };
        case SET_EXPANDED_GROUP:
            return {
                ...state,
                expandedGroup: action.expandedGroup,
            };
        case SET_CONTRACT_SCROLL_TO_PARTY:
            return {
                ...state,
                contractScrollToParty: action.party,
            };
        case SET_DRAGGED_PARTY:
            return {
                ...state,
                draggedParty: action.party,
            };
        case SET_HIGHLIGHTED_PARTY:
            return {
                ...state,
                highlightedParty: action.highlightedParty,
            };
        case SET_LOCAL_PARTIES:
            return {
                ...state,
                localParties: action.parties,
            };
        case SET_PARTIES:
            parties = action.parties;

            return {
                ...state,
                parties,
            };
        case SET_PARTY_TO_ADD:
            return {
                ...state,
                partyToAdd: action.partyToAdd,
            };
        case SET_SCROLL_TO_PARTY:
            return {
                ...state,
                scrollToParty: action.party,
            };
        case SET_SELECTED_PARTY:
            return {
                ...state,
                selectedParty: action.selectedParty,
            };
        case UPDATE_PARTY:
            const updatedParty = action.party;
            parties = [...state.parties].map((party) => {
                if (party.id === updatedParty.id) {
                    party = updatedParty;
                }
                return party;
            });

            return {
                ...state,
                parties,
            };
        default:
            return state;
    }
};

export default PartiesReducer;
