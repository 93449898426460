import { ComponentType, useEffect, useState } from 'react';
import { Draw, Grading, RateReview, Warning } from '@mui/icons-material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { ProcessingStep } from '@thought-river/negotiations-common/dist/api/contractManagement';
import styles from './StatusCell.module.scss';
import { Tooltip } from '@thought-river/ui-components';
import classNames from 'classnames';
import {
    capitalize,
    isProcessingStepComplete,
    processingStepLabels,
    ProcessingStepStatusIcon,
} from '@thought-river/negotiations-common';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { getEditPartiesPath } from '@modules/common/helpers';

const getStatusIcon = (status: string) => {
    const className = classNames(styles.icon, styles[status.toLowerCase()]);

    let IconComponent: ComponentType<{ className: string }> | undefined;

    switch (status) {
        case 'Template':
            IconComponent = Grading;
            break;
        case 'Signed':
            IconComponent = Draw;
            break;
    }

    if (IconComponent) {
        return (
            <Tooltip title={status} enterDelay={0} enterNextDelay={0}>
                <div className={styles.iconWithLabel}>
                    <IconComponent className={className} />
                </div>
            </Tooltip>
        );
    }
};

const StatusCell = ({ row }: GridRenderCellParams) => {
    const processingSteps = row.processing_steps as
        | ProcessingStep[]
        | undefined;

    const allStepsComplete =
        !!processingSteps?.length &&
        processingSteps.every(isProcessingStepComplete);

    const partyConfirmationStep = processingSteps?.find(
        (step) => step.name === 'party_confirmation'
    );
    const requiresUserConfirmation =
        partyConfirmationStep?.status === 'in_progress';
    const partiesUiPath = getEditPartiesPath(
        row.contract_uuid,
        row.deal_type.code
    );

    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    }, []);

    const stepsContent = (
        <AnimatePresence>
            {!allStepsComplete && (
                <div className={styles.steps}>
                    {processingSteps?.map((step, index) => (
                        <motion.div
                            key={step.name}
                            animate={{
                                x: 0,
                                opacity: 1,
                                transition: { duration: 0 },
                            }}
                            exit={{
                                x: -14 * index,
                                opacity: 0,
                                transition: {
                                    delay:
                                        0.05 *
                                        (processingSteps.length - 1 - index),
                                    type: 'tween',
                                },
                            }}
                        >
                            <Tooltip
                                key={step.name}
                                title={
                                    processingStepLabels[step.name] ||
                                    capitalize(step.name.replaceAll('_', ' '))
                                }
                                enterDelay={0}
                                enterNextDelay={0}
                            >
                                <ProcessingStepStatusIcon step={step} />
                            </Tooltip>
                        </motion.div>
                    ))}
                </div>
            )}
            {allStepsComplete && (
                <div className={styles.status}>
                    <motion.div
                        className={styles.iconWithLabel}
                        initial={!hasMounted ? false : { scale: 0 }}
                        animate={{ scale: [0, 1.2, 1] }}
                        transition={{
                            delay: (processingSteps.length - 1) * 0.05 + 0.2,
                        }}
                    >
                        <RateReview className={classNames(styles.readyIcon)} />
                        <div className={styles.ellipsis}>Ready for review</div>
                    </motion.div>
                    <div className={styles.statusIcons}>
                        {row.is_signed && getStatusIcon('Signed')}
                        {row.is_template && getStatusIcon('Template')}
                    </div>
                </div>
            )}
        </AnimatePresence>
    );

    return (
        <>
            {requiresUserConfirmation ? (
                <Link
                    to={partiesUiPath}
                    className={classNames(
                        styles.iconWithLabel,
                        styles.partiesAlert
                    )}
                    target="_blank"
                >
                    <Warning className={styles.icon} />
                    <div className={styles.ellipsis}>
                        Awaiting party confirmation
                    </div>
                </Link>
            ) : (
                stepsContent
            )}
        </>
    );
};

export default StatusCell;
