import * as React from 'react';

import {
    ContractSidebarTab,
    IContractSidebarProps,
    IContractSidebarState,
} from './ContractSidebar-types';
import IssueListPane from '../IssueListPane/IssueListPane-container';
import { FEATURE_TOGGLE_SUMMARY_LABEL } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import { doesIntersect } from '../App/App-helpers';
import {
    filterIssue,
    sortByClause,
    sortByFamiliarityDesc,
    sortBySeverity,
    sortByStatus,
    sortBySummaryAsc,
} from '../IssueListPane/IssuesListPane-helpers';
import {
    IIssueFiltersValues,
    IssuesSortOption,
} from '../IssueListFilters/IssueListFilters-types';
import SummaryPane from '../SummaryPane/SummaryPane-container';
import IssuePane from '../IssuePane/IssuePane-container';
import { Tabs } from '@mui/material';
import { analytics } from './ContractSidebar-analytics';
import { ErrorBoundary } from '@modules/common/components/ErrorBoundary';
import {
    ContractIssue,
    FamiliarityLevel,
} from '@thought-river/negotiations-common';
import { Tab } from '@thought-river/ui-components';
import throttle from 'lodash/throttle';

const { OVERVIEW, ISSUES } = ContractSidebarTab;

@analytics()
class ContractSidebar extends React.Component<
    IContractSidebarProps,
    IContractSidebarState
> {
    private sidebarContentRef: React.RefObject<any>;

    constructor(props: IContractSidebarProps) {
        super(props);

        this.onWindowResize = this.onWindowResize.bind(this);
        this.filterPresentIssue = this.filterPresentIssue.bind(this);

        this.state = {
            height: 0,
        };

        this.sidebarContentRef = React.createRef();
    }

    componentDidMount() {
        this.setState({
            height: this.sidebarContentRef.current.clientHeight,
        });

        this.props.setContractSidebarWidthInPixels(
            this.sidebarContentRef.current.clientWidth
        );

        window.addEventListener('resize', this.onWindowResize);
    }

    componentDidUpdate(prevProps: IContractSidebarProps) {
        const { width } = this.props;

        if (prevProps.width !== width && this.sidebarContentRef.current) {
            this.props.setContractSidebarWidthInPixels(
                this.sidebarContentRef.current.clientWidth
            );
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    private onWindowResize = throttle(() => {
        if (!this.sidebarContentRef.current) {
            return;
        }

        this.props.setContractSidebarWidthInPixels(
            this.sidebarContentRef.current.clientWidth
        );
    }, 150);

    private filterPresentIssue(issue: ContractIssue) {
        const { activeIssueFilters, highlightedParagraphs, selectedIssue } =
            this.props;

        const highlightedParagraphIds = highlightedParagraphs.map(
            (paragraph) => paragraph.id
        );

        let issueRelevantClauses;
        //If issue has any linked clauses use those for filtering
        if (issue.paragraphIds.length) {
            issueRelevantClauses = issue.paragraphIds;
        } else {
            //Otherwise use related clauses for filtering
            issueRelevantClauses = issue.relatedParagraphIds;
        }

        if (!filterIssue(issue, activeIssueFilters)) {
            return false;
        }

        if (selectedIssue) {
            return true;
        }

        // If we have highlighted paragraphs, check if the current issue is highlighted
        if (
            highlightedParagraphIds.length &&
            !doesIntersect(issueRelevantClauses, highlightedParagraphIds)
        ) {
            return false;
        }

        return true;
    }

    getActivePane(filteredIssues: ContractIssue[]) {
        const { activeTab, onReprocess } = this.props;

        switch (activeTab) {
            case OVERVIEW:
                return <SummaryPane onReprocess={onReprocess} />;
            case ISSUES:
                return <IssueListPane filteredIssues={filteredIssues} />;
        }
    }

    render() {
        const {
            activeTab,
            issues,
            featureToggles,
            selectedContractIntelligence,
            setActiveContractSidebarTab,
            sortBy,
            width,
            contract,
        } = this.props;

        const summaryLabelToggle = featureToggles.find(
            (toggle) => toggle.feature === FEATURE_TOGGLE_SUMMARY_LABEL
        );

        const summaryLabel =
            summaryLabelToggle?.enabled && summaryLabelToggle.value
                ? summaryLabelToggle.value
                : 'Overview';

        const issuesListLabel = 'Issues List';

        const customStyles = {
            width: `${width}%`,
        };

        let filteredIssues: ContractIssue[] = issues.filter(
            this.filterPresentIssue
        );

        const highlightType = selectedContractIntelligence.highlightType;
        if (highlightType) {
            if (
                highlightType === FamiliarityLevel.UNFAMILIAR ||
                highlightType === FamiliarityLevel.UNCOMMON ||
                highlightType === FamiliarityLevel.COMMON ||
                highlightType === FamiliarityLevel.TEMPLATE
            ) {
                filteredIssues = filteredIssues.filter((issue) => {
                    // If contract is a template, override filters to include all possible familiarity levels
                    const familiarityFilter = contract.isTemplate
                        ? [
                              FamiliarityLevel.COMMON,
                              FamiliarityLevel.TEMPLATE,
                              FamiliarityLevel.UNCOMMON,
                              FamiliarityLevel.UNFAMILIAR,
                          ]
                        : [highlightType];

                    return filterIssue(issue, {
                        familiarity: familiarityFilter,
                    } as IIssueFiltersValues);
                });
            } else {
                filteredIssues = filteredIssues.filter((issue) =>
                    filterIssue(issue, {
                        severity: [highlightType],
                    } as IIssueFiltersValues)
                );
            }
        }

        switch (sortBy) {
            case IssuesSortOption.SEVERITY:
                filteredIssues = filteredIssues.sort(sortBySeverity);
                break;
            case IssuesSortOption.STATUS:
                filteredIssues = filteredIssues.sort(sortByStatus);
                break;
            case IssuesSortOption.FAMILIARITY:
                filteredIssues = filteredIssues.sort(sortByFamiliarityDesc);
                break;
            case IssuesSortOption.ISSUE_SUMMARY_TITLE:
                filteredIssues = filteredIssues.sort(sortBySummaryAsc);
                break;
            default:
                filteredIssues = filteredIssues.sort(sortByClause);
        }

        return (
            <ErrorBoundary>
                <div className="contract-sidebar" style={customStyles}>
                    <Tabs
                        value={activeTab}
                        onChange={(_, selectedTab: ContractSidebarTab) =>
                            setActiveContractSidebarTab(selectedTab)
                        }
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        <Tab
                            label={summaryLabel}
                            data-testid="sidebar-tab-button"
                        />
                        <Tab
                            label={issuesListLabel}
                            data-testid="sidebar-tab-button"
                        />
                    </Tabs>
                    <div
                        className="contract-sidebar-content"
                        ref={this.sidebarContentRef}
                    >
                        {this.getActivePane(filteredIssues)}
                    </div>
                    <IssuePane sortedFilteredIssues={filteredIssues} />
                </div>
            </ErrorBoundary>
        );
    }
}

export default ContractSidebar;
