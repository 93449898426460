import {
    IInternalSharingModalActionTypes,
    IInternalSharingModalReducer,
    SET_INTERNAL_SHARING_MESSAGE,
    SET_INTERNAL_SHARING_REVIEWERS,
} from './InternalSharingModal-types';

const initialState: IInternalSharingModalReducer = {
    message: '',
    reviewers: [],
};

const InternalSharingModalReducer = (
    state = initialState,
    action: IInternalSharingModalActionTypes
): IInternalSharingModalReducer => {
    switch (action.type) {
        case SET_INTERNAL_SHARING_MESSAGE:
            return {
                ...state,
                message: action.message,
            };
        case SET_INTERNAL_SHARING_REVIEWERS:
            return {
                ...state,
                reviewers: action.reviewers,
            };
        default:
            return state;
    }
};

export default InternalSharingModalReducer;
