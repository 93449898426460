import { IReviewStream } from '../../resources/AuthResource-types';
import { IAccountUser } from '../Auth/Auth-types';
import { RouteComponentProps } from 'react-router-dom';
import { IDisplayContract } from '../Contract/Contract-types';
import {
    IProcessingContract,
    IReprocessingContract,
} from '../ContractPollingProvider/ContractPollingProvider-types';
import { IModalDialogButton } from '../ModalDialog/ModalDialog-types';

export const BULK_ACTION_LIMIT = 30;

export interface IContractListTableState {
    expandedContracts: string[];
    scrollTop: number;
    selectedContract: IDisplayContract | undefined;
}

export interface IContractListTableStateProps {
    bulkActions: boolean;
    contracts: IDisplayContract[];
    currentPage: number;
    fetchingContracts: boolean;
    hasNextPage: boolean;
    nextPage: number;
    processingContracts: IProcessingContract[];
    reprocessingContracts: IReprocessingContract[];
    selectAll: boolean;
    selectedContractIds: string[];
    showLoader: boolean;
    streams: IReviewStream[];
    timedOutProcessingContractIds: string[];
    userId: string;
    users: IAccountUser[];
}

export interface IContractListTableReducer {}

export interface IContractListTableDispatchProps {
    loadContracts: (page?: number) => void;
    setContracts: (contracts: IDisplayContract[]) => void;
    setFetchingContracts: (fetchingContracts: boolean) => void;
    updateContract: (
        contractId: string,
        fields: Partial<IDisplayContract>
    ) => void;
    updateContractInList: (
        contractId: string,
        fields: Partial<IDisplayContract>
    ) => void;
    setHasNextPage: (hasNextPage: boolean) => void;
    setReferringPage: (referringPage: string) => void;
    setRequestedPages: (requestedPages: number[]) => void;
    setSelectAllContracts: (
        selectAll: boolean,
        selectableContractIds?: string[]
    ) => void;
    setSelectedContractIds: (contractIds: string[]) => void;
    showDialog: (
        title: string | JSX.Element,
        showCloseButton: boolean,
        component: JSX.Element,
        buttons: IModalDialogButton[],
        height: string | null | undefined,
        customClassname?: string,
        closeOnBlur?: boolean
    ) => void;
}

export interface IContractListTableProps
    extends IContractListTableDispatchProps,
        IContractListTableStateProps,
        RouteComponentProps {}

export type IContractListTableActionTypes = any;
