import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { IRootState } from '../../redux/reducers-types';
import {
    setHighlightIndexAction,
    setIssuesAction,
    setSelectedContractIntelligenceAction,
    setSelectedIssueAction,
    setSelectedParagraphAction,
} from '../Contract/Contract-actions';
import {
    HighlightIndexType,
    ISelectedContractIntelligence,
} from '../Contract/Contract-types';
import {
    setIssuesSortByAction,
    setSelectedIssueFiltersAction,
} from '../IssueListFilters/IssueListFilters-actions';
import {
    IssuesSortOption,
    PresetIssueFilters,
} from '../IssueListFilters/IssueListFilters-types';
import IssueListPane from './IssueListPane';
import {
    IIssueListPaneDispatchProps,
    IIssueListPaneStateProps,
} from './IssueListPane-types';
import { ContractIssue } from '@thought-river/negotiations-common';
import { IssuePaneContext } from '@modules/common/context/IssuePaneProvider';
import { withContext } from '../withContext';

function mapStateToProps(state: IRootState): IIssueListPaneStateProps {
    return {
        activeIssueFilters: state.issueListFilters.activeIssueFilters,
        assessment: state.contract.assessment,
        categories: state.app.categories,
        contract: state.contract.contract,
        highlightedParagraphs: state.contract.highlightedParagraphs,
        featureToggles: state.featureToggles.toggles,
        issues: state.contract.issues,
        lexibleProperties: state.app.properties,
        paragraphs: state.contract.paragraphs,
        properties: state.contract.properties,
        selectedFilterPreset: state.issueListFilters.selectedFilterPreset,
        selectedContractIntelligence:
            state.contract.selectedContractIntelligence,
        selectedVersion: state.contract.selectedVersion,
        sortBy: state.issueListFilters.sortBy,
        themes: state.contract.themes,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IIssueListPaneDispatchProps {
    return {
        setHighlightIndex: (index: number, highlightType: HighlightIndexType) =>
            dispatch(setHighlightIndexAction(index, highlightType)),
        setIssues: (issues: ContractIssue[]) =>
            dispatch(setIssuesAction(issues)),
        setIssuesSortByOption: (sortBy: IssuesSortOption) =>
            dispatch(setIssuesSortByAction(sortBy)),
        setSelectedContractIntelligence: (
            intelligence: ISelectedContractIntelligence
        ) => dispatch(setSelectedContractIntelligenceAction(intelligence)),
        setSelectedIssue: (selectedIssue: ContractIssue) =>
            dispatch(setSelectedIssueAction(selectedIssue)),
        setSelectedIssueFilters: (
            selectedFilterPreset: keyof typeof PresetIssueFilters
        ) => dispatch(setSelectedIssueFiltersAction(selectedFilterPreset)),
        setSelectedParagraph: (...args) =>
            dispatch(setSelectedParagraphAction(...args)),
    };
}

export default withContext(IssuePaneContext)(
    connect(mapStateToProps, mapDispatchToProps)(IssueListPane)
);
