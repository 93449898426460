import { Action } from 'redux';

export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SET_MATCHES_COUNT = 'SET_MATCHES_COUNT';
export const SET_MATCH_INDEX = 'SET_MATCH_INDEX';

export interface IHeatmapSearchBarState {
    expanded: boolean;
}

export interface IHeatmapSearchBarStateProps {
    matchesCount: number;
    matchIndex: number;
    searchTerm: string;
    redlinedText: string;
}

export interface IHeatmapSearchBarReducer {
    searchTerm: string;
    matchesCount: number;
    matchIndex: number;
}

export interface IHeatmapSearchBarDispatchProps {
    setSearchTerm: (searchTerm: string) => void;
    setMatchesCount: (matchesCount: number) => void;
    setMatchIndex: (matchIndex: number) => void;
}

export interface IHeatmapSearchBarProps
    extends IHeatmapSearchBarDispatchProps,
        IHeatmapSearchBarStateProps {
    classes?: string;
    disabled?: boolean;
}

export interface ISetSearchTermAction extends Action {
    type: typeof SET_SEARCH_TERM;
    searchTerm: string;
}

export interface ISetMatchesCountAction extends Action {
    type: typeof SET_MATCHES_COUNT;
    matchesCount: number;
}

export interface ISetMatchIndexAction extends Action {
    type: typeof SET_MATCH_INDEX;
    matchIndex: number;
}

export type IHeatmapSearchBarActionTypes =
    | ISetSearchTermAction
    | ISetMatchesCountAction
    | ISetMatchIndexAction;
