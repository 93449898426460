import { analyticsService } from '../../services/Analytics/Analytics';
import { EVENT_ACTION_LOGOUT } from '../../services/Analytics/Analytics-types';

export function analytics() {
    return (target: any) => {
        const onSignOut = target.prototype.onSignOut;

        target.prototype.onSignOut = function () {
            analyticsService.recordEvent(EVENT_ACTION_LOGOUT, {});

            onSignOut.apply(this, arguments);
        };
    };
}
