import Split from 'react-split';
import { ContractPane } from './Contract-types';

interface IContractPanesProps {
    children?: React.ReactNode;
    setPaneWidth: (pane: ContractPane, width: number) => void;
    heatmapWidth: number;
    sidebarWidth: number;
}

const ContractPanes = ({
    children,
    setPaneWidth,
    heatmapWidth,
    sidebarWidth,
}: IContractPanesProps) => (
    <Split
        className="split"
        sizes={[heatmapWidth, sidebarWidth]}
        minSize={[830, 525]}
        gutterSize={2}
        snapOffset={0}
        onDragEnd={(sizes) => {
            setPaneWidth(ContractPane.HEATMAP, sizes[0]);
            setPaneWidth(ContractPane.SIDEBAR, sizes[1]);
        }}
    >
        {children}
    </Split>
);

export default ContractPanes;
