import { Alert, Button, TextField } from '@thought-river/ui-components';
import styles from './ApiKeysPage.module.scss';
import {
    useGetMe,
    useInitializeApiKeys,
    useRotateApiKeys,
} from '@thought-river/negotiations-common/dist/api/landlord';
import { useState } from 'react';
import { IRootState } from '../../../../redux/reducers-types';
import { useSelector } from 'react-redux';
import { CopyToClipboardButton } from '@modules/common/components/CopyToClipboardButton';

const ApiKeysPage = () => {
    const [apiKey1, setApiKey1] = useState('');
    const [apiKey2, setApiKey2] = useState('');

    const { accountCode } = useSelector((state: IRootState) => state.auth);
    const { data: currentUser } = useGetMe(
        {},
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            refetchOnReconnect: false,
            refetchIntervalInBackground: false,
        }
    );

    const { mutate: generateApiKeys } = useInitializeApiKeys({});
    const { mutate: rotateApiKeys } = useRotateApiKeys({});

    const handleGenerateKeysBtnClick = () => {
        generateApiKeys(
            {},
            {
                onSuccess: (data) => {
                    setApiKey1(data.api_key_1);
                    setApiKey2(data.api_key_2);
                },
            }
        );
    };

    const handleRotateKeysBtnClick = () => {
        rotateApiKeys(
            {},
            {
                onSuccess: (data) => {
                    setApiKey2(data.new_api_key);
                },
            }
        );
    };

    const hasApiKeys = currentUser?.find(
        (user) => user.tenant.account_code === accountCode
    )?.has_api_keys;

    const description = hasApiKeys ? (
        <div>
            Clicking Rotate keys will set the Primary Key to the value of the
            Secondary Key and then the Secondary Key is assigned a new value.
            <br /> <br />
            This will mean your current Primary Key will no longer work. Please
            ensure your application is currently configured to use your current
            Secondary API Key.
        </div>
    ) : (
        <div>
            Clicking Generate keys will produce a Primary and Secondary API Key.
            These keys are unique to you and should be stored in a safe place.
            They allow full access to your ThoughtRiver account. <br />
            <br /> Once generated it is not possible to view these keys again,
            so please ensure you copy them and store them safely.
        </div>
    );

    const alert = (() => {
        if (apiKey1 && apiKey2) {
            return (
                <Alert severity="info">
                    Please make sure to save these keys as you will not be able
                    to retrieve them after closing this page.
                </Alert>
            );
        } else if (apiKey2) {
            return (
                <Alert severity="info">
                    Please make sure to save this key as you will not be able to
                    retrieve it after closing this page.
                </Alert>
            );
        }
    })();

    const apiKey1TextField = apiKey1 && (
        <TextField
            label="Primary API Key"
            value={apiKey1}
            multiline
            inputProps={{ readOnly: true }}
            classes={{ label: { root: styles.textFieldLabel } }}
            endAdornment={<CopyToClipboardButton textToCopy={apiKey1} />}
        />
    );

    const apiKey2TextField = apiKey2 && (
        <TextField
            label="Secondary API Key"
            value={apiKey2}
            multiline
            inputProps={{ readOnly: true }}
            classes={{ label: { root: styles.textFieldLabel } }}
            endAdornment={<CopyToClipboardButton textToCopy={apiKey2} />}
        />
    );

    const button = hasApiKeys ? (
        <Button className={styles.button} onClick={handleRotateKeysBtnClick}>
            Rotate keys
        </Button>
    ) : (
        <Button
            className={styles.button}
            onClick={handleGenerateKeysBtnClick}
            disabled={!!apiKey1}
        >
            Generate keys
        </Button>
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>API Keys</div>
            {description}
            {alert}
            {apiKey1TextField}
            {apiKey2TextField}
            {button}
        </div>
    );
};

export default ApiKeysPage;
