import { analyticsService } from '../../services/Analytics/Analytics';
import { EVENT_ACTION_INITIATE_SET_PARTIES_FLOW } from '../../services/Analytics/Analytics-types';
import { IDisplayContract } from '../Contract/Contract-types';

export function analytics() {
    return (target: any) => {
        const onClickSetParties = target.prototype.onClickSetParties;

        target.prototype.onClickSetParties = function (
            contract: IDisplayContract
        ) {
            analyticsService.recordEvent(
                EVENT_ACTION_INITIATE_SET_PARTIES_FLOW,

                {
                    ContractId: contract.id,
                    Stream: contract.streamCode,
                    VersionId: contract.latestVersion?.id ?? '',
                    VersionNumber: contract.latestVersion?.versionNumber ?? '',
                }
            );

            onClickSetParties.apply(this, arguments);
        };
    };
}
