import { ContractIssue } from '@thought-river/negotiations-common';
import {
    IDisplayContract,
    ISelectedContractIntelligence,
} from '../Contract/Contract-types';
import { IFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import {
    IIssueFiltersValues,
    IssuesSortOption,
} from '../IssueListFilters/IssueListFilters-types';
import { IContractParagraph } from '../Paragraph/Paragraph-types';

export const SET_ACTIVE_CONTRACT_SIDEBAR_TAB =
    'SET_ACTIVE_CONTRACT_SIDEBAR_TAB';
export const SET_CONTRACT_SIDEBAR_WIDTH_IN_PIXELS =
    'SET_CONTRACT_SIDEBAR_WIDTH_IN_PIXELS';

export enum ContractSidebarTab {
    OVERVIEW = 0,
    ISSUES = 1,
}

export interface IContractSidebarState {
    height: number;
}

export interface IContractSidebarStateProps {
    activeIssueFilters: IIssueFiltersValues;
    activeTab: ContractSidebarTab;
    contract: IDisplayContract;
    featureToggles: IFeatureToggle[];
    highlightedParagraphs: IContractParagraph[];
    issues: ContractIssue[];
    paragraphs: IContractParagraph[];
    selectedContractIntelligence: ISelectedContractIntelligence;
    selectedIssue: ContractIssue;
    selectedPropertyCode: string;
    sortBy: IssuesSortOption;
    width: number;
    widthPixels: number;
}

export interface IContractSidebarReducer {
    activeTab: ContractSidebarTab;
    widthPixels: number;
}

export interface IContractSidebarDispatchProps {
    setActiveContractSidebarTab: (activeTab: ContractSidebarTab) => void;
    setContractSidebarWidthInPixels: (widthPixels: number) => void;
}

export interface IContractSidebarProps
    extends IContractSidebarDispatchProps,
        IContractSidebarStateProps {
    onReprocess: () => void;
}

export interface ISetActiveContractSiebarTabAction {
    type: typeof SET_ACTIVE_CONTRACT_SIDEBAR_TAB;
    activeTab: ContractSidebarTab;
}

export interface ISetContractSidebarWidthInPixelsAction {
    type: typeof SET_CONTRACT_SIDEBAR_WIDTH_IN_PIXELS;
    widthPixels: number;
}

export type IContractSidebarActionTypes =
    | ISetActiveContractSiebarTabAction
    | ISetContractSidebarWidthInPixelsAction;
