import { useState } from 'react';
import * as React from 'react';
import { Button } from '@mui/material';
import './CustomButton.scss';
import {
    IAnimatedButtonProps,
    ICustomButtonProps,
    ISimpleButtonProps,
} from './CustomButton-types';

const clearOptionalTimeout = (timeoutId: NodeJS.Timeout | null) => {
    if (timeoutId) {
        clearTimeout(timeoutId);
    }
};

const AnimatedButton = (props: IAnimatedButtonProps) => {
    const [animating, setAnimating] = useState(false);
    const [endTimeout, setEndTimeout] = useState<NodeJS.Timeout | null>(null);
    const [resetTimeout, setResetTimeout] = useState<NodeJS.Timeout | null>(
        null
    );
    const [resetting, setResetting] = useState(false);
    const [startTimeout, setStartTimeout] = useState<NodeJS.Timeout | null>(
        null
    );

    const {
        // Removes animatedButton property from props so we can pass the rest of props to the child, otherwise it complains it doesn't recognize the custom props
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        animatedButton,
        buttonType = 'dark',
        callback,
        duration = 4250,
        iconBefore,
        iconAfter,
        textBefore,
        textAfter,
        ...otherProps
    } = props;

    const animate = () => {
        clearOptionalTimeout(startTimeout);
        clearOptionalTimeout(endTimeout);
        clearOptionalTimeout(resetTimeout);

        const startTimeoutFn = setTimeout(() => {
            setAnimating(false);
        }, duration);

        const resetTimeoutFn = setTimeout(() => {
            setResetting(true);
        }, duration - 250);

        const endTimeoutFn = setTimeout(() => {
            setResetting(false);
        }, duration + 250);

        setAnimating(true);
        setResetting(false);
        setStartTimeout(startTimeoutFn);
        setEndTimeout(endTimeoutFn);
        setResetTimeout(resetTimeoutFn);
    };

    const IconBefore = React.cloneElement(iconBefore, {
        ...iconBefore.props,
        className: 'animated-btn-icon animated-btn-icon-before',
    });

    const IconAfter = React.cloneElement(iconAfter, {
        ...iconAfter.props,
        className: 'animated-btn-icon animated-btn-icon-after',
    });

    return (
        <Button
            {...otherProps}
            className={[
                'custom-button',
                'animated-button',
                animating ? 'animating' : '',
                resetting ? 'resetting' : '',
                buttonType || '',
                props.className,
            ].join(' ')}
            onMouseDown={(event: React.MouseEvent) => {
                animate();
                callback(event);
            }}
            variant="contained"
        >
            {IconBefore}
            {IconAfter}
            <span
                className="animated-btn-text"
                data-text-before={textBefore}
                data-text-after={textAfter}
            />
        </Button>
    );
};

const SimpleButton = (props: ISimpleButtonProps) => {
    const { buttonType = 'dark', ...otherProps } = props;

    return (
        <Button
            {...otherProps}
            className={[
                'custom-button',
                buttonType || '',
                props.className,
            ].join(' ')}
        />
    );
};

const CustomButton = (props: ICustomButtonProps) =>
    props.animatedButton ? (
        <AnimatedButton {...(props as IAnimatedButtonProps)} />
    ) : (
        <SimpleButton {...(props as ISimpleButtonProps)} />
    );

export default CustomButton;
