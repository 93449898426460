import { useAppSelector } from '@modules/common/hooks';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import flagsmith from 'flagsmith';
import { useEffect, useState } from 'react';
import { IFeatureToggle } from '../../../../components/FeatureToggleProvider/FeatureToggleProvider-types';
import { ListItem } from '../ListItem';
import styles from './FlagsList.module.scss';

interface FlagsListProps {
    featureToggles: IFeatureToggle[];
}

const getFlagValue = (toggle: IFeatureToggle) => {
    if (!toggle.value) {
        return null;
    }

    try {
        return `Value: ${JSON.stringify(toggle.value)}`;
    } catch {
        return 'Invalid JSON value';
    }
};

const FlagsList = ({ featureToggles }: FlagsListProps) => {
    const userId = useAppSelector((state) => state.auth.userId);

    const [flagsmithEnvironment, setFlagsmithEnvironment] = useState('');

    useEffect(() => {
        setFlagsmithEnvironment(flagsmith.getState().environmentID);
    }, []);

    return (
        <>
            <div className={styles.description}>
                Displaying flag statuses for user {userId} in environment ID{' '}
                {flagsmithEnvironment}
            </div>
            {featureToggles.map((toggle) => (
                <ListItem
                    key={toggle.feature}
                    name={toggle.feature}
                    value={
                        <div className={styles.value}>
                            {toggle.enabled ? (
                                <CheckCircle />
                            ) : (
                                <RadioButtonUnchecked />
                            )}
                            {toggle.value && (
                                <div className={styles.ellipsis}>
                                    {getFlagValue(toggle)}
                                </div>
                            )}
                        </div>
                    }
                    nameWidth={300}
                    copyableName
                    copyableValue={false}
                />
            ))}
        </>
    );
};

export default FlagsList;
