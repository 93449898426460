import { Download, ExpandMore } from '@mui/icons-material';
import {
    Icon,
    IconButton,
    OverflowMenu,
    OverflowMenuItem,
    Tooltip,
} from '@thought-river/ui-components';
import { useState } from 'react';
import { IDownloadButtonProps } from './DownloadButton-types';
import { EXPORT_TO_PDF_BUTTON_ID } from '../../IssuesReport/HybridReportDataGridToolbar/HybridReportDataGridToolbar-types';
import { getFileType } from './DownloadButton-helpers';

import styles from './DownloadButton.module.scss';
import { FileFormat } from '@modules/common/types';
import { downloadContract } from '@modules/common/helpers';

const DownloadButton = ({
    contract,
    version,
    formats,
    isReportTab,
    onPrimaryDownloadClick,
    onSecondaryDownloadClick,
}: IDownloadButtonProps) => {
    const [isDownloading, setIsDownloading] = useState(false);

    if (!formats.length) {
        return null;
    }

    const handleDownloadFile = async (format: FileFormat) => {
        if (isDownloading) {
            return;
        }

        setIsDownloading(true);
        await downloadContract(version.id, format, contract.contractName);
        setIsDownloading(false);
    };

    const handleClick = async () => {
        onPrimaryDownloadClick();

        if (isReportTab) {
            const exportToPdfButton = document.getElementById(
                EXPORT_TO_PDF_BUTTON_ID
            );

            if (exportToPdfButton) {
                exportToPdfButton.click();
            }
        } else {
            // Download the first available format
            handleDownloadFile(formats[0]);
        }
    };

    const menuItems = [
        formats.map<OverflowMenuItem>((format) => ({
            label: `Download as ${getFileType(format)}`,
            icon: <Download />,
            onClick: () => {
                onSecondaryDownloadClick();
                handleDownloadFile(format);
            },
        })),
    ];

    return (
        <div className={styles.wrapper}>
            <Tooltip
                title={
                    isReportTab
                        ? 'Export Report as PDF'
                        : `Download as ${getFileType(formats[0])}`
                }
            >
                <IconButton size="medium" onClick={handleClick}>
                    <Icon type="download" />
                </IconButton>
            </Tooltip>

            {!isReportTab && (
                <OverflowMenu
                    button={
                        <button className={styles.expand}>
                            <ExpandMore />
                        </button>
                    }
                    menuItems={menuItems}
                />
            )}
        </div>
    );
};

export default DownloadButton;
