import pdfMake from 'pdfmake/build/pdfmake';
import { Content, TableCell, TDocumentDefinitions } from 'pdfmake/interfaces';

import { IIssuesReportOrder } from './hybridReportGenerator-types';
import { IDisplayContract } from '../components/Contract/Contract-types';
import {
    IParty,
    PartiesType,
    PartyVariantType,
} from '../components/Parties/Parties-types';
import { getFirstPartyName } from '../components/IssuesReport/IssuesReport-helpers';
import {
    snakeCaseToLabel,
    stripExtension,
} from '../components/App/App-helpers';
import logo from '../assets/report/logo-dark.png';
import { PresetIssueFilters } from '../components/IssueListFilters/IssueListFilters-types';

import imageArrowDown from '../assets/report/arrow-down.png';
import imageArrowUp from '../assets/report/arrow-up.png';
import imageSeverityLevelHigh from '../assets/report/icon-severity-high.png';
import imageSeverityLevelLow from '../assets/report/icon-severity-low.png';
import imageSeverityLevelMedium from '../assets/report/icon-severity-medium.png';
import imageSeverityLevelOkay from '../assets/report/icon-severity-okay.png';
import imageSeverityLevelShowstopper from '../assets/report/icon-severity-showstopper.png';
import imageSeverityLevelUnscored from '../assets/report/icon-severity-unscored.png';
import imageStatusClosed from '../assets/report/icon-status-closed.png';
import imageStatusIgnored from '../assets/report/icon-status-ignored.png';
import imageStatusOpen from '../assets/report/icon-status-open.png';
import imageFamiliarityTemplate from '../assets/report/familiarity_dot_template.png';
import imageFamiliarityCommon from '../assets/report/familiarity_dot_common.png';
import imageFamiliarityUncommon from '../assets/report/familiarity_dot_uncommon.png';
import imageFamiliarityUnfamiliar from '../assets/report/familiarity_dot_unfamiliar.png';
import {
    IIssuesTableColumn,
    IssuesReportPropertyLabels,
} from '../components/IssuesReport/IssuesReport-types';
import { fonts } from './reportFonts';
import {
    EXTERNAL_COMMENTS,
    INTERNAL_GUIDANCE,
} from '../components/App/App-types';
import {
    IHybridReportGenerator,
    IHybridReportGeneratorStatic,
} from './hybridReportGenerator-types';
import {
    IContractReportRow,
    IssuesTableHeader,
    SortOrder,
} from '../components/IssuesReport/HybridReportDataGrid/HybridReportDataGrid-types';
import { IContractParagraph } from '../components/Paragraph/Paragraph-types';
import { DEFAULT_DATE_FORMAT } from '@thought-river/ui-components';
import dayjs from 'dayjs';
import {
    capitalize,
    FamiliarityLevel,
    getFamiliarityLevelLabel,
    IssueSeverityLevel,
    IssueStatus,
} from '@thought-river/negotiations-common';
import htmlToPdfmake from 'html-to-pdfmake';

const { OWN_PARTIES, COUNTER_PARTIES } = PartiesType;

const { FORMAL } = PartyVariantType;

const HybridReportGenerator: IHybridReportGeneratorStatic = class HybridReportGenerator
    implements IHybridReportGenerator
{
    static generateFooter(currentPage: number, pageCount: number): any {
        return {
            color: '#495961',
            columns: [
                {
                    text: 'Created with ',
                    width: 65,
                },
                {
                    height: 15,
                    image: logo,
                    width: 70,
                    margin: [0, -2, 0, 0],
                },
                {
                    text: currentPage.toString() + ' of ' + pageCount,
                    alignment: 'right',
                },
            ],
            margin: [40, 0],
            fontSize: 10,
        };
    }

    static generateIssuesReport(
        contract: IDisplayContract,
        parties: IParty[],
        selectedView: PresetIssueFilters,
        rows: IContractReportRow[],
        columns: IIssuesTableColumn[],
        order: IIssuesReportOrder,
        paragraphs: IContractParagraph[]
    ): void {
        pdfMake.vfs = fonts;

        pdfMake.fonts = {
            Lato: {
                normal: 'Lato-Regular.ttf',
                bold: 'Lato-Bold.ttf',
                italics: 'Lato-Italic.ttf',
                bolditalics: 'Lato-BoldItalic.ttf',
            },
        };

        const options: TDocumentDefinitions = {
            pageOrientation: 'landscape',
            pageMargins: [40, 60, 40, 40],
            header: this.generateIssuesReportHeader(contract.contractName),
            footer: function (currentPage, pageCount) {
                return HybridReportGenerator.generateFooter(
                    currentPage,
                    pageCount
                );
            },
            content: [
                this.generateIssuesReportParties(parties),
                this.generateIssuesReportSelectedView(selectedView),
                this.generateIssuesReportTable(
                    columns,
                    rows,
                    order,
                    paragraphs
                ),
            ],
            defaultStyle: {
                font: 'Lato',
            },
        };

        const filename = `${stripExtension(
            contract.contractName
        )}_IssuesReport_${dayjs().format('DD_MMM_YYYY')}`;

        pdfMake.createPdf(options).download(filename);
    }

    static generateIssuesReportHeader(contractName: string) {
        return {
            color: '#495961',
            table: {
                widths: ['*', 'auto'],
                body: [
                    [
                        {
                            text: ' ',
                            borderColor: ['white', 'white', 'white', 'white'],
                            fontSize: 3,
                        },
                        {
                            text: ' ',
                            borderColor: ['white', 'white', 'white', 'white'],
                            fontSize: 3,
                        },
                    ],
                    [
                        {
                            bold: true,
                            borderColor: ['white', 'white', 'white', 'white'],
                            fontSize: 10,
                            text: contractName,
                            margin: [40, 0, 0, 0],
                        },
                        {
                            alignment: 'right',
                            text: `Date: ${dayjs().format(
                                DEFAULT_DATE_FORMAT
                            )}`,
                            borderColor: ['white', 'white', 'white', 'white'],
                            margin: [0, 0, 40, 0],
                            fontSize: 10,
                        },
                    ],
                    [
                        {
                            text: ' ',
                            borderColor: ['white', 'white', 'white', '#F5F5F5'],
                            fontSize: 3,
                        },
                        {
                            text: ' ',
                            borderColor: ['white', 'white', 'white', '#F5F5F5'],
                            fontSize: 3,
                        },
                    ],
                ],
            },
        };
    }

    static generateIssuesReportParties(parties: IParty[]): Content {
        const ownParty = getFirstPartyName(parties, OWN_PARTIES, FORMAL);
        const counterParty = getFirstPartyName(
            parties,
            COUNTER_PARTIES,
            FORMAL
        );
        const backgroundColor = '#f8fcff';
        const transparentBorders = [
            backgroundColor,
            backgroundColor,
            backgroundColor,
            backgroundColor,
        ];
        const underlineColor = '#e5e5e5';
        const emptyCell = {
            borderColor: transparentBorders,
            fillColor: backgroundColor,
            fontSize: 8,
            text: ' ',
        };

        return {
            color: '#495961',
            table: {
                body: [
                    [
                        { ...emptyCell },
                        { ...emptyCell },
                        { ...emptyCell },
                        { ...emptyCell },
                        { ...emptyCell },
                    ],
                    [
                        { ...emptyCell },
                        {
                            borderColor: transparentBorders,
                            color: '#899499',
                            fillColor: backgroundColor,
                            fontSize: 8,
                            text: 'Own Party',
                        },
                        { ...emptyCell },
                        {
                            borderColor: transparentBorders,
                            color: '#899499',
                            fillColor: backgroundColor,
                            fontSize: 8,
                            text: 'Counter Party',
                        },
                        { ...emptyCell },
                    ],
                    [
                        { ...emptyCell },
                        {
                            borderColor: [
                                backgroundColor,
                                backgroundColor,
                                backgroundColor,
                                underlineColor,
                            ],
                            fillColor: backgroundColor,
                            fontSize: 10,
                            text: ownParty,
                        },
                        { ...emptyCell },
                        {
                            borderColor: [
                                backgroundColor,
                                backgroundColor,
                                backgroundColor,
                                underlineColor,
                            ],
                            fillColor: backgroundColor,
                            fontSize: 10,
                            text: counterParty,
                        },
                        { ...emptyCell },
                    ],
                    [
                        { ...emptyCell },
                        { ...emptyCell },
                        { ...emptyCell },
                        { ...emptyCell },
                        { ...emptyCell },
                    ],
                ],
                widths: [15, '*', 10, '*', 15],
            },
        };
    }

    static generateIssuesReportSelectedView(
        selectedView: PresetIssueFilters
    ): Content {
        const backgroundColor = 'white';
        const transparentBorders = [
            backgroundColor,
            backgroundColor,
            backgroundColor,
            backgroundColor,
        ];
        const underlineColor = '#e5e5e5';

        return {
            color: '#495961',
            table: {
                body: [
                    [
                        {
                            borderColor: transparentBorders,
                            color: '#899499',
                            fontSize: 8,
                            margin: [0, 20, 0, 0],
                            text: 'Views',
                        },
                    ],
                    [
                        {
                            borderColor: [
                                backgroundColor,
                                backgroundColor,
                                backgroundColor,
                                underlineColor,
                            ],
                            fillColor: backgroundColor,
                            fontSize: 10,
                            text: selectedView ?? '-',
                        },
                    ],
                    [
                        {
                            borderColor: transparentBorders,
                            fillColor: backgroundColor,
                            fontSize: 10,
                            text: ' ',
                        },
                    ],
                ],
                widths: [250],
            },
        };
    }

    static generateIssuesTableHeader(
        name: string,
        displayLabel: string,
        order: IIssuesReportOrder,
        index: number,
        isLast: boolean = false
    ): TableCell {
        const backgroundColor = '#F9FAFA';
        const transparentBorders = [
            backgroundColor,
            backgroundColor,
            backgroundColor,
            backgroundColor,
        ];
        const headerOptions = {
            bold: true,
            borderColor: transparentBorders,
            fillColor: backgroundColor,
            fontSize: 10,
            margin: [!index ? 10 : 0, 10, isLast ? 10 : 0, 10],
        };

        const content: any[] = [
            {
                color: '#495961',
                text: snakeCaseToLabel(displayLabel).toUpperCase(),
            },
        ];

        if (order.orderBy === name) {
            content[0] = {
                ...content[0],
                color: '#5099fd',
                width: 'auto',
            };
            content.push({
                image:
                    order.order === SortOrder.ASC
                        ? imageArrowUp
                        : imageArrowDown,
                margin: [5, 3, 0, 0],
                height: 8,
                width: 8,
            });
        }

        return {
            ...headerOptions,
            columns: content,
        };
    }

    static generateIssuesTableRow(
        row: IContractReportRow,
        columns: IIssuesTableColumn[],
        linkedParagraphs: IContractParagraph[]
    ): TableCell[] {
        const backgroundColor = 'white';
        const transparentBorders = [
            backgroundColor,
            backgroundColor,
            backgroundColor,
            backgroundColor,
        ];
        const cellOptions = {
            borderColor: transparentBorders,
            fillColor: backgroundColor,
            fontSize: 10,
            margin: [0, 10, 0, 10],
        };
        const iconOptions = {
            height: 10,
            margin: [0, 2, 0, 0],
            fontSize: 10,
            width: 10,
        };
        const statusIcon = HybridReportGenerator.getIssueStatusIcon(
            row.status!
        ); // Fixme: null checks
        const statusIconData = statusIcon
            ? {
                  ...iconOptions,
                  ...{ image: statusIcon },
              }
            : null;
        const severityIcon = HybridReportGenerator.getIssueSeverityIcon(
            row.severityLevel! // Fixme: null checks
        );
        const severityIconData = severityIcon
            ? {
                  ...iconOptions,
                  ...{ image: severityIcon },
              }
            : null;
        const familiarityIcon = HybridReportGenerator.getFamiliarityIcon(
            row.familiarity
        );
        const familiarityIconData = familiarityIcon
            ? {
                  ...iconOptions,
                  ...{ image: familiarityIcon },
              }
            : null;

        const cells: TableCell[] = [];

        const htmlDescription = htmlToPdfmake(row.description);
        const description = Array.isArray(htmlDescription)
            ? { stack: htmlDescription }
            : htmlDescription;

        const htmlNotes = htmlToPdfmake(row.notes);
        const notes = Array.isArray(htmlNotes)
            ? { stack: htmlNotes }
            : htmlNotes;

        const htmlSuggestions = htmlToPdfmake(row.selectedSuggestion || '-');
        const suggestions = Array.isArray(htmlSuggestions)
            ? { stack: htmlSuggestions }
            : htmlSuggestions;

        const htmlLinkedClausesText = htmlToPdfmake(
            // @ts-ignore
            linkedParagraphs.map(
                (paragraph) =>
                    `<div>${paragraph.clauseNumber}</div><div>${paragraph.text}</div>`
            )
        );
        // @ts-ignore
        htmlLinkedClausesText.forEach((element: { text: string }) => {
            if (element.text === ',') {
                element.text = ' ';
            }
        });
        const linkedClausesText = { stack: htmlLinkedClausesText };

        const cellsData = {
            [IssuesTableHeader.STATUS]: {
                ...cellOptions,
                margin: [10, 10, 0, 10],
                columns: [
                    statusIconData,
                    {
                        margin: [5, 0, 0, 0],
                        text: row.status && capitalize(row.status),
                    },
                ].filter((data) => data),
            },
            [IssuesTableHeader.IMPORTANCE]: {
                ...cellOptions,
                columns: [
                    severityIconData,
                    {
                        margin: [5, 0, 0, 0],
                        text:
                            row.severityLevel && capitalize(row.severityLevel),
                    },
                ].filter((data) => data),
            },
            [IssuesTableHeader.FAMILIARITY]: {
                ...cellOptions,
                columns: [
                    familiarityIconData,
                    {
                        margin: [5, 0, 0, 0],
                        text: getFamiliarityLevelLabel(row.familiarity),
                    },
                ].filter((data) => data),
            },
            [IssuesTableHeader.LINKED_CLAUSES]: {
                ...cellOptions,
                text: row.linkedParagraphClauses.length
                    ? row.linkedParagraphClauses
                          .map((clause) => clause.clauseNumber)
                          .join(' ')
                    : '-',
            },
            [IssuesTableHeader.LINKED_CLAUSES_TEXT]: {
                ...cellOptions,
                ...(linkedParagraphs.length
                    ? { ...linkedClausesText }
                    : { text: '-' }),
            },
            [IssuesTableHeader.SUGGESTIONS]: {
                ...cellOptions,
                // @ts-ignore
                ...suggestions,
            },
            [IssuesTableHeader.SUMMARY]: {
                ...cellOptions,
                text: row.title,
            },
            [IssuesTableHeader.DESCRIPTION]: {
                ...cellOptions,
                // @ts-ignore
                ...description,
            },
            [IssuesTableHeader.NOTES]: {
                ...cellOptions,
                // @ts-ignore
                ...notes,
            },
            [IssuesTableHeader.LAST_UPDATED]: {
                ...cellOptions,
                margin: [0, 10, 10, 10],
                text: dayjs(row.issueUpdatedAt).format(DEFAULT_DATE_FORMAT),
            },
            [IssuesTableHeader.PROPERTY_ANSWER]: {
                ...cellOptions,
                text: row.propertyValue,
            },
            [IssuesTableHeader.PROPERTY_CODE]: {
                ...cellOptions,
                text: row.propertyCode,
            },
            [IssuesTableHeader.PROPERTY_LABEL]: {
                ...cellOptions,
                text: row.propertyLabel,
            },
            [IssuesTableHeader.PROPERTY_NAME]: {
                ...cellOptions,
                text: row.propertyName,
            },
            [IssuesTableHeader.PROPERTY_QUESTION]: {
                ...cellOptions,
                text: row.propertyQuestion,
            },
            [IssuesTableHeader.PROPERTY_THEME]: {
                ...cellOptions,
                text: row.propertyTheme?.name || '-',
            },
        };

        if (
            columns.findIndex(
                (column) => column.label === IssuesTableHeader.NOTES
            ) === -1
        ) {
            delete cellsData[IssuesTableHeader.NOTES];
        }

        for (const column of columns) {
            if (column.active && cellsData[column.label]) {
                cells.push(cellsData[column.label]);
            }
        }

        return cells;
    }

    static resolveColumnHeaderLabel(header: IssuesTableHeader): string {
        let label: string = header;

        switch (header) {
            case IssuesTableHeader.DESCRIPTION:
                label = INTERNAL_GUIDANCE;
                break;
            case IssuesTableHeader.NOTES:
                label = EXTERNAL_COMMENTS;
                break;
            case IssuesTableHeader.PROPERTY_ANSWER:
                label =
                    IssuesReportPropertyLabels[
                        IssuesTableHeader.PROPERTY_ANSWER
                    ];
                break;
            case IssuesTableHeader.PROPERTY_CODE:
                label =
                    IssuesReportPropertyLabels[IssuesTableHeader.PROPERTY_CODE];
                break;
            case IssuesTableHeader.PROPERTY_NAME:
                label =
                    IssuesReportPropertyLabels[IssuesTableHeader.PROPERTY_NAME];
                break;
            case IssuesTableHeader.PROPERTY_QUESTION:
                label =
                    IssuesReportPropertyLabels[
                        IssuesTableHeader.PROPERTY_QUESTION
                    ];
                break;
            case IssuesTableHeader.PROPERTY_LABEL:
                label =
                    IssuesReportPropertyLabels[
                        IssuesTableHeader.PROPERTY_LABEL
                    ];
                break;
            case IssuesTableHeader.PROPERTY_THEME:
                label =
                    IssuesReportPropertyLabels[
                        IssuesTableHeader.PROPERTY_THEME
                    ];
                break;
        }

        return label;
    }

    static generateIssuesReportTable(
        columns: IIssuesTableColumn[],
        rows: IContractReportRow[],
        order: IIssuesReportOrder,
        paragraphs: IContractParagraph[]
    ): Content {
        const activeColumns = columns.filter((column) => column.active);

        const tableRows = rows.map((row) => {
            const linkedParagraphs = row.paragraphOriginUuids.length
                ? paragraphs.filter((paragraph) =>
                      row.paragraphOriginUuids.includes(paragraph.originUuid)
                  )
                : [];
            return this.generateIssuesTableRow(row, columns, linkedParagraphs);
        });

        return {
            color: '#495961',
            table: {
                body: [
                    activeColumns.map((column, index) => {
                        const displayLabel = this.resolveColumnHeaderLabel(
                            column.label
                        );

                        return HybridReportGenerator.generateIssuesTableHeader(
                            column.label,
                            displayLabel,
                            order,
                            index,
                            index === activeColumns.length - 1
                        );
                    }),
                    ...tableRows,
                ],
                headerRows: 1,
            },
        };
    }

    static getFamiliarityIcon(familiarity: FamiliarityLevel): string | null {
        // TODO: update to correct images
        switch (familiarity) {
            case FamiliarityLevel.UNFAMILIAR:
                return imageFamiliarityUnfamiliar;
            case FamiliarityLevel.UNCOMMON:
                return imageFamiliarityCommon;
            case FamiliarityLevel.COMMON:
                return imageFamiliarityUncommon;
            case FamiliarityLevel.TEMPLATE:
                return imageFamiliarityTemplate;
            default:
                return null;
        }
    }

    static getIssueSeverityIcon(
        severityLevel: IssueSeverityLevel
    ): string | null {
        switch (severityLevel) {
            case IssueSeverityLevel.UNSCORED:
                return imageSeverityLevelUnscored;
            case IssueSeverityLevel.OK:
                return imageSeverityLevelOkay;
            case IssueSeverityLevel.LOW:
                return imageSeverityLevelLow;
            case IssueSeverityLevel.MEDIUM:
                return imageSeverityLevelMedium;
            case IssueSeverityLevel.HIGH:
                return imageSeverityLevelHigh;
            case IssueSeverityLevel.SHOWSTOPPER:
                return imageSeverityLevelShowstopper;
            default:
                return null;
        }
    }

    static getIssueStatusIcon(status: IssueStatus): string | null {
        switch (status) {
            case IssueStatus.OPEN:
                return imageStatusOpen;
            case IssueStatus.CLOSED:
                return imageStatusClosed;
            case IssueStatus.IGNORED:
                return imageStatusIgnored;
            default:
                return null;
        }
    }
};

export default HybridReportGenerator;
