import {
    ContractVersionReportColumn,
    ContractVersionReportRow,
} from '@thought-river/negotiations-common/dist/api/contractContent';
import {
    EditCell,
    Cell,
} from '@modules/reports/contractReports/components/ViewReportPage/cells';
import { groupingValueGetter, valueGetter } from './valueGetter';
import { GridColDef, GridValueOptionsParams } from '@mui/x-data-grid-premium';
import { useMemo } from 'react';
import { getSortComparator } from './sortComparators';
import { getOrderedColumns } from '@modules/reports/common';
import { SELECTED_COLUMNS_STORAGE_KEY } from '@modules/reports/contractReports/consts';
import { valueFormatter } from './valueFormatter';
import { valueOptions } from './valueOptions';

const EDITABLE_COLUMNS = [
    'issue_severity_level',
    'issue_internal_guidance',
    'issue_external_comments',
    'issue_decision',
];

const GROUPABLE_COLUMNS = [
    'contract_sections',
    'contract_primary_section',
    'contract_sub_sections',
    'issue_summary',
    'issue_severity_level',
];

export const useGetColumns = (
    columns: ContractVersionReportColumn[],
    orderedFields: string[],
    rows: ContractVersionReportRow[]
): GridColDef[] => {
    const memoizedColumns = useMemo(() => {
        const orderedColumns = getOrderedColumns(columns, orderedFields, true);

        // Store ordered columns in session storage for analytics to use
        sessionStorage.setItem(
            SELECTED_COLUMNS_STORAGE_KEY,
            JSON.stringify(orderedColumns.map((column) => column.field))
        );

        // Override type of some columns as a separate step
        // TODO: The types should be changed in the backend
        const columnsWithTypeOverrides = orderedColumns.map((column) => {
            let type = column.type;
            if (column.field === 'contract_primary_section') {
                type = 'singleSelect';
            }

            return {
                ...column,
                type,
            };
        });

        const columnDefs = columnsWithTypeOverrides.map<GridColDef>(
            (column) => ({
                ...column,
                valueOptions: (params: GridValueOptionsParams) =>
                    valueOptions(params, rows) ?? column.valueOptions,
                editable: EDITABLE_COLUMNS.includes(column.field),
                minWidth: 200,
                flex: 1,
                valueGetter,
                valueFormatter: (params) => valueFormatter(column, params),
                renderCell: Cell,
                sortComparator: getSortComparator(column),
                renderEditCell: EditCell,
                groupable: GROUPABLE_COLUMNS.includes(column.field),
                groupingValueGetter,
                aggregable: false,
            })
        );

        return columnDefs;
    }, [columns, orderedFields, rows]);

    return memoizedColumns;
};
