import { useRef, useState } from 'react';
import { Link } from '@mui/icons-material';
import classNames from 'classnames';

import { IPropertyLinkWidgetProps } from './PropertyLinkWidget-types';
import './PropertyLinkWidget.scss';
import LegacyHTMLParser from '../../services/legacyHTMLParser';
import {
    ILexiblePropertyUpdateParams,
    NO_VALUE,
    YES_VALUE,
} from '../../services/legacyHTMLParser-types';
import { syncContractData } from '../Contract/Contract-helpers';
import { ISummaryProperty } from '../SummaryPane/SummaryPane-types';
import { NONE_IDENTIFIED_OPTION, ValueType } from '../App/App-types';
import { BannerClauseType, BannerType } from '../HeatmapPane/HeatmapPane-types';
import debounce from 'lodash/debounce';
import { Button } from '@thought-river/ui-components';
import { UnlinkButton } from '@modules/common/components/UnlinkButton';

const PropertyLinkWidget = ({
    bannerSettings,
    highlightedParagraphs,
    selectedPropertyCode,
    setBannerSettings,
    paragraph,
    paragraphs,
    summaryProperties,
    updateSummaryProperty,
    setHighlightedParagraphs,
    setSelectedParagraph,
    topOffset,
    isHighlighted,
}: IPropertyLinkWidgetProps) => {
    const [isHovered, setIsHovered] = useState(false);

    const legacyHtmlParser = LegacyHTMLParser.getInstance();
    const selectedProperty = summaryProperties.find(
        (p) => p.code === selectedPropertyCode
    );

    const debouncedSyncContractData = useRef(
        debounce(() => {
            syncContractData();
        }, 5000)
    );

    if (!selectedProperty) {
        return null;
    }

    const onLinkClauses = () => {
        if (
            selectedProperty.valueType === ValueType.DISCRETE ||
            selectedProperty.valueType === ValueType.YES_NO_CAT
        ) {
            linkClauseNonEntityProperty();
        } else {
            linkClauseEntityProperty();
        }

        setBannerSettings({
            clauseType: BannerClauseType.PROPERTY_LINKED,
            type: BannerType.INFO,
            items: selectedProperty.linkedParagraphIds,
        });
    };

    const linkClauseNonEntityProperty = () => {
        const paragraphIndex = paragraph.id
            ? String(paragraph.index)
            : undefined;

        const updateParams: ILexiblePropertyUpdateParams[] = [
            {
                dfCode: selectedProperty.code,
                paragraphIndex,
                value: YES_VALUE,
            },
        ];

        const updatedLinkedParagraphs = paragraphs.filter((paragraph) =>
            selectedProperty.linkedParagraphIds.includes(paragraph.id)
        );
        updatedLinkedParagraphs.push(paragraph);
        updatedLinkedParagraphs.sort((a, b) => a.index - b.index);

        const updatedProperty: ISummaryProperty = {
            ...selectedProperty,
            linkedParagraphIds: [...updatedLinkedParagraphs.map((p) => p.id)],
            value: 'Yes',
        };

        updateSummaryProperty(updatedProperty);

        legacyHtmlParser.editLexibleProperties(updateParams).then(() => {
            debouncedSyncContractData.current();
        });
    };

    const linkClauseEntityProperty = () => {
        const paragraphIndex = paragraph.id
            ? String(paragraph.index)
            : undefined;

        const updateParams: ILexiblePropertyUpdateParams[] = [
            {
                dfCode: selectedProperty.parentProperty.code,
                paragraphIndex,
                value: YES_VALUE,
            },
        ];

        const updatedLinkedParagraphs = paragraphs.filter((paragraph) =>
            selectedProperty.linkedParagraphIds.includes(paragraph.id)
        );
        updatedLinkedParagraphs.push(paragraph);
        updatedLinkedParagraphs.sort((a, b) => a.index - b.index);

        const updatedProperty: ISummaryProperty = {
            ...selectedProperty,
            linkedParagraphIds: [...updatedLinkedParagraphs.map((p) => p.id)],
        };

        updateSummaryProperty(updatedProperty);

        legacyHtmlParser.editLexibleProperties(updateParams).then(() => {
            debouncedSyncContractData.current();
        });
    };

    const onUnlinkClauses = () => {
        if (
            selectedProperty.valueType === ValueType.DISCRETE ||
            selectedProperty.valueType === ValueType.YES_NO_CAT
        ) {
            unlinkClauseNonEntityProperty();
        } else {
            unlinkClauseEntityProperty();
        }
    };

    const unlinkClauseNonEntityProperty = () => {
        const paragraphIndex = paragraph.id
            ? String(paragraph.index)
            : undefined;

        const updateParams: ILexiblePropertyUpdateParams[] = [
            {
                dfCode: selectedProperty.code,
                paragraphIndex,
                value: NO_VALUE,
            },
        ];

        const updatedLinkedParagraphIds =
            selectedProperty.linkedParagraphIds.filter(
                (id) => id !== paragraph.id
            );

        const newSelectedParagraph =
            selectedProperty.linkedParagraphIds.length === 0
                ? null
                : paragraphs.find(
                      (paragraph) =>
                          paragraph.id === updatedLinkedParagraphIds[0]
                  ) ?? null;

        setSelectedParagraph(newSelectedParagraph!); // Fixme: null checks
        setHighlightedParagraphs(
            newSelectedParagraph ? [newSelectedParagraph] : []
        );

        let value = selectedProperty.value;

        if (
            !updatedLinkedParagraphIds.length &&
            selectedProperty.value === 'Yes'
        ) {
            value = 'No'; // change a 'Yes' to a 'No' if all paragraphs are unlinked from the property
            setBannerSettings({
                clauseType: BannerClauseType.PROPERTY_RELATED,
                type: BannerType.INFO,
                items: selectedProperty.relatedParagraphIds,
            });
        }

        const updatedProperty: ISummaryProperty = {
            ...selectedProperty,
            linkedParagraphIds: updatedLinkedParagraphIds,
            value,
        };

        updateSummaryProperty(updatedProperty);

        legacyHtmlParser.editLexibleProperties(updateParams).then(() => {
            debouncedSyncContractData.current();
        });
    };

    const unlinkClauseEntityProperty = () => {
        const paragraphIndex = paragraph.id
            ? String(paragraph.index)
            : undefined;
        const updateParams: ILexiblePropertyUpdateParams[] = [
            {
                dfCode: selectedProperty.parentProperty.code,
                paragraphIndex,
                value: NO_VALUE,
            },
        ];

        const updatedLinkedParagraphIds =
            selectedProperty.linkedParagraphIds.filter(
                (id) => id !== paragraph.id
            );
        const newSelectedParagraph =
            updatedLinkedParagraphIds.length === 0
                ? null
                : paragraphs.find(
                      (paragraph) =>
                          paragraph.id === updatedLinkedParagraphIds[0]
                  ) ?? null;

        setSelectedParagraph(newSelectedParagraph!); // Fixme: null checks
        setHighlightedParagraphs(
            newSelectedParagraph ? [newSelectedParagraph] : []
        );

        let value = selectedProperty.value;
        if (updatedLinkedParagraphIds.length === 0) {
            value = NONE_IDENTIFIED_OPTION;
            setBannerSettings({
                clauseType: BannerClauseType.PROPERTY_RELATED,
                type: BannerType.INFO,
                items: selectedProperty.relatedParagraphIds,
            });
        }

        const updatedProperty: ISummaryProperty = {
            ...selectedProperty,
            linkedParagraphIds: updatedLinkedParagraphIds,
            value,
        };

        updateSummaryProperty(updatedProperty);

        legacyHtmlParser.editLexibleProperties(updateParams).then(() => {
            debouncedSyncContractData.current();
        });
    };

    const linkButton = (
        <Button
            variant="tertiary"
            className="link-clause-btn"
            onClick={onLinkClauses}
            startIcon={<Link className="link-clause-icon" />}
        >
            Link
        </Button>
    );

    const unlinkButton = <UnlinkButton onClick={onUnlinkClauses} />;

    const showUnlinkButton =
        selectedProperty?.linkedParagraphIds.includes(paragraph?.id) &&
        bannerSettings?.clauseType === BannerClauseType.PROPERTY_LINKED;

    return (
        <div
            className={classNames('property-link-widget', {
                hide: highlightedParagraphs[0]?.id !== paragraph.id,
                hovered: isHovered,
                highlighted: isHighlighted,
            })}
            style={{
                top: topOffset,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {showUnlinkButton ? unlinkButton : linkButton}
        </div>
    );
};

export default PropertyLinkWidget;
