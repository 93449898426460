import { analyticsService } from '../../services/Analytics/Analytics';
import {
    EVENT_ACTION_DOWNLOAD,
    EVENT_ACTION_INITIATE_SET_PARTIES_FLOW,
    EVENT_ACTION_REPROCESS,
} from '../../services/Analytics/Analytics-types';
import { IContractVersion, IDisplayContract } from '../Contract/Contract-types';

export function analytics() {
    return (target: any) => {
        const downloadFile = target.prototype.downloadFile;
        const reprocessContract = target.prototype.reprocessContract;
        const editParties = target.prototype.editParties;

        target.prototype.downloadFile = function (
            contract: IDisplayContract,
            version: IContractVersion
        ) {
            analyticsService.recordEvent(
                EVENT_ACTION_DOWNLOAD,

                {
                    ContractId: contract.id,
                    Stream: contract.streamCode,
                    VersionId: version.id,
                    VersionNumber: version.versionNumber,
                }
            );

            downloadFile.apply(this, arguments);
        };

        target.prototype.reprocessContract = function (
            contract: IDisplayContract
        ) {
            analyticsService.recordEvent(
                EVENT_ACTION_REPROCESS,

                {
                    ContractId: contract?.id,
                    Stream: contract?.streamCode,
                    VersionId: contract?.latestVersion?.id,
                    VersionNumber: contract?.latestVersion?.versionNumber,
                }
            );

            reprocessContract.apply(this, arguments);
        };

        target.prototype.editParties = function (contract: IDisplayContract) {
            analyticsService.recordEvent(
                EVENT_ACTION_INITIATE_SET_PARTIES_FLOW,

                {
                    ContractId: contract.id,
                    Stream: contract.streamCode,
                    VersionId: contract.latestVersion?.id ?? '',
                    VersionNumber: contract.latestVersion?.versionNumber ?? '',
                }
            );

            editParties.apply(this, arguments);
        };
    };
}
