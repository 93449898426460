import AuthResource from '../../resources/AuthResource';
import { IReviewStream } from '../../resources/AuthResource-types';
import { sortUsers } from '../App/App-helpers';
import { mapUser } from '../App/App-mappers';
import { API_USER, IAccountUser, IAuthEnv } from './Auth-types';
import { reduxStore } from '../../redux/store';
import { setUsersAction } from '../Auth/Auth-actions';
import dayjs from 'dayjs';

export function alphabetizeStreams(
    streamA: IReviewStream,
    streamB: IReviewStream
) {
    return streamA.accountName
        ? streamA.accountName.localeCompare(streamB.accountName, undefined, {
              numeric: true,
              sensitivity: 'base',
          })
        : 0;
}

export function filterOutApiUser(user: IAccountUser) {
    return (
        `${user.firstName.toUpperCase()} ${user.lastName.toUpperCase()}` !==
        API_USER
    );
}

export function generateLogoutUrl(authUrl: string, clientId: string): string {
    const returnToUrl = encodeURIComponent(window.location.origin);
    return `${authUrl}/logout?returnTo=${returnToUrl}&client_id=${clientId}`;
}

export function getAuthEnv(): IAuthEnv {
    const { apiUrl } = window.__APP_CONFIG__;
    const {
        audience,
        baseUrl: authBaseUrl,
        clientId,
        redirectUrl,
    } = window.__APP_CONFIG__.auth;

    return {
        apiUrl,
        audience,
        authBaseUrl,
        clientId,
        redirectUrl,
    };
}

export function isRedirectionPage() {
    return (
        window.location.pathname.includes('authRedirect') ||
        window.location.pathname.includes('auth-callback')
    );
}

export function isAutoLogoutPage() {
    return window.location.pathname.includes('logout');
}

export function resolveCompanyFromEmail(email: string): string {
    const parts: RegExpMatchArray | [] = email?.match(/@([^.]+)/) ?? [];
    return parts[1] ? String(parts[1]).toLowerCase() : 'unknown';
}

export function tokenHasExpired(date: string) {
    return !date || dayjs(date).isBefore(dayjs());
}

export function findUser(
    users: IAccountUser[] = [],
    userId: string = ''
): IAccountUser | undefined {
    return users.find((user) => user.id === userId);
}

export async function fetchUsers() {
    try {
        const dispatch = reduxStore.store.dispatch;
        const users = (await AuthResource.getUsers()).data.map(mapUser);
        await dispatch(
            setUsersAction(users.filter(filterOutApiUser).sort(sortUsers))
        );

        return users;
    } catch (error) {
        console.log('Error fetching users');
    }
}
