import * as React from 'react';

import { IModalOverlayProps, IModalOverlayState } from './ModalOverlay-types';

class ModalOverlay extends React.Component<
    IModalOverlayProps,
    IModalOverlayState
> {
    private readonly overlayRef: React.RefObject<HTMLDivElement>;

    constructor(props: IModalOverlayProps) {
        super(props);

        this.state = { overlayMouseDown: false };
        this.overlayRef = React.createRef();

        this.handleClick = this.handleClick.bind(this);
        this.mouseDown = this.mouseDown.bind(this);
        this.mouseUp = this.mouseUp.bind(this);
    }

    private getClassName(visible: boolean, className?: string): string {
        const classList = ['modal-overlay', visible ? 'visible' : 'hidden'];

        if (typeof className === 'string' && className.length) {
            classList.push(className);
        }

        return classList.join(' ');
    }

    private handleClick(event: React.MouseEvent) {
        if (event.target === this.overlayRef.current) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private mouseDown(event: React.MouseEvent) {
        if (event.target === this.overlayRef.current) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({ overlayMouseDown: true });
        }
    }

    private mouseUp(event: React.MouseEvent) {
        if (event.target === this.overlayRef.current) {
            event.preventDefault();
            event.stopPropagation();

            if (
                typeof this.props.onClick === 'function' &&
                this.state.overlayMouseDown
            ) {
                this.props.onClick();
            }

            this.setState({ overlayMouseDown: false });
        }
    }

    render() {
        const { visible, className, children } = this.props;

        return (
            <div
                className={this.getClassName(visible, className)}
                onMouseDown={this.mouseDown}
                onMouseUp={this.mouseUp}
                onClick={this.handleClick}
                ref={this.overlayRef}
            >
                {children}
            </div>
        );
    }
}

export default ModalOverlay;
