import { TextField } from '@thought-river/ui-components';
import { Component } from 'react';
import { PartiesType, PartyVariantType } from '../../Parties/Parties-types';
import { getFirstPartyName } from '../IssuesReport-helpers';

import {
    IIssuesReportDetailsProps,
    IIssuesReportDetailsState,
} from './IssuesReportDetails-types';
import './IssuesReportDetails.scss';

const { OWN_PARTIES, COUNTER_PARTIES } = PartiesType;

const { FORMAL } = PartyVariantType;

class IssuesReportDetails extends Component<
    IIssuesReportDetailsProps,
    IIssuesReportDetailsState
> {
    render() {
        const parties = this.props.version?.parties ?? [];
        const ownParty = getFirstPartyName(parties, OWN_PARTIES, FORMAL);
        const counterParty = getFirstPartyName(
            parties,
            COUNTER_PARTIES,
            FORMAL
        );

        return (
            <div className="issues-report-details">
                <TextField
                    inputProps={{
                        readOnly: true,
                    }}
                    label="Own Party"
                    value={ownParty}
                />
                <TextField
                    inputProps={{
                        readOnly: true,
                    }}
                    label="Counterparty"
                    value={counterParty}
                />
            </div>
        );
    }
}

export default IssuesReportDetails;
