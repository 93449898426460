import { Slide, ToastContainer as ToastifyContainer } from 'react-toastify';
import { ToastProps } from 'react-toastify/dist/types';
import { CheckCircle, Error, Info, Warning } from '@mui/icons-material';

const ToastContainer = () => (
    <ToastifyContainer
        autoClose={5000}
        closeOnClick={true}
        draggable={false}
        hideProgressBar={false}
        newestOnTop={true}
        pauseOnFocusLoss={false}
        pauseOnHover={true}
        position="bottom-right"
        rtl={false}
        style={{
            bottom: '20px',
            right: '19px',
        }}
        toastStyle={{
            borderRadius: '4px',
            marginBottom: 0,
            marginTop: '10px',
            minHeight: 'auto',
            padding: '20px',
        }}
        transition={Slide}
        icon={({ type }: ToastProps) => {
            switch (type) {
                case 'error':
                    return <Error className="notification-icon" />;
                case 'info':
                    return <Info className="notification-icon" />;
                case 'success':
                    return <CheckCircle className="notification-icon" />;
                case 'warning':
                    return <Warning className="notification-icon" />;
                default:
                    return false;
            }
        }}
    />
);

export default ToastContainer;
