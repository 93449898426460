import { Tooltip } from '@thought-river/ui-components';

interface NoIssueTooltipProps {
    content?: string;
}

export const NoIssueTooltip = ({ content }: NoIssueTooltipProps) => (
    <Tooltip title="Cannot edit the value as this row does not have an issue">
        <div
            style={{
                cursor: 'not-allowed',
                flexGrow: 1,
                height: '100%',
            }}
        >
            {content}
        </div>
    </Tooltip>
);

export default NoIssueTooltip;
