import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import HeaderTabs from './HeaderTabs';
import { IRootState } from '../../redux/reducers-types';
import {
    IHeaderTabsDispatchProps,
    IHeaderTabsStateProps,
} from './HeaderTabs-types';

function mapStateToProps(state: IRootState): IHeaderTabsStateProps {
    return {
        featureToggles: state.featureToggles.toggles,
    };
}

function mapDispatchToProps(): IHeaderTabsDispatchProps {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(HeaderTabs));
