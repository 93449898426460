import * as React from 'react';

import { IPaneDividerProps, IPaneDividerState } from './PaneDivider-types';
import transparent from '../../assets/transparent.gif';
import { isFirefox } from '../App/App-helpers';
import throttle from 'lodash/throttle';

class PaneDivider extends React.Component<
    IPaneDividerProps,
    IPaneDividerState
> {
    private ref: React.RefObject<HTMLDivElement>;
    private handleRef: React.RefObject<HTMLSpanElement>;

    constructor(props: IPaneDividerProps) {
        super(props);

        this.ref = React.createRef();
        this.handleRef = React.createRef();

        this.state = {
            draggingInProgress: false,
        };
    }

    onDrag(event: React.DragEvent<HTMLSpanElement>, self: PaneDivider) {
        event.persist();
        event.stopPropagation();
        self.throttledOnDrag(event, self);
    }

    async onDragEnd() {
        if (isFirefox()) {
            await this.setState({
                draggingInProgress: false,
            });
        }
    }

    onDragOver(event: React.DragEvent<HTMLSpanElement>, self: PaneDivider) {
        if (isFirefox()) {
            this.onDrag(event, self);
        }
    }

    onDragStart(event: React.DragEvent<HTMLSpanElement>) {
        const img = new Image();
        img.src = transparent;
        img.className = 'ghost-handle';

        if (typeof event.dataTransfer.setDragImage === 'function') {
            event.dataTransfer.setDragImage(img, 0, 0);
        }
    }

    async onMouseDown() {
        if (isFirefox()) {
            await this.setState({
                draggingInProgress: true,
            });
        }
    }

    async onMouseUp() {
        if (isFirefox()) {
            await this.setState({
                draggingInProgress: false,
            });
        }
    }

    throttledOnDrag = throttle(function (
        event: React.DragEvent<HTMLSpanElement>,
        self: PaneDivider
    ) {
        const { leftPane, rightPane } = self.props;
        const windowWidth = window.innerWidth;
        const offsetLeft = event.clientX;
        const leftPaneWidth = (offsetLeft / windowWidth) * 100;
        const rightPaneWidth = ((windowWidth - offsetLeft) / windowWidth) * 100;

        if (offsetLeft) {
            self.props.setPaneWidth(leftPane, leftPaneWidth);
            self.props.setPaneWidth(rightPane, rightPaneWidth);
        }
    },
    100);

    render() {
        const { disabled } = this.props;

        return (
            <div
                className={`pane-divider ${disabled ? 'disabled' : ''}`}
                ref={this.ref}
                data-id="pane-divider"
            >
                {this.state.draggingInProgress ? (
                    <span
                        className="drag-overlay"
                        onDragOver={(event) => this.onDragOver(event, this)}
                    />
                ) : null}
                <span
                    draggable={!disabled}
                    onDrag={(event) => this.onDrag(event, this)}
                    onDragStart={(event) => this.onDragStart(event)}
                    onDragEnd={() => this.onDragEnd()}
                    onMouseUp={() => this.onMouseUp()}
                    onMouseDown={() => this.onMouseDown()}
                    className="handle"
                    ref={this.handleRef}
                />
            </div>
        );
    }
}

export default PaneDivider;
