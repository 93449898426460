import { ModelTrainingOutlined } from '@mui/icons-material';
import { PropertyAnswerCellProps } from './ReportCell.types';
import { Link } from '@mui/material';
import styles from '../../../ViewReportPage.module.scss';
import { DEFAULT_DATE_FORMAT, Tooltip } from '@thought-river/ui-components';
import dayjs from 'dayjs';
import { useReportsAnalytics } from '@modules/reports/portfolioReports';
import { useClausesSidebarContext } from '@modules/reports/common';

const PropertyAnswerCell = ({ property, params }: PropertyAnswerCellProps) => {
    const { openClausesSidebar } = useClausesSidebarContext();
    const { trackPreviewLinkedClauses } = useReportsAnalytics();

    if (!params.value) {
        return (
            <Tooltip
                className={styles.cellContent}
                title="The question was not asked of the contract. Add this question to the checklist and reprocess your contracts."
            >
                <ModelTrainingOutlined />
            </Tooltip>
        );
    }

    /* Don't render the link if we're grouping by property */
    if (params.rowNode.type === 'group') {
        return (
            <Tooltip title={params.value} className={styles.cellContent}>
                {params.value}
            </Tooltip>
        );
    }

    const handleLinkClick = () => {
        openClausesSidebar({
            propertyCode: property.dfcode,
            contractId: params.row.contract_id,
            stream: params.row.deal_type_code,
            propertyLabel: property.short_label!, // Fixme: null checks
        });
        trackPreviewLinkedClauses();
    };

    const value = (() => {
        if (property.value_type === 'date' && dayjs(params.value).isValid()) {
            return dayjs(params.value).format(DEFAULT_DATE_FORMAT);
        }
        return params.value;
    })();

    return (
        <Tooltip title={value}>
            <Link
                className={styles.cellContent}
                component="button"
                onClick={handleLinkClick}
            >
                {value}
            </Link>
        </Tooltip>
    );
};

export default PropertyAnswerCell;
