import { IRootState } from '../../redux/reducers-types';
import { reduxStore } from '../../redux/store';
import { analyticsService } from '../../services/Analytics/Analytics';
import { EVENT_ACTION_COPY_NEGOTIATION_LINK } from '../../services/Analytics/Analytics-types';

export function analytics() {
    return (target: any) => {
        const onCopyUrl = target.prototype.onCopyUrl;

        target.prototype.onCopyUrl = function () {
            const {
                contract: { contract, selectedVersion },
            } = reduxStore.store.getState() as IRootState;

            analyticsService.recordEvent(
                EVENT_ACTION_COPY_NEGOTIATION_LINK,

                {
                    ContractId: contract?.id,
                    Stream: contract?.streamCode,
                    VersionId: selectedVersion?.id,
                    VersionNumber: selectedVersion?.versionNumber,
                }
            );

            onCopyUrl.apply(this, arguments);
        };
    };
}
