import { useHistory, useLocation } from 'react-router-dom';
import { Button, Icon } from '@thought-river/ui-components';
import styles from './EditReportButton.module.scss';
import { EditReportButtonProps } from './EditReportButton.types';

// NOTE This has been switched off in contract reports, when a final decision is made we should keep/delete it
const EditReportButton = ({ onClick }: EditReportButtonProps) => {
    const location = useLocation();
    const history = useHistory();

    return (
        <Button
            variant="secondary"
            className={styles.editButton}
            startIcon={<Icon type="mode-edit" />}
            onClick={() => {
                history.push(`${location.pathname}/edit`);
                onClick?.();
            }}
        >
            Edit
        </Button>
    );
};

export default EditReportButton;
