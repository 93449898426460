import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { IRootState } from '../../../../redux/reducers-types';

/**
 * Hook returning deal types (streams) that the user has access to and that are not primary
 * todo: Move this to a more suitable place when folder structure convention is established
 */
export const useUserDealTypes = () => {
    const streams = useSelector((state: IRootState) => state.auth.streams);

    const memoizedStreams = useMemo(
        () => streams.filter((stream) => !stream.isPrimary),
        [streams]
    );

    return memoizedStreams;
};
