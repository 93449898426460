import { Auth0Client } from '@auth0/auth0-spa-js';

export const auth0Client = new Auth0Client({
    clientId: window.__APP_CONFIG__.auth0.clientId,
    domain: window.__APP_CONFIG__.auth0.domain,
    authorizationParams: {
        redirect_uri: `${window.location.origin}/#/auth-callback`,
        audience: window.__APP_CONFIG__.auth0.audience,
    },
});
