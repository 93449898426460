import { Component } from 'react';
import { Link } from 'react-router-dom';

import { IHeaderProps, IHeaderState } from './Header-types';
import logo from '../../assets/logo.svg';
import logoMini from '../../assets/logo-mini.svg';
import HelpMenu from '../HelpMenu/HelpMenu-container';
import MobileMenu from '../MobileMenu/MobileMenu-container';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import ProfileMenu from '../ProfileMenu/ProfileMenu-container';
import HeaderTabs from '../HeaderTabs/HeaderTabs-container';
import { IHeaderTab } from '../HeaderTabs/HeaderTabs-types';

class Header extends Component<IHeaderProps, IHeaderState> {
    render() {
        const showHeader =
            /\/$|\/(analytics-report|reports|settings|diagnostics)\/?.*/.test(
                this.props.location.pathname
            );

        const headers: IHeaderTab[] = [
            {
                label: 'Negotiations',
                target: '/',
            },
            {
                label: 'Reports',
                target: '#/reports',
            },
        ];

        return (
            showHeader && (
                <header className="header">
                    <MobileMenu />
                    <div className="logo-wrapper">
                        <Link to="/">
                            <img
                                className="logo"
                                src={logo}
                                alt="ThoughtRiver Logo"
                            />
                        </Link>
                        <Link to="/">
                            <img
                                className="logo-mini"
                                src={logoMini}
                                alt="ThoughtRiver Logo"
                            />
                        </Link>
                    </div>
                    <HeaderTabs tabs={headers} />
                    <nav className="nav-options">
                        <HelpMenu />
                        <SettingsMenu />
                        <ProfileMenu />
                    </nav>
                </header>
            )
        );
    }
}

export default Header;
