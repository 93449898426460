import { Component } from 'react';

import {
    IIssuePaneTabsProps,
    IIssuePaneTabsState,
    IssuePaneTab,
} from './IssuePaneTabs-types';
import IssuePaneIntelTab from './IssuePaneIntelTab/IssuePaneIntelTab-container';
import IssuePaneSuggestionTab from './IssuePaneSuggestionTab/IssuePaneSuggestionTab-container';
import IssuePaneDescriptionTab from './IssuePaneDescriptionTab/IssuePaneDescriptionTab-container';
import { IssueCreationType } from '../../IssueListPane/IssueListPane-types';
import './IssuePaneTabs.scss';
import { Tabs } from '@mui/material';
import { Tab, Tooltip } from '@thought-river/ui-components';
import { analytics } from './IssuePaneTabs-analytics';

@analytics()
export default class IssuePaneTabs extends Component<
    IIssuePaneTabsProps,
    IIssuePaneTabsState
> {
    constructor(props: IIssuePaneTabsProps) {
        super(props);

        this.state = {
            selectedTabIndex: IssuePaneTab.DESCRIPTION,
        };

        this.handleTabChange = this.handleTabChange.bind(this);
    }

    private renderSelectedTab() {
        const {
            debouncedSaveIssue,
            form,
            formInitialised,
            lockedVersion,
            onClickSelectedClause,
            saveIssue,
            submitAttempted,
            toggleShowPropertyDetails,
            issue,
            paragraphs,
        } = this.props;

        if (!issue) {
            return;
        }

        switch (this.state.selectedTabIndex) {
            case IssuePaneTab.DESCRIPTION:
                return (
                    <IssuePaneDescriptionTab
                        formInitialised={formInitialised}
                        lockedVersion={lockedVersion}
                        onClickSelectedClause={onClickSelectedClause}
                        saveIssue={saveIssue}
                        debouncedSaveIssue={debouncedSaveIssue}
                        submitAttempted={submitAttempted}
                    />
                );
            case IssuePaneTab.INTELLIGENCE:
                return (
                    <IssuePaneIntelTab
                        form={form}
                        onViewPropertyDetails={toggleShowPropertyDetails}
                    />
                );
            case IssuePaneTab.SUGGESTIONS:
                return (
                    <IssuePaneSuggestionTab
                        form={form}
                        issue={issue}
                        paragraphs={paragraphs}
                    />
                );
        }
    }

    private isSuggestionsDisabled() {
        const { issue } = this.props;
        return !issue || issue?.creationType === IssueCreationType.MANUAL;
    }

    handleTabChange(_: React.SyntheticEvent, newTab: IssuePaneTab) {
        const suggestionsDisabled = this.isSuggestionsDisabled();

        if (newTab === IssuePaneTab.SUGGESTIONS && suggestionsDisabled) {
            return;
        }

        this.setState({ selectedTabIndex: newTab });
    }

    render() {
        const { selectedTabIndex } = this.state;

        const suggestionsDisabled = this.isSuggestionsDisabled();
        const suggestionsLabel = suggestionsDisabled ? (
            <Tooltip
                title="Drafting options are not available for manually created issues"
                placement="top-end"
            >
                <span>Drafting options</span>
            </Tooltip>
        ) : (
            'Drafting options'
        );

        return (
            <div className="issue-pane-tabs" data-id="issue-pane-tabs">
                <Tabs
                    className="issue-content-tabs"
                    value={selectedTabIndex}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    scrollButtons="auto"
                >
                    <Tab label="Description" />
                    <Tab label="Intelligence" />
                    <Tab
                        disabled={suggestionsDisabled}
                        label={suggestionsLabel}
                    />
                </Tabs>
                {this.renderSelectedTab()}
            </div>
        );
    }
}
