import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import FeatureToggleProvider from './FeatureToggleProvider';
import { IRootState } from '../../redux/reducers-types';
import {
    IFeatureToggle,
    IFeatureToggleProviderDispatchProps,
    IFeatureToggleProviderStateProps,
} from './FeatureToggleProvider-types';
import { setFeatureTogglesAction } from './FeatureToggleProvider-actions';

function mapStateToProps(state: IRootState): IFeatureToggleProviderStateProps {
    return {
        featureToggles: state.featureToggles.toggles,
        userId: state.auth.userId,
        accountCode: state.auth.accountCode,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IFeatureToggleProviderDispatchProps {
    return {
        setFeatureToggles: (toggles: IFeatureToggle[]) =>
            dispatch(setFeatureTogglesAction(toggles)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FeatureToggleProvider);
