import { ContractMenuProps } from './ContractMenu.types';
import {
    Analytics,
    Delete,
    Draw,
    GetApp,
    Grading,
    NoteAdd,
    People,
    Person,
    Replay,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import {
    usePostSetTemplate,
    usePostSignContract,
    useDeleteUnsetTemplate,
    useDeleteUnsignContract,
} from '@thought-river/negotiations-common/dist/api/contractContent';
import {
    Icon,
    IconButton,
    OverflowMenu,
    OverflowMenuItem,
} from '@thought-river/ui-components';
import { toast } from 'react-toastify';
import { useAnalytics } from './ContractMenu.analytics';
import { DeleteVersionDialog } from '../DeleteVersionDialog';
import { DeleteContractDialog } from '../DeleteContractDialog';
import ContractResource from '../../../../resources/ContractResource';
import ContractUploadWizardModal from '../../../../components/ContractUploadWizardModal/ContractUploadWizardModal-container';
import { FileFormat } from '@modules/common/types';
import { downloadContract, getEditPartiesPath } from '@modules/common/helpers';
import AddPeopleDialog from '../AddPeopleDialog/AddPeopleDialog';
import { useAppSelector } from '@modules/common/hooks';
import { mapBusinessStatus } from 'src/components/App/App-mappers';
import { useDispatch } from 'react-redux';
import { setBusinessStatusesAction } from 'src/components/App/App-actions';
import { BusinessStatus } from 'src/components/Contract/Contract-types';
import { fetchGetBusinessStatusesV02 } from '@thought-river/negotiations-common/dist/api/contractManagement';

const ContractMenu = ({
    contractInfo,
    onOpen,
    hideDownloadsSection,
}: ContractMenuProps) => {
    const {
        fileFormats,
        uuid,
        versionUuid,
        versionNumber,
        dealTypeCode,
        isLatestVersion,
        isOnlyVersion,
        hasFailedProcessing,
        hasFinishedProcessing,
        dealTypeUuid,
        isSigned,
        isTemplate,
    } = contractInfo;

    const businessStatuses = useAppSelector(
        (state) => state.app.businessStatuses
    );
    const dispatch = useDispatch();

    useEffect(() => {
        document.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.key === 'd') {
                setShowContractInfoOption(true);
            }
        });
        document.addEventListener('keyup', (event: KeyboardEvent) => {
            if (event.key === 'd') {
                setShowContractInfoOption(false);
            }
        });
    }, []);

    const [showUploadContractModal, setShowUploadContractModal] =
        useState(false);
    const [showDeleteVersionDialog, setShowDeleteVersionDialog] =
        useState(false);
    const [showDeleteContractDialog, setShowDeleteContractDialog] =
        useState(false);
    const [showAddPeopleDialog, setShowAddPeopleDialog] = useState(false);
    const [showContractInfoOption, setShowContractInfoOption] = useState(false);

    const {
        trackDownloadClick,
        trackEditPartiesClick,
        trackSignContractClick,
        trackUnsignContractClick,
    } = useAnalytics(uuid, versionUuid, versionNumber);

    const { mutateAsync: setTemplate, isLoading: setTemplateLoading } =
        usePostSetTemplate();
    const { mutateAsync: unsetTemplate, isLoading: unsetTemplateLoading } =
        useDeleteUnsetTemplate();

    const { mutateAsync: signContract, isLoading: signContractLoading } =
        usePostSignContract({});
    const { mutateAsync: unsignContract, isLoading: unsignContractLoading } =
        useDeleteUnsignContract({});

    const handleDownloadFile = (format: FileFormat = FileFormat.DOCX) => {
        trackDownloadClick();
        downloadContract(contractInfo.versionUuid, format, contractInfo.name);
    };

    const editParties = () => {
        trackEditPartiesClick();
        const partiesPath = getEditPartiesPath(uuid, dealTypeCode);
        window.open(`#${partiesPath}`);
    };

    const handleSuccessfulContractUpdate = (toastMessage: string) => {
        toast.success(toastMessage);
    };

    const handleTemplateUpdate = () => {
        if (isTemplate) {
            unsetTemplate(
                {
                    pathParams: {
                        contractUuid: uuid,
                        contractVersion: versionNumber,
                    },
                },
                {
                    onSuccess: () => {
                        handleSuccessfulContractUpdate(
                            'Contract removed as template successfully!'
                        );
                    },
                    onError: () => {
                        toast.error(`Error removing contract as template!`);
                    },
                }
            );
        } else {
            setTemplate(
                {
                    pathParams: {
                        contractUuid: uuid,
                        contractVersion: versionNumber,
                    },
                },
                {
                    onSuccess: () => {
                        handleSuccessfulContractUpdate(
                            'Contract marked as template successfully!'
                        );
                    },
                    onError: (error) => {
                        if (
                            'message' in error.payload &&
                            error.payload.message ===
                                'Contract already set as a template'
                        ) {
                            toast.info(
                                'This contract is already marked as a template, please wait for the status to update'
                            );
                        } else {
                            toast.error(`Error marking contract as template!`);
                        }
                    },
                }
            );
        }
    };

    const handleSignUpdate = async () => {
        if (isSigned) {
            // Fetch business statuses if they're not in Redux
            let statuses = businessStatuses;
            if (!statuses) {
                const response = await fetchGetBusinessStatusesV02({});
                const mappedBusinessStatuses =
                    response.data?.map(mapBusinessStatus) ?? [];
                dispatch(setBusinessStatusesAction(mappedBusinessStatuses));
                statuses = mappedBusinessStatuses;
            }

            trackUnsignContractClick();
            unsignContract(
                {
                    pathParams: {
                        contractUuid: uuid,
                        contractVersion: versionNumber,
                    },
                    body: {
                        status: statuses.find(
                            (status) => status.label === BusinessStatus.RECEIVED
                        )?.id,
                    },
                },
                {
                    onSuccess: () => {
                        handleSuccessfulContractUpdate(
                            'Contract marked as unsigned successfully!'
                        );
                    },
                    onError: () =>
                        toast.error(`Error marking contract as unsigned!`),
                }
            );
        } else {
            trackSignContractClick();
            signContract(
                {
                    pathParams: {
                        contractUuid: uuid,
                        contractVersion: versionNumber,
                    },
                },
                {
                    onSuccess: () => {
                        handleSuccessfulContractUpdate(
                            'Contract marked as signed successfully!'
                        );
                    },
                    onError: () =>
                        toast.error(`Error marking contract as signed!`),
                }
            );
        }
    };

    const handleReanalyzeContract = () => {
        ContractResource.reprocess([uuid], dealTypeCode);
    };

    const reanalyzeContractDisabled =
        !hasFinishedProcessing && !hasFailedProcessing;
    const setPartiesDisabled = hasFailedProcessing;
    const signContractDisabled =
        isTemplate || hasFailedProcessing || !hasFinishedProcessing;
    const setTemplateDisabled =
        isSigned || hasFailedProcessing || !hasFinishedProcessing;
    const addVersionDisabled = hasFailedProcessing;

    const getMenuItems = (): OverflowMenuItem[][] => {
        const menuItems: OverflowMenuItem[][] = [
            [
                {
                    label: 'Reanalyze Contract',
                    icon: <Replay />,
                    onClick: handleReanalyzeContract,
                    disabled: reanalyzeContractDisabled,
                },
                {
                    label: 'Set Parties',
                    icon: <People />,
                    onClick: editParties,
                    disabled: setPartiesDisabled,
                },
                {
                    label: isSigned ? 'Unsign' : 'Sign',
                    icon: <Draw />,
                    onClick: handleSignUpdate,
                    disabled: signContractDisabled,
                    loading: signContractLoading || unsignContractLoading,
                },
                {
                    label: 'Add People',
                    icon: <Person />,
                    onClick: () => setShowAddPeopleDialog(true),
                },
                {
                    label: isTemplate
                        ? 'Remove as Template'
                        : 'Set as Template',
                    icon: <Grading />,
                    onClick: handleTemplateUpdate,
                    disabled: setTemplateDisabled,
                    loading: setTemplateLoading || unsetTemplateLoading,
                },
            ],
            [
                {
                    label: 'Download as Word',
                    icon: <GetApp />,
                    onClick: () => handleDownloadFile(FileFormat.DOCX),
                },
                ...(fileFormats?.includes(FileFormat.PDF)
                    ? [
                          {
                              label: 'Download as PDF',
                              icon: <GetApp />,
                              onClick: () => handleDownloadFile(FileFormat.PDF),
                          },
                      ]
                    : []),
            ],
            [
                {
                    label: 'Add Version',
                    icon: <NoteAdd />,
                    onClick: () => setShowUploadContractModal(true),
                    disabled: addVersionDisabled,
                },
            ],

            ...(showContractInfoOption
                ? [
                      [
                          {
                              label: 'Diagnostics',
                              icon: <Analytics />,
                              onClick: () => {
                                  window.open(
                                      `#/diagnostics/contract/${contractInfo.uuid}/version/${contractInfo.versionUuid}`,
                                      '_blank',
                                      'noopener,noreferrer'
                                  );
                              },
                          },
                      ],
                  ]
                : []),

            [
                ...(isLatestVersion
                    ? [
                          {
                              label: 'Delete Negotiation',
                              icon: <Delete />,
                              onClick: () => setShowDeleteContractDialog(true),
                              type: 'destructive' as const,
                          },
                      ]
                    : []),
                ...(!isOnlyVersion
                    ? [
                          {
                              label: 'Delete Version',
                              icon: <Delete />,
                              onClick: () => setShowDeleteVersionDialog(true),
                              type: 'destructive' as const,
                              disabled: !hasFinishedProcessing,
                          },
                      ]
                    : []),
            ],
        ];

        if (hideDownloadsSection) {
            menuItems.splice(1, 1);
        }

        return menuItems;
    };

    const menuItems = getMenuItems();

    return (
        <>
            <OverflowMenu
                button={
                    <IconButton
                        onClick={() => {
                            setShowContractInfoOption(false);
                            onOpen?.();
                        }}
                        size="medium"
                        data-testid="contract-menu"
                    >
                        <Icon type="more-vert" />
                    </IconButton>
                }
                menuItems={menuItems}
            />
            <ContractUploadWizardModal
                open={showUploadContractModal}
                onClose={() => setShowUploadContractModal(false)}
                existingContractId={uuid}
                existingStreamId={dealTypeUuid}
            />
            <DeleteVersionDialog
                open={showDeleteVersionDialog}
                contractInfo={contractInfo}
                onClose={() => setShowDeleteVersionDialog(false)}
            />
            <DeleteContractDialog
                open={showDeleteContractDialog}
                contractInfo={contractInfo}
                onClose={() => setShowDeleteContractDialog(false)}
            />
            <AddPeopleDialog
                open={showAddPeopleDialog}
                contractInfo={contractInfo}
                onClose={() => setShowAddPeopleDialog(false)}
            />
        </>
    );
};

export default ContractMenu;
