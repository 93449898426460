import { useState } from 'react';
import { toast } from 'react-toastify';
import { DeleteVersionDialogProps } from './DeleteVersionDialog.types';
import { Button, Dialog } from '@thought-river/ui-components';
import VersionResource from '../../../../resources/VersionResource';

const DeleteVersionDialog = ({
    open,
    onClose,
    contractInfo,
}: DeleteVersionDialogProps) => {
    const { dealTypeCode, name, uuid, versionUuid, versionNumber } =
        contractInfo;

    const [submitting, setSubmitting] = useState(false);

    const onSubmit = async () => {
        setSubmitting(true);

        try {
            await VersionResource.deleteVersion(
                uuid,
                versionUuid,
                dealTypeCode
            );
            toast.success('Version deleted successfully!');
        } catch (error) {
            setSubmitting(false);
            toast.error('Error deleting version');
            return;
        }

        setSubmitting(false);
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            dialogTitle="Delete version"
            dialogContent={
                <div>
                    Delete version {versionNumber} of "{name}"?
                    <br />
                    You will not be able to recover it.
                </div>
            }
            dialogActions={
                <>
                    <Button onClick={onClose} variant="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={onSubmit}
                        disabled={submitting}
                        variant="destructive"
                    >
                        Delete
                    </Button>
                </>
            }
        />
    );
};

export default DeleteVersionDialog;
