import {
    ContractTree,
    ContractTreeRow,
} from '@thought-river/negotiations-common/dist/api/contractManagement';
import { QueryClient } from '@tanstack/react-query';

export const updateTanStackQueryContractsRow = (
    queryClient: QueryClient,
    rowId: number,
    updatedFields: Record<string, any>
) => {
    queryClient.setQueryData(
        ['contracts'],
        (oldData: ContractTree | undefined) => {
            if (!oldData) {
                return oldData;
            }

            return {
                ...oldData,
                rows: oldData.rows.map((row) => {
                    if (row.id !== rowId) {
                        return row;
                    }

                    return {
                        ...row,
                        ...updatedFields,
                    };
                }),
            };
        }
    );
};

//Delete a contract row in a list of contracts
export const deleteTanStackQueryContractsRow = (
    queryClient: QueryClient,
    rowId: number
) => {
    queryClient.setQueryData(
        ['contracts'],
        (oldData: ContractTree | undefined) => {
            if (!oldData) {
                return oldData;
            }

            // Delete requested row
            let updatedRows = oldData.rows.filter((r) => r.id !== rowId);

            // Find any rows the deleted row was a parent of
            const childrenRows = updatedRows.filter(
                (row) => row.hierarchy?.[0] === String(rowId)
            );
            let maxVersion = 0,
                maxVersionRowId = 0;
            childrenRows.forEach((row) => {
                if (row.version.number > maxVersion) {
                    maxVersion = row.version.number;
                    maxVersionRowId = row.id;
                }
            });

            updatedRows = updatedRows.map((row) => {
                const parentRowId = row.hierarchy?.[0];
                if (parentRowId !== String(rowId)) {
                    return row;
                }

                // Highest remaining version should now become top-level row
                if (row.id === maxVersionRowId) {
                    return {
                        ...row,
                        hierarchy: row.hierarchy?.slice(1),
                    };
                }

                // Remaining versions need to be made children of the highest version
                return {
                    ...row,
                    hierarchy: [
                        String(maxVersionRowId),
                        ...(row.hierarchy?.slice(1) ?? []),
                    ],
                };
            });

            return {
                ...oldData,
                rows: updatedRows,
            };
        }
    );
};

//Add a contract row to a list of contracts
export const addTanStackQueryContractsRow = (
    queryClient: QueryClient,
    newRow: ContractTreeRow
) => {
    queryClient.setQueryData(
        ['contracts'],
        (oldData: ContractTree | undefined) => {
            if (!oldData || oldData.rows.some((row) => row.id === newRow.id)) {
                return oldData;
            }

            // Add "dummy" processing steps to show as a placeholder
            const dummySteps = [
                'document_conversion',
                'docx_parser',
                'party_detection',
                'outline_generation',
                'paragraphs_creation',
                'paragraphs_sections_creation',
                'entity_predictions',
                'frequency_analysis',
                'issues_updated',
            ];
            const rowWithSteps: ContractTreeRow = {
                ...newRow,
                processing_steps: dummySteps.map((step) => ({
                    name: step,
                    expected: 0,
                    requested: 0,
                    failed: 0,
                    succeeded: 0,
                    status: 'not_yet_started',
                })),
            };

            return {
                ...oldData,
                rows: [rowWithSteps, ...oldData.rows],
            };
        }
    );
};
