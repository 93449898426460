import { useAppSelector } from '@modules/common/hooks';
import React, {
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { clearLoginDetailsAction } from '../../../../components/Auth/Auth-actions';
import SplashLoader from '../../../../components/SplashLoader/SplashLoader';
import { auth0Client } from '../../auth0Client';
import { legacyUILogout } from './helpers';
import { generateLogoutUrl } from '../../../../components/Auth/Auth-helpers';
import { getShouldUseNoAccountCode } from '@modules/auth/helpers';

export interface AuthContextValue {
    logout: () => void;
}

export const AuthContext = React.createContext<AuthContextValue>({
    logout: () => {},
});

export const useAuthContext = () => {
    const context = useContext(AuthContext);
    return context;
};

interface AuthProviderProps {
    children?: React.ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
    const dispatch = useDispatch();

    const featureToggles = useAppSelector(
        (state) => state.featureToggles.toggles
    );

    const legacyLogoutIFrameRef = useRef<HTMLIFrameElement>(null);

    const [isLoggingOut, setIsLoggingOut] = useState(false);

    const handleLogout = useCallback(async () => {
        const shouldUseNoAccountCode =
            getShouldUseNoAccountCode(featureToggles);

        setIsLoggingOut(true);

        const iframe = legacyLogoutIFrameRef.current;
        if (iframe) {
            await legacyUILogout(iframe);
        }

        dispatch(clearLoginDetailsAction());

        if (shouldUseNoAccountCode) {
            auth0Client.logout({
                logoutParams: {
                    returnTo: window.location.origin,
                },
            });
        } else {
            window.location.assign(
                generateLogoutUrl(
                    window.__APP_CONFIG__.auth.baseUrl,
                    window.__APP_CONFIG__.auth.clientId
                )
            );
        }
    }, [dispatch, featureToggles]);

    const value = useMemo<AuthContextValue>(
        () => ({
            logout: handleLogout,
        }),
        [handleLogout]
    );

    return (
        <AuthContext.Provider value={value}>
            {isLoggingOut && <SplashLoader message="Signing out..." />}
            {children}

            <iframe
                style={{ display: 'none' }}
                ref={legacyLogoutIFrameRef}
                title="Logout"
            />
        </AuthContext.Provider>
    );
};

export default AuthProvider;
