import { useState, useEffect } from 'react';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material';

import './DataGridDropdown.scss';
import { IDataGridDropdownProps } from './DataGridDropdown-types';
import { IContractReportRow } from '../HybridReportDataGrid-types';
import { Icon, IconType } from '@thought-river/ui-components';
import {
    commitCellChanges,
    goToNextCell,
} from '../HybridReportDataGrid-helpers';

const DataGridDropdown = (props: IDataGridDropdownProps): JSX.Element => {
    const {
        attribute,
        options,
        cellParams: { id, value, field },
        renderLabel,
    } = props;

    const [open, setOpen] = useState(true);

    const apiRef = useGridApiContext();

    const row = value as IContractReportRow;

    const onKeyDown = async (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault();

            goToNextCell(apiRef, props.cellParams);
            return;
        }

        if (!event.ctrlKey && !event.shiftKey && event.key === 'Enter') {
            commitCellChanges(apiRef, props.cellParams);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    });

    const handleValueChange = async (event: SelectChangeEvent<any>) => {
        event.stopPropagation();

        const newRow = { ...row, [attribute]: event.target.value };

        commitCellChanges(apiRef, { ...props.cellParams, value: newRow });
    };

    const onClickOption = (option: string) => {
        if (option === row[attribute]) {
            apiRef.current.setCellMode(id, field, 'view');
        }
    };

    return (
        <Select
            className="data-grid-dropdown"
            data-id={`edit-${field}-dropdown`}
            labelId={`edit-${field}-dropdown`}
            value={row[attribute]}
            onChange={(event: SelectChangeEvent<any>) => {
                handleValueChange(event);
            }}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            open={open}
            fullWidth
            size="small"
            MenuProps={{
                className: 'data-grid-dropdown-menu-items',
            }}
            inputProps={{
                className: 'data-grid-dropdown-input',
            }}
            notched={false}
        >
            {options.map((option) => (
                <MenuItem
                    className="menu-item"
                    key={option}
                    value={option}
                    style={{ paddingLeft: '14px' }}
                    onClick={() => onClickOption(option)}
                >
                    <Icon type={option as IconType} />
                    <span className="mui-dropdown-option">
                        {renderLabel ? renderLabel(option) : option}
                    </span>
                </MenuItem>
            ))}
        </Select>
    );
};

export default DataGridDropdown;
