import { ReportColumn } from '@thought-river/negotiations-common/dist/api/contractContent';

export const columnInformationTooltipTitle: Record<ReportColumn, string> = {
    contract_name:
        "The contract name, editable by users, defaults to the uploaded file's name.",
    own_party: "My Organization refers to your company's legal name.",
    counter_party: "Counterparty refers to the other side's legal name.",
    deal_type:
        'Deal type denotes the contract category for review in ThoughtRiver, each with its own specific playbook and risk triggers.',
    status: 'Contract Status displays the phase of negotiation the contract is in.',
    upload_date: 'The date the Contract was uploaded into the system.',
    last_processed_date:
        'The date the Contract was last read by the system. This may be different to the last time an AI analysis was conducted on the contract.',
    version:
        'The number of times a file was uploaded and processed to this negotiation. Versions in ThoughtRiver may be higher than counterparty exchanged versions.',
};
