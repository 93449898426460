import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { IRootState } from '../../../../redux/reducers-types';
import {
    setHighlightIndexAction,
    setSelectedParagraphAction,
} from '../../../Contract/Contract-actions';
import { HighlightIndexType } from '../../../Contract/Contract-types';
import { IContractParagraph } from '../../../Paragraph/Paragraph-types';
import IssuePaneIntelTab from './IssuePaneIntelTab';
import {
    IIssuePaneIntelTabDispatchProps,
    IIssuePaneIntelTabStateProps,
} from './IssuePaneIntelTab-types';

function mapStateToProps(state: IRootState): IIssuePaneIntelTabStateProps {
    return {
        issue: state.contract.selectedIssue,
        paragraphs: state.contract.paragraphs,
        themes: state.contract.themes.concat(state.contract.unscoredThemes),
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IIssuePaneIntelTabDispatchProps {
    return {
        setHighlightIndex: (index: number, highlightType: HighlightIndexType) =>
            dispatch(setHighlightIndexAction(index, highlightType)),
        setSelectedParagraph: (paragraph: IContractParagraph) =>
            dispatch(setSelectedParagraphAction(paragraph)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssuePaneIntelTab);
