import { Button } from '@thought-river/ui-components';
import { Link } from 'react-router-dom';

const ReportViewError = () => (
    <div>
        <h1>Oops! We've hit a "Clause-tastrophe"!</h1>
        <h3>Error 404: Report Not Found</h3>
        <p>
            The specific report you're looking for is either hidden behind some
            serious legal jargon or just doesn't exist. Yep, it's like a missing
            addendum in a 100-page contract!
        </p>
        <p>For now, why not go to more accessible territory?</p>

        <Link to="/reports" component={Button}>
            Reports
        </Link>
    </div>
);

export default ReportViewError;
