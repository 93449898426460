import {
    FEATURE_TOGGLE_NO_ACCOUNT_CODE,
    IFeatureToggle,
} from '../../components/FeatureToggleProvider/FeatureToggleProvider-types';

export const getShouldUseNoAccountCode = (featureToggles: IFeatureToggle[]) => {
    const localStorageOverride = localStorage.getItem('noAccountCodeOverride');
    if (localStorageOverride === 'true') {
        return true;
    }

    if (window.__APP_CONFIG__.auth0.region === 'UK') {
        return true;
    }

    return !!featureToggles.find(
        (toggle) => toggle.feature === FEATURE_TOGGLE_NO_ACCOUNT_CODE
    )?.enabled;
};
