import { SubmitStatus } from '../App/App-types';
import {
    IIssueForm,
    ISetIssueFormAction,
    SET_ISSUE_FORM,
    ISetIssueFormStatusAction,
    SET_ISSUE_FORM_STATUS,
} from './IssuePane-types';

export function setIssueFormAction(form: IIssueForm): ISetIssueFormAction {
    return {
        type: SET_ISSUE_FORM,
        form,
    };
}

export function setIssueFormStatusAction(
    status: SubmitStatus
): ISetIssueFormStatusAction {
    return {
        type: SET_ISSUE_FORM_STATUS,
        status,
    };
}
