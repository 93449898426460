import { without } from 'lodash';
import Api from '../services/api';
import { Method, requestOptions } from '../services/api-types';
import {
    IAssessmentResourceStatic,
    IAssessmentResource,
} from './AssessmentResource-types';

let controller: AbortController;
//keep track of contract ids for which we're requesting assessments
let contractIds: string[] = [];

const AssessmentResource: IAssessmentResourceStatic = class AssessmentResource
    implements IAssessmentResource
{
    static async getAssessment(
        contractId: string,
        versionId: string,
        assessmentId: string,
        stream: string
    ): Promise<any> {
        const url = `/contracts/${contractId}/versions/${versionId}/assessments/${assessmentId}`;
        const options = { ...requestOptions, streamCode: stream };
        return Api.request(Method.GET, url, options)
            .then((data) => data?.json())
            .catch((error) => console.log(error));
    }

    static async getAssessments(
        contractId: string,
        versionId: string,
        stream: string
    ): Promise<any> {
        //Only cancel the request if there exists another assessments request with the same contract id
        if (controller && contractIds.includes(contractId)) {
            controller.abort();
        }
        controller = new AbortController();
        contractIds.push(contractId);

        const url = `/contracts/${contractId}/versions/${versionId}/assessments`;
        const options = {
            ...requestOptions,
            streamCode: stream,
            abortSignal: controller.signal,
        };
        return Api.request(Method.GET, url, options)
            .then((data) => data?.json())
            .catch((error) => console.log(error))
            .finally(() => (contractIds = without(contractIds, contractId)));
    }
};

export default AssessmentResource;
