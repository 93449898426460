import './PropertyDetails.scss';
import { IPropertyDetailsProps } from './PropertyDetails-types';
import './PropertyDetails.scss';
import { Info } from '@mui/icons-material';
import { propertyAnswerMissing } from '../App/App-helpers';
import { Drawer, Tooltip } from '@thought-river/ui-components';
import { isSummaryProperty } from './PropertyDetails-helpers';
import { useSelector } from 'react-redux';
import { IRootState } from '../../redux/reducers-types';
import { FEATURE_TOGGLE_TEST_AI_USER_VALUE } from '../FeatureToggleProvider/FeatureToggleProvider-types';

const PropertyDetails = ({
    open,
    width,
    onClose,
    property,
    policies,
}: IPropertyDetailsProps): JSX.Element => {
    const featureToggles = useSelector(
        (state: IRootState) => state.featureToggles.toggles
    );
    const testUserValueEnabled = !!featureToggles.find(
        (toggle) => toggle.feature === FEATURE_TOGGLE_TEST_AI_USER_VALUE
    )?.enabled;

    const renderDetailLabel = (label: string, tooltipValue: string = '') => (
        <div className="detail-label">
            <div className="detail-label-text">{label}</div>
            <Tooltip title={tooltipValue}>
                <Info className="detail-info-icon" />
            </Tooltip>
        </div>
    );

    const renderDetail = (
        label: string,
        value: string,
        tooltipValue: string = ''
    ) => (
        <div className="detail">
            {renderDetailLabel(label, tooltipValue)}
            <div className="detail-value">{value ?? '-'}</div>
        </div>
    );

    const renderQuestions = () => (
        <div className="detail">
            {renderDetailLabel(
                'Lexible Question and Answer',
                testUserValueEnabled
                    ? 'The legal question used to train our AI and this contract’s predicted answer. If a user has edited the answer you can see the latest change and compare against the original predicted answer here.'
                    : 'The legal question created to train our AI'
            )}
            <div className="questions">
                <div className="question">
                    <div className="detail-value">{property.question}</div>
                    {testUserValueEnabled
                        ? renderPropertyAnswers()
                        : renderAnswer(property.value)}
                </div>
                {property.relatedProperties.map((relatedProperty) =>
                    relatedProperty.question.length &&
                    relatedProperty.answer.length ? (
                        <div className="question" key={relatedProperty.id}>
                            <div className="detail-value">
                                {relatedProperty.question}
                            </div>
                            {renderAnswer(relatedProperty.answer)}
                        </div>
                    ) : null
                )}
            </div>
        </div>
    );

    const renderAnswer = (answer: string, answerSource?: string) => (
        <div className="answer">
            {answerSource && (
                <div className="answer-source">{answerSource}</div>
            )}

            {propertyAnswerMissing(answer) ? (
                <span className="property-answer-missing">Unavailable</span>
            ) : (
                answer
            )}
        </div>
    );

    const renderPropertyAnswers = () => {
        if (!isSummaryProperty(property) || !property.userValueDisplayText) {
            return renderAnswer(property.value);
        }

        return (
            <>
                {property.autodetectedValueDisplayText &&
                    renderAnswer(
                        property.autodetectedValueDisplayText,
                        'Lexible predicted'
                    )}

                {property.userValueDisplayText &&
                    renderAnswer(
                        property.userValueDisplayText,
                        'User edited the answer'
                    )}
            </>
        );
    };

    const policyNames = policies
        .filter((policy) => policy.propertyIds.includes(property?.id!)) // Fixme: null checks
        .map((policy) => policy.name);

    return (
        <Drawer
            open={open}
            width={width}
            onClose={onClose}
            title="Lexible property details"
        >
            {property ? (
                <>
                    {renderDetail(
                        'Lexible Name',
                        property.name,
                        'The descriptive name for the Lexible property'
                    )}
                    {renderDetail(
                        'Lexible Code',
                        property.code,
                        'The property reference code (abbreviated from the property name)'
                    )}
                    {renderDetail(
                        'Lexible Label',
                        property.shortLabel,
                        'The short name for the Lexible property'
                    )}
                    {renderQuestions()}
                    {renderDetail(
                        'Help Text',
                        property.helpText,
                        'A guide to how the Lexible question is interpreted and trained'
                    )}
                    {renderDetail(
                        'Theme',
                        property.theme.name,
                        'The high-level legal categories in which properties exist'
                    )}
                    {renderDetail(
                        'Policy',
                        policyNames.join(', ') || null!, // Fixme: null checks
                        'A key part of a playbook, selected properties with risks assigned to them'
                    )}
                </>
            ) : (
                'Property details unavailable'
            )}
        </Drawer>
    );
};

export default PropertyDetails;
