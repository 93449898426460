import { Component } from 'react';

import {
    IHeaderTabsProps,
    IHeaderTabsState,
    IHeaderTabsStateProps,
    TabColorTheme,
} from './HeaderTabs-types';
import './HeaderTabs.scss';
import { Tab, Tabs } from '@mui/material';

class HeaderTabs extends Component<
    IHeaderTabsProps,
    IHeaderTabsState,
    IHeaderTabsStateProps
> {
    constructor(props: IHeaderTabsProps) {
        super(props);

        this.state = {
            selectedTabIndex: 0,
        };

        this.handleTabChange = this.handleTabChange.bind(this);
    }

    componentDidMount(): void {
        this.updateSelectedTab();
    }

    componentDidUpdate(prevProps: IHeaderTabsProps): void {
        const {
            location: { pathname },
        } = this.props;

        if (prevProps.location.pathname !== pathname) {
            this.updateSelectedTab();
        }
    }

    private handleTabChange(_: any, selectedTabIndex: number) {
        this.setState({
            selectedTabIndex,
        });
    }

    private updateSelectedTab() {
        const {
            location: { pathname },
            tabs,
        } = this.props;

        for (const [index, tab] of tabs.entries()) {
            const target = tab.target.replace('#', '');

            if (
                target === pathname ||
                (pathname !== '/' && pathname.includes(target))
            ) {
                this.setState({
                    selectedTabIndex: index,
                });
            }
        }
    }

    private renderLinkTab(
        index: number,
        label: JSX.Element | string,
        target: string
    ) {
        return (
            <Tab
                className="tab"
                href={target}
                key={`tab-${target}`}
                label={label}
                component="a"
                data-id={`tab-${index}`}
                onClick={(event: any) => {
                    event.preventDefault();
                    this.props.history.push(target.replace('#', ''));
                }}
            />
        );
    }

    render() {
        const { colorTheme, tabs } = this.props;

        return (
            <div
                className={`header-tabs ${colorTheme ?? TabColorTheme.DARK}`}
                data-id="header-tabs"
            >
                <Tabs
                    value={this.state.selectedTabIndex}
                    onChange={this.handleTabChange}
                    className="tabs"
                    data-id="tabs-wrapper"
                >
                    {tabs.map((tab, index) =>
                        this.renderLinkTab(index, tab.label, tab.target)
                    )}
                </Tabs>
            </div>
        );
    }
}

export default HeaderTabs;
