import { createRef } from 'react';
import * as React from 'react';
import { copyRteContent } from '../../../../App/App-helpers';
import {
    EXTERNAL_COMMENTS,
    INTERNAL_GUIDANCE,
} from '../../../../App/App-types';
import CopyableElement from '../../../../CopyableElement/CopyableElement';
import RichTextEditor from '../../../../RichTextEditor/RichTextEditor';
import {
    IIssuePaneNotesProps,
    IIssuePaneNotesState,
    IssuePaneNotesTab,
} from './IssuePaneNotes-types';
import { analytics } from './IssuePaneNotes-analytics';
import { Tabs } from '@mui/material';
import './IssuePaneNotes.scss';
import CustomButton from '../../../../CustomButton/CustomButton';
import { CheckCircle, FileCopyOutlined } from '@mui/icons-material';
import { Tab } from '@thought-river/ui-components';

@analytics()
export default class IssuePaneNotes extends React.Component<
    IIssuePaneNotesProps,
    IIssuePaneNotesState
> {
    private editorRef: React.RefObject<any>;

    constructor(props: IIssuePaneNotesProps) {
        super(props);

        this.onInputBlur = this.onInputBlur.bind(this);
        this.onInputChange = this.onInputChange.bind(this);

        this.state = {
            selectedTabIndex: IssuePaneNotesTab.INTERNAL,
        };

        this.editorRef = createRef();
    }

    // Need 2 different functions to differentiate mixpanel events
    private onCopyDescription(event: React.MouseEvent) {
        event.stopPropagation();

        copyRteContent(
            '[data-contents="true"]',
            this.editorRef?.current?.isFocused()
        );
    }

    private onCopyNotes(event: React.MouseEvent) {
        event.stopPropagation();

        copyRteContent(
            '[data-contents="true"]',
            this.editorRef?.current?.isFocused()
        );
    }

    private onInputBlur() {
        const { form, issue, saveIssue } = this.props;

        const { selectedTabIndex } = this.state;

        if (!issue) {
            return;
        }

        const save = () => {
            setTimeout(() => {
                saveIssue();
            }, 250);
        };

        if (
            selectedTabIndex === IssuePaneNotesTab.INTERNAL &&
            issue.description !== form.description
        ) {
            save();
        } else if (
            selectedTabIndex === IssuePaneNotesTab.EXTERNAL &&
            issue.notes !== form.notes
        ) {
            save();
        }
    }

    private onInputChange(value: string) {
        const { form, debouncedSaveIssue, issue, setIssueForm } = this.props;

        const { selectedTabIndex } = this.state;

        if (selectedTabIndex === IssuePaneNotesTab.INTERNAL) {
            setIssueForm({ ...form, description: value });
        } else if (selectedTabIndex === IssuePaneNotesTab.EXTERNAL) {
            setIssueForm({ ...form, notes: value });
        }

        if (issue) {
            debouncedSaveIssue();
        }
    }

    render() {
        const { form, formInitialised } = this.props;

        const { selectedTabIndex } = this.state;

        return (
            <>
                <Tabs
                    value={selectedTabIndex}
                    onChange={(_, newIndex: IssuePaneNotesTab) =>
                        this.setState({ selectedTabIndex: newIndex })
                    }
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    scrollButtons="auto"
                >
                    <Tab label={INTERNAL_GUIDANCE} />
                    <Tab label={EXTERNAL_COMMENTS} />
                </Tabs>
                <div className="issue-description-wrapper">
                    <RichTextEditor
                        ref={this.editorRef}
                        key={
                            // Making the key unique for each issue to ensure the RTE re-renders
                            // when switching between issues
                            selectedTabIndex === IssuePaneNotesTab.INTERNAL
                                ? `internal-notes-${form.issueId}`
                                : `external-notes-${form.issueId}`
                        }
                        dynamicHeight
                        inlineToolbar
                        onBlur={this.onInputBlur}
                        onChange={this.onInputChange}
                        placeholder={
                            selectedTabIndex === IssuePaneNotesTab.INTERNAL
                                ? 'Internal Notes'
                                : EXTERNAL_COMMENTS
                        }
                        reset={formInitialised}
                        value={
                            selectedTabIndex === IssuePaneNotesTab.INTERNAL
                                ? form.description
                                : form.notes
                        }
                        showToolbarOnFocus
                        autoFocus
                    />
                    <CustomButton
                        animatedButton
                        buttonType="light"
                        callback={(event) =>
                            selectedTabIndex === IssuePaneNotesTab.INTERNAL
                                ? this.onCopyDescription(event)
                                : this.onCopyNotes(event)
                        }
                        disabled={
                            selectedTabIndex === IssuePaneNotesTab.INTERNAL
                                ? !form.description
                                : !form.notes
                        }
                        iconBefore={<FileCopyOutlined />}
                        iconAfter={<CheckCircle />}
                        textBefore="Copy"
                        textAfter="Copied"
                    />
                    <CopyableElement
                        id="copyable-notes"
                        content={
                            selectedTabIndex === IssuePaneNotesTab.INTERNAL
                                ? form.description
                                : form.notes
                        }
                    />
                </div>
            </>
        );
    }
}
