import { Action } from 'redux';
import { IDisplayContract } from '../Contract/Contract-types';
import { IFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider-types';

export const ADD_PROCESSING_CONTRACT = 'ADD_PROCESSING_CONTRACT';
export const UNSET_PROCESSING_CONTRACT = 'UNSET_PROCESSING_CONTRACT';
export const SET_REPROCESSING_CONTRACT = 'SET_REPROCESSING_CONTRACT';
export const UNSET_REPROCESSING_CONTRACT = 'UNSET_REPROCESSING_CONTRACT';
export const SET_TIMED_OUT_PROCESSING_CONTRACT_ID =
    'SET_TIMED_OUT_PROCESSING_CONTRACT_ID';
export const SET_TIMED_OUT_PROCESSING_CONTRACT_IDS =
    'SET_TIMED_OUT_PROCESSING_CONTRACT_IDS';
export const SET_POLLING_PROCESSING_CONTRACTS =
    'SET_POLLING_PROCESSING_CONTRACTS';
export const SET_POLLING_REPROCESSING_CONTRACTS =
    'SET_POLLING_REPROCESSING_CONTRACTS';
export const SET_SUCCESSFUL_PROCESSED_CONTRACT =
    'SET_SUCCESSFUL_PROCESSED_CONTRACT';
export const SET_FAILED_PROCESSED_CONTRACT = 'SET_FAILED_PROCESSED_CONTRACT';
export const SET_SUCCESSFUL_REPROCESSED_CONTRACT =
    'SET_SUCCESSFUL_REPROCESSED_CONTRACT';
export const UNSET_SUCCESSFUL_REPROCESSED_CONTRACT =
    'UNSET_SUCCESSFUL_REPROCESSED_CONTRACT';
export const SET_FAILED_REPROCESSED_CONTRACT =
    'SET_FAILED_REPROCESSED_CONTRACT';
export const UNSET_NOTIFIED_CONTRACT = 'UNSET_NOTIFIED_CONTRACT';
export const UPDATE_PROCESSING_CONTRACT = 'UPDATE_PROCESSING_CONTRACT';

export interface IProcessingContract {
    contractId: string;
    contractName: string;
    stream: string;
    userId: string;
    isPartiallyAssessed: boolean;
    status: string;
    // This property will be set only if contracts tree is enabled
    percentageProgress?: number;
}

export interface IReprocessingContract {
    assessmentCount: number;
    contractId: string;
    contractName: string;
    stream: string;
    streamId: string;
    versionId: string;
    queuedAt: string;
}

export interface IContractPollingProviderState {}

export interface IContractPollingProviderStateProps {
    pollingProcessing: boolean;
    pollingReprocessing: boolean;
    processingContracts: IProcessingContract[];
    reprocessingContracts: IReprocessingContract[];
    timedOutProcessingContractIds: string[];
    featureToggles: IFeatureToggle[];
}

export interface IContractPollingProviderReducer {
    failedProcessedContracts: IProcessingContract[];
    failedReprocessedContracts: IReprocessingContract[];
    pollingProcessing: boolean;
    pollingReprocessing: boolean;
    processingContracts: IProcessingContract[];
    reprocessingContracts: IReprocessingContract[];
    successfulProcessedContracts: IProcessingContract[];
    successfulReprocessedContracts: IReprocessingContract[];
    timedOutProcessingContractIds: string[];
}

export interface IContractPollingProviderDispatchProps {
    setFailedProcessedContract: (contract: IProcessingContract) => void;
    setFailedReprocessedContract: (contract: IReprocessingContract) => void;
    setPollingProcessing: (polling: boolean) => void;
    setPollingReprocessing: (polling: boolean) => void;
    setSuccessfulProcessedContract: (
        contract: IProcessingContract,
        isPartiallyAssessed: boolean
    ) => void;
    setSuccessfulReprocessedContract: (
        contract: IReprocessingContract,
        isPartiallyAssessed: boolean
    ) => void;
    setTimedOutProcessingContractId: (contractId: string) => void;
    setTimedOutProcessingContractIds: (contractIds: string[]) => void;
    unsetProcessingContract: (contractId: string) => void;
    unsetReprocessingContract: (contractId: string) => void;
    updateContract: (
        contractId: string,
        fields: Partial<IDisplayContract>
    ) => void;
    updateProcessingContract: (contract: IProcessingContract) => void;
}

export interface IContractPollingProviderProps
    extends IContractPollingProviderDispatchProps,
        IContractPollingProviderStateProps {
    children?: any;
}

export interface IUnsetProcessingContractAction extends Action {
    type: typeof UNSET_PROCESSING_CONTRACT;
    contractId: string;
}

export interface IAddProcessingContractAction extends Action {
    type: typeof ADD_PROCESSING_CONTRACT;
    contract: IProcessingContract;
}

export interface ISetTimedOutProcessingContractIdAction extends Action {
    type: typeof SET_TIMED_OUT_PROCESSING_CONTRACT_ID;
    timedOutProcessingContractId: string;
}

export interface ISetTimedOutProcessingContractIdsAction extends Action {
    type: typeof SET_TIMED_OUT_PROCESSING_CONTRACT_IDS;
    timedOutProcessingContractIds: string[];
}

export interface ISetReprocessingContractAction extends Action {
    type: typeof SET_REPROCESSING_CONTRACT;
    assessmentCount: number;
    contractId: string;
    contractName: string;
    stream: string;
    streamId: string;
    userId: string;
    versionId: string;
}

export interface IUnsetReprocessingContractAction extends Action {
    type: typeof UNSET_REPROCESSING_CONTRACT;
    contractId: string;
}

export interface ISetPollingProcessingContractAction extends Action {
    type: typeof SET_POLLING_PROCESSING_CONTRACTS;
    pollingProcessing: boolean;
}

export interface ISetPollingReprocessingContractAction extends Action {
    type: typeof SET_POLLING_REPROCESSING_CONTRACTS;
    pollingReprocessing: boolean;
}

export interface ISetSuccessfulProcessedContractAction extends Action {
    type: typeof SET_SUCCESSFUL_PROCESSED_CONTRACT;
    contract: IProcessingContract;
    isPartiallyAssessed: boolean;
}

export interface ISetSuccessfulReprocessedContractAction extends Action {
    type: typeof SET_SUCCESSFUL_REPROCESSED_CONTRACT;
    contract: IReprocessingContract;
    isPartiallyAssessed: boolean;
}

export interface IUnsetSuccessfulReprocessedContractAction extends Action {
    type: typeof UNSET_SUCCESSFUL_REPROCESSED_CONTRACT;
    contractId: string;
}

export interface ISetFailedProcessedContractAction extends Action {
    type: typeof SET_FAILED_PROCESSED_CONTRACT;
    contract: IProcessingContract;
}

export interface ISetFailedReprocessedContractAction extends Action {
    type: typeof SET_FAILED_REPROCESSED_CONTRACT;
    contract: IReprocessingContract;
}

export interface IUnsetNotifiedContractAction {
    type: typeof UNSET_NOTIFIED_CONTRACT;
    contractId: string;
}

export interface IUpdateProcessingContract {
    type: typeof UPDATE_PROCESSING_CONTRACT;
    contract: IProcessingContract;
}

export type IContractPollingProviderActionTypes =
    | IUnsetProcessingContractAction
    | IAddProcessingContractAction
    | ISetReprocessingContractAction
    | IUnsetReprocessingContractAction
    | ISetTimedOutProcessingContractIdAction
    | ISetTimedOutProcessingContractIdsAction
    | ISetPollingProcessingContractAction
    | ISetPollingReprocessingContractAction
    | ISetSuccessfulProcessedContractAction
    | ISetSuccessfulReprocessedContractAction
    | IUnsetSuccessfulReprocessedContractAction
    | ISetFailedProcessedContractAction
    | ISetFailedReprocessedContractAction
    | IUnsetNotifiedContractAction
    | IUpdateProcessingContract;
