import { IIssueRequestPayload } from '../../../../../../resources/IssueResource-types';
import IssueResource from '../../../../../../resources/IssueResource';
import { ComponentReference } from '../../../../../../services/Analytics/Analytics-types';
import { capitalize } from '@thought-river/negotiations-common';
import { useParams } from 'react-router-dom';
import { UrlParams } from '@modules/reports/contractReports/types';
import { useAddIssueComment } from '@thought-river/negotiations-common/dist/api/contractContent';
/**
 * Hook providing `processRowUpdate` callback to be passed to DataGrid
 * It handles storing all updated values in the backend after a cell was edited
 */
export const useProcessRowUpdate = () => {
    const { contractId, versionId, dealType } = useParams<UrlParams>();

    const { mutateAsync } = useAddIssueComment();

    const processRowUpdate = (
        newRow: Record<string, any>,
        oldRow: Record<string, any>
    ) => {
        if (!newRow['issue_id']) {
            return oldRow;
        }

        // List of fields that are updated using the old issues API
        const issueFields = [
            'issue_internal_guidance',
            'issue_external_comments',
            'issue_severity_level',
        ];
        const haveIssueFieldsChanged =
            newRow['issue_id'] &&
            issueFields.some((field) => newRow[field] !== oldRow[field]);

        if (haveIssueFieldsChanged) {
            const payload: IIssueRequestPayload = {
                data: {
                    attributes: {
                        description: newRow['issue_internal_guidance'],
                        notes: newRow['issue_external_comments'],
                        'severity-level': capitalize(
                            newRow['issue_severity_level']
                        ),
                    },
                },
            };

            IssueResource.updateIssue(
                contractId,
                versionId,
                newRow['issue_id'],
                dealType,
                payload,
                ComponentReference.ISSUES_REPORT
            );
        }

        const hasDecisionChanged =
            newRow['issue_decision'] !== oldRow['issue_decision'];
        if (hasDecisionChanged) {
            mutateAsync({
                body: {
                    comment: newRow['issue_decision'],
                    is_decision: true,
                },
                pathParams: {
                    issueUuid: newRow['issue_id'],
                },
            });
        }

        return newRow;
    };

    return { processRowUpdate };
};
