import { useState } from 'react';
import { toast } from 'react-toastify';
import { AddPeopleDialogProps } from './AddPeopleDialog.types';
import { CheckCircle, Link } from '@mui/icons-material';
import styles from './AddPeopleDialog.module.scss';
import {
    Select,
    SelectOption,
    Avatar,
    Button,
    Dialog,
} from '@thought-river/ui-components';
import ConfirmSetOwnerDialog from './ConfirmSetOwnerDialog';
import { useAppSelector } from '@modules/common/hooks';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import { UserType } from '@modules/common/types';
import ContractResource from '../../../../resources/ContractResource';
import { copyTextToClipboard } from '../../../../components/App/App-helpers';
import AddPeopleDialogUser from './AddPeopleDialogUser/AddPeopleDialogUser';
import { IAccountUser } from '../../../../components/Auth/Auth-types';

const AddPeopleDialog = ({
    contractInfo,
    open,
    onClose,
}: AddPeopleDialogProps) => {
    const { uuid, dealTypeCode, versionUuid } = contractInfo;

    const [newUser, setNewUser] = useState<IAccountUser | null>(null);
    const [updatedUserIds, setUpdatedUserIds] = useState<string[]>([]);
    const [confirmSetOwnerUser, setConfirmSetOwnerUser] =
        useState<IAccountUser | null>(null);
    const [updatedContractInfo, setUpdatedContractInfo] =
        useState(contractInfo);

    const users = useAppSelector((state) => state.auth.users);
    const currentUserId = useAppSelector((state) => state.auth.userId);

    const appUrl = window.location.origin;
    const contractUrl = encodeURI(
        `${appUrl}/#/stream/${dealTypeCode}/contract/${uuid}/version/${versionUuid}`
    );

    const ownerId = updatedContractInfo.ownerId;
    const addedUsers = users.filter((user) =>
        updatedContractInfo.reviewerIds.includes(user.id)
    );

    //User list order: current user -> owner -> the rest
    addedUsers.sort((a) => (a.id === ownerId ? -1 : 1));
    addedUsers.sort((a) => (a.id === currentUserId ? -1 : 1));

    const userOptions = (() => {
        const addedUserIds = addedUsers.map((user) => user.id);
        return users
            .filter((user) => user.streamCodes.includes(dealTypeCode))
            .filter((user) => !addedUserIds.includes(user.id))
            .map((user) => {
                const userName = `${user.firstName} ${user.lastName}`;

                return {
                    label: userName,
                    value: user.id,
                };
            });
    })();

    const updateUserType = (userId: string, newUserType: UserType) => {
        if (newUserType === UserType.OWNER) {
            const newOwner = users.find((user) => user.id === userId) ?? null;
            setConfirmSetOwnerUser(newOwner);
        }
    };

    const handleConfirmSetOwnerDialogClose = (shouldSetOwner: boolean) => {
        if (shouldSetOwner && confirmSetOwnerUser) {
            const newOwnerId = confirmSetOwnerUser?.id;
            const previousOwnerId = updatedContractInfo.ownerId;

            try {
                setUpdatedContractInfo({
                    ...updatedContractInfo,
                    ownerId: newOwnerId,
                });

                ContractResource.patchNegotiator(
                    uuid,
                    newOwnerId,
                    dealTypeCode
                ).then(() => {
                    setUpdatedUserIds((updatedUserIds) => [
                        ...new Set([
                            ...updatedUserIds,
                            newOwnerId,
                            previousOwnerId,
                        ]),
                    ]);
                });
            } catch {
                setUpdatedContractInfo({
                    ...updatedContractInfo,
                    ownerId: previousOwnerId,
                });

                toast.error('Error updating the owner.');
            }
        }

        setConfirmSetOwnerUser(null);
    };

    const onRemoveUser = (removedUserId: string) => {
        const previousReviewerIds = updatedContractInfo.reviewerIds;
        const newReviewerIds = previousReviewerIds.filter(
            (id) => id !== removedUserId
        );

        try {
            setUpdatedContractInfo({
                ...updatedContractInfo,
                reviewerIds: newReviewerIds,
            });

            ContractResource.patchReviewers(uuid, newReviewerIds, dealTypeCode);
        } catch {
            setUpdatedContractInfo({
                ...updatedContractInfo,
                reviewerIds: previousReviewerIds,
            });

            toast.error('Error updating the reviewers.');
        }
    };

    const onSelectUser = (selectedUserOption: SelectOption) => {
        if (!selectedUserOption) {
            return;
        }

        const previousReviewerIds = updatedContractInfo.reviewerIds;
        const newReviewerIds = [
            ...previousReviewerIds,
            selectedUserOption.value,
        ];

        try {
            setUpdatedContractInfo({
                ...updatedContractInfo,
                reviewerIds: newReviewerIds,
            });
            setNewUser(
                users.find((user) => selectedUserOption.value === user.id) ??
                    null
            );

            ContractResource.patchReviewers(uuid, newReviewerIds, dealTypeCode);
        } catch {
            setUpdatedContractInfo({
                ...updatedContractInfo,
                reviewerIds: previousReviewerIds,
            });
            setNewUser(null);

            toast.error('Error updating the reviewers.');
        }
    };

    const onCopyUrl = (url: string = '') => {
        try {
            copyTextToClipboard(url, false);
        } catch {
            toast.error('Error copying URL');
        }
    };

    const dialogContent = (
        <>
            <Select
                data-id="invite-users-dropdown"
                placeholder="Type a name and hit enter"
                label="Invite people"
                multiple={false}
                onChange={onSelectUser}
                options={userOptions}
                renderOption={(option) => (
                    <Avatar label={option.label} size="x-small" showLabel />
                )}
                // Passing null to prevent Select from keeping user name in input after selection
                value={null as any}
            />
            <ul className={styles.addedUsersList}>
                {addedUsers.map((user) => (
                    <AddPeopleDialogUser
                        key={user.id}
                        currentUserId={currentUserId}
                        ownerId={ownerId}
                        user={user}
                        isUpdated={updatedUserIds.includes(user.id)}
                        isNew={newUser?.id === user.id}
                        onUserTypeChangeCallback={updateUserType}
                        onUserRemovedCallback={onRemoveUser}
                    />
                ))}
            </ul>
            {confirmSetOwnerUser && (
                <ConfirmSetOwnerDialog
                    onClose={handleConfirmSetOwnerDialogClose}
                    newOwner={confirmSetOwnerUser}
                />
            )}
        </>
    );

    const dialogActions = (
        <div className={styles.footer}>
            <CustomButton
                animatedButton
                buttonType="text"
                callback={() => onCopyUrl(contractUrl)}
                iconBefore={<Link />}
                iconAfter={<CheckCircle />}
                textBefore="Copy Link"
                textAfter="Link Copied"
            />
            <Button data-id="done-btn" onClick={onClose}>
                Done
            </Button>
        </div>
    );

    return (
        <Dialog
            dialogTitle="Add People"
            dialogContent={dialogContent}
            dialogActions={dialogActions}
            open={open}
            onClose={onClose}
            classes={{ paper: styles.paper }}
        />
    );
};

export default AddPeopleDialog;
