import * as React from 'react';
import { toast } from 'react-toastify';
import {
    Add,
    Delete,
    KeyboardArrowRight,
    PriorityHigh,
    Refresh,
    Warning,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

import {
    BULK_ACTION_LIMIT,
    IContractListTableProps,
    IContractListTableState,
} from './ContractListTable-types';
import ContractProgressBar from '../ContractProgressBar/ContractProgressBar';
import {
    ContractStatus,
    IContractVersion,
    IDisplayContract,
    TYPE_CONTRACT,
    TYPE_VERSION,
    UploadStatus,
} from '../Contract/Contract-types';
import UploadProgressBar from '../UploadProgressBar/UploadProgressBar';
import { IParty, PartiesType } from '../Parties/Parties-types';
import {
    PROGRESS_BAR_BKG_COLOUR,
    PROGRESS_BAR_FILL_COLOUR,
} from '../ContractProgressBar/ContractProgressBar-types';
import UserIcon from '../UserIcon/UserIcon-container';
import { analytics } from './ContractListTable-analytics';
import { Checkbox, Tooltip } from '@thought-river/ui-components';
import { LinearProgress } from '@mui/material';
import ContractMenu from '../ContractMenu/ContractMenu';
import { reprocessContract } from '../Contract/Contract-helpers';
import DeleteContractModal from '../DeleteContractModal/DeleteContractModal-container';
import DeleteVersionModal from '../DeleteVersionModal/DeleteVersionModal-container';
import dayjs from 'dayjs';
import ContractUploadWizardModal from '../ContractUploadWizardModal/ContractUploadWizardModal-container';
import { getContractPath } from '@modules/common/helpers';
import throttle from 'lodash/throttle';
import find from 'lodash/find';
import get from 'lodash/get';

const { COMPLETED, FAILED } = UploadStatus;

@analytics()
class ContractListTable extends React.Component<
    IContractListTableProps,
    IContractListTableState
> {
    private readonly tableRef: React.RefObject<any>;
    private readonly lastRowRef: React.RefObject<any>;

    constructor(props: IContractListTableProps) {
        super(props);

        this.state = {
            expandedContracts: [],
            scrollTop: 0,
            selectedContract: undefined,
        };

        this.deleteContract = this.deleteContract.bind(this);
        this.formatCounterParty = this.formatCounterParty.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.lastRowIsVisible = this.lastRowIsVisible.bind(this);
        this.renderContractStatus = this.renderContractStatus.bind(this);
        this.renderExpandButton = this.renderExpandButton.bind(this);
        this.renderNegotiationDays = this.renderNegotiationDays.bind(this);
        this.renderRow = this.renderRow.bind(this);
        this.onClickSetParties = this.onClickSetParties.bind(this);

        this.lastRowRef = React.createRef();
        this.tableRef = React.createRef();

        this.onWindowResize = this.onWindowResize.bind(this);
    }

    async componentDidMount() {
        if (!this.tableRef.current) {
            return;
        }

        await this.props.setSelectAllContracts(false);
        await this.props.setFetchingContracts(false);
        await this.props.setContracts([]);
        await this.props.setSelectedContractIds([]);
        await this.props.setHasNextPage(false);
        await this.props.setRequestedPages([]);
        await this.props.loadContracts();

        this.setState({
            scrollTop: get(this.tableRef, 'current.scrollTop', 0),
        });

        window.addEventListener('resize', this.onWindowResize);
    }

    async componentWillUnmount() {
        await this.props.setSelectAllContracts(false);
        await this.props.setContracts([]);
        await this.props.setSelectedContractIds([]);
        await this.props.setHasNextPage(false);
        await this.props.setRequestedPages([]);
        window.removeEventListener('resize', this.onWindowResize);
    }

    async componentDidUpdate(
        prevProps: IContractListTableProps,
        prevState: IContractListTableState
    ) {
        const { hasNextPage, loadContracts, currentPage, fetchingContracts } =
            this.props;

        if (
            hasNextPage &&
            ((prevProps.fetchingContracts && !fetchingContracts) ||
                prevState.scrollTop !== this.state.scrollTop)
        ) {
            if (this.lastRowIsVisible()) {
                await loadContracts(currentPage + 1);
            }
        }
    }

    private onWindowResize = throttle(async () => {
        if (!this.tableRef?.current || !this.lastRowRef.current) {
            return;
        }

        const { hasNextPage, loadContracts, currentPage, fetchingContracts } =
            this.props;

        if (hasNextPage && !fetchingContracts && this.lastRowIsVisible()) {
            await loadContracts(currentPage + 1);
        }
    }, 250);

    private contractIsDeletable(
        contract: IDisplayContract,
        timedOutProcessingContractIds: string[]
    ): boolean {
        const latestVersion = contract.latestVersion;

        return (
            !latestVersion ||
            this.versionIsMissingAssessments(latestVersion) ||
            this.versionFailedProcessing(latestVersion) ||
            this.contractTimedOut(contract.id, timedOutProcessingContractIds)
        );
    }

    private contractTimedOut(
        contractId: string,
        timedOutProcessingContractIds: string[] = []
    ): boolean {
        return timedOutProcessingContractIds.includes(contractId);
    }

    private deleteContract(contract: IDisplayContract) {
        if (contract.versions.length > 1) {
            this.openDeleteVersionModal(
                contract,
                contract.versions[contract.versions.length - 1]
            );
        } else {
            this.openDeleteContractModal(contract);
        }
    }

    private formatCounterParty(version: IContractVersion) {
        if (!version) {
            return '';
        }

        return version.counterParty ?? null;
    }

    private formatDate(date: string) {
        return dayjs(date).format('DD MMM YYYY');
    }

    private isMissingParties(parties: IParty[] = []): boolean {
        return parties
            .reduce(
                (acc, party) => {
                    const [hasCounterParty, hasOwnParty] = acc;

                    return [
                        hasCounterParty ||
                            party.type === PartiesType.COUNTER_PARTIES,
                        hasOwnParty || party.type === PartiesType.OWN_PARTIES,
                    ];
                },
                [false, false]
            )
            .includes(false);
    }

    private lastRowIsVisible(): boolean {
        if (!this.lastRowRef.current) {
            return false;
        }

        const rect = this.lastRowRef.current.getBoundingClientRect();

        return (
            rect.bottom > 0 &&
            rect.right > 0 &&
            rect.left <
                (window.innerWidth || document.documentElement.clientWidth) &&
            rect.top <
                (window.innerHeight || document.documentElement.clientHeight)
        );
    }

    private onScrollTable = throttle(function onScrollContract(self: any) {
        self.setState({
            scrollTop: self.tableRef.current.scrollTop,
        });
    }, 150);

    private renderExpandButton(contract: IDisplayContract): JSX.Element {
        const expanded = this.state.expandedContracts.includes(contract.id);

        return (
            <KeyboardArrowRight
                className={`action contract-list-icon expand-icon ${
                    expanded ? 'expanded' : ''
                }`}
                onClick={() => this.toggleRowExpansion(contract.id)}
            />
        );
    }

    private renderContractRow(
        contract: IDisplayContract,
        index: number,
        isLast: boolean
    ) {
        const { bulkActions, setReferringPage } = this.props;

        const progressBarDataSets = [
            {
                label: '',
                colour: PROGRESS_BAR_FILL_COLOUR,
                count: contract.totalClosedIssues,
                showCount: false,
            },
            {
                label: '',
                colour: PROGRESS_BAR_BKG_COLOUR,
                count: contract.totalIssues,
                showCount: true,
            },
        ];

        const { latestVersion } = contract;
        const isValid =
            !contract.reprocessRequired &&
            latestVersion &&
            latestVersion.assessmentIds.length;

        return (
            <tr
                className="contract-row"
                key={`contract-row-${index}-${contract.id}`}
                ref={isLast ? this.lastRowRef : null}
                data-testid="standard-contract-row"
            >
                {bulkActions ? (
                    <td className="hide-on-xs">
                        {this.renderRowCheckbox(contract)}
                    </td>
                ) : null}
                <td>{isValid ? this.renderExpandButton(contract) : null}</td>
                <td
                    className={`contract-name-cell ${
                        isValid ? 'clickable' : ''
                    }`}
                >
                    {isValid ? (
                        <Link
                            className="contract-name"
                            to={getContractPath(
                                contract.streamCode,
                                contract.id,
                                contract.latestVersion.id
                            )}
                            onClick={async () => {
                                await setReferringPage(
                                    'Negotiations Dashboard'
                                );
                            }}
                        >
                            {contract.contractName}
                        </Link>
                    ) : (
                        contract.contractName
                    )}
                </td>
                <td className="hide-on-small">
                    <ContractProgressBar
                        dataSets={progressBarDataSets}
                        fullWidth={true}
                        heightInPixels={12}
                    />
                </td>
                <td className="new-version-cell hide-on-xs">
                    <button
                        className="create-revision-btn"
                        onClick={() =>
                            this.setState({ selectedContract: contract })
                        }
                    >
                        <Add className="contract-list-icon" />
                        <span className="contract-list-btn-text">New</span>
                    </button>
                </td>
                <td className="revision-cell text-center hide-on-xs">
                    <span className="version-number">
                        {contract.versionIds.length}
                    </span>
                </td>
                <td className="date-cell hide-on-small">
                    {contract.latestVersion
                        ? this.formatDate(contract.latestVersion.updatedAt)
                        : ''}
                </td>
                <td className="stream-cell hide-on-xs">
                    {contract.streamCode}
                </td>
                <td className="hide-on-med">
                    {this.renderNegotiator(contract)}
                </td>
                <td className="counterparty-cell hide-on-xs">
                    {this.renderCounterParty(contract)}
                </td>
                <td className="text-center hide-on-med">
                    {this.renderNegotiationDays(contract)}
                </td>
                <td className="hide-on-med">
                    {this.renderReviewers(index, contract.reviewerIds)}
                </td>
                <td>{this.renderContractStatus(contract)}</td>
                <td className="text-right">
                    <ContractMenu
                        key={contract.id}
                        contract={contract}
                        onContractUpdated={(contractId, fields) =>
                            this.props.updateContractInList(contractId, fields)
                        }
                    />
                </td>
            </tr>
        );
    }

    private renderSetPartiesRow(
        contract: IDisplayContract,
        index: number,
        isLast: boolean = false
    ) {
        const link = {
            pathname: `/stream/${contract.streamCode}/contract/${contract.id}/parties`,
            state: { prevPath: this.props.location.pathname },
        };
        return (
            <tr
                className="missing-reference-names-row"
                key={`warning-contract-row-${index}-${contract.id}`}
                ref={isLast ? this.lastRowRef : null}
                data-id="parties-required-contract-row"
            >
                <td>
                    <Warning className="warning-icon" />
                </td>
                <td className="contract-name-cell">{contract.contractName}</td>
                <td className="warning-cell" colSpan={200}>
                    Please <Link to={link}>set the parties</Link> for this
                    negotiation.
                    <ContractMenu
                        contract={contract}
                        onContractUpdated={(contractId, fields) =>
                            this.props.updateContractInList(contractId, fields)
                        }
                    />
                </td>
            </tr>
        );
    }

    private renderContractStatus(contract: IDisplayContract) {
        let status = '';

        if (contract.reprocessRequired) {
            return (
                <span className="contract-status important">
                    Re-analysis required
                </span>
            );
        }

        if (!contract.versions.length) {
            return <span className="contract-status important">{status}</span>;
        }

        if (!contract.reviewerIds.length) {
            status = ContractStatus.UNASSIGNED;
        } else if (contract.latestVersion) {
            if (contract.isSigned) {
                status = ContractStatus.SIGNED;
            } else if (contract.isTemplate) {
                status = ContractStatus.TEMPLATE;
            } else {
                status = ContractStatus.NEGOTIATING;
            }
        }

        const classes = `contract-status ${
            status === ContractStatus.UNASSIGNED ? 'important' : ''
        }`;

        return <span className={classes}>{status}</span>;
    }

    // This function is intentionally empty as it is required for analytics
    private onClickSetParties() {}

    private renderCounterParty(contract: IDisplayContract) {
        const { location } = this.props;
        const { latestVersion } = contract;
        const counterParty = this.formatCounterParty(latestVersion);

        if (counterParty) {
            return counterParty;
        }

        if (contract.reprocessRequired) {
            return <span className="edit-parties-link">Specify parties</span>;
        }

        if (latestVersion) {
            return (
                <Link
                    className="edit-parties-link"
                    to={{
                        pathname: `/stream/${contract.streamCode}/contract/${contract.id}/parties`,
                        state: { prevPath: location.pathname },
                    }}
                    onClick={this.onClickSetParties}
                >
                    Formal Name Not Set
                </Link>
            );
        }

        return '';
    }

    private renderNegotiationDays(contract: IDisplayContract) {
        if (!contract.versions.length) {
            return '';
        }

        const version = contract.versions[0];

        return dayjs().diff(dayjs(version.updatedAt), 'days');
    }

    private renderNegotiator(contract: IDisplayContract) {
        const { users } = this.props;

        const user = users.find((user) => user.id === contract.negotiatorId);

        if (!user) {
            return;
        }

        return (
            <UserIcon
                label={`${user.firstName} ${user.lastName}`}
                tooltip={
                    <UserIcon
                        label={`${user.firstName} ${user.lastName}`}
                        showLabel
                    />
                }
            />
        );
    }

    private renderFailedRow(
        contract: IDisplayContract,
        index: number,
        isLast: boolean = false
    ) {
        const { bulkActions, timedOutProcessingContractIds } = this.props;
        const description = contract.latestVersion
            ? contract.latestVersion.uploadStatusDescription
            : '';
        const allowDeletion = this.contractIsDeletable(
            contract,
            timedOutProcessingContractIds
        );

        return (
            <tr
                className="contract-row contract-list-table-failed-row"
                key={`contract-row-${index}-${contract.id}`}
                ref={isLast ? this.lastRowRef : null}
                data-id="failed-contract-row"
            >
                {bulkActions ? <td className="hide-on-xs" /> : null}
                <td>
                    <PriorityHigh className="failed-contract-icon" />
                </td>
                <td className="contract-name-cell">
                    <span className="contract-name">
                        {contract.contractName}
                    </span>
                </td>
                <td className="hide-on-small">Analysis failed...</td>
                <td colSpan={8}>
                    <div className="upload-progress-bar-wrapper">
                        <UploadProgressBar state={description} />
                    </div>
                </td>
                <td colSpan={2} className={allowDeletion ? 'hide-on-med' : ''}>
                    <Tooltip
                        className="delete-button"
                        title={
                            contract.versions.length === 1
                                ? 'Delete Negotiation'
                                : 'Delete Version'
                        }
                        placement="bottom"
                    >
                        <Delete
                            className="contract-list-round-icon delete-icon"
                            onClick={() => this.deleteContract(contract)}
                        />
                    </Tooltip>
                    <Tooltip
                        className="reanalyze-button"
                        title="Reanalyze Contract"
                        placement="bottom"
                    >
                        <Refresh
                            className="contract-list-round-icon reprocess-icon"
                            onClick={() => reprocessContract(contract)}
                        />
                    </Tooltip>
                </td>
            </tr>
        );
    }

    private renderProcessingRow(
        contract: IDisplayContract,
        index: number,
        isLast: boolean = false
    ) {
        const { bulkActions } = this.props;
        const description =
            contract.latestVersion?.uploadStatusDescription ?? '';

        return (
            <tr
                className="contract-row contract-list-table-processing-row"
                key={`contract-row-${index}-${contract.id}`}
                ref={isLast ? this.lastRowRef : null}
                data-id="processing-contract-row"
            >
                {bulkActions ? <td className="hide-on-xs" /> : null}
                <td />
                <td className="contract-name-cell">
                    <span className="contract-name">
                        {contract.contractName}
                    </span>
                </td>
                <td className="hide-on-small">Analyzing contract...</td>
                <td colSpan={10}>
                    <div className="upload-progress-bar-wrapper">
                        <UploadProgressBar state={description} />
                    </div>
                </td>
            </tr>
        );
    }

    private renderReprocessingRow(
        contract: IDisplayContract,
        index: number,
        isLast: boolean = false
    ) {
        const { bulkActions } = this.props;

        return (
            <tr
                className="contract-row contract-list-table-processing-row"
                key={`contract-row-${index}-${contract.id}`}
                ref={isLast ? this.lastRowRef : null}
                data-id="reprocessing-contract-row"
            >
                {bulkActions ? <td className="hide-on-xs" /> : null}
                <td />
                <td className="contract-name-cell">
                    <span className="contract-name">
                        {contract.contractName}
                    </span>
                </td>
                <td className="hide-on-small">Re-analyzing contract...</td>
                <td colSpan={10}>
                    <div className="upload-progress-bar-wrapper">
                        <LinearProgress className="indeterminate-progress-bar" />
                    </div>
                </td>
            </tr>
        );
    }

    private renderReviewers(rowIndex: number, reviewerIds: string[] = []) {
        const { users } = this.props;
        const reviewers = users.filter((user) => reviewerIds.includes(user.id));

        const showLimit = 2;

        if (!reviewers.length) {
            return null;
        }

        return (
            <div className="contract-list-table-reviewers">
                {reviewers.length > showLimit && (
                    <UserIcon
                        muted
                        label={`+${reviewers.slice(showLimit).length}`}
                        showLabel={false}
                        tooltip={
                            <div className="hidden-reviewers-icon-tooltip">
                                {reviewers.slice(showLimit).map((user) => (
                                    <UserIcon
                                        key={`${rowIndex}-additional-reviewer-${user.id}`}
                                        label={`${user.firstName} ${user.lastName}`}
                                        showLabel
                                    />
                                ))}
                            </div>
                        }
                        noInitials
                        customIconColor={'#5099FD'}
                    />
                )}
                {reviewers
                    .slice(0, showLimit)
                    .reverse()
                    .map((user) => (
                        <UserIcon
                            key={`${rowIndex}-reviewer-${user.id}`}
                            label={`${user.firstName} ${user.lastName}`}
                            showLabel={false}
                            tooltip={
                                <UserIcon
                                    label={`${user.firstName} ${user.lastName}`}
                                    showLabel
                                />
                            }
                        />
                    ))}
            </div>
        );
    }

    private renderRow(
        row: IDisplayContract | IContractVersion,
        index: number,
        array: (IDisplayContract | IContractVersion)[]
    ) {
        const {
            processingContracts,
            reprocessingContracts,
            timedOutProcessingContractIds,
        } = this.props;

        const isLast = index === array.length - 1;
        const uploadStatus =
            row.type === TYPE_CONTRACT && row?.latestVersion?.uploadStatus;
        const processingContract =
            find(processingContracts, { contractId: row.id }) ||
            uploadStatus === UploadStatus.PROCESSING;
        const failedContract = uploadStatus === UploadStatus.FAILED;
        const reprocessingContract = find(reprocessingContracts, {
            contractId: row.id,
        });
        const partiesOverridden =
            row.type === TYPE_CONTRACT && row.latestVersion?.partiesOverridden;
        const isMissingParties =
            row.type === TYPE_CONTRACT &&
            this.isMissingParties(row.latestVersion?.parties);
        const requirePartyConfirmation =
            row.type === TYPE_CONTRACT &&
            row.latestVersion?.requirePartyConfirmation;

        if (row.type === TYPE_VERSION) {
            return this.renderVersionRow(
                row as IContractVersion,
                index,
                isLast
            );
        }

        if (reprocessingContract) {
            return this.renderReprocessingRow(
                row as IDisplayContract,
                index,
                isLast
            );
        }

        if (timedOutProcessingContractIds.includes(row.id) || failedContract) {
            return this.renderFailedRow(row as IDisplayContract, index, isLast);
        }

        if (processingContract) {
            return this.renderProcessingRow(
                row as IDisplayContract,
                index,
                isLast
            );
        }

        if (
            (isMissingParties || requirePartyConfirmation) &&
            !partiesOverridden
        ) {
            return this.renderSetPartiesRow(
                row as IDisplayContract,
                index,
                isLast
            );
        }

        return this.renderContractRow(row as IDisplayContract, index, isLast);
    }

    private openDeleteContractModal(contract: IDisplayContract) {
        const title = 'Delete negotiation';

        this.props.showDialog(
            title,
            true,
            <DeleteContractModal contract={contract} />,
            [],
            null
        );
    }

    private openDeleteVersionModal(
        contract: IDisplayContract,
        version: IContractVersion
    ) {
        this.props.showDialog(
            'Delete Version',
            true,
            <DeleteVersionModal contract={contract} version={version} />,
            [],
            null
        );
    }

    private renderVersionRow(
        version: IContractVersion,
        index: number,
        isLast: boolean
    ) {
        const {
            contracts,
            bulkActions,
            processingContracts,
            reprocessingContracts,
        } = this.props;
        const { expandedContracts } = this.state;

        const contract = find(contracts, { id: version.contractId });

        if (!contract) {
            return null;
        }

        const expanded =
            expandedContracts.includes(version.contractId) &&
            !find(processingContracts, { contractId: version.contractId }) &&
            !find(reprocessingContracts, { contractId: version.contractId });

        return (
            <tr
                className={`version-row ${expanded ? 'expanded' : ''}`}
                key={`version-row-${index}-${version.id}`}
                ref={isLast ? this.lastRowRef : null}
                data-id="contract-version-row"
            >
                {bulkActions ? <td className="hide-on-xs" /> : null}
                <td className="revision-bullet-cell text-center">
                    <span className="revision-bullet" />
                </td>
                <td
                    className={`contract-name-cell ${
                        version.assessmentIds.length ? 'clickable' : ''
                    }`}
                >
                    {version.assessmentIds.length ? (
                        <Link
                            className="contract-name"
                            to={getContractPath(
                                contract.streamCode,
                                contract.id,
                                version.id
                            )}
                        >
                            {version.fileName}
                        </Link>
                    ) : (
                        version.fileName
                    )}
                </td>
                <td className="hide-on-small" />
                <td className="hide-on-xs" />
                <td className="text-center hide-on-xs">
                    <span className="version-number">
                        {version.versionNumber}
                    </span>
                </td>
                <td className="date-cell hide-on-small">
                    {this.formatDate(version.updatedAt)}
                </td>
                <td className="stream-cell hide-on-xs" />
                <td className="hide-on-med" />
                <td className="counterparty-cell hide-on-xs" />
                <td className="text-center hide-on-med" />
                <td className="hide-on-med" />
                <td />
                <td className="text-right">
                    <ContractMenu
                        contract={contract}
                        deleteVersionOnly //not the best solution making this a prop but will do for now
                        onContractUpdated={(contractId, fields) =>
                            this.props.updateContractInList(contractId, fields)
                        }
                    />
                </td>
            </tr>
        );
    }

    private renderRowCheckbox(contract: IDisplayContract) {
        const { selectedContractIds, setSelectedContractIds } = this.props;

        const selected = selectedContractIds.includes(contract.id);

        return (
            <Checkbox
                onChange={() => {
                    if (selected) {
                        setSelectedContractIds(
                            selectedContractIds.filter(
                                (contractId) => contractId !== contract.id
                            )
                        );
                    } else {
                        if (selectedContractIds.length >= BULK_ACTION_LIMIT) {
                            toast.info(
                                `The limit for bulk actions is ${BULK_ACTION_LIMIT} contracts`
                            );
                            return;
                        }
                        setSelectedContractIds([
                            ...selectedContractIds,
                            contract.id,
                        ]);
                    }
                }}
                checked={selected}
            />
        );
    }

    private renderSelectAllCheckbox() {
        const {
            contracts,
            processingContracts,
            reprocessingContracts,
            selectAll,
            setSelectAllContracts,
            timedOutProcessingContractIds,
        } = this.props;

        const selectableContractIds: string[] = [];

        for (const contract of contracts) {
            if (
                !find(processingContracts, { contractId: contract.id }) &&
                !find(reprocessingContracts, { contractId: contract.id }) &&
                !find(timedOutProcessingContractIds, {
                    contractId: contract.id,
                }) &&
                contract?.latestVersion?.uploadStatus === COMPLETED
            ) {
                selectableContractIds.push(contract.id);
            }
        }

        return (
            <Checkbox
                onChange={() => {
                    if (
                        !selectAll &&
                        selectableContractIds.length > BULK_ACTION_LIMIT
                    ) {
                        toast.info(
                            `The limit for bulk actions is ${BULK_ACTION_LIMIT} contracts`
                        );
                    }
                    setSelectAllContracts(!selectAll, selectableContractIds);
                }}
                checked={selectAll}
            />
        );
    }

    private renderTableHeaders() {
        const { bulkActions } = this.props;

        return (
            <thead>
                <tr>
                    {bulkActions ? (
                        <th className="contract-list-table-header-checkbox hide-on-xs">
                            <div className="header-label">
                                {this.renderSelectAllCheckbox()}
                            </div>
                        </th>
                    ) : null}
                    <th className="expand-row-header">
                        <div className="header-label" />
                    </th>
                    <th className="contract-name-header">
                        <div className="header-label">Negotiation</div>
                    </th>
                    <th className="progress-header hide-on-small">
                        <div className="header-label">
                            <span>Progress</span>
                            <span className="contract-list-table-subheader">
                                (Issues resolved)
                            </span>
                        </div>
                    </th>
                    <th className="new-version-header text-right hide-on-xs">
                        <div className="header-label" />
                    </th>
                    <th className="revision-header hide-on-xs">
                        <div className="header-label">Version</div>
                    </th>
                    <th className="date-header hide-on-small">
                        <div className="header-label">Last Upload</div>
                    </th>
                    <th className="stream-header hide-on-xs">
                        <div className="header-label">Deal Type</div>
                    </th>
                    <th className="hide-on-med">
                        <div className="header-label">Owner</div>
                    </th>
                    <th className="counterparty-header hide-on-xs">
                        <div className="header-label">Counterparty</div>
                    </th>
                    <th className="text-center hide-on-med">
                        <div className="header-label">
                            <span>Duration</span>
                            <span className="contract-list-table-subheader">
                                (days)
                            </span>
                        </div>
                    </th>
                    <th className="reviewers-header hide-on-med">
                        <div className="header-label">Reviewer(s)</div>
                    </th>
                    <th className="status-header">
                        <div className="header-label">Status</div>
                    </th>
                    <th className="dropdown-header">
                        <div className="header-label" />
                    </th>
                </tr>
            </thead>
        );
    }

    private toggleRowExpansion(contractId: string) {
        const { expandedContracts } = this.state;

        if (expandedContracts.includes(contractId)) {
            this.setState({
                expandedContracts: expandedContracts.filter(
                    (expandedContractId) => expandedContractId !== contractId
                ),
            });
        } else {
            this.setState({
                expandedContracts: [...expandedContracts, contractId],
            });
        }
    }

    private versionIsMissingAssessments(
        latestVersion: IContractVersion
    ): boolean {
        return !!(
            latestVersion &&
            latestVersion.uploadStatus === COMPLETED &&
            !latestVersion.assessmentIds.length
        );
    }

    private versionFailedProcessing(latestVersion: IContractVersion): boolean {
        return !!(latestVersion && latestVersion.uploadStatus === FAILED);
    }

    render() {
        const { bulkActions, contracts, showLoader } = this.props;

        const rows: (IDisplayContract | IContractVersion)[] = [];

        for (const contract of contracts) {
            rows.push(contract);

            for (const version of [...contract.versions].reverse()) {
                rows.push(version);
            }
        }

        const loaderColspan = bulkActions ? 14 : 13;

        return (
            <>
                <div
                    className="contract-list-table-container"
                    ref={this.tableRef}
                    onScroll={() => this.onScrollTable(this)}
                >
                    <table
                        className={`contract-list-table ${
                            bulkActions ? 'bulk-actions-on' : ''
                        }`}
                    >
                        {this.renderTableHeaders()}
                        <tbody>
                            {rows.length ? (
                                rows.map(this.renderRow)
                            ) : (
                                <tr ref={this.lastRowRef} />
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td
                                    className="contract-list-loader"
                                    colSpan={loaderColspan}
                                >
                                    {showLoader ? (
                                        <div className="loader-wrapper">
                                            <span className="loader" />
                                        </div>
                                    ) : null}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <ContractUploadWizardModal
                    open={Boolean(this.state.selectedContract)}
                    onClose={() =>
                        this.setState({ selectedContract: undefined })
                    }
                    existingContractId={this.state.selectedContract?.id}
                    existingStreamId={this.state.selectedContract?.streamId}
                />
            </>
        );
    }
}

export default ContractListTable;
