import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { IRootState } from '../../redux/reducers-types';
import {
    setHighlightedParagraphs,
    setSelectedParagraphAction,
    setSelectedPropertyCodeAction,
    updateSummaryPropertyAction,
} from '../Contract/Contract-actions';
import { setBannerSettingsAction } from '../HeatmapPane/HeatmapPane-actions';
import { IBannerSettings } from '../HeatmapPane/HeatmapPane-types';
import { IContractParagraph } from '../Paragraph/Paragraph-types';
import { ISummaryProperty } from '../SummaryPane/SummaryPane-types';
import PropertyLinkWidget from './PropertyLinkWidget';
import {
    IPropertyLinkWidgetDispatchProps,
    IPropertyLinkWidgetStateProps,
} from './PropertyLinkWidget-types';

function mapStateToProps(state: IRootState): IPropertyLinkWidgetStateProps {
    return {
        highlightedParagraphs: state.contract.highlightedParagraphs,
        bannerSettings: state.heatmapPane.bannerSettings!, // Fixme: null checks
        selectedPropertyCode: state.contract.selectedPropertyCode,
        summaryProperties: state.contract.summaryProperties,
        paragraphs: state.contract.paragraphs,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IPropertyLinkWidgetDispatchProps {
    return {
        setHighlightedParagraphs: (paragraphs: IContractParagraph[]) =>
            dispatch(setHighlightedParagraphs(paragraphs)),
        setBannerSettings: (settings: IBannerSettings) =>
            dispatch(setBannerSettingsAction(settings)),
        setSelectedPropertyCode: (code: string) =>
            dispatch(setSelectedPropertyCodeAction(code)),
        setSelectedParagraph: (paragraph: IContractParagraph) =>
            dispatch(setSelectedParagraphAction(paragraph)),
        updateSummaryProperty: (property: ISummaryProperty) =>
            dispatch(updateSummaryPropertyAction(property)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyLinkWidget);
