import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IRootState } from '../../redux/reducers-types';

import Header from './Header';
import { IHeaderStateProps } from './Header-types';

function mapStateToProps(state: IRootState): IHeaderStateProps {
    return {
        featureToggles: state.featureToggles.toggles,
    };
}

export default connect(mapStateToProps)(withRouter(Header));
