import { IconButton } from '@thought-river/ui-components';
import { SelectedDataElementProps } from './SelectedDateElement.types';
import { Cancel, Visibility, VisibilityOff } from '@mui/icons-material';
import styles from '../SelectedData.module.scss';
import { ColumnIcon } from '@modules/reports/common/components/ColumnIcon';

const SelectedDataElement = ({
    allowHidingColumn,
    data,
    onDataVisibilityChange,
    onDataRemove,
}: SelectedDataElementProps) => (
    <div className={styles.itemContent}>
        <div className={styles.column}>
            <ColumnIcon column={data.id} />
            {data.columnName}
            <div className={styles.buttonsContainer}>
                {data.isHideable && (
                    <>
                        {allowHidingColumn && (
                            <IconButton
                                className={styles.visibilityButton}
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDataVisibilityChange(
                                        data.id,
                                        !data.isVisible
                                    );
                                }}
                            >
                                {data.isVisible ? (
                                    <Visibility
                                        className={styles.visibleIcon}
                                    />
                                ) : (
                                    <VisibilityOff
                                        className={styles.invisibleIcon}
                                    />
                                )}
                            </IconButton>
                        )}
                        <IconButton
                            className={styles.removeButton}
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                onDataRemove(data);
                            }}
                        >
                            <Cancel className={styles.removeIcon} />
                        </IconButton>
                    </>
                )}
            </div>
        </div>
        {data.filters?.length > 0 && (
            <div className={styles.filters}>
                {data.filters.length} filter{data.filters.length > 1 && 's'}{' '}
                selected
            </div>
        )}
    </div>
);

export default SelectedDataElement;
