import { analyticsService } from '../../services/Analytics/Analytics';
import { EVENT_ACTION_BULK_SIGN } from '../../services/Analytics/Analytics-types';

export function analytics() {
    return (target: any) => {
        const onSubmit = target.prototype.onSubmit;

        target.prototype.onSubmit = function () {
            analyticsService.recordEvent(EVENT_ACTION_BULK_SIGN);

            onSubmit.apply(this, arguments);
        };
    };
}
