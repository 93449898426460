import {
    GridActionsCellItemProps,
    GridColDef,
    GridRowParams,
    GridValueFormatterParams,
    GridValueOptionsParams,
} from '@mui/x-data-grid-premium';
import { ReactElement, useMemo } from 'react';
import { Cell } from '../../cells';
import {
    columnLabels,
    DEFAULT_MIN_COLUMN_WIDTH,
    minColumnWidths,
} from '../../consts';
import dayjs from 'dayjs';
import { DEFAULT_DATE_FORMAT } from '@thought-river/ui-components';
import { ContractTreeColumn } from '@thought-river/negotiations-common/dist/api/contractManagement';
import { getSortComparator } from './sortComparators';
import { valueGetter } from './valueGetter';
import { valueOptions } from './valueOptions';
import { getOrderedColumns } from '@modules/reports/common';

const NON_FILTERABLE_COLUMNS = ['progress', 'duration'];
const NON_HIDEABLE_COLUMNS = ['status'];
const NON_SORTABLE_COLUMNS = ['status'];
const NON_PINNABLE_COLUMNS = ['status'];

export const useGetColumns = (
    columns: ContractTreeColumn[],
    orderedFields: string[],
    actionsColumnActions: (
        params: GridRowParams
    ) => ReactElement<GridActionsCellItemProps>[]
): GridColDef[] => {
    const memoizedColumns = useMemo(() => {
        const orderedColumns = getOrderedColumns(columns, orderedFields);

        const columnDefs = orderedColumns.map<GridColDef>((column) => ({
            ...column,
            headerName: columnLabels[column.field] ?? column.field,
            minWidth: minColumnWidths[column.field] ?? DEFAULT_MIN_COLUMN_WIDTH,
            flex: 1,
            renderCell: Cell,
            valueGetter,
            valueFormatter: (params: GridValueFormatterParams) => {
                if (column.type === 'date' || column.type === 'dateTime') {
                    return dayjs(params.value).format(DEFAULT_DATE_FORMAT);
                }

                return params.value;
            },
            groupable: false,
            filterable: !NON_FILTERABLE_COLUMNS.includes(column.field),
            headerAlign: 'left',
            hideable: !NON_HIDEABLE_COLUMNS.includes(column.field),
            sortComparator: getSortComparator(column),
            sortable: !NON_SORTABLE_COLUMNS.includes(column.field),
            pinnable: !NON_PINNABLE_COLUMNS.includes(column.field),
            aggregable: false,
            valueOptions: (params: GridValueOptionsParams) =>
                valueOptions(params) ?? column.valueOptions,
            type: (() => {
                if (column.field === 'status') {
                    return 'multiSelect';
                }
                return column.type;
            })(),
        }));

        //actions column
        columnDefs.push({
            field: 'actions',
            headerName: 'Contract actions',
            renderHeader: () => '',
            type: 'actions',
            minWidth: 50,
            maxWidth: 50,
            getActions: actionsColumnActions,
            hideable: false,
        });

        return columnDefs;
    }, [columns, orderedFields, actionsColumnActions]);

    return memoizedColumns;
};
