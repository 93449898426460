import { IDocuments } from 'types/thoughtriver';
import Api from '../services/api';
import { Method, requestOptions } from '../services/api-types';
import {
    IDocumentResource,
    IDocumentResourceStatic,
} from './DocumentResource-types';

const DocumentResource: IDocumentResourceStatic = class DocumentResource
    implements IDocumentResource
{
    static async getDocuments(
        contractId: string,
        versionId: string,
        stream: string
    ): Promise<IDocuments> {
        const url = `/contracts/${contractId}/versions/${versionId}/documents`;

        const options = {
            ...requestOptions,
            streamCode: stream,
        };

        return (await Api.request(Method.GET, url, options))!.json();
    }
};

export default DocumentResource;
