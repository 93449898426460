import { ContractPane } from '../Contract/Contract-types';
import { ISetPaneWidthAction, SET_PANE_WIDTH } from './PaneDivider-types';

export function setPaneWidthAction(
    pane: ContractPane,
    width: number
): ISetPaneWidthAction {
    return {
        type: SET_PANE_WIDTH,
        pane,
        width,
    };
}
