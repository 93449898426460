import { Dispatch } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { IRootState } from '../../../../../redux/reducers-types';
import { setIssueFormAction } from '../../../IssuePane-actions';
import { IIssueForm } from '../../../IssuePane-types';
import IssuePaneNotes from './IssuePaneNotes';
import {
    IIssuePaneNotesDispatchProps,
    IIssuePaneNotesStateProps,
} from './IssuePaneNotes-types';

function mapStateToProps(state: IRootState): IIssuePaneNotesStateProps {
    return {
        form: state.issuePane.form,
        issue: state.contract.selectedIssue,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IIssuePaneNotesDispatchProps {
    return {
        setIssueForm: (form: IIssueForm) => dispatch(setIssueFormAction(form)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IssuePaneNotes);
