import { IIssuesReportClauseProps } from './IssuesReportClause-types';
import { Link } from '@mui/material';

const IssuesReportClause = (props: IIssuesReportClauseProps) => {
    const { clause, onSelectClause, paragraphUuids, title } = props;

    return (
        <Link
            className="clause-number"
            key={clause.index}
            onClick={() => onSelectClause(paragraphUuids, clause, title)}
            underline="always"
        >
            {clause.clauseNumber}
        </Link>
    );
};

export default IssuesReportClause;
