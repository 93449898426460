import { useLexibleContext } from '../context';

//Given a list of columns group them into contract metadata, lexible properties
export const useGroupSelectedColumns = (
    columns: string[]
): { properties: string[]; contractMetadata: string[] } => {
    const { propertiesDict } = useLexibleContext();
    const lexibleCodes = Object.keys(propertiesDict || {});
    const properties = columns.filter((c) => lexibleCodes.includes(c));
    const contractMetadata = columns.filter(
        (c) => !lexibleCodes.includes(c)
    ) as string[];
    return {
        properties,
        contractMetadata,
    };
};
