import { ContractIssue } from '@thought-river/negotiations-common';
import { IIssueForm } from '../IssuePane-types';
import { IContractParagraph } from '../../Paragraph/Paragraph-types';

export enum IssuePaneTab {
    DESCRIPTION = 0,
    INTELLIGENCE = 1,
    SUGGESTIONS = 2,
}

export interface IIssuePaneTabsState {
    selectedTabIndex: IssuePaneTab;
}

export interface IIssuePaneTabsStateProps {
    form: IIssueForm;
    issue: ContractIssue | null;
    paragraphs: IContractParagraph[];
}

export interface IIssuePaneTabsDispatchProps {
    setIssueForm: (form: IIssueForm) => void;
}

export interface IIssuePaneTabsProps
    extends IIssuePaneTabsStateProps,
        IIssuePaneTabsDispatchProps {
    debouncedSaveIssue: () => void;
    formInitialised: boolean;
    lockedVersion: boolean;
    onClickSelectedClause: (paragraphId: string) => void;
    propertyDetailsPaneOpen: boolean;
    saveIssue: () => void;
    submitAttempted: boolean;
    toggleShowPropertyDetails: () => void;
}
