import { Component } from 'react';

import ParagraphSuggestions from './ParagraphSuggestions/ParagraphSuggestions-container';
import { IIssuePaneSuggestionTabProps } from './IssuePaneSuggestionTab-types';
import './IssuePaneSuggestionTab.scss';
import { getRelevantClauses } from './helpers';

export default class IssuePaneSuggestionTab extends Component<IIssuePaneSuggestionTabProps> {
    render() {
        const {
            form: { clauseNumbers },
            issue,
            paragraphs,
        } = this.props;

        const relevantClauseNumbers = getRelevantClauses(
            clauseNumbers,
            issue,
            paragraphs
        );

        return <ParagraphSuggestions clauseNumbers={relevantClauseNumbers} />;
    }
}
