import { QueryClient } from '@tanstack/react-query';
import { SummaryPanelTemplateOut } from '@thought-river/negotiations-common/dist/api/playbookManager';

//Update a template in a list of all templates
export const updateTanStackQueryTemplatesData = (
    queryClient: QueryClient,
    template: SummaryPanelTemplateOut
) => {
    queryClient.setQueryData(
        ['summary-panel-templates'],
        (oldData: SummaryPanelTemplateOut[] | undefined) => [
            ...(oldData?.map((data) =>
                data.id_str === template.id_str ? template : data
            ) ?? []),
        ]
    );
};
