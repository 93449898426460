import { IReviewStream } from '../../resources/AuthResource-types';
import {
    ISetApiUrlAction,
    ISetApiTokenAction,
    SET_API_URL,
    SET_API_TOKEN,
    SET_TOKEN_EXPIRY,
    ISetTokenExpiryAction,
    ISetAuthDetailsAction,
    SET_AUTH_DETAILS,
    ISetAuthMetadataAction,
    SET_AUTH_METADATA,
    SET_SELECTED_STREAM,
    ISetSelectedStreamAction,
    CLEAR_LOGIN_DETAILS,
    IClearLoginDetailsAction,
    IAccountUser,
    SET_USERS,
    ISetUsersAction,
    SET_LOGOUT,
    ISetLogoutAction,
    SET_BOOKMARK,
    ISetBookmarkAction,
    SET_LOGIN_SUCCESS,
    ISetLoginSuccessAction,
    SET_LOGGING_OUT,
    ISetLoggingOutAction,
    UserTenant,
    ISetLegacyApiTokenAction,
    SET_LEGACY_API_TOKEN,
} from './Auth-types';

export function setApiUrlAction(apiUrl: string): ISetApiUrlAction {
    return {
        type: SET_API_URL,
        apiUrl,
    };
}

export function setApiTokenAction(apiToken: string): ISetApiTokenAction {
    return {
        type: SET_API_TOKEN,
        apiToken,
    };
}

export function setLegacyApiTokenAction(
    apiToken: string
): ISetLegacyApiTokenAction {
    return {
        type: SET_LEGACY_API_TOKEN,
        apiToken,
    };
}

export function setTokenExpiryAction(
    tokenExpiry: string
): ISetTokenExpiryAction {
    return {
        type: SET_TOKEN_EXPIRY,
        tokenExpiry,
    };
}

export function setSelectedStreamAction(
    selectedStream: IReviewStream
): ISetSelectedStreamAction {
    return {
        type: SET_SELECTED_STREAM,
        selectedStream,
    };
}

export function clearLoginDetailsAction(): IClearLoginDetailsAction {
    return {
        type: CLEAR_LOGIN_DETAILS,
    };
}

export function setBookmarkAction(bookmark: string): ISetBookmarkAction {
    return {
        type: SET_BOOKMARK,
        bookmark,
    };
}

export function setAuthDetailsAction(
    authUrl: string,
    clientId: string,
    audience: string,
    redirectUrl: string
): ISetAuthDetailsAction {
    return {
        type: SET_AUTH_DETAILS,
        authUrl,
        clientId,
        audience,
        redirectUrl,
    };
}

export function setAuthMetadataAction(
    roles: string[],
    streams: IReviewStream[],
    userId: string,
    accountCode: string,
    company: string,
    tenants?: UserTenant[]
): ISetAuthMetadataAction {
    return {
        type: SET_AUTH_METADATA,
        roles,
        streams,
        userId,
        accountCode,
        company,
        tenants,
    };
}

export function setUsersAction(users: IAccountUser[]): ISetUsersAction {
    return {
        type: SET_USERS,
        users,
    };
}

export function setLoginSuccessAction(
    loginSuccess: boolean
): ISetLoginSuccessAction {
    return {
        type: SET_LOGIN_SUCCESS,
        loginSuccess,
    };
}

export function setLoggingOutAction(loggingOut: boolean): ISetLoggingOutAction {
    return {
        type: SET_LOGGING_OUT,
        loggingOut,
    };
}

export function setLogoutAction(logout: boolean): ISetLogoutAction {
    return {
        type: SET_LOGOUT,
        logout,
    };
}
