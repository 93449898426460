import {
    IPaneDividerActionTypes,
    IPaneDividerReducer,
    PANE_WIDTH_25,
    PANE_WIDTH_75,
    PANE_WIDTH_ONE_THIRD,
    PANE_WIDTH_TWO_THIRDS,
    SET_PANE_WIDTH,
} from './PaneDivider-types';

const initialState: IPaneDividerReducer = {
    analyticsReportWidth: PANE_WIDTH_75,
    analyticsSidebarWidth: PANE_WIDTH_25,
    heatmapWidth: PANE_WIDTH_TWO_THIRDS,
    insightWidth: PANE_WIDTH_25,
    issueListPaneWidth: PANE_WIDTH_25,
    overviewWidth: PANE_WIDTH_25,
    partyContractWidth: PANE_WIDTH_75,
    partySidebarWidth: PANE_WIDTH_25,
    playbookWidth: PANE_WIDTH_25,
    sidebarWidth: PANE_WIDTH_ONE_THIRD,
};

const PaneDividerReducer = (
    state = initialState,
    action: IPaneDividerActionTypes
): IPaneDividerReducer => {
    switch (action.type) {
        case SET_PANE_WIDTH:
            const { pane, width } = action;
            return {
                ...state,
                [`${pane}Width`]: width,
            };
        default:
            return state;
    }
};

export default PaneDividerReducer;
