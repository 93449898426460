import { Component, createRef } from 'react';

import {
    CONTRACT_VERTICAL_PADDING_IN_PIXELS,
    CONTRACT_WRAPPER_BACKGROUND_COLOUR,
    IHeatmapPaneProps,
    IHeatmapPaneState,
    TRANSPARENT_BACKGROUND,
} from './HeatmapPane-types';
import Paragraph from '../Paragraph/Paragraph-container';
import { HighlightIndexType } from '../Contract/Contract-types';
import { IContractParagraph } from '../Paragraph/Paragraph-types';
import { throttle } from 'lodash';
import HeatmapPaneBanner from './HeatmapPaneBanner/HeatmapPaneBanner';
import { ErrorBoundary } from '@modules/common/components/ErrorBoundary';

class HeatmapPane extends Component<IHeatmapPaneProps, IHeatmapPaneState> {
    private contractRef: React.RefObject<HTMLDivElement>;

    constructor(props: IHeatmapPaneProps) {
        super(props);

        this.state = {
            bannerParagraphIds: [],
            width: 0,
            highlightBeginParagraphIndex: null,
        };

        this.contractRef = createRef();

        this.onScrollContract = this.onScrollContract.bind(this);
        this.onWindowResize = this.onWindowResize.bind(this);
        this.autoSelectParagraph = this.autoSelectParagraph.bind(this);
    }

    componentDidMount() {
        const {
            match: { params },
        } = this.props;

        window.addEventListener('resize', this.onWindowResize);
        this.onWindowResize();

        if (params.paragraphUuid) {
            this.autoSelectParagraph(params.paragraphUuid);
        }
    }

    componentWillUnmount() {
        this.props.setContractScrollHeightInPixels(0);
        this.props.setContractVisibleHeightInPixels(0);
        this.props.setParagraphHeightsInPixels([]);
        window.removeEventListener('resize', this.onWindowResize);
    }

    componentDidUpdate(prevProps: IHeatmapPaneProps) {
        const {
            bannerSettings,
            match: { params },
            matchIndex,
            matchesCount,
            searchTerm,
            selectedParagraph,
            selectedContractIntelligence,
            sidebarWidth,
        } = this.props;

        const prevParagraphUuid = prevProps.match.params.paragraphUuid;

        if (
            params.paragraphUuid &&
            params.paragraphUuid !== prevParagraphUuid
        ) {
            this.autoSelectParagraph(params.paragraphUuid);
        }

        if (
            selectedParagraph &&
            prevProps.selectedParagraph?.id !== selectedParagraph.id
        ) {
            this.scrollToParagraph(selectedParagraph.id);
        }

        if (
            matchesCount &&
            searchTerm.trim() &&
            (prevProps.searchTerm !== searchTerm ||
                (prevProps.searchTerm === searchTerm &&
                    prevProps.matchIndex !== matchIndex))
        ) {
            this.scrollToSearchResult(matchIndex);
        }

        if (sidebarWidth !== prevProps.sidebarWidth) {
            this.onWindowResize();
        }

        if (
            selectedContractIntelligence.highlightType !==
            prevProps.selectedContractIntelligence?.highlightType
        ) {
            if (
                !selectedContractIntelligence.highlightType ||
                !bannerSettings
            ) {
                this.clearParagraphSelection();
                return;
            }
        }
    }

    private autoSelectParagraph(paragraphId: string) {
        const { paragraphs, setHighlightIndex, setSelectedParagraph } =
            this.props;

        const selectedParagraph = paragraphs.find(
            (paragraph) => paragraph.id === paragraphId
        );

        if (selectedParagraph) {
            setHighlightIndex(
                selectedParagraph.index,
                HighlightIndexType.START
            );
            setHighlightIndex(selectedParagraph.index, HighlightIndexType.END);
            setSelectedParagraph(selectedParagraph);
        } else {
            setHighlightIndex(-1, HighlightIndexType.START);
            setHighlightIndex(-1, HighlightIndexType.END);
            setSelectedParagraph(null);
        }
    }

    private clearParagraphSelection() {
        const { setHighlightIndex, setSelectedParagraph } = this.props;

        setHighlightIndex(-1, HighlightIndexType.START);
        setHighlightIndex(-1, HighlightIndexType.END);
        setSelectedParagraph(null);
    }

    private onWindowResize = throttle(
        () => {
            if (!this.contractRef?.current) {
                return;
            }

            const {
                contractRef: {
                    current: { scrollHeight, clientHeight },
                },
            } = this;

            const contractScrollHeight =
                scrollHeight - CONTRACT_VERTICAL_PADDING_IN_PIXELS;
            const contractClientHeight =
                clientHeight - CONTRACT_VERTICAL_PADDING_IN_PIXELS;

            this.props.setContractScrollHeightInPixels(contractScrollHeight);
            this.props.setContractVisibleHeightInPixels(contractClientHeight);
        },
        150,
        { trailing: true, leading: false }
    );

    private scrollToTarget(position: number) {
        if (typeof this.contractRef.current?.scrollTo === 'function') {
            this.contractRef.current.scrollTo({
                left: 0,
                top: position,
                behavior: 'auto',
            });
        } else {
            this.contractRef.current!.scrollTop = position;
        }
    }

    private scrollToSearchResult(index: number) {
        const $scrollTargets = document.getElementsByClassName(
            'search-result'
        ) as any;
        let $paragraph;

        if ($scrollTargets.length) {
            const $scrollTarget = $scrollTargets[index];

            if ($scrollTarget) {
                const firstParent = $scrollTarget.parentNode;

                if (firstParent.classList.contains('redlined-text')) {
                    $paragraph =
                        $scrollTarget.parentNode.parentNode.parentNode
                            .parentNode;
                } else {
                    $paragraph = $scrollTarget.parentNode.parentNode.parentNode;
                }

                const offsetTop = $paragraph.offsetTop;
                const targetOffset =
                    offsetTop - this.contractRef.current!.offsetTop - 100;

                this.scrollToTarget(targetOffset);
            }
        }
    }

    private scrollToParagraph(
        identifier: string,
        selector: string = 'paragraph'
    ) {
        const $scrollTarget = document.querySelector(
            `[data-${selector}='${identifier}']`
        ) as HTMLElement;

        if ($scrollTarget) {
            const offsetTop = $scrollTarget.offsetTop - 50;
            const targetOffset =
                offsetTop - this.contractRef.current!.offsetTop;
            this.scrollToTarget(targetOffset);
        }
    }

    private onScrollContract = throttle(
        function onScrollContract(self: any) {
            self.props.setScrollTop(
                Math.floor(self.contractRef.current.scrollTop)
            );
        },
        150,
        { leading: false, trailing: true }
    );

    private onParagraphMouseDown(index: number) {
        this.setState({
            ...this.state,
            highlightBeginParagraphIndex: index,
        });
    }

    private onParagraphMouseUp(finishIndex: number) {
        const { setHighlightIndex, selectedPropertyCode } = this.props;

        if (selectedPropertyCode) {
            return;
        }

        const { highlightBeginParagraphIndex: beginIndex } = this.state;

        setHighlightIndex(
            Math.min(finishIndex, beginIndex ?? 0),
            HighlightIndexType.START
        );
        setHighlightIndex(
            Math.max(finishIndex, beginIndex ?? 0),
            HighlightIndexType.END
        );
    }

    private deselectParagraphs(event: React.MouseEvent) {
        const { setHighlightIndex, setSelectedParagraph } = this.props;

        const styles = window.getComputedStyle(event.target as HTMLElement);
        const backgroundColour = styles?.backgroundColor;
        const className = (event.target as HTMLElement)?.className;
        const isSeverityMarker =
            typeof className === 'string' &&
            className.includes('severity-marker');

        if (
            backgroundColour === CONTRACT_WRAPPER_BACKGROUND_COLOUR ||
            (backgroundColour === TRANSPARENT_BACKGROUND && isSeverityMarker)
        ) {
            setSelectedParagraph(null);
            setHighlightIndex(-1, HighlightIndexType.START);
            setHighlightIndex(-1, HighlightIndexType.END);
        }
    }

    private groupTableParagraphs = (
        paragraphs: IContractParagraph[],
        groupBy: 'row' | 'cell'
    ): IContractParagraph[][] => {
        const grouping = {};

        for (const paragraph of paragraphs) {
            if (grouping[paragraph.table![groupBy]]) {
                grouping[paragraph.table![groupBy]].push(paragraph);
            } else {
                grouping[paragraph.table![groupBy]] = [paragraph];
            }
        }

        return Object.values(grouping);
    };

    private renderTableCell = (paragraphs: IContractParagraph[]) => {
        const cellData = paragraphs[0].table;

        if (!cellData) {
            return null;
        }

        return (
            <td
                key={`cell-${cellData.tableId}-${cellData.row}-${cellData.cell}`}
                colSpan={cellData.colSpan}
                rowSpan={cellData.rowSpan}
            >
                {paragraphs.map((paragraph) => (
                    <Paragraph
                        paragraph={paragraph}
                        key={`paragraph-${paragraph.id}`}
                        onMouseDown={() =>
                            this.onParagraphMouseDown(paragraph.index)
                        }
                        onMouseUp={() =>
                            this.onParagraphMouseUp(paragraph.index)
                        }
                    />
                ))}
            </td>
        );
    };

    private renderTableRow = (paragraphs: IContractParagraph[]) => {
        const rowData = paragraphs[0].table;
        const cells = this.groupTableParagraphs(paragraphs, 'cell');

        if (!rowData) {
            return null;
        }

        return (
            <tr key={`row-${rowData.row}`}>
                {cells.map((cell) => {
                    const cells = [];
                    for (let i = 0; i < cell[0].table!.prevEmptyCells; i++) {
                        cells.push(
                            <td key={`prev-empty-cell-${rowData.row}-${i}`} />
                        );
                    }
                    cells.push(this.renderTableCell(cell));
                    for (let i = 0; i < cell[0].table!.nextEmptyCells; i++) {
                        cells.push(
                            <td key={`next-empty-cell-${rowData.row}-${i}`} />
                        );
                    }
                    return cells;
                })}
            </tr>
        );
    };

    private renderTable = (paragraphs: IContractParagraph[]) => {
        const tableInfo = paragraphs[0].table;
        const rows = this.groupTableParagraphs(paragraphs, 'row');

        if (!tableInfo) {
            return null;
        }

        return (
            <table key={`table-id-${tableInfo.tableId}`}>
                <tbody>{rows.map(this.renderTableRow)}</tbody>
            </table>
        );
    };

    render() {
        const { bannerSettings, onReprocess, paragraphs, width } = this.props;

        const customStyles = {
            width: `${width}%`,
        };

        let paragraphsInTable: IContractParagraph[] = [];

        return (
            <div className="heatmap-pane" style={customStyles}>
                <ErrorBoundary>
                    {bannerSettings && (
                        <HeatmapPaneBanner
                            onReprocess={onReprocess}
                            selectParagraph={this.autoSelectParagraph}
                        />
                    )}
                </ErrorBoundary>
                <div
                    className="contract-wrapper"
                    ref={this.contractRef}
                    onScroll={() => this.onScrollContract(this)}
                    onClick={(event) => this.deselectParagraphs(event)}
                >
                    <div className="contract">
                        <div className="contract-body-wrapper">
                            <div className="contract-body">
                                <div className="contract-body-spacer" />
                                {paragraphs.map((paragraph) => {
                                    if (paragraph.table) {
                                        paragraphsInTable.push(paragraph);

                                        if (
                                            paragraphsInTable.length ===
                                            paragraph.table
                                                .totalParagraphsInTable
                                        ) {
                                            const table =
                                                this.renderTable(
                                                    paragraphsInTable
                                                );
                                            paragraphsInTable = [];
                                            return table;
                                        }

                                        return null;
                                    }

                                    return (
                                        <Paragraph
                                            paragraph={paragraph}
                                            key={`paragraph-${paragraph.id}`}
                                            onMouseDown={() =>
                                                this.onParagraphMouseDown(
                                                    paragraph.index
                                                )
                                            }
                                            onMouseUp={() =>
                                                this.onParagraphMouseUp(
                                                    paragraph.index
                                                )
                                            }
                                        />
                                    );
                                })}
                                <div className="contract-body-spacer" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HeatmapPane;
