import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-premium';
import { ContractReportColumn, availableColumns } from './columns';
import { IssueSeverityLevel } from '@thought-river/negotiations-common';

export type ReportView =
    | 'all-issues'
    | 'open-issues'
    | 'closed-issues'
    | 'priority-issues'
    | 'not-detected-issues'
    | 'lexible-questions'
    | 'customized-checklist'
    | 'commercial-review'
    | 'counterparty-response'
    | 'cover-sheet'
    | 'ga-comments';

export interface ReportViewConfig {
    orderedFields: ContractReportColumn[];
    filterModel?: GridFilterModel;
    sortModel?: GridSortModel;
}

// TODO: Add proper configuration for the views
export const reportViewsConfig: Record<ReportView, ReportViewConfig> = {
    'all-issues': {
        orderedFields: [
            'contract_primary_section',
            'contract_sub_sections',
            'property_linked_clauses',
            'issue_summary',
            'issue_internal_guidance',
            'issue_severity_level',
            'issue_external_comments',
            'issue_decision',
        ],
        filterModel: {
            items: [
                {
                    field: 'issue_severity_level',
                    operator: 'isAnyOf',
                    value: [
                        IssueSeverityLevel.OK,
                        IssueSeverityLevel.LOW,
                        IssueSeverityLevel.MEDIUM,
                        IssueSeverityLevel.HIGH,
                        IssueSeverityLevel.SHOWSTOPPER,
                    ],
                },
            ],
        },
    },
    'closed-issues': {
        orderedFields: availableColumns,
    },
    'commercial-review': {
        orderedFields: availableColumns,
    },
    'counterparty-response': {
        orderedFields: availableColumns,
    },
    'cover-sheet': {
        orderedFields: availableColumns,
    },
    'customized-checklist': {
        orderedFields: availableColumns,
    },
    'lexible-questions': {
        orderedFields: availableColumns,
    },
    'not-detected-issues': {
        orderedFields: availableColumns,
    },
    'open-issues': {
        orderedFields: availableColumns,
    },
    'priority-issues': {
        orderedFields: availableColumns,
    },
    'ga-comments': {
        orderedFields: [
            'contract_primary_section',
            'contract_sub_sections',
            'property_linked_clauses',
            'issue_summary',
            'issue_internal_guidance',
            'issue_severity_level',
            'issue_external_comments',
            'issue_decision',
        ],
        filterModel: {
            items: [
                {
                    field: 'issue_severity_level',
                    operator: 'isAnyOf',
                    value: [
                        IssueSeverityLevel.LOW,
                        IssueSeverityLevel.MEDIUM,
                        IssueSeverityLevel.HIGH,
                        IssueSeverityLevel.SHOWSTOPPER,
                    ],
                },
            ],
        },
    },
};
