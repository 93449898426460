import { FileFormat } from '@modules/common/types';

export const getFileType = (format: FileFormat) => {
    switch (format.toLowerCase()) {
        case 'docx':
            return 'Word';
        case 'pdf':
            return 'PDF';
    }
};
