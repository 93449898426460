import {
    GridEditInputCell,
    GridRenderEditCellParams,
} from '@mui/x-data-grid-premium';
import ImportanceEditCell from './ImportanceEditCell';
import RichTextEditCell from './RichTextEditCell';

const EditCell = (props: GridRenderEditCellParams) => {
    const { field } = props;

    if (field === 'issue_severity_level') {
        return <ImportanceEditCell {...props} />;
    }
    if (field === 'issue_internal_guidance') {
        return <RichTextEditCell placeholder="Internal Guidance" {...props} />;
    }
    if (field === 'issue_external_comments') {
        return <RichTextEditCell placeholder="External Comments" {...props} />;
    }
    if (field === 'issue_decision') {
        return <RichTextEditCell placeholder="Decision" {...props} />;
    }

    return <GridEditInputCell {...props} />;
};

export default EditCell;
