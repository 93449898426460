import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';

import PartyCorrection from './PartyCorrection';
import {
    IPartyCorrectionDispatchProps,
    IPartyCorrectionStateProps,
    PartyCorrectionStatus,
} from './PartyCorrection-types';
import { setPartyParagraphsAction } from '../PartyContract/PartyContract-actions';
import { IParty } from '../Parties/Parties-types';
import {
    resetSelectionsAction,
    setDraggedPartyAction,
    setLocalPartiesAction,
    setPartiesAction,
} from '../Parties/Parties-actions';
import {
    setPartyCorrectionStatusAction,
    setPartyContractIdAction,
    setPartyVersionIdAction,
    setHighlightingAction,
} from './PartyCorrection-actions';
import { IRootState } from '../../redux/reducers-types';
import { IContractParagraph } from '../Paragraph/Paragraph-types';
import { IModalDialogButton } from '../ModalDialog/ModalDialog-types';
import {
    hideDialogAction,
    showDialogAction,
} from '../ModalDialog/ModalDialog-actions';
import { setReprocessingContractAction } from '../ContractPollingProvider/ContractPollingProvider-actions';

function mapStateToProps(state: IRootState): IPartyCorrectionStateProps {
    return {
        contractId: state.partyCorrection.contractId,
        localParties: state.parties.localParties,
        parties: state.parties.parties,
        status: state.partyCorrection.status,
        statusMessage: state.partyCorrection.statusMessage,
        userId: state.auth.userId,
        versionId: state.partyCorrection.versionId,
        featureToggles: state.featureToggles.toggles,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IPartyCorrectionDispatchProps {
    return {
        hideDialog: () => dispatch(hideDialogAction()),
        resetSelections: () => dispatch(resetSelectionsAction()),
        setPartyCorrectionStatus: (
            status: PartyCorrectionStatus,
            statusMessage?: string
        ) => dispatch(setPartyCorrectionStatusAction(status, statusMessage)),
        setContractId: (contractId: string) =>
            dispatch(setPartyContractIdAction(contractId)),
        setDraggedParty: (party: IParty) =>
            dispatch(setDraggedPartyAction(party)),
        setLocalParties: (parties: IParty[]) =>
            dispatch(setLocalPartiesAction(parties)),
        setParagraphs: (paragraphs: IContractParagraph[]) =>
            dispatch(setPartyParagraphsAction(paragraphs)),
        setParties: (parties: IParty[]) => dispatch(setPartiesAction(parties)),
        setVersionId: (versionId: string) =>
            dispatch(setPartyVersionIdAction(versionId)),
        setReprocessingContract: (
            contractId: string,
            contractName: string,
            versionId: string,
            stream: string,
            streamId: string,
            assessmentCount: number,
            userId: string
        ) =>
            dispatch(
                setReprocessingContractAction(
                    contractId,
                    contractName,
                    versionId,
                    stream,
                    streamId,
                    assessmentCount,
                    userId
                )
            ),
        setHighlighting: (highlighting: boolean) =>
            dispatch(setHighlightingAction(highlighting)),
        showDialog: (
            title: string | JSX.Element,
            showCloseButton: boolean,
            component: JSX.Element,
            buttons: IModalDialogButton[],
            height: string
        ) =>
            dispatch(
                showDialogAction(
                    title,
                    showCloseButton,
                    component,
                    buttons,
                    height
                )
            ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PartyCorrection));
