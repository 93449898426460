import range from 'lodash/range';
import { TemplateCardsProps } from './TemplateCards.types';
import { LoadingCard, TemplateCard } from './TemplateCard';
import {
    useCreateReportDefinition,
    useUpdateSummaryPanelTemplate,
} from '@thought-river/negotiations-common/dist/api/playbookManager';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import styles from './TemplateCards.module.scss';
import { useState } from 'react';
import { SummaryPanelTemplateOut } from '@thought-river/negotiations-common/dist/api/playbookManager';
import {
    addToTanStackQueryReportDefinitionsData,
    updateTanStackQueryTemplatesData,
    useBetaReports2Enabled,
} from '@modules/reports/portfolioReports';
import { ConfigureChecklistDialog } from '@thought-river/negotiations-common';

const TemplateCards = ({
    templatesLoading,
    templates,
    filteredByDealTypeId,
}: TemplateCardsProps) => {
    const history = useHistory();
    const queryClient = useQueryClient();
    const betaReports2Enabled = useBetaReports2Enabled();

    const { mutate: createReportDefinition } = useCreateReportDefinition({});
    const { mutate: updateTemplate } = useUpdateSummaryPanelTemplate({});

    const [selectedTemplate, setSelectedTemplate] =
        useState<SummaryPanelTemplateOut | null>(null);

    const renameTemplate = (name: string, description?: string) => {
        if (!selectedTemplate) {
            return;
        }
        updateTemplate(
            {
                pathParams: {
                    summaryPanelTemplateId: selectedTemplate.id_str ?? '',
                },
                body: {
                    name,
                    description,
                    properties: selectedTemplate?.properties.map((p) => ({
                        dfcode: p.dfcode!, // Fixme: null checks
                        order: p.order!, // Fixme: null checks
                    })),
                },
            },
            {
                onSuccess: (template: SummaryPanelTemplateOut) => {
                    setSelectedTemplate(null);
                    updateTanStackQueryTemplatesData(queryClient, template);
                },
            }
        );
    };

    const createReportDefinitionFromTemplate = (id: string) => {
        createReportDefinition(
            //TODO: we should fix the schema to allow no body
            //@ts-ignore
            {
                queryParams: { summary_panel_template_id: id },
            },
            {
                onSuccess: (reportDefinition) => {
                    addToTanStackQueryReportDefinitionsData(
                        queryClient,
                        reportDefinition
                    );
                    history.push(`/reports/${reportDefinition.id_str}`);
                },
                onError: () => {
                    toast.error(`Error creating report from template!`);
                },
            }
        );
    };

    const loadingCards = range(0, 10).map((_, i) => (
        <LoadingCard key={`loading-card-${i}`} />
    ));

    const templateCards = templates?.map((template) => (
        <TemplateCard
            key={template.id_str}
            onClick={() =>
                createReportDefinitionFromTemplate(template.id_str ?? '')
            }
            onRenameTemplateClick={() => setSelectedTemplate(template)}
            template={template}
            filteredByDealTypeId={filteredByDealTypeId}
        />
    ));

    const startFromScratchCard = (
        <TemplateCard onClick={() => history.push('/reports/create')} />
    );

    return (
        <>
            <div className={styles.cards}>
                {betaReports2Enabled && startFromScratchCard}
                {templatesLoading ? loadingCards : templateCards}
            </div>
            {selectedTemplate && (
                <ConfigureChecklistDialog
                    checklist={selectedTemplate}
                    open={!!selectedTemplate}
                    onSave={renameTemplate}
                    onClose={() => setSelectedTemplate(null)}
                    title="Edit checklist"
                />
            )}
        </>
    );
};
export default TemplateCards;
