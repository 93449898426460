import styles from '../../../ViewReportPage.module.scss';
import { Tooltip } from '@thought-river/ui-components';
import {
    columnInformationTooltipTitle,
    reportColumnLabels,
    useLexibleContext,
} from '@modules/reports/portfolioReports';
import { extractColumnField } from '@modules/reports/common/helpers/extractColumnField';

interface ReportHeaderCellProps {
    column: string;
}

const ReportHeaderCell = ({ column }: ReportHeaderCellProps) => {
    const field = extractColumnField(column);
    const { propertiesDict } = useLexibleContext();
    const tooltipTitle = propertiesDict?.[field]
        ? propertiesDict[field]?.question
        : columnInformationTooltipTitle[field];
    const columnName = propertiesDict?.[field]
        ? propertiesDict?.[field]?.short_label
        : reportColumnLabels[field];
    return (
        <Tooltip title={tooltipTitle}>
            <span
                className={styles.headerText}
                data-testid={`column-header-${field}`}
            >
                {columnName}
            </span>
        </Tooltip>
    );
};

export default ReportHeaderCell;
