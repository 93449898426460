import DOMPurify from 'dompurify';

import { ICopyableElementProps } from './CopyableElement-types';
import './CopyableElement.scss';

const CopyableElement = ({
    id,
    content,
}: ICopyableElementProps): JSX.Element => (
    <div
        className="copyable-element"
        id={id}
        dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
                content.replace(/<p><\/p>/gm, '<p><br/></p>')
            ),
        }}
    />
);

export default CopyableElement;
