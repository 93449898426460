import { IParty, PartiesType } from '../Parties/Parties-types';
import { Action } from 'redux';

export const SET_HIGHLIGHTED_PARTY = 'SET_HIGHLIGHTED_PARTY';
export const SET_PARTY_TO_ADD = 'SET_PARTY_TO_ADD';

export interface IPartyParagraphDispatchProps {
    setExpandedGroup: (group: PartiesType) => void;
    setHighlightedParty: (party: IParty) => void;
    setPartyToAdd: (partyToAdd: string) => void;
    setSelectedParty: (party: IParty) => void;
    setScrollToParty: (party: IParty) => void;
    setDraggedParty: (party: IParty) => void;
    setHighlighting: (highlighting: boolean) => void;
}

export interface IPartyParagraphProps
    extends IPartyParagraphDispatchProps,
        IPartyParagraphStateProps {
    parties: IParty[];
    partiesMatchPattern: string;
    text: string;
    index: number;
}

export interface IPartyParagraphStateProps {
    expandedGroup: PartiesType;
    highlightedParty: IParty;
    highlighting: boolean;
    partyToAdd: string;
    selectedParty: IParty;
}

export interface ISetHighlightedPartyAction extends Action {
    type: typeof SET_HIGHLIGHTED_PARTY;
    highlightedParty: IParty;
}

export interface ISetPartyToAddAction extends Action {
    type: typeof SET_PARTY_TO_ADD;
    partyToAdd: string;
}
