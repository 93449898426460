import { SubmitStatus } from '../App/App-types';
import {
    IssuesTableHeader,
    SortOrder,
} from './HybridReportDataGrid/HybridReportDataGrid-types';
import {
    IIssuesReportActionTypes,
    IIssuesReportReducer,
    SET_DOWNLOAD_PDF,
    SET_ISSUES_REPORT_SAVED_STATUS,
    SET_ISSUES_REPORT_SORT_MODEL,
    SET_ISSUES_TABLE_COLUMNS,
    SET_SELECTED_PROPERTY_SUGGESTION,
} from './IssuesReport-types';

export const defaultColumns = [
    {
        label: IssuesTableHeader.STATUS,
        active: true,
        width: 12.5,
    },
    {
        label: IssuesTableHeader.IMPORTANCE,
        active: true,
        width: 12.5,
    },
    {
        label: IssuesTableHeader.FAMILIARITY,
        active: false,
        width: 12.5,
    },
    {
        label: IssuesTableHeader.LINKED_CLAUSES,
        active: true,
        width: 12.5,
    },
    {
        label: IssuesTableHeader.LINKED_CLAUSES_TEXT,
        active: true,
        width: 20,
    },
    {
        label: IssuesTableHeader.SUGGESTIONS,
        active: false,
        width: 20,
    },
    {
        label: IssuesTableHeader.SUMMARY,
        active: true,
        width: 20,
    },
    {
        label: IssuesTableHeader.DESCRIPTION,
        active: true,
        width: 30,
    },
    {
        label: IssuesTableHeader.LAST_UPDATED,
        active: true,
        width: 12.5,
    },
    {
        label: IssuesTableHeader.NOTES,
        active: true,
        width: 24,
    },
    {
        label: IssuesTableHeader.PROPERTY_LABEL,
        active: false,
        width: 20,
    },
    {
        label: IssuesTableHeader.PROPERTY_NAME,
        active: false,
        width: 25,
    },
    {
        label: IssuesTableHeader.PROPERTY_CODE,
        active: false,
        width: 17.5,
    },
    {
        label: IssuesTableHeader.PROPERTY_QUESTION,
        active: false,
        width: 25,
    },
    {
        label: IssuesTableHeader.PROPERTY_ANSWER,
        active: false,
        width: 20,
    },
    {
        label: IssuesTableHeader.PROPERTY_THEME,
        active: false,
        width: 20,
    },
];

export const issueColumns = [
    IssuesTableHeader.STATUS,
    IssuesTableHeader.IMPORTANCE,
    IssuesTableHeader.LINKED_CLAUSES,
    IssuesTableHeader.SUMMARY,
    IssuesTableHeader.DESCRIPTION,
    IssuesTableHeader.LAST_UPDATED,
    IssuesTableHeader.NOTES,
];

export const contractSummaryColumns = [
    // IssuesTableHeader.PROPERTY_LABEL, // TODO: enable once the short label is added to the API - remember to adjust the width of all the 'property' columns
    IssuesTableHeader.PROPERTY_NAME,
    IssuesTableHeader.PROPERTY_CODE,
    IssuesTableHeader.PROPERTY_QUESTION,
    IssuesTableHeader.PROPERTY_ANSWER,
    IssuesTableHeader.LINKED_CLAUSES,
];

export const counterpartyReponseColumns = [
    IssuesTableHeader.STATUS,
    IssuesTableHeader.IMPORTANCE,
    IssuesTableHeader.LINKED_CLAUSES,
    IssuesTableHeader.SUMMARY,
    IssuesTableHeader.NOTES,
    IssuesTableHeader.LAST_UPDATED,
];

export const commercialReviewColumns = [
    IssuesTableHeader.STATUS,
    IssuesTableHeader.LINKED_CLAUSES,
    IssuesTableHeader.SUMMARY,
    IssuesTableHeader.DESCRIPTION,
    IssuesTableHeader.NOTES,
    IssuesTableHeader.FAMILIARITY,
    IssuesTableHeader.IMPORTANCE,
];

export const coverSheetColumns = [
    IssuesTableHeader.PROPERTY_THEME,
    IssuesTableHeader.LINKED_CLAUSES,
    IssuesTableHeader.IMPORTANCE,
    IssuesTableHeader.SUMMARY,
];

export const bidReportColumns = [
    IssuesTableHeader.IMPORTANCE,
    IssuesTableHeader.SUMMARY,
    IssuesTableHeader.DESCRIPTION,
];

export const negotiationReportColumns = [
    IssuesTableHeader.IMPORTANCE,
    IssuesTableHeader.SUMMARY,
    IssuesTableHeader.LINKED_CLAUSES_TEXT,
    IssuesTableHeader.SUGGESTIONS,
];

export const finalReportForSignatoriesColumns = [
    IssuesTableHeader.IMPORTANCE,
    IssuesTableHeader.SUMMARY,
    IssuesTableHeader.DESCRIPTION,
];

export const finalReportForProjectTeamsColumns = [
    IssuesTableHeader.IMPORTANCE,
    IssuesTableHeader.SUMMARY,
    IssuesTableHeader.LINKED_CLAUSES_TEXT,
];

export const adeccoNDAReportColumns = [
    IssuesTableHeader.PROPERTY_QUESTION,
    IssuesTableHeader.PROPERTY_ANSWER,
    IssuesTableHeader.LINKED_CLAUSES_TEXT,
];

export const initialSortModel = [
    {
        field: IssuesTableHeader.LINKED_CLAUSES,
        sort: SortOrder.ASC,
    },
];

export const initialState: IIssuesReportReducer = {
    columns: defaultColumns,
    copyingTable: false,
    downloadPdf: false,
    sortModel: initialSortModel,
    savedStatus: SubmitStatus.SUCCESS,
    selectedPropertySuggestionMap: {},
};

const IssuesReportReducer = (
    state = initialState,
    action: IIssuesReportActionTypes
): IIssuesReportReducer => {
    switch (action.type) {
        case SET_ISSUES_TABLE_COLUMNS:
            return {
                ...state,
                columns: [...action.columns],
            };
        case SET_DOWNLOAD_PDF:
            return {
                ...state,
                downloadPdf: action.downloadPdf,
            };
        case SET_ISSUES_REPORT_SAVED_STATUS:
            return {
                ...state,
                savedStatus: action.status,
            };
        case SET_ISSUES_REPORT_SORT_MODEL:
            return {
                ...state,
                sortModel: action.sortModel,
            };
        case SET_SELECTED_PROPERTY_SUGGESTION: {
            const { propertyId, suggestionText } = action;
            return {
                ...state,
                selectedPropertySuggestionMap: {
                    [propertyId]: suggestionText,
                },
            };
        }
        default:
            return state;
    }
};

export default IssuesReportReducer;
