import { Component } from 'react';

import { Add, Link } from '@mui/icons-material';

import { IParagraphWidgetProps, LinkStatus } from './ParagraphWidget-types';
import { analytics } from './ParagraphWidget-analytics';
import { mapClauseNumber, mapIssue } from '../App/App-mappers';
import { IIssueRequestPayload } from '../../resources/IssueResource-types';
import IssueResource from '../../resources/IssueResource';
import { ComponentReference } from '../../services/Analytics/Analytics-types';
import { IIssueData } from 'types/thoughtriver';
import { toast } from 'react-toastify';
import { Button, ButtonGroup } from '@thought-river/ui-components';
import { UnlinkButton } from '@modules/common/components/UnlinkButton';
import { ParagraphClause } from '@thought-river/negotiations-common';

@analytics
class ParagraphWidget extends Component<IParagraphWidgetProps> {
    constructor(props: IParagraphWidgetProps) {
        super(props);
        this.onAddIssue = this.onAddIssue.bind(this);
        this.onLinkClauses = this.onLinkClauses.bind(this);
        this.onUnlinkClauses = this.onUnlinkClauses.bind(this);
    }

    onAddIssue() {
        const { setSelectedIssue, openIssuePane } = this.props;

        setSelectedIssue(null!); // Fixme: null checks
        openIssuePane();
    }

    onLinkClauses() {
        const { form, highlightedParagraphs } = this.props;

        const newLinkedClauses: ParagraphClause[] =
            highlightedParagraphs.map(mapClauseNumber);
        const formClauseUuids = form.clauseNumbers.map(
            (clause) => clause.originUuid
        );
        const clauseNumbers = form.clauseNumbers.concat(
            newLinkedClauses.filter(
                (clause) => !formClauseUuids.includes(clause.originUuid)
            )
        );

        this.updateIssue(clauseNumbers, 'Error linking clause');
    }

    onUnlinkClauses() {
        const { form, highlightedParagraphs } = this.props;

        const newUnlinkedClauses: ParagraphClause[] =
            highlightedParagraphs.map(mapClauseNumber);
        const newUnlinkedClauseUuids = newUnlinkedClauses.map(
            (clause) => clause.originUuid
        );
        const clauseNumbers = form.clauseNumbers.filter(
            (formClause) =>
                !newUnlinkedClauseUuids.includes(formClause.originUuid)
        );

        this.updateIssue(clauseNumbers, 'Error unlinking clause');
    }

    async updateIssue(clauseNumbers: ParagraphClause[], toastMsg: string) {
        const { contract, form, setIssueForm, selectedIssue } = this.props;

        setIssueForm({
            ...form,
            clauseNumbers,
        });

        if (!selectedIssue) {
            return;
        }

        const {
            id: contractId,
            latestVersion: { id: versionId },
            streamCode: stream,
        } = contract;

        const payload: IIssueRequestPayload = {
            data: {
                attributes: {
                    'paragraph-origin-uuids': clauseNumbers.map(
                        (clause) => clause.originUuid
                    ),
                },
            },
        };

        try {
            await IssueResource.updateIssue(
                contractId,
                versionId,
                selectedIssue.id,
                stream,
                payload,
                ComponentReference.ISSUE_CARD
            );
            const response = await IssueResource.getIssue(
                contractId,
                versionId,
                selectedIssue.id,
                stream
            );
            this.updateIssues(response.data);
        } catch {
            toast.error(toastMsg);
        }
    }

    updateIssues(issueData: IIssueData) {
        const {
            contract,
            issues,
            setIssues,
            paragraphs,
            properties,
            lexibleProperties,
            categories,
            themes,
        } = this.props;

        const clauseNumbers = paragraphs.map(mapClauseNumber);

        const newIssue = mapIssue(
            issueData,
            clauseNumbers,
            properties,
            paragraphs,
            lexibleProperties,
            categories,
            themes,
            contract.latestVersion
        );

        setIssues(
            issues.map((issue) => (issue.id === newIssue.id ? newIssue : issue))
        );
    }

    highlightedParagraphsLinkStatus(): LinkStatus | null {
        const { form, highlightedParagraphs } = this.props;

        if (!highlightedParagraphs) {
            return null;
        }

        let linkedClausesCount = 0;
        const selectedIssueClauseUuids = form.clauseNumbers.map(
            (clause) => clause.originUuid
        );
        const highlightedParagraphsUuids = highlightedParagraphs.map(
            (paragraph) => paragraph.originUuid
        );
        selectedIssueClauseUuids.forEach((clauseUuid) => {
            highlightedParagraphsUuids.includes(clauseUuid) &&
                linkedClausesCount++;
        });

        if (linkedClausesCount === highlightedParagraphs.length) {
            return LinkStatus.LINKED;
        } else if (linkedClausesCount === 0) {
            return LinkStatus.NOT_LINKED;
        } else {
            return LinkStatus.MIXED;
        }
    }

    renderLinkButtons() {
        const linkButton = (
            <Button
                variant="tertiary"
                className="link-clause-btn"
                onClick={this.onLinkClauses}
                startIcon={<Link className="link-clause-icon" />}
            >
                Link
            </Button>
        );

        const unLinkButton = <UnlinkButton onClick={this.onUnlinkClauses} />;

        if (this.highlightedParagraphsLinkStatus() === LinkStatus.NOT_LINKED) {
            return linkButton;
        } else if (
            this.highlightedParagraphsLinkStatus() === LinkStatus.LINKED
        ) {
            return unLinkButton;
        } else {
            return (
                <>
                    {linkButton}
                    <div className="paragraph-widget-seperator" />
                    {unLinkButton}
                </>
            );
        }
    }

    render() {
        const { isIssuePaneOpen, topOffset } = this.props;

        return (
            <div className="paragraph-widget" style={{ top: topOffset }}>
                <ButtonGroup>
                    <Button
                        variant="tertiary"
                        className="add-issue-btn"
                        onClick={this.onAddIssue}
                        startIcon={<Add />}
                    >
                        Issue
                    </Button>
                    {isIssuePaneOpen ? this.renderLinkButtons() : null}
                </ButtonGroup>
            </div>
        );
    }
}

export default ParagraphWidget;
