import ContractResource from '../../resources/ContractResource';
import {
    setContractsAction,
    setContractVersionsAction,
    updateContractInListAction,
    setShowLoaderAction,
    setRequestedPagesAction,
    setCurrentPageAction,
    setHasNextPageAction,
    setSelectAllContractsAction,
    setFetchingContractsAction,
} from './ContractList-actions';
import VersionResource from '../../resources/VersionResource';
import { mapContract, mapVersion } from '../App/App-mappers';
import {
    IContractVersion,
    IDisplayContract,
    UploadStatus,
} from '../Contract/Contract-types';
import { IGroupedVersion } from './ContractList-types';
import { reduxStore } from '../../redux/store';
import {
    IContractFilters,
    IContractsResponse,
} from '../../resources/ContractResource-types';
import { ToggleElement } from '../ToggleBar/ToggleBar-types';
import { updateContractAction } from '../Contract/Contract-actions';
import { IRootState } from '../../redux/reducers-types';
import {
    IProcessingContract,
    IReprocessingContract,
} from '../ContractPollingProvider/ContractPollingProvider-types';
import { addProcessingContractAction } from '../ContractPollingProvider/ContractPollingProvider-actions';
import { VERSION_STATE_UPLOADING } from '../UploadProgressBar/UploadProgressBar-types';
import { fetchGetStatuses } from '@thought-river/negotiations-common/dist/api/contractContent';
import { ContractInfos } from '@thought-river/negotiations-common/dist/api/contractContent';
import find from 'lodash/find';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';

export function updateContract(
    contractId: string,
    fields: Partial<IDisplayContract>
) {
    return (dispatch: CallableFunction) => {
        dispatch(updateContractAction(contractId, fields));
        dispatch(updateContractInListAction(contractId, fields));
    };
}

function resolveFilters(
    filters: ToggleElement[],
    searchTerm: string
): IContractFilters {
    const resolvedFilters: any = {
        name: searchTerm,
    };

    for (const filter of filters) {
        resolvedFilters[filter.id] = filter.enabled;
    }

    return resolvedFilters;
}

function versionIsProcessing(version: IContractVersion) {
    return (
        !version ||
        version.uploadStatus === UploadStatus.PROCESSING ||
        (version.uploadStatus === UploadStatus.COMPLETED &&
            !version.requirePartyConfirmation &&
            !version.assessmentIds.length)
    );
}

export async function loadContracts(
    dispatch: CallableFunction,
    page: number = 1
) {
    const { contractList, contractListToolbar, contractPollingProvider } =
        (await reduxStore.store.getState()) as IRootState;
    const { requestedPages } = contractList;
    const { processingContracts, reprocessingContracts } =
        contractPollingProvider;
    const { toggles, searchTerm } = contractListToolbar;
    const filters = resolveFilters(toggles, searchTerm);

    if (page === 1) {
        await dispatch(setSelectAllContractsAction(false));
    }

    if (requestedPages.includes(page)) {
        return;
    }

    await dispatch(setRequestedPagesAction([...requestedPages, page]));
    await dispatch(setShowLoaderAction(true));
    await dispatch(setFetchingContractsAction(true));

    const contractsResponse = await ContractResource.getContracts(
        page,
        filters,
        true
    );

    const contractIds = contractsResponse.data.map((contract) => contract.id);

    let contractStatuses: ContractInfos = [];
    if (contractIds.length > 0) {
        contractStatuses = await fetchGetStatuses({
            body: contractIds.map((id) => ({ contract_uuid: id })),
        });
    }

    const groupedVersions: IGroupedVersion = contractsResponse.included
        ? groupBy(
              contractsResponse.included.filter(
                  (version) => version.type === 'versions'
              ),
              'relationships.contract.data.id'
          )
        : {};

    const newContracts = contractsResponse.data
        .map((contract) => {
            const contractVersions = groupedVersions[contract.id]
                ? groupedVersions[contract.id].map((version, index) =>
                      mapVersion(version, index)
                  )
                : [];
            const contractStatus = contractStatuses.find(
                (status) => status.contract_uuid === contract.id
            );
            return mapContract(contract, contractVersions, contractStatus);
        })
        .filter((contract) => contract.versionIds.length);

    const contracts =
        page === 1
            ? []
            : await reduxStore.store.getState().contractList.contracts;

    await dispatch(setHasNextPageAction(hasNextPage(contractsResponse)));
    await dispatch(setCurrentPageAction(page));
    await dispatch(setShowLoaderAction(false));
    await dispatch(setContractsAction(contracts.concat(newContracts)));
    await dispatch(setFetchingContractsAction(false));
    await loadContractVersions(
        dispatch,
        newContracts,
        processingContracts,
        reprocessingContracts
    );
}

export async function loadContractVersions(
    dispatch: CallableFunction,
    contracts: IDisplayContract[],
    processingContracts: IProcessingContract[],
    reprocessingContracts: IReprocessingContract[]
) {
    for (const contract of contracts) {
        if (!contract.streamCode) {
            continue;
        }

        const versions = contract.versions.length
            ? contract.versions
            : (
                  await VersionResource.getVersions(
                      contract.id,
                      contract.streamCode
                  )
              ).data.map((version, index) => mapVersion(version, index));

        const latestVersion = versions.filter((version) => version.isLatest)[0];

        if (
            versionIsProcessing(latestVersion) &&
            !find(processingContracts, { contractId: contract.id }) &&
            !find(reprocessingContracts, { contractId: contract.id })
        ) {
            await dispatch(
                addProcessingContractAction({
                    contractId: contract.id,
                    contractName: contract.contractName,
                    stream: contract.streamCode,
                    userId: null!, // Fixme: null checks
                    isPartiallyAssessed: true,
                    status: VERSION_STATE_UPLOADING,
                })
            );
        }

        await dispatch(setContractVersionsAction(contract.id, versions));
    }
}

function hasNextPage(contractsResponse: IContractsResponse): boolean {
    return Boolean(get(contractsResponse, 'links.next', null));
}
