import {
    GridGroupingValueGetterParams,
    GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import dayjs from 'dayjs';

export const valueGetter = (params: GridValueGetterParams) => {
    if (params.colDef.type === 'dateTime' || params.colDef.type === 'date') {
        return dayjs(params.value).toDate();
    }

    if (params.field === 'property_linked_clauses') {
        return params.value?.map((clause: any) => clause.clause);
    }

    if (params.field === 'property_linked_clauses_text') {
        return params.row.property_linked_clauses
            ?.map((clause: any) => clause.text)
            .join('\n');
    }

    if (params.field === 'issue_severity_level') {
        // @TODO: Ask backend to return this in lowercase
        return params.value?.toLowerCase();
    }
    return params?.value;
};

export const groupingValueGetter = (params: GridGroupingValueGetterParams) => {
    if (
        params.field === 'contract_sections' ||
        params.field === 'contract_sub_sections'
    ) {
        if (!params.value || params.value.length === 0) {
            return '-';
        }
        return params.value.join(', ');
    }

    return params.value ?? '-';
};
