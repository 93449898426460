import { IssueCardProps } from './IssueCard.types';
import { useSelector, useDispatch } from 'react-redux';
import { IRootState } from '../../../redux/reducers-types';
import { setBannerSettingsAction } from '../../HeatmapPane/HeatmapPane-actions';
import {
    BannerClauseType,
    BannerType,
} from '../../HeatmapPane/HeatmapPane-types';
import {
    setSelectedIssueAction,
    setSelectedPropertyCodeAction,
} from '../../Contract/Contract-actions';
import {
    FamiliarityThresholds,
    IssueCard,
    IssueSeverityLevel,
    IssueStatus,
    useRelatedClausesContext,
} from '@thought-river/negotiations-common';
import { IIssueRequestPayload } from '../../../resources/IssueResource-types';
import IssueResource from '../../../resources/IssueResource';
import { ComponentReference } from '../../../services/Analytics/Analytics-types';
import { resolveSeverityLevelValue } from '../IssuesListPane-helpers';
import { FEATURE_TOGGLE_FAMILIARITY_THRESHOLDS } from '../../../components/FeatureToggleProvider/FeatureToggleProvider-types';
import { useIssuePaneContext } from '@modules/common/context/IssuePaneProvider';

const IssueCardContainer = ({
    issue,
    onIssueUpdated,
    versionIsLocked,
    width,
}: IssueCardProps) => {
    const dispatch = useDispatch();
    const { openIssuePane } = useIssuePaneContext();
    const { shouldShowRelatedClauses } = useRelatedClausesContext();

    const contract = useSelector(
        (state: IRootState) => state.contract.contract
    );
    const featureToggles = useSelector(
        (state: IRootState) => state.featureToggles.toggles
    );

    const noLinkedClauses = !issue.paragraphOriginUuids.length;

    const dispatchSetBannerSettingsAction = (paragraphId?: string) =>
        dispatch(
            setBannerSettingsAction({
                type: BannerType.INFO,
                clauseType: noLinkedClauses
                    ? BannerClauseType.PROPERTY_RELATED
                    : BannerClauseType.PROPERTY_LINKED,
                items: noLinkedClauses
                    ? issue.relatedParagraphIds
                    : issue.paragraphIds,
                currentItem: paragraphId,
            })
        );

    const handleClausesOnClick = (paragraphId?: string) => {
        dispatch(setSelectedPropertyCodeAction(issue.propertyCode));
        dispatchSetBannerSettingsAction(paragraphId);
    };

    const saveIssue = async (payload: IIssueRequestPayload) => {
        const {
            id: contractId,
            latestVersion: { id: versionId },
            streamCode: stream,
        } = contract;

        await IssueResource.updateIssue(
            contractId,
            versionId,
            issue.id,
            stream,
            payload,
            ComponentReference.ISSUE_CARD
        );
        const response = await IssueResource.getIssue(
            contractId,
            versionId,
            issue.id,
            stream
        );
        onIssueUpdated(response.data);
    };

    const handleCardOnClick = () => {
        dispatch(setSelectedIssueAction(issue));
        dispatch(setSelectedPropertyCodeAction(issue.propertyCode));
        openIssuePane();
        dispatchSetBannerSettingsAction();
    };

    const handleSeverityChange = (severityLevel: IssueSeverityLevel) => {
        const payload: IIssueRequestPayload = {
            data: {
                attributes: {
                    'severity-level': resolveSeverityLevelValue(severityLevel),
                },
            },
        };

        saveIssue(payload);
    };

    const handleStatusChange = (status: IssueStatus) => {
        const payload: IIssueRequestPayload = {
            data: {
                attributes: {
                    status: status,
                },
            },
        };

        saveIssue(payload);
    };

    const familiarityThresholds = featureToggles.find(
        (toggle) => toggle.feature === FEATURE_TOGGLE_FAMILIARITY_THRESHOLDS
    )?.value as FamiliarityThresholds | undefined;

    return (
        <IssueCard
            contract={contract}
            issue={issue}
            onClauseClick={handleClausesOnClick}
            onCardClick={handleCardOnClick}
            isVersionLocked={versionIsLocked}
            onSeverityChange={handleSeverityChange}
            onStatusChange={handleStatusChange}
            familiarityThresholds={familiarityThresholds}
            showRelatedClauses={shouldShowRelatedClauses}
            width={width}
        />
    );
};

export default IssueCardContainer;
