import { IReviewStream } from '../../../../../../../../resources/AuthResource-types';

/**
 * Function mapping the given string to a value in range [0, variantsCount)
 *  in such a way that the same string will always be assigned to the same value
 */
export const getVariantIndexFromString = (
    dealTypeId: string,
    variantsCount: number
) => {
    let hash = 0;
    for (let i = 0; i < dealTypeId.length; i++) {
        hash += dealTypeId.charCodeAt(i);
    }
    return hash % variantsCount;
};

/**
 * Function mapping the template deal type IDs to matching deal types,
 * filtering out the deal types user doesn't have access to
 * If filteredByDealTypeId is specified, its matching deal type will
 * be the first element of the returned array
 */
export const getTemplateDealTypes = (
    userDealTypes: IReviewStream[],
    templateDealTypeIds: string[],
    filteredByDealTypeId?: string
) => {
    const dealTypesByIdMap = new Map<string, IReviewStream>();
    userDealTypes.forEach((dealType) =>
        dealTypesByIdMap.set(dealType.streamId, dealType)
    );

    const templateDealTypes = templateDealTypeIds.reduce<IReviewStream[]>(
        (dealTypes, dealTypeId) => {
            const matchingDealType = dealTypesByIdMap.get(dealTypeId);
            if (!matchingDealType) {
                return dealTypes;
            }

            if (dealTypeId === filteredByDealTypeId) {
                dealTypes.unshift(matchingDealType);
            } else {
                dealTypes.push(matchingDealType);
            }

            return dealTypes;
        },
        []
    );

    return templateDealTypes;
};
