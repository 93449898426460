import { Component } from 'react';
import { toast } from 'react-toastify';
import { Cancel } from '@mui/icons-material';

import {
    IBulkSignModalProps,
    IBulkSignModalState,
} from './BulkSignModal-types';
import VersionResource from '../../resources/VersionResource';
import { BusinessStatus } from '../Contract/Contract-types';
import { mapBusinessStatus, mapVersion } from '../App/App-mappers';
import { analytics } from './BulkSignModal-analytics';
import find from 'lodash/find';
import { Button } from '@thought-river/ui-components';
import { fetchGetBusinessStatusesV02 } from '@thought-river/negotiations-common/dist/api/contractManagement';

@analytics()
class BulkSignModal extends Component<
    IBulkSignModalProps,
    IBulkSignModalState
> {
    constructor(props: IBulkSignModalProps) {
        super(props);

        this.state = {
            submitting: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    async onSubmit() {
        const {
            businessStatuses: businessStatusesProp,
            closeModal,
            selectedContracts,
            updateContract,
            setSelectAllContracts,
            setBusinessStatuses,
        } = this.props;

        let businessStatuses = businessStatusesProp;

        // Fetch business statuses if they're not in Redux
        if (!businessStatuses) {
            const response = await fetchGetBusinessStatusesV02({});
            businessStatuses = response.data?.map(mapBusinessStatus) ?? [];
            setBusinessStatuses(businessStatuses);
        }

        const targetStatus = find(businessStatuses, {
            label: BusinessStatus.SIGNED,
        });

        if (!targetStatus) {
            toast.error(
                "Error signing the contracts - 'Signed' status missing"
            );
            this.setState({
                submitting: false,
            });
            return;
        }

        const invalidContracts = [];
        const validContracts = [];
        let versions;

        this.setState({
            submitting: true,
        });

        try {
            for (let contract of selectedContracts) {
                if (contract.reprocessRequired || !contract.latestVersion) {
                    invalidContracts.push(contract.id);
                    continue;
                }

                const { id: contractId, streamCode: stream } = contract;

                await VersionResource.patchVersionStatus(
                    contractId,
                    contract.latestVersion.id,
                    stream,
                    targetStatus.id
                );

                validContracts.push(contract.id);

                versions = (
                    await VersionResource.getVersions(contractId, stream)
                ).data.map((version, index) => mapVersion(version, index));

                contract = {
                    ...contract,
                    versions,
                    latestVersion: versions[versions.length - 1],
                    isSigned: true,
                };

                updateContract(contract.id, contract);
            }

            setSelectAllContracts(false);

            toast.success(
                `${validContracts.length} contract(s) signed successfully`
            );

            if (invalidContracts.length) {
                toast.error(
                    `${invalidContracts.length} contract(s) were not signed because they are missing data`
                );
            }

            this.setState({
                submitting: false,
            });

            closeModal();
        } catch (error) {
            toast.error('Error signing the contract(s)');

            this.setState({
                submitting: false,
            });
        }
    }

    render() {
        const { selectedContracts, closeModal } = this.props;
        const { submitting } = this.state;

        return (
            <div className="bulk-sign-modal">
                <div className="modal-dialog-window-header">
                    <div className="modal-dialog-title">
                        <span className="title-text">
                            Mark contracts as 'Signed'
                        </span>
                    </div>
                    <div
                        className="modal-dialog-close-button"
                        onClick={closeModal}
                    >
                        <Cancel />
                    </div>
                </div>
                <div className="section">
                    <div className="section-content">
                        Please confirm that you want to mark{' '}
                        {selectedContracts.length} contract(s) as 'Signed.'
                    </div>
                </div>
                <div className="modal-dialog-window-footer">
                    <Button
                        className="btn flat"
                        onClick={closeModal}
                        variant="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn btn-primary"
                        onClick={this.onSubmit}
                        disabled={submitting}
                    >
                        <span>Sign {selectedContracts.length} contract(s)</span>
                    </Button>
                </div>
            </div>
        );
    }
}

export default BulkSignModal;
