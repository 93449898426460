import {
    IPartyCorrectionReducer,
    SET_PARTY_CONTRACT_ID,
    IPartyCorrectionActionTypes,
    SET_PARTY_VERSION_ID,
    PartyCorrectionStatus,
    SET_PARTY_CORRECTION_STATUS,
    SET_HIGHLIGHTING,
} from './PartyCorrection-types';

const initialState: IPartyCorrectionReducer = {
    contractId: null!, // Fixme: null checks
    highlighting: false,
    status: PartyCorrectionStatus.LOADING,
    statusMessage: '',
    versionId: null!, // Fixme: null checks
};

const PartyCorrectionReducer = (
    state = initialState,
    action: IPartyCorrectionActionTypes
): IPartyCorrectionReducer => {
    switch (action.type) {
        case SET_PARTY_CONTRACT_ID:
            return {
                ...state,
                contractId: action.contractId,
            };
        case SET_PARTY_VERSION_ID:
            return {
                ...state,
                versionId: action.versionId,
            };
        case SET_PARTY_CORRECTION_STATUS:
            const { status, statusMessage } = action;

            return {
                ...state,
                status,
                statusMessage,
            };
        case SET_HIGHLIGHTING:
            return {
                ...state,
                highlighting: action.highlighting,
            };
        default:
            return state;
    }
};

export default PartyCorrectionReducer;
