import { Component } from 'react';

import {
    ISummaryPartiesProps,
    ISummaryPartiesState,
    partySortOrder,
} from './SummaryParties-types';

import { Edit } from '@mui/icons-material';
import './SummaryParties.scss';
import { getFirstPartyName } from '../../IssuesReport/IssuesReport-helpers';
import {
    IParty,
    PartiesType,
    PartyVariantType,
} from '../../Parties/Parties-types';
import { Link } from 'react-router-dom';
import { Tooltip } from '@thought-river/ui-components';

const { OWN_PARTIES, COUNTER_PARTIES } = PartiesType;

const { FORMAL } = PartyVariantType;

class SummaryParties extends Component<
    ISummaryPartiesProps,
    ISummaryPartiesState
> {
    renderParty(title: string, partyName: string, tooltipContent: string) {
        const { contract, selectedVersion } = this.props;

        const versionIsLocked = selectedVersion && !selectedVersion.isLatest;

        return (
            <div className="party-wrapper">
                <div className="party-header">{title}</div>
                <Tooltip title={tooltipContent}>
                    <Link
                        className={`edit-party-link ${
                            versionIsLocked ? 'disabled' : ''
                        }`}
                        to={{
                            pathname: `/stream/${contract.streamCode}/contract/${contract.id}/parties`,
                            // eslint-disable-next-line no-restricted-globals
                            state: { prevPath: location.pathname },
                        }}
                    >
                        <div className="party-name-wrapper" title="Edit party">
                            <div className="party-name">{partyName}</div>
                            <Edit className="edit-party-icon" />
                        </div>
                    </Link>
                </Tooltip>
            </div>
        );
    }

    sortParties(partyA: IParty, partyB: IParty) {
        return (
            partySortOrder[partyA.variantType] -
            partySortOrder[partyB.variantType]
        );
    }

    render() {
        const { selectedVersion } = this.props;

        const parties = selectedVersion?.parties ?? [];
        const firstOwnParty = getFirstPartyName(parties, OWN_PARTIES, FORMAL);
        const firstCounterParty = getFirstPartyName(
            parties,
            COUNTER_PARTIES,
            FORMAL
        );

        const ownParties = selectedVersion.parties
            .filter((party) => party.type === OWN_PARTIES)
            .sort(this.sortParties)
            .map((party) => party.name)
            .join('\r\n');

        const counterParties = selectedVersion.parties
            .filter((party) => party.type === COUNTER_PARTIES)
            .sort(this.sortParties)
            .map((party) => party.name)
            .join('\r\n');

        return (
            <div className="summary-parties">
                {this.renderParty('Own Party', firstOwnParty, ownParties)}
                {this.renderParty(
                    'Counterparty',
                    firstCounterParty,
                    counterParties
                )}
            </div>
        );
    }
}

export default SummaryParties;
