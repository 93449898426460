import { ValueType } from '../components/App/App-types';
import LegacyHTMLParser from './legacyHTMLParser';
import {
    ILexiblePropertyUpdateParams,
    ILinkUnlinkPropertyOptions,
    NO_VALUE,
    YES_VALUE,
} from './legacyHTMLParser-types';

export async function linkUnlinkPropertyParagraphs({
    code: dfCode,
    unlink,
    unlinkValue,
    linkValue,
    removedParagraphs,
    newParagraphs,
}: ILinkUnlinkPropertyOptions) {
    const legacyHtmlParser = LegacyHTMLParser.getInstance();

    const updateParams: ILexiblePropertyUpdateParams[] = [];

    if (unlink) {
        updateParams.push({
            dfCode,
            value: unlinkValue,
        });
        await legacyHtmlParser.editLexibleProperties(updateParams);
    } else {
        for (const paragraph of newParagraphs) {
            updateParams.push({
                dfCode,
                value: linkValue,
                paragraphIndex: String(paragraph.index),
            });
        }

        for (const paragraph of removedParagraphs) {
            updateParams.push({
                dfCode,
                value: unlinkValue,
                paragraphIndex: String(paragraph.index),
            });
        }

        await legacyHtmlParser.editLexibleProperties(updateParams);
    }
}

export function resolvePropertyValue(
    valueType: ValueType,
    linkedParagraphsCount: number,
    fieldType: 'value' | 'answer',
    entityPropertyValue: string
) {
    let value = null;

    if (
        valueType === ValueType.DISCRETE ||
        valueType === ValueType.YES_NO_CAT
    ) {
        value =
            linkedParagraphsCount === 0
                ? fieldType === 'value'
                    ? NO_VALUE
                    : 'No'
                : fieldType === 'value'
                ? YES_VALUE
                : 'Yes';
    } else if (linkedParagraphsCount > 0) {
        value = entityPropertyValue;
    }

    return value;
}
