import { ReportColumn } from '@thought-river/negotiations-common/dist/api/contractContent';
import { ColumnIcon } from '@modules/reports/common/components/ColumnIcon';
import { reportColumnLabels } from '@modules/reports/portfolioReports';

interface FilterLabelProps {
    columnId: ReportColumn | string;
}

const FilterLabel = ({ columnId }: FilterLabelProps) => (
    <>
        <ColumnIcon column={columnId} />
        {reportColumnLabels[columnId]}
    </>
);

export default FilterLabel;
