import {
    ICategory,
    IApiLexibleTheme,
    IPropertyRiskMeta,
    IRelationshipData,
    IUser,
    IPolicy,
    IFeedback,
    IRelatedProperty,
} from 'types/thoughtriver';
import {
    IAssessment,
    IContractData,
    IParagraph,
    IProperty,
    IVersionData,
    IIssueData,
} from 'types/thoughtriver';
import {
    IHtmlParagraph,
    IRedlineParagraphCollection,
} from '../../services/documentParser-types';
import { IAccountUser } from '../Auth/Auth-types';
import {
    calculateDaysElapsed,
    paragraphIsResolved,
} from '../Contract/Contract-helpers';
import {
    AssessmentType,
    BusinessStatus,
    IContractAssesssment,
    IContractFeedback,
    IContractPolicy,
    IContractProperty,
    IContractRelatedProperty,
    IContractVersion,
    IDisplayContract,
    IVersionBusinessStatus,
    TYPE_CONTRACT,
    TYPE_VERSION,
    UploadStatus,
} from '../Contract/Contract-types';
import {
    IIssueCategory,
    IssueCreationType,
    IssueStateInVersion,
} from '../IssueListPane/IssueListPane-types';
import {
    defaultInsights,
    IContractParagraph,
} from '../Paragraph/Paragraph-types';
import { mapPartiesToOverrides } from '../Parties/Parties-helpers';
import { PartiesType, PartyVariantType } from '../Parties/Parties-types';
import {
    ValueTypesDictionary,
    ILexibleProperty,
    ValueType,
    getFamiliarityLevel,
    NONE_IDENTIFIED_OPTION,
} from './App-types';
import {
    removeInvalidCharacters,
    formatDate,
    findRelatedParagraphIds,
    findNearestPositiveAncestor,
    formatParagraphIndex,
} from './App-helpers';
import { PropertyOut } from '@thought-river/negotiations-common/dist/api/playbookManager';
import { ContractInfo } from '@thought-river/negotiations-common/dist/api/contractContent';
import lodash from 'lodash';
import dayjs from 'dayjs';
import {
    ContractIssue,
    FamiliarityLevel,
    FamiliarityType,
    IssueSeverityLevel,
    IssueStatus,
    LexibleTheme,
    getFamiliarityLevelLabel,
    ParagraphClause,
} from '@thought-river/negotiations-common';
import { BusinessStatusItem } from '@thought-river/negotiations-common/dist/api/contractManagement';

const { CLOSED, NEW, UNCHANGED, UPDATED } = IssueStateInVersion;

function isNullOrUndefined(value: unknown): value is undefined | null {
    return typeof value === 'undefined' || value === null;
}

function resolveStateInVersionLabel(
    stateInVersion: IssueStateInVersion,
    versionNumber: number,
    creationType: IssueCreationType,
    severityLevel: IssueSeverityLevel
): IssueStateInVersion | '' {
    return [NEW, UPDATED, CLOSED].includes(stateInVersion) &&
        severityLevel !== IssueSeverityLevel.UNSCORED &&
        (versionNumber > 1 || creationType === IssueCreationType.MANUAL)
        ? stateInVersion === CLOSED
            ? UPDATED
            : stateInVersion
        : '';
}

export function mapAssessment(assessment: IAssessment): IContractAssesssment {
    const familiarity = assessment.attributes.familiarity;

    return {
        id: assessment.id,
        type: assessment.attributes['assessment-type'] as AssessmentType,
        familiarity: {
            [FamiliarityType.ORGANIZATION]: {
                [FamiliarityLevel.UNFAMILIAR]:
                    familiarity?.paragraphTotals.neverSeenBefore ?? 0,
                [FamiliarityLevel.TEMPLATE]:
                    familiarity?.paragraphTotals.partOfOurTemplate ?? 0,
                [FamiliarityLevel.COMMON]:
                    familiarity?.paragraphTotals.common ?? 0,
                [FamiliarityLevel.UNCOMMON]:
                    familiarity?.paragraphTotals.uncommon ?? 0,
            },
            [FamiliarityType.MARKET]: {
                [FamiliarityLevel.UNFAMILIAR]:
                    familiarity?.marketTotals.neverSeenBefore ?? 0,
                [FamiliarityLevel.TEMPLATE]:
                    familiarity?.marketTotals.partOfOurTemplate ?? 0,
                [FamiliarityLevel.COMMON]:
                    familiarity?.marketTotals.common ?? 0,
                [FamiliarityLevel.UNCOMMON]:
                    familiarity?.marketTotals.uncommon ?? 0,
            },
            totalOrganization: familiarity
                ? familiarity.totals.contractsInSignedArchive
                : 0,
            totalMarket: familiarity
                ? familiarity.totals.contractsInMarketArchive
                : 0,
        },
    };
}

export function mapBusinessStatusLabel(
    label: string = BusinessStatus.RECEIVED
): BusinessStatus {
    const lowercaseLabel = label.toLowerCase();
    return lowercaseLabel === 'signed/executed'
        ? BusinessStatus.SIGNED
        : (lowercaseLabel as BusinessStatus);
}

export function mapBusinessStatus(
    status: BusinessStatusItem
): IVersionBusinessStatus {
    const label = mapBusinessStatusLabel(status.attributes.description);

    return {
        id: status.id,
        label,
    };
}

export function mapCategory(category: ICategory): IIssueCategory {
    return {
        id: category.id,
        name: category.attributes.name,
    };
}

export function mapClauseNumber(
    paragraph: IContractParagraph
): ParagraphClause {
    return {
        clauseNumber: paragraph.clauseNumber,
        id: paragraph.id,
        index: paragraph.index,
        marketFrequency: paragraph.marketFrequency,
        organisationFamiliarity: paragraph.organisationFamiliarity,
        originUuid: paragraph.originUuid,
    };
}

export function mapContract(
    contract: IContractData,
    versions: IContractVersion[] = [],
    status?: ContractInfo
): IDisplayContract {
    const streamCode = contract.attributes.stream ?? '';
    const streamId = contract.attributes.streamId ?? '';
    const totalIssues = contract.attributes.totalIssues;

    return {
        contractName: contract.attributes.name,
        createdAt: contract.attributes.createdOn!, // Fixme: null checks
        id: contract.id,
        latestVersion: versions?.[versions.length - 1],
        negotiatorId: contract.attributes.negotiatorId!, // Fixme: null checks
        reviewerIds: contract.attributes.reviewerIds ?? [],
        reprocessRequired:
            !streamCode || !streamId || isNullOrUndefined(totalIssues),
        streamCode,
        streamId,
        totalIssues: totalIssues ?? 0,
        totalClosedIssues: contract.attributes.totalClosedIssues ?? 0,
        type: TYPE_CONTRACT,
        uploadType: contract.attributes.uploadType,
        versions: versions ?? [],
        versionIds:
            contract.relationships.versions?.data?.map(
                (version: IRelationshipData) => version.id
            ) ?? [],
        isTemplate: Boolean(status?.template),
        isSigned: Boolean(status?.signed),
    };
}

export function mapLexibleTheme(theme: IApiLexibleTheme): LexibleTheme {
    return {
        id: theme.id,
        name: theme.attributes.name,
        nonAssessedId: theme.id,
    };
}

export function mapIssue(
    issue: IIssueData,
    clauseNumbers: ParagraphClause[],
    contractProperties: IContractProperty[],
    paragraphs: IContractParagraph[],
    lexibleProperties: ILexibleProperty[],
    contractCategories: IIssueCategory[],
    themes: LexibleTheme[],
    selectedContractVersion: IContractVersion
): ContractIssue {
    const nonAssessedThemeIds = (
        issue.relationships['lexible-themes']?.data ?? []
    ).map((theme) => theme.id);
    const versionId = issue.relationships.version?.data?.id;
    const paragraphOriginUuids = (
        issue.attributes['paragraph-origin-uuids'] ?? []
    ).filter((uuid) => uuid);
    const missingParagraphOriginUuids = (
        issue.attributes['missing-paragraph-origin-uuids'] ?? []
    ).filter((uuid) => uuid);
    const categoryMap = contractCategories.reduce((categoryMap, category) => {
        categoryMap[category.id] = category;
        return categoryMap;
    }, {} as { [categoryId: string]: IIssueCategory });
    const categories = (issue.relationships.categories?.data ?? []).map(
        (category) => categoryMap[category.id]
    );
    const issueCreatedDate = issue.attributes['date-created'];
    const updatedAt = issue.attributes['date-modified'] ?? issueCreatedDate;
    const severityLevel = (issue.attributes['severity-level']?.toLowerCase() ??
        IssueSeverityLevel.UNSCORED) as IssueSeverityLevel;
    const creationType =
        issue.attributes['creation-type'] ?? IssueCreationType.AUTO;
    const stateInVersion = issue.attributes['state-in-version'] ?? UNCHANGED;

    const stateLabel = resolveStateInVersionLabel(
        stateInVersion,
        selectedContractVersion.versionNumber,
        creationType,
        severityLevel
    );

    const sortedClauseNumbers = clauseNumbers.sort(
        (clauseA, clauseB) => clauseA.index - clauseB.index
    );

    const issueClauseNumbers = sortedClauseNumbers.filter((clause) =>
        paragraphOriginUuids.includes(clause.originUuid)
    );

    //Using the spread operator here as we want to preserve the original clause sort order
    const lowestOrgFrequencyClause = [...issueClauseNumbers].sort(
        (clauseA, clauseB) =>
            clauseA.organisationFamiliarity.frequency -
            clauseB.organisationFamiliarity.frequency
    )[0];

    let organizationFamiliarity = null;
    if (lowestOrgFrequencyClause) {
        organizationFamiliarity = {
            frequency:
                lowestOrgFrequencyClause.organisationFamiliarity.frequency,
            frequencyLevel:
                lowestOrgFrequencyClause.organisationFamiliarity.frequencyLevel,
            frequencyLabel:
                lowestOrgFrequencyClause.organisationFamiliarity
                    .frequencyLabel || '',
        };
    }

    const propertyCode =
        issue.relationships['lexible-properties']?.data[0]?.meta.code ?? null;
    const parentId = lexibleProperties.find(
        (p) => p.code === propertyCode
    )?.parentId; //need an optional chaining operator for the unit tests - couldn't figure out how to make it pass as the find always returns undefined
    const nearestPositiveAncestorProperty = findNearestPositiveAncestor(
        lexibleProperties,
        contractProperties,
        parentId! // Fixme: null checks
    );
    const relatedParagraphIds = findRelatedParagraphIds(
        nearestPositiveAncestorProperty,
        paragraphs,
        nonAssessedThemeIds[0]
    );

    return {
        categories,
        clauseNumbers: issueClauseNumbers,
        createdAt: dayjs(issueCreatedDate ?? dayjs()).format(),
        creationType,
        description: issue.attributes.description ?? '',
        id: issue.id,
        isNew: [NEW, UPDATED].includes(stateInVersion),
        lastModifiedById: issue.relationships['last-modified-by']?.data?.id,
        missingSuggestionLocations: sortedClauseNumbers.filter((clause) =>
            missingParagraphOriginUuids.includes(clause.originUuid)
        ),
        nonAssessedThemes: themes.filter((theme) =>
            nonAssessedThemeIds.includes(theme.id)
        ),
        nonAssessedThemeIds,
        notes: issue.attributes.notes ?? '',
        lexiblePropertyId:
            issue.relationships['lexible-properties']?.data[0]?.id ?? null,
        organizationFamiliarity,
        paragraphOriginUuids,
        paragraphIds: paragraphs
            .filter((p) => paragraphOriginUuids.includes(p.originUuid))
            .map((p) => p.id),
        propertyCode,
        public: issue.attributes['is-public'] ?? false,
        relatedParagraphIds,
        reviewerIds:
            issue.relationships.reviewers?.data?.map(
                (reviewer) => reviewer.id
            ) ?? [],
        severityLevel,
        stateInVersion,
        stateLabel,
        status: issue.attributes.status ?? IssueStatus.OPEN,
        title: issue.attributes.title ?? '',
        updatedAt: dayjs(updatedAt ?? dayjs()).format(),
        versionId,
    };
}

export function mapLexibleProperty(property: PropertyOut): ILexibleProperty {
    return {
        code: property.dfcode,
        description: property.question
            ? removeInvalidCharacters(property.question)
            : '',
        id: property.uuid!, // Fixme: null checks
        name: property.name!, // Fixme: null checks
        parentId: property.parent_uuid!, // Fixme: null checks
        themeId: property.theme?.uuid!, // Fixme: null checks
        themeName: property.theme?.name!, // Fixme: null checks
        valueType: property.value_type as ValueType,
        shortLabel: property.short_label!, // Fixme: null checks
    };
}

export function mapRawClauseNumber(
    paragraph: IParagraph,
    index: number
): ParagraphClause {
    const insights = paragraph.attributes.insights ?? defaultInsights;
    const frequencyLevel = getFamiliarityLevel(
        insights?.familiarity?.paragraphCommonality
    );

    return {
        clauseNumber:
            paragraph.attributes.numbering?.['clause-number'] ||
            formatParagraphIndex(index),
        id: paragraph.id,
        index,
        marketFrequency: insights.market?.similarProvision ?? 0,
        organisationFamiliarity: {
            frequency: insights?.familiarity?.similarProvision ?? 0,
            frequencyLevel: frequencyLevel!, // Fixme: null checks
            frequencyLabel: getFamiliarityLevelLabel(frequencyLevel!), // Fixme: null checks
        },
        originUuid: paragraph.attributes['origin-uuid'],
    };
}

export function mapParagraph(
    paragraph: IParagraph,
    properties: IContractProperty[] = [],
    index: number,
    htmlParagraph: IHtmlParagraph | null = null,
    issues: ContractIssue[] = [],
    redlinedParagraphs: IRedlineParagraphCollection = {}
): IContractParagraph {
    const propertyIds =
        paragraph.relationships.properties?.data?.map(
            (property) => property.id
        ) ?? [];
    const paragraphProperties = properties.filter((property) =>
        propertyIds.includes(property.id)
    );
    const location = parseInt(paragraph.attributes.index, 10);
    const insights = paragraph.attributes.insights ?? defaultInsights;
    const redlinedText =
        redlinedParagraphs[location] ?? htmlParagraph?.text ?? '';
    const frequencyLevel = getFamiliarityLevel(
        insights?.familiarity?.paragraphCommonality! // Fixme: null checks
    );

    return {
        charCount: paragraph.attributes.content?.length ?? 0,
        clauseNumber:
            paragraph.attributes?.numbering?.['clause-number'] ||
            formatParagraphIndex(index),
        id: paragraph.id,
        index,
        issues,
        location,
        marketFrequency: insights?.market?.similarProvision ?? 0,
        organisationFamiliarity: {
            frequency: insights?.familiarity?.similarProvision ?? 0,
            frequencyLevel: frequencyLevel!, // Fixme: null checks
            frequencyLabel: getFamiliarityLevelLabel(frequencyLevel!), // Fixme: null checks
        },
        originUuid: paragraph.attributes['origin-uuid'],
        properties: paragraphProperties,
        propertyIds,
        redlinedText,
        html: htmlParagraph?.html ?? '',
        classes: htmlParagraph?.classes ?? '',
        resolved: paragraphIsResolved(issues),
        reviewed: paragraph.attributes.reviewed ?? false,
        text: paragraph.attributes.content,
        themeIds: paragraphProperties.map(
            (property) => property.nonAssessedThemeId
        ),
        // To be deleted with the legacy Docx parser
        table: htmlParagraph?.tableId
            ? {
                  cell: htmlParagraph.cell!, // Fixme: null checks
                  cellsInRow: htmlParagraph.cellsInRow,
                  colSpan: htmlParagraph.colSpan!, // Fixme: null checks
                  row: htmlParagraph.row!, // Fixme: null checks
                  rowSpan: htmlParagraph.rowSpan!, // Fixme: null checks
                  tableId: htmlParagraph.tableId,
                  totalParagraphsInTable:
                      htmlParagraph.totalParagraphsInTable ?? 0,
                  prevEmptyCells: htmlParagraph.prevEmptyCells,
                  nextEmptyCells: htmlParagraph.nextEmptyCells,
              }
            : null,
        wordId: paragraph.attributes['word-paragraph-id'],
    };
}

export function mapPolicy(policy: IPolicy): IContractPolicy {
    return {
        description: policy.attributes.description,
        name: policy.attributes.name,
        propertyIds:
            policy.relationships?.properties?.data?.map(
                (property) => property.id
            ) ?? [],
        isGlobal: policy.attributes['is-global'],
    };
}

export function mapFeedback(feedback: IFeedback): IContractFeedback {
    return {
        vote: feedback?.meta?.vote ?? null,
        userId: feedback?.meta?.userId ?? null,
    };
}

export function mapRelatedProperty(
    relatedProperty: IRelatedProperty
): IContractRelatedProperty {
    return {
        id: relatedProperty.meta?.['property-id'] ?? '',
        lexiblePropertyId: relatedProperty.id,
        question: relatedProperty.meta?.question ?? '',
        answer: relatedProperty.meta?.answer ?? '',
        code: relatedProperty.meta?.dfcode ?? '',
    };
}

const dedupeValue = (value: string) => {
    const values = lodash.uniq(value.split(', '));
    if (values.length > 1 && values.includes(NONE_IDENTIFIED_OPTION)) {
        return lodash.without(values, NONE_IDENTIFIED_OPTION).join(', ');
    }
    return values.join(', ');
};

export function mapProperty(
    property: IProperty,
    themes: LexibleTheme[],
    paragraphsDictionary: { [paragraphId: string]: string }
): IContractProperty {
    const risk =
        property.relationships.risk?.data?.meta ??
        ({
            advice: undefined,
            label: undefined,
        } as IPropertyRiskMeta);
    const nonAssessedPropertyId =
        property.relationships['lexible-property']?.data.id;
    const themeName = property.attributes.theme;
    const nonAssessedTheme = themes.find((theme) => theme.name === themeName);
    const paragraphs = property.relationships.paragraphs?.data ?? [];
    const paragraphIds = paragraphs.map((paragraph) => paragraph.id);
    const severityLevel = (
        (risk['risk-score'] as IssueSeverityLevel) ??
        IssueSeverityLevel.UNSCORED
    ).toLowerCase() as IssueSeverityLevel;
    const propertyName = property.attributes.name;
    const valueType =
        ValueTypesDictionary[`${property.attributes['value-type-id']}`] ??
        ValueType.DISCRETE;
    let value = String(property.attributes.value);

    if (valueType === ValueType.DATE) {
        value = value ? formatDate(value) : value;
    }

    return {
        autodetectedValue: property.attributes['autodetected-value']
            ? dedupeValue(property.attributes['autodetected-value'])
            : undefined!, // Fixme: null checks
        code: property.attributes.code,
        question: property.attributes.description,
        feedback: property.relationships.feedbacks?.data.map(mapFeedback) ?? [],
        helpText: property.attributes['help-text'],
        id: property.id,
        label: risk.label ?? propertyName,
        name: propertyName,
        nonAssessedPropertyId,
        nonAssessedThemeId: nonAssessedTheme?.nonAssessedId!, // Fixme: null checks
        paragraphIds,
        paragraphOriginUuids: paragraphIds.map(
            (paragraphId) => paragraphsDictionary[paragraphId]
        ),
        relatedProperties:
            property.relationships['related-properties']?.data.map(
                mapRelatedProperty
            ) ?? [],
        relevantForContract:
            paragraphIds.length > 0 ||
            severityLevel !== IssueSeverityLevel.UNSCORED,
        severityLevel,
        shortLabel: property.attributes['short-label'] ?? propertyName,
        theme: {
            id: nonAssessedTheme?.id!, // Fixme: null checks
            name: themeName,
        },
        userValue: property.attributes['user-value'],
        value: dedupeValue(value),
        valueType,
    };
}

export function mapUser(user: IUser): IAccountUser {
    const { attributes } = user;

    return {
        id: user.id,
        firstName: attributes.firstName ?? '',
        lastName: attributes.lastName ?? '',
        email: attributes.email,
        streamCodes: user.relationships.accounts.data.map(
            (data) => data.meta.code
        ),
    };
}

export function mapVersion(
    version: IVersionData,
    index: number = 0,
    updatedAssessmentIds: string[] = []
): IContractVersion {
    const parties = mapPartiesToOverrides(
        version.attributes.parties ?? {
            'excluded-terms': [],
            'reciprocal-reference-names': [],
            'own-parties': [],
            'counter-parties': [],
        }
    );
    const businessStatusLabel = mapBusinessStatusLabel(
        version.attributes.businessStatus?.description ??
            BusinessStatus.RECEIVED
    );
    const updatedAt = version.attributes.businessStatus?.modified
        ? dayjs(version.attributes.businessStatus?.modified)
        : dayjs().subtract(index + 1, 'days');
    const document = version.relationships?.documents?.data?.slice(-1)[0];
    const isLatest = version.attributes['is-latest'];
    const assessmentIds =
        version.relationships?.assessments?.data?.map(
            (assessment) => assessment.id
        ) ?? [];

    return {
        assessmentIds:
            isLatest && assessmentIds.length < updatedAssessmentIds.length
                ? updatedAssessmentIds
                : assessmentIds,
        businessStatus: businessStatusLabel,
        contractId: version.relationships?.contract?.data?.id,
        counterParty:
            parties.filter(
                (party) =>
                    party.type === PartiesType.COUNTER_PARTIES &&
                    party.variantType === PartyVariantType.FORMAL
            )[0]?.name ?? '',
        createdAt: dayjs(version.attributes.created ?? dayjs()).format(),
        daysElapsed: calculateDaysElapsed(dayjs(), updatedAt),
        documentId: document?.id,
        fileName: document?.meta?.name ?? '',
        formattedUpdatedAt: updatedAt.format('D MMM YYYY'),
        id: version.id,
        isLatest: version.attributes['is-latest'],
        ownParty:
            parties.filter(
                (party) =>
                    party.type === PartiesType.OWN_PARTIES &&
                    party.variantType === PartyVariantType.FORMAL
            )[0]?.name ?? '',
        parties,
        partiesOverridden: version.attributes['parties-overridden'] ?? false,
        type: TYPE_VERSION,
        updatedAt: updatedAt.format(),
        uploadStatus: (version.attributes.status?.state as UploadStatus) ?? '',
        uploadStatusDescription: version.attributes.status?.description ?? '',
        versionNumber: version.attributes.number ?? index + 1,
        requirePartyConfirmation:
            version.attributes['require-party-confirmation'] ?? null!, // Fixme: null checks
    };
}
