import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';

import { ISummaryPaneProps } from './SummaryPane-types';

import IntelligenceBars from '../IntelligenceBars/IntelligenceBars';
import './SummaryPane.scss';
import SummaryParties from './SummaryParties/SummaryParties';
import SplashLoader from '../SplashLoader/SplashLoader';
import SummaryProperties from './SummaryProperties/SummaryProperties-container';
import {
    IContractProperty,
    ISelectedContractIntelligence,
} from '../Contract/Contract-types';
import PropertyDetails from '../PropertyDetails/PropertyDetails';
import {
    useCreateSummaryPanelTemplate,
    useUpdateSummaryPanelTemplate,
    useSetDefaultSummaryPanelTemplateForDealType,
    useDealTypeUnassignSummaryPanelTemplates,
    useDealTypeAssignSummaryPanelTemplates,
    SummaryPanelTemplateIn,
    SummaryPanelTemplateOut,
} from '@thought-river/negotiations-common/dist/api/playbookManager';
import { toast } from 'react-toastify';
import { BannerType } from '../HeatmapPane/HeatmapPane-types';
import { getSummaryProperties } from '../App/App-helpers';
import { getHighestSeverityFromIssues } from '../Paragraph/Paragraph-helpers';
import { Button } from '@thought-river/ui-components';
import { ErrorBoundary } from '@modules/common/components/ErrorBoundary';
import {
    ChecklistSelect,
    EditChecklistsSidebar,
    FamiliarityLevel,
} from '@thought-river/negotiations-common';

const SummaryPane = (props: ISummaryPaneProps) => {
    const paneRef: React.RefObject<any> = React.createRef();

    const {
        assessment,
        contract,
        featureToggles,
        highlightedText,
        issues,
        lexibleProperties,
        onReprocess,
        paragraphs,
        properties,
        themes,
        policies,
        propertyTemplates,
        selectedContractIntelligence,
        selectedPropertyCode,
        selectedPropertiesTemplate,
        selectedVersion,
        setBannerSettings,
        setHighlightedText,
        setHighlightedParagraphs,
        setPropertyTemplates,
        setSelectedContractIntelligence,
        setSelectedPropertiesTemplate,
        setSelectedPropertyCode,
        setSummaryProperties,
        summaryProperties,
        width,
    } = props;

    const [newPropertyCodes, setNewPropertyCodes] = useState<string[]>([]);
    const [isPropertyDetailsOpen, setIsPropertyDetailsOpen] = useState(false);
    const [isEditChecklistsOpen, setIsEditChecklistsOpen] = useState(false);
    const [widthPixels, setWidthPixels] = useState(0);

    const { mutateAsync: createTemplate } = useCreateSummaryPanelTemplate({});
    const { mutateAsync: updateTemplate } = useUpdateSummaryPanelTemplate({});
    const { mutateAsync: softDeleteTemplate } =
        useDealTypeUnassignSummaryPanelTemplates({});
    const { mutateAsync: linkTemplateToStream } =
        useDealTypeAssignSummaryPanelTemplates({});
    const { mutateAsync: setDefaultTemplate } =
        useSetDefaultSummaryPanelTemplateForDealType({});

    useEffect(() => {
        setWidthPixels(paneRef.current.clientWidth);

        return () => {
            window.removeEventListener('resize', onWindowResize);
            setSelectedPropertyCode(null!); // Fixme: null checks

            if (highlightedText) {
                setHighlightedText('');
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setWidthPixels(paneRef.current.clientWidth);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    useEffect(() => {
        window.addEventListener('resize', onWindowResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const memoizedProperties = useMemo(
        () => summaryProperties.map((property) => property.code),
        [summaryProperties]
    );

    if (!selectedVersion) {
        return null;
    }

    const onWindowResize = () => {
        if (paneRef.current) {
            setWidthPixels(paneRef.current.clientWidth);
        }
    };

    const callLinkTemplateToStream = (template: SummaryPanelTemplateOut) => {
        linkTemplateToStream(
            {
                body: [
                    {
                        id: template.id_str ?? '',
                    },
                ],
                pathParams: {
                    dealTypeIdOrUuid: contract.streamId,
                },
            },
            {
                onSuccess: () => handleTemplateSaved(template),
            }
        );
    };

    const handleCreateTemplate = async (
        name: string,
        properties: string[],
        description?: string
    ) => {
        const requestBody: SummaryPanelTemplateIn = {
            name,
            description,
            properties: properties.map((property, order) => ({
                dfcode: property,
                order,
            })),
        };

        await createTemplate(
            { body: requestBody },
            {
                onSuccess: (template) => {
                    callLinkTemplateToStream(template);
                },
                onError: (error) => {
                    toast.error(error?.payload || 'Unknown error');
                },
            }
        );
    };

    const handleSaveTemplate = async (
        id: string,
        name: string,
        properties: string[],
        description?: string
    ) => {
        let highestOrder = selectedPropertiesTemplate.properties.length
            ? Math.max(
                  ...selectedPropertiesTemplate.properties.map(
                      (p) => p.order ?? 0
                  )
              )
            : 0;

        const updatedProperties = properties.map((property) => {
            const existingProperty = selectedPropertiesTemplate.properties.find(
                (p) => p.dfcode === property
            );
            if (existingProperty) {
                return {
                    dfcode: existingProperty.dfcode ?? '',
                    order: existingProperty.order ?? 0,
                };
            } else {
                highestOrder++;
                return { dfcode: property, order: highestOrder };
            }
        });

        const requestBody: SummaryPanelTemplateIn = {
            name,
            description,
            properties: updatedProperties,
        };

        await updateTemplate(
            {
                body: requestBody,
                pathParams: {
                    summaryPanelTemplateId: id,
                },
            },
            {
                onSuccess: (template) => {
                    handleTemplateSaved(template);
                },
                onError: (error) => {
                    toast.error(error.payload);
                },
            }
        );
    };

    const handleTemplateSaved = (template: SummaryPanelTemplateOut) => {
        const newTemplate = !propertyTemplates.find(
            (t) => t.id_str === template.id_str
        );

        if (newTemplate) {
            setPropertyTemplates([...propertyTemplates, template]);

            // It appears that our backend asynchronously links a template to a deal type,
            // which means if we try to set a new template as the default right away, the request will fail
            // because the relationship does not exist yet
            setTimeout(() => {
                updateDefaultTemplate(template.id_str ?? '');
            }, 500);
        } else {
            setPropertyTemplates(
                propertyTemplates.map((t) =>
                    t.id_str === template.id_str ? template : t
                )
            );
        }

        setSelectedPropertiesTemplate(template);
        setIsEditChecklistsOpen(false);

        const newPropertyCodes = [];
        let propertyMissingFromAssessment = false;

        for (const property of template.properties) {
            const code = property.dfcode;
            const summaryProperty = summaryProperties.find(
                (sp) => sp.code === code
            );

            if (!summaryProperty) {
                newPropertyCodes.push(property.dfcode!); // Fixme: null checks

                if (!properties.find((p) => p.code === property.dfcode)) {
                    propertyMissingFromAssessment = true;
                }
            }
        }

        const updatedSummaryProperties = getSummaryProperties({
            lexibleProperties,
            paragraphs,
            properties,
            templateProperties: template.properties,
        });

        setNewPropertyCodes(newPropertyCodes);

        setSummaryProperties(updatedSummaryProperties);

        if (propertyMissingFromAssessment) {
            setBannerSettings({
                type: BannerType.REPROCESS_REQUIRED,
                clauseType: null!, // Fixme: null checks
            });
        }
    };

    const handleDeleteTemplate = async (templateId: string) => {
        await softDeleteTemplate(
            {
                pathParams: {
                    dealTypeIdOrUuid: contract.streamId,
                },
                body: [
                    {
                        id: templateId,
                    },
                ],
            },
            {
                onSuccess: () => {
                    const updatedTemplates = propertyTemplates.filter(
                        (t) => t.id_str !== templateId
                    );

                    if (selectedPropertiesTemplate?.id_str === templateId) {
                        setSelectedPropertiesTemplate(updatedTemplates[0]);
                    }

                    setPropertyTemplates(updatedTemplates);
                },
                onError: () => {
                    toast.error('Error deleting template');
                },
            }
        );
    };

    const updateDefaultTemplate = (templateId: string) => {
        setDefaultTemplate({
            body: {
                id: templateId,
            },
            pathParams: {
                dealTypeIdOrUuid: contract.streamId,
            },
        });
    };

    const onSelectPropertiesTemplate = (templateId: string) => {
        const selectedTemplate = propertyTemplates.find(
            (template) => template.id_str === templateId
        );

        setSelectedPropertiesTemplate(selectedTemplate!); // Fixme: null checks
        setBannerSettings(null!); // Fixme: null checks
        setSelectedPropertyCode(null!); // Fixme: null checks

        updateDefaultTemplate(templateId);

        setNewPropertyCodes([]);
    };

    const toggleEditChecklists = () => {
        setIsEditChecklistsOpen(!isEditChecklistsOpen);
    };

    const togglePropertyDetails = () => {
        setIsPropertyDetailsOpen(!isPropertyDetailsOpen);
    };

    const onContractIntelligenceChange = (
        intelligence: ISelectedContractIntelligence
    ) => {
        setBannerSettings(null!); // Fixme: null checks
        setHighlightedParagraphs([]);
        setSelectedPropertyCode(null!); // Fixme: null checks
        setSelectedContractIntelligence(intelligence);

        const items = [];
        if (
            intelligence.highlightType === FamiliarityLevel.UNFAMILIAR ||
            intelligence.highlightType === FamiliarityLevel.UNCOMMON ||
            intelligence.highlightType === FamiliarityLevel.COMMON ||
            intelligence.highlightType === FamiliarityLevel.TEMPLATE
        ) {
            items.push(
                ...paragraphs
                    .filter(
                        (p) =>
                            p.organisationFamiliarity.frequencyLevel ===
                            intelligence.highlightType
                    )
                    .map((p) => p.id)
            );
        } else {
            items.push(
                ...paragraphs
                    .filter(
                        (p) =>
                            getHighestSeverityFromIssues(p.issues) ===
                            intelligence.highlightType
                    )
                    .map((p) => p.id)
            );
        }

        if (intelligence.highlightType) {
            setBannerSettings({
                type: BannerType.INFO,
                clauseType: null!, // Fixme: null checks
                items,
            });
        }
    };

    const groupedContractProperties: { [code: string]: IContractProperty } = {};
    properties.forEach((property) => {
        groupedContractProperties[property.code] = property;
    });

    const selectedProperty = summaryProperties.find(
        (p) => p.code === selectedPropertyCode
    );

    return (
        <div className="summary-pane" ref={paneRef}>
            <div className="content">
                <div className="section">
                    <SummaryParties
                        contract={contract}
                        selectedVersion={selectedVersion}
                    />
                </div>

                <div className="section">
                    <IntelligenceBars
                        assessment={assessment}
                        featureToggles={featureToggles}
                        issues={issues}
                        selectedContractIntelligence={
                            selectedContractIntelligence
                        }
                        onContractIntelligenceChange={
                            onContractIntelligenceChange
                        }
                        contract={contract}
                    />
                </div>
                <div className="section summary-properties-section">
                    {selectedPropertiesTemplate && propertyTemplates.length && (
                        <div className="summary-properties-header">
                            <ChecklistSelect
                                disableEdit
                                onTemplateChange={onSelectPropertiesTemplate}
                                selectedTemplate={selectedPropertiesTemplate}
                                templates={propertyTemplates}
                                data-testid="sp-template-dropdown"
                            />
                            <Button
                                variant="secondary"
                                onClick={toggleEditChecklists}
                                data-testid="sp-edit-prop-btn"
                            >
                                Edit checklists
                            </Button>
                        </div>
                    )}
                    {selectedPropertiesTemplate?.properties.length &&
                    summaryProperties.length !== 0 ? (
                        propertyTemplates.length ? (
                            summaryProperties.length ? (
                                <ErrorBoundary>
                                    <SummaryProperties
                                        onReprocess={onReprocess}
                                        onTemplateUpdatedSuccessfully={
                                            handleTemplateSaved
                                        }
                                        toggleShowPropertyDetails={
                                            togglePropertyDetails
                                        }
                                        newPropertyCodes={newPropertyCodes}
                                    />
                                </ErrorBoundary>
                            ) : (
                                <span className="summary-properties-placeholder">
                                    No properties selected for template
                                </span>
                            )
                        ) : (
                            <span className="summary-properties-placeholder">
                                No templates configured
                            </span>
                        )
                    ) : (
                        <SplashLoader
                            message="Loading properties..."
                            size="small"
                        />
                    )}
                </div>
            </div>
            {selectedPropertiesTemplate && (
                <EditChecklistsSidebar
                    open={isEditChecklistsOpen}
                    selectedPropertyCodes={memoizedProperties}
                    onCancel={toggleEditChecklists}
                    onChecklistChange={onSelectPropertiesTemplate}
                    onChecklistSave={handleSaveTemplate}
                    onChecklistSaveNew={handleCreateTemplate}
                    onChecklistDelete={handleDeleteTemplate}
                    checklists={propertyTemplates}
                    selectedChecklist={selectedPropertiesTemplate}
                    width={widthPixels}
                    themes={themes}
                />
            )}
            <PropertyDetails
                policies={policies}
                property={selectedProperty!} // Fixme: null checks
                open={isPropertyDetailsOpen}
                width={widthPixels}
                onClose={togglePropertyDetails}
            />
        </div>
    );
};

export default SummaryPane;
