import { IRootState } from '../../../../../../redux/reducers-types';
import { reduxStore } from '../../../../../../redux/store';
import { analyticsService } from '../../../../../../services/Analytics/Analytics';
import { EVENT_ACTION_SUGGESTION_COPIED } from '../../../../../../services/Analytics/Analytics-types';

export function analytics() {
    return (target: any) => {
        const onCopySuggestion = target.prototype.onCopySuggestion;

        target.prototype.onCopySuggestion = function () {
            const { contract } = reduxStore.store.getState() as IRootState;

            analyticsService.recordEvent(
                EVENT_ACTION_SUGGESTION_COPIED,

                {
                    ContractId: contract.contract?.id,
                    Stream: contract.contract?.streamCode,
                    VersionId: contract.selectedVersion?.id,
                    VersionNumber: contract.selectedVersion?.versionNumber,
                    SuggestionId: this.props.suggestion.id,
                    IssueId: contract.selectedIssue?.id,
                    IssueCreationType: contract.selectedIssue?.creationType,
                    LegalThemes: contract.selectedIssue?.nonAssessedThemes.map(
                        (theme) => theme.id
                    ),
                }
            );

            onCopySuggestion.apply(this, arguments);
        };
    };
}
