import { Help } from '@mui/icons-material';
import { Tooltip } from '@thought-river/ui-components';
import React from 'react';
import { InputType, NONE_IDENTIFIED_OPTION } from '../../../App/App-types';
import dayjs from 'dayjs';
import { ISummaryProperty } from '../../SummaryPane-types';

interface HelperTextProps {
    handleAskNewQuestions: () => void;
    property: ISummaryProperty;
}

const HelpIcon = ({ message }: { message: React.ReactNode }) => (
    <Tooltip
        classes={{ tooltip: 'help-icon-tooltip' }}
        title={message}
        placement="top-end"
    >
        <Help />
    </Tooltip>
);

const HelperText = ({ handleAskNewQuestions, property }: HelperTextProps) => {
    if (property.reprocessRequired) {
        const message = (
            <div className="reprocess-tooltip-content">
                <div>
                    This question has not yet been asked of the contract because
                    it was added to the checklist after the last review request.
                </div>
                <div>
                    <span
                        className="link-text"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleAskNewQuestions();
                        }}
                    >
                        Ask New Questions
                    </span>
                </div>
            </div>
        );
        return <>Unasked Question {<HelpIcon message={message} />}</>;
    }

    if (
        property.inputType === InputType.DATE &&
        !dayjs(property.value).isValid() &&
        property.value !== NONE_IDENTIFIED_OPTION
    ) {
        const message =
            "Lexible found the answer but can't put it into the right format. Please review the identified clauses and add it manually";
        return <>Can't extract answer {<HelpIcon message={message} />}</>;
    }
    return <></>;
};

export default HelperText;
