import { useEffect, useRef, useState } from 'react';

import './SummaryProperty.scss';
import { ISummaryPropertyProps } from './SummaryProperty.types';
import {
    hyphenateLabel,
    propertyAnswerMissing,
} from '../../../App/App-helpers';
import { InputType, NONE_IDENTIFIED_OPTION } from '../../../App/App-types';
import {
    Badge,
    DEFAULT_DATE_FORMAT,
    DatePicker,
    Select,
    TextField,
    Tooltip,
} from '@thought-river/ui-components';
import { debounce, difference, throttle } from 'lodash';
import MultiSelect from './MultiSelect';
import HelperText from './HelperText';
import dayjs from 'dayjs';
import { Link } from '@mui/icons-material';
import {
    IssueSeverityLevel,
    getIssueSeverityLevelBadgeType,
    getIssueSeverityLevelLabel,
} from '@thought-river/negotiations-common';
import { LexiblePropertyDetailsButton } from '@modules/common/components/LexiblePropertyDetailsButton';

const NEW_PROPERTY_BADGE_SHOW_TIME = 6000000;

const clearOptionalTimeout = (timeoutId: NodeJS.Timeout | null) => {
    if (timeoutId !== null) {
        clearTimeout(timeoutId);
    }
};

const SummaryProperty = (props: ISummaryPropertyProps): JSX.Element => {
    const {
        isSelected,
        onChange,
        onClick,
        onDetailsIconClick,
        onSeverityBadgeClick,
        onReprocess,
        isNew,
        property,
    } = props;

    const {
        value,
        linkedParagraphIds,
        inputType,
        shortLabel,
        options,
        severityLevel,
        question,
        reprocessRequired,
    } = property;

    const inputDisabled = !property.linkedParagraphIds.length;

    const [currentValue, setCurrentValue] = useState(value);
    const [showUpdatedAnimation, setShowUpdatedAnimation] = useState(false);
    const [showUpdatedBadge, setShowUpdatedBadge] = useState(false);
    const [
        showRemovedLinkedParagraphsBadge,
        setShowRemovedLinkedParagraphsBadge,
    ] = useState(false);
    const [showNewLinkedParagraphsBadge, setShowNewLinkedParagraphsBadge] =
        useState(false);
    const [originalLinkedParagraphIds, setOriginalLinkedParagraphIds] =
        useState(linkedParagraphIds);
    const [currentLinkedParagraphIds, setCurrentLinkedParagraphIds] =
        useState(linkedParagraphIds);
    const [overallNewLinkedParagraphIds, setOverallNewLinkedParagraphIds] =
        useState<string[]>([]);
    const [newLinkedParagraphIds, setNewLinkedParagraphIds] = useState<
        string[]
    >([]);
    const [
        overallRemovedLinkedParagraphIds,
        setOverallRemovedLinkedParagraphIds,
    ] = useState<string[]>([]);
    const [removedLinkedParagraphIds, setRemovedLinkedParagraphIds] = useState<
        string[]
    >([]);
    const [showNewPropertyBadge, setShowNewPropertyBadge] = useState(false);

    const [timeoutA, setTimeoutA] = useState<null | ReturnType<
        typeof setTimeout
    >>(null);
    const [timeoutB, setTimeoutB] = useState<null | ReturnType<
        typeof setTimeout
    >>(null);
    const [timeoutC, setTimeoutC] = useState<null | ReturnType<
        typeof setTimeout
    >>(null);
    const [timeoutD, setTimeoutD] = useState<null | ReturnType<
        typeof setTimeout
    >>(null);
    const [timeoutE, setTimeoutE] = useState<null | ReturnType<
        typeof setTimeout
    >>(null);
    const [selected, setSelected] = useState(isSelected);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    useEffect(() => {
        if (currentValue !== value) {
            if (inputType === InputType.TEXT) {
                debounce((_) => {
                    throttledStartUpdatedAnimation.current();
                }, 3000);
            } else {
                throttledStartUpdatedAnimation.current();
            }
        }
    }, [value, currentValue, inputType]);

    useEffect(() => {
        if (!selected && isSelected) {
            setSelected(true);
            setOriginalLinkedParagraphIds(linkedParagraphIds);
        } else if (selected && !isSelected) {
            setSelected(false);
        }
    }, [isSelected, selected, linkedParagraphIds]);

    useEffect(() => {
        if (isNew && isNew !== showNewPropertyBadge) {
            setShowNewPropertyBadge(true);

            setTimeout(() => {
                setShowNewPropertyBadge(false);
            }, NEW_PROPERTY_BADGE_SHOW_TIME);
        }
    }, [isNew, showNewPropertyBadge]);

    useEffect(() => {
        const linkedSinceSelected = difference(
            linkedParagraphIds,
            originalLinkedParagraphIds
        );
        const linkedSinceLastChange = difference(
            linkedParagraphIds,
            currentLinkedParagraphIds
        );
        const removedSinceSelected = difference(
            originalLinkedParagraphIds,
            linkedParagraphIds
        );
        setCurrentLinkedParagraphIds(linkedParagraphIds);
        setOverallNewLinkedParagraphIds(linkedSinceSelected);
        setOverallRemovedLinkedParagraphIds(removedSinceSelected);

        if (linkedSinceLastChange.length) {
            setNewLinkedParagraphIds(linkedSinceLastChange);
            setShowNewLinkedParagraphsBadge(false);
            clearOptionalTimeout(timeoutD);

            setTimeout(() => {
                // the outer timeout is used to ensure the animation properly resets
                setShowNewLinkedParagraphsBadge(true);

                setTimeoutD(
                    setTimeout(() => {
                        setShowNewLinkedParagraphsBadge(false);
                    }, 6000)
                );
            }, 0);
        }
    }, [
        linkedParagraphIds,
        currentLinkedParagraphIds,
        originalLinkedParagraphIds,
        timeoutD,
    ]);

    useEffect(() => {
        const linkedSinceSelected = difference(
            linkedParagraphIds,
            originalLinkedParagraphIds
        );
        const removedSinceSelected = difference(
            originalLinkedParagraphIds,
            linkedParagraphIds
        );
        const removedSinceLastChange = difference(
            currentLinkedParagraphIds,
            linkedParagraphIds
        );
        setCurrentLinkedParagraphIds(linkedParagraphIds);
        setOverallNewLinkedParagraphIds(linkedSinceSelected);
        setOverallRemovedLinkedParagraphIds(removedSinceSelected);

        if (removedSinceLastChange.length) {
            setRemovedLinkedParagraphIds(removedSinceLastChange);
            setShowRemovedLinkedParagraphsBadge(false);

            setTimeout(() => {
                // the outer timeout is used to ensure the animation properly resets
                clearOptionalTimeout(timeoutE);
                setShowRemovedLinkedParagraphsBadge(true);

                setTimeoutE(
                    setTimeout(() => {
                        setShowRemovedLinkedParagraphsBadge(false);
                    }, 6000)
                );
            }, 0);
        }
    }, [
        linkedParagraphIds,
        currentLinkedParagraphIds,
        originalLinkedParagraphIds,
        timeoutE,
    ]);

    const throttledStartUpdatedAnimation = useRef(
        throttle((): void => {
            startUpdatedAnimation();
        }, 300)
    );

    const startUpdatedAnimation = () => {
        setShowUpdatedBadge(false);
        setShowUpdatedAnimation(true);
        clearOptionalTimeout(timeoutA);
        clearOptionalTimeout(timeoutB);
        clearOptionalTimeout(timeoutC);

        setTimeout(() => {
            // the outer timeout is used to ensure the animation properly resets
            setTimeoutA(
                setTimeout(() => {
                    setShowUpdatedAnimation(false);
                }, 2000)
            );

            setTimeoutB(
                setTimeout(() => {
                    setShowUpdatedBadge(true);

                    setTimeoutC(
                        setTimeout(() => {
                            setShowUpdatedBadge(false);
                        }, 15000)
                    );
                }, 1600)
            );
        }, 0);
    };

    const handleInputChange = (newValue: string, debounceOnChange = false) => {
        setCurrentValue(newValue || NONE_IDENTIFIED_OPTION);

        if (debounceOnChange) {
            debounceInputChange.current(newValue, property);
        } else {
            onChange(newValue, property);
            throttledStartUpdatedAnimation.current();
        }
    };

    const debounceInputChange = useRef(
        debounce((newValue: string, property): void => {
            onChange(newValue, property);
            throttledStartUpdatedAnimation.current();
        }, 3000)
    );

    const answerIsMissing = propertyAnswerMissing(currentValue);
    const validDate = dayjs(currentValue).isValid();

    const helperText = (
        <HelperText handleAskNewQuestions={onReprocess} property={property} />
    );

    const dateInput = (
        <DatePicker
            onChange={(newValue) => {
                handleInputChange(newValue!.format(DEFAULT_DATE_FORMAT), true); // Fixme: null checks
            }}
            onOpen={() => {
                !isSelected && onClick();
            }}
            value={validDate ? dayjs(currentValue) : null}
            disabled={inputDisabled}
            label={shortLabel}
            helperText={helperText}
            placeholder={value}
        />
    );

    const selectInput = (
        <Select
            disableAutocomplete
            multiple={false}
            label={shortLabel}
            value={options.find((option) => option.label === currentValue)}
            onChange={(value) => handleInputChange(value.label)}
            onOpen={isSelected ? null! : onClick} // Fixme: null checks
            options={options}
            helperText={helperText}
            disabled={inputDisabled}
            data-testid="editable-property-dropdown"
        />
    );

    const multiSelectInput = (
        <MultiSelect
            helperText={helperText}
            onChange={(value) => onChange(value, property)}
            label={shortLabel}
            onOpen={isSelected ? null! : onClick} // Fixme: null checks
            options={options}
            value={value}
            disabled={inputDisabled}
            data-testid="editable-property-multi-select"
        />
    );

    const textInput = (
        <TextField
            multiline
            maxRows={selected ? 99 : 1}
            fullWidth
            onChange={({ target: { value } }) => handleInputChange(value, true)}
            label={shortLabel}
            value={currentValue}
            onClick={(e) => {
                e.stopPropagation();
                if (!isSelected) {
                    onClick();
                }
            }}
            helperText={helperText}
            ellipsis={!selected}
            disabled={inputDisabled}
            data-testid="editable-property-text-field"
        />
    );

    const renderInput = () => {
        switch (inputType) {
            case InputType.SEARCHABLE_DROPDOWN:
                return multiSelectInput;
            case InputType.DROPDOWN:
                return selectInput;
            case InputType.TEXT:
                return textInput;
            case InputType.DATE:
                return dateInput;
            default: {
                return null;
            }
        }
    };

    const newBadge = (
        <Badge
            className={`new-badge ${
                !showUpdatedBadge && showNewPropertyBadge ? 'show' : ''
            }`}
            type="pink"
            label="New"
        />
    );

    const updatedBadge = (
        <Tooltip
            className={`updated-badge ${showUpdatedBadge ? 'show' : ''}`}
            title={
                overallNewLinkedParagraphIds.length ||
                overallRemovedLinkedParagraphIds.length ? (
                    <div className="links-tooltip">
                        {overallNewLinkedParagraphIds.length ? (
                            <div className="links-tooltip-count">
                                {overallNewLinkedParagraphIds.length} link
                                {overallNewLinkedParagraphIds.length !== 1
                                    ? 's'
                                    : ''}{' '}
                                added
                            </div>
                        ) : (
                            ''
                        )}
                        {overallRemovedLinkedParagraphIds.length ? (
                            <div className="links-tooltip-count">
                                {overallRemovedLinkedParagraphIds.length} link
                                {overallRemovedLinkedParagraphIds.length !== 1
                                    ? 's'
                                    : ''}{' '}
                                removed
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                ) : (
                    ''
                )
            }
            placement="top"
        >
            <Badge type="pink" label="Updated" />
        </Tooltip>
    );

    const severityBadge = (
        <Badge
            type={getIssueSeverityLevelBadgeType(severityLevel)}
            label={getIssueSeverityLevelLabel(severityLevel)}
            onClick={onSeverityBadgeClick}
            data-testid={`property-severity-badge-${severityLevel}`}
        />
    );

    const badges = (
        <div className="badges-wrapper">
            <Badge
                className={`updated-clauses-badge ${
                    !showUpdatedBadge &&
                    !showUpdatedAnimation &&
                    showNewLinkedParagraphsBadge &&
                    newLinkedParagraphIds.length
                        ? 'show'
                        : ''
                }`}
                type="pink"
                label={
                    <span className="badge-content">
                        +1
                        <Link className="link-icon" />
                    </span>
                }
            />
            <Badge
                className={`updated-clauses-badge ${
                    !showUpdatedBadge &&
                    !showUpdatedAnimation &&
                    showRemovedLinkedParagraphsBadge &&
                    removedLinkedParagraphIds.length
                        ? 'show'
                        : ''
                }`}
                type="pink"
                label={
                    <span className="badge-content">
                        -1
                        <Link className="link-icon" />
                    </span>
                }
            />
            {newBadge}
            {updatedBadge}
            {severityLevel !== IssueSeverityLevel.UNSCORED && severityBadge}
        </div>
    );

    return (
        <div
            className={[
                'summary-property',
                isSelected ? 'selected' : '',
                answerIsMissing || (inputType === InputType.DATE && !validDate)
                    ? 'missing-answer'
                    : '',
                reprocessRequired ? 'reprocess-required' : '',
                // showUpdatedAnimation ? 'updated' : '', //Commenting it out for now until we fix pink background animation
            ]
                .join(' ')
                .trim()}
            data-testid={hyphenateLabel(shortLabel)}
            onClick={onClick}
        >
            <div className="summary-property-content">
                {inputDisabled ? (
                    <Tooltip title="Link a contract clause to unlock and edit this field.">
                        <div className="input">{renderInput()}</div>
                    </Tooltip>
                ) : (
                    <div className="input">{renderInput()}</div>
                )}
                {badges}
                <LexiblePropertyDetailsButton
                    className="details-btn"
                    onClick={onDetailsIconClick}
                />
            </div>
            {question && (
                <div className="summary-property-question">{question}</div>
            )}
        </div>
    );
};

export default SummaryProperty;
