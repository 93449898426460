import { analyticsService } from '../../services/Analytics/Analytics';
import {
    EVENT_ACTION_ANALYTICS_REPORT_VIEW_PARAGRAPH_IN_CONTRACT,
    EVENT_KEY_ANALYTICS_REPORT_ID,
} from '../../services/Analytics/Analytics-types';
import { IClausesSidebarMetadata } from './ClausesSidebar-types';

export function analytics() {
    return (target: any) => {
        const onClickClauseLink = target.prototype.onClickClauseLink;

        target.prototype.onClickClauseLink = function (
            metadata: IClausesSidebarMetadata
        ) {
            analyticsService.recordEvent(
                EVENT_ACTION_ANALYTICS_REPORT_VIEW_PARAGRAPH_IN_CONTRACT,

                {
                    [EVENT_KEY_ANALYTICS_REPORT_ID]: metadata.reportId,
                    ContractId: metadata.contractId,
                    VersionId: metadata.versionId,
                    VersionNumber: metadata.versionNumber,
                    PropertyId: metadata.propertyId,
                }
            );

            onClickClauseLink.apply(this, arguments);
        };
    };
}
