import { Component } from 'react';

import { IssueCreationType } from '../../../IssueListPane/IssueListPane-types';
import {
    IIssuePaneIntelTabProps,
    IIssuePaneIntelTabState,
} from './IssuePaneIntelTab-types';
import { motion } from 'framer-motion';
import { HighlightIndexType } from '../../../Contract/Contract-types';
import IssueFamiliarity from './IssueFamiliarity/IssueFamiliarity';
import './IssuePaneIntelTab.scss';
import { Alert, Select, SelectOption } from '@thought-river/ui-components';
import { analytics } from './IssuePaneIntelTab-analytics';
import { animationProps } from '../IssuePaneTabs-helpers';
import { LexibleTheme, capitalize } from '@thought-river/negotiations-common';

function mapThemeValuesIntoMultiSelectValues(theme: LexibleTheme) {
    return {
        value: theme.nonAssessedId,
        label: capitalize(theme.name),
    };
}

@analytics()
export default class IssuePaneIntelTab extends Component<
    IIssuePaneIntelTabProps,
    IIssuePaneIntelTabState
> {
    private readonly themeOptions: SelectOption[];
    private readonly themesDictionary: { [themeId: string]: LexibleTheme };

    constructor(props: IIssuePaneIntelTabProps) {
        super(props);

        const { themes = [] } = props;

        this.themeOptions = themes.map(mapThemeValuesIntoMultiSelectValues);
        this.themesDictionary = themes.reduce((dictionary, theme) => {
            dictionary[theme.nonAssessedId] = theme;
            return dictionary;
        }, {});

        this.state = {
            selectedThemeValues: this.generateSelectedThemeValues().map(
                mapThemeValuesIntoMultiSelectValues
            ),
        };

        this.onSelectParagraph = this.onSelectParagraph.bind(this);
    }

    componentDidUpdate(prevProps: IIssuePaneIntelTabProps) {
        if (
            JSON.stringify(prevProps.issue) !==
                JSON.stringify(this.props.issue) ||
            JSON.stringify(prevProps.form.clauseNumbers) !==
                JSON.stringify(this.props.form.clauseNumbers)
        ) {
            this.setState({
                selectedThemeValues: this.generateSelectedThemeValues().map(
                    mapThemeValuesIntoMultiSelectValues
                ),
            });
        }
    }

    private generateSelectedThemeValues() {
        const {
            issue,
            paragraphs,
            form: { clauseNumbers: formClauses },
        } = this.props;

        try {
            if (issue?.creationType === IssueCreationType.AUTO) {
                return issue.nonAssessedThemes;
            }

            const clauses = issue?.clauseNumbers ?? formClauses;
            const clauseParagraphs = clauses.map((clause) =>
                paragraphs.find((p) => p.id === clause.id)
            );

            const filteredThemesDictionary = clauseParagraphs.reduce(
                (acc, paragraph) => {
                    paragraph?.themeIds.forEach((themeId) => {
                        if (this.themesDictionary[themeId]) {
                            acc[themeId] = this.themesDictionary[themeId];
                        }
                    });
                    return acc;
                },
                {} as { [themeId: string]: LexibleTheme }
            );

            return Object.values(filteredThemesDictionary);
        } catch (_) {
            return [];
        }
    }

    private onSelectParagraph(paragraphId: string) {
        const { paragraphs, setSelectedParagraph, setHighlightIndex } =
            this.props;

        const paragraph = paragraphs.find((p) => p.id === paragraphId);

        if (paragraph) {
            setHighlightIndex(paragraph.index, HighlightIndexType.START);
            setHighlightIndex(paragraph.index, HighlightIndexType.END);
            setSelectedParagraph(paragraph);
        }
    }

    render() {
        const {
            form: { clauseNumbers: formClauses },
        } = this.props;

        const { selectedThemeValues } = this.state;

        return (
            <div className="issue-pane-intel-tab">
                <motion.div className="issue-themes" {...animationProps(0.1)}>
                    {selectedThemeValues.length ? (
                        <Select
                            label="Legal themes"
                            multiple
                            value={selectedThemeValues}
                            options={this.themeOptions}
                            onChange={() => {}}
                            readOnly
                        />
                    ) : (
                        <div className="issue-themes-missing">
                            <div className="label">Legal themes</div>
                            <Alert severity="info">
                                There must be at least one linked clause or
                                paragraph to display legal themes.
                            </Alert>
                        </div>
                    )}
                </motion.div>
                <motion.div
                    className="issue-paragraph-familiarity"
                    {...animationProps(0.2)}
                >
                    <div className="label">Familiarity</div>
                    {formClauses?.length ? (
                        <IssueFamiliarity
                            paragraphs={formClauses}
                            onSelectParagraph={this.onSelectParagraph}
                        />
                    ) : (
                        <Alert severity="info">
                            There must be at least one linked clause or
                            paragraph to display language insights.
                        </Alert>
                    )}
                </motion.div>
            </div>
        );
    }
}
