import { Avatar, Select } from '@thought-river/ui-components';
import FilterLabel from './FilterLabel';
import { useGetAllOwnParties } from '@thought-river/negotiations-common/dist/api/contractContent';
import { ColumnFilterProps } from '@modules/reports/common';

const OwnPartyFilter = ({ selected, onChange }: ColumnFilterProps) => {
    const { data: ownParties } = useGetAllOwnParties({
        queryParams: { types: 'formal_names' },
    });

    const options =
        ownParties?.map((party) => ({
            label: party,
            value: party,
        })) || [];

    const value = options.filter((o) => selected.includes(o.value));

    return (
        <Select
            label={<FilterLabel columnId="own_party" />}
            options={options}
            renderOption={(option) => (
                <Avatar label={option.label} showLabel size="small" />
            )}
            onChange={onChange}
            multiple
            value={value}
            disableCloseOnSelect
        />
    );
};

export default OwnPartyFilter;
