import {
    GridGroupingValueGetterParams,
    GridValueGetterParams,
} from '@mui/x-data-grid-premium';
import dayjs from 'dayjs';
import { PARTIES_STRING_SEPERATOR } from '@modules/reports/portfolioReports';
import { extractColumnField } from '@modules/reports/common/helpers/extractColumnField';

const partiesValueGetter = (value?: string[]) =>
    //Join the parties list using the seperator
    value?.join(PARTIES_STRING_SEPERATOR);

export const valueGetter = (params: GridValueGetterParams) => {
    //If a value of a 'date' or 'dateTime' column is not of type Date then DataGrid complains
    if (params.colDef.type === 'dateTime' || params.colDef.type === 'date') {
        return dayjs(params.value).toDate();
    }

    const field = extractColumnField(params.field);
    if (field === 'own_party' || field === 'counter_party') {
        return partiesValueGetter(params.value);
    }

    return params.value;
};

export const groupingValueGetter = (params: GridGroupingValueGetterParams) => {
    const field = extractColumnField(params.field);

    if (field === 'own_party' || field === 'counter_party') {
        return partiesValueGetter(params.value);
    }
    return params.value ?? '';
};
