import {
    ContractTree,
    ContractTreeRow,
} from '@thought-river/negotiations-common/dist/api/contractManagement';

export const getContractRow = (
    contractId: string,
    contractsTreeData?: ContractTree
) => {
    const contractRow = contractsTreeData?.rows.find(
        (row) => row.contract_uuid === contractId
    );

    return contractRow;
};

export const getRelevantProcessingSteps = (contractRow?: ContractTreeRow) => {
    const processingSteps = contractRow?.processing_steps ?? [];

    const steps = ['docx_parser', 'party_detection', 'paragraphs_creation'];

    return processingSteps.filter((step) => steps.includes(step.name));
};

export const getHasPartyDetectionStarted = (contractRow?: ContractTreeRow) => {
    const partyDetectionStep = contractRow?.processing_steps?.find(
        (step) => step.name === 'party_detection'
    );
    return (
        partyDetectionStep?.status === 'in_progress' ||
        partyDetectionStep?.status === 'succeeded'
    );
};
