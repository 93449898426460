import { IDocuments } from 'types/thoughtriver';

export enum DocumentOcrMode {
    AUTODETECT = 'auto',
    TEXT = 'text',
    SCANNED = 'scanned',
}

export interface IDocumentResourceStatic {
    getDocuments: (
        contractId: string,
        versionId: string,
        stream: string
    ) => Promise<IDocuments>;
}

export interface IDocumentResource {}
