import Api from '../services/api';
import { Method, requestOptions } from '../services/api-types';
import {
    IBulkPatchVersionStatusPayload,
    IPatchVersionPayload,
    IVersionResource,
    IVersionResourceStatic,
} from './VersionResource-types';
import { IPartyGroups, IVersion, IVersions } from 'types/thoughtriver';
import { APP_NAME } from '../components/App/App-types';
import { AxiosResponse } from 'axios';
import { reduxStore } from '../redux/store';
import { IRootState } from '../redux/reducers-types';
import { FEATURE_TOGGLE_CONTRACT_MANAGEMENT_UPLOAD_CONTRACT_VERSION } from '../components/FeatureToggleProvider/FeatureToggleProvider-types';

const VersionResource: IVersionResourceStatic = class VersionResource
    implements IVersionResource
{
    static async getVersion(
        contractId: string,
        versionId: string,
        stream: string
    ): Promise<IVersion> {
        const url = `/contracts/${contractId}/versions/${versionId}`;

        const options = {
            ...requestOptions,
            streamCode: stream,
        };

        return await (await Api.request(Method.GET, url, options))!.json();
    }

    static async getVersions(
        contractId: string,
        stream: string
    ): Promise<IVersions> {
        const url = `/contracts/${contractId}/versions`;
        console.log({ stream });

        const options = {
            ...requestOptions,
            streamCode: stream,
        };

        return await (await Api.request(Method.GET, url, options))!.json();
    }

    static async deleteVersion(
        contractId: string,
        versionId: string,
        stream: string
    ): Promise<any> {
        const url = `/contracts/${contractId}/versions/${versionId}`;

        const options = {
            ...requestOptions,
            streamCode: stream,
        };

        return await Api.request(Method.DELETE, url, options);
    }

    static async patchVersion(
        contractId: string,
        versionId: string,
        parties: IPartyGroups,
        stream: string
    ): Promise<Response> {
        const url = `/contracts/${contractId}/versions/${versionId}`;

        const payload: IPatchVersionPayload = {
            data: {
                attributes: {
                    parties,
                },
            },
        };

        const options = {
            ...requestOptions,
            payload,
            streamCode: stream,
            noRedirect: true,
        };

        return (await Api.request(Method.PATCH, url, options))!;
    }

    static async patchVersionStatus(
        contractId: string,
        versionId: string,
        stream: string,
        statusId: string
    ): Promise<Response> {
        const url = `/contracts/${contractId}/versions/${versionId}/business-status`;

        const payload: IPatchVersionPayload = {
            data: {
                attributes: {
                    'status-id': statusId,
                },
            },
        };

        const options = {
            ...requestOptions,
            payload,
            streamCode: stream,
        };

        return (await Api.request(Method.PATCH, url, options))!;
    }

    static async bulkPatchVersionStatus(
        contractIds: string[],
        stream: string,
        statusId: string
    ): Promise<any> {
        const url = '/bulk-update-statuses';

        const payload: IBulkPatchVersionStatusPayload = {
            data: {
                attributes: {
                    'contract-ids': contractIds,
                    'status-id': statusId,
                },
            },
        };

        const options = {
            ...requestOptions,
            payload,
            streamCode: stream,
        };

        return await Api.request(Method.PATCH, url, options);
    }

    static async uploadVersion(
        payload: FormData,
        contractId: string,
        streamCode: string,
        onUploadProgress: CallableFunction,
        abortSignal: AbortSignal
    ): Promise<AxiosResponse> {
        const url = `/contracts/${contractId}/versions`;
        const store = reduxStore.store.getState() as IRootState;
        const featureToggles = store.featureToggles.toggles;
        const endpointVersionFeatureFlag = featureToggles.find(
            (toggle) =>
                toggle.feature ===
                FEATURE_TOGGLE_CONTRACT_MANAGEMENT_UPLOAD_CONTRACT_VERSION
        );

        const options = {
            ...requestOptions,
            payload,
            streamCode,
            headers: {
                'x-application': APP_NAME,
            },
            noRedirect: true,
            abortSignal,
        };

        if (endpointVersionFeatureFlag?.enabled) {
            options.baseUrl = `${window.__APP_CONFIG__.api2Url}/contract-management/${endpointVersionFeatureFlag.value}`;
        }

        return (await Api.axiosRequest(
            Method.POST,
            url,
            options,
            onUploadProgress
        ))!;
    }
};

export default VersionResource;
