import { Component } from 'react';
import { toast } from 'react-toastify';
import { Cancel } from '@mui/icons-material';

import {
    IBulkReprocessModalProps,
    IBulkReprocessModalState,
    IGroupedContracts,
} from './BulkReprocessModal-types';
import ContractResource from '../../resources/ContractResource';
import { analytics } from './BulkReprocessModal-analytics';
import AssessmentResource from '../../resources/AssessmentResource';
import groupBy from 'lodash/groupBy';

@analytics()
class BulkReprocessModal extends Component<
    IBulkReprocessModalProps,
    IBulkReprocessModalState
> {
    constructor(props: IBulkReprocessModalProps) {
        super(props);

        this.state = {
            submitting: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    async onSubmit() {
        const {
            closeModal,
            selectedContracts,
            setReprocessingContract,
            setSelectAllContracts,
            userId,
        } = this.props;

        const invalidContractIds = [];
        const validContractIds = [];
        let groupValidContractIds = [];

        await this.setState({
            submitting: true,
        });

        try {
            const groupedContracts: IGroupedContracts = groupBy(
                selectedContracts,
                'streamCode'
            );

            for (const [streamCode, contracts] of Object.entries(
                groupedContracts
            )) {
                groupValidContractIds = [];

                for (const contract of contracts) {
                    if (!contract.versionIds.length) {
                        invalidContractIds.push(contract.id);
                        continue;
                    }

                    const versionId =
                        contract.versionIds[contract.versionIds.length - 1];

                    const assessments = await AssessmentResource.getAssessments(
                        contract.id,
                        versionId,
                        streamCode
                    );

                    await setReprocessingContract(
                        contract.id,
                        contract.contractName,
                        versionId,
                        contract.streamCode,
                        contract.streamId,
                        assessments.data.length,
                        userId
                    );

                    groupValidContractIds.push(contract.id);
                    validContractIds.push(contract.id);
                }

                if (groupValidContractIds.length) {
                    await ContractResource.reprocess(
                        groupValidContractIds,
                        streamCode
                    );
                }
            }

            if (validContractIds.length) {
                toast.success(
                    `${validContractIds.length} contract(s) queued for reprocessing`
                );
            }

            if (invalidContractIds.length) {
                toast.error(
                    `${invalidContractIds.length} contract(s) were not reprocessed because they are missing data`
                );
            }

            await setSelectAllContracts(false);

            await this.setState({
                submitting: false,
            });

            closeModal();
        } catch (error) {
            toast.error('Error signing the contract(s)');

            await this.setState({
                submitting: false,
            });
        }
    }

    render() {
        const { selectedContracts, closeModal } = this.props;
        const { submitting } = this.state;

        return (
            <div className="bulk-reprocess-modal">
                <div className="modal-dialog-window-header">
                    <div className="modal-dialog-title">
                        <span className="title-text">Reanalyze contracts</span>
                    </div>
                    <div
                        className="modal-dialog-close-button"
                        onClick={closeModal}
                    >
                        <Cancel />
                    </div>
                </div>
                <div className="section">
                    <div className="section-content">
                        Please confirm that you want to reanalyze{' '}
                        {selectedContracts.length} contract(s).
                    </div>
                </div>
                <div className="modal-dialog-window-footer">
                    <button className="btn flat" onClick={closeModal}>
                        Cancel
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={this.onSubmit}
                        disabled={submitting}
                    >
                        <span>
                            Reanalyze {selectedContracts.length} contract(s)
                        </span>
                    </button>
                </div>
            </div>
        );
    }
}

export default BulkReprocessModal;
