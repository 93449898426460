import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const REFRESH_INTERVAL_MS = 10_000;

const DurationCell = ({ value }: GridRenderCellParams) => {
    const [duration, setDuration] = useState('');
    useEffect(() => {
        const updateDuration = () =>
            setDuration(dayjs().to(value * 1000, true));

        updateDuration();
        const interval = setInterval(updateDuration, REFRESH_INTERVAL_MS);

        return () => clearInterval(interval);
    }, [value]);
    return <div>{duration}</div>;
};

export default DurationCell;
