import { Query, QueryClient } from '@tanstack/react-query';
import {
    ContractsReport,
    ContractsReportRow,
} from '@thought-river/negotiations-common/dist/api/contractContent';

//Reset report data with a given id
export const resetTanStackQueryReportData = (
    queryClient: QueryClient,
    reportDefinitionId: string
) => {
    queryClient.resetQueries({
        queryKey: ['contracts', 'report'],
        predicate: (query: Query) =>
            query.queryHash.includes(reportDefinitionId),
    });
};

//Update a single row for a given report
export const updateTanStackQueryReportDataRow = (
    queryClient: QueryClient,
    reportDefinitionId: string,
    newRow: ContractsReportRow
) => {
    queryClient.setQueriesData(
        {
            queryKey: ['contracts', 'report'],
            predicate: (query: Query) =>
                query.queryHash.includes(reportDefinitionId.toString()),
        },
        (oldData: ContractsReport | undefined) =>
            oldData
                ? {
                      ...oldData,
                      rows: oldData.rows.map((row) =>
                          row.id === newRow.id ? newRow : row
                      ),
                  }
                : oldData
    );
};
