import { ReportColumn } from '@thought-river/negotiations-common/dist/api/contractContent';
import { CounterPartyFilter, DealTypeFilter, StatusFilter } from '.';
import OwnPartyFilter from './OwnParty';
import { ColumnFilterProps } from '@modules/reports/common';

export const filterComponents: Partial<
    Record<ReportColumn, React.ComponentType<ColumnFilterProps>>
> = {
    deal_type: DealTypeFilter,
    status: StatusFilter,
    own_party: OwnPartyFilter,
    counter_party: CounterPartyFilter,
};
