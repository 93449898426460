export const API_REDLINE_TAG = 'TR_deleted_paragraph';
export const PARAGRAPH_INDEX_ATTRIBUTE = 'data-ppl-index';
export const PARAGRAPH_WORD_ID_ATTRIBUTE = 'paraid';
export const STYLES_EXTRACTED = 'styles-extracted';

export interface IDocumentParserStatic {
    extractParagraphs: (text: string) => HTMLCollectionOf<HTMLParagraphElement>;
    extractStyles: (text: string) => CSSStyleSheet;
    enhancedParseDocument: (text: string) => IHtmlParagraphCollection;
    parseTable(table: Element, tableId: number): IHtmlParagraphCollection;
    replaceStyleTagsWithClassTags: (text: string) => string;
    removeCssPropertyKeys: (text: string) => string;
    removeExtraClassFromTag: (text: string) => string;
    removeUnstyledSpanTags: (text: string) => string;
    replaceRange: (
        text: string,
        startIndex: number,
        endIndex: number,
        substitute: string
    ) => any;
    stripHtml: (text: string) => string;
    tagParagraph: (text: string) => ITaggedParagraph;
    trimWhitespace: (text: string) => string;
}

export interface IDocumentParser {}

export interface ITaggedParagraph {
    text: string;
    html: string;
}

export interface IHtmlParagraph {
    cell: string | null;
    cellsInRow: number;
    colSpan: number | null;
    html: string;
    classes: string;
    location: number;
    nextEmptyCells: number;
    prevEmptyCells: number;
    row: string | null;
    rowSpan: number | null;
    tableId: number | null;
    text: string;
    totalParagraphsInTable: number | null;
}

export interface IHtmlParagraphCollection {
    [index: string]: IHtmlParagraph;
}

export interface IRedlineParagraphCollection {
    [index: string]: string;
}
