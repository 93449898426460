import { ReactElement, useMemo } from 'react';
import {
    GridActionsCellItemProps,
    GridColDef,
    GridRenderCellParams,
    GridRowParams,
    GridValueFormatterParams,
} from '@mui/x-data-grid-premium';
import ReportHeaderCell from '../../cells/ReportHeaderCell';
import ReportCell from '../../cells/ReportCell';
import { ContractsReportColumn } from '@thought-river/negotiations-common/dist/api/contractContent';
import { groupingValueGetter, valueGetter } from './valueGetter';
import { getSortComparator } from './sortComparators';
import { getFilterOperators } from './filterOperators';
import { valueFormatter } from './valueFormatter';
import {
    getIsColumnHideable,
    reportColumnLabels,
    useLexibleContext,
} from '@modules/reports/portfolioReports';
import { getOrderedColumns } from '@modules/reports/common';

export const useGetColumns = (
    columns: ContractsReportColumn[],
    actionsColumnActions: (
        params: GridRowParams
    ) => ReactElement<GridActionsCellItemProps>[],
    orderedFields: string[]
): GridColDef[] => {
    const { propertiesDict } = useLexibleContext();

    const memoizedColumns = useMemo(() => {
        const orderedColumns = getOrderedColumns(columns, orderedFields);

        const columnDefs = orderedColumns.map<GridColDef>(
            (column: ContractsReportColumn) => {
                const columnProperty = propertiesDict?.[column.field] ?? null;
                const columnName = columnProperty
                    ? columnProperty.short_label
                    : reportColumnLabels[column.field];

                return {
                    ...column,
                    headerName: columnName,
                    renderHeader: () => (
                        <ReportHeaderCell column={column.field} />
                    ),
                    width: (() => {
                        /*Only contract name column needs to have a fixed with*/
                        if (column.field === 'contract_name') {
                            return 400;
                        }
                    })(),
                    flex: (() => {
                        if (column.field !== 'contract_name') {
                            return 1;
                        }
                    })(),
                    filterOperators: getFilterOperators(column),
                    minWidth: 200,
                    valueGetter,
                    valueFormatter: (params: GridValueFormatterParams) =>
                        valueFormatter(params, column.type),
                    groupingValueGetter: groupingValueGetter,
                    renderCell: (params: GridRenderCellParams) => (
                        <ReportCell params={params} />
                    ),
                    sortComparator: getSortComparator(column, columnProperty),
                    hideable: getIsColumnHideable(column.field),
                };
            }
        );

        //actions column
        columnDefs.push({
            field: 'actions',
            type: 'actions',
            minWidth: 50,
            maxWidth: 50,
            getActions: actionsColumnActions,
            hideable: false,
        });

        return columnDefs;
    }, [columns, propertiesDict, orderedFields, actionsColumnActions]);

    return memoizedColumns;
};
