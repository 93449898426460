import { useContractSearchContext } from '@modules/common/context/ContractSearchProvider';
import { Search } from '@thought-river/ui-components';

const SearchBar = () => {
    const { searchTerm, onSearchTermChange } = useContractSearchContext();

    return <Search value={searchTerm} onChange={onSearchTermChange} />;
};

export default SearchBar;
