import { GridFilterItem } from '@mui/x-data-grid-premium';
import { SelectOption } from '@thought-river/ui-components';
import { ComponentType } from 'react';

export interface ColumnFilterProps {
    selected: string | string[];
    onChange: (options: SelectOption[]) => void;
}

export type ReportFilters = Record<string, string | string[]>;

export enum ReportConfigPageMiddleSectionActiveView {
    COLUMNS_LIST,
    FILTERS_LIST,
}

export interface SelectedDataItem {
    id: string;
    columnName: string;
    isVisible: boolean;
    filters: string | string[];
    isHideable: boolean;
}

export interface ColumnSelectionProps {
    selectedColumns: string[];
    onSelectedColumnsChange: (columns: string[]) => void;
    columnSearchTerm: string;
}

export interface GenericReportConfigPageProps {
    selectedColumns: string[];
    selectedFilters: ReportFilters;
    visibleColumns?: string[];
    applyBtnDisabled?: boolean;
    ColumnSelection: ComponentType<ColumnSelectionProps>;
    columnFilterComponents?: Record<
        string,
        React.ComponentType<ColumnFilterProps>
    >;
    allowHidingColumns?: boolean;
    onCancelBtnClick: () => void;
    onApplyBtnClick: () => void;
    getColumnLabel?: (column: string) => string;
    getIsColumnHideable?: (column: string) => boolean;
    onSelectedColumnsChange: (columns: string[]) => void;
    onSelectedFiltersChange: (filters: ReportFilters) => void;
    onVisibleColumnsChange?: (visibleColumns: string[]) => void;
    unsupportedFilterItems?: GridFilterItem[];
}
