import {
    HIDE_DIALOG,
    IModalDialogActionTypes,
    IModalDialogReducer,
    SHOW_DIALOG,
    UPDATE_AUTO_CLOSE,
    UPDATE_BUTTONS,
} from './ModalDialog-types';

const initialState: IModalDialogReducer = {
    title: '',
    buttons: [],
    showCloseButton: true,
    visible: false,
    component: undefined!, // Fixme: null checks
    autoClose: true,
    height: null,
    customClassname: null!, // Fixme: null checks
    closeOnBlur: true,
};

const ModalDialogReducer = (
    state = initialState,
    action: IModalDialogActionTypes
): IModalDialogReducer => {
    switch (action.type) {
        case SHOW_DIALOG:
            return {
                ...state,
                visible: true,
                title: action.title,
                showCloseButton: action.showCloseButton,
                buttons: action.buttons,
                component: action.component,
                height: action.height,
                customClassname: action.customClassname,
                closeOnBlur: action.closeOnBlur,
            };

        case HIDE_DIALOG:
            return {
                ...state,
                ...initialState,
            };

        case UPDATE_BUTTONS:
            return {
                ...state,
                buttons: action.buttons,
            };

        case UPDATE_AUTO_CLOSE:
            return {
                ...state,
                autoClose: action.autoClose,
            };

        default:
            return state;
    }
};

export default ModalDialogReducer;
