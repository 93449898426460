import { Component } from 'react';

import { IPartyParagraphProps } from './PartyParagraph-types';
import {
    PartiesType,
    IParty,
    PartyVariantType,
} from '../Parties/Parties-types';
import { escapeRegex, isInternetExplorer } from '../App/App-helpers';

class PartyParagraph extends Component<IPartyParagraphProps> {
    onDragStart(event: any, party: IParty) {
        const { setDraggedParty } = this.props;

        const format = isInternetExplorer() ? 'Text' : 'text/plain';
        const draggingInProgress = event.dataTransfer.getData(format);

        if (draggingInProgress) {
            return;
        }

        event.dataTransfer.setData(format, `${party.id}@@@${party.name}`);
        setDraggedParty(party);
    }

    onHoverOffParty() {
        this.props.setHighlightedParty(null!); // Fixme: null checks
    }

    onHoverParty(
        party: IParty,
        highlightedParty: IParty | null = null,
        selectedParty: IParty | null = null
    ) {
        const isHighlightedParty =
            highlightedParty && highlightedParty.id === party.id;
        const isSelectedParty = selectedParty && selectedParty.id === party.id;

        if (
            (!highlightedParty && (!selectedParty || !isSelectedParty)) ||
            (highlightedParty && !isHighlightedParty)
        ) {
            this.props.setHighlightedParty(party);
        }
    }

    onSelectParty(party: IParty, selectedParty: IParty) {
        const { expandedGroup } = this.props;

        if (selectedParty && selectedParty.id === party.id) {
            this.props.setSelectedParty(null!); // Fixme: null checks
        } else {
            this.props.setSelectedParty(party);
            this.props.setScrollToParty(party);

            if (expandedGroup !== party.type) {
                this.props.setExpandedGroup(party.type);
            }
        }
    }

    async onSelectText() {
        const { partyToAdd, setPartyToAdd, setSelectedParty } = this.props;
        const selectedText = window.getSelection()?.toString().trim();

        await this.props.setHighlighting(false);

        if (selectedText && partyToAdd !== selectedText) {
            setSelectedParty(null!); // Fixme: null checks
            setPartyToAdd(selectedText);
        } else {
            setPartyToAdd(null!); // Fixme: null checks
        }
    }

    async onStartTextSelection(event: any) {
        let { classList } = event.target;
        const parentNode = event.target.parentNode;

        if (
            !classList.contains('party-to-add') &&
            parentNode &&
            parentNode.classList.contains('party-name')
        ) {
            classList = parentNode.classList;
        }

        if (
            !classList.contains('party-to-add') &&
            !classList.contains('selected')
        ) {
            await this.props.setHighlighting(true);
        }
    }

    removeNestedTags(text: string): string {
        const openingTagRegex = new RegExp('({{[^{{}}]+|({{))({{)', 'g');
        const closingTagRegex = new RegExp('(}})(}}|[^{{}}]+}})', 'g');
        text = text.replace(openingTagRegex, '$1');
        text = text.replace(closingTagRegex, '$2');
        return text;
    }

    removeInnerTags(text: string): string {
        return text.replace(/<</g, '').replace(/>>/g, '');
    }

    normaliseQuotes(text: string): string {
        // eslint-disable-next-line
        return text.replace(/’/g, `'`).replace(/“/g, '"').replace(/”/g, '"');
    }

    replaceNestedTags(text: string): string {
        const regex = new RegExp(
            '({{[^{{}}]+|({{))({{)([^{{}}]+)(}})(}}|[^{{}}]+}})',
            'g'
        );
        let match;

        while ((match = regex.exec(text))) {
            if (match[3] && match[5]) {
                // eslint-disable-next-line
                text = text.replace(regex, '$1$2<<$4>>$6');
            }
        }

        return text;
    }

    renderPartyParagraph(
        text: string,
        partiesMatchPattern: string,
        parties: IParty[],
        partyToAdd: string
    ): JSX.Element[] {
        if (partiesMatchPattern) {
            text = this.tagTerm(text, partiesMatchPattern);
        }

        if (partyToAdd) {
            text = this.tagTerm(text, escapeRegex(partyToAdd));
        }

        const paragraphParts = this.splitByTags(text);

        return paragraphParts.map((part, partIndex) =>
            this.renderPartyParagraphPart(part, partIndex, parties, partyToAdd)
        );
    }

    renderPartyToAdd(text: string, index: number, partIndex: number) {
        const { highlighting } = this.props;
        const newParty: IParty = {
            id: null!, // Fixme: null checks
            name: text,
            type: null!, // Fixme: null checks
            variantType: PartyVariantType.REFERENCE,
            originalType: null!, // Fixme: null checks
        };
        return (
            <span
                className="party-to-add"
                key={`paragraph-${index}-part-${partIndex}-party-to-add`}
                onDragStart={(event) => this.onDragStart(event, newParty)}
                draggable={!highlighting}
            >
                {text}
            </span>
        );
    }

    renderTaggedParty(
        text: string,
        party: IParty,
        selectedParty: IParty,
        highlightedParty: IParty,
        index: number,
        partIndex: number
    ) {
        const { highlighting, parties, partyToAdd } = this.props;
        const isDiscarded = party.type === PartiesType.DISCARDED;
        const isSelected = selectedParty && selectedParty.id === party.id;
        const isHighlighted =
            highlightedParty &&
            highlightedParty.id === party.id &&
            (!selectedParty ||
                (selectedParty && selectedParty.id !== party.id));
        const partyClasses =
            'party-name' +
            `${isDiscarded ? ' discarded' : ''} ` +
            `${isHighlighted ? 'highlighted' : ''}` +
            `${isSelected ? 'selected' : ''}`;

        const nestedParts = this.splitByTags(text, '<<', '>>');

        return (
            <span
                className={partyClasses}
                key={`paragraph-${index}-part-${partIndex}-party-${party.id}`}
                onClick={() => this.onSelectParty(party, selectedParty)}
                onMouseEnter={() =>
                    this.onHoverParty(party, highlightedParty, selectedParty)
                }
                onMouseLeave={() => this.onHoverOffParty()}
                onDragStart={(event) => this.onDragStart(event, party)}
                draggable={!highlighting}
                id={`paragraph-${index}-part-${partIndex}-party-${party.id}`}
                data-party={party.name}
            >
                {nestedParts.length > 1
                    ? nestedParts.map((part, index) =>
                          this.renderPartyParagraphPart(
                              part,
                              parseInt(`${partIndex}${index}`, 10),
                              parties,
                              partyToAdd
                          )
                      )
                    : this.removeInnerTags(text)}
            </span>
        );
    }

    renderPartyParagraphPart(
        text: string,
        partIndex: number,
        parties: IParty[],
        partyToAdd: string
    ): JSX.Element {
        const { index, selectedParty, highlightedParty } = this.props;
        const party = parties.find(
            (party) =>
                this.normaliseQuotes(this.removeInnerTags(text)) === party.name
        );

        if (party) {
            return this.renderTaggedParty(
                text,
                party,
                selectedParty,
                highlightedParty,
                index,
                partIndex
            );
        } else if (partyToAdd === text) {
            return this.renderPartyToAdd(text, index, partIndex);
        }

        return (
            <span key={`paragraph-${index}-part-${partIndex}-text`}>
                {text}
            </span>
        );
    }

    splitByTags(
        text: string,
        openingTag: string = '{{',
        closingTag: string = '}}'
    ): string[] {
        const regex = new RegExp(`${openingTag}|${closingTag}`, 'g');
        return text.split(regex).filter((part) => part.trim());
    }

    tagTerm(text: string, terms: string): string {
        if (!terms) {
            return text;
        }

        try {
            let regex = new RegExp(`(${terms})`, 'g');
            const matches = this.normaliseQuotes(text).match(regex);

            if (matches) {
                const filteredMatches = matches.filter(
                    (value, index, self) => self.indexOf(value) === index
                );

                for (const match of filteredMatches) {
                    regex = new RegExp(`(${escapeRegex(match)})`, 'g');
                    text = this.normaliseQuotes(text).replace(
                        regex,
                        `{{${match}}}`
                    );
                    text = this.replaceNestedTags(text);
                    text = this.removeNestedTags(text);
                }
            }
        } catch (error) {
            //
        }

        return text;
    }

    render() {
        const { text, partiesMatchPattern, parties, partyToAdd } = this.props;

        return (
            <li
                className="party-paragraph"
                onMouseDown={(event: any) => this.onStartTextSelection(event)}
                onMouseUp={() => this.onSelectText()}
            >
                {this.renderPartyParagraph(
                    text,
                    partiesMatchPattern,
                    parties,
                    partyToAdd
                )}
            </li>
        );
    }
}

export default PartyParagraph;
