import { useHistory } from 'react-router-dom';
import {
    ReportCellCount,
    ReportColumn,
    ReportData,
    ReportFilters as BEReportFiltesr,
} from '@thought-river/negotiations-common/dist/api/contractContent';
import { useQueryClient } from '@tanstack/react-query';
import {
    ReportDefinitionIn,
    ReportDefinitionOut,
} from '@thought-river/negotiations-common/dist/api/playbookManager';
import { toast } from 'react-toastify';
import { useGetReportCellCount } from '@thought-river/negotiations-common/dist/api/contractContent';
import { useCreateReportDefinition } from '@thought-river/negotiations-common/dist/api/playbookManager';
import { useState } from 'react';
import {
    defaultSelectedColumns,
    defaultSelectedFilters,
} from './CreateReportPage.types';
import {
    BaseReportDefinitionRequestBody,
    ColumnSelection,
    ErrorBanner,
    REPORTS_MAX_CELL_COUNT,
    addToTanStackQueryReportDefinitionsData,
    filterComponents,
    getIsColumnHideable,
    reportColumnLabels,
    useGetBaseReportDefinitionRequestBody,
    useLexibleContext,
} from '@modules/reports/portfolioReports';
import {
    GenericReportConfigPage,
    ReportFilters,
} from '@modules/reports/common';

const CreateReportPage = () => {
    const queryClient = useQueryClient();
    const history = useHistory();

    const { propertiesDict } = useLexibleContext();

    const [selectedColumns, setSelectedColumns] = useState<
        (ReportColumn | string)[]
    >(defaultSelectedColumns);
    const [selectedFilters, setSelectedFilters] = useState<ReportFilters>(
        defaultSelectedFilters
    );
    const [visibleColumns, setVisibleColumns] = useState(
        defaultSelectedColumns
    );

    const reportDefinitionRequestBody = useGetBaseReportDefinitionRequestBody(
        selectedColumns,
        selectedFilters,
        visibleColumns
    );

    const [showBanner, setShowBanner] = useState(false);

    const { mutate: getReportCellCount, isLoading: getReportCellCountLoading } =
        useGetReportCellCount({});
    const {
        mutate: createReportDefinition,
        isLoading: createReportDefinitionLoading,
    } = useCreateReportDefinition({});

    const getColumnName = (column: string) =>
        reportColumnLabels[column] || propertiesDict[column]?.short_label;

    const handleCreateReportDefinitionSuccess = (data: ReportDefinitionOut) => {
        addToTanStackQueryReportDefinitionsData(queryClient, data);
        history.push(`/reports/${data.id_str}`);
    };

    const createReport = (
        reportDefinitionRequestBody: BaseReportDefinitionRequestBody
    ) => {
        const requestBody: ReportDefinitionIn = {
            name: 'Untitled',
            columns: reportDefinitionRequestBody.columns,
            properties: reportDefinitionRequestBody.properties,
            config: reportDefinitionRequestBody.config,
            filters: reportDefinitionRequestBody.filters,
        };

        setShowBanner(false);
        createReportDefinition(
            {
                body: requestBody,
            },
            {
                onSuccess: handleCreateReportDefinitionSuccess,
                onError: () => toast.error(`Error creating report!`),
            }
        );
    };

    const handleApply = () => {
        const reportCellCountBody: ReportData = {
            columns: reportDefinitionRequestBody.columns as ReportColumn[],
            properties: reportDefinitionRequestBody.properties,
            filters: reportDefinitionRequestBody.filters as BEReportFiltesr,
        };

        getReportCellCount(
            {
                body: reportCellCountBody,
            },
            {
                onSuccess: (data: ReportCellCount) => {
                    if (data.cell_count >= REPORTS_MAX_CELL_COUNT) {
                        setShowBanner(true);
                        return;
                    }
                    createReport(reportDefinitionRequestBody);
                },

                onError: (error) => console.error(error),
            }
        );
    };

    return (
        <>
            {showBanner && <ErrorBanner onClose={() => setShowBanner(false)} />}
            <GenericReportConfigPage
                selectedColumns={selectedColumns}
                selectedFilters={selectedFilters}
                visibleColumns={visibleColumns}
                applyBtnDisabled={
                    getReportCellCountLoading || createReportDefinitionLoading
                }
                columnFilterComponents={filterComponents}
                getColumnLabel={getColumnName}
                getIsColumnHideable={getIsColumnHideable}
                onApplyBtnClick={handleApply}
                onCancelBtnClick={() => history.push('/reports')}
                onSelectedColumnsChange={setSelectedColumns}
                onSelectedFiltersChange={setSelectedFilters}
                onVisibleColumnsChange={setVisibleColumns}
                ColumnSelection={ColumnSelection}
            />
        </>
    );
};

export default CreateReportPage;
