import {
    IParty,
    PartiesType,
    PartyVariantType,
} from '../Parties/Parties-types';

export function getFirstPartyName(
    parties: IParty[],
    partyType: PartiesType,
    variantType: PartyVariantType
) {
    return (
        parties.find(
            (party) =>
                party.type === partyType && party.variantType === variantType
        )?.name ?? 'Formal name not set'
    );
}
