import { IPolicies } from 'types/thoughtriver';
import Api from '../services/api';
import { Method, requestOptions } from '../services/api-types';
import { IPolicyResource, IPolicyResourceStatic } from './PolicyResource-types';

const PolicyResource: IPolicyResourceStatic = class PolicyResource
    implements IPolicyResource
{
    static async getPolicies(
        contractId: string,
        versionId: string,
        assessmentId: string,
        stream: string
    ): Promise<IPolicies> {
        const url = `/contracts/${contractId}/versions/${versionId}/assessments/${assessmentId}/policies`;

        const options = {
            ...requestOptions,
            streamCode: stream,
        };

        return await (await Api.request(Method.GET, url, options))!.json();
    }
};

export default PolicyResource;
