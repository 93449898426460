import { useAppSelector } from '@modules/common/hooks';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { getParagraphIndexesFromSectionsTree } from './SectionOutline.helpers';
import {
    SectionOutlineProps,
    SectionTreeItemProps,
} from './SectionOutline.types';
import { useLayoutEffect, useRef, useState } from 'react';
import styles from './SectionOutline.module.scss';
import classNames from 'classnames';

const SectionTreeItem = ({ node, ...props }: SectionTreeItemProps) => {
    const ref = useRef<HTMLLIElement>(null);

    const isCurrentSection =
        node.paragraph_index === props.currentParagraphIndex;

    useLayoutEffect(() => {
        if (ref.current && isCurrentSection && !props.hasScrolled) {
            ref.current.scrollIntoView();
            props.onScrolled();
        }
    }, [isCurrentSection, props]);

    return (
        <TreeItem
            ref={ref}
            key={node.paragraph_id}
            nodeId={String(node.paragraph_index)}
            label={node.paragraph_text}
            className={classNames(styles.treeItem, {
                [styles.current]: isCurrentSection,
            })}
        >
            {Array.isArray(node.children)
                ? node.children.map((childNode) => (
                      <SectionTreeItem
                          key={childNode.paragraph_index}
                          node={childNode}
                          {...props}
                      />
                  ))
                : null}
        </TreeItem>
    );
};

const SectionOutline = ({
    topLevelSections,
    onSectionClick,
    currentParagraphIndex,
}: SectionOutlineProps) => {
    const [hasScrolled, setHasScrolled] = useState(false);

    const paragraphs = useAppSelector((state) => state.contract.paragraphs);

    const handleNodeSelect = (paragraphIndex: number) => {
        const targetParagraph = paragraphs.find(
            (paragraph) => paragraph.index === paragraphIndex - 1
        );

        if (targetParagraph) {
            onSectionClick(targetParagraph.id);
        }
    };

    const allNodesIds = getParagraphIndexesFromSectionsTree(topLevelSections);

    return (
        <TreeView
            expanded={allNodesIds}
            className={styles.treeView}
            onNodeSelect={(_, nodeId) => handleNodeSelect(Number(nodeId))}
        >
            {topLevelSections.map((node) => (
                <SectionTreeItem
                    key={node.paragraph_index}
                    node={node}
                    hasScrolled={hasScrolled}
                    onScrolled={() => setHasScrolled(true)}
                    currentParagraphIndex={currentParagraphIndex}
                />
            ))}
        </TreeView>
    );
};

export default SectionOutline;
