import { Component } from 'react';
import { toast } from 'react-toastify';

import {
    IPartyCorrectionProps,
    PartyCorrectionStatus,
    IPartyCorrectionUrlParams,
    IPartyCorrectionState,
    PAGE_TITLE_PARTY_CORRECTION,
} from './PartyCorrection-types';
import PartyContract from '../PartyContract/PartyContract-container';
import ContractResource from '../../resources/ContractResource';
import {
    getRelationship,
    getLatestRelationshipId,
    getAttribute,
} from '../../resources/resource-helpers';
import { mapPartiesToOverrides } from '../Parties/Parties-helpers';
import ParagraphResource from '../../resources/ParagraphResource';
import PartySidebar from '../PartySidebar/PartySidebar-container';
import VersionResource from '../../resources/VersionResource';
import { mapContract, mapParagraph, mapVersion } from '../App/App-mappers';
import PaneDivider from '../PaneDivider/PaneDivider-container';
import { ContractPane } from '../Contract/Contract-types';
import AssessmentResource from '../../resources/AssessmentResource';
import { IParty, PartiesType } from '../Parties/Parties-types';
import { analytics } from './PartyCorrection-analytics';
import PartyCorrectionHeader from '../PartyCorrectionHeader/PartyCorrectionHeader';
import { ModalDialogButtonType } from '../ModalDialog/ModalDialog-types';
import SplashLoader from '../SplashLoader/SplashLoader';
import { SplashLoaderType } from '../SplashLoader/SplashLoader-types';
import { fetchContractStatus } from '../Contract/Contract-helpers';
import { FEATURE_TOGGLE_CONTRACTS_TREE } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import { fetchUpdatePartiesForContractVersion } from '@thought-river/negotiations-common/dist/api/contractContent';
import { mapPartiesToContractVersionParties } from '@modules/common/helpers';

const { PARTY_CONTRACT, PARTY_SIDEBAR } = ContractPane;

@analytics()
class PartyCorrection extends Component<
    IPartyCorrectionProps,
    IPartyCorrectionState
> {
    constructor(props: IPartyCorrectionProps) {
        super(props);

        this.state = {
            contract: null!, // Fixme: null checks
            version: null!, // Fixme: null checks
            submitting: false,
        };

        this.cancelPartyCorrection = this.cancelPartyCorrection.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        document.title = PAGE_TITLE_PARTY_CORRECTION;
        const contractId = this.props.match.params['id'];
        const stream = this.props.match.params['stream'];
        this.props.setPartyCorrectionStatus(PartyCorrectionStatus.LOADING);
        this.props.setHighlighting(false);
        this.props.resetSelections();
        await this.props.setContractId(contractId);
        await this.loadContract(contractId, stream);
    }

    async componentWillUnmount() {
        const { setLocalParties } = this.props;

        setLocalParties([]);
    }

    private cancelPartyCorrection() {
        this.props.history.push('/');
    }

    async loadContract(contractId: string, stream: string) {
        const { localParties } = this.props;

        try {
            const contract = await ContractResource.getContract(
                contractId,
                stream
            );
            const contractStatus = await fetchContractStatus(contractId);
            const versions = getRelationship(contract, 'versions');
            const versionId = getLatestRelationshipId(versions);
            const version = await VersionResource.getVersion(
                contractId,
                versionId!, // Fixme: null checks
                stream
            );
            const paragraphs = await ParagraphResource.getParagraphs(
                contractId,
                versionId!, // Fixme: null checks
                stream
            );
            const parties = localParties.length
                ? localParties
                : mapPartiesToOverrides(getAttribute(version, 'parties'));
            this.props.setParties(parties);

            this.setState({
                contract: mapContract(contract.data, [], contractStatus[0]),
                version: mapVersion(version.data),
            });

            this.props.setContractId(contractId);
            this.props.setVersionId(versionId!); // Fixme: null checks
            this.props.setParagraphs(
                paragraphs.data.map((paragraph, index) =>
                    mapParagraph(paragraph, [], index)
                )
            );

            this.props.setPartyCorrectionStatus(PartyCorrectionStatus.LOADED);
        } catch (error) {
            this.props.setPartyCorrectionStatus(
                PartyCorrectionStatus.ERROR,
                'Failed to load the contract.'
            );
            return;
        }
    }

    validateForm(parties: IParty[]): string[] {
        const errors: string[] = [];

        if (
            !parties.filter((party) => party.type === PartiesType.OWN_PARTIES)
                .length
        ) {
            errors.push('Own party');
        }

        if (
            !parties.filter(
                (party) => party.type === PartiesType.COUNTER_PARTIES
            ).length
        ) {
            errors.push('Counter party');
        }

        return errors;
    }

    async updatePartiesViaContractContent() {
        const { versionId, parties } = this.props;

        await fetchUpdatePartiesForContractVersion({
            pathParams: {
                contractVersionUuid: versionId,
            },
            body: mapPartiesToContractVersionParties(parties),
        });
    }

    async updateParties() {
        const {
            featureToggles,
            history,
            setReprocessingContract,
            versionId,
            userId,
        } = this.props;
        const { contract } = this.state;
        const { id: contractId, contractName, streamCode } = contract;

        const contractsTreeEnabled = !!featureToggles.find(
            (toggle) => toggle.feature === FEATURE_TOGGLE_CONTRACTS_TREE
        )?.enabled;

        try {
            this.setState({
                submitting: true,
            });

            await this.updatePartiesViaContractContent();

            if (!contractsTreeEnabled) {
                const assessments = await AssessmentResource.getAssessments(
                    contractId,
                    versionId,
                    streamCode
                );

                setReprocessingContract(
                    contractId,
                    contractName,
                    versionId,
                    streamCode,
                    contract.streamId,
                    assessments.data.length,
                    userId
                );
            }

            this.setState({
                submitting: false,
            });

            if (contractsTreeEnabled) {
                window.close();
            } else {
                history.push('/');
            }
        } catch (error) {
            this.setState({
                submitting: false,
            });
            toast.error('Updating parties has failed');
            return;
        }
    }

    private async updatePartiesNoDialogue() {
        await this.updateParties();
    }

    private onDialogueContinue() {
        this.updateParties();
        this.props.hideDialog();
    }

    private openConfirmationDialogue() {
        const { showDialog } = this.props;

        showDialog(
            'Set Party Confirmation',
            true,
            <p>
                One or more parties is missing. Do you want to continue with the
                review?
            </p>,
            [
                {
                    label: 'Cancel',
                    type: ModalDialogButtonType.Negative,
                    enabled: true,
                    callback: () => this.props.hideDialog(),
                },
                {
                    label: 'Continue',
                    type: ModalDialogButtonType.Positive,
                    enabled: true,
                    callback: () => this.onDialogueContinue(),
                },
            ],
            null! // Fixme: null checks
        );
    }

    async onSubmit() {
        const { parties } = this.props;
        const formErrors = this.validateForm(parties);

        if (formErrors.length) {
            this.openConfirmationDialogue();
            return;
        }

        await this.updatePartiesNoDialogue();
    }

    onDragEnd(event: any) {
        event.preventDefault();
        this.props.setDraggedParty(null!); // Fixme: null checks
    }

    onDragOver(event: any) {
        event.preventDefault();
    }

    validateUrlParams(urlParams: IPartyCorrectionUrlParams) {
        const { contractId } = urlParams;

        const errorMessage =
            'This page requires a Contract ID to be provided in the URL.';

        if (!contractId) {
            throw new Error(errorMessage);
        }
    }

    render() {
        const { status, statusMessage } = this.props;
        const { contract, submitting } = this.state;

        return (
            <div className="party-correction-wrapper">
                <PartyCorrectionHeader
                    onCancel={this.cancelPartyCorrection}
                    contract={contract}
                    onSubmitCallback={this.onSubmit}
                    submitting={submitting}
                />
                <div
                    className="party-correction-container"
                    onDrop={(event) => this.onDragEnd(event)}
                    onDragOver={this.onDragOver}
                >
                    {status === PartyCorrectionStatus.LOADED ? (
                        <div className="party-correction-inner">
                            <div className="content">
                                <PartyContract />
                                <PaneDivider
                                    leftPane={PARTY_CONTRACT}
                                    rightPane={PARTY_SIDEBAR}
                                />
                                <PartySidebar />
                            </div>
                        </div>
                    ) : null}
                    {status === PartyCorrectionStatus.LOADING ? (
                        <SplashLoader message="Loading contract..." />
                    ) : null}
                    {status === PartyCorrectionStatus.ERROR ? (
                        <SplashLoader
                            message={statusMessage}
                            type={SplashLoaderType.MESSAGE}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}

export default PartyCorrection;
