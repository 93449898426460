import { useAppSelector } from '@modules/common/hooks';
import DocumentViewer from '@modules/common/components/DocumentViewer/DocumentViewer';
import PropertyDetails from '../../../../../../components/PropertyDetails/PropertyDetails';
import { useState } from 'react';
import { LexiblePropertyDetailsButton } from '@modules/common/components/LexiblePropertyDetailsButton';
import SearchBanner from './SearchBanner';
import {
    Icon,
    Drawer,
    IconButton,
    Tooltip,
} from '@thought-river/ui-components';
import { ContractViewerSidebarProps } from './types';
import { useContractSearchContext } from '@modules/common/context/ContractSearchProvider';

import styles from './ContractViewerSidebar.module.scss';

const CONTRACT_REPORT_SIDEBAR_WIDTH_LOCAL_STORAGE_KEY =
    'contractReportSidebarWidth';

const ContractViewerSidebar = ({
    onClose,
    documentHTML,
}: ContractViewerSidebarProps) => {
    const { onSearchTermChange } = useContractSearchContext();

    const paragraphs = useAppSelector((state) => state.contract.paragraphs);
    const policies = useAppSelector((state) => state.contract.policies);
    const properties = useAppSelector((state) => state.contract.properties);
    const selectedPropertyCode = useAppSelector(
        (state) => state.contract.selectedPropertyCode
    );
    const selectedProperty = properties.find(
        (p) => p.code === selectedPropertyCode
    );

    const [isPropertyDetailsOpen, setIsPropertyDetailsOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const [sidebarWidth, setSidebarWidth] = useState(
        Number(
            localStorage.getItem(
                CONTRACT_REPORT_SIDEBAR_WIDTH_LOCAL_STORAGE_KEY
            )
        ) || 500
    );

    const handleOnResizeStop = (newWidth: number) => {
        localStorage.setItem(
            CONTRACT_REPORT_SIDEBAR_WIDTH_LOCAL_STORAGE_KEY,
            newWidth.toString()
        );
        setSidebarWidth(newWidth);
    };

    const handleSearchToggleClick = () => {
        setIsSearchOpen(!isSearchOpen);
        onSearchTermChange('');
    };

    return (
        <>
            <Drawer
                open
                width={sidebarWidth}
                //Close the sidebar if user clicked outside it and it wasn't a linked clause button
                // onClickAway={(e) => {
                //     !(e.target as HTMLElement)?.id?.includes('clause-btn') &&
                //         onClose();
                // }}
                title="Contract View"
                noPadding
                resizable
                resizableProps={{
                    minWidth: 500,
                    maxWidth: 1200,
                    onResizeStop: (_, __, ___, d) => {
                        handleOnResizeStop(sidebarWidth + d.width);
                    },
                }}
                onClose={onClose}
                headerButtons={
                    <Tooltip
                        title={
                            isSearchOpen
                                ? 'Navigate linked clauses'
                                : 'Search contract'
                        }
                    >
                        <IconButton onClick={handleSearchToggleClick}>
                            <Icon type={isSearchOpen ? 'link' : 'search'} />
                        </IconButton>
                    </Tooltip>
                }
            >
                <div className={styles.content}>
                    {isSearchOpen && (
                        <SearchBanner onClose={() => setIsSearchOpen(false)} />
                    )}
                    <DocumentViewer
                        documentHTML={documentHTML ?? ''}
                        paragraphs={paragraphs}
                        inSidebar
                        bannerActionButtons={
                            <div className={styles.bannerButtons}>
                                <LexiblePropertyDetailsButton
                                    onClick={() =>
                                        setIsPropertyDetailsOpen(true)
                                    }
                                />
                            </div>
                        }
                    />
                </div>

                {selectedProperty && (
                    <PropertyDetails
                        open={isPropertyDetailsOpen}
                        onClose={() => setIsPropertyDetailsOpen(false)}
                        width={sidebarWidth}
                        policies={policies}
                        property={selectedProperty}
                    />
                )}
            </Drawer>
        </>
    );
};

export default ContractViewerSidebar;
