import {
    HighlightIndexType,
    IContractAssesssment,
    IContractProperty,
    IContractVersion,
    IDisplayContract,
    ISelectedContractIntelligence,
} from '../Contract/Contract-types';
import {
    IIssueFiltersValues,
    IssuesSortOption,
    PresetIssueFilters,
} from '../IssueListFilters/IssueListFilters-types';
import { IContractParagraph } from '../Paragraph/Paragraph-types';
import { IFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import { ILexibleProperty } from '../App/App-types';
import {
    ContractIssue,
    IssueStatus,
    LexibleTheme,
} from '@thought-river/negotiations-common';
import { setSelectedParagraphAction } from '../Contract/Contract-actions';
import { IssuePaneContextValue } from '@modules/common/context/IssuePaneProvider/types';

export const MISSING_RISK = 'Missing';
export const ADD_ISSUE = 'add_issue';

export enum IssueCreationType {
    AUTO = 'auto',
    MANUAL = 'manual',
}

export enum IssueDisplay {
    PRESENT = 'Present',
    NOT_DETECTED = 'Not Detected',
}

export enum IssuePrivacySetting {
    INTERNAL = 'internal',
    PUBLIC = 'public',
}

export enum IssueStateInVersion {
    NEW = 'New',
    UPDATED = 'Updated',
    CLOSED = 'Closed',
    UNCHANGED = 'Unchanged',
}

export enum NewIssueCardPosition {
    ABOVE = 'above',
    BELOW = 'below',
}

export const statusSortOrder = {
    [IssueStatus.OPEN]: 1,
    [IssueStatus.CLOSED]: 2,
    [IssueStatus.IGNORED]: 3,
};

export interface IIssueListPaneState {
    showFilters: boolean;
}

export interface IIssueListPaneStateProps {
    activeIssueFilters: IIssueFiltersValues;
    assessment: IContractAssesssment;
    categories: IIssueCategory[];
    contract: IDisplayContract;
    highlightedParagraphs: IContractParagraph[];
    featureToggles: IFeatureToggle[];
    issues: ContractIssue[];
    lexibleProperties: ILexibleProperty[];
    paragraphs: IContractParagraph[];
    properties: IContractProperty[];
    selectedFilterPreset: keyof typeof PresetIssueFilters | '';
    selectedContractIntelligence: ISelectedContractIntelligence;
    selectedVersion: IContractVersion | null;
    sortBy: IssuesSortOption;
    themes: LexibleTheme[];
}

export interface IIssueListPaneReducer {}

export interface IIssueListPaneDispatchProps {
    setHighlightIndex: (
        index: number,
        highlightType: HighlightIndexType
    ) => void;
    setIssues: (issue: ContractIssue[]) => void;
    setIssuesSortByOption: (sortBy: IssuesSortOption) => void;
    setSelectedContractIntelligence: (
        intelligence: ISelectedContractIntelligence
    ) => void;
    setSelectedIssue: (selectedIssue: ContractIssue) => void;
    setSelectedIssueFilters: (
        selectedFilterPreset: keyof typeof PresetIssueFilters
    ) => void;
    setSelectedParagraph: typeof setSelectedParagraphAction;
}

export interface IIssueListPaneProps
    extends IIssueListPaneDispatchProps,
        IIssueListPaneStateProps,
        IssuePaneContextValue {
    filteredIssues: ContractIssue[];
}

export interface IIssueCategory {
    id: string;
    name: 'Compliance' | 'Uncommon Text' | 'Non-Contractual' | 'Commercial';
}
