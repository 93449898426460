import DataGridTextInput from '../DataGridTextInput/DataGridTextInput';
import { IEditDescriptionInputProps } from './EditDescriptionInput-types';

const EditDescriptionInput = (
    props: IEditDescriptionInputProps
): JSX.Element => (
    <DataGridTextInput
        cellParams={props}
        attribute={props.type}
        multiline={true}
        enableRichText={props.enableRichText}
        placeholder={props.placeholder}
    />
);

export default EditDescriptionInput;
