import * as React from 'react';
import ContentEditable from 'react-contenteditable';
import { toast } from 'react-toastify';

import {
    BusinessStatus,
    IContractVersion,
    IDisplayContract,
} from '../Contract/Contract-types';
import ContractProgressBar from '../ContractProgressBar/ContractProgressBar';
import {
    PROGRESS_BAR_BKG_COLOUR,
    PROGRESS_BAR_FILL_COLOUR,
} from '../ContractProgressBar/ContractProgressBar-types';

import {
    IInternalSharingModalProps,
    IInternalSharingModalState,
} from './InternalSharingModal-types';
import { IAccountUser } from '../Auth/Auth-types';
import { IDropdownOption } from './DropdownGroup/Dropdown/Dropdown-types';
import ContractResource from '../../resources/ContractResource';
import DropdownGroup from './DropdownGroup/DropdownGroup';
import { analytics } from './InternalSharingModal-analytics';
import { OperatingSystem } from '../App/App-types';
import { getOperatingSystem, sortUsers } from '../App/App-helpers';

@analytics()
class InternalSharingModal extends React.Component<
    IInternalSharingModalProps,
    IInternalSharingModalState
> {
    private readonly messageRef: React.RefObject<any>;

    constructor(props: IInternalSharingModalProps) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.onSelectReviewers = this.onSelectReviewers.bind(this);
        this.onMessageChange = this.onMessageChange.bind(this);

        this.state = {
            submitting: false,
        };

        this.messageRef = React.createRef();
    }

    async componentDidMount() {
        const {
            contract,
            userId,
            users,
            selectedVersion,
            setInternalSharingMessage,
            resetInternalSharingFilters,
            setInternalSharingReviewers,
        } = this.props;

        if (!selectedVersion) {
            return;
        }

        const currentUser = users.filter((user) => user.id === userId)[0];

        const message = this.renderDefaultMessage(
            contract,
            selectedVersion,
            currentUser
        );

        await setInternalSharingMessage(message);

        const reviewers = users.filter((user) =>
            contract.reviewerIds.includes(user.id)
        );

        await setInternalSharingReviewers(reviewers);

        await resetInternalSharingFilters();
    }

    formatUserOption(user: IAccountUser): IDropdownOption<string> {
        const option: IDropdownOption<string> = {
            value: user.id,
            label: `${user.firstName} ${user.lastName}`,
        };

        return option;
    }

    onMessageChange(event: any) {
        this.props.setInternalSharingMessage(event.target.value);
    }

    async onSelectReviewers(selectedReviewerIds: string[]) {
        const { users } = this.props;
        const reviewers = users.filter((user) =>
            selectedReviewerIds.includes(user.id)
        );
        await this.props.setInternalSharingReviewers(reviewers);
    }

    async onSubmit() {
        const { reviewers } = this.props;

        if (!reviewers.length) {
            toast.error('Please select at least one reviewer');
            return;
        }

        await this.shareInternally();
    }

    renderDefaultMessage(
        contract: IDisplayContract,
        selectedVersion: IContractVersion,
        user: IAccountUser
    ) {
        const { streamCode, id: contractId } = contract;
        const appUrl = window.location.origin;
        const contractUrl = encodeURI(
            `${appUrl}/#/stream/${streamCode}/contract/${contractId}/version/${selectedVersion.id}`
        );

        const name = user ? user.firstName : '';

        return `
            <div>
                <div>Hello,</div>
                <br/>
                <div>Here's the ThoughtRiver analysis for ${contract.contractName}.</div>
                <br/>
                <div><a href="${contractUrl}">${contractUrl}</a></div>
                <br/>
                <div>Thanks,</div>
                <br/>
                <div>${name}</div>
            </div>
        `;
    }

    async shareInternally() {
        const { contract, closeModal, reviewers } = this.props;

        await this.setState({
            submitting: true,
        });

        const reviewerIds = reviewers.map((reviewer) => reviewer.id);

        try {
            await ContractResource.patchReviewers(
                contract.id,
                reviewerIds,
                contract.streamCode
            );

            const subject = 'Sharing a contract';
            const message = this.messageRef.current.innerText;
            const body = encodeURIComponent(message);
            const separator =
                getOperatingSystem() === OperatingSystem.WINDOWS ? ';' : ',';
            const recipients = reviewers
                .map((reviewer) => reviewer.email)
                .join(separator);
            window.location.href = `mailto:${recipients}?subject=${subject}&body=${body}`;

            await this.setState({
                submitting: false,
            });

            toast.success('Contract shared successfully!');

            closeModal();
        } catch (error) {
            await this.setState({
                submitting: false,
            });
        }
    }

    render() {
        const {
            contract,
            closeModal,
            versions,
            message,
            reviewers,
            users,
            selectedVersion,
        } = this.props;
        const { submitting } = this.state;

        if (!contract) {
            return;
        }

        const progressBarDataSets = [
            {
                count: contract.totalClosedIssues,
                colour: PROGRESS_BAR_FILL_COLOUR,
                showCount: false,
            },
            {
                count: contract.totalIssues,
                colour: PROGRESS_BAR_BKG_COLOUR,
                showCount: true,
            },
        ];

        const latestVersion = contract.latestVersion;

        const reviewerOptions = [...users]
            .sort(sortUsers)
            .map(this.formatUserOption);

        return (
            <div className="internal-sharing-modal">
                <div className="contract-name">{contract.contractName}</div>
                <table className="progress-table">
                    <thead>
                        <tr>
                            <th>
                                Progress{' '}
                                <span className="table-header-subtitle">
                                    (Issues resolved)
                                </span>
                            </th>
                            <th className="text-center">Version</th>
                            <th className="text-center">Activity</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <ContractProgressBar
                                    dataSets={progressBarDataSets}
                                />
                            </td>
                            <td className="text-center">
                                {selectedVersion
                                    ? selectedVersion.versionNumber
                                    : versions.length}
                            </td>
                            <td className="text-center">
                                {latestVersion.businessStatus ===
                                BusinessStatus.UNSHARED ? (
                                    <i>{latestVersion.businessStatus}</i>
                                ) : (
                                    latestVersion.businessStatus
                                )}
                            </td>
                            <td className="text-center">
                                {latestVersion.formattedUpdatedAt}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="section">
                    <div className="section-title">Message</div>
                    <div className="section-subtitle">
                        E.g. to invite a new reviewer to ask for advice, to
                        share with internal business stakeholders
                    </div>
                    <ContentEditable
                        className="message"
                        innerRef={this.messageRef}
                        html={message}
                        onChange={this.onMessageChange}
                    />
                </div>
                <div className="section">
                    <div className="section-title">Share with</div>

                    <DropdownGroup
                        onSelectCallback={this.onSelectReviewers}
                        selectedIds={reviewers.map((reviewer) => reviewer.id)}
                        options={reviewerOptions}
                        placeholder="Select reviewer"
                    />
                </div>
                <div className="modal-dialog-window-footer">
                    <button className="btn flat" onClick={closeModal}>
                        Cancel
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={this.onSubmit}
                        disabled={submitting}
                    >
                        <span>
                            Share internally{' '}
                            <span className="btn-label-subtext">
                                (via Email)
                            </span>
                        </span>
                    </button>
                </div>
            </div>
        );
    }
}

export default InternalSharingModal;
