import { Component } from 'react';
import { toast } from 'react-toastify';
import ContractResource from '../../resources/ContractResource';
import { IAccountUser } from '../Auth/Auth-types';
import ShareNegotiationModalUser from './ShareNegotiationModalUser/ShareNegotiationModalUser';
import {
    IShareNegotiationModalProps,
    IShareNegotiationModalState,
} from './ShareNegotiationModal-types';
import { CheckCircle, Link } from '@mui/icons-material';
import './ShareNegotiationModal.scss';
import { copyTextToClipboard } from '../App/App-helpers';
import CustomButton from '../CustomButton/CustomButton';
import {
    Select,
    SelectOption,
    Avatar,
    Button,
} from '@thought-river/ui-components';
import { analytics } from './ShareNegotiationModal-analytics';
import ConfirmSetOwnerDialog from './ConfirmSetOwnerDialog';
import { IDisplayContract } from '../Contract/Contract-types';
import { UserType } from '@modules/common/types';

@analytics()
class ShareNegotiationModal extends Component<
    IShareNegotiationModalProps,
    IShareNegotiationModalState
> {
    constructor(props: IShareNegotiationModalProps) {
        super(props);

        const { contract } = this.props;

        this.state = {
            newUser: null,
            updatedUserIds: [],
            confirmSetOwnerUser: null,
            modifiedContract: { ...contract },
        };

        this.onRemoveUser = this.onRemoveUser.bind(this);
        this.updateUserType = this.updateUserType.bind(this);
        this.onSelectUser = this.onSelectUser.bind(this);
        this.handleConfirmSetOwnerDialogClose =
            this.handleConfirmSetOwnerDialogClose.bind(this);
    }

    private getUserOptions(
        allUsers: IAccountUser[],
        addedUserIds: string[],
        contractStreamCode: string
    ): SelectOption[] {
        return allUsers
            .filter((user) => user.streamCodes.includes(contractStreamCode))
            .filter((user) => !addedUserIds.includes(user.id))
            .map(this.mapUserOption);
    }

    private mapUserOption(user: IAccountUser): SelectOption {
        const userName = `${user.firstName} ${user.lastName}`;

        return {
            label: userName,
            value: user.id,
        };
    }

    private updateUserType(userId: string, newUserType: UserType) {
        const { users } = this.props;
        if (newUserType === UserType.OWNER) {
            const newOwner = users.find((user) => user.id === userId) ?? null;
            this.setState({
                confirmSetOwnerUser: newOwner,
            });
        }
    }

    private async handleConfirmSetOwnerDialogClose(shouldSetOwner: boolean) {
        const { contract } = this.props;

        const { confirmSetOwnerUser, modifiedContract } = this.state;

        if (shouldSetOwner && confirmSetOwnerUser) {
            const newOwnerId = confirmSetOwnerUser?.id;
            const previousOwnerId = contract.negotiatorId;

            try {
                this.updateContract({
                    ...modifiedContract,
                    negotiatorId: newOwnerId,
                });

                const response = await ContractResource.patchNegotiator(
                    contract.id,
                    newOwnerId,
                    contract.streamCode
                );

                if (response.ok) {
                    this.setState({
                        updatedUserIds: [
                            ...new Set([
                                ...this.state.updatedUserIds,
                                newOwnerId,
                                previousOwnerId,
                            ]),
                        ],
                    });
                }
            } catch {
                this.updateContract({
                    ...modifiedContract,
                    negotiatorId: previousOwnerId,
                });

                toast.error('Error updating the owner.');
            }
        }

        this.setState({
            confirmSetOwnerUser: null,
        });
    }

    private async onRemoveUser(removedUserId: string): Promise<void> {
        const { contract } = this.props;

        const { modifiedContract } = this.state;

        const previousReviewerIds = modifiedContract.reviewerIds;
        const newReviewerIds = previousReviewerIds.filter(
            (id) => id !== removedUserId
        );

        try {
            this.updateContract({
                ...modifiedContract,
                reviewerIds: newReviewerIds,
            });

            await ContractResource.patchReviewers(
                contract.id,
                newReviewerIds,
                contract.streamCode
            );
        } catch {
            this.updateContract({
                ...modifiedContract,
                reviewerIds: previousReviewerIds,
            });

            toast.error('Error updating the reviewers.');
        }
    }

    private async onSelectUser(
        selectedUserOption: SelectOption
    ): Promise<void> {
        const { contract, users } = this.props;

        const { modifiedContract } = this.state;

        if (!selectedUserOption) {
            return;
        }

        const previousReviewerIds = modifiedContract.reviewerIds;
        const newReviewerIds = [
            ...previousReviewerIds,
            selectedUserOption.value,
        ];

        try {
            this.updateContract({
                ...modifiedContract,
                reviewerIds: newReviewerIds,
            });
            this.setState({
                newUser:
                    users.find(
                        (user) => selectedUserOption.value === user.id
                    ) ?? null,
            });

            await ContractResource.patchReviewers(
                contract.id,
                newReviewerIds,
                contract.streamCode
            );
        } catch {
            this.updateContract({
                ...modifiedContract,
                reviewerIds: previousReviewerIds,
            });
            this.setState({
                newUser: null,
            });

            toast.error('Error updating the reviewers.');
        }
    }

    /**
     * Helper method to update contract both in local state and Redux
     */
    private updateContract(updatedContract: IDisplayContract) {
        const { setContract } = this.props;

        this.setState({
            modifiedContract: updatedContract,
        });

        setContract(updatedContract);
    }

    private onCopyUrl(url: string = '') {
        try {
            copyTextToClipboard(url, false);
        } catch {
            toast.error('Error copying URL');
        }
    }

    render() {
        const { contract, currentUserId, hideDialog, version, users } =
            this.props;

        const {
            newUser,
            updatedUserIds,
            confirmSetOwnerUser,
            modifiedContract,
        } = this.state;

        const appUrl = window.location.origin;
        const contractUrl = encodeURI(
            `${appUrl}/#/stream/${contract.streamCode}/contract/${contract.id}/version/${version.id}`
        );

        const ownerId = modifiedContract.negotiatorId;
        const addedUsers = users.filter((user) =>
            modifiedContract.reviewerIds.includes(user.id)
        );

        //User list order: current user -> owner -> the rest
        addedUsers.sort((a) => (a.id === ownerId ? -1 : 1));
        addedUsers.sort((a) => (a.id === currentUserId ? -1 : 1));

        const userOptions = this.getUserOptions(
            users,
            addedUsers.map((user) => user.id),
            contract.streamCode
        );

        return (
            <>
                <Select
                    className="invite-users-dropdown"
                    data-id="invite-users-dropdown"
                    placeholder="Type a name and hit enter"
                    label="Invite people"
                    multiple={false}
                    onChange={this.onSelectUser}
                    options={userOptions}
                    renderOption={(option) => (
                        <Avatar label={option.label} size="x-small" showLabel />
                    )}
                    // Passing null to prevent Select from keeping user name in input after selection
                    value={null as any}
                />
                <ul className="selected-users-list">
                    {addedUsers.map((user) => (
                        <ShareNegotiationModalUser
                            key={user.id}
                            currentUserId={currentUserId}
                            ownerId={ownerId}
                            user={user}
                            isUpdated={updatedUserIds.includes(user.id)}
                            isNew={newUser?.id === user.id}
                            onUserTypeChangeCallback={this.updateUserType}
                            onUserRemovedCallback={this.onRemoveUser}
                        />
                    ))}
                </ul>
                <div className="modal-footer">
                    <CustomButton
                        animatedButton
                        buttonType="text"
                        callback={() => this.onCopyUrl(contractUrl)}
                        iconBefore={<Link />}
                        iconAfter={<CheckCircle />}
                        textBefore="Copy Link"
                        textAfter="Link Copied"
                    />
                    <Button data-id="done-btn" onClick={hideDialog}>
                        Done
                    </Button>
                </div>

                {confirmSetOwnerUser && (
                    <ConfirmSetOwnerDialog
                        onClose={this.handleConfirmSetOwnerDialogClose}
                        newOwner={confirmSetOwnerUser}
                    />
                )}
            </>
        );
    }
}

export default ShareNegotiationModal;
