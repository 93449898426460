import {
    IPartyContractActionTypes,
    SET_PARTY_PARAGRAPHS,
    IPartyContractReducer,
    SET_PARTY_CONTRACT_WIDTH_IN_PIXELS,
} from './PartyContract-types';

const initialState: IPartyContractReducer = {
    widthPixels: 0,
    paragraphs: [],
};

const PartyContractReducer = (
    state = initialState,
    action: IPartyContractActionTypes
): IPartyContractReducer => {
    switch (action.type) {
        case SET_PARTY_PARAGRAPHS:
            return {
                ...state,
                paragraphs: action.paragraphs,
            };
        case SET_PARTY_CONTRACT_WIDTH_IN_PIXELS:
            return {
                ...state,
                widthPixels: action.widthPixels,
            };
        default:
            return state;
    }
};

export default PartyContractReducer;
