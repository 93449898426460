export enum DropdownTheme {
    DARK = 'dark',
    LIGHT = 'light',
}

export interface IDropdownState {
    expanded: boolean;
    searchTerm: string;
    outsideClickListener: boolean;
}

export interface IDropdownStateProps {}

export interface IDropdownReducer {}

export interface IDropdownDispatchProps {}

export interface IDropdownProps<T>
    extends IDropdownDispatchProps,
        IDropdownStateProps {
    onSelectCallback: any;
    options: IDropdownOption<T>[];
    selectedOption: IDropdownOption<T>;
    placeholder: string;
    searchPlaceholder?: string;
    searchable?: boolean;
    className?: string;
    disabled?: boolean;
    colourTheme?: DropdownTheme;
}

export interface IDropdownOption<T> {
    value: T;
    label: any;
}

export type IDropdownActionTypes = any;
