import saveAs from 'file-saver';
import { FileFormat } from '../types';
import { fetchGetFile } from '@thought-river/negotiations-common/dist/api/contractManagement';
import { stripExtension } from '../../../components/App/App-helpers';
import { toast } from 'react-toastify';

export const downloadContract = async (
    versionId: string,
    format: FileFormat,
    contractName: string
) => {
    try {
        const response = await fetchGetFile({
            pathParams: {
                uuid: versionId,
                fileType: format === FileFormat.DOCX ? 'ast_docx' : 'original',
            },
        });

        const filename = `${stripExtension(contractName)}.${format}`;

        saveAs(response as unknown as Blob, filename);

        toast.success('Contract downloaded successfully!');
    } catch {
        toast.error('Error downloading the contract');
    }
};
