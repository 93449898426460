import { DeleteReportDialogProps } from './types';
import { Button, Dialog } from '@thought-river/ui-components';
import { useQueryClient } from '@tanstack/react-query';
import { useDeleteReportDefinition } from '@thought-river/negotiations-common/dist/api/playbookManager';
import { useHistory, useLocation } from 'react-router-dom';
import { removeFromTanStackQueryReportDefinitionsData } from '@modules/reports/portfolioReports';
import styles from './DeleteReportDialog.module.scss';

const DeleteReportDialog = ({
    onClose,
    reportDefinition,
}: DeleteReportDialogProps) => {
    const location = useLocation();
    const history = useHistory();

    const queryClient = useQueryClient();
    const { mutate: deleteReportDefinition } = useDeleteReportDefinition({});

    const handleDeleteSuccess = () => {
        if (location.pathname.includes(reportDefinition.id_str ?? '')) {
            history.push('/reports');
        }
        removeFromTanStackQueryReportDefinitionsData(
            queryClient,
            reportDefinition.id_str ?? ''
        );
        onClose();
    };

    const handleDelete = () => {
        deleteReportDefinition(
            {
                pathParams: {
                    reportDefinitionId: reportDefinition.id_str ?? '',
                },
            },
            {
                onSuccess: handleDeleteSuccess,
            }
        );
    };

    return (
        <Dialog
            onClose={onClose}
            dialogTitle="Confirm delete?"
            dialogContent={
                <div className={styles.dialogContent}>
                    <div>
                        Please confirm you wish to delete the{' '}
                        {reportDefinition.name} report.
                    </div>
                    <div>This action cannot be undone.</div>
                </div>
            }
            dialogActions={
                <>
                    <Button onClick={onClose} variant="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} variant="destructive">
                        Confirm
                    </Button>
                </>
            }
            open
        />
    );
};

export default DeleteReportDialog;
