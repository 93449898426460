import dayjs from 'dayjs';
import { GridComparatorFn } from '@mui/x-data-grid-premium';
import { ContractsReportColumn } from '@thought-river/negotiations-common/dist/api/contractContent';
import { PropertyOut } from '@thought-river/negotiations-common/dist/api/playbookManager';
import { getDefaultSortComparator } from '@modules/reports/common';

// Custom comparator for cells displaying date property value
// The sorting order rules are:
// 1. Empty values (question not asked)
// 2. Parsed dates
// 3. Values that are invalid dates
const datePropertySortComparator: GridComparatorFn = (v1, v2) => {
    const v1Date = dayjs(v1);
    const v2Date = dayjs(v2);

    // If values are missing (question not asked)
    if (!v1 && !v2) {
        return 0;
    }
    if (!v1) {
        return -1;
    }
    if (!v2) {
        return 1;
    }

    // If values are not valid dates
    if (!v1Date.isValid() && !v2Date.isValid()) {
        return String(v1).localeCompare(String(v2), undefined, {
            numeric: true,
            sensitivity: 'base',
        });
    }
    if (!v1Date.isValid()) {
        return 1;
    }
    if (!v2Date.isValid()) {
        return -1;
    }

    return v1Date.unix() - v2Date.unix();
};

export const getSortComparator = (
    column: ContractsReportColumn,
    columnProperty: PropertyOut | null
) => {
    if (columnProperty && columnProperty.value_type === 'date') {
        return datePropertySortComparator;
    }

    return getDefaultSortComparator(column.type);
};
