import { ParagraphClause } from '@thought-river/negotiations-common';

export const YES_VALUE = '1';
export const NO_VALUE = '2';

export interface ILexiblePropertyUpdateParams {
    dfCode: string;
    value: string;
    paragraphIndex?: string;
}

export interface ILinkUnlinkPropertyOptions {
    code: string;
    unlink: boolean;
    unlinkValue: string;
    linkValue: string;
    removedParagraphs: ParagraphClause[];
    newParagraphs: ParagraphClause[];
}
