import { setSelectedParagraphAction } from '../Contract/Contract-actions';
import {
    HighlightIndexType,
    IDisplayContract,
    ISelectedContractIntelligence,
} from '../Contract/Contract-types';
import { ContractSidebarTab } from '../ContractSidebar/ContractSidebar-types';
import { IFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import { IIssueFiltersValues } from '../IssueListFilters/IssueListFilters-types';
import { IContractParagraph } from '../Paragraph/Paragraph-types';

export const MIN_NODE_HEIGHT = 8;
export const SCROLLBAR_VERTICAL_PADDING = 22.5;

export interface IHeatmapScrollbarState {
    height: number;
}

export interface IHeatmapScrollbarStateProps {
    activeIssueFilters: IIssueFiltersValues;
    activeSidebarTab: ContractSidebarTab;
    contract: IDisplayContract;
    contractScrollHeightInPixels: number;
    contractScrollTop: number;
    contractVisibleHeightInPixels: number;
    featureToggles: IFeatureToggle[];
    highlightedParagraphs: IContractParagraph[];
    paragraphHeightsInPixels: number[];
    selectedContractIntelligence: ISelectedContractIntelligence;
    selectedParagraph: IContractParagraph | null;
}

export interface IHeatmapScrollbarReducer {}

export interface IHeatmapScrollbarDispatchProps {
    setSelectedParagraph: typeof setSelectedParagraphAction;
    setHighlightIndex: (
        index: number,
        highlightType: HighlightIndexType
    ) => void;
}

export interface IHeatmapScrollbarProps
    extends IHeatmapScrollbarDispatchProps,
        IHeatmapScrollbarStateProps {
    paragraphs: IContractParagraph[];
}

export interface IHeatmapScrollbarState {
    height: number;
}

export type IHeatmapScrollbarActionTypes = any;
