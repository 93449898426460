import Api from '../services/api';
import { Method, requestOptions } from '../services/api-types';
import {
    IContractFilters,
    IContractResource,
    IContractResourceStatic,
    IContractResponse,
    IContractsResponse,
    ICreateContractPayload,
    IReprocessRequestPayload,
    ReprocessAnalysisType,
} from './ContractResource-types';

const ContractResource: IContractResourceStatic = class ContractResource
    implements IContractResource
{
    static async getContract(
        contractId: string,
        stream: string
    ): Promise<IContractResponse> {
        const options = {
            ...requestOptions,
            streamCode: stream,
        };

        return await (await Api.request(
            Method.GET,
            `/contracts/${contractId}`,
            options
        ))!.json();
    }

    static async deleteContract(
        contractId: string,
        stream: string
    ): Promise<Response> {
        const options = {
            ...requestOptions,
            streamCode: stream,
        };

        return (await Api.request(
            Method.DELETE,
            `/contracts/${contractId}`,
            options
        ))!;
    }

    static async getContracts(
        page: number | null,
        filters: IContractFilters,
        includeVersions: boolean = false
    ): Promise<IContractsResponse> {
        const queryParams = ['filter[all-streams]=1'];

        if (page !== null) {
            queryParams.push(`page[number]=${page}`);
        }

        if (includeVersions) {
            queryParams.push('include=versions');
        }

        for (const key of Object.keys(filters)) {
            const prefix = `filter[${key}]=`;

            if (typeof filters[key] === 'string' && filters[key] !== '') {
                queryParams.push(
                    `${prefix}${encodeURIComponent(filters[key])}`
                );
            }

            if (typeof filters[key] === 'boolean') {
                queryParams.push(`${prefix}${filters[key] ? '1' : '0'}`);
            }
        }

        const options = {
            ...requestOptions,
            includeStream: false,
        };

        return await (await Api.request(
            Method.GET,
            `/contracts?${queryParams.join('&')}`,
            options
        ))!.json();
    }

    static async postContract(name: string, stream: string): Promise<Response> {
        const payload: ICreateContractPayload = {
            data: { attributes: { name } },
        };

        const options = {
            ...requestOptions,
            streamCode: stream,
            payload,
            noRedirect: true,
        };

        return (await Api.request(Method.POST, '/contracts', options))!;
    }

    static async patchContract(
        contractId: string,
        name: string,
        stream: string
    ): Promise<Response> {
        const payload: ICreateContractPayload = {
            data: { attributes: { name } },
        };

        const options = {
            ...requestOptions,
            streamCode: stream,
            payload,
            noRedirect: true,
        };

        return (await Api.request(
            Method.PATCH,
            `/contracts/${contractId}`,
            options
        ))!;
    }

    static async patchReviewers(
        contractId: string,
        reviewers: string[],
        streamCode: string
    ): Promise<Response> {
        const payload = { attributes: { reviewers } };

        const options = {
            ...requestOptions,
            payload,
            streamCode,
        };

        return (await Api.request(
            Method.PATCH,
            `/contracts/${contractId}/reviewers`,
            options
        ))!;
    }

    static async patchNegotiator(
        contractId: string,
        negotiator: string,
        streamCode: string
    ): Promise<Response> {
        const payload = { attributes: { negotiator } };

        const options = {
            ...requestOptions,
            payload,
            streamCode,
        };

        return (await Api.request(
            Method.PATCH,
            `/contracts/${contractId}/negotiator`,
            options
        ))!;
    }

    static async reprocess(
        contractIds: string[],
        streamCode?: string,
        analysisType: ReprocessAnalysisType = ReprocessAnalysisType.COMPLETE
    ): Promise<any> {
        const payload: IReprocessRequestPayload = {
            analysisType,
            data: {
                relationships: {
                    contracts: {
                        data: contractIds.map((contractId) => ({
                            id: contractId,
                        })),
                    },
                },
            },
        };

        let options = {
            ...requestOptions,
            payload,
        };

        if (streamCode) {
            options = { ...options, streamCode };
        }

        return await Api.request(Method.POST, '/reprocess', options);
    }
};

export default ContractResource;
