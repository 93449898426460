import { IconButton } from '@mui/material';
import { Component } from 'react';
import { Delete } from '@mui/icons-material';
import './ShareNegotiationModalUser.scss';
import {
    IShareNegotiationModalUserProps,
    IShareNegotiationModalUserState,
} from './ShareNegotiationModalUser-types';
import { Select, SelectOption, Avatar } from '@thought-river/ui-components';
import { UserType } from '@modules/common/types';

class ShareNegotiationModalUser extends Component<
    IShareNegotiationModalUserProps,
    IShareNegotiationModalUserState
> {
    render() {
        const {
            user,
            currentUserId,
            ownerId,
            isUpdated,
            isNew,
            onUserTypeChangeCallback,
            onUserRemovedCallback,
        } = this.props;

        const userName = `${user.firstName} ${user.lastName}`;
        const isCurrentUser = user.id === currentUserId;
        const isOwner = user.id === ownerId;

        const isOwnerCurrentUser = ownerId === currentUserId;

        const options = [
            { value: UserType.OWNER, label: 'Owner' },
            { value: UserType.REVIEWER, label: 'Reviewer' },
        ];

        const value = isOwner ? options[0] : options[1];

        return (
            <li className={`added-user ${isNew ? 'new-added-user' : ''} `}>
                <div className="added-user-icon">
                    <Avatar label={userName} size="medium" />
                </div>
                <div className="added-user-details">
                    <div className="added-user-name">{`${userName} ${
                        isCurrentUser ? '(You)' : ''
                    }`}</div>
                    <div className="added-user-email">{user.email}</div>
                </div>

                {!isOwner && !isCurrentUser && (
                    <IconButton
                        className="delete-added-user-btn"
                        title="Remove user"
                        aria-label="delete"
                        size="small"
                        onClick={() => onUserRemovedCallback(user.id)}
                    >
                        <Delete fontSize="small" />
                    </IconButton>
                )}

                {isUpdated && <div className="user-updated-badge">UPDATED</div>}

                <Select
                    className="role-select"
                    disabled={isOwner || !isOwnerCurrentUser}
                    value={value}
                    onChange={(event: SelectOption) =>
                        onUserTypeChangeCallback(
                            user.id,
                            event.value as UserType
                        )
                    }
                    options={options}
                />
            </li>
        );
    }
}

export default ShareNegotiationModalUser;
