import { Component } from 'react';
import { CheckCircle, Close, Link } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { toast } from 'react-toastify';

import {
    IIssuesReportShareModalProps,
    IIssuesReportShareModalState,
} from './IssuesReportShareModal-types';
import './IssuesReportShareModal.scss';
import { copyTextToClipboard } from '../../App/App-helpers';
import { analytics } from './IssuesReportShareModal-analytics';
import CustomButton from '../../CustomButton/CustomButton';

@analytics()
class IssuesReportShareModal extends Component<
    IIssuesReportShareModalProps,
    IIssuesReportShareModalState
> {
    constructor(props: IIssuesReportShareModalProps) {
        super(props);

        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    private handleCloseModal() {
        this.props.closeModal();
    }

    private onCopyUrl(url: string = '') {
        try {
            copyTextToClipboard(url, false, 'copy-report-url-input');
        } catch {
            toast.error('Error copying URL');
        }
    }

    render() {
        const { contract, streams, version } = this.props;

        const appUrl = window.location.origin;
        const reportUrl = `${appUrl}/#/stream/${contract.streamCode}/contract/${contract.id}/version/${version?.id}/report`;
        const stream = streams.find(
            (stream) => stream.accountCode === contract.streamCode
        );

        return (
            <div className="issues-report-modal issues-report-share-modal">
                <div className="issues-report-modal-header">
                    <div className="title">Share Report</div>
                    <Close
                        className="close-btn"
                        onClick={this.handleCloseModal}
                    />
                </div>
                <div className="issues-report-modal-content">
                    <div>
                        Users must be assigned to the deal type
                        {stream ? ` (${stream.accountName})` : ''} to access
                        this link.
                    </div>
                    <div className="copy-link-wrapper">
                        <TextField
                            className="link-input"
                            id="copy-report-url-input"
                            fullWidth={true}
                            InputProps={{
                                readOnly: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Link className="link-icon" />
                                    </InputAdornment>
                                ),
                            }}
                            value={reportUrl}
                            variant="outlined"
                            size="small"
                        />
                        <CustomButton
                            animatedButton
                            callback={() => {
                                this.onCopyUrl(reportUrl);
                            }}
                            iconBefore={<Link />}
                            iconAfter={<CheckCircle />}
                            textBefore="Copy Link"
                            textAfter="Link Copied"
                        />
                    </div>
                </div>
                <div className="modal-footer issues-report-modal-footer">
                    <CustomButton
                        buttonType="text"
                        onClick={this.handleCloseModal}
                    >
                        Cancel
                    </CustomButton>
                    <CustomButton onClick={this.handleCloseModal}>
                        Done
                    </CustomButton>
                </div>
            </div>
        );
    }
}

export default IssuesReportShareModal;
