import { MutableRefObject, useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { GridPinnedColumns } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import omit from 'lodash/omit';

interface UsePersistDataGridStateParams {
    onConfigChange: (config: GridInitialStatePremium) => void;
    onOrderedFieldsChange: (orderedFields: string[]) => void;
    apiRef: MutableRefObject<GridApiPremium>;
}

/**
 * Hook that returns a selection of DataGrid callback props that can be
 * passed to the DataGrid. When a given config param changes, it calls
 * the debounced `onConfigChange` callback with updated values.
 */
export const usePersistDataGridState = ({
    onConfigChange,
    onOrderedFieldsChange,
    apiRef,
}: UsePersistDataGridStateParams) => {
    const debouncedOnConfigChange = useMemo(
        () =>
            debounce((config) => {
                onConfigChange(config);
            }, 1000),
        [onConfigChange]
    );

    const updateState = useCallback(() => {
        const config = apiRef.current.exportState();
        debouncedOnConfigChange(omit(config, 'preferencePanel'));

        onOrderedFieldsChange(config.columns?.orderedFields ?? []);
    }, [apiRef, debouncedOnConfigChange, onOrderedFieldsChange]);

    const onPinnedColumnsChange = (pinnedColumns: GridPinnedColumns) => {
        const config = apiRef.current.exportState();
        const modifiedConfig = { ...config, pinnedColumns: pinnedColumns };
        debouncedOnConfigChange(modifiedConfig);
    };

    return {
        onColumnVisibilityModelChange: updateState,
        onColumnOrderChange: updateState,
        onPinnedColumnsChange: onPinnedColumnsChange,
        onRowGroupingModelChange: updateState,
        onSortModelChange: updateState,
        onFilterModelChange: updateState,
    };
};
