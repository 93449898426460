import { IRootState } from '../../../../redux/reducers-types';
import { reduxStore } from '../../../../redux/store';
import { analyticsService } from '../../../../services/Analytics/Analytics';
import { EVENT_ACTION_SELECT_PARA_IN_INTELLIGENCE_TAB } from '../../../../services/Analytics/Analytics-types';

export function analytics() {
    return (target: any) => {
        const onSelectParagraph = target.prototype.onSelectParagraph;

        target.prototype.onSelectParagraph = function () {
            const { contract } = reduxStore.store.getState() as IRootState;

            analyticsService.recordEvent(
                EVENT_ACTION_SELECT_PARA_IN_INTELLIGENCE_TAB,

                {
                    ContractId: contract.contract?.id,
                    VersionId: contract.selectedVersion?.id,
                    VersionNumber: contract.selectedVersion?.versionNumber,
                }
            );

            onSelectParagraph.apply(this, arguments);
        };
    };
}
