import { removeHtmlTags } from '@modules/common/helpers';
import {
    GridColType,
    GridComparatorFn,
    gridDateComparator,
    gridNumberComparator,
    gridStringOrNumberComparator,
} from '@mui/x-data-grid-premium';

const htmlSortComparator: GridComparatorFn = (a, b) => {
    const parsedA = a ? removeHtmlTags(a) : '';
    const parsedB = b ? removeHtmlTags(b) : '';
    return parsedA.localeCompare(parsedB);
};

export const getDefaultSortComparator = (columnType: GridColType) => {
    if (columnType === 'string' || columnType === 'singleSelect') {
        return gridStringOrNumberComparator;
    }
    if (columnType === 'number' || columnType === 'boolean') {
        return gridNumberComparator;
    }
    if (columnType === 'date' || columnType === 'dateTime') {
        return gridDateComparator;
    }
    if (columnType === 'html') {
        return htmlSortComparator;
    }
    return () => 0;
};
