import { analyticsService } from '../../services/Analytics/Analytics';
import {
    EVENT_ACTION_SET_PARTIES_CONFIRMATION_CONTINUE,
    EVENT_ACTION_SPECIFY_PARTIES,
} from '../../services/Analytics/Analytics-types';
import { IPartyCorrectionState } from './PartyCorrection-types';

export function analytics() {
    return (target: any) => {
        const updatePartiesNoDialogue =
            target.prototype.updatePartiesNoDialogue;
        const onDialogueContinue = target.prototype.onDialogueContinue;

        target.prototype.updatePartiesNoDialogue = function () {
            const { contract, version } = this.state as IPartyCorrectionState;

            analyticsService.recordEvent(
                EVENT_ACTION_SPECIFY_PARTIES,

                {
                    ContractId: contract?.id,
                    StreamId: contract?.streamCode,
                    VersionId: version?.id,
                    VersionNumber: version?.versionNumber,
                }
            );

            updatePartiesNoDialogue.apply(this, arguments);
        };

        target.prototype.onDialogueContinue = function () {
            const { contract, version } = this.state as IPartyCorrectionState;

            analyticsService.recordEvent(
                EVENT_ACTION_SET_PARTIES_CONFIRMATION_CONTINUE,

                {
                    ContractId: contract?.id,
                    StreamId: contract?.streamCode,
                    VersionId: version?.id,
                    VersionNumber: version?.versionNumber,
                }
            );

            onDialogueContinue.apply(this, arguments);
        };
    };
}
