import { IParagraphs } from 'types/thoughtriver';
import Api from '../services/api';
import { Method, requestOptions } from '../services/api-types';
import {
    IParagraphResourceStatic,
    IParagraphResource,
    IPatchParagraphPayload,
    IPatchParagraphsPayload,
    IGetPropertyParagraphsRequestParams,
} from './ParagraphResource-types';

const ParagraphResource: IParagraphResourceStatic = class ParagraphResource
    implements IParagraphResource
{
    static async getParagraphs(
        contractId: string,
        versionId: string,
        stream: string
    ): Promise<IParagraphs> {
        const url = `/contracts/${contractId}/versions/${versionId}/paragraphs`;

        const options = {
            ...requestOptions,
            streamCode: stream,
        };

        const response = await Api.request(Method.GET, url, options);
        const parsedResponse: IParagraphs = (await response?.json()) ?? [];

        // Ignore paragraph with index of -1
        const filteredData = parsedResponse.data.filter(
            (paragraph) => paragraph.attributes.index !== '-1'
        );

        return {
            data: filteredData,
        };
    }

    static async getPropertyParagraphs({
        assessmentId,
        contractId,
        propertyId,
        stream,
        versionId,
    }: IGetPropertyParagraphsRequestParams): Promise<IParagraphs> {
        const url = `/contracts/${contractId}/versions/${versionId}/assessments/${assessmentId}/properties/${propertyId}/paragraphs`;

        const options = {
            ...requestOptions,
            streamCode: stream,
        };

        return await (await Api.request(Method.GET, url, options))!.json();
    }

    static async patchParagraph(
        contractId: string,
        versionId: string,
        paragraphId: string,
        stream: string,
        payload: IPatchParagraphPayload
    ): Promise<Response> {
        const url = `/contracts/${contractId}/versions/${versionId}/paragraphs/${paragraphId}`;

        const options = {
            ...requestOptions,
            streamCode: stream,
            payload,
        };

        return (await Api.request(Method.PATCH, url, options))!;
    }

    static async patchParagraphs(
        contractId: string,
        versionId: string,
        stream: string,
        payload: IPatchParagraphsPayload
    ): Promise<Response> {
        const url = `/contracts/${contractId}/versions/${versionId}/paragraphs`;

        const options = {
            ...requestOptions,
            streamCode: stream,
            payload,
        };

        return (await Api.request(Method.PATCH, url, options))!;
    }
};

export default ParagraphResource;
