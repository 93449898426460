import { getDefaultSortComparator } from '@modules/reports/common';
import { GridComparatorFn } from '@mui/x-data-grid-premium';
import { ContractTreeColumn } from '@thought-river/negotiations-common/dist/api/contractManagement';

const reviewersSortComparator: GridComparatorFn = (a, b) => {
    const reviewerA = a[0],
        reviewerB = b[0];

    if ((!reviewerA && !reviewerB) || reviewerA === reviewerB) {
        return 0;
    }

    if (!reviewerA) {
        return -1;
    }

    if (!reviewerB) {
        return 1;
    }

    return reviewerA.localeCompare(reviewerB);
};

export const getSortComparator = (column: ContractTreeColumn) => {
    if (column.field === 'reviewers') {
        return reviewersSortComparator;
    }

    return getDefaultSortComparator(column.type);
};
