import dayjs from 'dayjs';
import { IIssueFiltersValues } from '../IssueListFilters/IssueListFilters-types';
import { IssueDisplay, statusSortOrder } from './IssueListPane-types';
import {
    ContractIssue,
    IssueSeverityLevel,
    capitalize,
    familiarityLevelSortOrder,
    issueSeveritySortOrder,
    ParagraphClause,
} from '@thought-river/negotiations-common';

export function formatClausesForSorting(
    clauseNumbers: ParagraphClause[]
): string {
    return clauseNumbers.map((clauseNumber) => clauseNumber.index).join(',');
}

export function sortByClause(
    issueA: ContractIssue,
    issueB: ContractIssue
): number {
    const issueAFirstClause = issueA.paragraphOriginUuids.length
        ? formatClausesForSorting(issueA.clauseNumbers)
        : formatClausesForSorting(issueA.missingSuggestionLocations);
    const issueBFirstClause = issueB.paragraphOriginUuids.length
        ? formatClausesForSorting(issueB.clauseNumbers)
        : formatClausesForSorting(issueB.missingSuggestionLocations);
    const issueASeverityValue = issueSeveritySortOrder[issueA.severityLevel];
    const issueBSeverityValue = issueSeveritySortOrder[issueB.severityLevel];

    return (
        issueAFirstClause.localeCompare(issueBFirstClause, undefined, {
            numeric: true,
            sensitivity: 'base',
        }) || issueASeverityValue - issueBSeverityValue
    );
}

export function sortByLastUpdatedDate(
    issueA: ContractIssue,
    issueB: ContractIssue
): number {
    if (dayjs(issueA.updatedAt).isAfter(issueB.updatedAt)) {
        return -1;
    }

    if (dayjs(issueA.updatedAt).isBefore(issueB.updatedAt)) {
        return 1;
    }

    return 0;
}

export function sortBySeverity(
    issueA: ContractIssue,
    issueB: ContractIssue
): number {
    const issueASeverityValue = issueSeveritySortOrder[issueA.severityLevel];
    const issueBSeverityValue = issueSeveritySortOrder[issueB.severityLevel];
    const issueAFirstClause = formatClausesForSorting(issueA.clauseNumbers);
    const issueBFirstClause = formatClausesForSorting(issueB.clauseNumbers);

    return (
        issueASeverityValue - issueBSeverityValue ||
        issueAFirstClause.localeCompare(issueBFirstClause, undefined, {
            numeric: true,
            sensitivity: 'base',
        })
    );
}

export function sortByStatus(
    issueA: ContractIssue,
    issueB: ContractIssue
): number {
    const issueAStatusValue = statusSortOrder[issueA.status];
    const issueBStatusValue = statusSortOrder[issueB.status];
    const issueAFirstClause = formatClausesForSorting(issueA.clauseNumbers);
    const issueBFirstClause = formatClausesForSorting(issueB.clauseNumbers);
    const issueASeverityValue = issueSeveritySortOrder[issueA.severityLevel];
    const issueBSeverityValue = issueSeveritySortOrder[issueB.severityLevel];

    return (
        issueAStatusValue - issueBStatusValue ||
        issueAFirstClause.localeCompare(issueBFirstClause, undefined, {
            numeric: true,
            sensitivity: 'base',
        }) ||
        issueASeverityValue - issueBSeverityValue
    );
}

export function sortByFamiliarityDesc(
    issueA: ContractIssue,
    issueB: ContractIssue
): number {
    const issueAFamiliarityValue =
        familiarityLevelSortOrder[
            issueA.organizationFamiliarity?.frequencyLevel!
        ] ?? // Fixme: null checks
        -1;
    const issueBFamiliarityValue =
        familiarityLevelSortOrder[
            issueB.organizationFamiliarity?.frequencyLevel!
        ] ?? // Fixme: null checks
        -1;
    const issueAFirstClause = formatClausesForSorting(issueA.clauseNumbers);
    const issueBFirstClause = formatClausesForSorting(issueB.clauseNumbers);
    const issueASeverityValue = issueSeveritySortOrder[issueA.severityLevel];
    const issueBSeverityValue = issueSeveritySortOrder[issueB.severityLevel];

    return (
        issueBFamiliarityValue - issueAFamiliarityValue ||
        issueASeverityValue - issueBSeverityValue ||
        issueAFirstClause.localeCompare(issueBFirstClause, undefined, {
            numeric: true,
            sensitivity: 'base',
        })
    );
}

export function sortBySummaryAsc(
    issueA: ContractIssue,
    issueB: ContractIssue
): number {
    return issueA.title.localeCompare(issueB.title, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
}

export function filterIssue(
    issue: ContractIssue,
    activeIssueFilters: IIssueFiltersValues
) {
    const {
        severity = [],
        status = [],
        themes = [],
        categories = [],
        displays = [],
        familiarity = [],
    } = activeIssueFilters;

    if (
        displays.length &&
        ((!displays.includes(IssueDisplay.PRESENT) &&
            issue.paragraphOriginUuids.length) ||
            (!displays.includes(IssueDisplay.NOT_DETECTED) &&
                !issue.paragraphOriginUuids.length))
    ) {
        return false;
    }

    if (severity.length && !severity.includes(issue.severityLevel)) {
        return false;
    }

    if (status.length && !status.includes(issue.status)) {
        return false;
    }

    if (
        familiarity.length &&
        issue.organizationFamiliarity?.frequencyLevel &&
        !familiarity.includes(issue.organizationFamiliarity.frequencyLevel)
    ) {
        return false;
    }

    if (
        themes.length &&
        !themes.some((t) => issue.nonAssessedThemeIds.includes(t.nonAssessedId))
    ) {
        return false;
    }

    if (
        categories.length &&
        !categories.some((filteredCategory) =>
            issue.categories.find(
                (issueCategory) => issueCategory.id === filteredCategory.id
            )
        )
    ) {
        return false;
    }

    return true;
}

export function resolveSeverityLevelValue(severity: IssueSeverityLevel) {
    return severity === IssueSeverityLevel.OK ? 'OK' : capitalize(severity);
}

export function renderSeverityDropdownLabel(severity: IssueSeverityLevel) {
    return capitalize(
        severity === IssueSeverityLevel.UNSCORED ? 'None' : severity
    );
}
