import { useContext } from 'react';

/**
 * withContext HOC injects the value of the context as props to the wrapped component
 * Example usage: withContext(SampleContext)(SampleComponent)
 */
export function withContext<TContext>(contextObject: React.Context<TContext>) {
    return function <TProps extends JSX.IntrinsicAttributes & TContext>(
        WrappedComponent: React.ComponentType<TProps>
    ) {
        const WithContextComponent = (props: Omit<TProps, keyof TContext>) => {
            const context = useContext(contextObject);

            return <WrappedComponent {...context} {...(props as TProps)} />;
        };

        WithContextComponent.displayName = WrappedComponent.displayName;

        return WithContextComponent;
    };
}
