import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import IssuesReportShareModal from './IssuesReportShareModal';
import { IRootState } from '../../../redux/reducers-types';
import {
    IIssuesReportShareModalDispatchProps,
    IIssuesReportShareModalStateProps,
} from './IssuesReportShareModal-types';
import { hideDialogAction } from '../../ModalDialog/ModalDialog-actions';

function mapStateToProps(state: IRootState): IIssuesReportShareModalStateProps {
    return {
        contract: state.contract.contract,
        streams: state.auth.streams,
        version: state.contract.selectedVersion,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IIssuesReportShareModalDispatchProps {
    return {
        closeModal: () => dispatch(hideDialogAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IssuesReportShareModal);
