import { useState } from 'react';

export const useHistoryPanel = () => {
    const [isPanelOpen, setIsPanelOpen] = useState(false);

    return {
        isHistoryPanelOpen: isPanelOpen,
        toggleHistoryPanel: () => setIsPanelOpen(!isPanelOpen),
    };
};
