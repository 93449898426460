import { GridColDef } from '@mui/x-data-grid-premium';

/**
 * Function re-ordering an array of grid column definitions according to the
 * order specified in `orderedFields` array
 * If `excludeMissing` is true, it will filter the column definitions only to
 * the ones included in `orderedFields` array
 */
export const getOrderedColumns = <T extends Pick<GridColDef, 'field'>>(
    columns: T[],
    orderedFields: string[],
    excludeMissing?: boolean
) => {
    // Iterate over the ordered fields array to find a matching column
    const orderedColumns = orderedFields.reduce<T[]>(
        (orderedColumns, field) => {
            const column = columns.find((c) => c.field === field);
            if (column) {
                orderedColumns.push(column);
            }
            return orderedColumns;
        },
        []
    );

    if (!excludeMissing) {
        // Add remaining columns that were not included in ordered fields
        const fieldsSet = new Set(orderedColumns.map((column) => column.field));
        orderedColumns.push(...columns.filter((c) => !fieldsSet.has(c.field)));
    }

    return orderedColumns;
};
