import RichTextEditor from '../../../../../../../components/RichTextEditor/RichTextEditor';
import NoIssueTooltip from './NoIssueTooltip';
import {
    GridRenderEditCellParams,
    useGridApiContext,
} from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';

interface RichTextEditCellProps extends GridRenderEditCellParams {
    placeholder: string;
}

const RichTextEditCell = ({
    row,
    value,
    field,
    id,
    placeholder,
}: RichTextEditCellProps) => {
    const apiRef = useGridApiContext();

    // Tracking if the shift is pressed to handle Shift + Enter correctly
    const [isShiftPressed, setIsShiftPressed] = useState(false);

    /**
     * The popover used by RichTextEditor requires us to pass the offsets of
     * the cell relative to the entire grid for correct positioning.
     * This should ideally be refactored in RichTextEditor component
     */
    const [offsetTop, setOffsetTop] = useState(0);

    useEffect(() => {
        const updateOffset = () => {
            const rowElement = document.querySelector(`[data-id='${row.id}']`);
            if (rowElement instanceof HTMLElement) {
                setOffsetTop(rowElement.offsetTop + 22);
            } else {
                setOffsetTop(0);
            }
        };

        updateOffset();
        window.addEventListener('scroll', updateOffset);

        return () => window.removeEventListener('scroll', updateOffset);
    }, [row.id, setOffsetTop]);

    if (!row.issue_id) {
        return <NoIssueTooltip />;
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Shift') {
            setIsShiftPressed(true);
        }

        // If Shift + Enter is pressed, prevent exiting cell edit mode
        if (event.key === 'Enter' && isShiftPressed) {
            event.stopPropagation();
        }
    };

    const handleKeyUp = (event: React.KeyboardEvent) => {
        if (event.key === 'Shift') {
            setIsShiftPressed(false);
        }
    };

    return (
        <RichTextEditor
            autoFocus
            value={value ?? ''}
            onChange={(value) =>
                apiRef.current.setEditCellValue({
                    id,
                    field,
                    value,
                })
            }
            placeholder={placeholder}
            popoverOffsetLeft={apiRef.current.getScrollPosition()?.left}
            popoverOffsetTop={offsetTop}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
        />
    );
};

export default RichTextEditCell;
