import { Select } from '@thought-river/ui-components';
import FilterLabel from './FilterLabel';
import { useUserDealTypes } from '@modules/reports/portfolioReports';
import { ColumnFilterProps } from '@modules/reports/common';

const DealTypeFilter = ({ selected, onChange }: ColumnFilterProps) => {
    const userDealTypes = useUserDealTypes();

    const options = userDealTypes.map((s) => ({
        value: s.streamId,
        label: s.accountName,
    }));

    const value = options.filter((o) => selected.includes(o.value));

    return (
        <Select
            label={<FilterLabel columnId="deal_type" />}
            options={options}
            onChange={onChange}
            multiple
            value={value}
            disableCloseOnSelect
        />
    );
};

export default DealTypeFilter;
