const AP_MIGRATION_LEGACY_UI_URL =
    'https://menu-ap-migration.thoughtriver.review';

export const getLegacyUIUrl = () => {
    if (window.location.origin.split('.')[0].includes('ap-migration')) {
        return AP_MIGRATION_LEGACY_UI_URL;
    }

    return window.__APP_CONFIG__.legacyUIUrl;
};
