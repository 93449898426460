import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { IRootState } from '../../redux/reducers-types';
import PartySidebar from './PartySidebar';
import {
    IPartySidebarStateProps,
    IPartySidebarDispatchProps,
} from './PartySidebar-types';
import { setPartyCorrectionStatusAction } from '../PartyCorrection/PartyCorrection-actions';
import { PartyCorrectionStatus } from '../PartyCorrection/PartyCorrection-types';

function mapStateToProps(state: IRootState): IPartySidebarStateProps {
    return {
        expandedGroup: state.parties.expandedGroup,
        parties: state.parties.parties,
        contractId: state.partyCorrection.contractId,
        versionId: state.partyCorrection.versionId,
        width: state.paneDivider.partySidebarWidth,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IPartySidebarDispatchProps {
    return {
        setPartyCorrectionStatus: (
            status: PartyCorrectionStatus,
            statusMessage?: string
        ) => dispatch(setPartyCorrectionStatusAction(status, statusMessage)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartySidebar);
