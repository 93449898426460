import { analyticsService } from '../../../../services/Analytics/Analytics';
import {
    EVENT_ACTION_DOWNLOAD_CONTRACT_PRIMARY,
    EVENT_ACTION_INITIATE_SET_PARTIES_FLOW,
    EVENT_ACTION_SIGN,
    EVENT_ACTION_UNSIGN,
} from '../../../..//services/Analytics/Analytics-types';

export const useAnalytics = (
    contractId: string,
    versionId: string,
    versionNumber: number
) => {
    const payload = {
        ContractId: contractId,
        VersionId: versionId,
        VersionNumber: versionNumber,
    };

    const trackDownloadClick = () => {
        analyticsService.recordEvent(
            EVENT_ACTION_DOWNLOAD_CONTRACT_PRIMARY,

            payload
        );
    };

    const trackEditPartiesClick = () => {
        analyticsService.recordEvent(
            EVENT_ACTION_INITIATE_SET_PARTIES_FLOW,

            payload
        );
    };

    const trackSignContractClick = () => {
        analyticsService.recordEvent(EVENT_ACTION_SIGN, payload);
    };

    const trackUnsignContractClick = () => {
        analyticsService.recordEvent(
            EVENT_ACTION_UNSIGN,

            payload
        );
    };

    return {
        trackDownloadClick,
        trackEditPartiesClick,
        trackSignContractClick,
        trackUnsignContractClick,
    };
};
