import {
    PartyCorrectionStatus,
    ISetPartyCorrectionStatusAction,
    ISetPartyContractIdAction,
    ISetPartyVersionIdAction,
    SET_PARTY_CORRECTION_STATUS,
    SET_PARTY_CONTRACT_ID,
    SET_PARTY_VERSION_ID,
    ISetHighlightingAction,
    SET_HIGHLIGHTING,
} from './PartyCorrection-types';

export function setPartyCorrectionStatusAction(
    status: PartyCorrectionStatus,
    statusMessage: string = ''
): ISetPartyCorrectionStatusAction {
    return {
        type: SET_PARTY_CORRECTION_STATUS,
        status,
        statusMessage,
    };
}

export function setPartyContractIdAction(
    contractId: string
): ISetPartyContractIdAction {
    return {
        type: SET_PARTY_CONTRACT_ID,
        contractId,
    };
}

export function setPartyVersionIdAction(
    versionId: string
): ISetPartyVersionIdAction {
    return {
        type: SET_PARTY_VERSION_ID,
        versionId,
    };
}

export function setHighlightingAction(
    highlighting: boolean
): ISetHighlightingAction {
    return {
        type: SET_HIGHLIGHTING,
        highlighting,
    };
}
