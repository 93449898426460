import { Banner, BreakpointComponents } from '@thought-river/ui-components';
import { ErrorBannerProps } from './ErrorBanner.types';

export const errorBannerTitles: BreakpointComponents = {
    xs: 'Adjust your filters.',
    sm: 'Too much data. Adjust your columnn and filter selection.',
    md: 'Too much data. Adjust your columnn and filter selection.',
    lg: 'Your selection results in too much data. Adjust your filters.',
    xl: "Your selection results in too much data. We're showing recent contract data. Adjust your filters.",
    max: "Your selection results in too much data. We're showing recent contract data. Adjust your filters.",
};

const ErrorBanner = ({ onClose }: ErrorBannerProps) => (
    <Banner type="error" onClose={onClose} dismissable>
        Your selection results in too much data. We're showing recent contract
        data. Adjust your filters.
    </Banner>
);

export default ErrorBanner;
