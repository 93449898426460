import {
    SummaryPanelTemplateOut,
    SummaryPanelTemplates,
} from '@thought-river/negotiations-common/dist/api/playbookManager';
import { ALL_DEAL_TYPES_OPTION_VALUE } from './HomePage';

// Filter templates by the deal types user has access to
// This is a temporary solution until such filtering is implemented in the back end
const filterTemplateByUserDealTypes = (
    template: SummaryPanelTemplateOut,
    userDealTypeIds: string[]
) => {
    const userDealTypesSet = new Set(userDealTypeIds);
    return template.deal_types.some((dealType) =>
        userDealTypesSet.has(dealType)
    );
};

const filterTemplateBySelectedDealType = (
    template: SummaryPanelTemplateOut,
    selectedDealTypeId: string
) =>
    selectedDealTypeId === ALL_DEAL_TYPES_OPTION_VALUE ||
    template.deal_types.includes(selectedDealTypeId);

const filterTemplateBySearchTerm = (
    template: SummaryPanelTemplateOut,
    searchTerm: string
) => {
    const normalisedTerm = searchTerm.toLowerCase();
    const normalisedTemplateName = template.name.toLowerCase();
    const normalisedTemplateDescription =
        template.description?.toLowerCase() ?? '';

    return (
        normalisedTemplateName.includes(normalisedTerm) ||
        normalisedTemplateDescription.includes(normalisedTerm)
    );
};

export const getFilteredTemplates = (
    spTemplates: SummaryPanelTemplates,
    userDealTypeIds: string[],
    selectedDealTypeId: string,
    searchTerm: string
) =>
    spTemplates
        .filter((template) =>
            filterTemplateByUserDealTypes(template, userDealTypeIds)
        )
        .filter((template) =>
            filterTemplateBySelectedDealType(template, selectedDealTypeId)
        )
        .filter((template) => filterTemplateBySearchTerm(template, searchTerm));
