import { ReportDefinitionOut } from '@thought-river/negotiations-common/dist/api/playbookManager';

export const getOrderedSelectedFields = (
    reportDefinition: ReportDefinitionOut
) => {
    // A list of all selected columns in a correct order
    // This gets populated when creating a report from scratch or editing a report
    // When creating a report from template this wouldn't be available
    const orderedFields: string[] =
        reportDefinition.config?.columns?.orderedFields;

    // A list of unordered columns
    const unorderedFields: string[] = reportDefinition?.columns.concat(
        reportDefinition?.properties.map((p) => p.dfcode)
    );

    // Prefer the ordered list and default to unordered list
    return orderedFields || unorderedFields;
};
