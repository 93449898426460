import { Select } from '@thought-river/ui-components';
import FilterLabel from './FilterLabel';
import { ColumnFilterProps } from '@modules/reports/common';

const StatusFilter = ({ selected, onChange }: ColumnFilterProps) => {
    const options = [
        { value: 'Signed', label: 'Signed' },
        { value: 'Negotiating', label: 'Negotiating' },
    ];

    const value = options.filter((o) => selected.includes(o.value));

    return (
        <Select
            label={<FilterLabel columnId="status" />}
            options={options}
            onChange={onChange}
            multiple
            value={value}
            disableCloseOnSelect
        />
    );
};

export default StatusFilter;
