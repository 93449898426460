import * as React from 'react';

import {
    IHeatmapScrollbarProps,
    IHeatmapScrollbarState,
    SCROLLBAR_VERTICAL_PADDING,
} from './HeatmapScrollbar-types';
import { IContractParagraph } from '../Paragraph/Paragraph-types';
import { HighlightIndexType } from '../Contract/Contract-types';
import ScrollbarNode from './ScrollbarNode/ScrollbarNode';
import { throttle } from 'lodash';

class HeatmapScrollbar extends React.Component<
    IHeatmapScrollbarProps,
    IHeatmapScrollbarState
> {
    private wrapperRef: React.RefObject<HTMLDivElement>;

    constructor(props: IHeatmapScrollbarProps) {
        super(props);

        this.state = {
            height: 0,
        };

        this.renderNode = this.renderNode.bind(this);

        this.wrapperRef = React.createRef();

        this.onWindowResize = this.onWindowResize.bind(this);

        this.onSelectNode = this.onSelectNode.bind(this);
    }

    componentDidMount() {
        this.setState({
            height: this.wrapperRef.current?.clientHeight ?? 0,
        });

        window.addEventListener('resize', this.onWindowResize);
    }

    private onWindowResize = throttle(() => {
        this.setState({
            height: this.wrapperRef.current?.clientHeight ?? 0,
        });
    }, 150);

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    private onSelectNode(
        paragraph: IContractParagraph,
        selectedParagraphId?: string
    ) {
        const { setSelectedParagraph, setHighlightIndex } = this.props;

        if (paragraph.id === selectedParagraphId) {
            setSelectedParagraph(null);
            setHighlightIndex(-1, HighlightIndexType.START);
            setHighlightIndex(-1, HighlightIndexType.END);
            return;
        }

        setHighlightIndex(paragraph.index, HighlightIndexType.START);
        setHighlightIndex(paragraph.index, HighlightIndexType.END);
        setSelectedParagraph(paragraph);

        this.renderNode = this.renderNode.bind(this);
    }

    private renderNode(
        paragraph: IContractParagraph,
        highlightedParagraphIds: string[]
    ) {
        const {
            activeIssueFilters,
            activeSidebarTab,
            contractScrollHeightInPixels,
            paragraphHeightsInPixels,
            selectedContractIntelligence,
            selectedParagraph,
            contract,
        } = this.props;

        return (
            <ScrollbarNode
                key={`paragraph-node-${paragraph.id}`}
                paragraph={paragraph}
                activeIssueFilters={activeIssueFilters}
                activeSidebarTab={activeSidebarTab}
                contractScrollHeightInPixels={contractScrollHeightInPixels}
                highlightedParagraphIds={highlightedParagraphIds}
                paragraphHeightsInPixels={paragraphHeightsInPixels}
                selectedIntelligenceType={selectedContractIntelligence?.type}
                selectedParagraph={selectedParagraph}
                scrollbarHeight={this.state.height}
                scrollbarVerticalPadding={SCROLLBAR_VERTICAL_PADDING}
                onSelectNodeCallback={this.onSelectNode}
                contract={contract}
            />
        );
    }

    private getSliderStyles(): React.CSSProperties {
        const {
            contractScrollTop,
            contractScrollHeightInPixels,
            contractVisibleHeightInPixels,
        } = this.props;

        const scrollbarHeight = this.state.height - SCROLLBAR_VERTICAL_PADDING;
        const relativeScrollTop =
            contractScrollTop / contractScrollHeightInPixels;
        const scrollTopInPixels = scrollbarHeight * relativeScrollTop;
        let sliderRelativeHeight =
            contractVisibleHeightInPixels / contractScrollHeightInPixels;
        sliderRelativeHeight =
            sliderRelativeHeight > 1 ? 1 : sliderRelativeHeight;
        const sliderHeightInPixels = scrollbarHeight * sliderRelativeHeight;

        return {
            height: `${sliderHeightInPixels}px`,
            top: `${scrollTopInPixels}px`,
        };
    }

    render() {
        const { highlightedParagraphs, paragraphs } = this.props;

        const highlightedParagraphIds = highlightedParagraphs.map(
            (paragraph) => paragraph.id
        );

        return (
            <div className="heatmap-scrollbar-wrapper" ref={this.wrapperRef}>
                {this.state.height && (
                    <div
                        className={`heatmap-scrollbar ${
                            highlightedParagraphs.length
                                ? 'active-selection'
                                : ''
                        }`}
                    >
                        <div className="backbone">
                            <div
                                className="slider"
                                style={this.getSliderStyles()}
                            />
                        </div>
                        {paragraphs.map((paragraph) =>
                            this.renderNode(paragraph, highlightedParagraphIds)
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default HeatmapScrollbar;
