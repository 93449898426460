import * as React from 'react';
import { Lock } from '@mui/icons-material';
import { toast } from 'react-toastify';

import {
    IContractProperty,
    IContractProps,
    IContractState,
    IContractVersion,
    IDisplayContract,
    IProcessedIssuesReturnValue,
    IProcessedParagraphsReturnValue,
    IProcessedThemesReturnValue,
    PAGE_TITLE_HEATMAP,
} from './Contract-types';
import { ContractPane } from './Contract-types';
import {
    closeIssue,
    createProcessingNotifications,
    fetchContractStatus,
    filterRelevantThemes,
    reprocessContract,
    resetSelectedParagraph,
} from './Contract-helpers';
import {
    mapAssessment,
    mapBusinessStatus,
    mapCategory,
    mapContract,
    mapIssue,
    mapLexibleProperty,
    mapLexibleTheme,
    mapParagraph,
    mapPolicy,
    mapProperty,
    mapRawClauseNumber,
    mapVersion,
} from '../App/App-mappers';
import VersionResource from '../../resources/VersionResource';
import ParagraphResource from '../../resources/ParagraphResource';
import PropertyResource from '../../resources/PropertyResource';
import ContractResource from '../../resources/ContractResource';
import AssessmentResource from '../../resources/AssessmentResource';
import DocumentResource from '../../resources/DocumentResource';
import { IDocuments, IIssueData, IParagraph } from 'types/thoughtriver';
import ContractSidebar from '../ContractSidebar/ContractSidebar-container';
import { ContractSidebarTab } from '../ContractSidebar/ContractSidebar-types';
import IssueResource from '../../resources/IssueResource';
import {
    IIssueCategory,
    IssueCreationType,
    IssueStateInVersion,
} from '../IssueListPane/IssueListPane-types';
import { IProcessingContract } from '../ContractPollingProvider/ContractPollingProvider-types';
import IssuesReport from '../IssuesReport/IssuesReport-container';
import {
    alphabetizeLexibleProperties,
    alphabetizeThemes,
    getSummaryProperties,
    isOnReportView,
    sortByAsc,
} from '../App/App-helpers';
import SplashLoader from '../SplashLoader/SplashLoader';
import { SplashLoaderType } from '../SplashLoader/SplashLoader-types';
import { VERSION_STATE_UPLOADING } from '../UploadProgressBar/UploadProgressBar-types';
import UploadProgressBar from '../UploadProgressBar/UploadProgressBar';
import { IHtmlParagraphCollection } from '../../services/documentParser-types';
import {
    FEATURE_TOGGLE_CONTRACTS_TREE,
    FEATURE_TOGGLE_CONTRACT_REPORT_NEW_DATA_GRID,
} from '../FeatureToggleProvider/FeatureToggleProvider-types';
import PolicyResource from '../../resources/PolicyResource';
import {
    PANE_WIDTH_ONE_THIRD,
    PANE_WIDTH_TWO_THIRDS,
} from '../PaneDivider/PaneDivider-types';
import {
    Properties,
    fetchGetDealTypeSummaryPanelTemplates,
    fetchGetProperties,
} from '@thought-river/negotiations-common/dist/api/playbookManager';
import LegacyHTMLParser from '../../services/legacyHTMLParser';
import { ReprocessAnalysisType } from '../../resources/ContractResource-types';
import { ILexibleProperty } from '../App/App-types';
import { BannerType } from '../HeatmapPane/HeatmapPane-types';
import { find, get } from 'lodash';
import ContractPanes from './ContractPanes';
import SecondaryNavigation from '../SecondaryNavigation/SecondaryNavigation';
import DocumentViewerPane from '../DocumentViewerPane/DocumentViewerPane';
import DocumentParser from '../../services/documentParser';
import { IContractParagraph } from '../Paragraph/Paragraph-types';
import { ContractReportPage } from '@modules/reports/contractReports';
import {
    IssueSeverityLevel,
    CloseAllIssuesProvider,
    ContractIssue,
    LexibleTheme,
    RelatedClausesProvider,
    ParagraphClause,
} from '@thought-river/negotiations-common';
import { FileFormat } from '@modules/common/types';
import { getContractPath } from '@modules/common/helpers';
import { ContractSearchProvider } from '@modules/common/context/ContractSearchProvider';
import { IssuePaneProvider } from '@modules/common/context/IssuePaneProvider/IssuePaneProvider';
import LexibleThemeResource from '../../resources/LexibleThemeResource';
import CategoryResource from '../../resources/CategoryResource';
import { ErrorBoundary } from '@modules/common/components/ErrorBoundary';
import {
    fetchGetBusinessStatusesV02,
    fetchGetFile,
} from '@thought-river/negotiations-common/dist/api/contractManagement';

class Contract extends React.Component<IContractProps, IContractState> {
    constructor(props: IContractProps) {
        super(props);

        this.state = {
            error: null,
            documentHTML: null,
        };

        this.handleReprocessContract = this.handleReprocessContract.bind(this);
        this.handleCloseIssuesComplete =
            this.handleCloseIssuesComplete.bind(this);
    }

    async componentDidMount() {
        const {
            setContractSidebarTab,
            setBusinessStatuses,
            setCategories,
            setGenericThemes,
            setLexibleProperties,
            setSelectedIssue,
            lexibleProperties,
            themes,
            match: {
                params: { versionId, contractId, stream },
            },
        } = this.props;

        document.title = PAGE_TITLE_HEATMAP;

        const nullPromise = Promise.resolve(null);

        const [rawBusinessStatuses, rawCategories, themesResponse, properties] =
            await Promise.all([
                fetchGetBusinessStatusesV02({}),
                CategoryResource.getAllCategories(),
                themes.length
                    ? nullPromise
                    : LexibleThemeResource.getLexibleThemes(),
                lexibleProperties.length
                    ? nullPromise
                    : this.getLexibleProperties(),
            ]);

        const businessStatuses =
            rawBusinessStatuses.data?.map(mapBusinessStatus) ?? [];

        const categories = rawCategories.data
            .map(mapCategory)
            .sort(sortByAsc('name'));

        setBusinessStatuses(businessStatuses);
        setCategories(categories);

        if (themesResponse) {
            const mappedThemes = themesResponse.data
                .map(mapLexibleTheme)
                .sort(alphabetizeThemes);
            setGenericThemes(mappedThemes);
        }

        if (properties) {
            setLexibleProperties(properties);
        }

        setContractSidebarTab(ContractSidebarTab.OVERVIEW);
        setSelectedIssue(null!);
        await this.loadContract(stream, contractId, versionId);
    }

    private async getLexibleProperties() {
        return await fetchGetProperties({}).then((properties) => {
            const filteredProperties: ILexibleProperty[] = (
                properties as Properties
            )
                .filter((p) => p.uuid)
                .map(mapLexibleProperty);
            return filteredProperties.sort(alphabetizeLexibleProperties);
        });
    }

    async componentDidUpdate(prevProps: IContractProps) {
        const {
            contract,
            contractId: persistedContractId,
            match: {
                params: { contractId, stream, versionId },
            },
            newVersionProcessed,
            lexibleProperties,
            reload,
            resetActiveIssueFilters,
            resetIssuesTableColumns,
            resetIssuesReportSortOrder,
            paragraphs,
            properties,
            setBannerSettings,
            setNewVersionProcessed,
            setReloadContract,
            setSummaryProperties,
            successfulProcessedContracts,
            successfulReprocessedContracts,
            setPaneWidth,
            selectedPropertiesTemplate,
        } = this.props;
        const {
            successfulProcessedContracts: prevSuccessfulProcessedContracts,
        } = prevProps;

        if (
            JSON.stringify(prevProps.lexibleProperties) !==
                JSON.stringify(lexibleProperties) ||
            JSON.stringify(prevProps.properties) !==
                JSON.stringify(properties) ||
            JSON.stringify(prevProps.selectedPropertiesTemplate) !==
                JSON.stringify(selectedPropertiesTemplate) ||
            JSON.stringify(prevProps.paragraphs) !== JSON.stringify(paragraphs)
        ) {
            const summaryProperties = getSummaryProperties({
                lexibleProperties,
                paragraphs,
                properties,
                templateProperties: selectedPropertiesTemplate?.properties,
            });

            setSummaryProperties(summaryProperties);

            for (const summaryProperty of summaryProperties) {
                if (!properties.find((p) => p.code === summaryProperty.code)) {
                    setBannerSettings({
                        type: BannerType.REPROCESS_REQUIRED,
                        clauseType: null!,
                    });
                    break;
                }
            }
        }

        if (
            prevProps.match.params.versionId !== versionId ||
            (!prevProps.reload && reload)
        ) {
            if (newVersionProcessed && contract) {
                toast.success(`${contract.contractName} review complete`);
                setNewVersionProcessed(false);
            }
            setReloadContract(false);
            await this.loadContract(
                stream,
                contractId,
                versionId,
                newVersionProcessed
            );
        }

        if (prevProps.contractId !== persistedContractId) {
            resetActiveIssueFilters();
            resetIssuesReportSortOrder();
            resetIssuesTableColumns();

            setPaneWidth(ContractPane.HEATMAP, PANE_WIDTH_TWO_THIRDS);
            setPaneWidth(ContractPane.SIDEBAR, PANE_WIDTH_ONE_THIRD);
        }

        if (
            successfulProcessedContracts.length &&
            !prevSuccessfulProcessedContracts.find(
                (c) => c.contractId === contract?.id
            ) &&
            successfulProcessedContracts.find(
                (c) => c.contractId === contract?.id
            )
        ) {
            this.onSuccessfulProcessing(contract);
        }

        if (
            successfulReprocessedContracts.find(
                (c) => c.contractId === contract?.id
            )
        ) {
            this.onSuccessfulReprocessing(contract);
        }
    }

    async componentWillUnmount() {
        this.props.setLoaded(false);
        this.props.setReferringPage('');
        this.props.clearContract();
    }

    private async handleReprocessContract() {
        const { setBannerSettings, contract } = this.props;

        setBannerSettings(null!);

        await reprocessContract(contract, ReprocessAnalysisType.DELTA, false);
    }

    private getProcessingContract(
        contractId: string
    ): IProcessingContract | undefined {
        const { processingContracts } = this.props;

        return find(processingContracts, { contractId });
    }

    private extractFormats(documents: IDocuments): FileFormat[] {
        const formats = [FileFormat.DOCX];

        for (const format of get(documents, 'data[0].attributes.formats', [])) {
            if (format['mime-type'] === 'application/pdf') {
                formats.push(FileFormat.PDF);
            }
        }

        return formats;
    }

    private processIssues(
        issues: IIssueData[] = [],
        clauseNumbers: ParagraphClause[],
        contractProperties: IContractProperty[],
        paragraphs: IContractParagraph[],
        lexibleProperties: ILexibleProperty[],
        categories: IIssueCategory[],
        themes: LexibleTheme[],
        version: IContractVersion
    ): IProcessedIssuesReturnValue {
        let closedCount = 0;
        let updatedCount = 0;
        let newCount = 0;
        const mappedIssues = [];
        const paragraphIssues = {};

        for (const issue of issues) {
            const mappedIssue = mapIssue(
                issue,
                clauseNumbers,
                contractProperties,
                paragraphs,
                lexibleProperties,
                categories,
                themes,
                version
            );

            if (mappedIssue.severityLevel !== IssueSeverityLevel.UNSCORED) {
                closedCount += +(
                    mappedIssue.stateInVersion === IssueStateInVersion.CLOSED
                );
                updatedCount += +(
                    mappedIssue.stateInVersion === IssueStateInVersion.UPDATED
                );
                newCount += +(
                    mappedIssue.stateInVersion === IssueStateInVersion.NEW &&
                    mappedIssue.creationType === IssueCreationType.AUTO
                );
            }

            for (const uuid of mappedIssue.paragraphOriginUuids) {
                if (paragraphIssues[uuid]) {
                    paragraphIssues[uuid].push(mappedIssue);
                } else {
                    paragraphIssues[uuid] = [mappedIssue];
                }
            }

            mappedIssues.push(mappedIssue);
        }

        return {
            issues: mappedIssues,
            closedCount,
            updatedCount,
            newCount,
            paragraphIssues,
        };
    }

    private processThemes(
        themes: LexibleTheme[] = [],
        properties: IContractProperty[] = []
    ): IProcessedThemesReturnValue {
        const relevantThemes = [];
        const unscoredThemes = [];

        for (const theme of themes) {
            if (filterRelevantThemes(theme, properties)) {
                relevantThemes.push(theme);
            } else {
                unscoredThemes.push(theme);
            }
        }

        return {
            relevantThemes,
            unscoredThemes,
        };
    }

    private loadLegacyHtml(contractId: string, streamId: string) {
        const legacyHtmlParser = LegacyHTMLParser.getInstance();
        legacyHtmlParser.loadLegacyHtml(contractId, streamId);
    }

    private loadSummaryPanelTemplates(stream: string) {
        const {
            setPropertyTemplates,
            setPropertyTemplatesLoaded,
            setSelectedPropertiesTemplate,
            streams,
        } = this.props;

        fetchGetDealTypeSummaryPanelTemplates({
            pathParams: {
                dealTypeIdOrUuid:
                    streams.find(
                        (s) =>
                            s.accountCode.toLowerCase() === stream.toLowerCase()
                    )?.streamId ?? '',
            },
        }).then((propertyTemplates) => {
            if (propertyTemplates.length) {
                setPropertyTemplates(propertyTemplates);
                setSelectedPropertiesTemplate(propertyTemplates[0]);
            }
            setPropertyTemplatesLoaded(true);
        });
    }

    private processParagraphs(
        document: any,
        rawParagraphs: IParagraph[],
        properties: IContractProperty[]
    ): IProcessedParagraphsReturnValue {
        const htmlParagraphs: IHtmlParagraphCollection =
            DocumentParser.enhancedParseDocument(document);

        const extractedStyles = DocumentParser.extractStyles(document);
        if (document.adoptedStyleSheets) {
            document.adoptedStyleSheets.push(extractedStyles);
        }

        const mappedParagraphs = [];

        let redlinedText = '';

        for (let index = 0; index < rawParagraphs.length; index++) {
            const paragraph = rawParagraphs[index];
            const location = paragraph.attributes['index'];
            const mappedParagraph = mapParagraph(
                paragraph,
                properties,
                index,
                htmlParagraphs[location]
            );

            redlinedText += `${
                mappedParagraph.redlinedText || mappedParagraph.text
            } `;

            mappedParagraphs.push(mappedParagraph);
        }

        return {
            paragraphs: mappedParagraphs,
            redlinedText,
        };
    }

    async loadContract(
        stream: string,
        contractId: string,
        versionId: string,
        showProcessingNotifications: boolean = false
    ) {
        const {
            addProcessingContract,
            setLoaded,
            streams,
            themes,
            lexibleProperties,
            categories,
            featureToggles,
        } = this.props;

        const contractsTreeEnabled = !!featureToggles.find(
            (toggle) => toggle.feature === FEATURE_TOGGLE_CONTRACTS_TREE
        )?.enabled;

        try {
            setLoaded(false);
            resetSelectedParagraph();

            this.loadSummaryPanelTemplates(stream);

            const [
                versionsResponse,
                contractResponse,
                assessmentsResponse,
                documentsResponse,
                paragraphsResponse,
                issuesResponse,
                fileResponse,
            ] = await Promise.all([
                VersionResource.getVersions(contractId, stream),
                ContractResource.getContract(contractId, stream),
                AssessmentResource.getAssessments(
                    contractId,
                    versionId,
                    stream
                ),
                DocumentResource.getDocuments(contractId, versionId, stream),
                ParagraphResource.getParagraphs(contractId, versionId, stream),
                IssueResource.getIssues(contractId, versionId, stream),
                fetchGetFile({
                    pathParams: { fileType: 'indexed_html', uuid: versionId },
                }),
            ]);

            const contractStatus = await fetchContractStatus(contractId);

            const versions = versionsResponse.data.map((version, index) =>
                mapVersion(version, index)
            );
            const contract = mapContract(
                contractResponse.data,
                versions,
                contractStatus[0]
            );
            const version =
                versions.find((v) => v.id === versionId) ??
                contract.latestVersion;

            this.loadLegacyHtml(contract.id, contract.streamId);

            if (
                !contractsTreeEnabled &&
                version.isLatest &&
                !version.assessmentIds.length
            ) {
                const assessedVersions = versions.filter(
                    (version) => version.assessmentIds.length
                );

                if (assessedVersions.length) {
                    const latestAssessedVersion =
                        assessedVersions[assessedVersions.length - 1];
                    this.props.history.push(
                        getContractPath(
                            stream,
                            contractId,
                            latestAssessedVersion.id
                        )
                    );
                    return;
                }
            }

            const assessments = assessmentsResponse.data.map(mapAssessment);
            const formats = this.extractFormats(documentsResponse);
            const assessment = assessments[assessments.length - 1];

            const [propertyResponse, policiesResponse] = await Promise.all([
                PropertyResource.getProperties(
                    contractId,
                    versionId,
                    assessment.id,
                    stream
                ),
                PolicyResource.getPolicies(
                    contractId,
                    versionId,
                    assessment.id,
                    stream
                ),
            ]);

            const paragraphsDictionary: { [paragraphId: string]: string } = {};
            const clauseNumbers: ParagraphClause[] = [];

            paragraphsResponse.data.forEach((paragraph, index) => {
                paragraphsDictionary[paragraph.id] =
                    paragraph.attributes['origin-uuid'];
                clauseNumbers.push(mapRawClauseNumber(paragraph, index));
            });

            const properties = propertyResponse.data.map((property) =>
                mapProperty(property, themes, paragraphsDictionary)
            );

            const policies = policiesResponse.data.map(mapPolicy);

            // Despite what codegen says the response is, it's actually a Blob
            const documentHTML = await (fileResponse as unknown as Blob).text();

            const { paragraphs, redlinedText } = this.processParagraphs(
                documentHTML,
                paragraphsResponse.data,
                properties
            );

            const {
                issues,
                closedCount,
                updatedCount,
                newCount,
                paragraphIssues,
            } = this.processIssues(
                issuesResponse.data,
                clauseNumbers,
                properties,
                paragraphs,
                lexibleProperties,
                categories,
                themes,
                version
            );

            //Set paragraph issues now that we have processed issues
            paragraphs.forEach(
                (p) => p.issues === paragraphIssues[p.originUuid]
            );

            const { relevantThemes, unscoredThemes } = this.processThemes(
                themes,
                properties
            );

            this.props.setParagraphs(paragraphs);
            this.props.setRedlinedText(redlinedText);
            this.props.setContract(contract);
            this.props.setAssessment(assessment);
            this.props.setThemes(relevantThemes);
            this.props.setUnscoredThemes(unscoredThemes);
            this.props.setFormats(formats);
            this.props.setVersions(versions);
            this.props.setSelectedVersion(version);
            this.props.setIssues(issues);
            this.props.setPolicies(policies);
            this.props.setProperties(properties);
            this.setState({ documentHTML });

            if (
                !contractsTreeEnabled &&
                contract.latestVersion &&
                !contract.latestVersion.assessmentIds.length &&
                !this.getProcessingContract(contract.id)
            ) {
                addProcessingContract({
                    contractId: contract.id,
                    contractName: contract.contractName,
                    stream: contract.streamCode,
                    userId: contract.negotiatorId,
                    isPartiallyAssessed: true,
                    status: VERSION_STATE_UPLOADING,
                });
            }

            setLoaded(true);

            if (showProcessingNotifications) {
                createProcessingNotifications(
                    closedCount,
                    updatedCount,
                    newCount
                );
            }

            console.timeEnd('load');
        } catch (error) {
            console.error(error);
            let errorMsg: string | JSX.Element = 'Error loading the contract';

            if (
                !find(streams, {
                    accountCode: stream?.toLowerCase(),
                })
            ) {
                errorMsg = this.renderInvalidDealTypeError(stream);
            }

            this.setState({
                error: errorMsg,
            });
        }
    }

    async onSuccessfulProcessing({
        streamCode,
        id: contractId,
        latestVersion,
    }: IDisplayContract) {
        const { history, unsetNotifiedContract } = this.props;

        unsetNotifiedContract(contractId);
        const redirectUrl = getContractPath(
            streamCode,
            contractId,
            latestVersion.id
        );
        history.push(redirectUrl);
    }

    async onSuccessfulReprocessing(contract: IDisplayContract) {
        const { id: contractId, streamCode, latestVersion } = contract;

        // Remove the contract from successfully reprocessed array
        this.props.unsetSuccessfulReprocessedContract(contractId);

        await this.loadContract(
            streamCode,
            contractId,
            latestVersion?.id,
            true
        );
    }

    private renderInvalidDealTypeError(stream: string = ''): JSX.Element {
        return (
            <div className="deal-type-error">
                <Lock className="deal-type-error-icon" />
                <div className="deal-type-error-title">Oops!</div>
                <div className="deal-type-error-message">
                    Looks like you don’t have access to this contract's deal
                    type. Please contact your administrator for access to{' '}
                    <span className="deal-type-error-stream">{stream}</span>.
                </div>
            </div>
        );
    }

    shouldShowReportView() {
        const {
            location: { pathname },
        } = this.props;
        return isOnReportView(pathname);
    }

    handleCloseIssuesComplete(updatedIssues: ContractIssue[]) {
        const { setIssues } = this.props;

        setIssues(updatedIssues);
        toast.success('All open issues have been marked as closed');
    }

    render() {
        const {
            contract,
            formats,
            loaded,
            reprocessingContracts,
            issues,
            featureToggles,
            paragraphs,
        } = this.props;

        const { error, documentHTML } = this.state;

        const reprocessingContract = find(reprocessingContracts, {
            contractId: contract?.id,
        });
        const processingContract = this.getProcessingContract(contract?.id);

        const newContractReportDataGridEnabled = featureToggles.find(
            (toggle) =>
                toggle.feature === FEATURE_TOGGLE_CONTRACT_REPORT_NEW_DATA_GRID
        )?.enabled;

        return (
            <ErrorBoundary>
                <ContractSearchProvider>
                    <IssuePaneProvider>
                        <RelatedClausesProvider>
                            <CloseAllIssuesProvider
                                issues={issues}
                                onIssuesUpdated={this.handleCloseIssuesComplete}
                                onCloseIssue={(issueId) =>
                                    closeIssue(contract, issueId)
                                }
                            >
                                <div
                                    className="contract-container"
                                    data-id={contract?.id}
                                >
                                    <SecondaryNavigation
                                        contract={contract}
                                        loaded={loaded}
                                        formats={formats}
                                    />

                                    {loaded && documentHTML ? (
                                        <>
                                            {this.shouldShowReportView() ? (
                                                newContractReportDataGridEnabled ? (
                                                    <ContractReportPage
                                                        documentHTML={
                                                            documentHTML
                                                        }
                                                    />
                                                ) : (
                                                    <IssuesReport />
                                                )
                                            ) : (
                                                <div className="contract-panes">
                                                    <ContractPanes
                                                        setPaneWidth={
                                                            this.props
                                                                .setPaneWidth
                                                        }
                                                        heatmapWidth={
                                                            this.props
                                                                .heatmapWidth
                                                        }
                                                        sidebarWidth={
                                                            this.props
                                                                .sidebarWidth
                                                        }
                                                    >
                                                        <DocumentViewerPane
                                                            paragraphs={
                                                                paragraphs
                                                            }
                                                            onReprocess={
                                                                this
                                                                    .handleReprocessContract
                                                            }
                                                            documentHTML={
                                                                documentHTML
                                                            }
                                                        />
                                                        <ContractSidebar
                                                            onReprocess={
                                                                this
                                                                    .handleReprocessContract
                                                            }
                                                        />
                                                    </ContractPanes>
                                                </div>
                                            )}

                                            {reprocessingContract ? (
                                                <div className="reprocessing-alert">
                                                    <div className="loader-wrapper">
                                                        <span className="loader" />
                                                        <span className="loader-text">
                                                            Re-analyzing
                                                            contract...
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : null}
                                            {processingContract ? (
                                                <div className="processing-alert">
                                                    <div className="loader-wrapper">
                                                        <div className="loader-text">
                                                            Analyzing
                                                            contract...
                                                        </div>
                                                    </div>
                                                    <UploadProgressBar
                                                        state={
                                                            processingContract.status
                                                        }
                                                        percentageProgress={
                                                            processingContract.percentageProgress
                                                        }
                                                    />
                                                </div>
                                            ) : null}
                                        </>
                                    ) : error ? (
                                        <SplashLoader
                                            message={error}
                                            type={SplashLoaderType.ERROR}
                                        />
                                    ) : (
                                        <SplashLoader message="Loading contract..." />
                                    )}
                                </div>
                            </CloseAllIssuesProvider>
                        </RelatedClausesProvider>
                    </IssuePaneProvider>
                </ContractSearchProvider>
            </ErrorBoundary>
        );
    }
}

export default Contract;
