import {
    ContractVersionFileOut,
    ContractVersionFilesOut,
    fetchGetFile,
} from '@thought-river/negotiations-common/dist/api/contractManagement';
import { Button } from '@thought-river/ui-components';
import { Download } from '@mui/icons-material';
import styles from './FilesList.module.scss';
import { saveAs } from 'file-saver';

interface FilesListProps {
    versionUuid: string;
    files: ContractVersionFilesOut;
}

const FilesList = ({ versionUuid, files }: FilesListProps) => {
    const downloadFile = (file: ContractVersionFileOut) => {
        fetchGetFile({
            pathParams: {
                uuid: versionUuid,
                fileType: file.type,
            },
            //@ts-ignore
        }).then((fileBlob) => saveAs(fileBlob, file.file_name));
    };

    return (
        <div className={styles.wrapper}>
            {files.map((file) => (
                <Button
                    key={file.id}
                    startIcon={<Download />}
                    variant="secondary"
                    onClick={() => downloadFile(file)}
                >
                    {file.file_name}
                </Button>
            ))}
        </div>
    );
};

export default FilesList;
