import { DataGrid } from '@thought-river/ui-components';
import {
    GridColDef,
    GridColumnHeaderParams,
    GridRowParams,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import { ReportDataGridProps } from './ReportDataGrid.types';
import ReportHeaderCell from './cells/ReportHeaderCell';
import { useGetColumns, usePersistReportState } from './hooks';
import { useEffect, useState } from 'react';
import uniq from 'lodash/uniq';
import pull from 'lodash/pull';
import { useReportsAnalytics } from '@modules/reports/portfolioReports/hooks';
import { EditReportButton } from '@modules/reports/common/components';
import {
    getOrderedSelectedFields,
    getReprocessingContractsCount,
} from '@modules/reports/portfolioReports';
import SimplifiedContractMenu from '@modules/common/components/ContractMenu/SimplifiedContractMenu';

const ReportDataGrid = ({
    reportDefinition,
    reportData,
    onConfigChange,
    onReprocessContract,
}: ReportDataGridProps) => {
    const { trackReportExport, trackPageNavigation, trackEditReportButton } =
        useReportsAnalytics();

    const [reprocessingContractsCount, setReprocessingContractsCount] =
        useState(0);

    useEffect(() => {
        setReprocessingContractsCount(
            getReprocessingContractsCount(reportData)
        );
    }, [reportData]);

    const getActionsColumnActions = (params: GridRowParams) => [
        <SimplifiedContractMenu
            key={params.id}
            contractId={params.row.contract_id}
            dealType={params.row.deal_type_code}
            onReprocessContract={() => {
                setReprocessingContractsCount((current) => current + 1);
                onReprocessContract(params.row.contract_id);
            }}
            isContractReprocessing={
                params.row.high_level_contract_status === 'processing'
            }
            reprocessingContractsCount={reprocessingContractsCount}
        />,
    ];

    // Ordered fields need to be stored in state so that we can use
    // them to properly order the columns
    // See: https://github.com/mui/mui-x/issues/5704
    const orderedFieldsConfig = reportDefinition.config?.columns?.orderedFields;
    const [orderedFields, setOrderedFields] = useState<string[]>(
        getOrderedSelectedFields(reportDefinition)
    );

    const pinnedColumnsConfig = {
        left: ['contract_name'], //default pinned columns
        ...reportDefinition.config?.pinnedColumns,
        //always want 'actions' column pinned
        right: uniq([
            ...(reportDefinition.config?.pinnedColumns?.right ?? []),
            'actions',
        ]),
    };
    const [pinnedColumns, setPinnedColumns] = useState(pinnedColumnsConfig);

    const columns: GridColDef[] = useGetColumns(
        reportData.columns || [],
        getActionsColumnActions,
        orderedFields
    );

    const apiRef = useGridApiRef();
    const { onPinnedColumnsChange, ...persistReportStateProps } =
        usePersistReportState({
            onConfigChange,
            onOrderedFieldsChange: (orderedFields) => {
                setOrderedFields(orderedFields);
            },
            apiRef,
        });

    // Sync ordered fields state with config when it updates
    useEffect(() => {
        if (!orderedFieldsConfig) {
            return;
        }

        setOrderedFields(orderedFieldsConfig ?? []);
    }, [orderedFieldsConfig]);

    const groupingColDef = {
        minWidth: 200,
        renderHeader: (params: GridColumnHeaderParams) => (
            <ReportHeaderCell column={params.field} />
        ),
    };

    return (
        <DataGrid
            apiRef={apiRef}
            key={reportDefinition.id_str}
            rows={reportData.rows || []}
            columns={columns || []}
            groupingColDef={groupingColDef}
            disableRowSelectionOnClick
            disableAggregation
            initialState={reportDefinition.config}
            slotProps={{
                toolbar: {
                    toolbarCustomButtons: (
                        <EditReportButton onClick={trackEditReportButton} />
                    ),
                    exportProps: {
                        onExportOptionClick: trackReportExport,
                    },
                    copyTableProps: { hide: true },
                },
                pagination: {
                    onPageChange: trackPageNavigation,
                },
            }}
            {...persistReportStateProps}
            pinnedColumns={pinnedColumns}
            onPinnedColumnsChange={(newPinnedColumns) => {
                pull(newPinnedColumns.right || [], 'actions').push('actions');
                setPinnedColumns(newPinnedColumns);
                onPinnedColumnsChange(newPinnedColumns);
            }}
        />
    );
};

export default ReportDataGrid;
