import * as React from 'react';
import PartyParagraph from '../PartyParagraph/PartyParagraph-container';
import { sortParties, getMatchPattern } from '../Parties/Parties-helpers';
import { IPartyContractProps } from './PartyContract-types';
import { Domain } from '@mui/icons-material';

class PartyContract extends React.Component<IPartyContractProps> {
    private containerRef: React.RefObject<any>;
    private contractRef: React.RefObject<any>;

    constructor(props: IPartyContractProps) {
        super(props);

        this.containerRef = React.createRef();
        this.contractRef = React.createRef();
    }

    componentDidMount() {
        this.props.setPartyContractWidthInPixels(
            this.containerRef.current.clientWidth
        );
    }

    componentDidUpdate(prevProps: IPartyContractProps) {
        const { contractScrollToParty, setContractScrollToParty } = this.props;

        if (contractScrollToParty) {
            try {
                // eslint-disable-next-line
                const $scrollTarget = document.querySelector(
                    `[data-party='${contractScrollToParty.name.replaceAll(
                        `'`,
                        `\\'`
                    )}']`
                ) as HTMLElement;

                if ($scrollTarget) {
                    const offsetTop = $scrollTarget.offsetTop;
                    const targetOffset =
                        offsetTop - this.contractRef.current.offsetTop - 50;

                    if (
                        typeof this.contractRef.current.scrollTo === 'function'
                    ) {
                        this.contractRef.current.scrollTo({
                            left: 0,
                            top: targetOffset,
                            behavior: 'smooth',
                        });
                    } else {
                        this.contractRef.current.scrollTop = targetOffset;
                    }
                }

                setContractScrollToParty(null!); // Fixme: null checks
            } catch (error) {
                console.log('Error scrolling to party name');
            }
        }

        if (this.containerRef) {
            if (prevProps.width !== this.props.width) {
                this.props.setPartyContractWidthInPixels(
                    this.containerRef.current.clientWidth
                );
            }
        }
    }

    render() {
        const { paragraphs, parties, width } = this.props;

        const sortedParties = sortParties(parties);

        const partiesMatchPattern = getMatchPattern(
            sortedParties.map((party) => party.name)
        );

        const paragraphsList = paragraphs.map((paragraph, index) => (
            <PartyParagraph
                key={paragraph.id}
                parties={sortedParties}
                partiesMatchPattern={partiesMatchPattern}
                text={paragraph.text}
                index={index}
            />
        ));

        const customStyles = {
            width: `${width}%`,
        };

        return (
            <div
                className="party-contract"
                style={customStyles}
                ref={this.containerRef}
            >
                <div className="party-contract-content">
                    <p className="party-contract-header">
                        <span className="header-title">
                            We may not have identified all parties.{' '}
                        </span>
                        <span>
                            These can include company names (e.g. 'Acme Inc')
                            and references (e.g. the 'Customer'). Select the
                            company or reference names below and drag &amp; drop
                            them to the correct field on the right.
                            <br />
                            You can mark a company name as the formal legal name
                            by using the building icon{' '}
                            <Domain className="building-icon" /> next to a
                            selected name in the party boxes on the right.
                        </span>
                    </p>
                    <div
                        className="contract-body"
                        ref={this.contractRef}
                        id="contract-body"
                    >
                        <ul className="paragraphs">{paragraphsList}</ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default PartyContract;
