import {
    FLIP_GROUPS,
    IFlipGroupsAction,
    PartiesType,
    IParty,
    IResetSelectionsAction,
    ISetContractScrollToPartyAction,
    ISetDraggedPartyAction,
    ISetExpandedGroupAction,
    ISetPartiesAction,
    ISetScrollToPartyAction,
    SET_CONTRACT_SCROLL_TO_PARTY,
    SET_DRAGGED_PARTY,
    SET_EXPANDED_GROUP,
    SET_PARTIES,
    SET_SCROLL_TO_PARTY,
    RESET_SELECTIONS,
    SET_LOCAL_PARTIES,
    ISetLocalPartiesAction,
} from './Parties-types';

export function flipGroupsAction(
    typeA: PartiesType,
    typeB: PartiesType
): IFlipGroupsAction {
    return {
        type: FLIP_GROUPS,
        typeA,
        typeB,
    };
}

export function resetSelectionsAction(): IResetSelectionsAction {
    return {
        type: RESET_SELECTIONS,
    };
}

export function setContractScrollToPartyAction(
    party: IParty
): ISetContractScrollToPartyAction {
    return {
        type: SET_CONTRACT_SCROLL_TO_PARTY,
        party,
    };
}

export function setDraggedPartyAction(party: IParty): ISetDraggedPartyAction {
    return {
        type: SET_DRAGGED_PARTY,
        party,
    };
}

export function setExpandedGroupAction(
    expandedGroup: PartiesType
): ISetExpandedGroupAction {
    return {
        type: SET_EXPANDED_GROUP,
        expandedGroup,
    };
}

export function setLocalPartiesAction(
    parties: IParty[]
): ISetLocalPartiesAction {
    return {
        type: SET_LOCAL_PARTIES,
        parties,
    };
}

export function setPartiesAction(parties: IParty[]): ISetPartiesAction {
    return {
        type: SET_PARTIES,
        parties,
    };
}

export function setScrollToPartyAction(party: IParty): ISetScrollToPartyAction {
    return {
        type: SET_SCROLL_TO_PARTY,
        party,
    };
}
