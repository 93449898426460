export const FILE_TYPE_DOC = 'application/msword';
export const FILE_TYPE_DOCX =
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const FILE_TYPE_KOREAN_DOC = 'application/haansoftdoc';
export const FILE_TYPE_KOREAN_DOCX = 'application/haansoftdocx';
export const FILE_TYPE_PDF = 'application/pdf';
export const FILE_TYPE_RTF = 'text/rtf';
export const FILE_TYPE_RTF_2 = 'application/rtf';

export const wordFileTypes = [FILE_TYPE_DOCX, FILE_TYPE_DOC];

export enum PreviewFileStatus {
    CANCELLED = 'cancelled',
    FAILED = 'failed',
    IN_QUEUE = 'in_queue',
    SUCCESSFUL = 'successful',
    UPLOADING = 'uploading',
}

export enum PreviewFileType {
    WORD = 'word',
    PDF = 'pdf',
    RTF = 'rtf',
    OTHER = 'other',
}

export enum ValidHeaderBits {
    WORD = '504b34',
    PDF = '25504446',
}

export const ValidHeaderBitsToPreviewMimeType: { [key: string]: string } = {
    [ValidHeaderBits.WORD]: FILE_TYPE_DOCX,
    [ValidHeaderBits.PDF]: FILE_TYPE_PDF,
};

export const previewFileTypeToValidMimeType = {
    [PreviewFileType.WORD]: wordFileTypes[0],
    [PreviewFileType.PDF]: FILE_TYPE_PDF,
    [FILE_TYPE_DOCX]: PreviewFileType.WORD,
    [FILE_TYPE_PDF]: PreviewFileType.PDF,
};

export const validFileTypes = [...wordFileTypes, FILE_TYPE_PDF];

export interface IWizardFileUploaderState {
    dragging: boolean;
    filesSelected: boolean;
    fileList: FileList;
    files: IPreviewFile[];
    invalidFileError: boolean;
    multipleFilesError: boolean;
}

export interface IPreviewFile {
    abortController: AbortController;
    file: File | Blob;
    id: string;
    index: number;
    mimeType: string;
    name: string;
    sizeBytes: number;
    status: PreviewFileStatus;
    type: PreviewFileType;
    uploadProgress: number;
}

export interface IWizardFileUploaderProps {
    className?: string;
    isUploading: boolean;
    multiple: boolean;
    onChangeCallback?: (files: IPreviewFile[]) => void;
}
