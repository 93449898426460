import { ReportViewSelectProps } from './ReportViewSelect.types';
import { Select, SelectOption } from '@thought-river/ui-components';
import styles from './ReportViewSelect.module.scss';
import { ReportView } from '@modules/reports/contractReports/consts';
import { useGetAvailableViews } from '../../hooks';

const ReportViewSelect = ({
    selectedView,
    onViewChange,
}: ReportViewSelectProps) => {
    const availableViews = useGetAvailableViews();
    const sortedOptions = availableViews.sort((a, b) =>
        a.label.localeCompare(b.label)
    );

    const value = sortedOptions.find((o) => o.value === selectedView);

    return (
        <Select
            className={styles.select}
            label="Views"
            options={sortedOptions}
            value={value}
            onChange={(option: SelectOption) => {
                onViewChange(option.value as ReportView);
            }}
        />
    );
};

export default ReportViewSelect;
