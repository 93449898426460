import * as React from 'react';

import './SuggestionsSidebar.scss';
import ParagraphSuggestion from '../IssuePane/IssuePaneTabs/IssuePaneSuggestionTab/ParagraphSuggestions/ParagraphSuggestion/ParagraphSuggestion';
import {
    ISuggestionsSidebarProps,
    ISuggestionsSidebarState,
} from './SuggestionsSidebar-types';
import { Drawer } from '@thought-river/ui-components';

class SuggestionsSidebar extends React.Component<
    ISuggestionsSidebarProps,
    ISuggestionsSidebarState
> {
    constructor(props: ISuggestionsSidebarProps) {
        super(props);

        this.state = {
            editedSuggestionIndex: null,
        };

        this.onClose = this.onClose.bind(this);
    }

    private onClose() {
        const { onClose: onCloseCallback } = this.props;

        this.setState({
            editedSuggestionIndex: null,
        });

        onCloseCallback();
    }

    render() {
        const { onSuggestionSelected, row, show, width, suggestions } =
            this.props;

        const { editedSuggestionIndex } = this.state;

        const rowPropertyId = row?.lexiblePropertyId;

        const relevantSuggestions = suggestions.filter(
            (s) => s.property_uuid === rowPropertyId
        );
        const suggestionsText = relevantSuggestions.map((s) => s.content);

        return (
            <Drawer
                data-id="suggestions-sidebar"
                onClose={this.onClose}
                open={show}
                width={width}
                title={row?.title}
            >
                <div
                    className="suggestions-sidebar-inner-wrapper"
                    data-id="sidebar-inner-wrapper"
                >
                    <div className="sidebar-content" data-id="sidebar-content">
                        <div className="suggestions-list-wrapper">
                            <div className="suggestions-list">
                                {suggestionsText.map((suggestion, i) => (
                                    <ParagraphSuggestion
                                        key={suggestion}
                                        editing={editedSuggestionIndex === i}
                                        isSelected={
                                            row?.selectedSuggestion
                                                ? row.selectedSuggestion ===
                                                  suggestion
                                                : i === 0
                                        }
                                        onClick={(suggestionIndex) => {
                                            this.setState({
                                                editedSuggestionIndex:
                                                    suggestionIndex,
                                            });
                                        }}
                                        onSuggestionSelected={() =>
                                            onSuggestionSelected(suggestion)
                                        }
                                        suggestion={suggestion}
                                        suggestionIndex={i}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
        );
    }
}

export default SuggestionsSidebar;
