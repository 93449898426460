import { Button, Icon, IconButton } from '@thought-river/ui-components';
import { useHistory } from 'react-router-dom';
import styles from './Sidebar.module.scss';
import UserReportsList from './UserReportsList/UserReportsList';
import classNames from 'classnames';
import { useState } from 'react';

const Sidebar = () => {
    const history = useHistory();
    const [collapsed, setCollapsed] = useState(false);

    return (
        <>
            <div
                className={classNames(
                    styles.sidebar,
                    collapsed && styles.collapsed
                )}
            >
                {collapsed ? (
                    <IconButton onClick={() => history.push('/reports')}>
                        <Icon type="add" />
                    </IconButton>
                ) : (
                    <div className={styles.newReportButtonWrapper}>
                        <Button
                            variant="secondary"
                            startIcon={<Icon type="add" />}
                            onClick={() => history.push('/reports')}
                        >
                            New Report
                        </Button>
                    </div>
                )}
                {!collapsed && <UserReportsList />}
            </div>
            <div className={styles.expandButtonWrapper}>
                <IconButton
                    className={styles.expandButton}
                    onClick={() => setCollapsed(!collapsed)}
                    size="small"
                >
                    <Icon
                        type="arrow-left"
                        className={styles.expandButtonIcon}
                    />
                </IconButton>
            </div>
        </>
    );
};

export default Sidebar;
