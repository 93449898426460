import { analyticsService } from '../../services/Analytics/Analytics';
import { isRedirectionPage } from './Auth-helpers';
import { tokenHasExpired } from './Auth-helpers';
import { IAuthProps } from './Auth-types';

export function analytics() {
    return (target: any) => {
        const componentDidMount = target.prototype.componentDidMount;
        const handleLogout = target.prototype.handleLogout;

        target.prototype.componentDidMount = async function () {
            const { loginSuccess, tokenExpiry, setLoginSuccess } = this
                .props as IAuthProps;

            const authRedirect = isRedirectionPage();

            if (!authRedirect && !tokenHasExpired(tokenExpiry)) {
                await analyticsService.initialise();

                if (loginSuccess) {
                    setLoginSuccess(false);
                }
            }

            componentDidMount.apply(this, arguments);
        };

        target.prototype.handleLogout = async function () {
            await analyticsService.resetSession();
            handleLogout.apply(this, arguments);
        };
    };
}
