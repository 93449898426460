import classNames from 'classnames';
import { useState } from 'react';
import styles from './CollapseList.module.scss';
import { Icon } from '@thought-river/ui-components';
import { Collapse } from '@mui/material';
import { CollapseListProps } from './CollapseList.types';

const CollapseList = ({
    classes,
    children,
    title,
    expanded: expandedOnMount = true,
}: CollapseListProps) => {
    const [expanded, setExpanded] = useState(expandedOnMount);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div className={classNames(styles.wrapper, classes?.root)}>
            <div
                className={classNames(styles.title, classes?.title)}
                onClick={handleExpandClick}
            >
                <span className={styles.titleText}>{title}</span>
                <Icon
                    type="arrow-down"
                    className={classNames(
                        styles.expandMoreIcon,
                        expanded && styles.expanded
                    )}
                />
            </div>
            <Collapse
                in={expanded}
                timeout="auto"
                unmountOnExit
                classes={{
                    root: styles.collapseRoot,
                }}
            >
                <>{children}</>
            </Collapse>
        </div>
    );
};

export default CollapseList;
