import { Action } from 'redux';
import { ContractPane } from '../Contract/Contract-types';

export const SET_PANE_WIDTH = 'SET_PANE_WIDTH';
export const PANE_WIDTH_0 = 0;
export const PANE_WIDTH_25 = 25;
export const PANE_WIDTH_50 = 50;
export const PANE_WIDTH_75 = 75;
export const PANE_WIDTH_100 = 100;
export const PANE_WIDTH_TWO_THIRDS = 67;
export const PANE_WIDTH_ONE_THIRD = 33;

export interface IPaneDividerState {
    draggingInProgress: boolean;
}

export interface IPaneDividerStateProps {
    analyticsReportWidth: number;
    analyticsSidebarWidth: number;
    heatmapWidth: number;
    insightWidth: number;
    issueListPaneWidth: number;
    overviewWidth: number;
    partyContractWidth: number;
    partySidebarWidth: number;
    playbookWidth: number;
    sidebarWidth: number;
}

export interface IPaneDividerReducer {
    analyticsReportWidth: number;
    analyticsSidebarWidth: number;
    heatmapWidth: number;
    insightWidth: number;
    issueListPaneWidth: number;
    overviewWidth: number;
    partyContractWidth: number;
    partySidebarWidth: number;
    playbookWidth: number;
    sidebarWidth: number;
}

export interface IPaneDividerDispatchProps {
    setPaneWidth: (pane: ContractPane, width: number) => void;
}

export interface IPaneDividerProps
    extends IPaneDividerDispatchProps,
        IPaneDividerStateProps {
    disabled?: boolean;
    leftPane: ContractPane;
    rightPane: ContractPane;
}

export interface ISetPaneWidthAction extends Action {
    type: typeof SET_PANE_WIDTH;
    pane: ContractPane;
    width: number;
}

export type IPaneDividerActionTypes = ISetPaneWidthAction;
