import { ContractTree } from '@thought-river/negotiations-common/dist/api/contractManagement';
import {
    GridInputRowSelectionModel,
    GridRowSelectionModel,
} from '@mui/x-data-grid-premium';

export interface ContractsDataGridProps {
    data?: ContractTree;
    rowSelectionModel: GridInputRowSelectionModel;
    onSelectedRowsChange: (model: GridRowSelectionModel) => void;
    toolbarLeftContent: JSX.Element;
}

export enum ContractStatusFilterOption {
    Signed = 'Signed',
    Template = 'Template',
}
