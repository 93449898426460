import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { useCallback, useMemo } from 'react';
import { CONTRACTS_DATA_GRID_STATE_LOCAL_STORAGE_KEY } from '../consts';

const getLocalStorageConfig = () => {
    try {
        const parsedConfig = JSON.parse(
            localStorage.getItem(CONTRACTS_DATA_GRID_STATE_LOCAL_STORAGE_KEY) ||
                '{}'
        );

        return parsedConfig as GridInitialStatePremium;
    } catch {
        return {};
    }
};

export const useLocalStorageConfig = () => {
    const config = useMemo(() => getLocalStorageConfig(), []);

    const handleConfigChange = useCallback(
        (config: GridInitialStatePremium) => {
            localStorage.setItem(
                CONTRACTS_DATA_GRID_STATE_LOCAL_STORAGE_KEY,
                JSON.stringify(config)
            );
        },
        []
    );

    return {
        config,
        onConfigChange: handleConfigChange,
    };
};
