import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from 'react-beautiful-dnd';
import classNames from 'classnames';
import { VirtualizedDraggableListProps } from './DraggableList.types';
import './DraggableList.scss';
import { Icon } from '@thought-river/ui-components';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
    VariableSizeList as List,
    ListChildComponentProps,
} from 'react-window';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const Row = ({ index, data, style }: ListChildComponentProps) => {
    const rowRef = useRef<HTMLDivElement>(null);
    const item = data.items[index];

    useEffect(() => {
        if (rowRef?.current) {
            const observer = new ResizeObserver((entries) => {
                if (entries.length > 0) {
                    if (rowRef.current) {
                        data.setRowHeight(
                            index,
                            entries[0].target.clientHeight
                        );
                    }
                }
            });

            if (rowRef.current?.children) {
                observer.observe(rowRef.current.children[1]);
            }

            return () => observer.disconnect();
        }
    }, [data, index]);

    return (
        <Draggable draggableId={item.id} index={index} key={item.id}>
            {(provided) => (
                <Item
                    style={style}
                    provided={provided}
                    item={item}
                    //@ts-ignore
                    ref={rowRef}
                    onItemClick={data.onItemClick}
                    className={data.itemClassName}
                />
            )}
        </Draggable>
    );
};

const Item = forwardRef<HTMLDivElement, any>((props, ref) => {
    const { provided, item, isDragging, style, onItemClick, className } = props;

    //@ts-ignore
    useImperativeHandle(provided.innerRef, () => ref?.current);

    return (
        <div
            {...provided.draggableProps}
            ref={ref}
            style={{
                ...style,
                ...provided.draggableProps.style,
            }}
            className={classNames(
                'item',
                isDragging && 'is-dragging',
                className
            )}
            onClick={() => onItemClick?.(item.id)}
        >
            <span className="drag-handle" {...provided.dragHandleProps}>
                <Icon type="drag-indicator" className="drag-handle-icon" />
            </span>
            {item.content}
        </div>
    );
});

const VirtualizedDraggableList = ({
    classes,
    items,
    defaultItemHeight,
    onReorder,
    onItemClick,
}: VirtualizedDraggableListProps) => {
    const listRef = useRef(null);
    const rowHeights = useRef({});

    const getRowHeight = (index: number) =>
        rowHeights.current[index] || defaultItemHeight;

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        if (result.source.index === result.destination.index) {
            return;
        }

        const reorderedItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        onReorder?.(reorderedItems);
    };

    const setRowHeight = (index: number, size: number) => {
        if (listRef.current) {
            rowHeights.current = { ...rowHeights.current, [index]: size };
            //@ts-ignore
            listRef.current.resetAfterIndex?.(index);
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
                droppableId="droppable"
                mode="virtual"
                renderClone={(provided, snapshot, rubric) => (
                    <Item
                        provided={provided}
                        isDragging={snapshot.isDragging}
                        item={items[rubric.source.index]}
                        className={classes?.item}
                    />
                )}
            >
                {(provided) => (
                    <AutoSizer>
                        {({ height, width }: any) => (
                            <List
                                //@ts-ignore
                                ref={listRef}
                                outerRef={provided.innerRef}
                                className={classNames(classes?.list, 'list')}
                                height={height}
                                width={width}
                                itemSize={getRowHeight}
                                itemCount={items.length}
                                overscanCount={10}
                                itemData={{
                                    items: items,
                                    setRowHeight,
                                    onItemClick,
                                    itemClassName: classes?.item,
                                }}
                            >
                                {Row}
                            </List>
                        )}
                    </AutoSizer>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default VirtualizedDraggableList;
