import { Component } from 'react';

import {
    IDeleteContractModalProps,
    IDeleteContractModalState,
} from './DeleteContractModal-types';
import ContractResource from '../../resources/ContractResource';
import { toast } from 'react-toastify';
import { Button } from '@thought-river/ui-components';

class DeleteContractModal extends Component<
    IDeleteContractModalProps,
    IDeleteContractModalState
> {
    constructor(props: IDeleteContractModalProps) {
        super(props);

        this.state = { submitting: false };

        this.onSubmit = this.onSubmit.bind(this);
    }

    async onSubmit() {
        const {
            closeModal,
            contract,
            contracts,
            history,
            onModalClose,
            setContracts,
            unsetProcessingContract,
            unsetReprocessingContract,
        } = this.props;

        await this.setState({ submitting: true });

        try {
            await ContractResource.deleteContract(
                contract.id,
                contract.streamCode
            );
            await setContracts(contracts.filter((c) => contract.id !== c.id));
            await unsetProcessingContract(contract.id);
            await unsetReprocessingContract(contract.id);
            toast.success('Contract deleted successfully!');

            history.push('/');
        } catch (error) {
            toast.error('Error deleting contract');
        }

        await this.setState({ submitting: false });

        if (typeof onModalClose === 'function') {
            onModalClose(contract);
        }

        closeModal();
    }

    render() {
        const { closeModal, contract } = this.props;

        const { submitting } = this.state;

        return (
            <div className="delete-contract-modal">
                <div className="section">
                    <p className="section-content">
                        Delete all contract versions of "{contract.contractName}
                        "? <br /> <br />
                        You will not be able to recover them.
                    </p>
                </div>
                <div className="modal-dialog-window-footer">
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        variant="destructive"
                        disabled={submitting}
                        onClick={this.onSubmit}
                    >
                        Delete
                    </Button>
                </div>
            </div>
        );
    }
}

export default DeleteContractModal;
