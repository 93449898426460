import styles from './SectionsOutline.module.scss';

interface SectionsOutlineProps {
    sections: string;
}

const SectionsOutline = ({ sections }: SectionsOutlineProps) => (
    <div className={styles.wrapper}>{sections}</div>
);

export default SectionsOutline;
