import { Action } from 'redux';
import { IReviewStream } from '../../resources/AuthResource-types';
import { IFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import { setAuthDetailsAction, setAuthMetadataAction } from './Auth-actions';

export const CLEAR_LOGIN_DETAILS = 'CLEAR_LOGIN_DETAILS';
export const SET_API_TOKEN = 'SET_API_TOKEN';
export const SET_API_URL = 'SET_API_URL';
export const SET_AUTH_DETAILS = 'SET_AUTH_DETAILS';
export const SET_AUTH_METADATA = 'SET_AUTH_METADATA';
export const SET_BOOKMARK = 'SET_BOOKMARK';
export const SET_LOGGING_OUT = 'SET_LOGGING_OUT';
export const SET_LOGIN_SUCCESS = 'SET_LOGIN_SUCCESS';
export const SET_LOGOUT = 'SET_LOGOUT';
export const SET_SELECTED_STREAM = 'SET_SELECTED_STREAM';
export const SET_STREAMS = 'SET_STREAMS';
export const SET_TOKEN_EXPIRY = 'SET_TOKEN_EXPIRY';
export const SET_USERS = 'SET_USERS';
export const SET_LEGACY_API_TOKEN = 'SET_LEGACY_API_TOKEN';

export enum UserRole {
    AUTHOR = 'author',
    BILLING = 'billing-user',
    BUSINESS = 'business-user',
    LEGAL_ADMIN = 'legal-administrator',
    LEGAL_ADVISER = 'legal-adviser',
    SUPPORT_COORDINATOR = 'support-coordinator',
}

export const API_USER = 'API USER';
export const AUTH0_LOGOUT_IFRAME_ID = 'auth0-logout-iframe';

export interface IAccountUser {
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    streamCodes: string[];
}

export interface UserTenant {
    accountCode: string;
    companyName: string;
}

export interface IAuthState {
    sessionInterval: number;
}

export interface IAuthStateProps {
    accountCode: string;
    apiToken: string;
    audience: string;
    authUrl: string;
    bookmark: string;
    clientId: string;
    featureToggles: IFeatureToggle[];
    history?: any;
    loginSuccess: boolean;
    logout: boolean;
    match?: any;
    propertyDetailsLoginCount: number;
    redirectUrl: string;
    tokenExpiry: string;
    userId: string;
}

export interface IAuthReducer {
    accountCode: string;
    apiToken: string;
    apiUrl: string;
    audience: string;
    authUrl: string;
    bookmark: string;
    clientId: string;
    company: string;
    loggingOut: boolean;
    loginSuccess: boolean;
    logout: boolean;
    redirectUrl: string;
    roles: string[];
    selectedStream: IReviewStream;
    streams: IReviewStream[];
    tokenExpiry: string;
    userId: string;
    users: IAccountUser[];
    tenants: UserTenant[];
    legacyApiToken: string | null;
}

export interface IAuthDispatchProps {
    clearLoginDetails: () => void;
    setApiToken: (apiToken: string) => void;
    setApiUrl: (apiUrl: string) => void;
    setAuthDetails: typeof setAuthDetailsAction;
    setAuthMetadata: typeof setAuthMetadataAction;
    setBookmark: (bookmark: string) => void;
    setLoggingOut: (loggingOut: boolean) => void;
    setLoginSuccess: (loginSuccess: boolean) => void;
    setLogout: (logout: boolean) => void;
    setPropertyDetailsLoginCount: (count: number) => void;
    setSelectedStream: (selectedStream: IReviewStream) => void;
    setTokenExpiry: (tokenExpiry: string) => void;
}

export interface IAuthProps extends IAuthDispatchProps, IAuthStateProps {
    children?: any;
}

export interface IUrlParams {
    apiToken: string;
    changeUser: string;
}

export interface IAuthEnv {
    apiUrl: string;
    audience: string;
    authBaseUrl: string;
    clientId: string;
    redirectUrl: string;
}

export interface ISetStreamsAction extends Action {
    type: typeof SET_STREAMS;
    streams: IReviewStream[];
}

export interface ISetApiTokenAction extends Action {
    type: typeof SET_API_TOKEN;
    apiToken: string;
}

export interface ISetLegacyApiTokenAction extends Action {
    type: typeof SET_LEGACY_API_TOKEN;
    apiToken: string;
}

export interface ISetApiUrlAction extends Action {
    type: typeof SET_API_URL;
    apiUrl: string;
}

export interface ISetTokenExpiryAction extends Action {
    type: typeof SET_TOKEN_EXPIRY;
    tokenExpiry: string;
}

export interface ISetSelectedStreamAction extends Action {
    type: typeof SET_SELECTED_STREAM;
    selectedStream: IReviewStream;
}

export interface IClearLoginDetailsAction extends Action {
    type: typeof CLEAR_LOGIN_DETAILS;
}

export interface ISetBookmarkAction extends Action {
    type: typeof SET_BOOKMARK;
    bookmark: string;
}

export interface ISetAuthDetailsAction extends Action {
    type: typeof SET_AUTH_DETAILS;
    authUrl: string;
    clientId: string;
    audience: string;
    redirectUrl: string;
}

export interface ISetAuthMetadataAction extends Action {
    type: typeof SET_AUTH_METADATA;
    streams: IReviewStream[];
    roles: string[];
    userId: string;
    accountCode: string;
    company: string;
    tenants?: UserTenant[];
}

export interface ISetUsersAction extends Action {
    type: typeof SET_USERS;
    users: IAccountUser[];
}

export interface ISetLoggingOutAction extends Action {
    type: typeof SET_LOGGING_OUT;
    loggingOut: boolean;
}

export interface ISetLoginSuccessAction extends Action {
    type: typeof SET_LOGIN_SUCCESS;
    loginSuccess: boolean;
}

export interface ISetLogoutAction extends Action {
    type: typeof SET_LOGOUT;
    logout: boolean;
}

export type IAuthActionTypes =
    | IClearLoginDetailsAction
    | ISetApiTokenAction
    | ISetApiUrlAction
    | ISetAuthDetailsAction
    | ISetAuthMetadataAction
    | ISetBookmarkAction
    | ISetLoggingOutAction
    | ISetLoginSuccessAction
    | ISetLogoutAction
    | ISetSelectedStreamAction
    | ISetStreamsAction
    | ISetTokenExpiryAction
    | ISetUsersAction
    | ISetLegacyApiTokenAction;
