import * as React from 'react';
import { OpenInNew } from '@mui/icons-material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import {
    IClausesSidebarProps,
    IClausesSidebarState,
} from './ClausesSidebar-types';
import './ClausesSidebar.scss';
import { IContractParagraph } from '../Paragraph/Paragraph-types';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { analytics } from './ClausesSidebar-analytics';
import {
    ClausesSidebarContentSkeleton,
    ClausesSidebarNavigationSkeleton,
} from './ClausesSidebarSkeleton/ClausesSidebarSkeleton';
import { getContractPath } from '@modules/common/helpers';
import { Drawer } from '@thought-river/ui-components';

@analytics()
class ClausesSidebar extends React.Component<
    IClausesSidebarProps,
    IClausesSidebarState
> {
    private contentRef: React.RefObject<HTMLDivElement>;

    constructor(props: IClausesSidebarProps) {
        super(props);

        this.state = {
            currentParagraphIndex: 0,
        };

        this.renderParagraph = this.renderParagraph.bind(this);
        this.renderClauseLink = this.renderClauseLink.bind(this);

        this.contentRef = React.createRef();
    }

    componentDidUpdate(prevProps: IClausesSidebarProps): void {
        const { paragraphs, selectedParagraphOriginUuid, uniqueId, show } =
            this.props;

        if (prevProps.uniqueId !== uniqueId && uniqueId) {
            const targetIndex = paragraphs.findIndex(
                (paragraph) =>
                    paragraph.originUuid === selectedParagraphOriginUuid
            );

            this.setState({
                currentParagraphIndex: targetIndex !== -1 ? targetIndex : 0,
            });

            setTimeout(() => {
                this.scrollToParagraphAtIndex(targetIndex);
            }, 0);
        }

        if (prevProps.show && !show) {
            this.setState({
                currentParagraphIndex: 0,
            });
        }
    }

    private highlightParagraphAtIndex(
        targetIndex: number,
        scrollToParagraphAtIndex: boolean = true
    ) {
        const { paragraphs } = this.props;
        const { currentParagraphIndex } = this.state;

        let nextIndex = targetIndex;

        if (currentParagraphIndex === targetIndex) {
            return;
        }

        if (targetIndex < 0) {
            nextIndex = paragraphs.length - 1;
        }

        if (targetIndex >= paragraphs.length) {
            nextIndex = 0;
        }

        this.setState({
            currentParagraphIndex: nextIndex,
        });

        if (scrollToParagraphAtIndex) {
            this.scrollToParagraphAtIndex(nextIndex);
        }
    }

    // This function is intentionally empty as it is required for analytics
    private onClickClauseLink() {}

    private renderClauseLink(
        paragraph: IContractParagraph,
        index: number
    ): React.ReactNode {
        const { metadata } = this.props;

        if (!metadata) {
            return null;
        }

        const url = `${getContractPath(
            metadata.streamCode,
            metadata.contractId,
            metadata.versionId
        )}/paragraph/${paragraph.id}`;

        return (
            <Link
                className="view-clause-in-contract-btn-wrapper"
                data-id={`view-clause-in-contract-btn-wrapper-${index}`}
                onClick={this.onClickClauseLink}
                target="_blank"
                to={url}
            >
                <Button
                    className="view-clause-in-contract-btn"
                    data-id={`view-clause-in-contract-btn-${index}`}
                    size="small"
                    variant="outlined"
                >
                    <OpenInNew className="view-contract-icon" />
                </Button>
            </Link>
        );
    }

    private renderParagraph(paragraph: IContractParagraph) {
        const { currentParagraphIndex } = this.state;
        const { metadata, renderClauseLinks, paragraphs } = this.props;

        const index = paragraphs.findIndex(
            (currentParagraph) =>
                currentParagraph.originUuid === paragraph.originUuid
        );
        const highlighted = currentParagraphIndex === index;
        const isLastParagraph = index === paragraphs.length - 1;

        return (
            <div
                className={`clause-wrapper ${highlighted ? 'highlighted' : ''}`}
                data-clause-index={index}
                data-id={`clauses-sidebar-clause-${index}`}
                key={paragraph.originUuid}
                onClick={() => {
                    this.highlightParagraphAtIndex(index, false);
                }}
            >
                <div
                    className="clause-details"
                    data-id={`clause-details-${index}`}
                >
                    <div
                        className="clause-number-wrapper"
                        data-id={`clause-number-wrapper-${index}`}
                    >
                        <div
                            className="clause-number"
                            data-id={`clause-number-${index}`}
                        >
                            {paragraph.clauseNumber}
                        </div>
                        {renderClauseLinks && metadata
                            ? this.renderClauseLink(paragraph, index)
                            : null}
                    </div>
                    <div
                        className="clause-text"
                        data-id={`clause-text-${index}`}
                    >
                        {paragraph.text}
                    </div>
                </div>
                {isLastParagraph ? null : <div className="clause-partition" />}
            </div>
        );
    }

    private scrollToParagraphAtIndex(index: number) {
        const selector = `[data-clause-index='${index}']`;

        const $scrollTarget = document.querySelector(selector) as HTMLElement;

        if ($scrollTarget && this.contentRef.current) {
            const offsetTop = $scrollTarget.offsetTop - 150;
            const targetOffset = offsetTop - this.contentRef.current.offsetTop;

            if (typeof this.contentRef.current.scrollTo === 'function') {
                this.contentRef.current.scrollTo({
                    left: 0,
                    top: targetOffset,
                    behavior: 'smooth',
                });
            } else {
                this.contentRef.current.scrollTop = targetOffset;
            }
        }
    }

    render() {
        const {
            onCloseCallback,
            show,
            title,
            width,
            paragraphs,
            showSkeletonLoading,
            noClausesMessage,
        } = this.props;
        const { currentParagraphIndex } = this.state;

        return (
            <Drawer
                data-id="clauses-sidebar"
                onClose={onCloseCallback}
                open={show}
                width={width}
                title={title}
            >
                <div
                    className="clauses-sidebar-inner-wrapper"
                    data-id="sidebar-inner-wrapper"
                >
                    {showSkeletonLoading && (
                        <>
                            <ClausesSidebarNavigationSkeleton />
                            <ClausesSidebarContentSkeleton />
                        </>
                    )}

                    {!showSkeletonLoading &&
                        !paragraphs.length &&
                        noClausesMessage}

                    {!showSkeletonLoading && !!paragraphs.length && (
                        <>
                            <div
                                className="sidebar-clause-navigation"
                                data-id="sidebar-clause-navigation"
                            >
                                <div
                                    className="clause-navigation-label"
                                    data-id="clause-navigation-label"
                                >
                                    Linked Clause {currentParagraphIndex + 1} of{' '}
                                    {paragraphs.length}
                                </div>
                                <div
                                    className="clause-navigation-controls"
                                    data-id="clause-navigation-controls"
                                >
                                    <KeyboardArrowUp
                                        className="clause-navigation-control"
                                        data-id="clause-navigation-control"
                                        onClick={() =>
                                            this.highlightParagraphAtIndex(
                                                currentParagraphIndex - 1
                                            )
                                        }
                                    />
                                    <KeyboardArrowDown
                                        className="clause-navigation-control"
                                        data-id="clause-navigation-control"
                                        onClick={() =>
                                            this.highlightParagraphAtIndex(
                                                currentParagraphIndex + 1
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div
                                className="sidebar-content"
                                ref={this.contentRef}
                                data-id="sidebar-content"
                            >
                                <div className="clauses" data-id="clauses">
                                    {paragraphs.map(this.renderParagraph)}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Drawer>
        );
    }
}

export default ClausesSidebar;
