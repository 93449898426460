import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';

import DataGridDropdown from '../DataGridDropdown/DataGridDropdown';
import {
    IssueStatus,
    getIssueStatusLabel,
} from '@thought-river/negotiations-common';

const statuses = [IssueStatus.IGNORED, IssueStatus.OPEN, IssueStatus.CLOSED];

const EditStatusDropdown = (props: GridRenderEditCellParams): JSX.Element => (
    <DataGridDropdown
        cellParams={props}
        options={statuses}
        attribute="status"
        renderLabel={getIssueStatusLabel}
    />
);

export default EditStatusDropdown;
