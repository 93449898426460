import { ContractIssue } from '@thought-river/negotiations-common';
import { IIssueForm } from '../../../IssuePane-types';

export enum IssuePaneNotesTab {
    INTERNAL = 0,
    EXTERNAL = 1,
}

export interface IIssuePaneNotesState {
    selectedTabIndex: IssuePaneNotesTab;
}

export interface IIssuePaneNotesStateProps {
    form: IIssueForm;
    issue: ContractIssue;
}

export interface IIssuePaneNotesDispatchProps {
    setIssueForm: (form: IIssueForm) => void;
}

export interface IIssuePaneNotesProps
    extends IIssuePaneNotesDispatchProps,
        IIssuePaneNotesStateProps {
    formInitialised: boolean;
    debouncedSaveIssue: () => void;
    saveIssue: () => void;
}
