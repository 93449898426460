import { LexibleTheme } from '@thought-river/negotiations-common';
import { IReviewStream } from '../../resources/AuthResource-types';
import { DocumentOcrMode } from '../../resources/DocumentResource-types';
import { IVersionBusinessStatus } from '../Contract/Contract-types';
import { IIssueCategory } from '../IssueListPane/IssueListPane-types';
import {
    ISetPropertyDetailsLoginCountAction,
    SET_PROPERTY_DETAILS_LOGIN_COUNT,
    ISetBusinessStatusesAction,
    ISetCategoriesAction,
    ISetDefaultOcrModeAction,
    ISetDefaultStreamAction,
    ISetGenericThemesAction,
    ISetLexiblePropertiesAction,
    SET_BUSINESS_STATUSES,
    SET_CATEGORIES,
    SET_DEFAULT_OCR_MODE,
    SET_DEFAULT_STREAM,
    SET_GENERIC_THEMES,
    SET_LEXIBLE_PROPERTIES,
    ILexibleProperty,
} from './App-types';

export function setBusinessStatusesAction(
    businessStatuses: IVersionBusinessStatus[]
): ISetBusinessStatusesAction {
    return {
        type: SET_BUSINESS_STATUSES,
        businessStatuses,
    };
}

export function setCategoriesAction(
    categories: IIssueCategory[]
): ISetCategoriesAction {
    return {
        type: SET_CATEGORIES,
        categories,
    };
}

export function setDefaultOcrModeAction(
    defaultOcrMode: DocumentOcrMode
): ISetDefaultOcrModeAction {
    return {
        type: SET_DEFAULT_OCR_MODE,
        defaultOcrMode,
    };
}

export function setDefaultStreamAction(
    defaultStream: IReviewStream
): ISetDefaultStreamAction {
    return {
        type: SET_DEFAULT_STREAM,
        defaultStream,
    };
}

export function setLexiblePropertiesAction(
    properties: ILexibleProperty[]
): ISetLexiblePropertiesAction {
    return {
        type: SET_LEXIBLE_PROPERTIES,
        properties,
    };
}

export function setGenericThemesAction(
    themes: LexibleTheme[]
): ISetGenericThemesAction {
    return {
        type: SET_GENERIC_THEMES,
        themes,
    };
}

export function setPropertyDetailsLoginCountAction(
    count: number
): ISetPropertyDetailsLoginCountAction {
    return {
        type: SET_PROPERTY_DETAILS_LOGIN_COUNT,
        count,
    };
}
