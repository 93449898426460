import { IAccountUser } from '../Auth/Auth-types';
import {
    ISetInternalSharingMessageAction,
    ISetInternalSharingReviewersAction,
    SET_INTERNAL_SHARING_MESSAGE,
    SET_INTERNAL_SHARING_REVIEWERS,
    RESET_INTERNAL_SHARING_FILTERS,
    IResetInternalSharingFilters,
} from './InternalSharingModal-types';

export function setInternalSharingMessageAction(
    message: string
): ISetInternalSharingMessageAction {
    return {
        type: SET_INTERNAL_SHARING_MESSAGE,
        message,
    };
}
export function setInternalSharingReviewersAction(
    reviewers: IAccountUser[]
): ISetInternalSharingReviewersAction {
    return {
        type: SET_INTERNAL_SHARING_REVIEWERS,
        reviewers,
    };
}

export function resetInternalSharingFiltersAction(): IResetInternalSharingFilters {
    return {
        type: RESET_INTERNAL_SHARING_FILTERS,
    };
}
