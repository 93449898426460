import { reduxStore } from '../../redux/store';
import { IAuthReducer } from '../../components/Auth/Auth-types';
import { IRootState } from '../../redux/reducers-types';
import {
    IAnalyticsService,
    MP_NEGOTIATIONS,
    MP_SHARED,
    USER_TYPE_CODES,
} from './Analytics-types';
import { APP_NAME } from '../../components/App/App-types';
import { profileEvents } from './MixpanelHelper-types';
import MixpanelHelper from './MixpanelHelper';
import { findUser } from '../../components/Auth/Auth-helpers';
import mixpanel from 'mixpanel-browser';

class AnalyticsService implements IAnalyticsService {
    mpUrl: string = '';
    mpTrackingId: string = '';
    mpSharedTrackingId: string = '';
    pendoTrackingId: string = '';

    initialised = false;

    setAnalyticsEnv(): void {
        const { mpUrl, mpTrackingId, mpSharedTrackingId2, pendoTrackingId } =
            window.__APP_CONFIG__.analytics;

        this.mpUrl = mpUrl;
        this.mpTrackingId = mpTrackingId;
        this.mpSharedTrackingId = mpSharedTrackingId2;
        this.pendoTrackingId = pendoTrackingId;
    }

    getAccountIdentifier(authReducer: IAuthReducer): string {
        return authReducer.accountCode;
    }

    getCompanyIdentifier(authReducer: IAuthReducer): string {
        return authReducer.company;
    }

    getCurrentUrl() {
        return this.removeQueryParamsFromUrl(window.location.href);
    }

    formatUserRoles(roles: string[]): string {
        return Array.isArray(roles)
            ? roles.map((role) => USER_TYPE_CODES[role]).join('|')
            : '';
    }

    /**
     * Initialises the analytics service
     */
    async initialise(): Promise<void> {
        const { auth } = reduxStore.store.getState() as IRootState;
        const { userId, roles } = auth;
        this.setAnalyticsEnv();

        if (this.mpTrackingId) {
            await this.initialiseMixpanel(
                MP_NEGOTIATIONS,
                this.mpTrackingId,
                auth
            );
        }

        if (this.mpSharedTrackingId) {
            await this.initialiseMixpanel(
                MP_SHARED,
                this.mpSharedTrackingId,
                auth
            );
        }

        if (this.pendoTrackingId && typeof window['pendo'] !== 'undefined') {
            window['pendo'].initialize({
                sanitizeUrl: (url: string) =>
                    this.removeQueryParamsFromUrl(url),
                visitor: {
                    id: userId,
                    emailDomain: this.getCompanyIdentifier(auth),
                    roles: this.formatUserRoles(roles),
                },
                account: {
                    application: APP_NAME,
                    environment: this.resolveEnvironment(),
                    id: this.getAccountIdentifier(auth),
                    region: window.__APP_CONFIG__.auth.region,
                },
            });

            window['pendo'].location?.addTransforms([
                {
                    attr: 'search',
                    action: 'ExcludeKeys',
                    data: ['jwt'],
                },
            ]);
        }

        this.initialised = true;
    }

    async initialiseMixpanel(
        project: string,
        trackingId: string,
        auth: IAuthReducer
    ) {
        const { roles, userId, users } = auth;
        const user = findUser(users, userId);

        mixpanel.init(
            trackingId,
            {
                api_host: this.mpUrl,
                debug: process.env.NODE_ENV !== 'production',
                ip: false,
                property_blacklist: [
                    '$current_url',
                    '$referrer',
                    '$initial_referrer',
                    'mp_page',
                ],
                track_pageview: false,
                ignore_dnt: true,
            },
            project
        );

        if (mixpanel[project]) {
            await mixpanel[project].identify(userId);

            if (mixpanel[project].people) {
                await mixpanel[project].people.set({
                    'User ID': userId,
                    Company: this.getCompanyIdentifier(auth),
                    Account: this.getAccountIdentifier(auth),
                    Region: window.__APP_CONFIG__.auth.region,
                    $email: user?.email,
                    $first_name: user?.firstName,
                    $last_name: user?.lastName,
                    'User Roles': roles,
                    'Screen Width': window.screen.width,
                    'Screen Height': window.screen.height,
                });
            }
        }
    }

    /**
     * Submits an event to the analytics service
     *
     * @param action - A description of the behaviour (e.g. 'Clicked Delete', 'Added a component', 'Deleted account')
     * @param payload - an object with additional data passed to the event
     */
    async recordEvent(action: string, payload: any = {}) {
        const { auth } = reduxStore.store.getState() as IRootState;

        if (!this.initialised) {
            return;
        }

        const currentUrl = this.getCurrentUrl();

        const mpEventPayload = {
            Action: action,
            UserId: auth.userId,
            Application: APP_NAME,
            Account: this.getAccountIdentifier(auth),
            Company: this.getCompanyIdentifier(auth),
            Roles: this.formatUserRoles(auth.roles),
            $current_url: currentUrl,
            $referrer: currentUrl,
            $initial_referrer: currentUrl,
            mp_page: currentUrl,
            ...payload,
        };

        if (this.mpTrackingId && mixpanel[MP_NEGOTIATIONS]) {
            mixpanel[MP_NEGOTIATIONS].track(action, mpEventPayload);

            if (profileEvents.includes(action)) {
                const mixpanelHelper = new MixpanelHelper(MP_NEGOTIATIONS);
                mixpanelHelper.incrementProperty(action);
                mixpanelHelper.registerFirstProperty(action);
                mixpanelHelper.registerLastProperty(action);
            }
        }

        if (this.mpSharedTrackingId && mixpanel[MP_SHARED]) {
            mixpanel[MP_SHARED].track(action, mpEventPayload);

            if (profileEvents.includes(action)) {
                const sharedMixpanelHelper = new MixpanelHelper(MP_SHARED);
                sharedMixpanelHelper.incrementProperty(action);
                sharedMixpanelHelper.registerFirstProperty(action);
                sharedMixpanelHelper.registerLastProperty(action);
            }
        }
    }

    resolveEnvironment() {
        const appUrl = window.location.origin;

        if (process.env.NODE_ENV === 'production') {
            return 'Production';
        }

        if (appUrl.toLowerCase().includes('tst')) {
            return 'Testing';
        }

        return 'Development';
    }

    removeQueryParamsFromUrl(url: string) {
        return typeof url === 'string' ? url.split('?')[0] : '';
    }

    async resetSession() {
        if (!this.initialised) {
            return;
        }

        try {
            if (this.mpTrackingId && mixpanel[MP_NEGOTIATIONS]) {
                await mixpanel[MP_NEGOTIATIONS].reset();
            }

            if (this.mpSharedTrackingId && mixpanel[MP_SHARED]) {
                await mixpanel[MP_SHARED].reset();
            }
        } catch {
            //
        }
    }
}

export const analyticsService = new AnalyticsService();
