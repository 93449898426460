import { IContractData, IContracts } from 'types/thoughtriver';

export interface IContractResourceStatic {
    getContract: (
        contractId: string,
        stream: string
    ) => Promise<IContractResponse>;
    deleteContract: (contractId: string, stream: string) => Promise<Response>;
    getContracts: (
        page: number | null,
        filters: IContractFilters,
        includeVersions?: boolean
    ) => Promise<IContractsResponse>;
    postContract: (name: string, stream: string) => Promise<Response>;
    patchContract: (
        contractId: string,
        name: string,
        stream: string
    ) => Promise<Response>;
    patchReviewers: (
        contractId: string,
        reviewers: string[],
        streamCode: string
    ) => Promise<Response>;
    patchNegotiator: (
        contractId: string,
        negotiator: string,
        streamCode: string
    ) => Promise<Response>;
    reprocess: (
        contractIds: string[],
        streamCode?: string,
        analysisType?: ReprocessAnalysisType
    ) => Promise<any>;
}

export interface IContractResource {}

export interface IContractResponse {
    data: IContractData;
}

export interface IContractsResponse extends IContracts {}

export interface ICreateContractPayload {
    data: {
        attributes: {
            name: string;
        };
    };
}

export interface IContractFilters {
    mine?: boolean;
    other?: boolean;
    unassigned?: boolean;
    negotiating?: boolean;
    signed?: boolean;
    name?: string;
    'contract-ids'?: string;
}

export enum ReprocessAnalysisType {
    COMPLETE = 'complete',
    DELTA = 'delta',
}

export interface IReprocessRequestPayload {
    analysisType?: ReprocessAnalysisType;
    data: {
        relationships: {
            contracts: {
                data: IReprocessContractId[];
            };
        };
    };
}

interface IReprocessContractId {
    id: string;
}
