import '@thought-river/ui-components/dist/index.css';
import '@thought-river/negotiations-common/dist/index.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './styles/index.scss';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'promise-polyfill/src/polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { LicenseInfo } from '@mui/x-license-pro';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import { reduxStore } from './redux/store';
import { HashRouter as Router } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const muiLicenceKey =
    '79bb3bdb5de8c08253202cf5640e6259Tz04NjQ1NyxFPTE3NDIwMzA4NzYwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y';
LicenseInfo.setLicenseKey(muiLicenceKey);

export const { store, persistor } = reduxStore;

ReactDOM.render(
    <StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <App />
                </Router>
            </PersistGate>
        </Provider>
    </StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
