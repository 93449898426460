import { IAppReducer } from '../components/App/App-types';
import { IAuthReducer } from '../components/Auth/Auth-types';
import { IContractListReducer } from '../components/ContractList/ContractList-types';
import { IContractListToolbarReducer } from '../components/ContractListToolbar/ContractListToolbar-types';
import { IContractReducer } from '../components/Contract/Contract-types';
import { IHeatmapSearchBarReducer } from '../components/HeatmapSearchBar/HeatmapSearchBar-types';
import { IInternalSharingModalReducer } from '../components/InternalSharingModal/InternalSharingModal-types';
import { IModalDialogReducer } from '../components/ModalDialog/ModalDialog-types';
import { IPaneDividerReducer } from '../components/PaneDivider/PaneDivider-types';
import { IPartiesReducer } from '../components/Parties/Parties-types';
import { IPartyContractReducer } from '../components/PartyContract/PartyContract-types';
import { IPartyCorrectionReducer } from '../components/PartyCorrection/PartyCorrection-types';
import { IContractSidebarReducer } from '../components/ContractSidebar/ContractSidebar-types';
import { IIssuePaneReducer } from '../components/IssuePane/IssuePane-types';
import { IIssueListFiltersReducer } from '../components/IssueListFilters/IssueListFilters-types';
import { IHeatmapPaneReducer } from '../components/HeatmapPane/HeatmapPane-types';
import { IContractPollingProviderReducer } from '../components/ContractPollingProvider/ContractPollingProvider-types';
import { IFeatureToggleProviderReducer } from '../components/FeatureToggleProvider/FeatureToggleProvider-types';
import { IIssuesReportReducer } from '../components/IssuesReport/IssuesReport-types';

export const PERSIST_KEY = 'persist:root';

export interface IRootState {
    app: IAppReducer;
    auth: IAuthReducer;
    contract: IContractReducer;
    contractList: IContractListReducer;
    contractListToolbar: IContractListToolbarReducer;
    contractPollingProvider: IContractPollingProviderReducer;
    contractSidebar: IContractSidebarReducer;
    featureToggles: IFeatureToggleProviderReducer;
    heatmapPane: IHeatmapPaneReducer;
    internalSharing: IInternalSharingModalReducer;
    issueListFilters: IIssueListFiltersReducer;
    issueModal: IIssuePaneReducer;
    issuePane: IIssuePaneReducer;
    issuesReport: IIssuesReportReducer;
    modalDialog: IModalDialogReducer;
    paneDivider: IPaneDividerReducer;
    parties: IPartiesReducer;
    partyContract: IPartyContractReducer;
    partyCorrection: IPartyCorrectionReducer;
    searchBar: IHeatmapSearchBarReducer;
}
