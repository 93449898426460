import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';

import { IRootState } from '../../redux/reducers-types';
import {
    setHighlightIndexAction,
    setScrollTopAction,
    setSelectedContractIntelligenceAction,
    setSelectedParagraphAction,
    setSelectedPropertyCodeAction,
} from '../Contract/Contract-actions';
import {
    HighlightIndexType,
    ISelectedContractIntelligence,
} from '../Contract/Contract-types';
import HeatmapPane from './HeatmapPane';
import {
    setContractScrollHeightInPixelsAction,
    setContractVisibleHeightInPixelsAction,
    setParagraphHeightsInPixelsAction,
} from './HeatmapPane-actions';
import {
    IHeatmapPaneDispatchProps,
    IHeatmapPaneStateProps,
} from './HeatmapPane-types';

function mapStateToProps(state: IRootState): IHeatmapPaneStateProps {
    return {
        highlightedParagraphs: state.contract.highlightedParagraphs,
        bannerSettings: state.heatmapPane.bannerSettings,
        overviewPaneWidth: state.paneDivider.overviewWidth,
        matchesCount: state.searchBar.matchesCount,
        matchIndex: state.searchBar.matchIndex,
        paragraphs: state.contract.paragraphs,
        selectedContractIntelligence:
            state.contract.selectedContractIntelligence,
        selectedParagraph: state.contract.selectedParagraph,
        searchTerm: state.searchBar.searchTerm,
        selectedPropertyCode: state.contract.selectedPropertyCode,
        sidebarWidth: state.contractSidebar.widthPixels,
        summaryProperties: state.contract.summaryProperties,
        width: state.paneDivider.heatmapWidth,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IHeatmapPaneDispatchProps {
    return {
        setContractScrollHeightInPixels: (heightPixels: number) =>
            dispatch(setContractScrollHeightInPixelsAction(heightPixels)),
        setContractVisibleHeightInPixels: (heightPixels: number) =>
            dispatch(setContractVisibleHeightInPixelsAction(heightPixels)),
        setHighlightIndex: (index: number, highlightType: HighlightIndexType) =>
            dispatch(setHighlightIndexAction(index, highlightType)),
        setParagraphHeightsInPixels: (heights: number[]) =>
            dispatch(setParagraphHeightsInPixelsAction(heights)),
        setScrollTop: (scrollTop: number) =>
            dispatch(setScrollTopAction(scrollTop)),
        setSelectedContractIntelligence: (
            intelligence: ISelectedContractIntelligence
        ) => dispatch(setSelectedContractIntelligenceAction(intelligence)),
        setSelectedParagraph: (...args) =>
            dispatch(setSelectedParagraphAction(...args)),
        setSelectedPropertyCode: (code: string) =>
            dispatch(setSelectedPropertyCodeAction(code)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(HeatmapPane));
