import { lazy } from 'react';

//https://tanstack.com/query/v4/docs/react/devtools#devtools-in-production
export const ReactQueryDevtoolsProduction = lazy(() =>
    import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
        (d) => ({
            default: d.ReactQueryDevtools,
        })
    )
);
