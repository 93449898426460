import { toast } from 'react-toastify';

import { IContractProperty } from '../Contract/Contract-types';
import { PERSIST_KEY } from '../../redux/reducers-types';
import { IAccountUser } from '../Auth/Auth-types';
import {
    ILexibleProperty,
    InputType,
    NONE_IDENTIFIED_OPTION,
    OperatingSystem,
    ValueType,
} from './App-types';
import {
    ContractIssue,
    IssueSeverityLevel,
    LexibleTheme,
    capitalize,
    issueSeveritySortOrder,
} from '@thought-river/negotiations-common';
import { IContractParagraph } from '../Paragraph/Paragraph-types';
import {
    DEFAULT_DATE_FORMAT,
    SelectOption,
} from '@thought-river/ui-components';
import { ISummaryProperty } from '../SummaryPane/SummaryPane-types';
import { SummaryPanelTemplatePropertyOut } from '@thought-river/negotiations-common/dist/api/playbookManager';
import { IParagraph } from 'types/thoughtriver';
import dayjs from 'dayjs';
import get from 'lodash/get';
import diffBy from 'lodash/differenceBy';

export function alphabetizeLexibleProperties(
    propertyA: ILexibleProperty,
    propertyB: ILexibleProperty
) {
    return propertyA.description.localeCompare(
        propertyB.description,
        undefined,
        {
            numeric: true,
            sensitivity: 'base',
        }
    );
}

export function alphabetizeThemes(themeA: LexibleTheme, themeB: LexibleTheme) {
    return themeA.name
        ? themeA.name.localeCompare(themeB.name, undefined, {
              numeric: true,
              sensitivity: 'base',
          })
        : 0;
}

export function hyphenate(text: string): string {
    return typeof text === 'string'
        ? text.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`)
        : '';
}

export function hyphenateSnakeCase(text: string): string {
    return typeof text === 'string' ? text.replace(/_/g, '-') : '';
}

export function hyphenateLabel(text: string): string {
    return typeof text === 'string'
        ? text.toLowerCase().replace(/\s+/g, '-')
        : '';
}

export function isUuid(text: string): boolean {
    const regex = new RegExp(
        /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
    );
    return regex.test(text);
}

export function removeInvalidCharacters(text: string = '') {
    return text
        .replace(/[^a-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\s]/gim, '')
        .replace(/\s{2,}/g, ' ');
}

export function splitByCapitals(text: string, delimiter: string = ' ') {
    return typeof text === 'string'
        ? text.split(/(?=[A-Z])/).join(delimiter)
        : '';
}

export function snakeCaseToLabel(text: string, delimiter: string = ' ') {
    return text
        .split('_')
        .map((label) => capitalize(label))
        .join(delimiter);
}

export function sortByAsc<T>(field: keyof T): (a: T, b: T) => number {
    return (a: T, b: T) => {
        if (a[field] < b[field]) return -1;
        if (a[field] > b[field]) return 1;
        return 0;
    };
}

export function sortByDesc<T>(field: keyof T): (a: T, b: T) => number {
    const sorter = sortByAsc(field);
    return (a, b) => sorter(a, b) * -1;
}

export function doesIntersect<T>(arrayA: T[], arrayB: T[]): boolean {
    return arrayA.some((a) => arrayB.includes(a));
}

export function arraysAreEqualByField<T>(
    arrayA: T[],
    arrayB: T[],
    field: keyof T
): boolean {
    if (arrayA.length !== arrayB.length) {
        return false;
    }

    const sortedA = arrayA.sort(sortByAsc(field));
    const sortedB = arrayB.sort(sortByAsc(field));

    for (let i = 0; i < sortedA.length; i++) {
        if (sortedA[i][field] !== sortedB[i][field]) {
            return false;
        }
    }
    return true;
}

export function getOperatingSystem(): OperatingSystem {
    const platform = window.navigator.platform
        ? window.navigator.platform.toLowerCase()
        : null;

    if (platform) {
        if (platform.indexOf(OperatingSystem.WINDOWS) !== -1) {
            return OperatingSystem.WINDOWS;
        }

        if (platform.indexOf(OperatingSystem.MAC) !== -1) {
            return OperatingSystem.MAC;
        }

        if (platform.indexOf(OperatingSystem.LINUX) !== -1) {
            return OperatingSystem.LINUX;
        }

        if (platform.indexOf(OperatingSystem.ANDROID) !== -1) {
            return OperatingSystem.ANDROID;
        }

        if (platform.indexOf(OperatingSystem.IPHONE) !== -1) {
            return OperatingSystem.IPHONE;
        }

        if (platform.indexOf(OperatingSystem.IPAD) !== -1) {
            return OperatingSystem.IPAD;
        }

        if (platform.indexOf(OperatingSystem.IPOD) !== -1) {
            return OperatingSystem.IPOD;
        }

        if (platform.indexOf(OperatingSystem.BLACKBERRY) !== -1) {
            return OperatingSystem.BLACKBERRY;
        }
    }

    return OperatingSystem.WINDOWS;
}

export async function pollValueIsPersisted(
    resolve: any,
    field: string,
    subfield: string,
    timeElapsed: number = 0
) {
    const reduxPersist = localStorage.getItem(PERSIST_KEY);
    const fallbackWaitTime = 500;
    const interval = 100;
    const timeout = 2500;

    if (reduxPersist) {
        try {
            let fieldValues = get(JSON.parse(reduxPersist), field);

            if (typeof fieldValues === 'string') {
                fieldValues = JSON.parse(fieldValues);
            }

            if (fieldValues && (!subfield || fieldValues[subfield])) {
                resolve(true);
            } else if (timeElapsed >= timeout) {
                resolve(true);
            } else {
                await sleep(interval);
                pollValueIsPersisted(
                    resolve,
                    field,
                    subfield,
                    timeElapsed + interval
                );
            }
        } catch (error) {
            await sleep(fallbackWaitTime);
            resolve(true);
        }
    } else {
        await sleep(fallbackWaitTime);
        resolve(true);
    }
}

export function sleep(milliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export function sortSeverityLevel(
    severityLevelA: IssueSeverityLevel,
    severityLevelB: IssueSeverityLevel
): number {
    return (
        issueSeveritySortOrder[severityLevelA] -
        issueSeveritySortOrder[severityLevelB]
    );
}

export function sortUsers(userA: IAccountUser, userB: IAccountUser) {
    const firstNameA = userA.firstName.toLowerCase();
    const firstNameB = userB.firstName.toLowerCase();

    if (firstNameA > firstNameB) {
        return 1;
    }

    if (firstNameA < firstNameB) {
        return -1;
    }

    return 0;
}

export function stripExtension(text: string) {
    return text.includes('.') ? text.split('.').slice(0, -1).join('.') : text;
}

export async function valuePersistedInLocalStorage(
    field: string,
    subfield: string = ''
) {
    return new Promise((resolve) => {
        pollValueIsPersisted(resolve, field, subfield);
    });
}

export function isInternetExplorer() {
    return (
        navigator &&
        navigator.userAgent &&
        navigator.userAgent.toLowerCase().indexOf('trident') !== -1
    );
}

export function isFirefox() {
    return (
        navigator &&
        navigator.userAgent &&
        navigator.userAgent.toLowerCase().indexOf('firefox') !== -1
    );
}

export function formatParagraphIndex(index: number) {
    return `Para ${index + 1}`;
}

export function copyTextToClipboard(
    text: string,
    showConfirmation: boolean = true,
    fallbackElementId?: string
) {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
    } else if (fallbackElementId) {
        copyHtmlElementBySelector(fallbackElementId);
    }

    if (showConfirmation) {
        toast.success('Copied to Clipboard');
    }
}

export function removeOutdatedKeys(currentObject: any, prevObject: any): any {
    for (const key in currentObject) {
        if (!prevObject[key]) {
            delete currentObject[key];
        }
    }

    return currentObject;
}

export function themesFromIssueParagraphs(
    issue: ContractIssue,
    paragraphs: Array<IContractParagraph>
): Array<string> {
    if (issue.nonAssessedThemes.length > 0) {
        return issue?.nonAssessedThemes.map((theme) => theme.id);
    }
    const clauseParagraphs = issue.clauseNumbers.map((clause) =>
        paragraphs.find((p) => p.id === clause.id)
    );
    const themeIds: Array<string> = [];
    clauseParagraphs.forEach((paragraph) => {
        paragraph!.themeIds.forEach((themeId) => {
            // Fixme: null checks
            themeIds.push(themeId);
        });
    });
    return [...new Set(themeIds)];
}

export function isOnReportView(pathname: string = '') {
    return pathname.endsWith('report') || pathname.endsWith('report/edit');
}

export function copyHtmlElementBySelector(
    selector: string,
    selectorType: string = 'id'
) {
    let element;

    window.getSelection()?.empty();

    if (selectorType === 'id') {
        element = document.getElementById(selector);
    } else {
        element = document.querySelector(selector);
    }

    const range = document.createRange();
    range.selectNode(element!); // Fixme: null checks
    window.getSelection()?.addRange(range);
    document.execCommand('copy'); //TODO: should replace this as it's deprecated
    window.getSelection()?.empty();
}

export function copyRteContent(
    selector: string,
    rteIsFocused: boolean = false
) {
    const selection = window.getSelection();

    if (selection?.toString().length && rteIsFocused) {
        document.execCommand('copy');
    } else {
        const element = document.querySelector(selector);

        if (element) {
            const range = document.createRange();
            range.selectNodeContents(element);
            selection?.removeAllRanges();
            selection?.addRange(range);
            document.execCommand('copy');
            window.getSelection()?.empty();
        }
    }
}

export function escapeRegex(text: string) {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function arrayDifferenceResolver(
    prev: any[],
    current: any[],
    comparisonField: string
): {
    current: any[];
    removed: any[];
    added: any[];
    populated: boolean;
    emptied: boolean;
} {
    const added = diffBy(current, prev, comparisonField);
    const removed = diffBy(prev, current, comparisonField);
    const emptied = !!prev.length && !current.length;
    const populated = !prev.length && !!added.length;

    return {
        current,
        added,
        removed,
        emptied,
        populated,
    };
}

export function resolvePropertyInputType(
    valueType: ValueType,
    options: SelectOption[]
): InputType {
    if (
        [ValueType.DISCRETE, ValueType.YES_NO_CAT].includes(valueType) &&
        options.length <= 3
    ) {
        return InputType.DROPDOWN;
    }

    if (options.length >= 3) {
        return InputType.SEARCHABLE_DROPDOWN;
    }

    if (valueType === ValueType.DATE) {
        return InputType.DATE;
    }

    return InputType.TEXT;
}

export function getPropertyValueTypes(lexibleProperties: ILexibleProperty[]): {
    [propertyCode: string]: ValueType;
} {
    const propertyValueTypes: { [propertyCode: string]: ValueType } = {};

    for (const lexibleProperty of lexibleProperties) {
        propertyValueTypes[lexibleProperty.code] = lexibleProperty.valueType;
    }

    return propertyValueTypes;
}

export function getParagraphsDictionary(
    paragraphs: IContractParagraph[] | IParagraph[]
): {
    [paragraphId: string]: string;
} {
    const paragraphsDictionary: Record<string, string> = {};

    paragraphs.forEach((paragraph) => {
        const originUuid =
            'originUuid' in paragraph
                ? paragraph.originUuid
                : paragraph.attributes['origin-uuid'];

        paragraphsDictionary[paragraph.id] = originUuid;
    });

    return paragraphsDictionary;
}

export function isValidDate(value: string): boolean {
    return !isNaN(Date.parse(value));
}

export function formatDate(value: string) {
    const intValue = Number(value);
    if (!isNaN(intValue) && dayjs.unix(intValue).isValid()) {
        return dayjs.unix(intValue).format(DEFAULT_DATE_FORMAT);
    } else if (isValidDate(value)) {
        return dayjs(value).format(DEFAULT_DATE_FORMAT);
    }
    return value;
}

export function propertyAnswerMissing(value: string) {
    return (
        value === null || value === undefined || value === '-1' || value === ''
    );
}

export function labelToDataId(value: string, prefix: string = '') {
    return `${prefix}${value.replace(/\s+/g, '-').toLowerCase()}`;
}

const getUserValueDisplayText = (
    userValue: string | undefined,
    valueType: ValueType,
    possibleValues: SelectOption[]
) => {
    if (!userValue) {
        return '';
    }

    if (
        valueType === ValueType.DISCRETE ||
        valueType === ValueType.YES_NO_CAT
    ) {
        return (
            possibleValues.find((pValue) => pValue.value === userValue)
                ?.label || ''
        );
    }

    if (valueType === ValueType.DATE) {
        return formatDate(userValue);
    }

    return userValue;
};

const getAutodetectedValueDisplayText = (
    autodetectedValue: string | undefined,
    valueType: ValueType,
    possibleValues: SelectOption[]
) => {
    if (!autodetectedValue) {
        return '';
    }

    if (valueType === ValueType.DATE) {
        return formatDate(autodetectedValue);
    }

    return (
        possibleValues.find((pValue) => pValue.value === autodetectedValue)
            ?.label || autodetectedValue
    );
};

const extractSummaryPropertyValuesDisplayText = (
    property: SummaryPanelTemplatePropertyOut,
    contractProperty: IContractProperty | undefined,
    possibleValues: SelectOption[],
    nearestPositiveAncestorProperty: IContractProperty | undefined
) => {
    const value = (() => {
        // Question has not been asked
        if (!contractProperty) {
            return '';
        }

        if (
            property.value_type === ValueType.YES_NO_CAT &&
            contractProperty.userValue
        ) {
            return (
                possibleValues.find(
                    (pValue) => pValue.value === contractProperty.value
                )?.label ?? contractProperty.value
            );
        }

        // If property is an entity property and it's parent is a No show 'None Identified'
        const isEntityProperty =
            property.value_type !== ValueType.DISCRETE &&
            property.value_type !== ValueType.YES_NO_CAT;
        const isParentValueNo =
            property.parent_uuid &&
            nearestPositiveAncestorProperty?.nonAssessedPropertyId !==
                property.parent_uuid;

        if (isEntityProperty && isParentValueNo) {
            return NONE_IDENTIFIED_OPTION;
        }

        return contractProperty.value || NONE_IDENTIFIED_OPTION;
    })();

    return {
        value,
        userValue: getUserValueDisplayText(
            contractProperty?.userValue,
            property.value_type as ValueType,
            possibleValues
        ),
        autodetectedValue: getAutodetectedValueDisplayText(
            contractProperty?.autodetectedValue,
            property.value_type as ValueType,
            possibleValues
        ),
    };
};

// Function extracting property possible values
// And applying sorting if necessary
const getPropertyPossibleValues = (
    property: SummaryPanelTemplatePropertyOut
) => {
    const values =
        property.possible_values?.map((value) => ({
            label: value.display_text ?? '',
            value: String(value.value),
        })) ?? [];

    if (property.value_type === 'location') {
        values.sort((a, b) => a.label.localeCompare(b.label));
    }

    return values;
};

export interface IGetSummaryPropertiesParams {
    lexibleProperties: ILexibleProperty[];
    paragraphs: IContractParagraph[];
    properties: IContractProperty[];
    templateProperties: SummaryPanelTemplatePropertyOut[];
}

export function getSummaryProperties(
    params: IGetSummaryPropertiesParams
): ISummaryProperty[] {
    const {
        lexibleProperties,
        paragraphs,
        properties,
        templateProperties = [],
    } = params;

    if (
        !lexibleProperties.length ||
        !paragraphs.length ||
        !properties.length ||
        !templateProperties.length
    ) {
        return [];
    }

    const groupedContractProperties: { [code: string]: IContractProperty } = {};

    properties.forEach((property) => {
        groupedContractProperties[property.code] = property;
    });

    const summaryProperties: ISummaryProperty[] = [];

    templateProperties.forEach((property) => {
        const contractProperty = groupedContractProperties[property.dfcode!]; // Fixme: null checks
        const parentProperty = property.parent_uuid
            ? lexibleProperties.find((lp) => lp.id === property.parent_uuid)
            : undefined;

        const possibleValues = getPropertyPossibleValues(property);
        console.log({ possibleValues });
        const nearestPositiveAncestorProperty = findNearestPositiveAncestor(
            lexibleProperties,
            properties,
            property.parent_uuid ?? null
        );
        const linkedParagraphIds =
            findLinkedParagraphIds(
                nearestPositiveAncestorProperty,
                contractProperty,
                property.parent_uuid
            ) || [];
        const relatedParagraphIds =
            findRelatedParagraphIds(
                nearestPositiveAncestorProperty,
                paragraphs,
                property.theme?.uuid! // Fixme: null checks
            ) || [];

        const { value, userValue, autodetectedValue } =
            extractSummaryPropertyValuesDisplayText(
                property,
                contractProperty,
                possibleValues,
                nearestPositiveAncestorProperty
            );

        summaryProperties.push({
            autodetectedValueDisplayText: autodetectedValue,
            code: property.dfcode!, // Fixme: null checks
            question: property.question!, // Fixme: null checks
            helpText: contractProperty?.helpText,
            id: contractProperty?.id ?? null,
            inputType: resolvePropertyInputType(
                property.value_type as ValueType,
                possibleValues
            ),
            lexiblePropertyId: property.uuid!, // Fixme: null checks
            linkedParagraphIds,
            name: property.name!, // Fixme: null checks
            options: possibleValues,
            order: property?.order!, // Fixme: null checks
            paragraphOriginUuids: contractProperty?.paragraphOriginUuids ?? [],
            parentProperty: parentProperty!, // Fixme: null checks
            relatedProperties: contractProperty?.relatedProperties || [],
            relatedParagraphIds,
            shortLabel:
                property.short_label || property.name || property.dfcode!, // Fixme: null checks
            theme: {
                id: property.theme?.uuid!, // Fixme: null checks
                name: property.theme?.name!, // Fixme: null checks
            },
            userValueDisplayText: userValue,
            value,
            valueType: property.value_type as ValueType,
            severityLevel:
                contractProperty?.severityLevel || IssueSeverityLevel.UNSCORED,
            reprocessRequired: !contractProperty,
        });
    });

    return summaryProperties;
}

export function findRelatedParagraphIds(
    nearestPositiveAncestorProperty: IContractProperty | undefined,
    paragraphs: IContractParagraph[],
    propertyThemeId: string
): string[] {
    if (nearestPositiveAncestorProperty) {
        return nearestPositiveAncestorProperty.paragraphIds;
    }

    return paragraphs
        .filter((paragraph) => paragraph.themeIds.includes(propertyThemeId))
        .map((paragraph) => paragraph.id);
}

function findLinkedParagraphIds(
    nearestPositiveAncestorProperty: IContractProperty | undefined,
    property: IContractProperty | undefined,
    propertyParentId: string | undefined
) {
    //if entity property
    if (
        property?.valueType !== ValueType.DISCRETE &&
        property?.valueType !== ValueType.YES_NO_CAT
    ) {
        if (
            propertyParentId ===
            nearestPositiveAncestorProperty?.nonAssessedPropertyId
        ) {
            return nearestPositiveAncestorProperty?.paragraphIds;
        }
    } else {
        return property.paragraphIds;
    }
}

export function findNearestPositiveAncestor(
    lexibleProperties: ILexibleProperty[],
    contractProperties: IContractProperty[],
    parentId: string | null
): IContractProperty | undefined {
    const parentProperty = lexibleProperties.find(
        (property) => property.id === parentId
    );
    if (!parentProperty) {
        return;
    }
    const contractParentProperty = contractProperties.find(
        (property) => property.nonAssessedPropertyId === parentProperty.id
    );
    return contractParentProperty?.value === 'Yes'
        ? contractParentProperty
        : findNearestPositiveAncestor(
              lexibleProperties,
              contractProperties,
              parentProperty.parentId
          );
}
