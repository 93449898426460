import { IUsers } from 'types/thoughtriver';
import Api from '../services/api';
import { Method, requestOptions } from '../services/api-types';
import {
    IAuthResourceStatic,
    IAuthResource,
    IAuthResponse,
    IAuthMetadataResponse,
    IAuthRequest,
} from './AuthResource-types';

const AuthResource: IAuthResourceStatic = class AuthResource
    implements IAuthResource
{
    static async login(
        accountCode: string,
        username: string,
        password: string
    ): Promise<IAuthResponse> {
        const payload: IAuthRequest = {
            account_code: accountCode,
            username,
            password,
            grant_type: 'password',
        };

        const options = {
            ...requestOptions,
            authorize: false,
            payload,
        };

        return await (await Api.request(
            Method.POST,
            '/authenticate/',
            options
        ))!.json();
    }

    static async getAuthMetadata(): Promise<IAuthMetadataResponse> {
        const options = {
            ...requestOptions,
            includeStream: false,
        };

        return await (await Api.request(
            Method.GET,
            '/details',
            options
        ))!.json();
    }

    static async getUsers(): Promise<IUsers> {
        const options = {
            ...requestOptions,
            includeStream: false,
        };

        return await (await Api.request(Method.GET, '/users', options))!.json();
    }
};

export default AuthResource;
