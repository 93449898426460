import { Button } from '@thought-river/ui-components';
import styles from './GenericReportConfigPage.module.scss';
import { useState } from 'react';
import classNames from 'classnames';
import SelectedData from './components/rightSection/SelectedData/SelectedData';
import ColumnsList from './components/middleSection/ColumnsList/ColumnsList';
import SelectData from './components/leftSection/SelectData/SelectData';
import omit from 'lodash/omit';
import without from 'lodash/without';
import xor from 'lodash/xor';
import FiltersList from './components/middleSection/FiltersList/FiltersList';
import { useGetSelectedData } from './hooks';
import {
    GenericReportConfigPageProps,
    ReportConfigPageMiddleSectionActiveView,
    SelectedDataItem,
} from './GenericReportConfigPage.types';

const GenericReportConfigPage = ({
    selectedColumns,
    selectedFilters,
    visibleColumns = selectedColumns,
    applyBtnDisabled = false,
    ColumnSelection,
    columnFilterComponents,
    allowHidingColumns = true,
    onCancelBtnClick,
    onApplyBtnClick,
    getColumnLabel,
    getIsColumnHideable,
    onSelectedColumnsChange,
    onSelectedFiltersChange,
    onVisibleColumnsChange,
    unsupportedFilterItems,
}: GenericReportConfigPageProps) => {
    const [middleSectionActiveView, setMiddleSectionActiveView] =
        useState<ReportConfigPageMiddleSectionActiveView>(
            ReportConfigPageMiddleSectionActiveView.COLUMNS_LIST
        );
    const [
        middleSectionActiveFilterColumn,
        setMiddleSectionActiveFilterColumn,
    ] = useState<string | null>(null);

    const [columnSearchTerm, setColumnSearchTerm] = useState('');

    const selectedData = useGetSelectedData(
        selectedColumns,
        selectedFilters,
        visibleColumns,
        getColumnLabel,
        getIsColumnHideable
    );

    const handleSelectedDataRemoved = (removedData: SelectedDataItem) => {
        onSelectedColumnsChange(
            selectedColumns.filter((column) => column !== removedData.id)
        );
        onSelectedFiltersChange(omit(selectedFilters, [removedData.id]));
    };

    const handleSelectedDataVisibilityChange = (
        columnId: string,
        isVisible: boolean
    ) => {
        if (!isVisible) {
            onVisibleColumnsChange?.(
                visibleColumns.filter((column) => column !== columnId)
            );
        } else {
            onVisibleColumnsChange?.([...visibleColumns, columnId]);
        }
    };

    const handleSelectedColumnsChange = (columns: string[]) => {
        const difference = xor(visibleColumns, columns);
        if (columns.length > selectedColumns.length) {
            //new columns added
            onVisibleColumnsChange?.([...visibleColumns, ...difference]);
        } else {
            //columns removed
            onVisibleColumnsChange?.(without(visibleColumns, ...difference));
        }

        //We want to preserve the original order of columns, adding any new columns to the end
        columns.sort((a, b) => {
            const columnAIndex = selectedColumns.findIndex((c) => c === a);
            const columnBIndex = selectedColumns.findIndex((c) => c === b);

            if (columnAIndex === -1) {
                return 1;
            }
            if (columnBIndex === -1) {
                return -1;
            }
            return columnAIndex < columnBIndex ? -1 : 1;
        });
        onSelectedColumnsChange(columns);
    };

    const middleSectionTitle = (() => {
        switch (middleSectionActiveView) {
            case ReportConfigPageMiddleSectionActiveView.COLUMNS_LIST:
                return 'Choose columns';
            case ReportConfigPageMiddleSectionActiveView.FILTERS_LIST:
                return 'Choose filters';
        }
    })();

    const middleSectionContent = (() => {
        switch (middleSectionActiveView) {
            case ReportConfigPageMiddleSectionActiveView.COLUMNS_LIST:
                return (
                    <ColumnsList onSearchTermChanged={setColumnSearchTerm}>
                        <ColumnSelection
                            selectedColumns={selectedColumns}
                            onSelectedColumnsChange={
                                handleSelectedColumnsChange
                            }
                            columnSearchTerm={columnSearchTerm}
                        />
                    </ColumnsList>
                );
            case ReportConfigPageMiddleSectionActiveView.FILTERS_LIST:
                return (
                    <FiltersList
                        activeFilterColumn={middleSectionActiveFilterColumn}
                        selectedFilters={selectedFilters}
                        onFilterChange={onSelectedFiltersChange}
                        onActiveFilterViewChange={
                            setMiddleSectionActiveFilterColumn
                        }
                        availableFilterComponents={columnFilterComponents}
                        getColumnLabel={getColumnLabel}
                        unsupportedFilterItems={unsupportedFilterItems}
                    />
                );
        }
    })();

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.title}>Configure your report</div>
                <div>
                    Select the data fields you wish to see in your report. Apply
                    filters to narrow your results.
                </div>
            </div>
            <div className={styles.content}>
                <div className={classNames(styles.section, styles.leftSection)}>
                    <div className={styles.sectionTitle}>Choose your data</div>
                    <SelectData
                        middleSectionActiveView={middleSectionActiveView}
                        selectedColumnsCount={selectedColumns.length}
                        selectedFiltersCount={
                            Object.keys(selectedFilters).length +
                            (unsupportedFilterItems?.length ?? 0)
                        }
                        onMiddleSectionActiveViewChange={(
                            view: ReportConfigPageMiddleSectionActiveView
                        ) => {
                            setMiddleSectionActiveView(view);
                            setMiddleSectionActiveFilterColumn(null);
                        }}
                    />
                </div>
                <div
                    className={classNames(styles.section, styles.middleSection)}
                >
                    <div className={styles.sectionTitle}>
                        {middleSectionTitle}
                    </div>
                    {middleSectionContent}
                </div>
                <div
                    className={classNames(styles.section, styles.rightSection)}
                >
                    <div className={styles.sectionTitle}>Selected data</div>
                    <SelectedData
                        allowHidingColumns={allowHidingColumns}
                        selectedData={selectedData}
                        onDataReorder={(data) => {
                            onSelectedColumnsChange(data.map((d) => d.id));
                        }}
                        onDataRemove={handleSelectedDataRemoved}
                        onDataVisibilityChange={
                            handleSelectedDataVisibilityChange
                        }
                        onDataClick={(dataId) => {
                            setMiddleSectionActiveView(
                                ReportConfigPageMiddleSectionActiveView.FILTERS_LIST
                            );
                            setMiddleSectionActiveFilterColumn(dataId);
                        }}
                    />
                </div>
            </div>
            <div className={styles.footer}>
                <Button variant="secondary" onClick={onCancelBtnClick}>
                    Cancel
                </Button>
                <Button onClick={onApplyBtnClick} disabled={applyBtnDisabled}>
                    Apply
                </Button>
            </div>
        </div>
    );
};

export default GenericReportConfigPage;
