import { FiltersListProps } from './FiltersList.types';
import styles from './FiltersList.module.scss';
import classNames from 'classnames';
import { List, ListItem } from '@mui/material';
import { Button, SelectOption } from '@thought-river/ui-components';
import omit from 'lodash/omit';
import { ArrowBackIos } from '@mui/icons-material';
import { ColumnIcon } from '@modules/reports/common/components/ColumnIcon';

const FiltersList = ({
    activeFilterColumn,
    availableFilterComponents = {},
    onActiveFilterViewChange,
    onFilterChange,
    selectedFilters,
    getColumnLabel,
    unsupportedFilterItems,
}: FiltersListProps) => {
    const availableFilters = Object.keys(availableFilterComponents).filter(
        (filterColumn) =>
            !unsupportedFilterItems?.some((item) => item.field === filterColumn)
    );

    const FilterComponent = activeFilterColumn
        ? availableFilterComponents[activeFilterColumn]
        : null;

    const handleFilterChange = (filters: any[]) => {
        if (filters.length === 0) {
            onFilterChange(omit(selectedFilters, [activeFilterColumn!]));
        } else {
            const updatedFilters = { ...selectedFilters };
            updatedFilters[activeFilterColumn!] = filters;
            onFilterChange(updatedFilters);
        }
    };

    const handleSelectChange = (selectedOptions: SelectOption[]) => {
        handleFilterChange(selectedOptions.map((o) => o.value));
    };

    const showUnavailableFilterMsg =
        activeFilterColumn && !availableFilters.includes(activeFilterColumn);
    const errorMsgText =
        activeFilterColumn &&
        `Filtering on ${
            getColumnLabel?.(activeFilterColumn) || activeFilterColumn
        } isn’t supported, but we’re working on it!`;

    return (
        <div className={styles.wrapper}>
            {showUnavailableFilterMsg && (
                <div className={styles.errorMsg}>{errorMsgText}</div>
            )}
            {activeFilterColumn && !showUnavailableFilterMsg && (
                <Button
                    className={styles.viewAllBtn}
                    variant="secondary"
                    startIcon={<ArrowBackIos />}
                    onClick={() => onActiveFilterViewChange(null)}
                >
                    View all
                </Button>
            )}
            {!activeFilterColumn || showUnavailableFilterMsg ? (
                <List className={styles.list}>
                    {availableFilters.map((column) => (
                        <ListItem
                            className={classNames(
                                styles.column,
                                selectedFilters[column] && styles.selected
                            )}
                            key={column}
                            onClick={() => {
                                onActiveFilterViewChange(column);
                            }}
                        >
                            <div className={styles.label}>
                                <ColumnIcon column={column} />
                                <div>
                                    {getColumnLabel?.(column || '') || column}
                                </div>
                            </div>
                        </ListItem>
                    ))}
                </List>
            ) : (
                FilterComponent && (
                    <FilterComponent
                        selected={selectedFilters[activeFilterColumn] ?? []}
                        onChange={handleSelectChange}
                    />
                )
            )}
        </div>
    );
};

export default FiltersList;
