import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import HeatmapScrollbar from './HeatmapScrollbar';
import {
    IHeatmapScrollbarDispatchProps,
    IHeatmapScrollbarStateProps,
} from './HeatmapScrollbar-types';
import { IRootState } from '../../redux/reducers-types';
import {
    setSelectedParagraphAction,
    setHighlightIndexAction,
} from '../Contract/Contract-actions';
import { HighlightIndexType } from '../Contract/Contract-types';

function mapStateToProps(state: IRootState): IHeatmapScrollbarStateProps {
    return {
        activeIssueFilters: state.issueListFilters.activeIssueFilters,
        activeSidebarTab: state.contractSidebar.activeTab,
        contract: state.contract.contract,
        contractScrollHeightInPixels:
            state.heatmapPane.contractScrollHeightInPixels,
        contractScrollTop: state.contract.scrollTop,
        contractVisibleHeightInPixels:
            state.heatmapPane.contractVisibleHeightInPixels,
        featureToggles: state.featureToggles.toggles,
        highlightedParagraphs: state.contract.highlightedParagraphs,
        paragraphHeightsInPixels: state.heatmapPane.paragraphHeightsInPixels,
        selectedContractIntelligence:
            state.contract.selectedContractIntelligence,
        selectedParagraph: state.contract.selectedParagraph,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IHeatmapScrollbarDispatchProps {
    return {
        setSelectedParagraph: (...args) =>
            dispatch(setSelectedParagraphAction(...args)),
        setHighlightIndex: (index: number, highlightType: HighlightIndexType) =>
            dispatch(setHighlightIndexAction(index, highlightType)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeatmapScrollbar);
