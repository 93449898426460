import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';
import { IRootState } from '../../redux/reducers-types';
import { setContractAction } from '../Contract/Contract-actions';
import { IDisplayContract } from '../Contract/Contract-types';
import { hideDialogAction } from '../ModalDialog/ModalDialog-actions';
import ShareNegotiationModal from './ShareNegotiationModal';
import {
    IShareNegotiationModalDispatchProps,
    IShareNegotiationModalStateProps,
} from './ShareNegotiationModal-types';

function mapStateToProps(state: IRootState): IShareNegotiationModalStateProps {
    return {
        currentUserId: state.auth.userId,
        users: state.auth.users,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IShareNegotiationModalDispatchProps {
    return {
        setContract: (contract: IDisplayContract) =>
            dispatch(setContractAction(contract)),
        hideDialog: () => dispatch(hideDialogAction()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ShareNegotiationModal));
