import {
    FamiliarityLevel,
    IssueSeverityLevel,
    Ratio,
} from '@thought-river/negotiations-common';

export function getLabelColour(label: FamiliarityLevel | IssueSeverityLevel) {
    let colour = 'white';

    switch (label) {
        case IssueSeverityLevel.SHOWSTOPPER:
            colour = '#c22f15';
            break;
        case IssueSeverityLevel.HIGH:
            colour = '#db6300';
            break;
        case IssueSeverityLevel.MEDIUM:
            colour = '#fdd13b';
            break;
        case IssueSeverityLevel.LOW:
            colour = '#8ad127';
            break;
        case IssueSeverityLevel.OK:
            colour = '#47d2d8';
            break;
        case FamiliarityLevel.UNFAMILIAR:
            colour = '#53167F';
            break;
        case FamiliarityLevel.UNCOMMON:
            colour = '#92299E';
            break;
        case FamiliarityLevel.COMMON:
            colour = '#BC6ABF';
            break;
        case FamiliarityLevel.TEMPLATE:
            colour = '#E2BFE3';
            break;
    }

    return colour;
}

export function formatRatio(
    total: number,
    ratioTotal: number,
    label: FamiliarityLevel | IssueSeverityLevel,
    tooltip: React.ReactNode = null
): Ratio {
    return {
        id: label,
        colour: getLabelColour(label),
        count: ratioTotal,
        label: label
            .split('_')
            .map(
                (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(' '),
        tooltip: tooltip,
        ratio: total ? (ratioTotal / total) * 100 : 0,
    };
}
