import * as React from 'react';

import { IProfileMenuProps, IProfileMenuState } from './ProfileMenu-types';

import './ProfileMenu.scss';
import {
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Zoom,
} from '@mui/material';
import { BusinessOutlined, ExitToAppOutlined } from '@mui/icons-material';
import { analytics } from './ProfileMenu-analytics';
import { Avatar } from '@thought-river/ui-components';
import { auth0Client } from '@modules/auth';

@analytics()
export default class ProfileMenu extends React.Component<
    IProfileMenuProps,
    IProfileMenuState
> {
    private readonly profileRef: React.RefObject<HTMLDivElement>;

    constructor(props: IProfileMenuProps) {
        super(props);

        const user = this.props.users.find(
            (user) => user.id === this.props.userId
        );

        this.state = {
            open: false,
            anchorElement: null!, // Fixme: null checks
            email: user?.email ?? '',
            fullName: user ? `${user.firstName} ${user.lastName}` : '',
            isTenantListOpen: false,
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);

        this.profileRef = React.createRef();
    }

    private handleClose() {
        this.setState({
            anchorElement: null!, // Fixme: null checks
            open: false,
        });
    }

    private handleOpen() {
        this.setState({
            anchorElement: this.profileRef.current!, // Fixme: null checks
            open: true,
        });
    }

    render() {
        const { anchorElement, email, fullName, open } = this.state;
        const { accountCode, tenants } = this.props;

        const nonSelectedTenants =
            [...tenants]
                ?.filter((tenant) => tenant.accountCode !== accountCode)
                .sort((a, b) => {
                    // If both have company_name, sort alphabetically
                    if (a.companyName && b.companyName) {
                        return a.companyName.localeCompare(b.companyName);
                    }

                    // If a has company_name but b doesn't, a comes first
                    if (a.companyName && !b.companyName) {
                        return -1;
                    }

                    // If b has company_name but a doesn't, b comes first
                    if (!a.companyName && b.companyName) {
                        return 1;
                    }

                    // If both don't have company_name, maintain original order
                    return 0;
                }) ?? [];

        return (
            <React.Fragment>
                <div
                    className="profile-menu-anchor header-menu-anchor"
                    onClick={this.handleOpen}
                    ref={this.profileRef}
                    id="user-button"
                >
                    <Avatar label={fullName} size="medium" />
                </div>
                <Menu
                    className="profile-menu"
                    anchorEl={anchorElement}
                    open={open}
                    onClose={this.handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'user-button',
                    }}
                >
                    <div className="menu-item profile-info">
                        <span className="user-info-wrapper">
                            <Avatar label={fullName} size="large" />
                            <div className="user-info-details">
                                <div className="user-name">{fullName}</div>
                                <div className="user-email">{email}</div>
                                <div className="user-account">
                                    {accountCode}
                                </div>
                            </div>
                        </span>
                    </div>

                    {nonSelectedTenants.length ? (
                        <MenuItem
                            className="menu-item"
                            onClick={() => {
                                this.setState({
                                    isTenantListOpen:
                                        !this.state.isTenantListOpen,
                                });
                            }}
                        >
                            <ListItemIcon className="menu-item-icon">
                                <BusinessOutlined />
                            </ListItemIcon>
                            <ListItemText
                                className="menu-item-label"
                                primary="Switch tenant"
                            />
                        </MenuItem>
                    ) : null}

                    <Zoom
                        in={this.state.isTenantListOpen}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div>
                            {nonSelectedTenants.map((tenant) => (
                                <MenuItem
                                    key={tenant.accountCode}
                                    className="menu-item"
                                    onClick={() => {
                                        auth0Client.loginWithRedirect({
                                            authorizationParams: {
                                                tenantCode: tenant.accountCode,
                                            },
                                        });
                                    }}
                                >
                                    <ListItemText
                                        className="menu-item-label"
                                        primary={`${tenant.companyName} (${tenant.accountCode})`}
                                    />
                                </MenuItem>
                            ))}
                        </div>
                    </Zoom>

                    <MenuItem
                        className="menu-item"
                        onClick={() => {
                            this.handleClose();
                            this.props.logout();
                        }}
                    >
                        <ListItemIcon className="menu-item-icon">
                            <ExitToAppOutlined />
                        </ListItemIcon>
                        <ListItemText
                            className="menu-item-label"
                            primary="Sign out"
                        />
                    </MenuItem>
                </Menu>
            </React.Fragment>
        );
    }
}
