export const DEFAULT_MIN_COLUMN_WIDTH = 150;

export const minColumnWidths: Record<string, number> = {
    duration: 100,
    version: 100,
    status: 160,
    processing_steps: 200,
};

export const columnLabels: Record<string, string> = {
    version: 'Version',
    progress: 'Progress',
    upload_date: 'Upload Date',
    deal_type: 'Deal Type',
    counterparty: 'Counterparty',
    owner: 'Owner',
    reviewers: 'Reviewers',
    duration: 'Duration',
    status: 'Status',
};

export const CONTRACTS_DATA_GRID_STATE_LOCAL_STORAGE_KEY =
    'contracts_data_grid_state';
