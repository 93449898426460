export interface ContractInfo {
    name: string;
    uuid: string;
    documentUuid: string;
    versionUuid: string;
    versionNumber: number;
    isLatestVersion: boolean;
    isOnlyVersion: boolean;
    dealTypeCode: string;
    dealTypeUuid: string;
    fileFormats: FileFormat[];
    ownerId: string;
    reviewerIds: string[];
    hasFinishedProcessing: boolean;
    hasFailedProcessing: boolean;
    isSigned: boolean;
    isTemplate: boolean;
}

export enum FileFormat {
    DOCX = 'docx',
    PDF = 'pdf',
}

export enum DocumentFormat {
    ORIGINAL = 'original',
    HTML = 'indexed.html',
    DOCX = 'ast.docx',
    CONVERTED = 'convereted.html',
    JSON = 'paragraphs.json',
}
