import { Component } from 'react';
import { Check, Close, Delete } from '@mui/icons-material';

import {
    IFileUploadProgressProps,
    IFileUploadProgressState,
} from './FileUploadProgress-types';
import { PreviewFileType } from '../WizardFileUploader/WizardFileUploader-types';
import pdfLogo from '../../assets/pdf_logo.png';
import wordLogo from '../../assets/word_logo.png';
import RtfLogo from '../RtfLogo/RtfLogo';
import './FileUploadProgress.scss';
import { LinearProgress } from '@mui/material';

class FileUploadProgress extends Component<
    IFileUploadProgressProps,
    IFileUploadProgressState
> {
    render() {
        const {
            disableDeletion,
            filename,
            fileType,
            progress,
            onCancelUpload,
            onDeleteUpload,
        } = this.props;

        let logo = (
            <img className="file-type-img" src={wordLogo} alt="word-logo" />
        );

        switch (fileType) {
            case PreviewFileType.PDF:
                logo = (
                    <img
                        className="file-type-img"
                        src={pdfLogo}
                        alt="pdf-logo"
                    />
                );
                break;
            case PreviewFileType.RTF:
                logo = <RtfLogo />;
                break;
        }

        return (
            <div
                className="file-upload-progress"
                data-id="file-upload-progress"
            >
                <div className="file-type">{logo}</div>
                <div className="progress-bar-wrapper">
                    <div className="file-name-wrapper">
                        <div className="file-name">{filename}</div>
                        {progress === 100 ? (
                            <div className="file-upload-icons">
                                <Delete
                                    className={`file-upload-icon delete-icon ${
                                        disableDeletion ? 'disabled' : ''
                                    }`}
                                    onClick={() =>
                                        disableDeletion
                                            ? null
                                            : onDeleteUpload()
                                    }
                                />
                                <Check className="file-upload-icon success-icon" />
                            </div>
                        ) : (
                            <div className="file-upload-icons">
                                <Close
                                    className="file-upload-icon cancel-icon"
                                    onClick={onCancelUpload}
                                />
                            </div>
                        )}
                    </div>
                    <LinearProgress
                        className="progress-bar"
                        value={progress}
                        variant="determinate"
                    />
                </div>
            </div>
        );
    }
}

export default FileUploadProgress;
