import {
    GridRenderEditCellParams,
    useGridApiContext,
} from '@mui/x-data-grid-premium';
import {
    getIssueSeverityLevelLabel,
    issueSeverityOptions,
} from '@thought-river/negotiations-common';
import { Select, SelectOption, Tooltip } from '@thought-river/ui-components';

const ImportanceEditCell = ({
    row,
    value,
    id,
    field,
}: GridRenderEditCellParams) => {
    const apiRef = useGridApiContext();

    const handleOnChange = (value: SelectOption) => {
        apiRef.current.setEditCellValue({
            id,
            field,
            value: value.value,
        });
        apiRef.current.stopCellEditMode({
            id,
            field,
        });
    };

    if (!row.issue_id) {
        return (
            <Tooltip title="Cannot edit the importance as this row does not have an issue">
                <div style={{ cursor: 'not-allowed', flexGrow: 1 }}>
                    {getIssueSeverityLevelLabel(value)}
                </div>
            </Tooltip>
        );
    }

    return (
        <Select
            options={issueSeverityOptions}
            onChange={handleOnChange}
            value={issueSeverityOptions.find((o) => o.value === value)}
        />
    );
};

export default ImportanceEditCell;
