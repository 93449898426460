import { Skeleton } from '@mui/material';

import styles from './ClausesSidebarSkeleton.module.scss';

export const ClausesSidebarHeaderSkeleton = () => (
    <div className={styles.headerSkeleton}>
        <Skeleton />
    </div>
);

export const ClausesSidebarNavigationSkeleton = () => (
    <div className={styles.navigationSkeleton}>
        <div className={styles.navigationLabel}>
            <Skeleton />
        </div>
        <Skeleton width={40} />
    </div>
);

export const ClausesSidebarContentSkeleton = () => {
    const paragraphsCount = 3;

    return (
        <div className={styles.contentSkeleton}>
            {[...new Array(paragraphsCount)].map((_, index) => (
                <div key={index} className={styles.paragraphSkeleton}>
                    <Skeleton />
                    <Skeleton variant="rounded" height={80} />
                </div>
            ))}
        </div>
    );
};
