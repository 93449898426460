/**
 * Performs user logout from the legacy UI (menu-uk)
 * by using an iframe loading its logout page
 */
export const legacyUILogout = async (
    iframe: HTMLIFrameElement
): Promise<void> => {
    return new Promise((resolve) => {
        const { legacyUIUrl } = window.__APP_CONFIG__;
        iframe.src = `${legacyUIUrl}/login/logout`;

        const handleLoad = () => {
            resolve();
            iframe.removeEventListener('load', handleLoad);
        };

        iframe.addEventListener('load', handleLoad);
    });
};
