import { GridRenderEditCellParams } from '@mui/x-data-grid-pro';

import DataGridTextInput from '../DataGridTextInput/DataGridTextInput';

const EditSummaryInput = (props: GridRenderEditCellParams): JSX.Element => (
    <DataGridTextInput
        cellParams={props}
        attribute="title"
        multiline={true}
        placeholder="Summary"
        preventLinebreaks={true}
    />
);

export default EditSummaryInput;
