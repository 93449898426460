import { IInsightData } from 'types/thoughtriver';
import {
    HighlightIndexType,
    IContractProperty,
    IContractVersion,
    IDisplayContract,
    ISelectedContractIntelligence,
} from '../Contract/Contract-types';
import { IIssueFiltersValues } from '../IssueListFilters/IssueListFilters-types';
import { IFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import { ContractSidebarTab } from '../ContractSidebar/ContractSidebar-types';
import { IBannerSettings } from '../HeatmapPane/HeatmapPane-types';
import { ISummaryProperty } from '../SummaryPane/SummaryPane-types';
import { ContractIssue, Familiarity } from '@thought-river/negotiations-common';
import { setSelectedParagraphAction } from '../Contract/Contract-actions';

export const RESOLVED = 'resolved';
export const UNHIGHLIGHTED = 'unhighlighted';
export const SIGNIFICANT = 'significant';
export const INTERESTING = 'interesting';

export interface IHighlightDetails {
    highlighted: boolean;
    isFirstHighlighted: boolean;
    isLastHighlighted: boolean;
}

export const defaultInsights: IInsightData = {
    familiarity: {
        similarProvision: 0,
        paragraphCommonality: 'neverSeenBefore',
    },
    market: {
        similarProvision: 0,
        paragraphCommonality: 'common',
    },
    template: false,
};
export interface IParagraphState {}
export interface IParagraphStateProps {
    activeTab: ContractSidebarTab;
    activeIssueFilters: IIssueFiltersValues;
    contract: IDisplayContract;
    contractSidebarTab: ContractSidebarTab;
    featureToggles: IFeatureToggle[];
    highlightEndIndex: number;
    highlightStartIndex: number;
    highlightedParagraphs: IContractParagraph[];
    highlightedText: string;
    highlighting: boolean;
    bannerSettings: IBannerSettings | null;
    selectedIssue: ContractIssue;
    searchTerm: string;
    selectedContractIntelligence: ISelectedContractIntelligence;
    selectedParagraph: IContractParagraph | null;
    selectedPropertyCode: string;
    summaryProperties: ISummaryProperty[];
    selectedVersion: IContractVersion | null;
}

export interface IParagraphReducer {}

export interface IParagraphDispatchProps {
    addParagraphHeightInPixels: (height: number) => void;
    setHighlightIndex: (
        index: number,
        highlightType: HighlightIndexType
    ) => void;
    setSelectedParagraph: typeof setSelectedParagraphAction;
}

export interface IParagraphProps
    extends IParagraphDispatchProps,
        IParagraphStateProps {
    paragraph: IContractParagraph;
    onMouseDown: () => void;
    onMouseUp: () => void;
}

export interface IParagraphTable {
    cell: string;
    cellsInRow: number;
    colSpan: number;
    nextEmptyCells: number;
    prevEmptyCells: number;
    row: string;
    rowSpan: number;
    tableId: number;
    totalParagraphsInTable: number;
}

export interface IContractParagraph {
    charCount: number;
    clauseNumber: string;
    id: string;
    index: number;
    issues: ContractIssue[];
    location: number;
    marketFrequency: number;
    organisationFamiliarity: Familiarity;
    originUuid: string;
    properties: IContractProperty[];
    propertyIds: string[];
    html: string;
    redlinedText: string;
    resolved: boolean;
    reviewed: boolean;
    text: string;
    classes: string;
    themeIds: string[];
    table: IParagraphTable | null;
    wordId: string;
}

export interface IParagraphIssues {
    [paragraphOriginUuid: string]: ContractIssue[];
}

export interface IVisibleParagraph {
    index: number;
    visible: boolean;
}

export type IParagraphActionTypes = any;
