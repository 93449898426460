import { IApiLexibleThemesResponse } from 'types/thoughtriver';
import Api from '../services/api';
import { Method, requestOptions } from '../services/api-types';
import {
    ILexibleThemeResourceStatic,
    ILexibleThemeResource,
} from './LexibleThemeResource-types';

const LexibleThemeResource: ILexibleThemeResourceStatic = class LexibleThemeResource
    implements ILexibleThemeResource
{
    static async getLexibleThemes(): Promise<IApiLexibleThemesResponse> {
        const url = '/lexible/themes?fields[themes][]=name';

        const options = {
            ...requestOptions,
            includeStream: false,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return await (await Api.request(Method.GET, url, options))!.json();
    }
};

export default LexibleThemeResource;
