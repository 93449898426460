import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import ContractPollingProvider from './ContractPollingProvider';
import {
    IContractPollingProviderDispatchProps,
    IContractPollingProviderStateProps,
    IProcessingContract,
    IReprocessingContract,
} from './ContractPollingProvider-types';
import { IRootState } from '../../redux/reducers-types';
import { updateContract } from '../ContractList/ContractList-helpers';
import { IDisplayContract } from '../Contract/Contract-types';
import {
    unsetProcessingContractAction,
    unsetReprocessingContractAction,
    setTimedOutProcessingContractIdAction,
    setTimedOutProcessingContractIdsAction,
    setPollingProcessingAction,
    setPollingReprocessingAction,
    setFailedProcessedContractAction,
    setSuccessfulReprocessedContractAction,
    setFailedReprocessedContractAction,
    setSuccessfulProcessedContractAction,
    updateProcessingContractAction,
} from './ContractPollingProvider-actions';

function mapStateToProps(
    state: IRootState
): IContractPollingProviderStateProps {
    return {
        pollingReprocessing: state.contractPollingProvider.pollingReprocessing,
        pollingProcessing: state.contractPollingProvider.pollingProcessing,
        processingContracts: state.contractPollingProvider.processingContracts,
        reprocessingContracts:
            state.contractPollingProvider.reprocessingContracts,
        timedOutProcessingContractIds:
            state.contractPollingProvider.timedOutProcessingContractIds,
        featureToggles: state.featureToggles.toggles,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch
): IContractPollingProviderDispatchProps {
    return {
        unsetReprocessingContract: (contractId: string) =>
            dispatch(unsetReprocessingContractAction(contractId)),

        unsetProcessingContract: (contractId: string) =>
            dispatch(unsetProcessingContractAction(contractId)),

        updateContract: (
            contractId: string,
            fields: Partial<IDisplayContract>
        ) => updateContract(contractId, fields)(dispatch),

        setPollingProcessing: (polling: boolean) =>
            dispatch(setPollingProcessingAction(polling)),

        setPollingReprocessing: (polling: boolean) =>
            dispatch(setPollingReprocessingAction(polling)),

        setTimedOutProcessingContractId: (contractId: string) =>
            dispatch(setTimedOutProcessingContractIdAction(contractId)),

        setTimedOutProcessingContractIds: (contractIds: string[]) =>
            dispatch(setTimedOutProcessingContractIdsAction(contractIds)),

        setFailedReprocessedContract: (contract: IReprocessingContract) =>
            dispatch(setFailedReprocessedContractAction(contract)),

        setSuccessfulReprocessedContract: (
            contract: IReprocessingContract,
            isPartiallyAssessed: boolean
        ) =>
            dispatch(
                setSuccessfulReprocessedContractAction(
                    contract,
                    isPartiallyAssessed
                )
            ),

        setFailedProcessedContract: (contract: IProcessingContract) =>
            dispatch(setFailedProcessedContractAction(contract)),

        setSuccessfulProcessedContract: (
            contract: IProcessingContract,
            isPartiallyAssessed: boolean
        ) =>
            dispatch(
                setSuccessfulProcessedContractAction(
                    contract,
                    isPartiallyAssessed
                )
            ),

        updateProcessingContract: (contract: IProcessingContract) =>
            dispatch(updateProcessingContractAction(contract)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContractPollingProvider);
