import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import ContractUploadWizardModal from './ContractUploadWizardModal';
import { IRootState } from '../../redux/reducers-types';
import {
    IContractUploadWizardModalDispatchProps,
    IContractUploadWizardModalStateProps,
} from './ContractUploadWizardModal-types';
import { IReviewStream } from '../../resources/AuthResource-types';
import { setDefaultStreamAction } from '../App/App-actions';
import { withRouter } from 'react-router-dom';
import { IProcessingContract } from '../ContractPollingProvider/ContractPollingProvider-types';
import { IDisplayContract } from '../Contract/Contract-types';
import {
    addProcessingContractAction,
    setReprocessingContractAction,
    unsetNotifiedContractAction,
    unsetProcessingContractAction,
} from '../ContractPollingProvider/ContractPollingProvider-actions';
import {
    prependContractAction,
    setContractsAction,
} from '../ContractList/ContractList-actions';
import { IParty } from '../Parties/Parties-types';
import { setLocalPartiesAction } from '../Parties/Parties-actions';

function mapStateToProps(
    state: IRootState
): IContractUploadWizardModalStateProps {
    return {
        openContract: state.contract.contract,
        contracts: state.contractList.contracts,
        defaultStream: state.app.defaultStream,
        failedProcessedContracts:
            state.contractPollingProvider.failedProcessedContracts,
        featureToggles: state.featureToggles.toggles,
        streams: state.auth.streams.filter((stream) => !stream.isPrimary),
        successfulProcessedContracts:
            state.contractPollingProvider.successfulProcessedContracts,
        userId: state.auth.userId,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IContractUploadWizardModalDispatchProps {
    return {
        addProcessingContract: (contract: IProcessingContract) =>
            dispatch(addProcessingContractAction(contract)),
        prependContract: (contract: IDisplayContract) =>
            dispatch(prependContractAction(contract)),
        setContracts: (contracts: IDisplayContract[]) =>
            dispatch(setContractsAction(contracts)),
        setDefaultStream: (stream: IReviewStream) =>
            dispatch(setDefaultStreamAction(stream)),
        setLocalParties: (parties: IParty[]) =>
            dispatch(setLocalPartiesAction(parties)),
        setReprocessingContract: (
            contractId: string,
            contractName: string,
            versionId: string,
            stream: string,
            streamId: string,
            assessmentCount: number,
            userId: string
        ) =>
            dispatch(
                setReprocessingContractAction(
                    contractId,
                    contractName,
                    versionId,
                    stream,
                    streamId,
                    assessmentCount,
                    userId
                )
            ),
        unsetNotifiedContract: (contractId: string) =>
            dispatch(unsetNotifiedContractAction(contractId)),
        unsetProcessingContract: (contractId: string) =>
            dispatch(unsetProcessingContractAction(contractId)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ContractUploadWizardModal));
