import { analyticsService } from '../../services/Analytics/Analytics';
import {
    EVENT_ACTION_VIEW_NEXT_ISSUE,
    EVENT_ACTION_VIEW_PREVIOUS_ISSUE,
} from '../../services/Analytics/Analytics-types';

export function analytics() {
    return (target: any) => {
        const goToNextIssue = target.prototype.goToNextIssue;

        target.prototype.goToNextIssue = function (delta: -1 | 1) {
            const { contract, issue, selectedVersion } = this.props;

            const eventName =
                delta === 1
                    ? EVENT_ACTION_VIEW_NEXT_ISSUE
                    : EVENT_ACTION_VIEW_PREVIOUS_ISSUE;

            analyticsService.recordEvent(eventName, {
                ContractId: contract?.id,
                Stream: contract?.streamCode,
                VersionId: selectedVersion?.id,
                VersionNumber: selectedVersion?.versionNumber,
                IssueId: issue?.id,
            });

            goToNextIssue.apply(this, arguments);
        };
    };
}
