import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { IDocumentClauseMarkerProps } from './DocumentClause.types';
import { getHighlightType } from '../helpers';

import styles from './DocumentClause.module.scss';

const DocumentClauseMarker = ({
    contract,
    contractParagraph,
    activeIssueFilters,
    documentContentRef,
    selectedContractIntelligence,
    isHalfOpacity,
    offsets,
    clauseHeight,
}: IDocumentClauseMarkerProps) => {
    const highlightType = getHighlightType(
        contractParagraph,
        contract,
        activeIssueFilters,
        selectedContractIntelligence
    );

    const className = classNames(
        styles.marker,
        highlightType && styles[highlightType],
        isHalfOpacity && styles.halfOpacity
    );

    if (!documentContentRef.current || !offsets) {
        return null;
    }

    return createPortal(
        <div
            className={className}
            style={
                {
                    '--topOffset': offsets.top,
                    '--leftOffset': offsets.left,
                    '--clauseHeight': clauseHeight,
                } as React.CSSProperties
            }
        />,
        documentContentRef.current
    );
};

export default DocumentClauseMarker;
