import { analyticsService } from '../../services/Analytics/Analytics';
import {
    EVENT_ACTION_BACK_BUTTON,
    EVENT_ACTION_CLOSE_ALL_ISSUES,
    EVENT_ACTION_CONTEXT_MENU_CLICK,
    EVENT_ACTION_DOWNLOAD_CONTRACT_PRIMARY,
    EVENT_ACTION_DOWNLOAD_CONTRACT_SECONDARY,
    EVENT_ACTION_FOCUS_CONTRACT_NAME,
    EVENT_ACTION_REVIEW_TAB,
    EVENT_ACTION_SHARE_CONTRACT_CLICK,
    EVENT_ACTION_VIEW_ALL_OWNERS,
} from '../../services/Analytics/Analytics-types';
import { IAccountUser } from '../Auth/Auth-types';
import { IContractVersion, IDisplayContract } from '../Contract/Contract-types';

export const getUserAvatarLabels = (
    contract: IDisplayContract | null,
    users: IAccountUser[],
    currentUserId: string
) => {
    if (!contract) {
        return [];
    }

    const contractUsers = [];

    // Current user should be shown first
    if (
        contract.negotiatorId === currentUserId ||
        contract.reviewerIds.includes(currentUserId)
    ) {
        const currentUser: IAccountUser | undefined = users.find(
            (user) => user.id === currentUserId
        );

        if (currentUser) {
            contractUsers.push(currentUser);
        }
    }

    // Followed by the contract owner
    const ownerUser: IAccountUser | undefined = users.find(
        (user) => user.id === contract.negotiatorId
    );
    if (ownerUser) {
        contractUsers.push(ownerUser);
    }

    // Followed by other reviewers
    const reviewerUsers = users.filter((user) =>
        contract.reviewerIds.includes(user.id)
    );
    contractUsers.push(...reviewerUsers);

    // Remove duplicate users (e.g. when owner is also a reviewer)
    const uniqueContractUsers = [...new Set(contractUsers)];

    return uniqueContractUsers.map(
        ({ firstName, lastName }) => `${firstName} ${lastName}`
    );
};

export const useAnalytics = (
    contract: IDisplayContract,
    version: IContractVersion | null
) => {
    const payload = {
        ContractId: contract?.id,
        VersionId: version?.id,
        VersionNumber: version?.versionNumber,
    };

    const trackContractNameFocus = () => {
        analyticsService.recordEvent(EVENT_ACTION_FOCUS_CONTRACT_NAME, payload);
    };

    const trackBackButtonClick = () => {
        analyticsService.recordEvent(EVENT_ACTION_BACK_BUTTON, payload);
    };

    const trackTabClick = (tabHref: string) => {
        if (tabHref !== '') {
            return;
        }
        analyticsService.recordEvent(EVENT_ACTION_REVIEW_TAB, payload);
    };

    const trackViewAllOwners = () => {
        analyticsService.recordEvent(EVENT_ACTION_VIEW_ALL_OWNERS, payload);
    };

    const trackPrimaryDownloadClick = () => {
        analyticsService.recordEvent(
            EVENT_ACTION_DOWNLOAD_CONTRACT_PRIMARY,
            payload
        );
    };

    const trackSecondaryDownloadClick = () => {
        analyticsService.recordEvent(
            EVENT_ACTION_DOWNLOAD_CONTRACT_SECONDARY,
            payload
        );
    };

    const trackShareClick = () => {
        analyticsService.recordEvent(
            EVENT_ACTION_SHARE_CONTRACT_CLICK,
            payload
        );
    };

    const trackContextMenuClick = () => {
        analyticsService.recordEvent(EVENT_ACTION_CONTEXT_MENU_CLICK, payload);
    };

    const trackCloseAllIssuesClick = () => {
        analyticsService.recordEvent(EVENT_ACTION_CLOSE_ALL_ISSUES, payload);
    };

    return {
        trackContractNameFocus,
        trackBackButtonClick,
        trackTabClick,
        trackViewAllOwners,
        trackPrimaryDownloadClick,
        trackSecondaryDownloadClick,
        trackShareClick,
        trackContextMenuClick,
        trackCloseAllIssuesClick,
    };
};
