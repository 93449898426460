import {
    ISetHighlightedPartyAction,
    SET_HIGHLIGHTED_PARTY,
    SET_PARTY_TO_ADD,
    ISetPartyToAddAction,
} from './PartyParagraph-types';
import { IParty } from '../Parties/Parties-types';

export function setHighlightedPartyAction(
    highlightedParty: IParty
): ISetHighlightedPartyAction {
    return {
        type: SET_HIGHLIGHTED_PARTY,
        highlightedParty,
    };
}

export function setPartyToAddAction(partyToAdd: string): ISetPartyToAddAction {
    return {
        type: SET_PARTY_TO_ADD,
        partyToAdd,
    };
}
