import { ReactElement } from 'react';
import { Action } from 'redux';
import {
    ISetSelectedPartyAction,
    IUpdatePartyAction,
    IPartyStateProps,
    IAddPartyAction,
    IRemovePartyAction,
} from '../Party/Party-types';
import {
    ISetHighlightedPartyAction,
    ISetPartyToAddAction,
} from '../PartyParagraph/PartyParagraph-types';

export const FLIP_GROUPS = 'FLIP_GROUPS';
export const FORMAL_NAMES = 'formal-names';
export const REFERENCE_NAMES = 'reference-names';
export const RESET_SELECTIONS = 'RESET_SELECTIONS';
export const SET_CONTRACT_SCROLL_TO_PARTY = 'SET_CONTRACT_SCROLL_TO_PARTY';
export const SET_DRAGGED_PARTY = 'SET_DRAGGED_PARTY';
export const SET_EXPANDED_GROUP = 'SET_EXPANDED_GROUP';
export const SET_LOCAL_PARTIES = 'SET_LOCAL_PARTIES';
export const SET_PARTIES = 'SET_PARTIES';
export const SET_SCROLL_TO_PARTY = 'SET_SCROLL_TO_PARTY';
export const VARIANTS = 'variants';

export interface IPartiesDispatchProps {
    addParty: (partyToAdd: string, partyType: PartiesType) => void;
    flipGroups: (typeA: PartiesType, typeB: PartiesType) => void;
    setExpandedGroup: (group: PartiesType) => void;
    setScrollToParty: (party: IParty) => void;
    setSelectedParty: (party: IParty) => void;
    setDraggedParty: (draggedParty: IParty) => void;
    updateParty: (party: IParty) => void;
}

export interface IPartiesProps
    extends IPartiesDispatchProps,
        IPartiesStateProps {
    collapsible?: boolean;
    discardTo: PartiesType;
    flipWith?: PartiesType;
    nextType?: PartiesType;
    prevType?: PartiesType;
    restoreByDefault?: boolean;
    subtitle: ReactElement | string;
    title: string;
    type: PartiesType;
    overlay?: boolean;
    compact?: boolean;
}

export interface IPartiesStateProps {
    expandedGroup: PartiesType;
    parties: IParty[];
    partyToAdd: string;
    selectedParty: IParty;
    scrollToParty: IParty;
    draggedParty: IParty;
}

export interface IPartiesReducer extends IPartiesStateProps, IPartyStateProps {
    contractScrollToParty: IParty;
    highlightedParty: IParty;
    localParties: IParty[];
}

export enum PartiesType {
    OWN_PARTIES = 'own-parties',
    COUNTER_PARTIES = 'counter-parties',
    RECIPROCAL = 'reciprocal-reference-names',
    DISCARDED = 'excluded-terms',
}

export enum PartyVariantType {
    FORMAL = 'formal',
    REFERENCE = 'reference',
}

export interface IParty {
    id: number;
    name: string;
    type: PartiesType;
    originalType: PartiesType;
    variantType: PartyVariantType;
}

export interface ISetContractScrollToPartyAction extends Action {
    type: typeof SET_CONTRACT_SCROLL_TO_PARTY;
    party: IParty;
}

export interface IFlipGroupsAction extends Action {
    type: typeof FLIP_GROUPS;
    typeA: PartiesType;
    typeB: PartiesType;
}

export interface IResetSelectionsAction extends Action {
    type: typeof RESET_SELECTIONS;
}

export interface ISetDraggedPartyAction extends Action {
    type: typeof SET_DRAGGED_PARTY;
    party: IParty;
}

export interface ISetExpandedGroupAction extends Action {
    type: typeof SET_EXPANDED_GROUP;
    expandedGroup: PartiesType;
}

export interface ISetLocalPartiesAction extends Action {
    type: typeof SET_LOCAL_PARTIES;
    parties: IParty[];
}

export interface ISetPartiesAction extends Action {
    type: typeof SET_PARTIES;
    parties: IParty[];
}

export interface ISetScrollToPartyAction extends Action {
    type: typeof SET_SCROLL_TO_PARTY;
    party: IParty;
}

export type IPartiesActionTypes =
    | IAddPartyAction
    | IFlipGroupsAction
    | IRemovePartyAction
    | IResetSelectionsAction
    | ISetContractScrollToPartyAction
    | ISetDraggedPartyAction
    | ISetExpandedGroupAction
    | ISetHighlightedPartyAction
    | ISetLocalPartiesAction
    | ISetPartiesAction
    | ISetPartyToAddAction
    | ISetScrollToPartyAction
    | ISetSelectedPartyAction
    | IUpdatePartyAction;
