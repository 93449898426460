import { Link, useLocation } from 'react-router-dom';
import { useGetReportDefinitions } from '@thought-river/negotiations-common/dist/api/playbookManager';
import styles from './UserReportsList.module.scss';
import './UserReportsList.module.scss';
import { Tooltip } from '@thought-river/ui-components';
import ReportOverflowMenu from '../../ReportOverflowMenu/ReportOverflowMenu';
import classNames from 'classnames';
import { CollapseList } from '@modules/common/components/CollapseList';

const UserReportsList = () => {
    const location = useLocation();
    const isReportSelected = (reportId: string) =>
        location.pathname.endsWith(reportId) ||
        location.pathname.endsWith(`${reportId}/edit`);

    const { data: reportDefinitions } = useGetReportDefinitions({});

    return (
        <>
            <CollapseList title="My reports" classes={{ title: styles.title }}>
                <>
                    {reportDefinitions?.length ? (
                        reportDefinitions.map((reportDefinition) => (
                            <div
                                key={reportDefinition.id}
                                className={classNames(
                                    styles.item,
                                    isReportSelected(
                                        reportDefinition.id_str ?? ''
                                    ) && styles.itemSelected
                                )}
                            >
                                <Tooltip title={reportDefinition.name}>
                                    <Link
                                        to={{
                                            pathname: `/reports/${reportDefinition.id_str}`,
                                            state: {
                                                // Parameter required for tracking view report events
                                                viewReportTriggerLocation:
                                                    'Nav3 My Reports',
                                            },
                                        }}
                                        className={styles.link}
                                    >
                                        {reportDefinition.name}
                                    </Link>
                                </Tooltip>
                                <ReportOverflowMenu
                                    classes={{
                                        button: styles.overflowMenuButton,
                                    }}
                                    reportDefinition={reportDefinition}
                                />
                            </div>
                        ))
                    ) : (
                        <div className={styles.noItemsMessage}>
                            Your reports will appear here
                        </div>
                    )}
                </>
            </CollapseList>
        </>
    );
};

export default UserReportsList;
