import { Component } from 'react';

import {
    IContractProgressBarDataSet,
    IContractProgressBarProps,
    IContractProgressBarState,
    TOTAL_COUNT_DEFAULT_COLOUR,
} from './ContractProgressBar-types';
import './ContractProgressBar.scss';

class ContractProgressBar extends Component<
    IContractProgressBarProps,
    IContractProgressBarState
> {
    private ratio(value: number, total: number): string {
        const ratio = Math.ceil((value / total) * 100);
        return `${ratio === 100 ? 101 : ratio}%`;
    }

    private renderCount(
        dataSet: IContractProgressBarDataSet,
        classes: string = '',
        prefix = '',
        defaultColor: string = TOTAL_COUNT_DEFAULT_COLOUR
    ) {
        const customStyles = {
            color: dataSet.countColour ?? defaultColor,
        };

        return dataSet.showCount ? (
            <div
                className={`contract-progress-bar-count ${classes}`}
                style={customStyles}
            >
                {prefix}
                {dataSet.count}
            </div>
        ) : null;
    }

    private renderLabel(dataSet: IContractProgressBarDataSet, index: number) {
        const labelColour = dataSet.labelColour ?? dataSet.colour;
        return (
            <span
                className="label"
                style={{ color: labelColour }}
                key={`progress-bar-label-${index}`}
            >
                {dataSet.label}
            </span>
        );
    }

    render() {
        const {
            dataSets: [filler, background],
            heightInPixels,
            fullWidth,
        } = this.props;
        const totalResolved = filler.count;
        const totalRemaining = background.count;

        const height = heightInPixels ? heightInPixels : 16;

        const classes = `contract-progress-bar-wrapper ${
            fullWidth ? 'full-width' : ''
        }`;

        const backgroundCustomStyles = {
            backgroundColor: background.colour,
            height: `${height}px`,
        };

        const fillerCustomStyles = {
            backgroundColor: filler.colour,
            height: `${height}px`,
            borderRadius: `${height / 2}px`,
            width: this.ratio(totalResolved, totalRemaining),
        };

        return (
            <div className={classes}>
                {[filler, background].filter(({ label }) => label).length ? (
                    <div className="labels">
                        {[filler, background].map(this.renderLabel)}
                    </div>
                ) : null}
                <div className="contract-progress-bar">
                    {this.renderCount(filler, 'left', '', filler.colour)}
                    <div
                        className="contract-progress-bar-background"
                        style={backgroundCustomStyles}
                    >
                        <div
                            className="contract-progress-bar-filler"
                            style={fillerCustomStyles}
                        />
                    </div>
                    {this.renderCount(background, 'right', '/')}
                </div>
            </div>
        );
    }
}

export default ContractProgressBar;
