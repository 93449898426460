import { useEffect } from 'react';
import { IntelligenceBarsProps } from './IntelligenceBars.types';
import { getOrgFamiliarityRatios } from './helpers';
import { ContractIntelligenceType } from '../Contract/Contract-types';
import { FEATURE_TOGGLE_FAMILIARITY_SIGNED_CONTRACTS_THRESHOLD } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import { Select, SelectOption } from '@thought-river/ui-components';
import {
    IssueSeverityLevel,
    capitalize,
    FragmentedBar,
    FamiliarityLevel,
} from '@thought-river/negotiations-common';
import { getSeverityRatios } from './helpers';
import styles from './IntelligenceBars.module.scss';
import { useAnalytics } from './IntelligenceBars.analytics';

const IntelligenceBars = ({
    assessment,
    contract,
    issues,
    featureToggles,
    selectedContractIntelligence,
    onContractIntelligenceChange,
}: IntelligenceBarsProps) => {
    useEffect(() => {
        onContractIntelligenceChange({
            type: ContractIntelligenceType.RISK,
            highlightType: null,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { trackSelectFA, trackFABarClick } = useAnalytics();

    const handleIntelligenceOptionChanged = (option: SelectOption) => {
        if (option.value === ContractIntelligenceType.FAMILIARITY) {
            trackSelectFA();
        }

        onContractIntelligenceChange({
            type: option.value as ContractIntelligenceType,
            highlightType: null,
        });
    };

    const handleFragmentedBarClick = (fragment: string) => {
        onContractIntelligenceChange({
            ...selectedContractIntelligence,
            highlightType:
                fragment === selectedContractIntelligence.highlightType
                    ? null
                    : (fragment as IssueSeverityLevel | FamiliarityLevel),
        });

        if (
            selectedContractIntelligence.type ===
            ContractIntelligenceType.FAMILIARITY
        ) {
            trackFABarClick();
        }
    };

    const organizationFamiliarityRatios = getOrgFamiliarityRatios(
        assessment.familiarity,
        contract.isTemplate,
        contract.streamCode
    );
    const riskRatios = getSeverityRatios(issues);

    const organizationFamiliarityCount =
        assessment.familiarity.totalOrganization;

    const options = (() => {
        const familiaritySignedContractsThresholdToggle = featureToggles.find(
            (toggle) =>
                toggle.feature ===
                FEATURE_TOGGLE_FAMILIARITY_SIGNED_CONTRACTS_THRESHOLD
        );

        const familiaritySignedContractsThreshold = Number(
            familiaritySignedContractsThresholdToggle?.value ?? 30
        );

        const familiarityOptionDisabled =
            organizationFamiliarityCount < familiaritySignedContractsThreshold;

        return [
            {
                value: ContractIntelligenceType.RISK,
                label: capitalize(ContractIntelligenceType.RISK),
            },
            {
                value: ContractIntelligenceType.FAMILIARITY,
                label: capitalize(ContractIntelligenceType.FAMILIARITY),
                disabled: familiarityOptionDisabled,
            },
        ];
    })();

    const selectedOption = options.find(
        (o) => o.value === selectedContractIntelligence.type
    );

    const riskBar = (
        <FragmentedBar
            ratios={riskRatios || []}
            onFragmentClick={(fragment) => handleFragmentedBarClick(fragment)}
            selectedRatioId={selectedContractIntelligence.highlightType?.toString()}
            dataTestId="intelligence-risk-bar"
        />
    );

    const familiarityBar = (
        <FragmentedBar
            ratios={organizationFamiliarityRatios || []}
            onFragmentClick={(fragment) => handleFragmentedBarClick(fragment)}
            selectedRatioId={selectedContractIntelligence.highlightType?.toString()}
            dataTestId="intelligence-familiarity-bar"
        />
    );

    return (
        <div className={styles.wrapper}>
            <Select
                className={styles.select}
                value={selectedOption}
                onChange={handleIntelligenceOptionChanged}
                options={options}
                data-testid="intelligence-drop-down"
            />
            {selectedOption?.value === ContractIntelligenceType.RISK && riskBar}
            {selectedOption?.value === ContractIntelligenceType.FAMILIARITY &&
                familiarityBar}
        </div>
    );
};

export default IntelligenceBars;
