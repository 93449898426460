import { IRootState } from '../../redux/reducers-types';
import { reduxStore } from '../../redux/store';
import { analyticsService } from '../../services/Analytics/Analytics';
import {
    EVENT_ACTION_SELECT_FA_IN_SP,
    EVENT_ACTION_SELECT_FA_IN_ISSUE_PANE,
    EVENT_ACTION_FA_BAR_CLICK_IN_SP,
    EVENT_ACTION_FA_BAR_CLICK_IN_ISSUE_PANE,
} from '../../services/Analytics/Analytics-types';
import { ContractSidebarTab } from '../ContractSidebar/ContractSidebar-types';

export const useAnalytics = () => {
    const getContractProperties = () => {
        const state = reduxStore.store.getState() as IRootState;
        return {
            ContractId: state.contract.contract?.id,
            VersionId: state.contract.selectedVersion?.id,
            VersionNumber: state.contract.selectedVersion?.versionNumber,
        };
    };

    const trackSelectFA = () => {
        const state = reduxStore.store.getState() as IRootState;

        const inSummaryPane =
            state.contractSidebar.activeTab === ContractSidebarTab.OVERVIEW;

        if (inSummaryPane) {
            analyticsService.recordEvent(
                EVENT_ACTION_SELECT_FA_IN_SP,

                getContractProperties()
            );
        } else {
            analyticsService.recordEvent(
                EVENT_ACTION_SELECT_FA_IN_ISSUE_PANE,

                getContractProperties()
            );
        }
    };

    const trackFABarClick = () => {
        const state = reduxStore.store.getState() as IRootState;

        const inSummaryPane =
            state.contractSidebar.activeTab === ContractSidebarTab.OVERVIEW;

        if (inSummaryPane) {
            analyticsService.recordEvent(
                EVENT_ACTION_FA_BAR_CLICK_IN_SP,

                getContractProperties()
            );
        } else {
            analyticsService.recordEvent(
                EVENT_ACTION_FA_BAR_CLICK_IN_ISSUE_PANE,

                getContractProperties()
            );
        }
    };

    return {
        trackFABarClick,
        trackSelectFA,
    };
};
