import { getTemplateDealTypes, getVariantIndexFromString } from './helpers';
import { DealTypeBadgeProps, TemplateDealTypesProps } from './types';
import { Tooltip } from '@thought-river/ui-components';
import classNames from 'classnames';
import styles from './TemplateDealTypes.module.scss';
import { useUserDealTypes } from '@modules/reports/portfolioReports';

const DealTypeBadge = ({ dealTypeId, dealTypeName }: DealTypeBadgeProps) => {
    const numberOfColorOptions = 10; // This must match the number of colors defined in CSS
    const colorClass = `color-${getVariantIndexFromString(
        dealTypeId,
        numberOfColorOptions
    )}`;

    return (
        <div className={classNames(styles.badge, styles[colorClass])}>
            {dealTypeName}
        </div>
    );
};

const TemplateDealTypes = ({
    template,
    filteredByDealTypeId,
}: TemplateDealTypesProps) => {
    const userDealTypes = useUserDealTypes();
    const templateDealTypes = getTemplateDealTypes(
        userDealTypes,
        template.deal_types,
        filteredByDealTypeId
    );

    const dealTypesCount = templateDealTypes.length;
    if (dealTypesCount === 0) {
        return null;
    }

    const badges = templateDealTypes.map((dealType) => (
        <DealTypeBadge
            key={dealType.streamId}
            dealTypeId={dealType.streamId}
            dealTypeName={dealType.accountName}
        />
    ));

    const firstBadge = badges[0];
    const remainingBadges = badges.slice(1);

    if (dealTypesCount > 1) {
        return (
            <Tooltip
                title={<div className={styles.badges}>{remainingBadges}</div>}
            >
                <div className={styles.multiDealTypes}>
                    {firstBadge} and {remainingBadges.length} more
                </div>
            </Tooltip>
        );
    }

    return firstBadge;
};

export default TemplateDealTypes;
