import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ClausesSidebarProps } from './types';
import { Link } from 'react-router-dom';
import { Alert, Button } from '@thought-river/ui-components';
import { OpenInNew } from '@mui/icons-material';
import styles from './ClausesSidebar.module.scss';
import { IRootState } from '../../../../../redux/reducers-types';
import { IClausesSidebarMetadata } from '../../../../../components/ClausesSidebar/ClausesSidebar-types';
import { IContractParagraph } from '../../../../../components/Paragraph/Paragraph-types';
import AssessmentResource from '../../../../../resources/AssessmentResource';
import VersionResource from '../../../../../resources/VersionResource';
import ParagraphResource from '../../../../../resources/ParagraphResource';
import PropertyResource from '../../../../../resources/PropertyResource';
import { getParagraphsDictionary } from '../../../../../components/App/App-helpers';
import {
    mapParagraph,
    mapProperty,
} from '../../../../../components/App/App-mappers';
import BaseClausesSidebar from '../../../../../components/ClausesSidebar/ClausesSidebar';
import { getContractPath } from '@modules/common/helpers';

const ClausesSidebar = ({
    options,
    onCloseCallback,
    showContractLink,
}: ClausesSidebarProps) => {
    const themes = useSelector((state: IRootState) => state.app.themes);

    const [isLoading, setIsLoading] = useState(false);
    const [paragraphs, setParagraphs] = useState<IContractParagraph[]>(
        options?.paragraphs ?? []
    );
    const [clausesSidebarMetadata, setClausesSidebarMetadata] =
        useState<IClausesSidebarMetadata | null>(null);

    useEffect(() => {
        if (!options) {
            return;
        }

        if (options.paragraphs) {
            setParagraphs(options.paragraphs);
            return;
        }

        const { contractId, propertyCode, stream } = options;

        const fetchParagraphs = async () => {
            setIsLoading(true);

            try {
                setParagraphs([]);

                const versionsResponse = await VersionResource.getVersions(
                    contractId,
                    stream
                );
                const versions = versionsResponse.data;
                const latestVersion = versions[versions.length - 1];

                const assessmentsResponse =
                    await AssessmentResource.getAssessments(
                        contractId,
                        latestVersion.id,
                        stream
                    );
                const assessments = assessmentsResponse.data;
                const latestAssessment = assessments[assessments.length - 1];

                const [paragraphsResponse, propertiesResponse] =
                    await Promise.all([
                        ParagraphResource.getParagraphs(
                            contractId,
                            latestVersion.id,
                            stream
                        ),
                        PropertyResource.getProperties(
                            contractId,
                            latestVersion.id,
                            latestAssessment.id,
                            stream
                        ),
                    ]);

                const paragraphDictionary = getParagraphsDictionary(
                    paragraphsResponse.data
                );

                const properties = propertiesResponse.data.map((propertyData) =>
                    mapProperty(propertyData, themes, paragraphDictionary)
                );

                const paragraphs = paragraphsResponse.data.map(
                    (paragraphData, index) =>
                        mapParagraph(paragraphData, properties, index)
                );

                const selectedProperty = properties.find(
                    (property) => property.code === propertyCode
                );
                if (!selectedProperty) {
                    return;
                }

                const relevantParagraphs = paragraphs.filter((paragraph) =>
                    selectedProperty.paragraphIds.includes(paragraph.id)
                );

                setParagraphs(relevantParagraphs);
                setClausesSidebarMetadata({
                    contractId,
                    propertyId: selectedProperty.id,
                    streamCode: stream,
                    versionNumber: latestVersion.attributes['number'],
                    versionId: latestVersion.id,
                });
            } catch (error) {
                console.error(error);
            }

            setIsLoading(false);
        };

        fetchParagraphs();
    }, [options, themes]);

    const contractLink = clausesSidebarMetadata
        ? getContractPath(
              clausesSidebarMetadata.streamCode,
              clausesSidebarMetadata.contractId,
              clausesSidebarMetadata.versionId
          )
        : '';

    const sidebarTitle = (
        <div className={styles.title}>
            {options?.propertyLabel}

            {showContractLink && (
                <Link
                    className={styles.openContractButton}
                    target="_blank"
                    to={contractLink}
                >
                    <Button size="small" variant="secondary">
                        <OpenInNew className={styles.openContractIcon} />
                        <span>Open contract</span>
                    </Button>
                </Link>
            )}
        </div>
    );

    const noClausesMessage = (
        <div className={styles.noClausesMessage}>
            <Alert severity="info">
                There are no linked clauses for {options?.propertyLabel}.{' '}
                <Link to={contractLink} target="_blank">
                    Open the contract
                </Link>{' '}
                to view any related clauses.
            </Alert>
        </div>
    );

    return (
        <BaseClausesSidebar
            onCloseCallback={onCloseCallback}
            paragraphs={paragraphs}
            title={sidebarTitle}
            show={!!options}
            width={500}
            selectedParagraphOriginUuid={''}
            uniqueId={''}
            renderClauseLinks
            metadata={clausesSidebarMetadata ?? undefined}
            showSkeletonLoading={isLoading}
            noClausesMessage={noClausesMessage}
        />
    );
};

export default ClausesSidebar;
