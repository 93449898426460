import { setSelectedParagraphAction } from '../Contract/Contract-actions';
import {
    HighlightIndexType,
    IContractPropsHistoryProps,
    ISelectedContractIntelligence,
} from '../Contract/Contract-types';
import { IContractParagraph } from '../Paragraph/Paragraph-types';
import { ISummaryProperty } from '../SummaryPane/SummaryPane-types';

export const ADD_PARAGRAPH_HEIGHT_IN_PIXELS = 'ADD_PARAGRAPH_HEIGHT_IN_PIXELS';
export const SET_CONTRACT_SCROLL_HEIGHT_IN_PIXELS =
    'SET_CONTRACT_SCROLL_HEIGHT_IN_PIXELS';
export const SET_CONTRACT_VISIBLE_HEIGHT_IN_PIXELS =
    'SET_CONTRACT_VISIBLE_HEIGHT_IN_PIXELS';
export const SET_PARAGRAPH_HEIGHTS_IN_PIXELS =
    'SET_PARAGRAPH_HEIGHTS_IN_PIXELS';
export const SET_BANNER_SETTINGS = 'SET_BANNER_SETTINGS';

export const CONTRACT_VERTICAL_PADDING_IN_PIXELS = 70;
export const CONTRACT_WRAPPER_BACKGROUND_COLOUR = 'rgb(247, 247, 247)';
export const TRANSPARENT_BACKGROUND = 'rgba(0, 0, 0, 0)';

export interface IHeatmapPaneState {
    bannerParagraphIds: string[];
    highlightBeginParagraphIndex: number | null;
    width: number;
}

export interface IHeatmapPaneStateProps {
    highlightedParagraphs: IContractParagraph[];
    bannerSettings: IBannerSettings | null;
    matchIndex: number;
    matchesCount: number;
    overviewPaneWidth: number;
    selectedContractIntelligence: ISelectedContractIntelligence;
    paragraphs: IContractParagraph[];
    searchTerm: string;
    selectedParagraph: IContractParagraph | null;
    selectedPropertyCode: string | undefined;
    summaryProperties: ISummaryProperty[];
    sidebarWidth: number;
    width: number;
}

export interface IBannerSettings {
    type: BannerType;
    clauseType: BannerClauseType;
    items?: string[];
    currentItem?: string;
}

export enum BannerType {
    INFO = 'info',
    WARNING = 'warning',
    REPROCESS_REQUIRED = 'reprocess_required',
    RISK_LEVEL = 'risk_level',
    FAMILIARITY_LEVEL = 'familiarity_level',
}

export enum BannerClauseType {
    PROPERTY_LINKED = 'property_linked',
    PROPERTY_RELATED = 'property_related',
}

export interface IHeatmapPaneReducer {
    contractScrollHeightInPixels: number;
    contractVisibleHeightInPixels: number;
    paragraphHeightsInPixels: number[];
    bannerSettings: IBannerSettings | null;
}

export interface IHeatmapPaneDispatchProps {
    setContractScrollHeightInPixels: (heightPixels: number) => void;
    setContractVisibleHeightInPixels: (heightPixels: number) => void;
    setHighlightIndex: (
        index: number,
        highlightType: HighlightIndexType
    ) => void;
    setParagraphHeightsInPixels: (heights: number[]) => void;
    setScrollTop: (scrollTop: number) => void;
    setSelectedContractIntelligence: (
        intelligence: ISelectedContractIntelligence
    ) => void;
    setSelectedParagraph: typeof setSelectedParagraphAction;
    setSelectedPropertyCode: (code: string) => void;
}

export interface IAddParagraphHeightInPixelsAction {
    type: typeof ADD_PARAGRAPH_HEIGHT_IN_PIXELS;
    paragraphHeightInPixels: number;
}

export interface ISetParagraphHeightsInPixelsAction {
    type: typeof SET_PARAGRAPH_HEIGHTS_IN_PIXELS;
    paragraphHeightsInPixels: number[];
}

export interface ISetContractScrollHeightInPixelsAction {
    type: typeof SET_CONTRACT_SCROLL_HEIGHT_IN_PIXELS;
    contractScrollHeightInPixels: number;
}

export interface ISetContractVisibleHeightInPixelsAction {
    type: typeof SET_CONTRACT_VISIBLE_HEIGHT_IN_PIXELS;
    contractVisibleHeightInPixels: number;
}

export interface ISetBannerSettingsAction {
    type: typeof SET_BANNER_SETTINGS;
    settings: IBannerSettings;
}

export interface IHeatmapPaneProps
    extends IHeatmapPaneDispatchProps,
        IHeatmapPaneStateProps,
        IContractPropsHistoryProps {
    onReprocess: () => void;
}

export type IHeatmapPaneActionTypes =
    | IAddParagraphHeightInPixelsAction
    | ISetContractScrollHeightInPixelsAction
    | ISetContractVisibleHeightInPixelsAction
    | ISetBannerSettingsAction
    | ISetParagraphHeightsInPixelsAction;
