import {
    ContractIssue,
    IssueSeverityLevel,
} from '@thought-river/negotiations-common';
import DocumentParser from '../../services/documentParser';
import { escapeRegex, sortSeverityLevel } from '../App/App-helpers';
import { IIssueFiltersValues } from '../IssueListFilters/IssueListFilters-types';
import { IContractParagraph } from './Paragraph-types';

export function tagTerm(
    text: string,
    term: string,
    classes: string = 'search-result'
): string {
    const regex = new RegExp(escapeRegex(term), 'gi');
    let match;
    let startIndex;
    let endIndex;
    const matchRanges: any[] = [];

    while ((match = regex.exec(text))) {
        startIndex = match.index;
        endIndex = match.index + term.length;
        matchRanges.push({ startIndex, endIndex });
    }

    for (const range of [...matchRanges].reverse()) {
        text = DocumentParser.replaceRange(
            text,
            range.endIndex,
            range.endIndex,
            '</span>'
        );
        text = DocumentParser.replaceRange(
            text,
            range.startIndex,
            range.startIndex,
            `<span class="${classes}">`
        );
    }

    return text;
}

export function getFilteredIssuesForParagraph(
    filters: IIssueFiltersValues,
    paragraph: IContractParagraph
): { filtered: ContractIssue[]; filteredByThemeOnly: ContractIssue[] } {
    const {
        categories = [],
        familiarity = [],
        severity = [],
        status = [],
        themes = [],
    } = filters;

    return {
        filtered: paragraph.issues.filter(
            (i) =>
                (!familiarity.length ||
                    familiarity.includes(
                        i.organizationFamiliarity!.frequencyLevel // Fixme: null checks
                    )) &&
                (!severity.length || severity.includes(i.severityLevel)) &&
                (!status.length || status.includes(i.status)) &&
                (!themes.length ||
                    themes.some((t) =>
                        i.nonAssessedThemeIds.includes(t.nonAssessedId)
                    )) &&
                (!categories.length ||
                    categories.some((filterCategory) =>
                        i.categories.find(
                            (globalCategory) =>
                                globalCategory.id === filterCategory.id
                        )
                    ))
        ),
        filteredByThemeOnly: paragraph.issues.filter((i) =>
            themes.some((t) => i.nonAssessedThemeIds.includes(t.nonAssessedId))
        ),
    };
}

export function getHighestSeverityFromIssues(
    issues: ContractIssue[]
): IssueSeverityLevel {
    return (
        issues.map((issue) => issue.severityLevel).sort(sortSeverityLevel)[0] ||
        IssueSeverityLevel.UNSCORED
    );
}
