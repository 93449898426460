import {
    GridCellParams,
    GridFilterInputValueProps,
    GridFilterItem,
    GridFilterOperator,
    getGridSingleSelectOperators,
} from '@mui/x-data-grid-premium';
import { ContractsReportColumn } from '@thought-river/negotiations-common/dist/api/contractContent';
import { Select, SelectOption } from '@thought-river/ui-components';
import { PARTIES_STRING_SEPERATOR } from '@modules/reports/portfolioReports';
import { getDefaultFilterOperators } from '@modules/reports/common';

export const getFilterOperators = (column: ContractsReportColumn) =>
    column.field === 'own_party' || column.field === 'counter_party'
        ? [getCustomPartiesOperator(column), ...getGridSingleSelectOperators()]
        : getDefaultFilterOperators(column.type);

const getCustomPartiesOperator = (
    column: ContractsReportColumn
): GridFilterOperator => ({
    label: 'contains',
    value: 'contains',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
            return null;
        }

        return (params: GridCellParams): boolean =>
            (params.value as string)
                .split(PARTIES_STRING_SEPERATOR)
                .includes(filterItem.value);
    },
    InputComponent: ({ item, applyValue }: GridFilterInputValueProps) => {
        const options: SelectOption[] =
            column.valueOptions?.map((o) => ({
                value: o,
                label: o,
            })) ?? [];
        //Nothing's been selected option
        options.unshift({
            value: '',
            label: '',
        });

        return (
            <Select
                options={options}
                onChange={(option: SelectOption) =>
                    applyValue({
                        ...item,
                        value: option.value,
                    })
                }
                value={item.value || options[0]}
            />
        );
    },
    InputComponentProps: { type: 'singleSelect' },
});
