import { SelectOption } from '@thought-river/ui-components';
import { IRootState } from '../../redux/reducers-types';
import { reduxStore } from '../../redux/store';
import { analyticsService } from '../../services/Analytics/Analytics';
import {
    EVENT_ACTION_ISSUE_STATUS_FILTER_SELECTED,
    EVENT_ACTION_ISSUE_STATUS_FILTER_REMOVED,
    EVENT_ACTION_ISSUE_SEVERITY_FILTER_SELECTED,
    EVENT_ACTION_ISSUE_SEVERITY_FILTER_REMOVED,
    EVENT_ACTION_ISSUE_CATEGORY_FILTER_SELECTED,
    EVENT_ACTION_ISSUE_CATEGORY_FILTER_REMOVED,
    EVENT_ACTION_ISSUE_PRESENT_DISPLAY_FILTER_SELECTED,
    EVENT_ACTION_ISSUE_NOT_DETECTED_DISPLAY_FILTER_SELECTED,
    EVENT_ACTION_ISSUE_PRESENT_DISPLAY_FILTER_REMOVED,
    EVENT_ACTION_ISSUE_NOT_DETECTED_DISPLAY_FILTER_REMOVED,
    EVENT_ACTION_FA_FILTER_SELECTED,
    EVENT_ACTION_FA_FILTER_REMOVED,
    EVENT_ACTION_FA_SORT_IN_ISSUE_PANE,
    EVENT_ACTION_THEME_FILTER_REMOVED,
    EVENT_ACTION_THEME_FILTER_SELECTED,
} from '../../services/Analytics/Analytics-types';
import { IssueDisplay } from '../IssueListPane/IssueListPane-types';

const isOptionRemoved = (options: any, selectedOption: SelectOption): boolean =>
    options.includes(selectedOption.value);

const getContractProperties = (state: IRootState) => ({
    ContractId: state.contract.contract?.id,
    VersionId: state.contract.selectedVersion?.id,
    VersionNumber: state.contract.selectedVersion?.versionNumber,
});

export function analytics() {
    return (target: any) => {
        const statusFilterSelected = target.prototype.statusFilterSelected;
        const severityFilterSelected = target.prototype.severityFilterSelected;
        const categoryFilterSelected = target.prototype.categoryFilterSelected;
        const displayFilterSelected = target.prototype.displayFilterSelected;
        const familiarityFilterSelected =
            target.prototype.familiarityFilterSelected;
        const themeFilterSelected = target.prototype.themeFilterSelected;
        const onChangeSortBy = target.prototype.onChangeSortBy;

        target.prototype.statusFilterSelected = function (
            selectedOption: SelectOption
        ) {
            const state = reduxStore.store.getState() as IRootState;
            const {
                contract: { contract },
            } = state;

            const optionRemoved = isOptionRemoved(
                this.props.activeIssueFilters['status'],
                selectedOption
            );

            analyticsService.recordEvent(
                optionRemoved
                    ? EVENT_ACTION_ISSUE_STATUS_FILTER_REMOVED
                    : EVENT_ACTION_ISSUE_STATUS_FILTER_SELECTED,

                {
                    Stream: contract?.streamCode,
                    ...getContractProperties(state),
                }
            );

            statusFilterSelected.apply(this, arguments);
        };

        target.prototype.severityFilterSelected = function (
            selectedOption: SelectOption
        ) {
            const state = reduxStore.store.getState() as IRootState;
            const {
                contract: { contract },
            } = state;

            const optionRemoved = isOptionRemoved(
                this.props.activeIssueFilters['severity'],
                selectedOption
            );

            analyticsService.recordEvent(
                optionRemoved
                    ? EVENT_ACTION_ISSUE_SEVERITY_FILTER_REMOVED
                    : EVENT_ACTION_ISSUE_SEVERITY_FILTER_SELECTED,

                {
                    Stream: contract?.streamCode,
                    ...getContractProperties(state),
                }
            );

            severityFilterSelected.apply(this, arguments);
        };

        target.prototype.categoryFilterSelected = function (
            selectedOption: SelectOption
        ) {
            const state = reduxStore.store.getState() as IRootState;
            const {
                contract: { contract },
            } = state;

            const categoryValues = this.props.activeIssueFilters[
                'categories'
            ].map((option: any) => option.id);
            const optionRemoved = isOptionRemoved(
                categoryValues,
                selectedOption
            );

            analyticsService.recordEvent(
                optionRemoved
                    ? EVENT_ACTION_ISSUE_CATEGORY_FILTER_REMOVED
                    : EVENT_ACTION_ISSUE_CATEGORY_FILTER_SELECTED,

                {
                    Stream: contract?.streamCode,
                    ...getContractProperties(state),
                }
            );

            categoryFilterSelected.apply(this, arguments);
        };

        target.prototype.displayFilterSelected = function (
            selectedOption: SelectOption
        ) {
            const state = reduxStore.store.getState() as IRootState;
            const {
                contract: { contract },
            } = state;

            const optionRemoved = isOptionRemoved(
                this.props.activeIssueFilters['displays'],
                selectedOption
            );

            let action;
            if (optionRemoved) {
                action =
                    selectedOption.value === IssueDisplay.PRESENT
                        ? EVENT_ACTION_ISSUE_PRESENT_DISPLAY_FILTER_REMOVED
                        : EVENT_ACTION_ISSUE_NOT_DETECTED_DISPLAY_FILTER_REMOVED;
            } else {
                action =
                    selectedOption.value === IssueDisplay.PRESENT
                        ? EVENT_ACTION_ISSUE_PRESENT_DISPLAY_FILTER_SELECTED
                        : EVENT_ACTION_ISSUE_NOT_DETECTED_DISPLAY_FILTER_SELECTED;
            }

            analyticsService.recordEvent(action, {
                Stream: contract?.streamCode,
                ...getContractProperties(state),
            });

            displayFilterSelected.apply(this, arguments);
        };

        target.prototype.familiarityFilterSelected = function (
            option: SelectOption
        ) {
            const state = reduxStore.store.getState() as IRootState;

            const action = isOptionRemoved(
                this.props.activeIssueFilters['familiarity'],
                option
            )
                ? EVENT_ACTION_FA_FILTER_REMOVED
                : EVENT_ACTION_FA_FILTER_SELECTED;

            analyticsService.recordEvent(
                action,

                getContractProperties(state)
            );

            familiarityFilterSelected.apply(this, arguments);
        };

        target.prototype.themeFilterSelected = function (option: SelectOption) {
            const state = reduxStore.store.getState() as IRootState;

            const themeValues = this.props.activeIssueFilters['themes'].map(
                (option: any) => option.id
            );

            const action = isOptionRemoved(themeValues, option)
                ? EVENT_ACTION_THEME_FILTER_REMOVED
                : EVENT_ACTION_THEME_FILTER_SELECTED;

            analyticsService.recordEvent(action, {
                ...getContractProperties(state),
                Theme: option.label,
            });

            themeFilterSelected.apply(this, arguments);
        };

        target.prototype.onChangeSortBy = function (option: SelectOption) {
            const state = reduxStore.store.getState() as IRootState;
            if (option.value === 'familiarity') {
                analyticsService.recordEvent(
                    EVENT_ACTION_FA_SORT_IN_ISSUE_PANE,

                    getContractProperties(state)
                );
            }
            onChangeSortBy.apply(this, arguments);
        };
    };
}
