import {
    IContractVersion,
    IDisplayContract,
} from '../../Contract/Contract-types';
import { PartyVariantType } from '../../Parties/Parties-types';

export const partySortOrder = {
    [PartyVariantType.FORMAL]: 1,
    [PartyVariantType.REFERENCE]: 2,
};
export interface ISummaryPartiesState {}

export interface ISummaryPartiesProps {
    contract: IDisplayContract;
    selectedVersion: IContractVersion;
}
