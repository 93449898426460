import { IParty, PartiesType } from '../Parties/Parties-types';
import {
    SET_SELECTED_PARTY,
    ISetSelectedPartyAction,
    IUpdatePartyAction,
    UPDATE_PARTY,
    IAddPartyAction,
    ADD_PARTY,
    IRemovePartyAction,
    REMOVE_PARTY,
} from './Party-types';

export function setSelectedPartyAction(
    selectedParty: IParty
): ISetSelectedPartyAction {
    return {
        type: SET_SELECTED_PARTY,
        selectedParty,
    };
}

export function updatePartyAction(party: IParty): IUpdatePartyAction {
    return {
        type: UPDATE_PARTY,
        party,
    };
}

export function addPartyAction(
    partyToAdd: string,
    partyType: PartiesType
): IAddPartyAction {
    return {
        type: ADD_PARTY,
        partyToAdd,
        partyType,
    };
}

export function removePartyAction(party: IParty): IRemovePartyAction {
    return {
        type: REMOVE_PARTY,
        party,
    };
}
