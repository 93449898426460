import { AuthContext } from '@modules/auth/context/AuthProvider';
import { Dispatch } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { IRootState } from '../../redux/reducers-types';
import { setLogoutAction } from '../Auth/Auth-actions';
import { withContext } from '../withContext';
import ProfileMenu from './ProfileMenu';

import {
    IProfileMenuDispatchProps,
    IProfileMenuStateProps,
} from './ProfileMenu-types';

function mapStateToProps(state: IRootState): IProfileMenuStateProps {
    return {
        accountCode: state.auth.accountCode,
        userId: state.auth.userId,
        users: state.auth.users,
        tenants: state.auth.tenants,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IProfileMenuDispatchProps {
    return {
        setLogout: (logout: boolean) => dispatch(setLogoutAction(logout)),
    };
}

export default withContext(AuthContext)(
    connect(mapStateToProps, mapDispatchToProps)(ProfileMenu)
);
