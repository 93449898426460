import { Component, CSSProperties } from 'react';
import { Tooltip } from '@thought-river/ui-components';
import { FEATURE_TOGGLE_USER_AVATAR_GRADIENT } from '../FeatureToggleProvider/FeatureToggleProvider-types';

import { IUserIconProps } from './UserIcon-types';

class UserIcon extends Component<IUserIconProps> {
    labelToInitials(text: string) {
        let parts = text.split(' ');

        if (parts.length > 2) {
            parts = [parts[0], parts[parts.length - 1]];
        }

        return parts.map((name) => name.charAt(0).toUpperCase());
    }

    private filterGradient(gradient: string[]) {
        const hexCodeRegex = new RegExp('^#(?:[0-9a-f]{3}){1,2}$', 'i');

        return (
            Array.isArray(gradient) &&
            gradient.length >= 2 &&
            gradient.every((hexCode) => hexCodeRegex.test(hexCode))
        );
    }

    private generateGradientStyles(
        gradient: string[],
        angle: number = 130
    ): CSSProperties {
        const gradienceAngle =
            !isNaN(angle) && angle >= 0 && angle <= 360 ? angle : 130;
        const gradation = 100 / (gradient.length - 1);
        const gradients = [];
        let colorStep = 0;

        for (const color of gradient) {
            gradients.push(`${color} ${colorStep}%`);
            colorStep += gradation;
        }

        return {
            background: `linear-gradient(${gradienceAngle}deg, ${gradients.join(
                ', '
            )})`,
        };
    }

    render() {
        const {
            className,
            customStyles = {},
            customIconColor,
            featureToggles,
            label,
            muted,
            noInitials,
            showLabel,
            size,
            tooltip,
        } = this.props;

        const userIconGradients = featureToggles.find(
            (toggle) => toggle.feature === FEATURE_TOGGLE_USER_AVATAR_GRADIENT
        );

        let gradients = [];
        let gradienceAngle = 130;

        if (userIconGradients?.enabled) {
            // @ts-ignore
            gradients = userIconGradients.value?.gradients;
            // @ts-ignore
            gradienceAngle = userIconGradients.value?.angle;
        }

        const initials = this.labelToInitials(label);
        const colorClass =
            muted && !userIconGradients?.enabled
                ? 'muted'
                : `color-${(initials[0].charCodeAt(0) % 6) + 1}`;

        let iconStyles = {};

        if (!customIconColor) {
            const validGradients = Array.isArray(gradients)
                ? gradients.filter(this.filterGradient)
                : [];

            if (validGradients.length) {
                const gradientIndex =
                    initials[0].charCodeAt(0) % validGradients.length;

                if (validGradients[gradientIndex]) {
                    iconStyles = this.generateGradientStyles(
                        validGradients[gradientIndex],
                        gradienceAngle
                    );
                }
            }
        } else {
            iconStyles = { backgroundColor: customIconColor };
        }

        const userIcon = (
            <div
                className={`user-icon ${className ? className : ''}`}
                style={customStyles}
            >
                <div
                    className={`user-icon-initials ${
                        size ? size : ''
                    } ${colorClass}`}
                    style={iconStyles}
                >
                    {noInitials ? label : this.labelToInitials(label)}
                </div>
                {showLabel && <div className="user-icon-label">{label}</div>}
            </div>
        );

        return tooltip ? (
            <Tooltip title={tooltip}>{userIcon}</Tooltip>
        ) : (
            userIcon
        );
    }
}

export default UserIcon;
