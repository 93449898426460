import { Component } from 'react';

import {
    IUploadProgressBarProps,
    IUploadProgressBarState,
    uploadStateRatios,
} from './UploadProgressBar-types';

class UploadProgressBar extends Component<
    IUploadProgressBarProps,
    IUploadProgressBarState
> {
    render() {
        const { state, percentageProgress } = this.props;

        const ratio = percentageProgress
            ? `${percentageProgress}%`
            : `${(uploadStateRatios[state] ?? 0) * 100}%`;

        return (
            <div className="upload-progress-bar">
                <div
                    className="upload-progress-bar-indicator"
                    style={{ width: ratio }}
                />
            </div>
        );
    }
}

export default UploadProgressBar;
