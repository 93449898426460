import { SET_CONTRACT_VERSIONS } from '../ContractList/ContractList-types';
import { IContractParagraph } from '../Paragraph/Paragraph-types';
import { paragraphIsResolved } from './Contract-helpers';
import {
    HighlightIndexType,
    IContractActionTypes,
    IContractReducer,
    IDisplayContract,
    SET_ASSESSMENT,
    SET_CONTRACT,
    SET_FORMATS_ACTION,
    SET_HIGHLIGHTED_PARAGRAPHS,
    SET_HIGHLIGHT_INDEX,
    SET_ISSUES,
    SET_LATEST_VERSION,
    SET_PROPERTY_TEMPLATES_LOADED,
    SET_LOADED,
    SET_NEW_VERSION_PROCESSED,
    SET_PARAGRAPHS,
    SET_POLICIES,
    SET_PROCESSING_VERSION_ID,
    SET_PROPERTIES,
    SET_PROPERTY_TEMPLATES,
    SET_REDLINED_TEXT,
    SET_RELOAD_CONTRACT_ACTION,
    SET_REVIEWED_PARAGRAPHS,
    SET_SCROLL_TOP,
    SET_SELECTED_ISSUE,
    SET_SELECTED_PARAGRAPH,
    SET_SELECTED_PROPERTIES_TEMPLATE,
    SET_SELECTED_PROPERTY_CODE,
    SET_SELECTED_VERSION,
    SET_SUMMARY_PROPERTIES,
    SET_THEMES,
    SET_TOTAL_RESOLVED_ISSUES,
    SET_TOTAL_SIGNIFICANT_ISSUES,
    SET_UNSCORED_THEMES,
    SET_VERSIONS,
    UPDATE_CONTRACT,
    UPDATE_CONTRACT_DETAILS,
    UPDATE_CONTRACT_PROPERTY,
    UPDATE_ISSUE,
    UPDATE_SUMMARY_PROPERTY,
    CLEAR_CONTRACT,
    ContractIntelligenceType,
    SET_SELECTED_CONTRACT_INTELLIGENCE,
    SET_HIGHLIGHTED_TEXT,
} from './Contract-types';

export const contractInitialState: IContractReducer = {
    id: null!, // Fixme: null checks
    assessment: null!, // Fixme: null checks
    contract: null!, // Fixme: null checks
    formats: [],
    highlightedText: '',
    highlightEndIndex: -1,
    highlightStartIndex: -1,
    highlightedParagraphs: [],
    issues: [],
    propertyTemplatesLoaded: false,
    loaded: false,
    newVersionProcessed: false,
    paragraphs: [],
    policies: [],
    processingVersionId: null!, // Fixme: null checks
    properties: [],
    propertyTemplates: [],
    redlinedText: '',
    reload: false,
    scrollTop: 0,
    selectedContractIntelligence: {
        type: ContractIntelligenceType.RISK,
        highlightType: null!, // Fixme: null checks
    },
    selectedIssue: null!, // Fixme: null checks
    selectedParagraph: null,
    selectedPropertiesTemplate: null!, // Fixme: null checks
    selectedPropertyCode: null!, // Fixme: null checks
    selectedVersion: null!, // Fixme: null checks
    summaryProperties: [],
    themes: [],
    unscoredThemes: [],
    versions: [],
};

const ContractReducer = (
    state = contractInitialState,
    action: IContractActionTypes
): IContractReducer => {
    let contract: IDisplayContract;
    let highlightedParagraphIndices: number[];
    let index: number;
    let paragraph: IContractParagraph;
    let paragraphs: IContractParagraph[];
    let reviewed: boolean;

    switch (action.type) {
        case SET_ASSESSMENT:
            return {
                ...state,
                assessment: action.assessment,
            };
        case SET_CONTRACT:
            return {
                ...state,
                contract: action.contract,
                id: action.contract?.id,
            };
        case CLEAR_CONTRACT:
            return {
                ...contractInitialState,
            };
        case SET_CONTRACT_VERSIONS:
            if (state.contract && state.contract.id === action.contractId) {
                return {
                    ...state,
                    contract: {
                        ...state.contract,
                        versions: action.versions,
                    },
                };
            }

            return {
                ...state,
            };
        case SET_FORMATS_ACTION:
            return {
                ...state,
                formats: action.formats,
            };
        case SET_HIGHLIGHTED_PARAGRAPHS:
            return {
                ...state,
                highlightedParagraphs: action.highlightedParagraphs,
            };
        case SET_HIGHLIGHTED_TEXT:
            return {
                ...state,
                highlightedText: action.text,
            };
        case SET_HIGHLIGHT_INDEX:
            const highlightedParagraphs: IContractParagraph[] = [];

            if (
                action.index !== -1 &&
                action.highlightType === HighlightIndexType.END
            ) {
                for (paragraph of state.paragraphs) {
                    if (
                        paragraph.index >= state.highlightStartIndex &&
                        paragraph.index <= action.index
                    ) {
                        highlightedParagraphs.push(paragraph);
                    }
                }
            }

            return {
                ...state,
                [`highlight${action.highlightType}Index`]: action.index,
                highlightedParagraphs,
            };
        case SET_ISSUES:
            paragraphs = [...state.paragraphs];

            for (const [index, paragraph] of state.paragraphs.entries()) {
                const paragraphIssues = action.issues.filter((issue) =>
                    issue.paragraphOriginUuids.includes(paragraph.originUuid)
                );
                paragraphs[index] = {
                    ...paragraphs[index],
                    issues: paragraphIssues,
                    resolved: paragraphIsResolved(paragraphIssues),
                };
            }

            return {
                ...state,
                paragraphs,
                issues: action.issues,
            };
        case UPDATE_ISSUE:
            const issues = [...state.issues].map((issue) => {
                if (issue.id === action.issue.id) {
                    issue = action.issue;
                }
                return issue;
            });
            return {
                ...state,
                issues,
            };
        case SET_LATEST_VERSION:
            contract = { ...state.contract };

            return {
                ...state,
                contract: contract
                    ? { ...contract, latestVersion: action.latestVersion }
                    : null!, // Fixme: null checks
            };
        case SET_PROPERTY_TEMPLATES_LOADED:
            return {
                ...state,
                propertyTemplatesLoaded: action.propertyTemplatesLoaded,
            };
        case SET_LOADED:
            return {
                ...state,
                loaded: action.loaded,
            };
        case SET_PARAGRAPHS:
            return {
                ...state,
                paragraphs: action.paragraphs,
            };
        case SET_POLICIES:
            return {
                ...state,
                policies: action.policies,
            };
        case SET_PROCESSING_VERSION_ID:
            return {
                ...state,
                processingVersionId: action.processingVersionId,
            };
        case SET_PROPERTIES:
            return {
                ...state,
                properties: action.properties,
            };
        case SET_PROPERTY_TEMPLATES:
            return {
                ...state,
                propertyTemplates: action.propertyTemplates,
            };
        case SET_REDLINED_TEXT:
            return {
                ...state,
                redlinedText: action.redlinedText,
            };
        case SET_RELOAD_CONTRACT_ACTION:
            return {
                ...state,
                reload: action.reload,
            };
        case SET_SELECTED_PROPERTIES_TEMPLATE:
            return {
                ...state,
                selectedPropertiesTemplate: action.selectedPropertiesTemplate,
            };
        case SET_SELECTED_PROPERTY_CODE:
            return {
                ...state,
                selectedPropertyCode: action.selectedPropertyCode!, // Fixme: null checks
            };
        case SET_SUMMARY_PROPERTIES:
            return {
                ...state,
                summaryProperties: action.summaryProperties,
            };
        case SET_REVIEWED_PARAGRAPHS:
            paragraphs = [...state.paragraphs];
            reviewed = action.reviewed;

            highlightedParagraphIndices = state.highlightedParagraphs.map(
                (paragraph) => paragraph.index
            );

            for (index of action.paragraphIndices ??
                highlightedParagraphIndices) {
                paragraphs[index] = { ...paragraphs[index], reviewed };
            }

            return {
                ...state,
                paragraphs,
            };
        case SET_SCROLL_TOP:
            return {
                ...state,
                scrollTop: action.scrollTop,
            };
        case SET_SELECTED_CONTRACT_INTELLIGENCE:
            return {
                ...state,
                selectedContractIntelligence: action.intelligence,
            };
        case SET_SELECTED_ISSUE:
            return {
                ...state,
                selectedIssue: action.selectedIssue,
            };
        case SET_SELECTED_PARAGRAPH:
            return {
                ...state,
                selectedParagraph: action.selectedParagraph,
            };
        case SET_SELECTED_VERSION:
            return {
                ...state,
                selectedVersion: action.selectedVersion,
            };
        case SET_THEMES:
            return {
                ...state,
                themes: action.themes,
            };
        case SET_TOTAL_RESOLVED_ISSUES:
            contract = { ...state.contract };

            return {
                ...state,
                contract: contract
                    ? { ...contract, totalClosedIssues: action.total }
                    : null!, // Fixme: null checks
            };
        case SET_TOTAL_SIGNIFICANT_ISSUES:
            contract = { ...state.contract };

            return {
                ...state,
                contract: contract
                    ? { ...contract, totalIssues: action.total }
                    : null!, // Fixme: null checks
            };
        case SET_UNSCORED_THEMES:
            return {
                ...state,
                unscoredThemes: action.unscoredThemes,
            };
        case SET_VERSIONS:
            return {
                ...state,
                versions: action.versions,
            };
        case UPDATE_CONTRACT:
            return {
                ...state,
                contract:
                    state.contract?.id === action.contractId
                        ? { ...state.contract, ...action.fields }
                        : state.contract,
            };
        case UPDATE_CONTRACT_DETAILS:
            contract = { ...state.contract };

            return {
                ...state,
                contract: contract
                    ? {
                          ...contract,
                          contractName: action.contractName
                              ? action.contractName
                              : contract.contractName,
                          reviewerIds: action.reviewerIds
                              ? action.reviewerIds
                              : contract.reviewerIds,
                          negotiatorId: action.negotiatorId
                              ? action.negotiatorId
                              : contract.negotiatorId,
                      }
                    : null!, // Fixme: null checks
            };
        case UPDATE_CONTRACT_PROPERTY:
            const updatedContractProperties = state.properties.map((property) =>
                property.code === action.property.code
                    ? { ...action.property }
                    : property
            );

            return {
                ...state,
                properties: updatedContractProperties,
            };
        case UPDATE_SUMMARY_PROPERTY:
            const updatedSummaryProperties = state.summaryProperties.map(
                (property) =>
                    property.code === action.property.code
                        ? { ...action.property }
                        : property
            );

            return {
                ...state,
                summaryProperties: updatedSummaryProperties,
            };
        case SET_NEW_VERSION_PROCESSED:
            return {
                ...state,
                newVersionProcessed: action.newVersionProcessed,
            };
        default:
            return state;
    }
};

export default ContractReducer;
