import { Component } from 'react';

import {
    FiltersContext,
    IIssueListFiltersProps,
    IIssueListFiltersState,
    IssuesSortOption,
} from './IssueListFilters-types';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
    IIssueCategory,
    IssueDisplay,
} from '../IssueListPane/IssueListPane-types';
import { sortSeverityLevel, splitByCapitals } from '../App/App-helpers';
import { analytics } from './IssueListFilters-analytics';
import { ILexibleProperty } from '../App/App-types';
import {
    Drawer,
    Icon,
    Select,
    SelectOption,
} from '@thought-river/ui-components';
import {
    IssueSeverityLevel,
    IssueStatus,
    getIssueSeverityLevelIconType,
    getIssueStatusIconType,
    getIssueStatusLabel,
    capitalize,
    FamiliarityLevel,
    getFamiliarityLevelLabel,
    getFamiliarityLevelIconType,
    LexibleTheme,
} from '@thought-river/negotiations-common';

function mapStatusToMultiSelectOption(value: IssueStatus) {
    return {
        value,
        label: getIssueStatusLabel(value),
        icon: <Icon type={getIssueStatusIconType(value)} />,
    };
}

function mapSeverityToMultiSelectOption(value: IssueSeverityLevel) {
    return {
        value,
        label: capitalize(
            value === IssueSeverityLevel.UNSCORED ? 'None' : value
        ),
        icon: <Icon type={getIssueSeverityLevelIconType(value)} />,
    };
}

const mapFamiliarityToOption = (value: FamiliarityLevel) => ({
    value: value,
    label: getFamiliarityLevelLabel(value),
    icon: <Icon type={getFamiliarityLevelIconType(value)} />,
});

function mapDisplaysToMultiSelectOption(value: IssueDisplay) {
    return {
        value,
        label: value,
    };
}

function simpleMapToSelectValues<
    T extends { [key in K]: string },
    K extends keyof T
>(mappee: T, labelField: K, valueField: K): SelectOption {
    return {
        value: mappee[valueField],
        label: capitalize(mappee[labelField]),
    };
}

const mapThemeValuesIntoMultiSelectValues = (theme: LexibleTheme) =>
    simpleMapToSelectValues(theme, 'name', 'nonAssessedId');

const mapCategoryValuesIntoMultiSelectValues = (category: IIssueCategory) =>
    simpleMapToSelectValues(category, 'name', 'id');

const severityOptions = [
    IssueSeverityLevel.SHOWSTOPPER,
    IssueSeverityLevel.HIGH,
    IssueSeverityLevel.MEDIUM,
    IssueSeverityLevel.LOW,
    IssueSeverityLevel.OK,
    IssueSeverityLevel.UNSCORED,
].map(mapSeverityToMultiSelectOption);

const statusOptions = [
    IssueStatus.OPEN,
    IssueStatus.IGNORED,
    IssueStatus.CLOSED,
].map(mapStatusToMultiSelectOption);

const displayOptions = [IssueDisplay.PRESENT, IssueDisplay.NOT_DETECTED].map(
    mapDisplaysToMultiSelectOption
);

const familiarityOptions = [
    FamiliarityLevel.TEMPLATE,
    FamiliarityLevel.COMMON,
    FamiliarityLevel.UNCOMMON,
    FamiliarityLevel.UNFAMILIAR,
].map(mapFamiliarityToOption);

@analytics()
export default class IssueListFilters extends Component<
    IIssueListFiltersProps,
    IIssueListFiltersState
> {
    private readonly categoryOptions: SelectOption[];
    private readonly themeOptions: SelectOption[];

    constructor(props: IIssueListFiltersProps) {
        super(props);

        this.categoryFilterSelected = this.categoryFilterSelected.bind(this);
        this.categoryOptions = this.props.categories.map(
            mapCategoryValuesIntoMultiSelectValues
        );

        this.themeOptions = this.filterThemesWithProperties().map(
            mapThemeValuesIntoMultiSelectValues
        );
        this.displayFilterSelected = this.displayFilterSelected.bind(this);
        this.familiarityFilterSelected =
            this.familiarityFilterSelected.bind(this);
        this.onChangeSortBy = this.onChangeSortBy.bind(this);
        this.severityFilterSelected = this.severityFilterSelected.bind(this);
        this.state = this.buildStateObject();
        this.statusFilterSelected = this.statusFilterSelected.bind(this);
        this.themeFilterSelected = this.themeFilterSelected.bind(this);
    }

    componentDidUpdate(prevProps: IIssueListFiltersProps) {
        if (
            JSON.stringify(prevProps.activeIssueFilters) !==
            JSON.stringify(this.props.activeIssueFilters)
        ) {
            this.setState(this.buildStateObject());
        }
    }

    buildStateObject(): IIssueListFiltersState {
        const {
            activeIssueFilters: {
                status = [],
                severity = [],
                themes = [],
                categories = [],
                displays = [],
                familiarity = [],
            },
        } = this.props;

        return {
            selectedCategoryValues: categories.map(
                mapCategoryValuesIntoMultiSelectValues
            ),
            selectedDisplayValues: displays.map(mapDisplaysToMultiSelectOption),
            selectedFamiliarityValues: familiarity.map(mapFamiliarityToOption),
            selectedSeverityValues: severity
                .sort(sortSeverityLevel)
                .map(mapSeverityToMultiSelectOption),
            selectedStatusValues: status.map(mapStatusToMultiSelectOption),
            selectedThemeValues: themes.map(
                mapThemeValuesIntoMultiSelectValues
            ),
        };
    }

    filterThemesWithProperties(): LexibleTheme[] {
        const { properties, themes } = this.props;

        const groupedProperties = {};
        for (const property of properties) {
            if (groupedProperties[property.themeId]) {
                groupedProperties[property.themeId].push(property);
            } else {
                groupedProperties[property.themeId] = [property];
            }
        }

        const themesWithProperties = [];
        for (const theme of themes) {
            if (groupedProperties[theme.nonAssessedId]) {
                themesWithProperties.push(theme);
            }
        }

        return themesWithProperties;
    }

    groupPropertiesByTheme(properties: ILexibleProperty[]): {
        [themeId: string]: ILexibleProperty[];
    } {
        const groupedProperties = {};

        for (const property of properties) {
            if (groupedProperties[property.themeId]) {
                groupedProperties[property.themeId].push(property);
            } else {
                groupedProperties[property.themeId] = [property];
            }
        }

        return groupedProperties;
    }

    severityFilterSelected(options: SelectOption[]) {
        const { activeIssueFilters } = this.props;

        this.props.updateActiveIssueFilters({
            ...activeIssueFilters,
            severity: options.map((o) => o.value) as IssueSeverityLevel[],
        });
    }

    statusFilterSelected(options: SelectOption[]) {
        const { activeIssueFilters } = this.props;

        this.props.updateActiveIssueFilters({
            ...activeIssueFilters,
            status: options.map((o) => o.value) as IssueStatus[],
        });
    }

    categoryFilterSelected(options: SelectOption[]) {
        const { activeIssueFilters, categories } = this.props;

        this.props.updateActiveIssueFilters({
            ...activeIssueFilters,
            categories: categories.filter((c) =>
                options.map((o) => o.value).includes(c.id)
            ),
        });
    }

    displayFilterSelected(options: SelectOption[]) {
        const { activeIssueFilters } = this.props;

        this.props.updateActiveIssueFilters({
            ...activeIssueFilters,
            displays: options.map((o) => o.value) as IssueDisplay[],
        });
    }

    familiarityFilterSelected(options: SelectOption[]) {
        const { activeIssueFilters } = this.props;

        this.props.updateActiveIssueFilters({
            ...activeIssueFilters,
            familiarity: options.map((o) => o.value) as FamiliarityLevel[],
        });
    }

    themeFilterSelected(options: SelectOption[]) {
        const { activeIssueFilters, themes } = this.props;

        this.props.updateActiveIssueFilters({
            ...activeIssueFilters,
            themes: themes.filter((t) =>
                options.map((o) => o.value).includes(t.id)
            ),
        });
    }

    formatSortByOption(sortByOption: IssuesSortOption): SelectOption {
        const option: SelectOption = {
            value: sortByOption,
            label: capitalize(sortByOption).replace(/_/g, ' '),
        };

        return option;
    }

    async onChangeSortBy(selectedOption: SelectOption) {
        this.props.setIssuesSortByOption(
            selectedOption.value as IssuesSortOption
        );
    }

    render() {
        const { context, sortBy, show, onClose, sidebarWidthInPixels } =
            this.props;

        const contextClassname = splitByCapitals(context, '-').toLowerCase();

        const sortByOptions = [
            IssuesSortOption.CLAUSE,
            IssuesSortOption.FAMILIARITY,
            IssuesSortOption.SEVERITY,
            IssuesSortOption.STATUS,
            IssuesSortOption.ISSUE_SUMMARY_TITLE,
        ].map(this.formatSortByOption);

        return (
            <Drawer
                open={show}
                width={sidebarWidthInPixels}
                onClose={onClose}
                title={`Filter ${
                    context === FiltersContext.IssuesList ? ' & Sort' : ''
                }`}
            >
                <div className={`issues-filters ${contextClassname}`}>
                    {context === FiltersContext.IssuesList && (
                        <Select
                            value={this.formatSortByOption(sortBy)}
                            options={sortByOptions}
                            onChange={this.onChangeSortBy}
                            label="Sort by"
                            className="filter-select"
                        />
                    )}
                    <Select
                        className="filter-select"
                        multiple
                        disableCloseOnSelect
                        label="Display Issues"
                        onChange={this.displayFilterSelected}
                        options={displayOptions}
                        value={this.state.selectedDisplayValues}
                    />

                    <Select
                        className="filter-select"
                        disableCloseOnSelect
                        label="Status"
                        multiple
                        limitTags={2}
                        onChange={this.statusFilterSelected}
                        options={statusOptions}
                        value={this.state.selectedStatusValues}
                    />

                    <Select
                        className="filter-select"
                        disableCloseOnSelect
                        label="Importance"
                        multiple
                        limitTags={2}
                        onChange={this.severityFilterSelected}
                        options={severityOptions}
                        value={this.state.selectedSeverityValues}
                    />

                    <Select
                        className="filter-select"
                        disableCloseOnSelect
                        label="Familiarity"
                        multiple
                        limitTags={2}
                        onChange={this.familiarityFilterSelected}
                        options={familiarityOptions}
                        value={this.state.selectedFamiliarityValues}
                    />

                    <Select
                        className="filter-select"
                        disableCloseOnSelect
                        label="Category"
                        multiple
                        limitTags={2}
                        onChange={this.categoryFilterSelected}
                        options={this.categoryOptions}
                        value={this.state.selectedCategoryValues}
                    />

                    <Select
                        className="filter-select"
                        disableCloseOnSelect
                        label="Themes"
                        multiple
                        limitTags={2}
                        onChange={this.themeFilterSelected}
                        options={this.themeOptions}
                        value={this.state.selectedThemeValues}
                    />
                </div>
            </Drawer>
        );
    }
}
