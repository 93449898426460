import { useContractSearchContext } from '@modules/common/context/ContractSearchProvider/ContractSearchProvider';
import {
    Banner,
    TextField,
    BannerNavigation,
} from '@thought-river/ui-components';
import styles from './ContractViewerSidebar.module.scss';
import { SearchBannerProps } from './types';

const SearchBanner = ({ onClose }: SearchBannerProps) => {
    const {
        searchTerm,
        onSearchTermChange,
        navigate,
        highlightedMatchIndex,
        totalMatches,
    } = useContractSearchContext();

    return (
        <div className={styles.searchBanner}>
            <Banner
                type="info"
                hideIcon
                dismissable
                onClose={() => {
                    onSearchTermChange('');
                    onClose();
                }}
                noPadding
                className={styles.searchBannerContainer}
            >
                <div className={styles.searchBannerContent}>
                    <div className={styles.searchInput}>
                        <TextField
                            size="small"
                            value={searchTerm}
                            onChange={(event) =>
                                onSearchTermChange(event.target.value)
                            }
                            placeholder="Search"
                        />
                    </div>

                    <BannerNavigation
                        currentIndex={highlightedMatchIndex}
                        totalItems={totalMatches}
                        onNavigation={navigate}
                    />
                </div>
            </Banner>
        </div>
    );
};

export default SearchBanner;
