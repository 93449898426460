import { CollapseList } from '@modules/common/components/CollapseList';
import { ColumnSelectionProps } from '@modules/reports/common';
import {
    useBetaReports2Enabled,
    useGroupSelectedColumns,
    useLexibleContext,
} from '@modules/reports/portfolioReports';
import uniq from 'lodash/uniq';
import { ContractMetadataColumnsList } from './ContractMetadataColumnList';
import { LexiblePropertiesTree } from '@thought-river/negotiations-common';

const ColumnSelection = ({
    selectedColumns,
    onSelectedColumnsChange,
    columnSearchTerm,
}: ColumnSelectionProps) => {
    const { contractMetadata, properties } =
        useGroupSelectedColumns(selectedColumns);

    const reportsBeta2Enabled = useBetaReports2Enabled();

    const { properties: lexibleProperties, themes } = useLexibleContext();

    return (
        <>
            <ContractMetadataColumnsList
                searchTerm={columnSearchTerm}
                selectedColumns={contractMetadata}
                onSelectedColumnsChange={(newColumns) =>
                    onSelectedColumnsChange(
                        uniq([...properties, ...newColumns])
                    )
                }
            />
            {reportsBeta2Enabled && (
                <CollapseList title="Lexible questions">
                    {lexibleProperties && themes && (
                        <LexiblePropertiesTree
                            onChange={(newProperties) =>
                                onSelectedColumnsChange(
                                    uniq([
                                        ...contractMetadata,
                                        ...newProperties,
                                    ])
                                )
                            }
                            selectedPropertyCodes={properties}
                            searchTerm={columnSearchTerm}
                            themes={themes}
                            properties={lexibleProperties}
                        />
                    )}
                </CollapseList>
            )}
        </>
    );
};

export default ColumnSelection;
