import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import {
    createTransform,
    persistStore,
    persistReducer,
    createMigrate,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducers';
import { appInitialState } from '../components/App/App-reducer';
import { IAppReducer } from '../components/App/App-types';
import { IContractReducer } from '../components/Contract/Contract-types';
import { IRootState } from './reducers-types';
import { contractInitialState } from '../components/Contract/Contract-reducer';
import {
    defaultColumns as IssuesReportColumns,
    initialState as IssuesReportInitialState,
} from '../components/IssuesReport/IssuesReport-reducer';
import {
    filtersPresets,
    issueListFiltersinitialState,
} from '../components/IssueListFilters/IssueListFilters-reducer';
import {
    DEFAULT_FILTER_PRESET,
    IIssueListFiltersReducer,
    IssuesSortOption,
    PresetIssueFilters,
} from '../components/IssueListFilters/IssueListFilters-types';
// import { removeOutdatedKeys } from '../components/App/App-helpers';
import { IContractPollingProviderReducer } from '../components/ContractPollingProvider/ContractPollingProvider-types';
import { contractPollingProviderInitialState } from '../components/ContractPollingProvider/ContractPollingProvider-reducer';
import { IIssuesReportReducer } from '../components/IssuesReport/IssuesReport-types';

const transformApp = createTransform(
    (inboundState: IAppReducer): IAppReducer => ({
        ...appInitialState,
        defaultStream: inboundState.defaultStream,
        propertyDetailsLoginCount: inboundState.propertyDetailsLoginCount,
        themes: [],
    }),
    (outboundState) => ({ ...outboundState }),
    { whitelist: ['app'] }
);

const transformContractPollingProvider = createTransform(
    (
        inboundState: IContractPollingProviderReducer
    ): IContractPollingProviderReducer => ({
        ...contractPollingProviderInitialState,
        reprocessingContracts: inboundState.reprocessingContracts,
    }),
    (outboundState) => ({ ...outboundState }),
    { whitelist: ['contractPollingProvider'] }
);

const transformContract = createTransform(
    (inboundState: IContractReducer): IContractReducer => ({
        ...contractInitialState,
        id: inboundState.id,
    }),
    (outboundState) => ({ ...outboundState }),
    { whitelist: ['contract'] }
);

const transformIssueListFilters = createTransform(
    // transform state on its way to being serialized and persisted.
    (inboundState: IIssueListFiltersReducer): IIssueListFiltersReducer => ({
        ...inboundState,
    }),
    // transform state being rehydrated
    (outboundState: IIssueListFiltersReducer) => {
        let activeIssueFilters = outboundState.activeIssueFilters;
        let sortBy = outboundState.sortBy;

        activeIssueFilters = {
            ...issueListFiltersinitialState.activeIssueFilters,
            ...activeIssueFilters,
        };

        let selectedFilterPreset = outboundState.selectedFilterPreset;

        if (
            selectedFilterPreset &&
            !filtersPresets[PresetIssueFilters[selectedFilterPreset]]
        ) {
            activeIssueFilters =
                filtersPresets[PresetIssueFilters[DEFAULT_FILTER_PRESET]];
            selectedFilterPreset = DEFAULT_FILTER_PRESET;
        }

        if (!sortBy) {
            sortBy = IssuesSortOption.CLAUSE;
        }

        return {
            ...outboundState,
            activeIssueFilters,
            selectedFilterPreset,
            sortBy,
        };
    },
    { whitelist: ['issueListFilters'] }
);

const transformIssuesReport = createTransform(
    (inboundState: IIssuesReportReducer): IIssuesReportReducer => {
        const columns = inboundState.columns.map((column) => {
            const width =
                column.width ??
                IssuesReportColumns.find((c) => c.label === column.label)
                    ?.width ??
                12.5;

            return {
                ...column,
                width,
            };
        });

        return {
            ...IssuesReportInitialState,
            sortModel: inboundState.sortModel,
            columns,
        };
    },
    (outboundState) => ({ ...outboundState }),
    { whitelist: ['issuesReport'] }
);

const migrations = {
    8: (state: any) =>
        // reset filters
        ({
            ...state,
            issueListFilters: issueListFiltersinitialState,
        }),
};

const persistConfig = {
    key: 'root',
    version: 8,
    storage,
    whitelist: [
        'app',
        'auth',
        'contract',
        'contractList',
        'contractListToolbar',
        'contractPollingProvider',
        'contractToolbar',
        'featureToggles',
        'heatmapToggles',
        'issueListFilters',
        'paneDivider',
        'partyCorrection',
        'issuesReport',
    ],
    transforms: [
        transformApp,
        transformContract,
        transformContractPollingProvider,
        transformIssueListFilters,
        transformIssuesReport,
    ],
    migrate: createMigrate(migrations, { debug: true }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

function configureStore(preloadedState: IRootState | undefined = undefined) {
    const middlewares = [thunk];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const composeEnhancers = composeWithDevTools({
        trace: true,
        traceLimit: 25,
    });

    const store = createStore(
        persistedReducer,
        preloadedState,
        composeEnhancers(middlewareEnhancer)
    );

    const persistor = persistStore(store);

    return {
        store,
        persistor,
    };
}

export const reduxStore = configureStore();
