import React, { useContext, useMemo, useState } from 'react';
import { useSearchMatchHighlight, useSearchNavigation } from './hooks';
import {
    ContractSearchContextValue,
    ContractSearchProviderProps,
} from './types';

const ContractSearchContext = React.createContext<ContractSearchContextValue>({
    onSearchTermChange: () => {},
    searchTerm: '',
    registerContentElement: () => {},
    navigate: () => {},
    highlightedMatchIndex: 0,
    totalMatches: 0,
});

export const useContractSearchContext = () => {
    const context = useContext(ContractSearchContext);
    return context;
};

export const ContractSearchProvider = ({
    children,
}: ContractSearchProviderProps) => {
    const [searchTerm, setSearchTerm] = useState('');

    const { onHighlightDone, navigate, highlightedMatchIndex, totalMatches } =
        useSearchNavigation();
    const { registerContentElement } = useSearchMatchHighlight({
        searchTerm,
        onHighlightDone,
    });

    const value = useMemo<ContractSearchContextValue>(
        () => ({
            onSearchTermChange: setSearchTerm,
            searchTerm,
            registerContentElement,
            navigate,
            highlightedMatchIndex,
            totalMatches,
        }),
        [
            highlightedMatchIndex,
            navigate,
            registerContentElement,
            searchTerm,
            totalMatches,
        ]
    );

    return (
        <ContractSearchContext.Provider value={value}>
            {children}
        </ContractSearchContext.Provider>
    );
};
