import {
    ContractIssue,
    FamiliarityLevel,
    IssueSeverityLevel,
} from '@thought-river/negotiations-common';
import DocumentParser from '../../../../services/documentParser';
import {
    escapeRegex,
    sortSeverityLevel,
} from '../../../../components/App/App-helpers';
import {
    ContractIntelligenceType,
    IDisplayContract,
    ISelectedContractIntelligence,
} from '../../../../components/Contract/Contract-types';
import { IIssueFiltersValues } from '../../../../components/IssueListFilters/IssueListFilters-types';
import { IContractParagraph } from '../../../../components/Paragraph/Paragraph-types';
import { ISummaryProperty } from '../../../../components/SummaryPane/SummaryPane-types';
import { analyticsService } from '../../../../services/Analytics/Analytics';
import { EVENT_ACTION_DOCUMENT_VIEWER_MISSING_FONT } from '../../../../services/Analytics/Analytics-types';

export function tagTerm(
    text: string,
    term: string,
    classes: string = 'search-result'
): string {
    const regex = new RegExp(escapeRegex(term), 'gi');
    let match;
    let startIndex;
    let endIndex;
    const matchRanges: any[] = [];

    while ((match = regex.exec(text))) {
        startIndex = match.index;
        endIndex = match.index + term.length;
        matchRanges.push({ startIndex, endIndex });
    }

    for (const range of [...matchRanges].reverse()) {
        text = DocumentParser.replaceRange(
            text,
            range.endIndex,
            range.endIndex,
            '</span>'
        );
        text = DocumentParser.replaceRange(
            text,
            range.startIndex,
            range.startIndex,
            `<span class="${classes}">`
        );
    }

    return text;
}

export function getFilteredIssuesForParagraph(
    filters: IIssueFiltersValues,
    paragraph: IContractParagraph
): { filtered: ContractIssue[]; filteredByThemeOnly: ContractIssue[] } {
    const {
        categories = [],
        familiarity = [],
        severity = [],
        status = [],
        themes = [],
    } = filters;

    return {
        filtered: paragraph.issues.filter(
            (i) =>
                (!familiarity.length ||
                    familiarity.includes(
                        i.organizationFamiliarity!.frequencyLevel // Fixme: null checks
                    )) &&
                (!severity.length || severity.includes(i.severityLevel)) &&
                (!status.length || status.includes(i.status)) &&
                (!themes.length ||
                    themes.some((t) =>
                        i.nonAssessedThemeIds.includes(t.nonAssessedId)
                    )) &&
                (!categories.length ||
                    categories.some((filterCategory) =>
                        i.categories.find(
                            (globalCategory) =>
                                globalCategory.id === filterCategory.id
                        )
                    ))
        ),
        filteredByThemeOnly: paragraph.issues.filter((i) =>
            themes.some((t) => i.nonAssessedThemeIds.includes(t.nonAssessedId))
        ),
    };
}

export function isParagraphLinked(
    paragraph: IContractParagraph,
    selectedIssue: ContractIssue,
    selectedProperty: ISummaryProperty
): boolean {
    const paragraphLinkedToSelectedIssue = selectedIssue?.clauseNumbers
        .map((clause) => clause.originUuid)
        .includes(paragraph.originUuid);
    const paragraphRelatedToSelectedProperty =
        selectedProperty?.relatedParagraphIds.includes(paragraph.id);
    const paragraphLinkedToSelectedProperty =
        selectedProperty?.linkedParagraphIds.includes(paragraph.id);

    if (
        paragraphLinkedToSelectedIssue ||
        paragraphRelatedToSelectedProperty ||
        paragraphLinkedToSelectedProperty
    ) {
        return true;
    }

    return false;
}

export function getHighestSeverityFromIssues(
    issues: ContractIssue[]
): IssueSeverityLevel {
    return (
        issues
            ?.map((issue) => issue.severityLevel)
            .sort(sortSeverityLevel)[0] || IssueSeverityLevel.UNSCORED
    );
}

export function getHighlightType(
    paragraph: IContractParagraph,
    contract: IDisplayContract,
    activeIssueFilters: IIssueFiltersValues,
    selectedContractIntelligence: ISelectedContractIntelligence
) {
    const { filtered: activeFilteredIssues } = getFilteredIssuesForParagraph(
        activeIssueFilters,
        paragraph
    );
    const filteredSeverity = getHighestSeverityFromIssues(activeFilteredIssues);
    const unfilteredSeverity = getHighestSeverityFromIssues(paragraph.issues);

    if (selectedContractIntelligence.type === ContractIntelligenceType.RISK) {
        if (selectedContractIntelligence.highlightType) {
            return unfilteredSeverity;
        } else if (!selectedContractIntelligence.highlightType) {
            return filteredSeverity;
        }
    } else if (
        selectedContractIntelligence.type ===
        ContractIntelligenceType.FAMILIARITY
    ) {
        // If contract is a template, override its familiarity to Standard
        return contract.isTemplate
            ? FamiliarityLevel.TEMPLATE
            : paragraph.organisationFamiliarity.frequencyLevel;
    }

    return null;
}

function getFontFamilyFromURL(url: string) {
    try {
        const fontUrl = new URL(url);
        const fontName = fontUrl.searchParams.get('family');
        return fontName;
    } catch {
        return null;
    }
}

function getIsFontAvailable(fontName: string) {
    try {
        return document.fonts.check(`16px "${fontName}"`);
    } catch {
        return false;
    }
}

export function loadGoogleFonts(
    fontLinks: NodeListOf<HTMLLinkElement>,
    contract: IDisplayContract
) {
    const errorListeners: { link: HTMLLinkElement; listener: () => void }[] =
        [];

    fontLinks.forEach((link) => {
        const fontName = getFontFamilyFromURL(link.href);
        if (!fontName) {
            return;
        }

        const isFontAvailable = getIsFontAvailable(fontName);
        if (!isFontAvailable) {
            document.head.appendChild(link);

            const onFontLoadingError = () => {
                analyticsService.recordEvent(
                    EVENT_ACTION_DOCUMENT_VIEWER_MISSING_FONT,

                    {
                        ContractId: contract.id,
                        VersionId: contract.latestVersion.id,
                        VersionNumber: contract.latestVersion.versionNumber,
                        FontFamily: getFontFamilyFromURL(link.href),
                    }
                );
            };
            link.addEventListener('error', onFontLoadingError);
            errorListeners.push({
                link,
                listener: onFontLoadingError,
            });
        }
    });

    // Return a cleanup function for the useEffect that removes the listeners
    return () => {
        errorListeners.forEach(({ link, listener }) =>
            link.removeEventListener('error', listener)
        );
    };
}

/**
 * Calculates offset between two elements, provided one of them is an ancestor of the other
 */
export const calculateOffsetsBetweenElements = <
    TA extends HTMLElement,
    TB extends HTMLElement
>(
    elA: TA,
    elB: TB
) => {
    let parentElement: TA | TB, childElement: TA | TB;

    if (elA.contains(elB)) {
        parentElement = elA;
        childElement = elB;
    } else if (elB.contains(elA)) {
        parentElement = elB;
        childElement = elA;
    } else {
        return {
            top: 0,
            left: 0,
        };
    }

    let topOffset = 0;
    let leftOffset = 0;
    let currentElement = childElement;

    while (currentElement && !currentElement.isSameNode(parentElement)) {
        topOffset += currentElement.offsetTop;
        leftOffset += currentElement.offsetLeft;
        currentElement = currentElement.offsetParent as TA | TB;
    }

    return { top: topOffset, left: leftOffset };
};
