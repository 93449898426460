import { Component } from 'react';
import { toast } from 'react-toastify';
import { Add, ToggleOff, ToggleOn, Done, Replay } from '@mui/icons-material';

import {
    IContractListToolbarProps,
    IContractListToolbarState,
} from './ContractListToolbar-types';
import ToggleBar from '../ToggleBar/ToggleBar';
import { ToggleElement } from '../ToggleBar/ToggleBar-types';
import SearchBar from '../SearchBar/SearchBar';
import BulkSignModal from '../BulkSignModal/BulkSignModal-container';
import BulkReprocessModal from '../BulkReprocessModal/BulkReprocessModal-container';
import ContractUploadWizardModal from '../ContractUploadWizardModal/ContractUploadWizardModal-container';
import { Button } from '@thought-river/ui-components';
import debounce from 'lodash/debounce';

class ContractListToolbar extends Component<
    IContractListToolbarProps,
    IContractListToolbarState
> {
    constructor(props: IContractListToolbarProps) {
        super(props);

        this.state = {
            showUploadContractModal: false,
        };

        this.toggleClick = this.toggleClick.bind(this);
        this.performSearch = this.performSearch.bind(this);
    }

    componentDidMount() {
        this.props.setSearchTerm('');
    }

    async toggleClick(element: ToggleElement) {
        this.props.switchToggle(element);
        this.props.setRequestedPages([]);
        this.props.loadContracts();
    }

    openBulkReprocessModal() {
        const { contracts, selectedContractIds, showDialog } = this.props;

        if (!selectedContractIds.length) {
            toast.info('Please select at least one contract');
            return;
        }

        const selectedContracts = contracts.filter((contract) =>
            selectedContractIds.includes(contract.id)
        );

        showDialog(
            null!, // Fixme: null checks
            true,
            <BulkReprocessModal selectedContracts={selectedContracts} />,
            [],
            null
        );
    }

    openBulkSignModal() {
        const { businessStatuses, contracts, selectedContractIds, showDialog } =
            this.props;

        if (!selectedContractIds.length) {
            toast.info('Please select at least one contract');
            return;
        }

        const selectedContracts = contracts.filter((contract) =>
            selectedContractIds.includes(contract.id)
        );

        showDialog(
            null!, // Fixme: null checks
            true,
            <BulkSignModal
                businessStatuses={businessStatuses}
                selectedContracts={selectedContracts}
            />,
            [],
            null
        );
    }

    performSearch = debounce(async function performSearch(self: any) {
        await self.props.setRequestedPages([]);
        await self.props.loadContracts(1);
    }, 350);

    render() {
        const {
            bulkActions,
            searchTerm,
            selectedContractIds,
            setBulkActions,
            setSearchTerm,
            toggles,
        } = this.props;

        return (
            <>
                <div className="contract-list-toolbar">
                    <div className="contract-list-toolbar-actions">
                        <Button
                            onClick={() =>
                                this.setState({ showUploadContractModal: true })
                            }
                            startIcon={<Add />}
                            data-testid="new-negotiation-btn"
                        >
                            New negotiation
                        </Button>
                        <div
                            className={`contract-list-toolbar-bulk-actions ${
                                bulkActions ? 'on' : ''
                            }`}
                        >
                            <div
                                className="bulk-actions-toggle"
                                onClick={() => setBulkActions(!bulkActions)}
                            >
                                {bulkActions ? (
                                    <ToggleOn className="toggle-icon" />
                                ) : (
                                    <ToggleOff className="toggle-icon" />
                                )}
                                <span className="bulk-actions-label">
                                    Bulk actions
                                </span>
                            </div>
                            {bulkActions ? (
                                <div
                                    className={`bulk-action-btn bulk-sign ${
                                        selectedContractIds.length
                                            ? ''
                                            : 'disabled'
                                    }`}
                                    onClick={() => this.openBulkSignModal()}
                                >
                                    <span className="bulk-action-btn-text">
                                        <Done className="bulk-action-icon" />{' '}
                                        <span className="bulk-action-count">
                                            {selectedContractIds.length}
                                        </span>
                                    </span>
                                    <span className="bulk-action-btn-text hide-on-mobile">
                                        Sign {selectedContractIds.length}{' '}
                                        contracts
                                    </span>
                                </div>
                            ) : null}
                            {bulkActions ? (
                                <div
                                    className={`bulk-action-btn bulk-reprocess ${
                                        selectedContractIds.length
                                            ? ''
                                            : 'disabled'
                                    }`}
                                    onClick={() =>
                                        this.openBulkReprocessModal()
                                    }
                                >
                                    <span className="bulk-action-btn-text">
                                        <Replay className="bulk-action-icon" />{' '}
                                        <span className="bulk-action-count">
                                            {selectedContractIds.length}
                                        </span>
                                    </span>
                                    <span className="bulk-action-btn-text hide-on-mobile">
                                        Reanalyze {selectedContractIds.length}{' '}
                                        contracts
                                    </span>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="contract-list-toolbar-filters">
                        <ToggleBar
                            elements={toggles}
                            elementClick={this.toggleClick}
                        />

                        <div className="contract-list-toolbar-filter-search">
                            <SearchBar
                                searchTerm={searchTerm}
                                showMatches={false}
                                placeholder="Search contract name…"
                                onSearchTermChange={async (value: string) => {
                                    await setSearchTerm(value);
                                    await this.performSearch(this);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <ContractUploadWizardModal
                    open={this.state.showUploadContractModal}
                    onClose={() =>
                        this.setState({ showUploadContractModal: false })
                    }
                />
            </>
        );
    }
}

export default ContractListToolbar;
