import { useAppSelector } from '@modules/common/hooks';
import { queryClient } from '@thought-river/negotiations-common';
import { ContractTreeUpdate } from '@thought-river/negotiations-common/dist/api/contractManagement';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
    addTanStackQueryContractsRow,
    deleteTanStackQueryContractsRow,
    updateTanStackQueryContractsRow,
} from '../helpers';

const WAIT_INTERVAL_SECONDS = 30;
const RETRY_DELAY_SECONDS = 3;

export const useLongPoll = () => {
    const apiToken = useAppSelector((state) => state.auth.apiToken);

    const pollingIndexRef = useRef<string>();
    const retryTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const abortController = useMemo(() => new AbortController(), []);

    const pollUpdates = useCallback(async () => {
        const headers = {
            Prefer: `wait=${WAIT_INTERVAL_SECONDS}${
                pollingIndexRef.current
                    ? `;index=${pollingIndexRef.current}`
                    : ''
            }`,
            Authorization: `Bearer ${apiToken}`,
        };

        try {
            const response = await fetch(
                `${
                    window.__APP_CONFIG__.api2Url
                }/contract-management/v1_0/contracts?x=${Date.now()}`,
                {
                    headers,
                    signal: abortController.signal,
                }
            );

            const index = response.headers.get('x-polling-index');
            if (index) {
                pollingIndexRef.current = index;
            }

            if (response.status === 304) {
                // long poll finished with no updates, retry immediately
                pollUpdates();
                return;
            }

            if (response.status !== 200) {
                throw new Error('Unexpected response status code');
            }

            const updateData = (await response.json()) as ContractTreeUpdate;

            for (const deletedRowId of updateData.deleted) {
                deleteTanStackQueryContractsRow(queryClient, deletedRowId);
            }

            for (const newRow of updateData.new) {
                addTanStackQueryContractsRow(queryClient, newRow);
            }

            for (const updatedRow of updateData.updated) {
                updateTanStackQueryContractsRow(
                    queryClient,
                    updatedRow.id,
                    updatedRow
                );
            }

            pollUpdates();
        } catch (err) {
            retryTimeoutRef.current = setTimeout(
                pollUpdates,
                RETRY_DELAY_SECONDS * 1000
            );
        }
    }, [abortController.signal, apiToken]);

    useEffect(() => {
        return () => {
            abortController.abort();
            clearTimeout(retryTimeoutRef.current);
        };
    }, [abortController]);

    return {
        initLongPoll: pollUpdates,
    };
};
