import { ICustomPopoverProps } from './CustomPopover-types';
import './CustomPopover.scss';

const CustomPopover = (props: ICustomPopoverProps): JSX.Element => {
    const topOffset =
        props.offsetTop ?? props.anchor?.getBoundingClientRect()?.top ?? 0;
    const leftOffset =
        (props.anchor?.getBoundingClientRect()?.left ?? 0) +
        (props.offsetLeft ?? 0);
    const anchorHeight = props.anchor?.clientHeight ?? 0;
    const anchorWidth = props.anchor?.clientWidth ?? 0;
    const position = props.position ?? 'center';

    let transformX;

    switch (position) {
        case 'center':
            transformX = `calc(-50% + ${anchorWidth}px)`;
            break;
        case 'center-right':
            transformX = `calc(-75% + ${anchorWidth}px)`;
            break;
        case 'left':
            transformX = '0';
            break;
        case 'right':
            transformX = `calc(-100% + ${anchorWidth}px)`;
            break;
        default:
            transformX = '0px';
    }

    return (
        <div className={`custom-popover-wrapper ${props.open ? '' : 'hidden'}`}>
            <div
                className="custom-popover"
                style={{
                    position: 'fixed',
                    top: `${topOffset}px`,
                    left: `${leftOffset}px`,
                    transform: `translate(${transformX}, ${
                        anchorHeight + 10
                    }px)`,
                }}
            >
                {props.children}
            </div>
            <div className="custom-popover-overlay" onClick={props.onClose} />
        </div>
    );
};

export default CustomPopover;
