import {
    Icon,
    IconButton,
    Tooltip,
    AvatarGroup,
} from '@thought-river/ui-components';
import { People, Share } from '@mui/icons-material';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { ISecondaryNavigationProps } from './SecondaryNavigation-types';
import ShareNegotiationModal from '../ShareNegotiationModal/ShareNegotiationModal-container';
import { toast } from 'react-toastify';
import InternalSharingModal from '../InternalSharingModal/InternalSharingModal-container';
import ContractMenu from '../ContractMenu/ContractMenu';
import IssuesReportShareModal from '../IssuesReport/IssuesReportShareModal/IssuesReportShareModal-container';
import {
    getUserAvatarLabels,
    useAnalytics,
} from './SecondaryNavigation-helpers';
import DownloadButton from './DownloadButton/DownloadButton';
import SecondaryNavigationSaveStatus from './SaveStatus/SaveStatus';
import SearchBar from './SearchBar/SearchBar';
import { useDispatch, useSelector } from 'react-redux';
import { showDialogAction } from '../ModalDialog/ModalDialog-actions';
import { IRootState } from '../../redux/reducers-types';
import { updateContractAction } from '../Contract/Contract-actions';
import ContractName from './ContractName/ContractName';
import { useState } from 'react';
import { SubmitStatus } from '../App/App-types';
import {
    FEATURE_TOGGLE_CONTRACT_REPORT_NEW_DATA_GRID,
    FEATURE_TOGGLE_RELEASE_CLOSE_ALL_ISSUES,
} from '../FeatureToggleProvider/FeatureToggleProvider-types';
import { getContractPath } from '@modules/common/helpers';
import HistoryPanel, {
    useHistoryPanel,
} from '@modules/common/components/HistoryPanel';
import HistoryButton from '@modules/common/components/HistoryPanel/HistoryButton';
import { useAppSelector } from '@modules/common/hooks';
import {
    RelatedClausesToggle,
    useCloseAllIssuesContext,
} from '@thought-river/negotiations-common';

import './SecondaryNavigation.scss';

const TABS = [
    {
        key: 'review',
        label: 'Review',
        href: '',
    },
    {
        key: 'report',
        label: 'Report',
        href: '/report',
    },
];

const SecondaryNavigation = ({
    contract,
    loaded,
    formats,
}: ISecondaryNavigationProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const match = useRouteMatch();

    const { userId, users } = useSelector((state: IRootState) => state.auth);
    const featureToggles = useSelector(
        (state: IRootState) => state.featureToggles.toggles
    );
    const selectedVersion = useAppSelector(
        (state) => state.contract.selectedVersion
    );

    const { closeAllIssues, hasOpenIssues } = useCloseAllIssuesContext();

    const [contractNameSubmitStatus, setContractNameSubmitStatus] =
        useState<SubmitStatus>(SubmitStatus.SUCCESS);

    const {
        trackContractNameFocus,
        trackBackButtonClick,
        trackTabClick,
        trackViewAllOwners,
        trackPrimaryDownloadClick,
        trackSecondaryDownloadClick,
        trackShareClick,
        trackContextMenuClick,
        trackCloseAllIssuesClick,
    } = useAnalytics(contract, selectedVersion);

    const { isHistoryPanelOpen, toggleHistoryPanel } = useHistoryPanel();

    const isReportTab = match.path.endsWith('/report');
    const dataGridReportToggle = featureToggles.find(
        (toggle) =>
            toggle.feature === FEATURE_TOGGLE_CONTRACT_REPORT_NEW_DATA_GRID
    );
    const showDownloadButton = !isReportTab || !dataGridReportToggle?.enabled;

    const closeAllIssuesEnabled = !!featureToggles.find(
        (toggle) => toggle.feature === FEATURE_TOGGLE_RELEASE_CLOSE_ALL_ISSUES
    )?.enabled;

    const handleUserIconsClick = () => {
        if (!selectedVersion) {
            return;
        }

        dispatch(
            showDialogAction(
                'Add People',
                true,
                <ShareNegotiationModal
                    contract={contract}
                    version={selectedVersion}
                />,
                [],
                null,
                'share-negotiation-modal-wrapper',
                true
            )
        );

        trackViewAllOwners();
    };

    const handleShareClick = () => {
        if (!loaded) {
            toast.info('Please wait for the contract to finish loading');
            return;
        }

        trackShareClick();

        if (isReportTab) {
            dispatch(
                showDialogAction(
                    '',
                    false,
                    <IssuesReportShareModal />,
                    [],
                    '500px',
                    'issues-report-modal-wrapper'
                )
            );

            return;
        }

        const title = (
            <div className="modal-title-wrapper">
                <div className="title">
                    <Share className="title-icon" />
                    <span className="title-text">Share Version</span>
                </div>
                <div className="subtitle">
                    Share the latest version of the Negotiation.
                </div>
            </div>
        );

        dispatch(
            showDialogAction(title, true, <InternalSharingModal />, [], null)
        );
    };

    const handleBackClick = () => {
        trackBackButtonClick();
        history.push('/');
    };

    const handleCloseAllIssuesClick = () => {
        trackCloseAllIssuesClick();
        closeAllIssues();
    };

    const contractPath =
        contract && selectedVersion
            ? getContractPath(
                  contract.streamCode,
                  contract.id,
                  selectedVersion?.id
              )
            : '';
    const userAvatarLabels = getUserAvatarLabels(contract, users, userId);

    return (
        <>
            <header className="sec-nav">
                <div className="sec-nav-left-col">
                    <Tooltip title="Back">
                        <IconButton size="medium" onClick={handleBackClick}>
                            <Icon type="arrow-back" />
                        </IconButton>
                    </Tooltip>

                    {loaded && (
                        <div className="sec-nav-contract-details-wrapper">
                            <div className="sec-nav-contract-details">
                                <ContractName
                                    contract={contract}
                                    onContractNameFocus={trackContractNameFocus}
                                    onSubmitStatusChange={(newStatus) =>
                                        setContractNameSubmitStatus(newStatus)
                                    }
                                />
                                <div className="sec-nav-save-status">
                                    <SecondaryNavigationSaveStatus
                                        contractNameSubmitStatus={
                                            contractNameSubmitStatus
                                        }
                                    />
                                </div>
                            </div>

                            <ul className="sec-nav-tabs">
                                {TABS.map((tab) => (
                                    <li key={tab.key}>
                                        <NavLink
                                            className="sec-nav-tab-link"
                                            to={`${contractPath}${tab.href}`}
                                            activeClassName="active"
                                            exact
                                            onClick={() =>
                                                trackTabClick(tab.href)
                                            }
                                        >
                                            {tab.label}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>

                {loaded && (
                    <div className="sec-nav-right-col">
                        <div className="sec-nav-controls">
                            <RelatedClausesToggle />
                            <HistoryButton onClick={toggleHistoryPanel} />
                            {userAvatarLabels.length ? (
                                <button
                                    className="sec-nav-user-icons"
                                    onClick={handleUserIconsClick}
                                >
                                    <AvatarGroup
                                        labels={userAvatarLabels}
                                        iconOptions={{ size: 'medium' }}
                                    />
                                </button>
                            ) : (
                                <Tooltip title="Add People">
                                    <IconButton onClick={handleUserIconsClick}>
                                        <People />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {!isReportTab && <SearchBar />}
                            {showDownloadButton && selectedVersion && (
                                <DownloadButton
                                    contract={contract}
                                    version={selectedVersion}
                                    formats={formats}
                                    isReportTab={isReportTab}
                                    onPrimaryDownloadClick={
                                        trackPrimaryDownloadClick
                                    }
                                    onSecondaryDownloadClick={
                                        trackSecondaryDownloadClick
                                    }
                                />
                            )}
                            <Tooltip title="Share Version">
                                <IconButton
                                    size="medium"
                                    onClick={handleShareClick}
                                >
                                    <Icon type="share" />
                                </IconButton>
                            </Tooltip>
                        </div>

                        <ContractMenu
                            contract={contract}
                            formats={formats}
                            onOpen={trackContextMenuClick}
                            onContractUpdated={(contractId, fields) =>
                                dispatch(
                                    updateContractAction(contractId, fields)
                                )
                            }
                            hideDownloadsSection
                            additionalItems={
                                closeAllIssuesEnabled
                                    ? [
                                          {
                                              label: 'Close All Issues',
                                              icon: (
                                                  <Icon type="status-closed" />
                                              ),
                                              onClick:
                                                  handleCloseAllIssuesClick,
                                              disabled: !hasOpenIssues,
                                          },
                                      ]
                                    : undefined
                            }
                        />
                    </div>
                )}
            </header>
            <HistoryPanel
                open={isHistoryPanelOpen}
                onClose={toggleHistoryPanel}
                isReportTab={isReportTab}
            />
        </>
    );
};

export default SecondaryNavigation;
