import {
    AccountBalance,
    BusinessCenter,
    Chat,
    DeviceHub,
    Folder,
    FolderSpecial,
    Language,
    Layers,
    People,
    School,
    SettingsOutlined,
} from '@mui/icons-material';

import { MenuGroup, IMenuListItem } from '../../MobileMenu/MobileMenu-types';
import { UserRole } from '../../Auth/Auth-types';
import { AppMenuItem } from '../../App/App-types';
import { doesIntersect } from '../../App/App-helpers';
import './SettingsMenu.scss';
import { Tooltip } from '@thought-river/ui-components';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { MutableRefObject, useRef, useState } from 'react';
import groupBy from 'lodash/groupBy';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../redux/reducers-types';
import { FEATURE_TOGGLE_API_KEYS_PAGE } from '../../../components/FeatureToggleProvider/FeatureToggleProvider-types';
import { getLegacyUIUrl } from '@modules/common/helpers';

const SettingsMenu = () => {
    const { roles } = useSelector((state: IRootState) => state.auth);
    const featureToggles = useSelector(
        (state: IRootState) => state.featureToggles.toggles
    );

    const apiKeysPageEnabled = featureToggles.find(
        (toggle) => toggle.feature === FEATURE_TOGGLE_API_KEYS_PAGE
    )?.enabled;

    const settingsRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

    const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(
        null
    );

    const menuItems: IMenuListItem[] = [
        ...(apiKeysPageEnabled
            ? [
                  {
                      group: MenuGroup.ACCOUNT_ADMIN,
                      icon: <People />,
                      label: AppMenuItem.API_KEYS,
                      link: { url: '#/settings/api-keys' },
                      requiredRoles: [UserRole.LEGAL_ADMIN],
                  },
              ]
            : []),

        {
            group: MenuGroup.PARTY_SETTINGS,
            icon: <AccountBalance />,
            label: AppMenuItem.ORGANIZATIONS,
            link: { url: '{reviewUrl}/admin#/organisations' },
            requiredRoles: [UserRole.LEGAL_ADMIN, UserRole.AUTHOR],
        },
        {
            group: MenuGroup.PARTY_SETTINGS,
            icon: <Layers />,
            label: AppMenuItem.REFERENCE_NAME_TEMPLATES,
            link: { url: '{reviewUrl}/reference_name_templates#/' },
            requiredRoles: [UserRole.LEGAL_ADMIN, UserRole.AUTHOR],
        },
        {
            group: MenuGroup.PLAYBOOK_MANAGEMENT,
            icon: <DeviceHub />,
            label: AppMenuItem.AUTHORING,
            link: { url: '{reviewUrl}/lexible' },
            requiredRoles: [UserRole.AUTHOR],
        },
        {
            group: MenuGroup.PLAYBOOK_MANAGEMENT,
            icon: <School />,
            label: AppMenuItem.TRAINING,
            link: { url: '{reviewUrl}/training' },
            requiredRoles: [UserRole.AUTHOR],
        },
        {
            group: MenuGroup.PLAYBOOK_MANAGEMENT,
            icon: <FolderSpecial />,
            label: AppMenuItem.POLICIES,
            link: { url: '{reviewUrl}/admin#/policies' },
            requiredRoles: [UserRole.AUTHOR],
        },
        {
            group: MenuGroup.PLAYBOOK_MANAGEMENT,
            icon: <Chat />,
            label: AppMenuItem.SUGGESTIONS,
            link: { url: '{reviewUrl}/admin#/suggestions' },
            requiredRoles: [UserRole.AUTHOR],
        },
        {
            group: MenuGroup.PLAYBOOK_MANAGEMENT,
            icon: <Language />,
            label: AppMenuItem.REVIEW_STREAMS,
            link: { url: '{reviewUrl}/admin#/accounts' },
            requiredRoles: [UserRole.AUTHOR],
        },
        {
            group: MenuGroup.PLAYBOOK_MANAGEMENT,
            icon: <BusinessCenter />,
            label: AppMenuItem.LEXIBLE_CONTRACT_TYPES,
            link: { url: '{reviewUrl}/lexible/#/contract-types' },
            requiredRoles: [UserRole.AUTHOR],
        },
        {
            group: MenuGroup.PLAYBOOK_MANAGEMENT,
            icon: <Folder />,
            label: AppMenuItem.PORTFOLIOS,
            link: { url: '{reviewUrl}/admin#/portfolios' },
            requiredRoles: [UserRole.AUTHOR],
        },
    ];

    const filterMenuItem = (menuItem: IMenuListItem): boolean => {
        if (menuItem.requiredRoles) {
            return doesIntersect(menuItem.requiredRoles, roles);
        }

        return true;
    };

    const renderMenuItem = (menuItem: IMenuListItem): JSX.Element => {
        const { link, label, icon } = menuItem;
        const { url = '', target = '_self' } = link ?? {};

        const updatedUrl = url.replace('{reviewUrl}', getLegacyUIUrl());

        return (
            <MenuItem
                className="menu-item"
                onClick={handleClose}
                key={label}
                data-testid={`settings-menu-item-${label}`}
            >
                <a href={updatedUrl} target={target}>
                    <ListItemIcon className="menu-item-icon">
                        {icon}
                    </ListItemIcon>
                    <ListItemText className="menu-item-label" primary={label} />
                </a>
            </MenuItem>
        );
    };

    const handleClose = () => {
        setAnchorElement(null);
    };

    const handleOpen = () => {
        setAnchorElement(settingsRef.current);
    };

    const renderMenuGroup = (
        group: MenuGroup,
        groupedMenuItems: { [group: string]: IMenuListItem[] }
    ) => {
        if (!groupedMenuItems[group]) {
            return null;
        }

        return (
            <div className="menu-group">
                <MenuItem className="menu-group-title" key={group}>
                    {group}
                </MenuItem>
                <div className="menu-group-items">
                    {groupedMenuItems[group].map(renderMenuItem)}
                </div>
            </div>
        );
    };

    const filteredMenuItems = menuItems.filter(filterMenuItem);

    const groupedMenuItems = groupBy(filteredMenuItems, 'group');

    if (!filteredMenuItems.length) {
        return null;
    }

    return (
        <>
            <div
                className="settings-menu-anchor header-menu-anchor"
                onClick={handleOpen}
                ref={settingsRef}
                id="settings-button"
            >
                <Tooltip title="Settings" placement="bottom">
                    {<SettingsOutlined className="menu-icon" />}
                </Tooltip>
            </div>
            <Menu
                className="settings-menu"
                anchorEl={anchorElement}
                open={!!anchorElement}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        transform: 'translateX(-50px)',
                    },
                }}
                MenuListProps={{
                    'aria-labelledby': 'settings-button',
                }}
            >
                <div className="menu-content">
                    <div className="menu-column">
                        {renderMenuGroup(
                            MenuGroup.ACCOUNT_ADMIN,
                            groupedMenuItems
                        )}
                        {renderMenuGroup(
                            MenuGroup.PARTY_SETTINGS,
                            groupedMenuItems
                        )}
                    </div>
                    <div className="menu-column">
                        {renderMenuGroup(
                            MenuGroup.PLAYBOOK_MANAGEMENT,
                            groupedMenuItems
                        )}
                    </div>
                </div>
            </Menu>
        </>
    );
};

export default SettingsMenu;
