import * as React from 'react';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import {
    IContractUploadWizardModalProps,
    IContractUploadWizardModalState,
    IUploadedFile,
    ProcessStatus,
} from './ContractUploadWizardModal-types';
import ProgressSteps from './ProgressSteps/ProgressSteps';
import './ContractUploadWizardModal.scss';
import { Delete, Domain, Edit } from '@mui/icons-material';
import DualListEditor from '../DualListEditor/DualListEditor';
import {
    IParty,
    PartiesType,
    PartyVariantType,
} from '../Parties/Parties-types';
import { IDualItem } from '../DualListEditor/DualListEditor-types';
import WizardFileUploader from '../WizardFileUploader/WizardFileUploader';
import FileUploadProgress from '../FileUploadProgress/FileUploadProgress';
import { IPreviewFile } from '../WizardFileUploader/WizardFileUploader-types';
import {
    getResourceIdFromAxiosResponse,
    getResourceIdFromResponse,
} from '../../resources/resource-helpers';
import ContractResource from '../../resources/ContractResource';
import { mapContract, mapVersion } from '../App/App-mappers';
import { IContractVersion, IDisplayContract } from '../Contract/Contract-types';
import VersionResource from '../../resources/VersionResource';
import { IProcessingContract } from '../ContractPollingProvider/ContractPollingProvider-types';
import { analytics } from './ContractUploadWizardModal-analytics';
import { VERSION_STATE_UPLOADING } from '../UploadProgressBar/UploadProgressBar-types';
import { IReviewStream } from '../../resources/AuthResource-types';
import {
    Button,
    Select,
    SelectOption,
    Tooltip,
    Dialog,
} from '@thought-river/ui-components';
import { formatStreamOption } from './ContractUploadWizardModal-helpers';
import { fetchContractStatus } from '../Contract/Contract-helpers';
import { FEATURE_TOGGLE_CONTRACTS_TREE } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import { fetchUpdatePartiesForContractVersion } from '@thought-river/negotiations-common/dist/api/contractContent';
import {
    getEditPartiesPath,
    mapPartiesToContractVersionParties,
} from '@modules/common/helpers';
import PartyDetectionListener from './PartyDetectionListener';

@analytics()
class ContractUploadWizardModal extends React.Component<
    IContractUploadWizardModalProps,
    IContractUploadWizardModalState
> {
    private readonly dualListEditorRef: React.RefObject<any>;

    constructor(props: IContractUploadWizardModalProps) {
        super(props);

        const { existingContractId } = this.props;

        const defaultStream = this.resolveDefaultStream();

        this.state = {
            activeStep: existingContractId ? 1 : 0,
            deletingFile: false,
            discardedParties: [],
            files: [],
            isUploading: false,
            parties: [],
            reciprocalParties: [],
            selectedStream: defaultStream
                ? formatStreamOption(defaultStream)
                : null!, // Fixme: null checks
            stepLabels: ['Select Deal Type', 'Upload Files', 'Confirm Parties'],
            submitting: false,
            uploadedFiles: [],
        };

        this.dualListEditorRef = React.createRef();
        this.editParties = this.editParties.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.onConfirmParties = this.onConfirmParties.bind(this);
        this.swapParties = this.swapParties.bind(this);
    }

    componentDidUpdate(prevProps: IContractUploadWizardModalProps) {
        const {
            failedProcessedContracts,
            successfulProcessedContracts,
            existingContractId,
            open,
        } = this.props;
        const {
            failedProcessedContracts: prevFailedProcessedContracts,
            successfulProcessedContracts: prevSuccessfulProcessedContracts,
        } = prevProps;

        const { uploadedFiles } = this.state;

        if (
            uploadedFiles.length === 1 &&
            !prevSuccessfulProcessedContracts.find(
                (c) => c.contractId === uploadedFiles[0].contractId
            ) &&
            successfulProcessedContracts.find(
                (c) => c.contractId === uploadedFiles[0].contractId
            )
        ) {
            this.onSuccessfulProcessing(uploadedFiles[0]);
        }

        if (
            uploadedFiles.length === 1 &&
            !prevFailedProcessedContracts.find(
                (c) => c.contractId === uploadedFiles[0].contractId
            ) &&
            failedProcessedContracts.find(
                (c) => c.contractId === uploadedFiles[0].contractId
            )
        ) {
            this.onFailedProcessing(uploadedFiles[0]);
        }

        if (open !== prevProps.open) {
            const defaultStream = this.resolveDefaultStream();

            this.setState({
                activeStep: existingContractId ? 1 : 0,
                deletingFile: false,
                discardedParties: [],
                files: [],
                isUploading: false,
                parties: [],
                reciprocalParties: [],
                selectedStream: defaultStream
                    ? formatStreamOption(defaultStream)
                    : null!, // Fixme: null checks
                submitting: false,
                uploadedFiles: [],
            });
        }
    }

    private allFilesHaveUploaded = (): boolean => {
        const { files } = this.state;

        return !!(
            files.length &&
            files.length === files.filter(this.fileHasUploaded).length
        );
    };

    private hasFailedProcess = (): boolean => {
        const { uploadedFiles } = this.state;

        return !!uploadedFiles.find(
            (c) => c.processStatus === ProcessStatus.FAILURE
        );
    };

    private cancelFileUpload = (fileId: string): void => {
        const { files } = this.state;

        const updatedFiles = [];

        for (const file of files) {
            if (file.id === fileId) {
                file.abortController.abort();
            } else {
                updatedFiles.push(file);
            }
        }

        this.setState({
            files: updatedFiles,
        });
    };

    private deleteUploadedFile = async (
        fileId: string,
        showConfirmation: boolean = true
    ): Promise<void> => {
        const { files, uploadedFiles } = this.state;
        const {
            contracts,
            setContracts,
            unsetProcessingContract,
            unsetNotifiedContract,
        } = this.props;

        const updatedUploadedFiles = [];
        const updatedFiles = files.filter((file) => file.id !== fileId);

        await this.setState({
            files: updatedFiles,
            deletingFile: true,
        });

        try {
            for (const file of uploadedFiles) {
                if (file.fileId === fileId) {
                    await ContractResource.deleteContract(
                        file.contractId,
                        file.streamCode
                    );
                    setContracts(
                        contracts.filter(
                            (contract) => contract.id !== file.contractId
                        )
                    );
                    unsetProcessingContract(file.contractId);
                    unsetNotifiedContract(file.contractId);
                } else {
                    updatedUploadedFiles.push(file);
                }
            }

            this.setState({
                uploadedFiles: updatedUploadedFiles,
            });

            if (showConfirmation) {
                toast.success('File deleted successfully');
            }
        } catch (error) {
            toast.error('Error deleting file');

            this.setState({
                files,
                deletingFile: false,
            });
        }

        this.setState({
            deletingFile: false,
        });
    };

    private editParties(): void {
        const { location, onClose, featureToggles } = this.props;

        const { uploadedFiles } = this.state;

        const uploadedFile = uploadedFiles[0];

        const contractsTreeEnabled = !!featureToggles.find(
            (toggle) => toggle.feature === FEATURE_TOGGLE_CONTRACTS_TREE
        )?.enabled;

        const partiesUiPath = getEditPartiesPath(
            uploadedFile.contractId,
            uploadedFile.streamCode
        );

        if (contractsTreeEnabled) {
            window.open(`#${partiesUiPath}`);
        } else {
            this.props.history.push({
                pathname: partiesUiPath,
                state: { prevPath: location.pathname },
            });
        }

        onClose();
    }

    private fileHasUploaded = (file: IPreviewFile): boolean =>
        file.uploadProgress === 100;

    private goToStep = (delta: 1 | -1): void => {
        const { activeStep, stepLabels } = this.state;

        const nextStep = activeStep + delta;

        if (nextStep >= stepLabels.length || nextStep < 0) {
            return;
        }

        this.setState({
            activeStep: nextStep,
        });

        this.onStepChange(nextStep);
    };

    private isProcessingCompleted = (): boolean => {
        const { uploadedFiles } = this.state;
        return uploadedFiles[0]?.processStatus === ProcessStatus.SUCCESS;
    };

    private onClickNext = () => {
        const { activeStep, files } = this.state;
        const { onClose } = this.props;

        if (activeStep === 2) {
            this.onConfirmParties();
            return;
        }

        if (activeStep === 1 && files.length > 1) {
            onClose();
            return;
        }

        this.goToStep(1);
    };

    private async confirmPartiesViaContractContent(versionId: string) {
        const { parties } = this.state;

        await fetchUpdatePartiesForContractVersion({
            pathParams: {
                contractVersionUuid: versionId,
            },
            body: mapPartiesToContractVersionParties(parties),
        });
    }

    private async onConfirmParties() {
        const { uploadedFiles, submitting } = this.state;
        const { onClose, featureToggles, setReprocessingContract, userId } =
            this.props;

        const contractFile = uploadedFiles[0];

        if (!contractFile || submitting) {
            return;
        }

        this.setState({
            submitting: true,
        });

        try {
            await this.confirmPartiesViaContractContent(contractFile.versionId);

            const contractsTreeEnabled = !!featureToggles.find(
                (toggle) => toggle.feature === FEATURE_TOGGLE_CONTRACTS_TREE
            )?.enabled;

            if (!contractsTreeEnabled) {
                const {
                    filename,
                    contractId,
                    versionId,
                    streamCode,
                    streamId,
                } = contractFile;

                setReprocessingContract(
                    contractId,
                    filename,
                    versionId,
                    streamCode,
                    streamId,
                    0,
                    userId
                );
            }

            this.setState({
                submitting: false,
            });

            onClose();
        } catch (error) {
            this.setState({
                submitting: false,
            });
            toast.error('Updating parties has failed');
            return;
        }
    }

    private onDiscardParty = async (discardedParty: IParty): Promise<void> => {
        const { parties, discardedParties } = this.state;

        const updatedParties = parties.filter(
            (party) => party.id !== discardedParty.id
        );
        const updatedDiscardedParties = [
            ...discardedParties,
            { ...discardedParty, type: PartiesType.DISCARDED },
        ];

        this.setState({
            parties: updatedParties,
            discardedParties: updatedDiscardedParties,
        });

        this.dualListEditorRef.current.deleteItem(discardedParty.name);

        this.persistPartiesLocally();
    };

    private onFileChange(files: IPreviewFile[]): void {
        if (!files) {
            return;
        }

        for (const file of files) {
            this.uploadFile(file, files.length);
        }

        this.setState({
            files: [...this.state.files, ...files],
        });
    }

    private onPartiesChange = async (items: IDualItem[]): Promise<void> => {
        const { parties } = this.state;

        parties.map((party) => {
            const item = items.find((item) => item.label === party.name);

            if (item) {
                party.type = item.active
                    ? PartiesType.COUNTER_PARTIES
                    : PartiesType.OWN_PARTIES;
            }

            return party;
        });

        await this.setState({
            parties,
        });

        this.persistPartiesLocally();
    };

    private onSelectStream = (selectedOption: SelectOption): void => {
        const { setDefaultStream, streams } = this.props;

        this.setState({
            selectedStream: selectedOption,
        });

        const stream = streams.find(
            (stream) => stream.accountCode === selectedOption.value
        );

        if (stream) {
            setDefaultStream(stream);
        }
    };

    private onStepChange = (targetStep: number): void => {
        const { files, uploadedFiles } = this.state;

        if (targetStep === 0) {
            for (const file of files) {
                this.cancelFileUpload(file.id);
            }

            for (const uploadedFile of uploadedFiles) {
                this.deleteUploadedFile(uploadedFile.fileId, false);
            }
        }
    };

    private onFailedProcessing = async ({
        fileId,
        filename,
    }: IUploadedFile) => {
        const { uploadedFiles } = this.state;

        const updatedUploadedFiles = [];

        for (const file of uploadedFiles) {
            if (file.fileId === fileId) {
                updatedUploadedFiles.push({
                    ...file,
                    processStatus: ProcessStatus.FAILURE,
                });
            } else {
                updatedUploadedFiles.push(file);
            }
        }

        this.setState({
            activeStep: 1,
            uploadedFiles: updatedUploadedFiles,
        });

        toast.error(`Contract ${filename} failed to process`);
    };

    private onSuccessfulProcessing = async ({
        contractId,
        versionId,
        streamCode,
        fileId,
    }: IUploadedFile): Promise<void> => {
        const { uploadedFiles } = this.state;

        try {
            const updatedUploadedFiles = [];

            for (const file of uploadedFiles) {
                if (file.fileId === fileId) {
                    updatedUploadedFiles.push({
                        ...file,
                        processStatus: ProcessStatus.SUCCESS,
                    });
                } else {
                    updatedUploadedFiles.push(file);
                }
            }

            const version = mapVersion(
                (
                    await VersionResource.getVersion(
                        contractId,
                        versionId,
                        streamCode
                    )
                ).data
            );

            this.setState({
                parties: version.parties.filter((party) =>
                    [
                        PartiesType.OWN_PARTIES,
                        PartiesType.COUNTER_PARTIES,
                    ].includes(party.type)
                ),
                reciprocalParties: version.parties.filter(
                    (party) => party.type === PartiesType.RECIPROCAL
                ),
                uploadedFiles: updatedUploadedFiles,
            });
        } catch (error) {
            toast.error('Error fetching parties');
        }
    };

    private onSuccessfulUpload = async (
        contract: IDisplayContract,
        version: IContractVersion
    ): Promise<void> => {
        const {
            addProcessingContract,
            existingContractId,
            prependContract,
            userId,
            unsetNotifiedContract,
        } = this.props;

        unsetNotifiedContract(contract.id);

        const updatedContract = {
            ...contract,
            versionIds: [...contract.versionIds, version.id],
            contractName: version.fileName,
        };

        const processingContract: IProcessingContract = {
            contractId: contract.id,
            contractName: contract.contractName,
            stream: contract.streamCode,
            userId,
            isPartiallyAssessed: true,
            status: VERSION_STATE_UPLOADING,
        };

        if (!existingContractId) {
            prependContract(updatedContract);
        }

        addProcessingContract(processingContract);
    };

    private onUploadProgress = (
        fileId: string,
        { loaded, total }: ProgressEvent
    ) => {
        const percentageComplete = (loaded / total) * 100;

        const { files } = this.state;
        const updatedFiles = [];

        for (const file of files) {
            if (file.id === fileId) {
                updatedFiles.push({
                    ...file,
                    uploadProgress: percentageComplete,
                });
            } else {
                updatedFiles.push(file);
            }
        }

        this.setState({
            files: updatedFiles,
        });
    };

    private mapPartyToDualListItem = (party: IParty): IDualItem => {
        const active = party.type === PartiesType.COUNTER_PARTIES;

        return {
            label: party.name,
            active,
            data: party,
        };
    };

    private persistPartiesLocally() {
        const { parties, reciprocalParties, discardedParties } = this.state;
        const { setLocalParties } = this.props;

        setLocalParties([
            ...parties,
            ...reciprocalParties,
            ...discardedParties,
        ]);
    }

    private renderFileList = (): React.ReactNode => {
        const { files, deletingFile } = this.state;

        if (!files.length) {
            return null;
        }

        return (
            <div className="file-list" data-id="file-list">
                {files.map((file) => (
                    <FileUploadProgress
                        fileType={file.type}
                        key={file.id}
                        filename={file.name}
                        onCancelUpload={() => this.cancelFileUpload(file.id)}
                        onDeleteUpload={() => this.deleteUploadedFile(file.id)}
                        progress={file.uploadProgress}
                        disableDeletion={deletingFile}
                    />
                ))}
            </div>
        );
    };

    private renderFileUploader = (): React.ReactNode => {
        const { isUploading } = this.state;
        const { existingContractId } = this.props;

        return (
            <WizardFileUploader
                className="form-element-file-input"
                onChangeCallback={this.onFileChange}
                multiple={!existingContractId}
                isUploading={isUploading}
            />
        );
    };

    private renderPartyEditor = (): React.ReactNode => {
        const { parties } = this.state;

        return (
            <div
                className="parties-editor-wrapper"
                data-id="parties-editor-wrapper"
            >
                <DualListEditor
                    firstListTitle="OWN PARTY"
                    secondListTitle="COUNTERPARTY"
                    items={parties.map(this.mapPartyToDualListItem)}
                    onChange={this.onPartiesChange}
                    itemRenderer={this.renderPartyItem}
                    skeletonItems={7}
                    loading={!this.isProcessingCompleted()}
                    ref={this.dualListEditorRef}
                />
                <div className="contract-upload-edit-parties">
                    <Button
                        data-id="edit-parties-button"
                        onClick={this.editParties}
                        disabled={!this.isProcessingCompleted()}
                        startIcon={<Edit />}
                    >
                        Preview Contract & Edit Parties
                    </Button>
                    <Button
                        variant="tertiary"
                        data-id="swap-parties-button"
                        onClick={this.swapParties}
                        disabled={!this.isProcessingCompleted()}
                        startIcon={<Edit />}
                    >
                        Swap Parties
                    </Button>
                </div>
            </div>
        );
    };

    private renderPartyItem = (item: IDualItem): React.ReactNode => {
        const party = item.data as IParty;
        const hideTooltip = this.state.discardedParties.find(
            (discardedParty) => discardedParty.id === party.id
        );

        const deleteIcon = (
            <Delete
                className="party-item-icon discard-icon"
                onClick={() => this.onDiscardParty(party)}
            />
        );

        return (
            <div className="party-item-wrapper">
                <span className="party-item-label">{item.label}</span>
                <Domain
                    className={`party-item-icon formal-party-icon ${
                        party.variantType === PartyVariantType.FORMAL
                            ? 'active'
                            : 'inactive'
                    }`}
                />
                <Tooltip
                    className={`discard-party-tooltip ${
                        hideTooltip ? 'hidden' : ''
                    }`}
                    placement="top"
                    title="Discard Party"
                >
                    {deleteIcon}
                </Tooltip>
            </div>
        );
    };

    private renderStepContent = (stepIndex: number): React.ReactNode => {
        switch (stepIndex) {
            case 0:
                return this.renderStreamsDropdown();
            case 1:
                return (
                    <div
                        className="upload-step-content"
                        data-id="upload-step-content"
                    >
                        {this.renderFileUploader()}
                        {this.renderFileList()}
                    </div>
                );
            case 2:
                return this.renderPartyEditor();
            default:
                return null;
        }
    };

    private renderStepDescription = (stepIndex: number): React.ReactNode => {
        switch (stepIndex) {
            case 0:
                return (
                    <div>
                        Let's start by selecting the type of deal you are
                        looking to review
                    </div>
                );
            case 1:
                return (
                    <div>Now add the file(s) for your selected deal type</div>
                );
            case 2:
                return (
                    <div>
                        Did we identify the correct contracting parties?
                        <br /> Names with the{' '}
                        <Domain className="building-icon" /> next to them are
                        set as formal names
                    </div>
                );
            default:
                return null;
        }
    };

    private renderStreamsDropdown = (): React.ReactNode => {
        const { selectedStream } = this.state;
        const { streams } = this.props;

        const streamOptions = streams.map(formatStreamOption);
        const disabled = streamOptions.length <= 1;

        const dropdown = (
            <Select
                className="streams-dropdown"
                data-testid="upload-modal-streams-dropdown"
                label="Deal Type"
                value={selectedStream}
                options={streamOptions}
                onChange={this.onSelectStream}
                placeholder="Select deal type"
                disabled={disabled}
                multiple={false}
            />
        );

        return disabled ? (
            <Tooltip
                data-id="streams-dropdown-tooltip-wrapper"
                title="You have access to one deal type."
                placement="top"
            >
                <div>{dropdown}</div>
            </Tooltip>
        ) : (
            dropdown
        );
    };

    private resolveDefaultStream = (): IReviewStream | undefined => {
        const { defaultStream, existingStreamId, streams } = this.props;

        let selectedStreamId: string;

        if (existingStreamId) {
            selectedStreamId = existingStreamId;
        } else if (streams.length === 1) {
            selectedStreamId = streams[0].streamId;
        } else if (defaultStream) {
            selectedStreamId = defaultStream.streamId;
        }

        return streams.find((stream) => stream.streamId === selectedStreamId);
    };

    private shouldDisablePrevStep = (stepIndex: number): boolean =>
        stepIndex === 1 && this.allFilesHaveUploaded();

    private shouldEnableNextStep = (stepIndex: number): boolean => {
        const { files, selectedStream } = this.state;

        switch (stepIndex) {
            case 0:
                return !!selectedStream;
            case 1:
                return (
                    this.allFilesHaveUploaded() &&
                    (files.length > 1 || !this.hasFailedProcess())
                );
            case 2:
                return this.isProcessingCompleted();
            default:
                return false;
        }
    };

    private swapParties(): void {
        this.dualListEditorRef.current.swapItems();
    }

    private uploadContract = async (
        filename: string,
        streamCode: string
    ): Promise<string | undefined> => {
        const contractId = getResourceIdFromResponse(
            await ContractResource.postContract(filename, streamCode)
        );

        return contractId;
    };

    private uploadFile = async (
        file: IPreviewFile,
        batchSize: number
    ): Promise<void> => {
        const { selectedStream } = this.state;
        const { openContract, existingContractId } = this.props;

        try {
            // Use existing contract ID when uploading a new version
            // Alternatively, upload contract as new and get its ID
            let contractId = existingContractId;
            if (!contractId) {
                contractId = await this.uploadContract(
                    file.name,
                    selectedStream.value
                );
            }

            // Use open contract if available
            // Else, fetch contract data
            let contract = openContract;
            if (!contract) {
                const contractResponse = await ContractResource.getContract(
                    contractId ?? '',
                    selectedStream.value
                );

                const contractStatus = await fetchContractStatus(
                    contractId ?? ''
                );

                contract = mapContract(
                    contractResponse.data,
                    [],
                    contractStatus[0]
                );
            }

            const version = await this.uploadVersion(
                file,
                contractId ?? '',
                selectedStream.value,
                batchSize
            );

            this.setState({
                uploadedFiles: [
                    ...this.state.uploadedFiles,
                    {
                        contractId: contractId ?? '',
                        fileId: file.id,
                        filename: file.name,
                        versionId: version.id,
                        streamCode: contract.streamCode,
                        streamId: contract.streamId,
                        processStatus: ProcessStatus.PROCESSING,
                    },
                ],
            });

            this.onSuccessfulUpload(contract, version);
        } catch (error: any) {
            if (error?.code !== AxiosError.ERR_CANCELED) {
                toast.error(`Error uploading file "${file.name}"`);
            }
        }
    };

    private uploadVersion = async (
        file: IPreviewFile,
        contractId: string,
        streamCode: string,
        batchSize: number
    ): Promise<IContractVersion> => {
        const { existingContractId } = this.props;

        const payload = new FormData();
        payload.append('document', file.file, file.name);

        if (!existingContractId) {
            payload.append(
                'requirePartyConfirmation',
                batchSize > 1 ? 'false' : 'true'
            );
        }

        const response = await VersionResource.uploadVersion(
            payload,
            contractId,
            streamCode,
            (uploadProgress: ProgressEvent) => {
                this.onUploadProgress(file.id, uploadProgress);
            },
            file.abortController.signal
        );

        const versionId = getResourceIdFromAxiosResponse(response);
        const versionResponse = await VersionResource.getVersion(
            contractId,
            versionId!, // Fixme: null checks
            streamCode
        );
        const versionNumber = versionResponse.data?.attributes?.number ?? 1;
        return mapVersion(versionResponse.data, versionNumber);
    };

    render() {
        const { activeStep, files, stepLabels, submitting, uploadedFiles } =
            this.state;
        const { existingContractId, open, onClose } = this.props;

        if (existingContractId) {
            return (
                <Dialog
                    className="contract-upload-wizard-modal"
                    data-id="contract-upload-wizard-modal"
                    open={open}
                    dialogTitle="Upload a new Version"
                    onClose={onClose}
                    dialogContent={
                        <div
                            className="contract-upload-wizard-modal-content"
                            data-id="contract-upload-wizard-modal-content"
                        >
                            {this.renderStepContent(activeStep)}
                        </div>
                    }
                    dialogActions={
                        <div
                            className="modal-footer contract-upload-wizard-modal-footer"
                            data-id="contract-upload-wizard-modal-footer"
                        >
                            <Button
                                variant="secondary"
                                data-id="cancel-btn"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                data-id="next-btn"
                                disabled={
                                    !this.shouldEnableNextStep(activeStep)
                                }
                                onClick={onClose}
                            >
                                Review
                            </Button>
                        </div>
                    }
                />
            );
        }

        return (
            <>
                <Dialog
                    className="contract-upload-wizard-modal"
                    data-id="contract-upload-wizard-modal"
                    open={open}
                    dialogTitle="Upload a new Negotiation"
                    onClose={onClose}
                    dialogContent={
                        <>
                            <ProgressSteps
                                stepLabels={stepLabels}
                                activeStep={activeStep}
                            />
                            <div
                                className="contract-upload-wizard-modal-content"
                                data-id="contract-upload-wizard-modal-content"
                            >
                                <div
                                    className="contract-upload-wizard-modal-step-description"
                                    data-id="contract-upload-wizard-modal-step-description"
                                >
                                    {this.renderStepDescription(activeStep)}
                                </div>
                                {this.renderStepContent(activeStep)}
                            </div>
                        </>
                    }
                    dialogActions={
                        <div
                            className="contract-upload-wizard-modal-footer"
                            data-id="contract-upload-wizard-modal-footer"
                        >
                            {activeStep === 0 ? (
                                <Button
                                    variant="secondary"
                                    data-id="cancel-btn"
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                            ) : (
                                <Button
                                    variant="secondary"
                                    data-id="back-btn"
                                    disabled={this.shouldDisablePrevStep(
                                        activeStep
                                    )}
                                    onClick={() => this.goToStep(-1)}
                                >
                                    Back
                                </Button>
                            )}
                            <Button
                                data-id="next-btn"
                                disabled={
                                    !this.shouldEnableNextStep(activeStep) ||
                                    submitting
                                }
                                onClick={this.onClickNext}
                            >
                                {activeStep === 2 ||
                                (activeStep === 1 && files.length > 1)
                                    ? 'Review'
                                    : 'Next'}
                            </Button>
                        </div>
                    }
                />
                {activeStep === 2 && uploadedFiles[0] && (
                    <PartyDetectionListener
                        contractFile={uploadedFiles[0]}
                        onPartiesDetected={() =>
                            this.onSuccessfulProcessing(uploadedFiles[0])
                        }
                    />
                )}
            </>
        );
    }
}

export default ContractUploadWizardModal;
