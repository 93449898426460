import { QueryClient } from '@tanstack/react-query';
import { ReportDefinitionOut } from '@thought-river/negotiations-common/dist/api/playbookManager';

//Add a new report definition to the current list of report definitions
export const addToTanStackQueryReportDefinitionsData = (
    queryClient: QueryClient,
    reportDefinition: ReportDefinitionOut
) => {
    queryClient.setQueryData(
        ['report-definitions'],
        (oldData: ReportDefinitionOut[] | undefined) =>
            oldData ? [...oldData, reportDefinition] : oldData
    );
};

//Remove a report definition from the current list of report definitions
export const removeFromTanStackQueryReportDefinitionsData = (
    queryClient: QueryClient,
    reportDefinitionId: string
) => {
    queryClient.setQueryData(
        ['report-definitions'],
        (oldData: ReportDefinitionOut[] | undefined) =>
            oldData
                ? oldData.filter((data) => data.id_str !== reportDefinitionId)
                : oldData
    );
};

//Update a report definition in a list of definitions where query key is ['report-definitions']
//Update a report definition where query key is ['report-definitions', reportDefinition.id]
export const updateTanStackQueryReportDefinitionsData = (
    queryClient: QueryClient,
    reportDefinition: ReportDefinitionOut
) => {
    queryClient.setQueryData(
        ['report-definitions'],
        (oldData: ReportDefinitionOut[] | undefined) => [
            ...(oldData?.map((data) =>
                data.id_str === reportDefinition.id_str
                    ? reportDefinition
                    : data
            ) ?? []),
        ]
    );
    queryClient.setQueryData(
        ['report-definitions', reportDefinition.id_str],
        () => reportDefinition
    );
};
