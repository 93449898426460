import { useAppSelector } from '@modules/common/hooks';
import { Button, Icon } from '@thought-river/ui-components';

import styles from './HistoryButton.module.scss';

interface HistoryButtonProps {
    onClick: () => void;
}

const HistoryButton = ({ onClick }: HistoryButtonProps) => {
    const selectedVersion = useAppSelector(
        (state) => state.contract.selectedVersion
    );

    if (!selectedVersion) {
        return null;
    }

    return (
        <Button
            variant="secondary"
            startIcon={<Icon className={styles.icon} type="restore" />}
            onClick={onClick}
            className={styles.wrapper}
        >
            Version {selectedVersion.versionNumber}
        </Button>
    );
};

export default HistoryButton;
