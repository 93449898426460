import * as React from 'react';

import {
    DropdownTheme,
    IDropdownOption,
    IDropdownProps,
    IDropdownState,
} from './Dropdown-types';
import { ArrowDropDown } from '@mui/icons-material';
import './Dropdown.scss';

class Dropdown<T> extends React.Component<IDropdownProps<T>, IDropdownState> {
    private inputRef: React.RefObject<any>;

    constructor(props: IDropdownProps<T>) {
        super(props);

        this.state = {
            expanded: false,
            searchTerm: '',
            outsideClickListener: false,
        };

        this.filterOptions = this.filterOptions.bind(this);
        this.onClickLabel = this.onClickLabel.bind(this);
        this.onClickOutsideDropdown = this.onClickOutsideDropdown.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSelectOption = this.onSelectOption.bind(this);
        this.onToggleExpanded = this.onToggleExpanded.bind(this);
        this.renderOption = this.renderOption.bind(this);

        this.inputRef = React.createRef();
    }

    async componentDidUpdate(_: IDropdownProps<T>, prevState: IDropdownState) {
        if (!prevState.expanded && this.state.expanded) {
            if (this.props.searchable) {
                this.inputRef.current.focus();
            }

            if (!this.state.outsideClickListener) {
                document.addEventListener(
                    'mousedown',
                    this.onClickOutsideDropdown
                );

                await this.setState({
                    outsideClickListener: true,
                });
            }
        }

        if (prevState.expanded && !this.state.expanded) {
            document.removeEventListener(
                'mousedown',
                this.onClickOutsideDropdown
            );

            this.setState({
                searchTerm: '',
                outsideClickListener: false,
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.onClickOutsideDropdown);
    }

    filterOptions(option: IDropdownOption<T>) {
        return (
            !this.state.searchTerm ||
            option.label
                .toLowerCase()
                .includes(this.state.searchTerm.toLowerCase())
        );
    }

    onClickOutsideDropdown(event: any) {
        if (
            event.target &&
            typeof event.target.className === 'string' &&
            !event.target.className.includes('dropdown')
        ) {
            this.setState({
                expanded: false,
            });
        }
    }

    onClickLabel() {
        if (this.props.disabled) {
            return;
        }

        if (!this.state.expanded) {
            this.setState({
                expanded: true,
            });
        }
    }

    onInputChange(event: any) {
        this.setState({
            searchTerm: event.target.value,
        });
    }

    onToggleExpanded() {
        if (!this.state.expanded && this.props.disabled) {
            return;
        }

        this.setState({
            expanded: !this.state.expanded,
        });
    }

    onSelectOption(option: IDropdownOption<T>) {
        this.setState({
            expanded: false,
        });
        this.props.onSelectCallback(option);
    }

    renderOption(option: IDropdownOption<T>) {
        return (
            <div
                className={`dropdown-option ${
                    option.value ? '' : 'clear-selection'
                }`}
                onClick={() => this.onSelectOption(option)}
                key={`dropdown-option-${option.value}`}
            >
                {option.label}
            </div>
        );
    }

    formatLabel(text: string) {
        if (typeof text === 'string') {
            text = text.trim();
            return text.length >= 27 ? `${text.slice(0, 22).trim()}...` : text;
        } else {
            return text;
        }
    }

    render() {
        const {
            className,
            disabled,
            options,
            placeholder,
            searchPlaceholder,
            selectedOption,
            searchable,
            colourTheme,
        } = this.props;
        const { expanded, searchTerm } = this.state;

        const classes =
            `dropdown ${className ? className : ''}` +
            ` ${disabled ? 'disabled' : ''}` +
            ` ${expanded ? 'expanded' : ''}` +
            ` ${colourTheme ? colourTheme : DropdownTheme.LIGHT}`;

        return (
            <div className={classes}>
                <div className="dropdown-label-wrapper">
                    {expanded && searchable ? (
                        <input
                            className="dropdown-input"
                            onChange={this.onInputChange}
                            value={searchTerm}
                            placeholder={searchPlaceholder}
                            ref={this.inputRef}
                        />
                    ) : (
                        <div
                            className={`dropdown-label ${
                                searchable ? 'searchable' : ''
                            }`}
                            onClick={this.onClickLabel}
                        >
                            {selectedOption && selectedOption.value ? (
                                <span className="dropdown-selected-option">
                                    {this.formatLabel(selectedOption.label)}
                                </span>
                            ) : (
                                <span className="dropdown-placeholder">
                                    {this.formatLabel(placeholder)}
                                </span>
                            )}
                        </div>
                    )}
                    <ArrowDropDown
                        className="dropdown-toggle-icon"
                        onClick={this.onToggleExpanded}
                    />
                </div>
                {expanded && (
                    <div className="dropdown-options">
                        {options
                            .filter(this.filterOptions)
                            .map(this.renderOption)}
                    </div>
                )}
            </div>
        );
    }
}

export default Dropdown;
