import { queryClient } from '@thought-river/negotiations-common';
import { ContractTree } from '@thought-river/negotiations-common/dist/api/contractManagement';
import isEqual from 'lodash/isEqual';
import { useEffect, useRef } from 'react';
import {
    IUploadedFile,
    ProcessStatus,
} from './ContractUploadWizardModal-types';

interface PartyDetectionListenerProps {
    contractFile: IUploadedFile;
    onPartiesDetected: () => void;
}

const PartyDetectionListener = ({
    contractFile,
    onPartiesDetected,
}: PartyDetectionListenerProps) => {
    // Ref indicating whether parent's callback has been called
    // State cannot be used as it is not up-to-date inside the subscribe callback
    const hasNotifiedRef = useRef<boolean>(false);

    useEffect(() => {
        const { contractId, processStatus } = contractFile;

        if (
            processStatus !== ProcessStatus.PROCESSING ||
            hasNotifiedRef.current
        ) {
            return;
        }

        const contractsQueryData = queryClient.getQueryData<ContractTree>([
            'contracts',
        ]);

        if (isAwaitingPartyConfirmation(contractId, contractsQueryData)) {
            onPartiesDetected();
        } else {
            const unsubscribe = queryClient
                .getQueryCache()
                .subscribe((event) => {
                    if (
                        event.type === 'updated' &&
                        event.action.type === 'success' &&
                        isEqual(event.query.queryKey, ['contracts'])
                    ) {
                        const shouldNotify =
                            !hasNotifiedRef.current &&
                            isAwaitingPartyConfirmation(
                                contractId,
                                event.action.data as ContractTree
                            );

                        if (shouldNotify) {
                            onPartiesDetected();
                            hasNotifiedRef.current = true;
                        }
                    }
                });

            return () => unsubscribe();
        }
    }, [contractFile, onPartiesDetected]);

    return null;
};

const isAwaitingPartyConfirmation = (
    contractId: string,
    contractsTreeData?: ContractTree
) => {
    const targetContract = contractsTreeData?.rows.find(
        (row) => row.contract_uuid === contractId
    );
    const partyConfirmationStep = targetContract?.processing_steps?.find(
        (step) => step.name === 'party_confirmation'
    );
    return partyConfirmationStep?.status === 'in_progress';
};

export default PartyDetectionListener;
