import {
    ContractIssue,
    IssueSeverityLevel,
    Ratio,
    getIssueSeverityLevelBadgeType,
    getIssueSeverityLevelLabel,
} from '@thought-river/negotiations-common';
import { formatRatio } from './formatRatio';
import { Badge } from '@thought-river/ui-components';
import { ImportanceTotals } from '../IntelligenceBars.types';
import styles from '../IntelligenceBars.module.scss';

function getSeverityTotals(issues: ContractIssue[]): ImportanceTotals {
    const severityTotals = {
        [IssueSeverityLevel.SHOWSTOPPER]: 0,
        [IssueSeverityLevel.HIGH]: 0,
        [IssueSeverityLevel.MEDIUM]: 0,
        [IssueSeverityLevel.LOW]: 0,
        [IssueSeverityLevel.OK]: 0,
    };

    for (const issue of issues) {
        if (typeof severityTotals[issue.severityLevel] !== 'undefined') {
            severityTotals[issue.severityLevel] += 1;
        }
    }

    return severityTotals;
}

export const getSeverityRatios = (issues: ContractIssue[]) => {
    const totalIssues = issues.reduce(
        (accumulator, currentValue) =>
            currentValue.severityLevel !== IssueSeverityLevel.UNSCORED
                ? accumulator + 1
                : accumulator,
        0
    );
    const importanceTotals = getSeverityTotals(issues);

    const Tooltip = ({ severity }: { severity: IssueSeverityLevel }) => (
        <div className={styles.tooltip}>
            {importanceTotals[severity]} issue
            {importanceTotals[severity] > 1 ? 's' : ''} with
            <Badge
                type={getIssueSeverityLevelBadgeType(severity)}
                label={getIssueSeverityLevelLabel(severity)}
            />
            importance
        </div>
    );

    const ratios: Ratio[] = [
        formatRatio(
            totalIssues,
            importanceTotals[IssueSeverityLevel.OK],
            IssueSeverityLevel.OK,
            <Tooltip severity={IssueSeverityLevel.OK} />
        ),
        formatRatio(
            totalIssues,
            importanceTotals[IssueSeverityLevel.LOW],
            IssueSeverityLevel.LOW,
            <Tooltip severity={IssueSeverityLevel.LOW} />
        ),
        formatRatio(
            totalIssues,
            importanceTotals[IssueSeverityLevel.MEDIUM],
            IssueSeverityLevel.MEDIUM,
            <Tooltip severity={IssueSeverityLevel.MEDIUM} />
        ),
        formatRatio(
            totalIssues,
            importanceTotals[IssueSeverityLevel.HIGH],
            IssueSeverityLevel.HIGH,
            <Tooltip severity={IssueSeverityLevel.HIGH} />
        ),
        formatRatio(
            totalIssues,
            importanceTotals[IssueSeverityLevel.SHOWSTOPPER],
            IssueSeverityLevel.SHOWSTOPPER,
            <Tooltip severity={IssueSeverityLevel.SHOWSTOPPER} />
        ),
    ];

    return ratios;
};
