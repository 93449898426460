import { useAppSelector } from '@modules/common/hooks';
import { TenantUsersOut } from '@thought-river/negotiations-common/dist/api/landlord';
import { ListItem } from '../ListItem';

interface UserInfoListProps {
    usersInfo: TenantUsersOut;
}
const UserInfoList = ({ usersInfo }: UserInfoListProps) => {
    const accountCode = useAppSelector((state) => state.auth.accountCode);

    const currentTennantUserInfo = usersInfo.find(
        (info) => info.tenant.account_code === accountCode
    );

    return (
        <>
            <ListItem name="Id" value={currentTennantUserInfo?.id} />
            <ListItem
                name="Name"
                value={`${currentTennantUserInfo?.first_name} ${currentTennantUserInfo?.last_name}`}
            />
            <ListItem
                name="Email"
                value={currentTennantUserInfo?.email_address}
            />
            <ListItem name="Auth ID" value={currentTennantUserInfo?.auth0_Id} />
            <ListItem
                name="Has API keys"
                value={currentTennantUserInfo?.has_api_keys.toString()}
            />
            <ListItem
                name="Deal types"
                value={currentTennantUserInfo?.deal_types
                    .map((d) => d.uuid)
                    .join(', ')}
            />
            <ListItem
                name="Roles"
                value={currentTennantUserInfo?.roles
                    .map((r) => r.name)
                    .join(', ')}
            />
        </>
    );
};

export default UserInfoList;
