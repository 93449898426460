import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Badge } from '@thought-river/ui-components';
import {
    getIssueSeverityLevelBadgeType,
    getIssueSeverityLevelLabel,
} from '@thought-river/negotiations-common';
import DOMPurify from 'dompurify';
import LinkedClausesCell from './LinkedClausesCell';
import styles from './Cell.module.scss';
import { extractColumnField } from '@modules/reports/common/helpers/extractColumnField';

const Cell = (params: GridRenderCellParams) => {
    const { formattedValue, value, colDef } = params;
    const field = extractColumnField(params.field);

    /*Return an empty element in the group row cells except the cell of the grouped field*/
    if (
        params.rowNode.type === 'group' &&
        params.rowNode.groupingField !== field
    ) {
        return <></>;
    }

    if (field === 'issue_severity_level') {
        if (!value) {
            return '-';
        }
        const badgeType = getIssueSeverityLevelBadgeType(value.toLowerCase());

        return (
            <Badge
                type={badgeType}
                label={getIssueSeverityLevelLabel(value.toLowerCase())}
            />
        );
    }

    if (field === 'property_linked_clauses') {
        return <LinkedClausesCell {...params} />;
    }

    if (colDef.type === 'html') {
        if (!value) {
            return '-';
        }
        return (
            <div
                className={styles.htmlContent}
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(value),
                }}
            />
        );
    }

    return formattedValue || value;
};

export default Cell;
