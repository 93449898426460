import { IRootState } from '../../../redux/reducers-types';
import { reduxStore } from '../../../redux/store';
import { analyticsService } from '../../../services/Analytics/Analytics';
import { EVENT_ACTION_INTELLIGENCE_TAB_IN_ISSUE_CARD } from '../../../services/Analytics/Analytics-types';
import { IssuePaneTab } from '../IssuePaneTabs/IssuePaneTabs-types';

export function analytics() {
    return (target: any) => {
        const handleTabChange = target.prototype.handleTabChange;

        target.prototype.handleTabChange = function (
            _: React.SyntheticEvent,
            newTab: IssuePaneTab
        ) {
            const state = reduxStore.store.getState() as IRootState;

            if (newTab === IssuePaneTab.INTELLIGENCE) {
                analyticsService.recordEvent(
                    EVENT_ACTION_INTELLIGENCE_TAB_IN_ISSUE_CARD,

                    {
                        ContractId: state.contract.contract?.id,
                        VersionId: state.contract.selectedVersion?.id,
                        VersionNumber:
                            state.contract.selectedVersion?.versionNumber,
                    }
                );
            }
            handleTabChange.apply(this, arguments);
        };
    };
}
