import { createRef } from 'react';
import * as React from 'react';
import { CheckCircle, FileCopy } from '@mui/icons-material';
import DOMPurify from 'dompurify';

import {
    IParagraphSuggestionProps,
    IParagraphSuggestionState,
} from './ParagraphSuggestion-types';

import './ParagraphSuggestion.scss';
import {
    copyHtmlElementBySelector,
    copyRteContent,
} from '../../../../../App/App-helpers';
import { analytics } from './ParagraphSuggestion-analytics';
import RichTextEditor from '../../../../../RichTextEditor/RichTextEditor';
import CopyableElement from '../../../../../CopyableElement/CopyableElement';
import CustomButton from '../../../../../CustomButton/CustomButton';
import { RadioButton, Tooltip } from '@thought-river/ui-components';

@analytics()
export default class ParagraphSuggestion extends React.Component<
    IParagraphSuggestionProps,
    IParagraphSuggestionState
> {
    private editorRef: React.RefObject<any>;

    constructor(props: IParagraphSuggestionProps) {
        super(props);

        this.state = {
            suggestionText: props.suggestion,
            suggestionSelection: '',
        };

        this.editorRef = createRef();
    }

    private getCopyElementId() {
        const { suggestionIndex } = this.props;
        return `suggestion-${suggestionIndex}`;
    }

    private onCopySuggestion = (event: React.MouseEvent) => {
        event.stopPropagation();

        const { editing } = this.props;

        if (editing) {
            copyRteContent(
                '[data-contents="true"]',
                this.editorRef?.current.isFocused()
            );
        } else {
            copyHtmlElementBySelector(this.getCopyElementId());
        }
    };

    private onRteSuggestionChange = (value: string) => {
        this.setState({
            suggestionText: value,
        });
    };

    render() {
        const {
            editing,
            isSelected,
            onClick,
            onSuggestionSelected,
            suggestionIndex,
        } = this.props;

        const { suggestionText, suggestionSelection } = this.state;

        return (
            <div className="paragraph-suggestion">
                <div className="suggestion-box">
                    <div className="suggestion-box-label">
                        <Tooltip title="Select to display in Contract Report">
                            <RadioButton
                                className="suggestion-radio-button"
                                checked={isSelected}
                                onChange={onSuggestionSelected}
                            />
                        </Tooltip>
                        <span>Drafting option {suggestionIndex + 1}</span>
                    </div>
                    {editing ? (
                        <RichTextEditor
                            dynamicHeight
                            editing
                            inlineToolbar
                            ref={this.editorRef}
                            onChange={this.onRteSuggestionChange}
                            onSelection={(selection: string) => {
                                this.setState({
                                    suggestionSelection: selection,
                                });
                            }}
                            placeholder="Drafting option"
                            value={suggestionText}
                            showToolbarOnFocus
                            autoFocus
                        />
                    ) : (
                        <p
                            className="suggestion-text"
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(suggestionText),
                            }}
                            onClick={() => {
                                onClick?.(suggestionIndex);
                            }}
                        />
                    )}
                </div>
                <div
                    className="copy-button-wrapper"
                    onMouseDown={(event) => event.preventDefault()}
                >
                    <CustomButton
                        animatedButton
                        callback={(event) => this.onCopySuggestion(event)}
                        iconBefore={<FileCopy />}
                        iconAfter={<CheckCircle />}
                        textBefore="Copy"
                        textAfter="Copied"
                    />
                </div>
                <CopyableElement
                    id={this.getCopyElementId()}
                    content={suggestionSelection || suggestionText}
                />
            </div>
        );
    }
}
