import { useEffect } from 'react';
import { useGridApiContext } from '@mui/x-data-grid-pro';

import LinkedClauses from '../../../LinkedClauses/LinkedClauses';
import { IEditLinkedClausesDropdownProps } from './EditLinkedClausesDropdown-types';
import { IContractReportRow } from '../HybridReportDataGrid-types';
import './EditLinkedClausesDropdown.scss';
import { goToNextCell } from '../HybridReportDataGrid-helpers';
import { ParagraphClause } from '@thought-river/negotiations-common';

const EditLinkedClausesDropdown = (
    props: IEditLinkedClausesDropdownProps
): JSX.Element => {
    const { id, field, value, paragraphs } = props;

    const apiRef = useGridApiContext();

    const row = value as IContractReportRow;

    const onKeyDown = async (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault();

            goToNextCell(apiRef, props);
            return;
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    });

    const handleValueChange = (clauseNumbers: ParagraphClause[]) => {
        const newRow = {
            ...row,
            clauseNumbers,
            paragraphOriginUuids: clauseNumbers.map(
                (clause) => clause.originUuid
            ),
            linkedParagraphClauses: clauseNumbers,
        };

        apiRef.current.setEditCellValue({
            id,
            field,
            value: newRow,
        });
    };

    return row.clausesEditable ? (
        <LinkedClauses
            onSelectOption={handleValueChange}
            onCloseCallback={undefined}
            paragraphs={paragraphs}
            selectedClauseNumbers={row.linkedParagraphClauses}
        />
    ) : (
        <span className="linked-clauses-disabled-msg">
            Please link/unlink clauses for this Lexible Property from the
            contract summary panel.
        </span>
    );
};

export default EditLinkedClausesDropdown;
