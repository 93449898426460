import Api from '../services/api';
import { Method, requestOptions } from '../services/api-types';
import {
    IIssueRequestPayload,
    IIssueResource,
    IIssueResourceStatic,
} from './IssueResource-types';
import { IIssue, IIssues } from 'types/thoughtriver';

const IssueResource: IIssueResourceStatic = class IssueResource
    implements IIssueResource
{
    static async createIssue(
        contractId: string,
        versionId: string,
        stream: string,
        payload: IIssueRequestPayload
    ): Promise<Response> {
        const url = `/contracts/${contractId}/versions/${versionId}/issues`;

        const options = {
            ...requestOptions,
            streamCode: stream,
            payload,
            noRedirect: true,
        };

        return (await Api.request(Method.POST, url, options))!;
    }

    static async deleteIssue(
        contractId: string,
        versionId: string,
        issueId: string,
        stream: string
    ): Promise<void> {
        const url = `/contracts/${contractId}/versions/${versionId}/issues/${issueId}`;

        const options = {
            ...requestOptions,
            streamCode: stream,
        };

        return await (await Api.request(Method.DELETE, url, options))!.json();
    }

    static async getIssue(
        contractId: string,
        versionId: string,
        issueId: string,
        stream: string
    ): Promise<IIssue> {
        const url = `/contracts/${contractId}/versions/${versionId}/issues/${issueId}`;

        const options = {
            ...requestOptions,
            streamCode: stream,
        };

        return await (await Api.request(Method.GET, url, options))!.json();
    }

    static async getIssues(
        contractId: string,
        versionId: string,
        stream: string
    ): Promise<IIssues> {
        const url = `/contracts/${contractId}/versions/${versionId}/issues`;

        const options = {
            ...requestOptions,
            streamCode: stream,
        };

        return await (await Api.request(Method.GET, url, options))!.json();
    }

    static async updateIssue(
        contractId: string,
        versionId: string,
        issueId: string,
        stream: string,
        payload: IIssueRequestPayload,
        referenceComponent: string
    ): Promise<Response> {
        const url = `/contracts/${contractId}/versions/${versionId}/issues/${issueId}`;

        const options = {
            ...requestOptions,
            streamCode: stream,
            payload,
            headers: {
                ...requestOptions.headers,
                'x-reference-component': referenceComponent,
            },
        };

        return (await Api.request(Method.PATCH, url, options))!;
    }
};

export default IssueResource;
