import { connect } from 'react-redux';
import { IRootState } from '../../../redux/reducers-types';
import HybridReportCopyableTable from './HybridReportCopyableTable';

import { IHybridReportCopyableTableStateProps } from './HybridReportCopyableTable-types';

function mapStateToProps(
    state: IRootState
): IHybridReportCopyableTableStateProps {
    return {
        copyingTable: state.issuesReport.copyingTable,
        paragraphs: state.contract.paragraphs,
    };
}

export default connect(mapStateToProps)(HybridReportCopyableTable);
