import { IProperties } from 'types/thoughtriver';

// @ts-ignore
export const mockProperties: IProperties = {
    data: [
        {
            type: 'properties',
            id: 'd1b178b8-a161-11e9-9192-bc764e08161d',
            attributes: {
                'autodetected-value': '',
                name: 'Counterparty identity',
                code: 'cp-identity',
                value: '2003-2018 Aspose Pty Ltd',
                confidence: 0.55000000000000004,
                recommendation: '',
                'help-text': 'None provided',
                description:
                    'Does the agreement specify who is the counterparty to the contract?',
                'training-level': 'entity',
                'is-polarity-dependent': true,
                theme: 'Counterparties',
                'sub-theme': 'Counterparty',
                'aggregate-model-input-hash': {
                    algorithm: 'md5',
                    value: 'some-hash',
                },
                'is-rules-based': false,
                'short-label': '(cp) identity',
                'user-value': '',
                'value-type-id': 1,
            },
            relationships: {
                assessment: {
                    data: {
                        type: 'assessments',
                        id: 'd119dc02-a161-11e9-9192-bc764e08161d',
                    },
                    // tslint:disable-next-line:max-line-length
                    links: {
                        related:
                            '/contracts/3837eb7c-8b7a-11e9-9b58-bc764e085a18/versions/3892d5a0-8b7a-11e9-a530-bc764e085a18/assessments/d119dc02-a161-11e9-9192-bc764e08161d',
                    },
                },
                paragraphs: {
                    data: [
                        {
                            type: 'paragraphs',
                            id: '3bb77dee-8b7a-11e9-b6e7-bc764e085a18',
                            meta: {
                                index: 15,
                                'content-hash': {
                                    algorithm: 'md5',
                                    value: '6d6bb01b0a00a68bd2d06b28e63bbe82',
                                },
                                'augmented-content-hash': {
                                    algorithm: 'md5',
                                    value: '6d6bb01b0a00a68bd2d06b28e63bbe82',
                                },
                            },
                        },
                        {
                            type: 'paragraphs',
                            id: '16da68b8-2e5f-11eb-80e5-ae8f3868b755',
                            meta: {
                                index: 47,
                                'content-hash': {
                                    algorithm: 'md5',
                                    value: 'b5aa89cdbe0eb56012492bd2aee1ec05',
                                },
                                'augmented-content-hash': {
                                    algorithm: 'md5',
                                    value: 'b5aa89cdbe0eb56012492bd2aee1ec05',
                                },
                            },
                        },
                    ],
                    // @ts-ignore
                    // tslint:disable-next-line:max-line-length
                    links: {
                        related:
                            '/contracts/3837eb7c-8b7a-11e9-9b58-bc764e085a18/versions/3892d5a0-8b7a-11e9-a530-bc764e085a18/assessments/d119dc02-a161-11e9-9192-bc764e08161d/properties/d1b178b8-a161-11e9-9192-bc764e08161d/paragraphs',
                    },
                },
                risk: {
                    data: {
                        id: '5554960e-9237-4064-ba06-3c55fd998769',
                        type: 'risk',
                        meta: {
                            'risk-score': 'High',
                            // tslint:disable-next-line:max-line-length
                            advice: 'The omission of a clause addressing termination for cause is a potential risk as it could deprive the parties of a right to terminate in circumstances that are not covered by common law.',
                            label: 'Clause addressing termination for cause ommitted',
                        },
                    },
                },
                theme: {
                    data: {
                        type: 'themes',
                        id: 'd13567bb-a161-11e9-9192-bc764e08161d',
                    },
                    // tslint:disable-next-line:max-line-length
                    links: {
                        related:
                            '/contracts/3837eb7c-8b7a-11e9-9b58-bc764e085a18/versions/3892d5a0-8b7a-11e9-a530-bc764e085a18/assessments/d119dc02-a161-11e9-9192-bc764e08161d/themes',
                    },
                },
                'lexible-property': {
                    data: {
                        type: 'lexible-properties',
                        id: 'd119dc02-a161-11e9-9192-bc764e08162b',
                    },
                },
                'related-properties': {
                    data: [],
                },
                feedbacks: {
                    data: [
                        {
                            id: '5554960e-9237-4064-ba06-3c55fd998769',
                            type: 'feedbacks',
                            meta: {
                                vote: 1,
                                userId: '5554960e-9237-4064-ba06-3c55fd998769',
                            },
                        },
                    ],
                    links: {
                        self: '/contracts/5554960e-9237-4064-ba06-3c55fd998769/versions/5554960e-9237-4064-ba06-3c55fd998769/assessments/5554960e-9237-4064-ba06-3c55fd998769/properties/5554960e-9237-4064-ba06-3c55fd998769/paragraphs',
                    },
                },
            },
            // tslint:disable-next-line:max-line-length
            links: {
                self: '/contracts/3837eb7c-8b7a-11e9-9b58-bc764e085a18/versions/3892d5a0-8b7a-11e9-a530-bc764e085a18/assessments/d119dc02-a161-11e9-9192-bc764e08161d/properties/d1b178b8-a161-11e9-9192-bc764e08161d',
            },
        },
        {
            type: 'properties',
            id: 'd1af1381-a161-11e9-9192-bc764e08161d',
            attributes: {
                'autodetected-value': '',
                name: 'Contract type',
                code: 'contract-type',
                value: 'Commercial Agreement',
                confidence: 0.45,
                recommendation: '',
                'help-text':
                    'Choose the contract type that best describes this agreement',
                description: 'Does the agreement specify the contract type?',
                'training-level': 'contract',
                'is-polarity-dependent': false,
                theme: 'General configuration',
                'sub-theme': 'Contract type',
                'aggregate-model-input-hash': {
                    algorithm: 'md5',
                    value: 'some-hash-2',
                },
                'is-rules-based': false,
                'short-label': 'Contract Type',
                'user-value': '',
                'value-type-id': 1,
            },
            relationships: {
                assessment: {
                    data: {
                        type: 'assessments',
                        id: 'd119dc02-a161-11e9-9192-bc764e08161d',
                    },
                    // tslint:disable-next-line:max-line-length
                    links: {
                        related:
                            '/contracts/3837eb7c-8b7a-11e9-9b58-bc764e085a18/versions/3892d5a0-8b7a-11e9-a530-bc764e085a18/assessments/d119dc02-a161-11e9-9192-bc764e08161d',
                    },
                },
                paragraphs: {
                    data: [
                        {
                            type: 'paragraphs',
                            id: '3bb77dee-8b7a-11e9-b6e7-bc764e085a18',
                            meta: {
                                index: 52,
                                'content-hash': {
                                    algorithm: 'md5',
                                    value: '13cc343b4ee79079046a89243fced081',
                                },
                                'augmented-content-hash': {
                                    algorithm: 'md5',
                                    value: '13cc343b4ee79079046a89243fced081',
                                },
                            },
                        },
                    ],
                    // @ts-ignore
                    // tslint:disable-next-line:max-line-length
                    links: {
                        related:
                            '/contracts/3837eb7c-8b7a-11e9-9b58-bc764e085a18/versions/3892d5a0-8b7a-11e9-a530-bc764e085a18/assessments/d119dc02-a161-11e9-9192-bc764e08161d/properties/d1af1381-a161-11e9-9192-bc764e08161d/paragraphs',
                    },
                },
                risk: {
                    data: {
                        id: '5554960e-9237-4064-ba06-3c55fd998769',
                        type: 'risk',
                        meta: {
                            'risk-score': 'OK',
                            // tslint:disable-next-line:max-line-length
                            advice: 'The omission of a clause addressing termination for cause is a potential risk as it could deprive the parties of a right to terminate in circumstances that are not covered by common law. For example, if the other party becomes insolvent. It is advisable to renegotiate the contract in order to include a clause that addresses specific circumstances that allow the parties to terminate.',
                            label: 'Clause addressing termination for cause ommitted',
                        },
                    },
                },
                theme: {
                    data: {
                        type: 'themes',
                        id: 'e7760825-a311-4d82-b423-16a2528f4d72',
                    },
                    // tslint:disable-next-line:max-line-length
                    links: {
                        related:
                            '/contracts/3837eb7c-8b7a-11e9-9b58-bc764e085a18/versions/3892d5a0-8b7a-11e9-a530-bc764e085a18/assessments/d119dc02-a161-11e9-9192-bc764e08161d/themes',
                    },
                },
                'lexible-property': {
                    data: {
                        type: 'lexible-properties',
                        id: 'd119dc02-a161-11e9-9192-bc764e08162b',
                    },
                },
                'related-properties': {
                    data: [
                        {
                            id: '3fa283ea-12ce-402b-87db-d06105091858',
                            type: 'lexible-properties',
                            meta: {
                                question:
                                    'Does the agreement specify that we have the right to additional compensation in the event of regulatory change?',
                                dfcode: 'op-riskr-reg-change-conseq-rights-compens',
                                answer: '',
                                'property-id': null!,
                            },
                        },
                        {
                            id: '0408780b-0ee3-4cf6-b588-13862b13ef88',
                            type: 'lexible-properties',
                            meta: {
                                question:
                                    'Does the agreement specify that we have the right to additional compensation in the event of regulatory change?',
                                dfcode: 'op-riskr-reg-change-conseq-rights-compens',
                                answer: '',
                                'property-id': null!,
                            },
                        },
                        {
                            id: 'c3d4e328-b532-46a9-b14c-afe969ed9638',
                            type: 'lexible-properties',
                            meta: {
                                question:
                                    'Does the agreement specify that we have the right to additional compensation in the event of regulatory change?',
                                dfcode: 'op-riskr-reg-change-conseq-rights-compens',
                                answer: '',
                                'property-id': null!,
                            },
                        },
                    ],
                },
                feedbacks: {
                    data: [
                        {
                            id: '5554960e-9237-4064-ba06-3c55fd998769',
                            type: 'feedbacks',
                            meta: {
                                vote: 1,
                                userId: '5554960e-9237-4064-ba06-3c55fd998769',
                            },
                        },
                    ],
                    links: {
                        self: '/contracts/5554960e-9237-4064-ba06-3c55fd998769/versions/5554960e-9237-4064-ba06-3c55fd998769/assessments/5554960e-9237-4064-ba06-3c55fd998769/properties/5554960e-9237-4064-ba06-3c55fd998769/paragraphs',
                    },
                },
            },
            // tslint:disable-next-line:max-line-length
            links: {
                self: '/contracts/3837eb7c-8b7a-11e9-9b58-bc764e085a18/versions/3892d5a0-8b7a-11e9-a530-bc764e085a18/assessments/d119dc02-a161-11e9-9192-bc764e08161d/properties/d1af1381-a161-11e9-9192-bc764e08161d',
            },
        },
        {
            type: 'properties',
            id: '0d9630a8-a647-11ea-922e-1eee4229d047',
            attributes: {
                'autodetected-value': '',
                name: 'Contract Role',
                code: 'contract-role',
                value: '0',
                confidence: 0.55000000000000004,
                recommendation: '',
                // tslint:disable-next-line:max-line-length
                'help-text':
                    'This determines which side of the deal we will be operating on e.g. buyer or seller, customer or supplier etc',
                description:
                    'Does the agreement specify the role we are performing?',
                'training-level': 'contract',
                'is-polarity-dependent': false,
                theme: 'General configuration',
                'sub-theme': 'Contract type',
                'aggregate-model-input-hash': null!,
                'is-rules-based': false,
                'short-label': 'Contract Role',
                'user-value': '',
                'value-type-id': 1,
            },
            relationships: {
                assessment: {
                    data: {
                        type: 'assessments',
                        id: 'd119dc02-a161-11e9-9192-bc764e08161d',
                    },
                    // tslint:disable-next-line:max-line-length
                    links: {
                        related:
                            '/contracts/3837eb7c-8b7a-11e9-9b58-bc764e085a18/versions/3892d5a0-8b7a-11e9-a530-bc764e085a18/assessments/d119dc02-a161-11e9-9192-bc764e08161d',
                    },
                },
                paragraphs: {
                    data: [],
                    // @ts-ignore
                    // tslint:disable-next-line:max-line-length
                    links: {
                        related:
                            '/contracts/3837eb7c-8b7a-11e9-9b58-bc764e085a18/versions/3892d5a0-8b7a-11e9-a530-bc764e085a18/assessments/d119dc02-a161-11e9-9192-bc764e08161d/properties/d1af158a-a161-11e9-9192-bc764e08161d/paragraphs',
                    },
                },
                risk: {
                    data: {
                        id: '5554960e-9237-4064-ba06-3c55fd998769',
                        type: 'risk',
                        meta: {
                            'risk-score': 'Medium',
                            // tslint:disable-next-line:max-line-length
                            advice: 'Some salient advice for this risk.',
                            label: 'Some other clause',
                        },
                    },
                },
                theme: {
                    data: {
                        type: 'themes',
                        id: 'd13567bb-a161-11e9-9192-bc764e08161d',
                    },
                    // tslint:disable-next-line:max-line-length
                    links: {
                        related:
                            '/contracts/3837eb7c-8b7a-11e9-9b58-bc764e085a18/versions/3892d5a0-8b7a-11e9-a530-bc764e085a18/assessments/d119dc02-a161-11e9-9192-bc764e08161d/themes',
                    },
                },
                'lexible-property': {
                    data: {
                        type: 'lexible-properties',
                        id: 'd119dc02-a161-11e9-9192-bc764e08162b',
                    },
                },
                'related-properties': {
                    data: [],
                },
                feedbacks: {
                    data: [
                        {
                            id: '5554960e-9237-4064-ba06-3c55fd998769',
                            type: 'feedbacks',
                            meta: {
                                vote: 1,
                                userId: '5554960e-9237-4064-ba06-3c55fd998769',
                            },
                        },
                    ],
                    links: {
                        self: '/contracts/5554960e-9237-4064-ba06-3c55fd998769/versions/5554960e-9237-4064-ba06-3c55fd998769/assessments/5554960e-9237-4064-ba06-3c55fd998769/properties/5554960e-9237-4064-ba06-3c55fd998769/paragraphs',
                    },
                },
            },
            // tslint:disable-next-line:max-line-length
            links: {
                self: '/contracts/3837eb7c-8b7a-11e9-9b58-bc764e085a18/versions/3892d5a0-8b7a-11e9-a530-bc764e085a18/assessments/d119dc02-a161-11e9-9192-bc764e08161d/properties/d1af158a-a161-11e9-9192-bc764e08161d',
            },
        },
        {
            type: 'properties',
            id: 'd1af1789-a161-11e9-9192-bc764e08161d',
            attributes: {
                'autodetected-value': '',
                name: 'Contract interpretation law',
                code: 'contract-interpretation-law',
                value: 'New York',
                confidence: 0.55000000000000004,
                recommendation: '',
                // tslint:disable-next-line:max-line-length
                'help-text':
                    "What is the country law that determines how a contract is to be interpreted and whether its provisions are enforceable and lawful?  This is important as the same contract might have different meanings depending on which country's law is used to interpret the provisions.",
                // tslint:disable-next-line:max-line-length
                description:
                    'If the agreement specifies the choice of law that will govern it, what is that choice of law?',
                'training-level': 'entity',
                'is-polarity-dependent': false,
                theme: 'Jurisdiction & Governing Law',
                'sub-theme': 'Governing Law',
                'aggregate-model-input-hash': {
                    algorithm: 'md5',
                    value: 'some-hash-3',
                },
                'is-rules-based': false,
                'short-label': 'Interpretation Law',
                'user-value': '',
                'value-type-id': 8,
            },
            relationships: {
                assessment: {
                    data: {
                        type: 'assessments',
                        id: 'd119dc02-a161-11e9-9192-bc764e08161d',
                    },
                    // tslint:disable-next-line:max-line-length
                    links: {
                        related:
                            '/contracts/3837eb7c-8b7a-11e9-9b58-bc764e085a18/versions/3892d5a0-8b7a-11e9-a530-bc764e085a18/assessments/d119dc02-a161-11e9-9192-bc764e08161d',
                    },
                },
                paragraphs: {
                    data: [
                        {
                            type: 'paragraphs',
                            id: '16da68b8-2e5f-11eb-80e5-ae8f3868b755',
                            meta: {
                                index: 1,
                                'content-hash': {
                                    algorithm: 'md5',
                                    value: '1f230c630625ef0f9f665a010f9a6fb8',
                                },
                                'augmented-content-hash': {
                                    algorithm: 'md5',
                                    value: '1f230c630625ef0f9f665a010f9a6fb8',
                                },
                            },
                        },
                    ],
                    // @ts-ignore
                    // tslint:disable-next-line:max-line-length
                    links: {
                        related:
                            '/contracts/3837eb7c-8b7a-11e9-9b58-bc764e085a18/versions/3892d5a0-8b7a-11e9-a530-bc764e085a18/assessments/d119dc02-a161-11e9-9192-bc764e08161d/properties/d1af1789-a161-11e9-9192-bc764e08161d/paragraphs',
                    },
                },
                risk: {
                    data: {
                        id: '5554960e-9237-4064-ba06-3c55fd998769',
                        type: 'risk',
                        meta: {
                            'risk-score': 'Low',
                            // tslint:disable-next-line:max-line-length
                            advice: 'The omission of a clause addressing termination for cause is a potential risk as it could deprive the parties of a right to terminate in circumstances that are not covered by common law. For example, if the other party becomes insolvent. It is advisable to renegotiate the contract in order to include a clause that addresses specific circumstances that allow the parties to terminate.',
                            label: 'Clause addressing termination for cause ommitted',
                        },
                    },
                },
                theme: {
                    data: {
                        type: 'themes',
                        id: 'd13567bb-a161-11e9-9192-bc764e08161d',
                    },
                    // tslint:disable-next-line:max-line-length
                    links: {
                        related:
                            '/contracts/3837eb7c-8b7a-11e9-9b58-bc764e085a18/versions/3892d5a0-8b7a-11e9-a530-bc764e085a18/assessments/d119dc02-a161-11e9-9192-bc764e08161d/themes',
                    },
                },
                'lexible-property': {
                    data: {
                        type: 'lexible-properties',
                        id: 'd119dc02-a161-11e9-9192-bc764e08162b',
                    },
                },
                'related-properties': {
                    data: [],
                },
                feedbacks: {
                    data: [],
                    links: {
                        self: '/contracts/5554960e-9237-4064-ba06-3c55fd998769/versions/5554960e-9237-4064-ba06-3c55fd998769/assessments/5554960e-9237-4064-ba06-3c55fd998769/properties/5554960e-9237-4064-ba06-3c55fd998769/paragraphs',
                    },
                },
            },
            // tslint:disable-next-line:max-line-length
            links: {
                self: '/contracts/3837eb7c-8b7a-11e9-9b58-bc764e085a18/versions/3892d5a0-8b7a-11e9-a530-bc764e085a18/assessments/d119dc02-a161-11e9-9192-bc764e08161d/properties/d1af1789-a161-11e9-9192-bc764e08161d',
            },
        },
    ],
};
