import { RouteComponentProps } from 'react-router-dom';
import { Action } from 'redux';
import { IContractVersion, IDisplayContract } from '../Contract/Contract-types';
import { IFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import { IModalDialogButton } from '../ModalDialog/ModalDialog-types';

import { IContractParagraph } from '../Paragraph/Paragraph-types';
import { IParty } from '../Parties/Parties-types';

export const SET_PARTY_CORRECTION_STATUS = 'SET_PARTY_CORRECTION_STATUS';
export const SET_PARTY_CONTRACT_ID = 'SET_PARTY_CONTRACT_ID';
export const SET_PARTY_VERSION_ID = 'SET_PARTY_VERSION_ID';
export const SET_HIGHLIGHTING = 'SET_HIGHLIGHTING';

export const PAGE_TITLE_PARTY_CORRECTION =
    'ThoughtRiver Negotiations - Party Correction';

export interface IPartyCorrectionState {
    contract: IDisplayContract;
    version: IContractVersion;
    submitting: boolean;
}

export interface IPartyCorrectionStateProps {
    contractId: string;
    parties: IParty[];
    localParties: IParty[];
    status: PartyCorrectionStatus;
    statusMessage: string;
    userId: string;
    versionId: string;
    featureToggles: IFeatureToggle[];
}

export enum PartyCorrectionStatus {
    COMPLETED = 'completed',
    ERROR = 'error',
    LOADED = 'loaded',
    LOADING = 'loading',
}

export interface IPartyCorrectionReducer {
    contractId: string;
    highlighting: boolean;
    status: PartyCorrectionStatus;
    statusMessage: string;
    versionId: string;
}

export interface IPartyCorrectionDispatchProps {
    hideDialog: () => void;
    resetSelections: () => void;
    setLocalParties: (parties: IParty[]) => void;
    setPartyCorrectionStatus: (
        status: PartyCorrectionStatus,
        statusMessage?: string
    ) => void;
    setContractId: (contractId: string) => void;
    setDraggedParty: (party: IParty) => void;
    setParagraphs: (paragraphs: IContractParagraph[]) => void;
    setParties: (parties: IParty[]) => void;
    setVersionId: (versionId: string) => void;
    setReprocessingContract: (
        contractId: string,
        contractName: string,
        versionId: string,
        stream: string,
        streamId: string,
        assessmentCount: number,
        userId: string
    ) => void;
    setHighlighting: (highlighting: boolean) => void;
    showDialog: (
        title: string | JSX.Element,
        showCloseButton: boolean,
        component: JSX.Element,
        buttons: IModalDialogButton[],
        height: string
    ) => void;
}

export interface IPartyCorrectionProps
    extends IPartyCorrectionDispatchProps,
        IPartyCorrectionStateProps,
        RouteComponentProps {}

export interface ISetPartyContractIdAction extends Action {
    type: typeof SET_PARTY_CONTRACT_ID;
    contractId: string;
}

export interface ISetPartyVersionIdAction extends Action {
    type: typeof SET_PARTY_VERSION_ID;
    versionId: string;
}

export interface ISetPartyCorrectionStatusAction extends Action {
    type: typeof SET_PARTY_CORRECTION_STATUS;
    status: PartyCorrectionStatus;
    statusMessage: string;
}

export interface ISetPartyCorrectionStatusAction extends Action {
    type: typeof SET_PARTY_CORRECTION_STATUS;
    status: PartyCorrectionStatus;
    statusMessage: string;
}

export interface ISetPartyCorrectionStatusAction extends Action {
    type: typeof SET_PARTY_CORRECTION_STATUS;
    status: PartyCorrectionStatus;
    statusMessage: string;
}

export interface ISetHighlightingAction extends Action {
    type: typeof SET_HIGHLIGHTING;
    highlighting: boolean;
}

export type IPartyCorrectionActionTypes =
    | ISetPartyContractIdAction
    | ISetPartyVersionIdAction
    | ISetPartyCorrectionStatusAction
    | ISetHighlightingAction;

export interface IPartyCorrectionUrlParams {
    contractId: string;
}
