import { GridRowId } from '@mui/x-data-grid-premium';
import styles from './ContractsTree.module.scss';
import { Button, LoadingDataGrid } from '@thought-river/ui-components';
import { Add, Gesture, Replay } from '@mui/icons-material';
import ContractUploadWizardModal from '../../components/ContractUploadWizardModal/ContractUploadWizardModal-container';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ContractsDataGrid } from './components';
import {
    ContractTree,
    useGetContractsV10,
} from '@thought-river/negotiations-common/dist/api/contractManagement';
import ContractResource from '../../resources/ContractResource';
import { usePostSignContract } from '@thought-river/negotiations-common/dist/api/contractContent';
import { useAppSelector } from '@modules/common/hooks';
import BulkActions from './components/BulkActions';
import { FEATURE_TOGGLE_PROCESSING_STEPS_IN_UPLOAD_MODAL } from '../../components/FeatureToggleProvider/FeatureToggleProvider-types';
import ContractUploadWizardModalWithProcessingSteps from '../../components/ContractUploadWizardModal/ContractUploadWizardModalWithProcessingSteps-container';
import { useLongPoll } from './hooks/useLongPoll';

const ContractsTree = () => {
    const [contractUploadModalOpen, setContractUploadModalOpen] =
        useState(false);

    const { mutateAsync: signContract } = usePostSignContract({});

    const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);

    const {
        data: contractsData,
        isLoading: contractsLoading,
        refetch: fetchContractsData,
    } = useGetContractsV10<ContractTree>(
        {},
        {
            enabled: false,
            refetchOnWindowFocus: false,
            refetchInterval: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
        }
    );

    const { initLongPoll } = useLongPoll();

    useEffect(() => {
        const fetchDataAndStartPolling = async () => {
            await fetchContractsData();

            initLongPoll();
        };

        fetchDataAndStartPolling();
    }, [fetchContractsData, initLongPoll]);

    const handleReanalyzeContracts = useCallback(() => {
        const contractRows = contractsData?.rows.filter((row) =>
            selectedRows.includes(row.id)
        );
        contractRows?.forEach(({ contract_uuid, deal_type }) => {
            // @ts-expect-error - codegen types need to be updated
            ContractResource.reprocess([contract_uuid], deal_type.code);
        });
    }, [contractsData?.rows, selectedRows]);

    const handleSignContracts = useCallback(() => {
        const contractRows = contractsData?.rows.filter((row) =>
            selectedRows.includes(row.id)
        );

        contractRows?.forEach(({ contract_uuid, version }) => {
            signContract({
                pathParams: {
                    contractUuid: contract_uuid,
                    contractVersion: version.number,
                },
            });
        });
    }, [contractsData?.rows, selectedRows, signContract]);

    const toolbarLeftContent = useMemo(
        () => (
            <div className={styles.actionsBar}>
                <Button
                    onClick={() => setContractUploadModalOpen(true)}
                    startIcon={<Add />}
                    data-testid="new-negotiation-btn"
                >
                    New negotiation
                </Button>
                <BulkActions
                    selectedCount={selectedRows.length}
                    actions={[
                        {
                            label: `Sign ${selectedRows.length} contracts`,
                            icon: <Gesture />,
                            onClick: handleSignContracts,
                        },
                        {
                            label: `Reanalyze ${selectedRows.length} contracts`,
                            icon: <Replay />,
                            onClick: handleReanalyzeContracts,
                        },
                    ]}
                />
            </div>
        ),
        [handleReanalyzeContracts, handleSignContracts, selectedRows.length]
    );

    const featureToggles = useAppSelector(
        (state) => state.featureToggles.toggles
    );
    const uploadWithProcessingStepsEnabled = !!featureToggles.find(
        (toggle) =>
            toggle.feature === FEATURE_TOGGLE_PROCESSING_STEPS_IN_UPLOAD_MODAL
    )?.enabled;
    const uploadModalProps = {
        open: contractUploadModalOpen,
        onClose: () => setContractUploadModalOpen(false),
    };

    return (
        <div className={styles.wrapper}>
            {contractsLoading ? (
                <LoadingDataGrid />
            ) : (
                <ContractsDataGrid
                    data={contractsData}
                    rowSelectionModel={selectedRows}
                    onSelectedRowsChange={setSelectedRows}
                    toolbarLeftContent={toolbarLeftContent}
                />
            )}
            {uploadWithProcessingStepsEnabled ? (
                <ContractUploadWizardModalWithProcessingSteps
                    {...uploadModalProps}
                />
            ) : (
                <ContractUploadWizardModal {...uploadModalProps} />
            )}
        </div>
    );
};

export default ContractsTree;
