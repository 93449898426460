import { useRef, useState } from 'react';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import {
    ErrorOutline,
    HelpOutline,
    Launch,
    LiveHelp,
} from '@mui/icons-material';

import { IMenuListItem } from '../MobileMenu/MobileMenu-types';
import { AppMenuItem } from '../App/App-types';
import { IHelpMenuProps } from './HelpMenu-types';
import { FEATURE_TOGGLE_BRAND_HELP_EMAIL } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import { getLegacyUIUrl } from '@modules/common/helpers';

import './HelpMenu.scss';

const HelpMenu = ({ toggles }: IHelpMenuProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleOpen = () => {
        setIsOpen(true);
    };

    const brandHelpEmailToggle = toggles.find(
        (toggle) => toggle.feature === FEATURE_TOGGLE_BRAND_HELP_EMAIL
    );
    const getHelpEmail =
        (brandHelpEmailToggle?.enabled && brandHelpEmailToggle.value) ||
        'support@thoughtriver.com';

    const menuItems: IMenuListItem[] = [
        {
            icon: <HelpOutline />,
            label: AppMenuItem.KNOWLEDGE_BASE,
            link: { target: '_blank', url: 'https://support.thoughtriver.com' },
            secondaryIcon: <Launch />,
        },
        {
            icon: <LiveHelp />,
            label: AppMenuItem.TOUR,
            link: { url: '#' },
        },
        {
            icon: <ErrorOutline />,
            label: AppMenuItem.HELP,
            link: { target: '_blank', url: `mailto:${getHelpEmail}` },
        },
    ];

    return (
        <>
            <div
                className="help-menu-anchor header-menu-anchor"
                onClick={handleOpen}
                ref={anchorRef}
                id="help-button"
            >
                <HelpOutline className="menu-icon" />
            </div>
            <Menu
                className="help-menu"
                anchorEl={anchorRef.current}
                open={isOpen}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'help-button',
                }}
                PaperProps={{
                    style: {
                        transform: 'translateX(-95px)',
                    },
                }}
            >
                <div className="menu-content">
                    {menuItems.map(({ label, icon, secondaryIcon, link }) => {
                        const { target = '_self', url = '' } = link ?? {};
                        const updatedUrl = url.replace(
                            '{reviewUrl}',
                            getLegacyUIUrl()
                        );

                        return (
                            <MenuItem
                                className="menu-item"
                                onClick={handleClose}
                                key={label}
                            >
                                <a href={updatedUrl} target={target}>
                                    <ListItemIcon className="menu-item-icon">
                                        {icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        className="menu-item-label"
                                        primary={label}
                                    />
                                    {secondaryIcon && (
                                        <ListItemIcon className="menu-item-icon secondary">
                                            {secondaryIcon}
                                        </ListItemIcon>
                                    )}
                                </a>
                            </MenuItem>
                        );
                    })}
                </div>
            </Menu>
        </>
    );
};

export default HelpMenu;
