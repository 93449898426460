import { Card, CardActions, CardContent, Skeleton } from '@mui/material';
import styles from './TemplateCard.module.scss';

const randomIntFromInterval = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1) + min);

//TODO: maybe move this to ui-components?
const LoadingCard = () => (
    <Card className={styles.card}>
        <CardContent>
            <Skeleton width={randomIntFromInterval(100, 250)} />
            <Skeleton width={randomIntFromInterval(250, 300)} />
            <Skeleton width={randomIntFromInterval(200, 250)} />
            <Skeleton
                style={{ marginTop: 'auto' }}
                width={randomIntFromInterval(150, 250)}
            />
        </CardContent>
        <CardActions>
            <Skeleton variant="circular" width={15} height={15} />
        </CardActions>
    </Card>
);

export default LoadingCard;
