import { Card, CardActions, CardContent } from '@mui/material';
import { TemplateCardProps } from './TemplateCard.types';
import classNames from 'classnames';
import {
    Avatar,
    Icon,
    IconButton,
    OverflowMenu,
    OverflowMenuItem,
} from '@thought-river/ui-components';

import styles from './TemplateCard.module.scss';
import TemplateDealTypes from './TemplateDealTypes';

const TemplateCard = ({
    onClick,
    onRenameTemplateClick,
    template,
    filteredByDealTypeId,
}: TemplateCardProps) => {
    const title = !template ? 'Start from scratch' : template?.name;
    const description = !template
        ? 'Begin with a blank slate and select your own data'
        : template?.description;

    const overflowMenuItems: OverflowMenuItem[][] = [
        [
            {
                label: 'Rename Template',
                onClick: onRenameTemplateClick ?? (() => {}),
            },
        ],
    ];

    return (
        <Card
            className={classNames(
                styles.card,
                !template && styles.startFromScratch
            )}
            onClick={onClick}
            data-testid={
                template ? `template-card-${template?.id}` : 'template-card-new'
            }
        >
            <CardContent classes={{ root: styles.content }}>
                {template && (
                    <TemplateDealTypes
                        template={template}
                        filteredByDealTypeId={filteredByDealTypeId}
                    />
                )}
                <div className={styles.title}>{title}</div>
                <div>{description}</div>
                {template && (
                    <div className={styles.avatar}>
                        by
                        {template.user_name === 'api@thoughtriver.com' ||
                        template.user_name === 'admin@thoughtriver.com' ? (
                            <Avatar
                                classes={{ label: styles.avatarLabel }}
                                label={template.display_name ?? ''}
                                showLabel
                                icon={<Icon type="tr-logo" />}
                                size="xx-small"
                            />
                        ) : (
                            <Avatar
                                classes={{ label: styles.avatarLabel }}
                                label={template.display_name ?? ''}
                                showLabel
                                size="xx-small"
                            />
                        )}
                    </div>
                )}
            </CardContent>
            <CardActions classes={{ root: styles.actions }}>
                {template && !template?.is_global && (
                    <OverflowMenu
                        button={
                            <IconButton
                                size="small"
                                classes={{ root: styles.overflowMenuBtn }}
                            >
                                <Icon type="more-vert" />
                            </IconButton>
                        }
                        menuItems={overflowMenuItems}
                    />
                )}
                <Icon className={styles.arrowIcon} type="arrow-right" />
            </CardActions>
        </Card>
    );
};

export default TemplateCard;
