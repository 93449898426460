import { APP_NAME } from '../components/App/App-types';

export const ERROR_BAD_REQUEST = 'ERROR_BAD_REQUEST';
export const ERROR_NOT_FOUND = 'ERROR_NOT_FOUND';
export const ERROR_SERVER = 'ERROR_SERVER';
export const ERROR_UNAUTHORIZED = 'ERROR_UNAUTHORIZED';
export const ERROR_UNKNOWN = 'ERROR_UNKNOWN';

export const requestOptions: IRequestOptions = {
    payload: null,
    authorize: true,
    baseUrl: null!, // Fixme: null checks
    noRedirect: false,
    includeStream: true,
    authHeaderKey: 'Authorization',
    streamCode: null!, // Fixme: null checks
    headers: {
        'Content-Type': 'application/json',
        'x-application': APP_NAME,
    },
    abortSignal: null!, // Fixme: null checks
};

export interface IApiStatic {
    request: (
        method: Method,
        url: string,
        payload: any,
        authorize: boolean
    ) => Promise<any>;
    apiUrl: string;
    apiToken: string;
}

export interface IApi {
    apiUrl: string;
    apiToken: string;
}

export interface IRequestOptions {
    payload?: any;
    authorize?: boolean;
    baseUrl?: string;
    noRedirect?: boolean;
    includeStream?: boolean;
    authHeaderKey?: string;
    streamCode?: string;
    headers?: { [key: string]: string };
    abortSignal: AbortSignal;
}

export enum Method {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}
