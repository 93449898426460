import { IScrollbarNodeProps } from './ScrollbarNode-types';
import {
    getFilteredIssuesForParagraph,
    getHighestSeverityFromIssues,
} from '../../Paragraph/Paragraph-helpers';
import { ContractIntelligenceType } from '../../Contract/Contract-types';
import { Tooltip } from '@thought-river/ui-components';
import { FamiliarityLevel } from '@thought-river/negotiations-common';

const MIN_NODE_HEIGHT = 8;

const ScrollbarNode = (props: IScrollbarNodeProps): JSX.Element => {
    const {
        activeIssueFilters,
        activeSidebarTab,
        contractScrollHeightInPixels,
        highlightedParagraphIds,
        paragraphHeightsInPixels,
        selectedParagraph,
        paragraph,
        scrollbarHeight,
        scrollbarVerticalPadding,
        selectedIntelligenceType,
        onSelectNodeCallback,
        contract,
    } = props;

    const selectedParagraphId = selectedParagraph?.id;
    const highlighted =
        highlightedParagraphIds.length > 1 &&
        highlightedParagraphIds.includes(paragraph.id);
    const paragraphRelativeHeight =
        paragraphHeightsInPixels[paragraph.index] /
        contractScrollHeightInPixels;
    const height =
        (scrollbarHeight - scrollbarVerticalPadding) * paragraphRelativeHeight;
    const style = {
        height: `${height}px`,
    };
    const { filtered: activeIssues, filteredByThemeOnly } =
        getFilteredIssuesForParagraph(activeIssueFilters, paragraph);

    let severity = null;
    let familiarity = null;

    const classes = ['paragraph-node', activeSidebarTab];

    if (selectedIntelligenceType === ContractIntelligenceType.RISK) {
        severity =
            getHighestSeverityFromIssues(activeIssues) ??
            (filteredByThemeOnly.length ? 'no-severity' : '');
        classes.push(severity || 'inactive');
    } else if (
        selectedIntelligenceType === ContractIntelligenceType.FAMILIARITY
    ) {
        // If contract is a template, override its familiarity level to Standard
        if (contract.isTemplate) {
            familiarity = FamiliarityLevel.TEMPLATE;
        } else {
            familiarity = paragraph.organisationFamiliarity.frequencyLevel;
        }

        classes.push(familiarity || 'inactive');
    }

    if (paragraph.id === selectedParagraphId) {
        classes.push('selected');
    }

    if (highlighted) {
        classes.push('highlighted');
    }

    if (height < MIN_NODE_HEIGHT) {
        classes.push('small');
    }

    return severity || familiarity ? (
        <Tooltip title={paragraph.clauseNumber} placement="top">
            <div
                className={classes.join(' ')}
                key={`paragraph-node-${paragraph.id}`}
                style={style}
                onClick={() => {
                    onSelectNodeCallback(paragraph, selectedParagraphId);
                }}
            />
        </Tooltip>
    ) : (
        <div
            className={classes.join(' ')}
            key={`paragraph-node-${paragraph.id}`}
            style={style}
            onClick={() => {
                onSelectNodeCallback(paragraph, selectedParagraphId);
            }}
        />
    );
};

export default ScrollbarNode;
