import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { setBusinessStatusesAction } from '../App/App-actions';
import { IDisplayContract } from '../Contract/Contract-types';
import { setSelectAllContractsAction } from '../ContractList/ContractList-actions';
import { updateContract } from '../ContractList/ContractList-helpers';
import { hideDialogAction } from '../ModalDialog/ModalDialog-actions';
import BulkSignModal from './BulkSignModal';
import {
    IBulkSignModalDispatchProps,
    IBulkSignModalStateProps,
} from './BulkSignModal-types';

function mapStateToProps(): IBulkSignModalStateProps {
    return {};
}

function mapDispatchToProps(dispatch: Dispatch): IBulkSignModalDispatchProps {
    return {
        closeModal: () => dispatch(hideDialogAction()),
        updateContract: (
            contractId: string,
            fields: Partial<IDisplayContract>
        ) => updateContract(contractId, fields)(dispatch),
        setSelectAllContracts: (
            selectAll: boolean,
            selectableContractIds?: string[]
        ) =>
            dispatch(
                setSelectAllContractsAction(selectAll, selectableContractIds)
            ),
        setBusinessStatuses: (...args) =>
            dispatch(setBusinessStatusesAction(...args)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkSignModal);
