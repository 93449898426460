import { useSelector } from 'react-redux';
import { useGetColumns } from './hooks';
import { DataGrid, LoadingDataGrid } from '@thought-river/ui-components';
import { IRootState } from '../../../../../redux/reducers-types';
import IssuesReportDetails from '../../../../../components/IssuesReport/IssuesReportDetails/IssuesReportDetails';
import styles from './ViewReportPage.module.scss';
import { useGetContractVersionReport } from '@thought-river/negotiations-common/dist/api/contractContent';
import { useParams } from 'react-router-dom';
import { useReportsAnalytics } from '@modules/reports/contractReports/hooks';
import { ReportViewSelect } from './components';
import { ViewReportPageProps } from './ViewReportPage.types';
import { UrlParams } from '@modules/reports/contractReports/types';
import { useProcessRowUpdate } from './hooks';
import { ClausesSidebarProvider } from '@modules/reports/common';

const ViewReportPage = ({
    selectedReportView,
    onReportViewChange,
    selectedColumns,
    filterModel,
    onFilterModelChange,
    sortModel,
    onSortModelChange,
    documentHTML,
}: ViewReportPageProps) => {
    const { contractId, versionId } = useParams<UrlParams>();
    const version = useSelector(
        (state: IRootState) => state.contract.contract.latestVersion
    );

    const { data: reportData, isLoading: reportLoading } =
        useGetContractVersionReport(
            {
                pathParams: { contractVersionUuid: versionId },
            },
            { refetchOnMount: 'always' }
        );

    const columns = useGetColumns(
        reportData?.columns || [],
        selectedColumns,
        reportData?.rows || []
    );

    const { trackCopyTableButton, trackReportExport } = useReportsAnalytics(
        contractId,
        versionId,
        version.versionNumber,
        selectedColumns
    );

    const { processRowUpdate } = useProcessRowUpdate();

    return (
        <ClausesSidebarProvider documentHTML={documentHTML}>
            <div className={styles.viewReportPage}>
                <IssuesReportDetails version={version} />
                {reportLoading ? (
                    <LoadingDataGrid />
                ) : (
                    <DataGrid
                        columns={columns}
                        rows={reportData?.rows || []}
                        getRowHeight={() => 'auto'}
                        slotProps={{
                            toolbar: {
                                copyTableProps: {
                                    onClick: trackCopyTableButton,
                                },
                                exportProps: {
                                    onExportOptionClick: trackReportExport,
                                },
                                leftContent: (
                                    <ReportViewSelect
                                        selectedView={selectedReportView}
                                        onViewChange={onReportViewChange}
                                    />
                                ),
                            },
                        }}
                        classes={{
                            'root--densityStandard': styles.standardDensity,
                            'root--densityComfortable':
                                styles.comfortableDensity,
                            'root--densityCompact': styles.compactDensity,
                            cell: styles.cell,
                        }}
                        groupingColDef={{ minWidth: 200 }}
                        filterModel={filterModel}
                        sortModel={sortModel}
                        onFilterModelChange={onFilterModelChange}
                        onSortModelChange={onSortModelChange}
                        processRowUpdate={processRowUpdate}
                    />
                )}
            </div>
        </ClausesSidebarProvider>
    );
};

export default ViewReportPage;
