import { Box } from '@mui/material';
import {
    GridBasicGroupNode,
    GridRenderCellParams,
    gridFilteredDescendantCountLookupSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid-premium';
import { ButtonProps, IconButton, Icon } from '@thought-river/ui-components';
import classnames from 'classnames';
import styles from './NegotiationCell.module.scss';
import { ContractLink } from '@modules/common/components/ContractLink';
import { ProcessingStep } from '@thought-river/negotiations-common/dist/api/contractManagement';
import { isProcessingStepComplete } from '@thought-river/negotiations-common';
import { getEditPartiesPath } from '@modules/common/helpers';

const NegotiationCell = ({ id, field, rowNode, row }: GridRenderCellParams) => {
    const apiRef = useGridApiContext();

    const filteredDescendantCountLookup = useGridSelector(
        apiRef,
        gridFilteredDescendantCountLookupSelector
    );
    const filteredDescendantCount =
        filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleClick: ButtonProps['onClick'] = (event) => {
        if (rowNode.type !== 'group') {
            return;
        }

        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
        apiRef.current.setCellFocus(id, field);
        event.stopPropagation();
    };

    const processingSteps = row.processing_steps as
        | ProcessingStep[]
        | undefined;
    const partyConfirmationStep = processingSteps?.find(
        (step) => step.name === 'party_confirmation'
    );
    const requiresUserConfirmation =
        partyConfirmationStep?.status === 'in_progress';

    const partiesUiPath = getEditPartiesPath(
        row.contract_uuid,
        row.deal_type.code
    );

    const allStepsComplete =
        !!processingSteps?.length &&
        processingSteps.every(isProcessingStepComplete);

    const contractLink = (
        <ContractLink
            dealType={row.deal_type.code}
            contractId={row.contract_uuid}
            versionId={row.version.uuid}
            linkText={row.version_name}
            disabled={!allStepsComplete && !requiresUserConfirmation}
            customHref={requiresUserConfirmation ? partiesUiPath : undefined}
        />
    );

    return (
        <Box
            sx={{
                ml: rowNode.depth * 4,
                display: 'flex',
                gap: '4px',
                overflow: 'hidden',
            }}
        >
            {filteredDescendantCount > 0 ? (
                <>
                    <IconButton
                        className={classnames(
                            styles.arrowButton,
                            (rowNode as GridBasicGroupNode).childrenExpanded &&
                                styles.expanded
                        )}
                        onClick={handleClick}
                        tabIndex={-1}
                        size="small"
                    >
                        <Icon
                            type="arrow-right"
                            classes={{ root: styles.icon }}
                        />
                    </IconButton>
                    {contractLink}
                </>
            ) : (
                contractLink
            )}
        </Box>
    );
};

export default NegotiationCell;
