import { SELECTED_COLUMNS_STORAGE_KEY } from '@modules/reports/contractReports/consts';
import { analyticsService } from '../../../services/Analytics/Analytics';
import { EVENT_ACTION_COPY_ISSUES_REPORT_LINK } from '../../../services/Analytics/Analytics-types';
import { IIssuesReportShareModalProps } from './IssuesReportShareModal-types';

const getContractReportColumns = () => {
    try {
        const selectedColumns = sessionStorage.getItem(
            SELECTED_COLUMNS_STORAGE_KEY
        );
        if (!selectedColumns) {
            return undefined;
        }
        return JSON.parse(selectedColumns);
    } catch {
        return undefined;
    }
};

export function analytics() {
    return (target: any) => {
        const onCopyUrl = target.prototype.onCopyUrl;

        target.prototype.onCopyUrl = function () {
            const { contract, version } = this
                .props as IIssuesReportShareModalProps;

            if (contract && version) {
                const selectedColumns = getContractReportColumns();

                analyticsService.recordEvent(
                    EVENT_ACTION_COPY_ISSUES_REPORT_LINK,

                    {
                        ContractId: contract.id,
                        Stream: contract.streamCode,
                        VersionId: version.id,
                        VersionNumber: version.versionNumber,
                        SelectedColumns: selectedColumns,
                    }
                );
            }

            onCopyUrl.apply(this, arguments);
        };
    };
}
