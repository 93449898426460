import {
    APPEND_CONTRACTS,
    IAppendContractsAction,
    IPrependContractAction,
    ISetContractVersionsAction,
    ISetContractsAction,
    ISetCurrentPageAction,
    ISetHasNextPageAction,
    ISetRequestedPagesAction,
    ISetShowLoaderAction,
    IUpdateContractInListAction,
    PREPEND_CONTRACT,
    SET_CONTRACTS,
    SET_CONTRACT_VERSIONS,
    SET_CURRENT_PAGE,
    SET_HAS_NEXT_PAGE,
    SET_REQUESTED_PAGES,
    SET_SHOW_LOADER,
    UPDATE_CONTRACT_IN_LIST,
    ISetSelectedContractIdsAction,
    SET_SELECTED_CONTRACT_IDS,
    SET_SELECT_ALL_CONTRACTS,
    ISetSelectAllContractsAction,
    SET_FETCHING_CONTRACTS,
    ISetFetchingContractsAction,
} from './ContractList-types';
import { IContractVersion, IDisplayContract } from '../Contract/Contract-types';

export function setContractsAction(
    contracts: IDisplayContract[]
): ISetContractsAction {
    return {
        type: SET_CONTRACTS,
        contracts,
    };
}

export function appendContractsAction(
    contracts: IDisplayContract[]
): IAppendContractsAction {
    return {
        type: APPEND_CONTRACTS,
        contracts,
    };
}

export function setContractVersionsAction(
    contractId: string,
    versions: IContractVersion[]
): ISetContractVersionsAction {
    return {
        type: SET_CONTRACT_VERSIONS,
        contractId,
        versions,
    };
}

export function setCurrentPageAction(
    currentPage: number
): ISetCurrentPageAction {
    return {
        type: SET_CURRENT_PAGE,
        currentPage,
    };
}

export function updateContractInListAction(
    contractId: string,
    fields: Partial<IDisplayContract>
): IUpdateContractInListAction {
    return {
        type: UPDATE_CONTRACT_IN_LIST,
        contractId,
        fields,
    };
}

export function setRequestedPagesAction(
    requestedPages: number[]
): ISetRequestedPagesAction {
    return {
        type: SET_REQUESTED_PAGES,
        requestedPages,
    };
}

export function setShowLoaderAction(showLoader: boolean): ISetShowLoaderAction {
    return {
        type: SET_SHOW_LOADER,
        showLoader,
    };
}

export function prependContractAction(
    contract: IDisplayContract
): IPrependContractAction {
    return {
        type: PREPEND_CONTRACT,
        contract,
    };
}

export function setHasNextPageAction(
    hasNextPage: boolean
): ISetHasNextPageAction {
    return {
        type: SET_HAS_NEXT_PAGE,
        hasNextPage,
    };
}

export function setSelectedContractIdsAction(
    selectedContractIds: string[]
): ISetSelectedContractIdsAction {
    return {
        type: SET_SELECTED_CONTRACT_IDS,
        selectedContractIds,
    };
}

export function setSelectAllContractsAction(
    selectAll: boolean,
    selectableContractIds: string[] = []
): ISetSelectAllContractsAction {
    return {
        type: SET_SELECT_ALL_CONTRACTS,
        selectAll,
        selectableContractIds,
    };
}

export function setFetchingContractsAction(
    fetchingContracts: boolean
): ISetFetchingContractsAction {
    return {
        type: SET_FETCHING_CONTRACTS,
        fetchingContracts,
    };
}
