import { useGetSummaryPanelTemplates } from '@thought-river/negotiations-common/dist/api/playbookManager';
import styles from './HomePage.module.scss';
import { Search, Select, SelectOption } from '@thought-river/ui-components';
import { useState } from 'react';
import { getFilteredTemplates } from './HomePage.helpers';
import { TemplateCards } from './TemplateCards';
import { useUserDealTypes } from '@modules/reports/portfolioReports';

export const ALL_DEAL_TYPES_OPTION_VALUE = 'all';

const HomePage = () => {
    const userDealTypes = useUserDealTypes();

    const dealTypeOptions: SelectOption[] = userDealTypes.map((dealType) => ({
        label: dealType.accountName,
        value: dealType.streamId,
    }));
    const allDealTypesOption = {
        label: 'All deal types',
        value: ALL_DEAL_TYPES_OPTION_VALUE,
    };
    dealTypeOptions.unshift(allDealTypesOption);

    const [selectedDealTypeOption, setSelectedDealTypeOption] =
        useState(allDealTypesOption);
    const [searchValue, setSearchValue] = useState('');

    const { data: spTemplates, isLoading: spTemplatesLoading } =
        useGetSummaryPanelTemplates({});

    const filteredTemplates = getFilteredTemplates(
        spTemplates ?? [],
        userDealTypes.map((dealType) => dealType.streamId),
        selectedDealTypeOption.value,
        searchValue
    );

    return (
        <div className={styles.homePage}>
            <div className={styles.toolbar}>
                <span>Create a new report</span>
                <div className={styles.toolbarFilters}>
                    <Select
                        className={styles.streamsSelect}
                        options={dealTypeOptions}
                        onChange={setSelectedDealTypeOption}
                        multiple={false}
                        value={selectedDealTypeOption}
                    />
                    <Search
                        value={searchValue}
                        onChange={setSearchValue}
                        alwaysExpanded
                    />
                </div>
            </div>
            <TemplateCards
                templates={filteredTemplates}
                templatesLoading={spTemplatesLoading}
                filteredByDealTypeId={selectedDealTypeOption.value}
            />
        </div>
    );
};

export default HomePage;
