import { Component } from 'react';

import { IPartyCorrectionHeaderProps } from './PartyCorrectionHeader-types';
import SquareButton from '../SquareButton/SquareButton';
import { ArrowBack } from '@mui/icons-material';
import { Button, Tooltip } from '@thought-river/ui-components';

class PartyCorrectionHeader extends Component<IPartyCorrectionHeaderProps> {
    render() {
        const { contract, onCancel, onSubmitCallback, submitting } = this.props;

        return (
            <div className="party-correction-header">
                <Tooltip title="Back" placement="bottom">
                    <div>
                        <SquareButton icon={<ArrowBack />} targetRoute="/" />
                    </div>
                </Tooltip>
                {contract && (
                    <>
                        <div className="contract-name-wrapper auto-truncate">
                            <span className="contract-name-label">
                                Parties for:
                            </span>
                            <span className="contract-name">
                                {contract.contractName}
                            </span>
                        </div>
                        {submitting ? (
                            <div className="loader-wrapper">
                                <span className="loader" />
                                <span className="loader-text">
                                    Submitting...
                                </span>
                            </div>
                        ) : (
                            <div className="submit-wrapper">
                                <Button
                                    data-testid="cancel-btn"
                                    onClick={onCancel}
                                    size="small"
                                    variant="secondary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="submit-btn"
                                    onClick={onSubmitCallback}
                                    data-testid="party-submit-btn"
                                >
                                    Complete Review
                                </Button>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
}

export default PartyCorrectionHeader;
