import {
    ContractVersionParties,
    CounterParty,
    OwnParty,
} from '@thought-river/negotiations-common/dist/api/contractContent';
import {
    IParty,
    PartiesType,
    PartyVariantType,
} from '../../../components/Parties/Parties-types';

/**
 * Note: Intended to be a temporary helper, eventually the UI should use types
 * as close to the backend ones as possible
 */
export const mapPartiesToContractVersionParties = (
    parties: IParty[]
): ContractVersionParties => {
    const ownParty: OwnParty = {
        formal_names: parties
            .filter(
                (p) =>
                    p.type === PartiesType.OWN_PARTIES &&
                    p.variantType === PartyVariantType.FORMAL
            )
            .map((p) => p.name),
        reference_names: parties
            .filter(
                (p) =>
                    p.type === PartiesType.OWN_PARTIES &&
                    p.variantType === PartyVariantType.REFERENCE
            )
            .map((p) => p.name),
    };

    const counterParty: CounterParty = {
        formal_names: parties
            .filter(
                (p) =>
                    p.type === PartiesType.COUNTER_PARTIES &&
                    p.variantType === PartyVariantType.FORMAL
            )
            .map((p) => p.name),
        reference_names: parties
            .filter(
                (p) =>
                    p.type === PartiesType.COUNTER_PARTIES &&
                    p.variantType === PartyVariantType.REFERENCE
            )
            .map((p) => p.name),
    };

    return { own_parties: [ownParty], counter_parties: [counterParty] };
};

export const getEditPartiesPath = (contractId: string, streamCode: string) =>
    `/stream/${streamCode}/contract/${contractId}/parties`;
