import styles from './Toolbar.module.scss';
import { ReportDefinitionOut } from '@thought-river/negotiations-common/dist/api/playbookManager';
import { UpdateReportDefinitionError } from '@thought-river/negotiations-common/dist/api/playbookManager';
import { EditableField } from '@modules/common/components/EditableField';
import { ReportOverflowMenu } from '@modules/reports/portfolioReports';
import { SubmitStatus } from '../../../../../../../components/App/App-types';
import { SaveStatus } from '@thought-river/negotiations-common';

interface ToolbarProps {
    reportDefinition: ReportDefinitionOut | undefined;
    reportUpdateInProgress: boolean;
    reportUpdateError: UpdateReportDefinitionError | null;
    onNameChange: (name: string) => void;
}

const Toolbar = ({
    reportDefinition,
    reportUpdateInProgress,
    reportUpdateError,
    onNameChange,
}: ToolbarProps) => {
    const saveStatus = (() => {
        if (reportUpdateInProgress) {
            return SubmitStatus.PROCESSING;
        } else if (reportUpdateError) {
            return SubmitStatus.FAILURE;
        } else {
            return SubmitStatus.SUCCESS;
        }
    })();

    return (
        <div className={styles.toolbar}>
            {reportDefinition && (
                <>
                    <div className={styles.reportNameWrapper}>
                        <EditableField
                            initialValue={reportDefinition?.name}
                            onChange={onNameChange}
                            placeholder="Report Name"
                        />
                        <SaveStatus
                            updatedBy={reportDefinition.display_name ?? ''}
                            status={saveStatus}
                            updatedAt={reportDefinition.last_updated_at}
                        />
                    </div>

                    <ReportOverflowMenu
                        classes={{ button: styles.overflowMenuButton }}
                        reportDefinition={reportDefinition}
                    />
                </>
            )}
        </div>
    );
};

export default Toolbar;
