import { ReportColumn } from '@thought-river/negotiations-common/dist/api/contractContent';

export const reportColumnLabels: Record<ReportColumn, string> = {
    status: 'Contract status',
    version: 'Contract version',
    contract_name: 'Contract name',
    upload_date: 'Contract upload date',
    own_party: 'My organization',
    counter_party: 'Counterparty (them)',
    deal_type: 'Deal type',
    last_processed_date: 'Contract last processed on',
};
