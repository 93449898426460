import { Pagination, TablePagination } from '@mui/material';
import {
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid-pro';
import * as React from 'react';
import { IDataGridPaginationProps } from './DataGridPagination-types';
import './DataGridPagination.scss';

function DataGridPagination(props: IDataGridPaginationProps) {
    const [rowsPerPage, setRowsPerPage] = React.useState(props.defaultPageSize);

    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const rowsCount = apiRef.current.getRowsCount();

    const handleChangePage = (page: number) => {
        apiRef.current.setPage(page - 1);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<{ name?: string; value: unknown }>
    ) => {
        const perPage = event.target.value as number;
        setRowsPerPage(perPage);
        apiRef.current.setPageSize(perPage);
        apiRef.current.setPage(0);
    };

    const calculateTotalPages = (totalResults: number, pageSize: number) =>
        totalResults <= pageSize ? 1 : Math.ceil(totalResults / pageSize);

    const TablePaginationActions = () => (
        <Pagination
            className="pagination"
            data-id="pagination"
            color="primary"
            count={calculateTotalPages(rowsCount, rowsPerPage)}
            page={page + 1}
            onChange={(_, value) => handleChangePage(value)}
        />
    );

    const renderPaginationSummary = () => {
        const pageFirstResultNumber = page === 0 ? 1 : page * rowsPerPage + 1;

        let pageLastResultNumber = pageFirstResultNumber + rowsPerPage - 1;

        if (pageLastResultNumber > rowsCount) {
            pageLastResultNumber = rowsCount;
        }

        return `Showing ${pageFirstResultNumber} to ${pageLastResultNumber} of ${rowsCount} ${props.summaryLabel}`;
    };

    return (
        <TablePagination
            rowsPerPageOptions={props.rowsPerPageOptions ?? [25, 50, 100]}
            count={rowsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            labelDisplayedRows={renderPaginationSummary}
            ActionsComponent={TablePaginationActions}
            onPageChange={() => {}}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    );
}

export default DataGridPagination;
