import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { IRootState } from '../../redux/reducers-types';
import IssuePane from './IssuePane';
import {
    IIssueForm,
    IIssuePaneDispatchProps,
    IIssuePaneStateProps,
} from './IssuePane-types';
import {
    setIssueFormAction,
    setIssueFormStatusAction,
} from './IssuePane-actions';
import {
    setContractAction,
    setHighlightIndexAction,
    setIssuesAction,
    setPropertiesAction,
    setSelectedIssueAction,
    setSelectedParagraphAction,
    setSelectedPropertyCodeAction,
    updateContractPropertyAction,
    updateSummaryPropertyAction,
} from '../Contract/Contract-actions';
import {
    HighlightIndexType,
    IContractProperty,
    IDisplayContract,
} from '../Contract/Contract-types';
import { SubmitStatus } from '../App/App-types';
import { ISummaryProperty } from '../SummaryPane/SummaryPane-types';
import { ContractSidebarTab } from '../ContractSidebar/ContractSidebar-types';
import { setContractSidebarTabAction } from '../ContractSidebar/ContractSidebar-actions';
import { setBannerSettingsAction } from '../HeatmapPane/HeatmapPane-actions';
import { IBannerSettings } from '../HeatmapPane/HeatmapPane-types';
import { ContractIssue } from '@thought-river/negotiations-common';
import { IssuePaneContext } from '@modules/common/context/IssuePaneProvider';
import { withContext } from '../withContext';

function mapStateToProps(state: IRootState): IIssuePaneStateProps {
    return {
        activeContractTab: state.contractSidebar.activeTab,
        assessment: state.contract.assessment,
        categories: state.app.categories,
        contract: state.contract.contract,
        featureToggles: state.featureToggles.toggles,
        highlightedParagraphs: state.contract.highlightedParagraphs,
        issue: state.contract.selectedIssue,
        issues: state.contract.issues,
        lexibleProperties: state.app.properties,
        form: state.issuePane.form,
        formStatus: state.issuePane.submitStatus,
        paragraphs: state.contract.paragraphs,
        policies: state.contract.policies,
        properties: state.contract.properties,
        propertyDetailsLoginCount: state.app.propertyDetailsLoginCount,
        selectedParagraph: state.contract.selectedParagraph,
        selectedVersion: state.contract.selectedVersion,
        summaryProperties: state.contract.summaryProperties,
        themes: state.contract.themes,
        userId: state.auth.userId,
        users: state.auth.users,
        width: state.contractSidebar.widthPixels,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IIssuePaneDispatchProps {
    return {
        setBannerSettings: (bannerSettings: IBannerSettings) =>
            dispatch(setBannerSettingsAction(bannerSettings)),
        setContractSidebarTab: (contractSidebarTab: ContractSidebarTab) =>
            dispatch(setContractSidebarTabAction(contractSidebarTab)),
        setContract: (contract: IDisplayContract) =>
            dispatch(setContractAction(contract)),
        setContractProperties: (properties: IContractProperty[]) =>
            dispatch(setPropertiesAction(properties)),
        setHighlightIndex: (index: number, highlightType: HighlightIndexType) =>
            dispatch(setHighlightIndexAction(index, highlightType)),
        setIssueForm: (form: IIssueForm) => dispatch(setIssueFormAction(form)),
        setIssues: (issues: ContractIssue[]) =>
            dispatch(setIssuesAction(issues)),
        setSelectedIssue: (selectedIssue: ContractIssue) =>
            dispatch(setSelectedIssueAction(selectedIssue)),
        setSelectedParagraph: (...args) =>
            dispatch(setSelectedParagraphAction(...args)),
        setSelectedPropertyCode: (code: string) =>
            dispatch(setSelectedPropertyCodeAction(code)),
        setFormStatus: (status: SubmitStatus) =>
            dispatch(setIssueFormStatusAction(status)),
        updateContractProperty: (contractProperty: IContractProperty) =>
            dispatch(updateContractPropertyAction(contractProperty)),
        updateSummaryProperty: (summaryProperty: ISummaryProperty) =>
            dispatch(updateSummaryPropertyAction(summaryProperty)),
    };
}

export default withContext(IssuePaneContext)(
    connect(mapStateToProps, mapDispatchToProps)(IssuePane)
);
