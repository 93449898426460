import {
    ADD_PARAGRAPH_HEIGHT_IN_PIXELS,
    IAddParagraphHeightInPixelsAction,
    ISetContractScrollHeightInPixelsAction,
    ISetParagraphHeightsInPixelsAction,
    SET_PARAGRAPH_HEIGHTS_IN_PIXELS,
    SET_CONTRACT_SCROLL_HEIGHT_IN_PIXELS,
    SET_CONTRACT_VISIBLE_HEIGHT_IN_PIXELS,
    ISetContractVisibleHeightInPixelsAction,
    ISetBannerSettingsAction,
    SET_BANNER_SETTINGS,
    IBannerSettings,
} from './HeatmapPane-types';

export function addParagraphHeightInPixelsAction(
    height: number
): IAddParagraphHeightInPixelsAction {
    return {
        type: ADD_PARAGRAPH_HEIGHT_IN_PIXELS,
        paragraphHeightInPixels: height,
    };
}

export function setContractScrollHeightInPixelsAction(
    height: number
): ISetContractScrollHeightInPixelsAction {
    return {
        type: SET_CONTRACT_SCROLL_HEIGHT_IN_PIXELS,
        contractScrollHeightInPixels: height,
    };
}

export function setContractVisibleHeightInPixelsAction(
    height: number
): ISetContractVisibleHeightInPixelsAction {
    return {
        type: SET_CONTRACT_VISIBLE_HEIGHT_IN_PIXELS,
        contractVisibleHeightInPixels: height,
    };
}

export function setParagraphHeightsInPixelsAction(
    heights: number[]
): ISetParagraphHeightsInPixelsAction {
    return {
        type: SET_PARAGRAPH_HEIGHTS_IN_PIXELS,
        paragraphHeightsInPixels: heights,
    };
}

export function setBannerSettingsAction(
    settings: IBannerSettings
): ISetBannerSettingsAction {
    return {
        type: SET_BANNER_SETTINGS,
        settings,
    };
}
