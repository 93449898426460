import React, { useContext, useEffect, useState } from 'react';
import {
    ClausesSidebarContextValue,
    ClausesSidebarOptions,
    ClausesSidebarProviderProps,
} from './types';
import ClausesSidebar from './ClausesSidebar';
import { useAppSelector } from '@modules/common/hooks';
import {
    setHighlightIndexAction,
    setSelectedParagraphAction,
    setSelectedPropertyCodeAction,
} from '../../../../../components/Contract/Contract-actions';
import { useDispatch } from 'react-redux';
import { HighlightIndexType } from '../../../../../components/Contract/Contract-types';
import { setBannerSettingsAction } from '../../../../../components/HeatmapPane/HeatmapPane-actions';
import {
    BannerClauseType,
    BannerType,
} from '../../../../../components/HeatmapPane/HeatmapPane-types';
import ContractViewerSidebar from './ContractViewerSidebar';

const ClausesSidebarContext = React.createContext<ClausesSidebarContextValue>({
    openClausesSidebar: () => {},
    htmlBody: null,
    openContractSidebar: () => {},
});

export const useClausesSidebarContext = () => {
    const context = useContext(ClausesSidebarContext);
    return context;
};

const ClausesSidebarProvider = ({
    children,
    showContractLink,
    documentHTML,
}: ClausesSidebarProviderProps) => {
    const dispatch = useDispatch();

    const paragraphs = useAppSelector((state) => state.contract.paragraphs);

    const [clausesSidebarOptions, setClausesSidebarOptions] =
        useState<ClausesSidebarOptions | null>(null);
    const [htmlBody, setHtmlBody] = useState<HTMLBodyElement | null>(null);
    const [isContractSidebarOpen, setIsContractSidebarOpen] = useState(false);

    // Process the document HTML
    useEffect(() => {
        if (!documentHTML) {
            return;
        }

        const parser = new DOMParser();
        const styleSheet = new CSSStyleSheet();
        const parsedHtml = parser.parseFromString(documentHTML, 'text/html');
        const documentBody = parsedHtml.querySelector('body');

        for (const style of [
            ...parsedHtml.getElementsByTagName('style'),
        ].reverse()) {
            if (!style.outerText.includes('body')) {
                styleSheet.insertRule(style.outerText);
            }
        }

        if (document.adoptedStyleSheets) {
            document.adoptedStyleSheets.push(styleSheet);
        }

        setHtmlBody(documentBody);
    }, [documentHTML]);

    const handleOpenContractSidebar = (
        paragraphId: string,
        linkedParagraphIds: string[],
        propertyCode: string
    ) => {
        setIsContractSidebarOpen(true);

        const selectedParagraph = paragraphs.find((p) => p.id === paragraphId);
        if (!selectedParagraph) {
            return;
        }

        dispatch(setSelectedParagraphAction(selectedParagraph));
        dispatch(
            setHighlightIndexAction(
                selectedParagraph.index,
                HighlightIndexType.START
            )
        );
        dispatch(
            setHighlightIndexAction(
                selectedParagraph.index,
                HighlightIndexType.END
            )
        );

        dispatch(setSelectedPropertyCodeAction(propertyCode));
        dispatch(
            setBannerSettingsAction({
                clauseType: BannerClauseType.PROPERTY_LINKED,
                type: BannerType.INFO,
                currentItem: paragraphId,
                items: linkedParagraphIds,
            })
        );
    };

    return (
        <ClausesSidebarContext.Provider
            value={{
                openClausesSidebar: setClausesSidebarOptions,
                htmlBody,
                openContractSidebar: handleOpenContractSidebar,
            }}
        >
            {children}
            <ClausesSidebar
                options={clausesSidebarOptions}
                onCloseCallback={() => setClausesSidebarOptions(null)}
                showContractLink={showContractLink}
            />
            {isContractSidebarOpen && documentHTML && (
                <ContractViewerSidebar
                    onClose={() => setIsContractSidebarOpen(false)}
                    documentHTML={documentHTML}
                />
            )}
        </ClausesSidebarContext.Provider>
    );
};

export default ClausesSidebarProvider;
