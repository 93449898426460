export const PROGRESS_BAR_BKG_COLOUR = '#e5e5e5';
export const PROGRESS_BAR_FILL_COLOUR = '#5099fd';
export const PROGRESS_BAR_FILL_GREEN_COLOUR = '#36b37e';
export const REMAINING_LABEL_COLOUR = '#5099fd';
export const TOTAL_LABEL_DEFAULT_COLOUR = '#c4c4c4';
export const TOTAL_COUNT_DEFAULT_COLOUR = '#345991';

export interface IContractProgressBarState {}

export interface IContractProgressBarProps {
    dataSets: IContractProgressBarDataSet[];
    heightInPixels?: number;
    fullWidth?: boolean;
}

export interface IContractProgressBarDataSet {
    colour: string;
    count: number;
    countColour?: string;
    label?: string;
    labelColour?: string;
    showCount: boolean;
}
