import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { IRootState } from '../../redux/reducers-types';
import { ContractPane } from '../Contract/Contract-types';
import PaneDivider from './PaneDivider';
import { setPaneWidthAction } from './PaneDivider-actions';
import {
    IPaneDividerDispatchProps,
    IPaneDividerStateProps,
} from './PaneDivider-types';

function mapStateToProps(state: IRootState): IPaneDividerStateProps {
    return {
        analyticsReportWidth: state.paneDivider.analyticsReportWidth,
        analyticsSidebarWidth: state.paneDivider.analyticsSidebarWidth,
        heatmapWidth: state.paneDivider.heatmapWidth,
        insightWidth: state.paneDivider.insightWidth,
        issueListPaneWidth: state.paneDivider.issueListPaneWidth,
        overviewWidth: state.paneDivider.overviewWidth,
        partyContractWidth: state.paneDivider.partyContractWidth,
        partySidebarWidth: state.paneDivider.partySidebarWidth,
        playbookWidth: state.paneDivider.playbookWidth,
        sidebarWidth: state.paneDivider.sidebarWidth,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IPaneDividerDispatchProps {
    return {
        setPaneWidth: (pane: ContractPane, width: number) =>
            dispatch(setPaneWidthAction(pane, width)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaneDivider);
