import { downloadContract, getContractPath } from '@modules/common/helpers';
import { useAppSelector } from '@modules/common/hooks';
import { Check } from '@mui/icons-material';
import {
    Icon,
    IconButton,
    OverflowMenu,
    OverflowMenuItem,
} from '@thought-river/ui-components';
import classnames from 'classnames';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formatDate } from '../../../../../components/App/App-helpers';
import { IContractVersion } from '../../../../../components/Contract/Contract-types';
import DeleteVersionModal from '../../../../../components/DeleteVersionModal/DeleteVersionModal-container';
import { showDialogAction } from '../../../../../components/ModalDialog/ModalDialog-actions';
import { FileFormat } from '@modules/common/types';
import { HistoryPanelProps } from '../HistoryPanel';

import styles from './VersionsList.module.scss';

interface VersionsListProps
    extends Pick<HistoryPanelProps, 'onClose' | 'isReportTab'> {
    versions: IContractVersion[];
}

const VersionsList = ({
    versions,
    onClose,
    isReportTab,
}: VersionsListProps) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const contract = useAppSelector((state) => state.contract.contract);
    const formats = useAppSelector((state) => state.contract.formats);
    const selectedVersion = useAppSelector(
        (state) => state.contract.selectedVersion
    );

    const [isDownloading, setIsDownloading] = useState(false);

    const handleVersionClick = (versionId: string) => {
        const contractPath = `${getContractPath(
            contract.streamCode,
            contract.id,
            versionId
        )}${isReportTab ? '/report' : ''}`;
        history.push(contractPath);
        onClose();
    };

    const handleDownloadClick = async (
        version: IContractVersion,
        format: FileFormat
    ) => {
        if (isDownloading) {
            return;
        }

        setIsDownloading(true);
        await downloadContract(version.id, format, contract.contractName);
        setIsDownloading(false);
    };

    const getMenuItems = (version: IContractVersion) => {
        const menuItems: OverflowMenuItem[][] = [
            [
                ...(formats.includes(FileFormat.DOCX)
                    ? [
                          {
                              icon: <Icon type="download" />,
                              label: 'Download Word',
                              onClick: () =>
                                  handleDownloadClick(version, FileFormat.DOCX),
                          },
                      ]
                    : []),
                ...(formats.includes(FileFormat.PDF)
                    ? [
                          {
                              icon: <Icon type="download" />,
                              label: 'Download PDF',
                              onClick: () =>
                                  handleDownloadClick(version, FileFormat.PDF),
                          },
                      ]
                    : []),
            ],
            ...(versions.length > 1
                ? [
                      [
                          {
                              icon: <Icon type="delete" />,
                              label: 'Delete',
                              onClick: () => {
                                  dispatch(
                                      showDialogAction(
                                          'Delete Version',
                                          true,
                                          <DeleteVersionModal
                                              contract={contract}
                                              version={version}
                                          />,
                                          [],
                                          null
                                      )
                                  );
                              },
                              type: 'destructive' as const,
                          },
                      ],
                  ]
                : []),
        ];

        return menuItems;
    };

    return (
        <>
            {versions.map((version) => (
                <div
                    className={classnames(
                        styles.versionItem,
                        version.id === selectedVersion?.id && styles.selected
                    )}
                    key={version.id}
                    onClick={() => handleVersionClick(version.id)}
                >
                    <div>Version {version.versionNumber}</div>
                    <div className={styles.status}>
                        {version.businessStatus === 'signed' && (
                            <>
                                <Check fontSize="small" />
                                Signed
                            </>
                        )}
                    </div>
                    <div className={styles.rightAlign}>
                        {formatDate(version.updatedAt)}
                        <OverflowMenu
                            menuItems={getMenuItems(version)}
                            button={
                                <IconButton
                                    className={styles.threeDotButton}
                                    size="small"
                                >
                                    <Icon
                                        type="more-vert"
                                        className={styles.threeDotIcon}
                                    />
                                </IconButton>
                            }
                        />
                    </div>
                </div>
            ))}
        </>
    );
};

export default VersionsList;
