import { Component } from 'react';

import {
    IProgressStep,
    IProgressStepsProps,
    IProgressStepsState,
} from './ProgressSteps-types';
import { StepIconProps, Step, Stepper, StepLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Check } from '@mui/icons-material';
import './ProgressSteps.scss';

const StepIconRoot = styled('div')(() => ({
    display: 'flex',
    height: 22,
    alignItems: 'center',
}));

class ProgressSteps extends Component<
    IProgressStepsProps,
    IProgressStepsState
> {
    constructor(props: IProgressStepsProps) {
        super(props);

        this.state = {
            steps: props.stepLabels.map(this.formatProgressStep),
        };
    }

    componentDidMount() {
        const { activeStep } = this.props;

        if (activeStep > 0) {
            this.goToNextStep(0);
        }
    }

    componentDidUpdate(prevProps: IProgressStepsProps) {
        const { activeStep: nextStep } = this.props;
        const { activeStep: currentStep } = prevProps;

        if (nextStep > currentStep) {
            this.goToNextStep(currentStep);
        } else if (nextStep < currentStep) {
            this.goToPreviousStep(currentStep);
        }
    }

    private goToPreviousStep = (currentStepIndex: number): void => {
        const { steps } = this.state;

        const prevStepIndex = currentStepIndex - 1;
        const updatedSteps = [...steps];

        updatedSteps[currentStepIndex] = {
            ...updatedSteps[currentStepIndex],
            active: false,
            completed: false,
        };

        if (updatedSteps[prevStepIndex]) {
            updatedSteps[prevStepIndex] = {
                ...updatedSteps[prevStepIndex],
                active: true,
                completed: false,
            };
        }

        this.setState({
            steps: updatedSteps,
        });
    };

    private goToNextStep = (currentStepIndex: number) => {
        const { steps } = this.state;

        const nextStepIndex = currentStepIndex + 1;
        const updatedSteps = [...steps];

        updatedSteps[currentStepIndex] = {
            ...updatedSteps[currentStepIndex],
            active: currentStepIndex === steps.length - 1,
            completed: true,
        };

        if (updatedSteps[nextStepIndex]) {
            updatedSteps[nextStepIndex] = {
                ...updatedSteps[nextStepIndex],
                active: true,
                completed: false,
            };
        }

        this.setState({
            steps: updatedSteps,
        });
    };

    private formatProgressStep = (
        stepLabel: string,
        index: number
    ): IProgressStep => ({
        active: index === 0,
        completed: false,
        label: stepLabel,
        number: index,
    });

    private renderStepIcon = (props: StepIconProps): JSX.Element => {
        const { active, completed, className } = props;

        return (
            <StepIconRoot className={className}>
                {completed ? (
                    <Check className="step-icon-completed" />
                ) : (
                    <div
                        className={`step-icon-circle ${active ? 'active' : ''}`}
                    />
                )}
            </StepIconRoot>
        );
    };

    render() {
        const { steps } = this.state;

        const activeStep = steps.find((step) => step.active)?.number;

        return (
            <div className="progress-steps" data-id="progress-steps">
                <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    className="stepper"
                >
                    {steps.map(({ label, active, completed }) => (
                        <Step
                            key={label}
                            active={active}
                            completed={completed}
                            className="step"
                        >
                            <StepLabel
                                StepIconComponent={this.renderStepIcon}
                                className="step-label"
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>
        );
    }
}

export default ProgressSteps;
