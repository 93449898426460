export enum SquareButtonTheme {
    DARK = 'dark',
    LIGHT = 'light',
}

export interface ISquareButtonState {}

export interface ISquareButtonStateProps {}

export interface ISquareButtonReducer {}

export interface ISquareButtonDispatchProps {}

export interface ISquareButtonProps
    extends ISquareButtonDispatchProps,
        ISquareButtonStateProps {
    targetRoute?: string;
    icon: JSX.Element;
    colourTheme?: SquareButtonTheme;
}

export type ISquareButtonActionTypes = any;
