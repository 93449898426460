import { Component } from 'react';

import {
    IDeleteVersionModalProps,
    IDeleteVersionModalState,
} from './DeleteVersionModal-types';
import ContractResource from '../../resources/ContractResource';
import { toast } from 'react-toastify';
import VersionResource from '../../resources/VersionResource';
import { mapContract, mapVersion } from '../App/App-mappers';
import { fetchContractStatus } from '../Contract/Contract-helpers';

class DeleteVersionModal extends Component<
    IDeleteVersionModalProps,
    IDeleteVersionModalState
> {
    constructor(props: IDeleteVersionModalProps) {
        super(props);

        this.state = {
            submitting: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    async deleteContract() {
        const {
            contract,
            contracts,
            history,
            setContracts,
            unsetProcessingContract,
            unsetReprocessingContract,
        } = this.props;
        await ContractResource.deleteContract(contract.id, contract.streamCode);
        setContracts(
            contracts.filter(
                (singleContract) => singleContract.id !== contract.id
            )
        );
        unsetProcessingContract(contract.id);
        unsetReprocessingContract(contract.id);

        history.push('/');
    }

    async deleteVersion() {
        const {
            match: { params },
            contract,
            history,
            selectedVersion,
            setVersions,
            updateContract,
            version,
        } = this.props;

        await VersionResource.deleteVersion(
            contract.id,
            version.id,
            contract.streamCode
        );

        const versions = (
            await VersionResource.getVersions(contract.id, contract.streamCode)
        ).data.map((version, index) => mapVersion(version, index));

        const newContract = await ContractResource.getContract(
            contract.id,
            contract.streamCode
        );

        const contractStatus = await fetchContractStatus(contract.id);

        const updatedContract = mapContract(
            newContract.data,
            versions,
            contractStatus[0]
        );

        updateContract(contract.id, updatedContract);

        setVersions(versions);

        if (params) {
            const {
                id: contractId,
                streamCode: stream,
                latestVersion,
            } = updatedContract;

            // Redirect if currently selected version was deleted
            if (selectedVersion && selectedVersion.id === version.id) {
                history.push(
                    `/stream/${stream}/contract/${contractId}/version/${latestVersion.id}`
                );
            }
        }
    }

    async onSubmit() {
        const { closeModal, contract, onModalClose } = this.props;

        this.setState({ submitting: true });

        try {
            if (contract.versions.length === 1) {
                await this.deleteContract();
            } else {
                await this.deleteVersion();
            }

            toast.success('Version deleted successfully!');
        } catch (error) {
            toast.error('Error deleting version(s)');
            this.setState({ submitting: false });
            return;
        }

        this.setState({ submitting: false });

        if (typeof onModalClose === 'function') {
            onModalClose(contract);
        }

        closeModal();
    }

    render() {
        const { closeModal, contract, version } = this.props;
        const { submitting } = this.state;

        return (
            <div className="delete-version-modal">
                <div className="section">
                    <p className="delete-version-modal-title">
                        Delete version {version.versionNumber} of "
                        {contract.contractName}"? <br />
                        <br />
                        You will not be able to recover it.
                    </p>
                </div>
                <div className="modal-dialog-window-footer">
                    <button
                        className="btn flat cancel-btn"
                        type="button"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-warn submit-btn"
                        type="button"
                        onClick={this.onSubmit}
                        disabled={submitting}
                    >
                        Delete
                    </button>
                </div>
            </div>
        );
    }
}

export default DeleteVersionModal;
