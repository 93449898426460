import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid';

import {
    EXPORT_TO_PDF_BUTTON_ID,
    IHybridReportDataGridToolbarProps,
} from './HybridReportDataGridToolbar-types';

import './HybridReportDataGridToolbar.scss';
import {
    IContractReportRow,
    IssuesTableHeader,
    SortOrder,
} from '../HybridReportDataGrid/HybridReportDataGrid-types';
import HybridReportGenerator from '../../../services/hybridReportGenerator';
import { IIssuesTableColumn } from '../IssuesReport-types';
import { IIssuesReportOrder } from '../../../services/hybridReportGenerator-types';
import { Button } from '@mui/material';

const HybridReportDataGridToolbar = (
    props: IHybridReportDataGridToolbarProps
) => {
    const apiRef = useGridApiContext();

    const exportAsPDF = () => {
        const {
            contract,
            columns,
            featureToggles,
            selectedView,
            paragraphs,
            version,
        } = props;

        const sortModel = apiRef.current.getSortModel();
        let issueReportOrder: IIssuesReportOrder | undefined;

        if (sortModel) {
            issueReportOrder = {
                order: sortModel.at(0)?.sort as SortOrder,
                orderBy: sortModel.at(0)?.field as IssuesTableHeader,
            };
        }

        const filteredRows = apiRef.current
            .getSortedRows()
            .map((item) => item as IContractReportRow);

        const parties = version?.parties ?? [];

        const orderedColumns: IIssuesTableColumn[] = columns;
        const updatedColumns = apiRef.current.getAllColumns();

        orderedColumns.sort(
            (columnA, columnB) =>
                updatedColumns.findIndex(
                    (column) => column.field === columnA.label
                ) -
                updatedColumns.findIndex(
                    (column) => column.field === columnB.label
                )
        );

        HybridReportGenerator.generateIssuesReport(
            contract,
            parties,
            selectedView,
            filteredRows,
            orderedColumns,
            issueReportOrder!, // Fixme: null checks
            paragraphs,
            featureToggles
        );
    };

    return (
        <GridToolbarContainer className="hybrid-report-data-grid-toolbar">
            <Button
                className="export-to-pdf-btn"
                id={EXPORT_TO_PDF_BUTTON_ID}
                onClick={() => exportAsPDF()}
            >
                Export
            </Button>
        </GridToolbarContainer>
    );
};

export default HybridReportDataGridToolbar;
