import { Action } from 'redux';
import { RouteComponentProps } from 'react-router-dom';

import { IReviewStream } from '../../resources/AuthResource-types';
import { ISetContractVersionsAction } from '../ContractList/ContractList-types';
import {
    IContractParagraph,
    IParagraphIssues,
} from '../Paragraph/Paragraph-types';
import { IParty } from '../Parties/Parties-types';
import { ContractSidebarTab } from '../ContractSidebar/ContractSidebar-types';
import { IIssueCategory } from '../IssueListPane/IssueListPane-types';
import {
    IProcessingContract,
    IReprocessingContract,
} from '../ContractPollingProvider/ContractPollingProvider-types';
import { ILexibleProperty, ValueType } from '../App/App-types';
import { IFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider-types';
import { ISummaryProperty } from '../SummaryPane/SummaryPane-types';
import { IBannerSettings } from '../HeatmapPane/HeatmapPane-types';
import { SummaryPanelTemplateOut } from '@thought-river/negotiations-common/dist/api/playbookManager';
import {
    ContractIssue,
    FamiliarityLevel,
    FamiliarityType,
    IssueSeverityLevel,
    LexibleTheme,
} from '@thought-river/negotiations-common';
import { FileFormat } from '@modules/common/types';

export const LOCKED_VERSION_MESSAGE = 'Older versions are locked from editing';
export const SET_ASSESSMENT = 'SET_ASSESSMENT';
export const SET_CONTRACT = 'SET_CONTRACT';
export const CLEAR_CONTRACT = 'CLEAR_CONTRACT';
export const SET_FORMATS_ACTION = 'SET_FORMATS_ACTION';
export const SET_HIGHLIGHTED_PARAGRAPHS = 'SET_HIGHLIGHTED_PARAGRAPHS';
export const SET_HIGHLIGHT_INDEX = 'SET_HIGHLIGHT_INDEX';
export const SET_ISSUES = 'SET_ISSUES';
export const UPDATE_ISSUE = 'UPDATE_ISSUE';
export const SET_LATEST_VERSION = 'SET_LATEST_VERSION';
export const SET_PROPERTY_TEMPLATES_LOADED = 'SET_PROPERTY_TEMPLATES_LOADED';
export const SET_LOADED = 'SET_LOADED';
export const SET_NEW_VERSION_PROCESSED = 'SET_NEW_VERSION_PROCESSED';
export const SET_PARAGRAPHS = 'SET_PARAGRAPHS';
export const SET_PARAGRAPHS_SIG_LEVEL = 'SET_PARAGRAPHS_SIG_LEVEL';
export const SET_POLICIES = 'SET_POLICIES';
export const SET_PROCESSING_VERSION_ID = 'SET_PROCESSING_VERSION_ID';
export const SET_PROPERTIES = 'SET_PROPERTIES';
export const SET_PROPERTY_TEMPLATES = 'SET_PROPERTY_TEMPLATES';
export const SET_REDLINED_TEXT = 'SET_REDLINED_TEXT';
export const SET_REFERRING_PAGE = 'SET_REFERRING_PAGE';
export const SET_RELOAD_CONTRACT_ACTION = 'SET_RELOAD_CONTRACT_ACTION';
export const SET_RESOLVED_PARAGRAPHS = 'SET_RESOLVED_PARAGRAPHS';
export const SET_REVIEWED_PARAGRAPHS = 'SET_REVIEWED_PARAGRAPHS';
export const SET_REVIEWED_PROPERTY = 'SET_REVIEWED_PROPERTY';
export const SET_SCROLL_TOP = 'SET_SCROLL_TOP';
export const SET_SELECTED_CONTRACT_INTELLIGENCE =
    'SET_SELECTED_CONTRACT_INTELLIGENCE';
export const SET_SELECTED_ISSUE = 'SET_SELECTED_ISSUE';
export const SET_SELECTED_MISSING_RISK = 'SET_SELECTED_MISSING_RISK';
export const SET_SELECTED_PARAGRAPH = 'SET_SELECTED_PARAGRAPH';
export const SET_SELECTED_PROPERTY_CODE = 'SET_SELECTED_PROPERTY_CODE';
export const SET_SELECTED_VERSION = 'SET_SELECTED_VERSION';
export const SET_SELECTED_PROPERTIES_TEMPLATE =
    'SET_SELECTED_PROPERTIES_TEMPLATE';
export const SET_SELECTING_PROPERTY = 'SET_SELECTING_PROPERTY';
export const SET_SUMMARY_PROPERTIES = 'SET_SUMMARY_PROPERTIES';
export const SET_SUMMARY_TEMPLATE_PROPERTIES =
    'SET_SUMMARY_TEMPLATE_PROPERTIES';
export const SET_SUMMARY_PROPERTIES_POSSIBLE_VALUES =
    'SET_SUMMARY_PROPERTIES_POSSIBLE_VALUES';
export const SET_THEMES = 'SET_THEMES';
export const SET_TOTAL_RESOLVED_ISSUES = 'SET_TOTAL_RESOLVED_ISSUES';
export const SET_TOTAL_SIGNIFICANT_ISSUES = 'SET_TOTAL_SIGNIFICANT_ISSUES';
export const SET_UNSCORED_THEMES = 'SET_UNSCORED_THEMES';
export const SET_VERSIONS = 'SET_VERSIONS';
export const TYPE_CONTRACT = 'contract';
export const TYPE_VERSION = 'version';
export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';
export const UPDATE_CONTRACT_DETAILS = 'UPDATE_CONTRACT_DETAILS';
export const UPDATE_CONTRACT_PROPERTY = 'UPDATE_CONTRACT_PROPERTY';
export const UPDATE_SUMMARY_PROPERTY = 'UPDATE_SUMMARY_PROPERTY';
export const SET_HIGHLIGHTED_TEXT = 'SET_HIGHLIGHTED_TEXT';

export const PAGE_TITLE_HEATMAP = 'ThoughtRiver Negotiations - Heatmap';

export enum ContractPane {
    ANALYTICS_SIDEBAR = 'analyticsSidebar',
    ANALYTICS_REPORT = 'analyticsReport',
    OVERVIEW = 'overview',
    HEATMAP = 'heatmap',
    INSIGHT = 'insight',
    ISSUES = 'issues',
    WORD = 'word',
    PARTY_CONTRACT = 'partyContract',
    PARTY_SIDEBAR = 'partySidebar',
    SIDEBAR = 'sidebar',
}

export interface ISelectedContractIntelligence {
    type: ContractIntelligenceType;
    highlightType: IssueSeverityLevel | FamiliarityLevel | null;
}

export enum ContractIntelligenceType {
    RISK = 'risk',
    FAMILIARITY = 'familiarity',
}

export interface IContractState {
    error: string | JSX.Element | null;
    documentHTML: string | null;
}

export interface IContractStateProps {
    accountCode: string;
    apiToken: string;
    categories: IIssueCategory[];
    contract: IDisplayContract;
    contractId: string;
    featureToggles: IFeatureToggle[];
    formats: FileFormat[];
    heatmapWidth: number;
    issues: ContractIssue[];
    lexibleProperties: ILexibleProperty[];
    loaded: boolean;
    newVersionProcessed: boolean;
    reload: boolean;
    paragraphs: IContractParagraph[];
    processingContracts: IProcessingContract[];
    properties: IContractProperty[];
    reprocessingContracts: IReprocessingContract[];
    selectedPropertiesTemplate: SummaryPanelTemplateOut;
    selectedVersion: IContractVersion | null;
    sidebarWidth: number;
    streams: IReviewStream[];
    successfulProcessedContracts: IProcessingContract[];
    successfulReprocessedContracts: IReprocessingContract[];
    themes: LexibleTheme[];
    userId: string;
    versions: IContractVersion[];
}

export interface IContractReducer {
    assessment: IContractAssesssment;
    contract: IDisplayContract;
    formats: FileFormat[];
    highlightedText: string;
    highlightEndIndex: number;
    highlightStartIndex: number;
    highlightedParagraphs: IContractParagraph[];
    id: string;
    issues: ContractIssue[];
    propertyTemplatesLoaded: boolean;
    loaded: boolean;
    newVersionProcessed: boolean;
    paragraphs: IContractParagraph[];
    policies: IContractPolicy[];
    processingVersionId: string;
    properties: IContractProperty[];
    propertyTemplates: SummaryPanelTemplateOut[];
    redlinedText: string;
    reload: boolean;
    scrollTop: number;
    selectedContractIntelligence: ISelectedContractIntelligence;
    selectedIssue: ContractIssue;
    selectedParagraph: IContractParagraph | null;
    selectedPropertyCode: string;
    selectedPropertiesTemplate: SummaryPanelTemplateOut;
    selectedVersion: IContractVersion | null;
    summaryProperties: ISummaryProperty[];
    themes: LexibleTheme[];
    unscoredThemes: LexibleTheme[];
    versions: IContractVersion[];
}

export interface IContractDispatchProps {
    setBusinessStatuses: (businessStatuses: IVersionBusinessStatus[]) => void;
    setCategories: (categories: IIssueCategory[]) => void;
    setGenericThemes: (themes: LexibleTheme[]) => void;
    setLexibleProperties: (properties: ILexibleProperty[]) => void;
    addProcessingContract: (contract: IProcessingContract) => void;
    resetActiveIssueFilters: () => void;
    setAssessment: (assessment: IContractAssesssment) => void;
    setContract: (contract: IDisplayContract) => void;
    clearContract: () => void;
    setContractSidebarTab: (contractSidebarTab: ContractSidebarTab) => void;
    setFormats: (formats: FileFormat[]) => void;
    setHighlightIndex: (
        index: number,
        highlightType: HighlightIndexType
    ) => void;
    setIssues: (issues: ContractIssue[]) => void;
    setBannerSettings: (settings: IBannerSettings) => void;
    setLoaded: (loaded: boolean) => void;
    setNewVersionProcessed: (newVersionProcessed: boolean) => void;
    setPaneWidth: (pane: ContractPane, width: number) => void;
    setParagraphs: (paragraphs: IContractParagraph[]) => void;
    setReprocessingContract: (
        contractId: string,
        contractName: string,
        versionId: string,
        stream: string,
        streamId: string,
        assessmentCount: number,
        userId: string
    ) => void;
    setPropertyTemplatesLoaded: (loaded: boolean) => void;
    setPolicies: (policies: IContractPolicy[]) => void;
    setProperties: (properties: IContractProperty[]) => void;
    setPropertyTemplates: (
        propertyTemplates: SummaryPanelTemplateOut[]
    ) => void;
    setReferringPage: (referringPage: string) => void;
    setReloadContract: (reload: boolean) => void;
    setRedlinedText: (redlinedText: string) => void;
    setSelectedIssue: (issue: ContractIssue) => void;
    setSelectedParagraph: (paragraph: IContractParagraph | null) => void;
    setSelectedVersion: (version: IContractVersion) => void;
    setSelectedPropertiesTemplate: (template: SummaryPanelTemplateOut) => void;
    setSummaryProperties: (summaryProperties: ISummaryProperty[]) => void;
    setThemes: (themes: LexibleTheme[]) => void;
    setUnscoredThemes: (unscoredThemes: LexibleTheme[]) => void;
    setVersions: (versions: IContractVersion[]) => void;
    resetIssuesReportSortOrder: () => void;
    resetIssuesTableColumns: () => void;
    unsetNotifiedContract: (contractId: string) => void;
    unsetReprocessingContract: (contractId: string) => void;
    unsetSuccessfulReprocessedContract: (contractId: string) => void;
}

export type IContractPropsHistoryProps = RouteComponentProps<{
    contractId: string;
    stream: string;
    versionId: string;
    propertyUuid?: string;
    paragraphUuid?: string;
}>;

export interface IContractProps
    extends IContractDispatchProps,
        IContractStateProps,
        IContractPropsHistoryProps {}

export interface IProcessedIssuesReturnValue {
    issues: ContractIssue[];
    closedCount: number;
    updatedCount: number;
    newCount: number;
    paragraphIssues: IParagraphIssues;
}

export interface IProcessedParagraphsReturnValue {
    paragraphs: IContractParagraph[];
    redlinedText: string;
}

export interface IProcessedThemesReturnValue {
    relevantThemes: LexibleTheme[];
    unscoredThemes: LexibleTheme[];
}

export interface IFamiliarity {
    [FamiliarityType.ORGANIZATION]: IFamiliarityValues;
    [FamiliarityType.MARKET]: IFamiliarityValues;
    totalOrganization: number;
    totalMarket: number;
}

export interface IFamiliarityValues {
    [FamiliarityLevel.COMMON]: number;
    [FamiliarityLevel.TEMPLATE]: number;
    [FamiliarityLevel.UNCOMMON]: number;
    [FamiliarityLevel.UNFAMILIAR]: number;
}

export interface IDisplayContract {
    contractName: string;
    createdAt: string;
    id: string;
    latestVersion: IContractVersion;
    negotiatorId: string;
    reprocessRequired: boolean;
    reviewerIds: string[];
    streamCode: string;
    streamId: string;
    totalClosedIssues: number;
    totalIssues: number;
    type: 'contract';
    uploadType: string;
    versionIds: string[];
    versions: IContractVersion[];
    isTemplate: boolean;
    isSigned: boolean;
}

export interface IContractFeedback {
    vote: number;
    userId: string;
}

export interface IContractPolicy {
    description: string;
    isGlobal: boolean;
    name: string;
    propertyIds: string[];
}

export interface IContractRelatedProperty {
    id: string;
    answer: string;
    question: string;
    code: string;
    lexiblePropertyId: string;
}

export interface IContractProperty {
    autodetectedValue: string;
    code: string;
    question: string;
    feedback: IContractFeedback[];
    helpText: string;
    id: string;
    label: string;
    name: string;
    nonAssessedPropertyId: string;
    nonAssessedThemeId: string;
    paragraphIds: string[];
    paragraphOriginUuids: string[];
    relatedProperties: IContractRelatedProperty[];
    relevantForContract: boolean;
    severityLevel: IssueSeverityLevel;
    shortLabel: string;
    theme: {
        id: string;
        name: string;
    };
    userValue: string;
    value: string;
    valueType: ValueType;
}

export enum UploadStatus {
    COMPLETED = 'completed',
    FAILED = 'failed',
    PROCESSING = 'processing',
    RESET = 'reset,',
    UPLOADING = 'uploading',
}

export enum BusinessStatus {
    RECEIVED = 'received',
    UNSHARED = 'unshared',
    SHARED = 'shared',
    SIGNED = 'signed',
}

export enum ContractStatus {
    UNASSIGNED = 'Unassigned',
    NEGOTIATING = 'Negotiating',
    SIGNED = 'Signed',
    TEMPLATE = 'Template',
}

export enum AssessmentType {
    FULL = 'full-analysis',
    PARTIAL = 'partial-assessment',
    REPROCESS = 'reprocess-required',
    WARNING = 'full-with-warnings',
}

export interface IContractAssesssment {
    id: string;
    type: AssessmentType;
    familiarity: IFamiliarity;
}

export enum HighlightIndexType {
    START = 'Start',
    END = 'End',
}

export interface IVersionBusinessStatus {
    id: string;
    label: BusinessStatus;
}

export interface IContractVersion {
    assessmentIds: string[];
    businessStatus: BusinessStatus;
    contractId: string;
    counterParty: string;
    createdAt: string;
    daysElapsed: number;
    documentId: string;
    fileName: string;
    formattedUpdatedAt: string;
    id: string;
    isLatest: boolean;
    ownParty: string;
    parties: IParty[];
    partiesOverridden: boolean;
    type: 'version';
    updatedAt: string;
    uploadStatus: UploadStatus | '';
    uploadStatusDescription: string;
    versionNumber: number;
    requirePartyConfirmation: boolean;
}

export interface ISetAssessmentAction extends Action {
    type: typeof SET_ASSESSMENT;
    assessment: IContractAssesssment;
}

export interface ISetIssuesAction extends Action {
    type: typeof SET_ISSUES;
    issues: ContractIssue[];
}

export interface IUpdateIssueAction extends Action {
    type: typeof UPDATE_ISSUE;
    issue: ContractIssue;
}

export interface ISetScrollTopAction extends Action {
    type: typeof SET_SCROLL_TOP;
    scrollTop: number;
}

export interface ISetSelectedIssueAction extends Action {
    type: typeof SET_SELECTED_ISSUE;
    selectedIssue: ContractIssue;
}

export interface ISetSelectedMissingRiskAction extends Action {
    type: typeof SET_SELECTED_MISSING_RISK;
    selectedMissingRisk: IContractProperty;
}

export interface ISetSelectedParagraphAction extends Action {
    type: typeof SET_SELECTED_PARAGRAPH;
    selectedParagraph: IContractParagraph | null;
}

export interface ISetParagraphsAction extends Action {
    type: typeof SET_PARAGRAPHS;
    paragraphs: IContractParagraph[];
}

export interface ISetPoliciesAction extends Action {
    type: typeof SET_POLICIES;
    policies: IContractPolicy[];
}

export interface ISetPropertiesAction extends Action {
    type: typeof SET_PROPERTIES;
    properties: IContractProperty[];
}

export interface ISetThemesAction extends Action {
    type: typeof SET_THEMES;
    themes: LexibleTheme[];
}

export interface ISetVersionsAction extends Action {
    type: typeof SET_VERSIONS;
    versions: IContractVersion[];
}

export interface ISetReviewedParagraphsAction extends Action {
    type: typeof SET_REVIEWED_PARAGRAPHS;
    reviewed: boolean;
    paragraphIndices?: number[];
}

export interface ISetResolvedParagraphsAction extends Action {
    type: typeof SET_RESOLVED_PARAGRAPHS;
    resolved: boolean;
}

export interface ISetReviewedPropertyAction extends Action {
    type: typeof SET_REVIEWED_PROPERTY;
    index: number;
    reviewed: boolean;
}

export interface ISetContractAction extends Action {
    type: typeof SET_CONTRACT;
    contract: IDisplayContract;
}

export interface IClearContractAction extends Action {
    type: typeof CLEAR_CONTRACT;
}

export interface ISetRedlinedTextAction extends Action {
    type: typeof SET_REDLINED_TEXT;
    redlinedText: string;
}

export interface ISetLoadedAction extends Action {
    type: typeof SET_LOADED;
    loaded: boolean;
}

export interface ISetTotalResolvedIssuesAction extends Action {
    type: typeof SET_TOTAL_RESOLVED_ISSUES;
    total: number;
}

export interface ISetTotalSignificantIssuesAction extends Action {
    type: typeof SET_TOTAL_SIGNIFICANT_ISSUES;
    total: number;
}

export interface ISetHighlightedParagraphsAction {
    type: typeof SET_HIGHLIGHTED_PARAGRAPHS;
    highlightedParagraphs: IContractParagraph[];
}

export interface ISetHighlightIndexAction {
    type: typeof SET_HIGHLIGHT_INDEX;
    index: number;
    highlightType: HighlightIndexType;
}

export interface ISetProcessingVersionIdAction {
    type: typeof SET_PROCESSING_VERSION_ID;
    processingVersionId: string;
}

export interface ISetLatestVersionAction {
    type: typeof SET_LATEST_VERSION;
    latestVersion: IContractVersion;
}

export interface ISetSelectedVersionAction extends Action {
    type: typeof SET_SELECTED_VERSION;
    selectedVersion: IContractVersion;
}

export interface ISetFormatsAction extends Action {
    type: typeof SET_FORMATS_ACTION;
    formats: FileFormat[];
}

export interface ISetReloadContractAction extends Action {
    type: typeof SET_RELOAD_CONTRACT_ACTION;
    reload: boolean;
}

export interface ISetUnscoredThemesAction extends Action {
    type: typeof SET_UNSCORED_THEMES;
    unscoredThemes: LexibleTheme[];
}

export interface IUpdateContractAction {
    type: typeof UPDATE_CONTRACT;
    contractId: string;
    fields: Partial<IDisplayContract>;
}

export interface IUpdateContractDetailsAction extends Action {
    type: typeof UPDATE_CONTRACT_DETAILS;
    contractName?: string;
    reviewerIds?: string[];
    negotiatorId?: string;
}

export interface ISetNewVersionProcessedAction extends Action {
    type: typeof SET_NEW_VERSION_PROCESSED;
    newVersionProcessed: boolean;
}

export interface ISetReferringPageAction extends Action {
    type: typeof SET_REFERRING_PAGE;
    referringPage: string;
}

export interface IUpdateContractPropertyAction extends Action {
    type: typeof UPDATE_CONTRACT_PROPERTY;
    property: IContractProperty;
}

export interface IUpdateSummaryPropertyAction extends Action {
    type: typeof UPDATE_SUMMARY_PROPERTY;
    property: ISummaryProperty;
}

export interface ISetPropertyTemplatesLoadedAction extends Action {
    type: typeof SET_PROPERTY_TEMPLATES_LOADED;
    propertyTemplatesLoaded: boolean;
}

export interface ISetSelectedPropertyCodeAction extends Action {
    type: typeof SET_SELECTED_PROPERTY_CODE;
    selectedPropertyCode: string | null;
}

export interface ISetSummaryPropertiesAction extends Action {
    type: typeof SET_SUMMARY_PROPERTIES;
    summaryProperties: ISummaryProperty[];
}

export interface ISetSelectedPropertiesTemplate extends Action {
    type: typeof SET_SELECTED_PROPERTIES_TEMPLATE;
    selectedPropertiesTemplate: SummaryPanelTemplateOut;
}

export interface ISetPropertyTemplatesAction extends Action {
    type: typeof SET_PROPERTY_TEMPLATES;
    propertyTemplates: SummaryPanelTemplateOut[];
}

export interface ISetSelectedContractIntelligenceAction extends Action {
    type: typeof SET_SELECTED_CONTRACT_INTELLIGENCE;
    intelligence: ISelectedContractIntelligence;
}

export interface ISetHighlightedTextAction extends Action {
    type: typeof SET_HIGHLIGHTED_TEXT;
    text: string;
}

export type IContractActionTypes =
    | ISetAssessmentAction
    | ISetContractAction
    | IClearContractAction
    | ISetContractVersionsAction
    | ISetFormatsAction
    | ISetHighlightedTextAction
    | ISetHighlightIndexAction
    | ISetHighlightedParagraphsAction
    | ISetIssuesAction
    | IUpdateIssueAction
    | ISetLatestVersionAction
    | ISetPropertyTemplatesLoadedAction
    | ISetLoadedAction
    | ISetNewVersionProcessedAction
    | ISetParagraphsAction
    | ISetPoliciesAction
    | ISetProcessingVersionIdAction
    | ISetPropertiesAction
    | ISetPropertyTemplatesAction
    | ISetRedlinedTextAction
    | ISetReferringPageAction
    | ISetReloadContractAction
    | ISetResolvedParagraphsAction
    | ISetReviewedParagraphsAction
    | ISetReviewedPropertyAction
    | ISetScrollTopAction
    | ISetSelectedContractIntelligenceAction
    | ISetSelectedIssueAction
    | ISetSelectedMissingRiskAction
    | ISetSelectedParagraphAction
    | ISetSelectedPropertyCodeAction
    | ISetSelectedPropertiesTemplate
    | ISetSelectedVersionAction
    | ISetThemesAction
    | ISetTotalResolvedIssuesAction
    | ISetTotalSignificantIssuesAction
    | ISetUnscoredThemesAction
    | ISetVersionsAction
    | ISetSummaryPropertiesAction
    | IUpdateContractAction
    | IUpdateContractDetailsAction
    | IUpdateContractPropertyAction
    | IUpdateSummaryPropertyAction;
