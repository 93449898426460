export type ContractReportColumn =
    | 'lexible_label'
    | 'lexible_name'
    | 'lexible_code'
    | 'lexible_question'
    | 'lexible_answer'
    | 'lexible_theme'
    | 'issue_last_updated'
    | 'issue_status'
    | 'issue_severity_level'
    | 'issue_summary'
    | 'issue_internal_guidance'
    | 'issue_external_comments'
    | 'property_linked_clauses'
    | 'property_linked_clauses_text'
    | 'suggestions'
    | 'familiarity'
    | 'issue_decision'
    | 'contract_sections'
    | 'contract_sub_sections'
    | 'contract_primary_section'
    | 'checklists';

//TODO: should the column labels be the same everywhere? in the data grid and the edit report page?
export const reportColumnLabels: Record<ContractReportColumn, string> = {
    lexible_label: 'Lexible label',
    lexible_name: 'Lexible name',
    lexible_code: 'Lexible code',
    lexible_question: 'Lexible question',
    lexible_answer: 'Lexible answer',
    lexible_theme: 'Lexible theme',
    issue_last_updated: 'Last updated',
    issue_status: 'Status',
    issue_severity_level: 'Importance',
    issue_summary: 'Summary',
    issue_internal_guidance: 'Internal guidance',
    issue_external_comments: 'External comments',
    property_linked_clauses: 'Linked clauses',
    property_linked_clauses_text: 'Linked clauses text',
    suggestions: 'Drafting options',
    familiarity: 'Familiarity',
    issue_decision: 'Decision',
    contract_sections: 'Section',
    contract_primary_section: 'Primary section',
    contract_sub_sections: 'Subsections',
    checklists: 'Checklists',
};

//TODO: ask backend if we can get available columns endpoint like in analytics
//So we don't have to uncomment these and instead rely on the backend
export const availableColumns: ContractReportColumn[] = [
    // 'checklists',
    'issue_decision',
    // 'familiarity',
    'issue_external_comments',
    'issue_severity_level',
    'issue_internal_guidance',
    // 'issue_last_updated',
    // 'issue_status',
    'issue_summary',
    // 'lexible_answer',
    // 'lexible_code',
    // 'lexible_label',
    // 'lexible_name',
    // 'lexible_question',
    // 'lexible_theme',
    'property_linked_clauses',
    // 'property_linked_clauses_text',
    'contract_sections',
    'contract_primary_section',
    'contract_sub_sections',
    // 'suggestions',
];

// The key used to store the currently selected columns in session storage for analytics
export const SELECTED_COLUMNS_STORAGE_KEY = 'contractReportSelectedColumns';
