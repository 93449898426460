import * as React from 'react';
import { Backup, NoteAdd, Replay } from '@mui/icons-material';
import { v4 as uuid } from 'uuid';

import pdfLogo from '../../assets/pdf_logo.png';
import wordLogo from '../../assets/word_logo.png';
import RtfLogo from '../RtfLogo/RtfLogo';

import {
    FILE_TYPE_PDF,
    IWizardFileUploaderProps,
    IWizardFileUploaderState,
    IPreviewFile,
    PreviewFileStatus,
    PreviewFileType,
    ValidHeaderBitsToPreviewMimeType,
    FILE_TYPE_RTF,
    FILE_TYPE_DOC,
    FILE_TYPE_DOCX,
    FILE_TYPE_RTF_2,
    FILE_TYPE_KOREAN_DOC,
    FILE_TYPE_KOREAN_DOCX,
} from './WizardFileUploader-types';
import './WizardFileUploader.scss';

function isArrayBuffer(value: any): value is ArrayBuffer {
    return 'byteLength' in value;
}

const CANNOT_READ_HEADER = 'CANNOT_READ_HEADER';

function getFileHeaderHex(file: File) {
    return new Promise<string>((res) => {
        const reader = new FileReader();
        reader.onloadend = (e: ProgressEvent<FileReader>) => {
            if (isArrayBuffer(e.target?.result)) {
                const header = new Uint8Array(e.target?.result ?? []).reduce(
                    (acc, byte) => acc + byte.toString(16),
                    ''
                );
                res(header);
            } else {
                res(CANNOT_READ_HEADER);
            }
        };
        reader.readAsArrayBuffer(file.slice(0, 4));
    });
}

async function resolveFileHeaderType(file: File) {
    const headerHex = await getFileHeaderHex(file);
    return ValidHeaderBitsToPreviewMimeType[headerHex] ?? PreviewFileType.OTHER;
}

function resolveFileType(fileType: string): PreviewFileType {
    let resolvedFileType = PreviewFileType.OTHER;

    switch (fileType) {
        case FILE_TYPE_DOC:
        case FILE_TYPE_DOCX:
        case FILE_TYPE_KOREAN_DOC:
        case FILE_TYPE_KOREAN_DOCX:
            resolvedFileType = PreviewFileType.WORD;
            break;
        case FILE_TYPE_PDF:
            resolvedFileType = PreviewFileType.PDF;
            break;
        case FILE_TYPE_RTF:
        case FILE_TYPE_RTF_2:
            resolvedFileType = PreviewFileType.RTF;
            break;
    }

    return resolvedFileType;
}

class WizardFileUploader extends React.Component<
    IWizardFileUploaderProps,
    IWizardFileUploaderState
> {
    private inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: IWizardFileUploaderProps) {
        super(props);

        this.state = {
            dragging: false,
            filesSelected: false,
            fileList: null!, // Fixme: null checks
            files: [],
            invalidFileError: false,
            multipleFilesError: false,
        };

        this.inputRef = React.createRef();

        this.onClickUploadButton = this.onClickUploadButton.bind(this);
        this.filesAreValid = this.filesAreValid.bind(this);
        this.onDragDrop = this.onDragDrop.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.resetFiles = this.resetFiles.bind(this);
        this.renderDropzoneLabel = this.renderDropzoneLabel.bind(this);
        this.renderFileSize = this.renderFileSize.bind(this);
        this.renderFilePreviewRow = this.renderFilePreviewRow.bind(this);
        this.renderPreviewInfo = this.renderPreviewInfo.bind(this);
        this.mapPreviewFile = this.mapPreviewFile.bind(this);
    }

    private onClickUploadButton() {
        this.inputRef.current?.click();
    }

    private async mapPreviewFile(
        file: File,
        index: number
    ): Promise<IPreviewFile> {
        const abortController = new AbortController();
        const type = file.type || (await resolveFileHeaderType(file));

        return {
            abortController,
            file,
            id: uuid(),
            index,
            mimeType: type ?? '',
            name: file.name,
            sizeBytes: file.size,
            status: PreviewFileStatus.IN_QUEUE,
            type: resolveFileType(type),
            uploadProgress: 0,
        };
    }

    private onDragDrop(event: React.DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === 'drop') {
            if (this.state.dragging) {
                this.setState({ dragging: false });
            }

            this.setSelectedFiles(event.dataTransfer.files);
        }
    }

    private onDragEnter(event: React.DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (!this.state.dragging) {
            this.setState({ dragging: true });
        }
    }

    private onDragLeave(event: any) {
        event.preventDefault();
        event.stopPropagation();

        const classes: string =
            typeof event.target.className === 'string'
                ? event.target.className
                : '';

        const leavingDropdown = classes
            .split(' ')
            .filter((className) => className === 'wizard-file-uploader').length;

        if (this.state.dragging && leavingDropdown) {
            this.setState({ dragging: false });
        }
    }

    private filesAreValid(files: IPreviewFile[]): boolean {
        for (const file of files) {
            if (file.type === PreviewFileType.OTHER) {
                return false;
            }
        }

        return true;
    }

    private onFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setSelectedFiles(event.target.files!); // Fixme: null checks
        if (this.inputRef.current) {
            this.inputRef.current.value = null!; // Fixme: null checks
        }
    }

    private resetFiles() {
        if (this.inputRef.current) {
            this.inputRef.current.value = null!; // Fixme: null checks
        }
        this.setState({
            filesSelected: false,
            fileList: null!, // Fixme: null checks
            files: [],
            invalidFileError: false,
            multipleFilesError: false,
        });
        this.props.onChangeCallback?.(null!); // Fixme: null checks
    }

    private renderDropzoneLabel(): JSX.Element {
        return (
            <div className="dropzone-label" onClick={this.onClickUploadButton}>
                <NoteAdd className="dropzone-icon" />
                <div className="dropzone-text">Select File(s) to Upload</div>
                <div className="dropzone-subtext">or Drag and Drop</div>
            </div>
        );
    }

    private renderFileSize(sizeBytes: number): string {
        let label = '';

        if (sizeBytes > 1000000) {
            label = `${(sizeBytes / 1000000).toFixed(2)} MB`;
        } else {
            label = `${(sizeBytes / 1000).toFixed(2)} KB`;
        }

        return label;
    }

    private renderFilePreviewRow(file: IPreviewFile): JSX.Element {
        let logo = (
            <img className="file-type-img" src={wordLogo} alt="word-logo" />
        );

        switch (file.type) {
            case PreviewFileType.PDF:
                logo = (
                    <img
                        className="file-type-img"
                        src={pdfLogo}
                        alt="pdf-logo"
                    />
                );
                break;
            case PreviewFileType.RTF:
                logo = <RtfLogo />;
                break;
        }

        return (
            <tr key={`file-preview-row-${file.id}`}>
                <td className="file-name-cell">
                    {logo}
                    <span className="file-name">{file.name}</span>
                </td>
            </tr>
        );
    }

    private renderPreviewInfo(fileCount: number): JSX.Element {
        return (
            <div className="file-preview-info">
                <div className="reset-btn-wrapper">
                    <span
                        className="reset-btn"
                        onClick={() => this.resetFiles()}
                    >
                        <Replay className="reset-btn-icon" />
                        <span className="reset-btn-text">Reset</span>
                    </span>
                </div>
                <div>Total files: {fileCount}</div>
            </div>
        );
    }

    private async setSelectedFiles(fileList: FileList) {
        const { onChangeCallback, multiple } = this.props;

        let files = await Promise.all(
            Array.from(fileList).map((file, index) =>
                this.mapPreviewFile(file, index)
            )
        );

        if (!multiple) {
            files = files.slice(0, 1);
        }

        this.setState({
            filesSelected: true,
            fileList,
            files,
            invalidFileError: false,
            multipleFilesError: !multiple && fileList?.length > 1,
        });

        if (typeof onChangeCallback === 'function') {
            if (this.filesAreValid(files)) {
                onChangeCallback(files);
            } else {
                this.resetFiles();

                this.setState({
                    invalidFileError: true,
                });
            }
        }
    }

    render() {
        const { className, multiple } = this.props;
        const { dragging, invalidFileError, multipleFilesError } = this.state;

        const dropzoneClasses =
            'wizard-file-uploader' +
            ` ${className ? className : ''}` +
            ` ${dragging ? 'dragging' : ''}`;

        return (
            <div
                className={`wizard-file-uploader-wrapper ${
                    invalidFileError || multipleFilesError ? 'error' : ''
                }`}
                data-id="wizard-file-uploader-wrapper"
            >
                <div className="wizard-file-uploader-dropzone">
                    <div
                        className={dropzoneClasses}
                        onDragEnter={this.onDragEnter}
                        onDragOver={this.onDragEnter}
                        onDragLeave={this.onDragLeave}
                        onDrop={this.onDragDrop}
                    >
                        <input
                            className="wizard-file-uploader-input"
                            multiple={multiple}
                            name="file-input"
                            onChange={this.onFileChange}
                            ref={this.inputRef}
                            type="file"
                        />
                        <div
                            className={`wizard-file-uploader-drop-label ${
                                dragging ? '' : 'hidden'
                            }`}
                        >
                            <Backup className="wizard-file-uploader-drop-label-icon" />
                            <span className="wizard-file-uploader-drop-label-text">
                                Drop here
                            </span>
                        </div>
                        {this.renderDropzoneLabel()}
                    </div>
                </div>
                <div
                    className={`wizard-file-uploader-error ${
                        invalidFileError ? 'show' : ''
                    }`}
                >
                    Your file must be a Microsoft Word format with extension
                    .docx or .doc, or a PDF file
                </div>
                <div
                    className={`multiple-files-error ${
                        multipleFilesError ? 'show' : ''
                    }`}
                >
                    You may upload only one file at a time.
                </div>
            </div>
        );
    }
}

export default WizardFileUploader;
