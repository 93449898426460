import { SelectOption } from '@thought-river/ui-components';
import { IRootState } from '../../redux/reducers-types';
import { reduxStore } from '../../redux/store';
import { analyticsService } from '../../services/Analytics/Analytics';
import { EVENT_ACTION_ISSUE_SELECT_SAVED_VIEW } from '../../services/Analytics/Analytics-types';

export function analytics() {
    return (target: any) => {
        const onViewChange = target.prototype.onViewChange;

        target.prototype.onViewChange = function (event: SelectOption) {
            const {
                contract: { contract, selectedVersion },
            } = reduxStore.store.getState() as IRootState;

            analyticsService.recordEvent(
                EVENT_ACTION_ISSUE_SELECT_SAVED_VIEW,

                {
                    ContractId: contract?.id,
                    Stream: contract?.streamCode,
                    VersionId: selectedVersion?.id,
                    VersionNumber: selectedVersion?.versionNumber,
                    ViewSelected: event.value,
                }
            );

            onViewChange.apply(this, arguments);
        };
    };
}
