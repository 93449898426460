import { Action } from 'redux';
import { ReactNode } from 'react';

export const FEATURE_TOGGLE_ISSUES_REPORT_BRANDING =
    'release_dilreport_v1.1_issues_report_branding';
export const FEATURE_TOGGLE_USER_AVATAR_GRADIENT =
    'release_user_avatar_gradient_v1.0';
export const FEATURE_TOGGLE_SUMMARY_LABEL = 'test_reviewsp_panel_name';
export const FEATURE_TOGGLE_REPORTS_BETA_2 = 'release_reports_beta_2';
export const FEATURE_TOGGLE_TEST_AI_USER_VALUE = 'test_ai_uservalue';
export const FEATURE_TOGGLE_CONTRACT_REPORT_NEW_DATA_GRID =
    'release_contract_report_new_data_grid';
export const FEATURE_TOGGLE_API_KEYS_PAGE = 'release_api_keys_page';
export const FEATURE_TOGGLE_ISSUES_REPORT_RSBG_VIEWS =
    'rsbg_issues_report_views';
export const FEATURE_TOGGLE_ISSUES_REPORT_ADECCO_VIEWS =
    'adecco_issues_report_views';
export const FEATURE_TOGGLE_GA_CONTRACT_REPORTS = 'release_ga_contract_reports';
export const FEATURE_TOGGLE_CONTRACTS_TREE = 'release_contracts_tree';
export const FEATURE_TOGGLE_TEST_CONTRACT_REPORT_CLAUSES_HTML =
    'test_contract_report_clauses_html';
export const FEATURE_TOGGLE_RELEASE_UNLINK_WITH_FEEDBACK =
    'release_unlink_with_feedback';
export const FEATURE_TOGGLE_RELEASE_CLOSE_ALL_ISSUES =
    'release_close_all_issues_option';
export const FEATURE_TOGGLE_RELEASE_SECTION_OUTLINE_LEFT_PANEL =
    'release_section_outline_left_panel';
export const FEATURE_TOGGLE_NO_ACCOUNT_CODE = 'negotiations_no_account_code';

//Feature flags with values
export const FEATURE_TOGGLE_BRAND_HELP_EMAIL = 'brand_help_email';
export const FEATURE_TOGGLE_FAMILIARITY_THRESHOLDS = 'familiarity_thresholds';
export const FEATURE_TOGGLE_FAMILIARITY_SIGNED_CONTRACTS_THRESHOLD =
    'familiarity_signed_contracts_threshold';
export const FEATURE_TOGGLE_CONTRACT_MANAGEMENT_UPLOAD_CONTRACT_VERSION =
    'contract_management_upload_contract_version';
export const FEATURE_TOGGLE_PROCESSING_STEPS_IN_UPLOAD_MODAL =
    'release_processing_steps_in_upload_modal';

export const FEATURE_TOGGLES_POLLING_TIME_MS = 600000; // 10 minutes
export const SET_FEATURE_TOGGLES = 'SET_FEATURE_TOGGLES';

export interface IBrandDetails {
    logoPrefix: string;
    signUpUrl: string;
    signUpPrefix: string;
    signUpText: string;
}

export interface IFeatureToggleProviderState {
    initialised: boolean;
}

export interface IFeatureToggleProviderStateProps {
    featureToggles: IFeatureToggle[];
    userId: string;
    accountCode: string;
}

export interface IFeatureToggleProviderReducer {
    toggles: IFeatureToggle[];
}

export interface IFeatureToggleProviderDispatchProps {
    setFeatureToggles: (toggles: IFeatureToggle[]) => void;
}

export interface IFeatureToggleProviderProps
    extends IFeatureToggleProviderDispatchProps,
        IFeatureToggleProviderStateProps {
    children?: ReactNode;
}

export interface IFeatureToggle {
    feature: string;
    enabled: boolean;
    value: string | boolean | number | object;
}

export interface ISetFeatureTogglesAction extends Action {
    type: typeof SET_FEATURE_TOGGLES;
    toggles: IFeatureToggle[];
}

export type IFeatureToggleProviderActionTypes = ISetFeatureTogglesAction;
