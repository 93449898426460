import {
    IContractVersion,
    IDisplayContract,
} from '../../Contract/Contract-types';
import { IFeatureToggle } from '../../FeatureToggleProvider/FeatureToggleProvider-types';
import { PresetIssueFilters } from '../../IssueListFilters/IssueListFilters-types';
import { IContractParagraph } from '../../Paragraph/Paragraph-types';
import { IIssuesTableColumn } from '../IssuesReport-types';

export interface IHybridReportDataGridToolbarProps {
    columns: IIssuesTableColumn[];
    contract: IDisplayContract;
    featureToggles: IFeatureToggle[];
    paragraphs: IContractParagraph[];
    selectedView: PresetIssueFilters;
    version: IContractVersion;
}
export interface IHybridReportDataGridToolbarState {}

export const EXPORT_TO_PDF_BUTTON_ID = 'export-to-pdf-btn';
