import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';

import ContractList from './ContractList';
import { IRootState } from '../../redux/reducers-types';
import {
    IContractListDispatchProps,
    IContractListStateProps,
} from './ContractList-types';
import { showDialogAction } from '../ModalDialog/ModalDialog-actions';
import { IModalDialogButton } from '../ModalDialog/ModalDialog-types';
import {
    setReprocessingContractAction,
    unsetNotifiedContractAction,
} from '../ContractPollingProvider/ContractPollingProvider-actions';

function mapStateToProps(state: IRootState): IContractListStateProps {
    return {
        contracts: state.contractList.contracts,
        failedProcessedContracts:
            state.contractPollingProvider?.failedProcessedContracts ?? [],
        failedReprocessedContracts:
            state.contractPollingProvider?.failedReprocessedContracts ?? [],
        featureToggles: state.featureToggles.toggles,
        nextPage: state.contractList.nextPage,
        showLoader: state.contractList.showLoader,
        successfulProcessedContracts:
            state.contractPollingProvider?.successfulProcessedContracts ?? [],
        successfulReprocessedContracts:
            state.contractPollingProvider?.successfulReprocessedContracts ?? [],
        userId: state.auth.userId,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IContractListDispatchProps {
    return {
        showDialog: (
            title: string | JSX.Element,
            showCloseButton: boolean,
            component: JSX.Element,
            buttons: IModalDialogButton[],
            height: string | null | undefined,
            customClassname: string = '',
            closeOnBlur: boolean = true
        ) =>
            dispatch(
                showDialogAction(
                    title,
                    showCloseButton,
                    component,
                    buttons,
                    height,
                    customClassname,
                    closeOnBlur
                )
            ),

        setReprocessingContract: (
            contractId: string,
            contractName: string,
            versionId: string,
            stream: string,
            streamId: string,
            assessmentCount: number,
            userId: string
        ) =>
            dispatch(
                setReprocessingContractAction(
                    contractId,
                    contractName,
                    versionId,
                    stream,
                    streamId,
                    assessmentCount,
                    userId
                )
            ),
        unsetNotifiedContract: (contractId: string) =>
            dispatch(unsetNotifiedContractAction(contractId)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ContractList));
