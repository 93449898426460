import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { IRootState } from '../../redux/reducers-types';
import ContractSidebar from './ContractSidebar';
import {
    ContractSidebarTab,
    IContractSidebarDispatchProps,
    IContractSidebarStateProps,
} from './ContractSidebar-types';
import {
    setContractSidebarTabAction,
    setContractSidebarWidthInPixelsAction,
} from './ContractSidebar-actions';

function mapStateToProps(state: IRootState): IContractSidebarStateProps {
    return {
        activeIssueFilters: state.issueListFilters.activeIssueFilters,
        activeTab: state.contractSidebar.activeTab,
        contract: state.contract.contract,
        featureToggles: state.featureToggles.toggles,
        highlightedParagraphs: state.contract.highlightedParagraphs,
        issues: state.contract.issues,
        paragraphs: state.contract.paragraphs,
        selectedContractIntelligence:
            state.contract.selectedContractIntelligence,
        selectedIssue: state.contract.selectedIssue,
        selectedPropertyCode: state.contract.selectedPropertyCode,
        sortBy: state.issueListFilters.sortBy,
        width: state.paneDivider.sidebarWidth,
        widthPixels: state.contractSidebar.widthPixels,
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<AnyAction>
): IContractSidebarDispatchProps {
    return {
        setActiveContractSidebarTab: (activeTab: ContractSidebarTab) =>
            dispatch(setContractSidebarTabAction(activeTab)),
        setContractSidebarWidthInPixels: (widthPixels: number) =>
            dispatch(setContractSidebarWidthInPixelsAction(widthPixels)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractSidebar);
