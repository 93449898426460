import { MUIDataType } from '@thought-river/negotiations-common/dist/api/contractContent';
import { getGridDefaultColumnTypes } from '@mui/x-data-grid-premium';

export const getDefaultFilterOperators = (columnType: MUIDataType) => {
    const stringOperators =
        getGridDefaultColumnTypes()['string'].filterOperators ?? [];
    return (
        getGridDefaultColumnTypes()[columnType]?.filterOperators ??
        stringOperators
    );
};
