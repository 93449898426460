import {
    ContractIssue,
    ParagraphClause,
} from '@thought-river/negotiations-common';
import { IContractParagraph } from '../../../Paragraph/Paragraph-types';
import { mapClauseNumber } from '../../../App/App-mappers';

/**
 * Function returning linked or related clauses of an issue
 * to use as drafting options suggested locations
 */
export const getRelevantClauses = (
    issueClauses: ParagraphClause[],
    issue: ContractIssue,
    paragraphs: IContractParagraph[]
): ParagraphClause[] => {
    if (issueClauses.length) {
        return issueClauses;
    }

    const relatedParagraphIds = new Set(issue.relatedParagraphIds);
    const relatedParagraphs = paragraphs.filter((paragraph) =>
        relatedParagraphIds.has(paragraph.id)
    );
    return relatedParagraphs.map(mapClauseNumber);
};
