import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import styles from './ProcessingHistoryTable.module.scss';
import { GetProcessingHistoryResponse } from '@thought-river/negotiations-common/dist/api/contractManagement';
import { Check, Clear, HourglassEmpty } from '@mui/icons-material';
import dayjs from 'dayjs';

interface ProcessingHistoryTableProps {
    processingHistory: GetProcessingHistoryResponse;
}

const ProcessingHistoryTable = ({
    processingHistory,
}: ProcessingHistoryTableProps) => (
    <Table classes={{ root: styles.table }}>
        <TableHead>
            <TableRow>
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                    Outbound event name
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                    Parameter summary
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                    Event sent at
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                    Inbound event name
                </TableCell>
                <TableCell>Event received at</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                    Total time
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                    Processing time
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                    Event processing time
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>Success</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                    Result code
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                    Result info
                </TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {processingHistory?.map((event) => (
                <TableRow key={event.id}>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {event.outbound_event_label || '-'}
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {event.parameter_summary || '-'}
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {event.message_sent_at
                            ? dayjs(event.message_sent_at).format(
                                  'YYYY-MM-DD HH:mm:ss'
                              )
                            : '-'}
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {event.inbound_event_label || '-'}
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {event.message_received_at
                            ? dayjs(event.message_received_at).format(
                                  'YYYY-MM-DD HH:mm:ss'
                              )
                            : '-'}
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {event.total_time_taken || '-'}
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {event.processing_duration_seconds || '-'}
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {event.event_processing_time || '-'}
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {event.success === null ? (
                            <HourglassEmpty fontSize="small" />
                        ) : event.success ? (
                            <Check fontSize="small" />
                        ) : (
                            <Clear fontSize="small" />
                        )}
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {event.result_code || '-'}
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                        {event.result_info || '-'}
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
);

export default ProcessingHistoryTable;
