import { Component } from 'react';

import {
    AUTH0_LOGOUT_IFRAME_ID,
    IAuthProps,
    IAuthState,
    IUrlParams,
} from './Auth-types';
import AuthResource from '../../resources/AuthResource';
import { IApiToken } from 'types/thoughtriver';
import {
    alphabetizeStreams,
    fetchUsers,
    generateLogoutUrl,
    getAuthEnv,
    isAutoLogoutPage,
    isRedirectionPage,
    resolveCompanyFromEmail,
    tokenHasExpired,
} from './Auth-helpers';
import { analytics } from './Auth-analytics';
import { valuePersistedInLocalStorage } from '../App/App-helpers';
import flagsmith from 'flagsmith';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import { auth0Client, getShouldUseNoAccountCode } from '@modules/auth';

@analytics()
class Auth extends Component<IAuthProps, IAuthState> {
    constructor(props: IAuthProps) {
        super(props);

        this.state = {
            sessionInterval: null!, // Fixme: null checks
        };

        this.checkSessionTimeout = this.checkSessionTimeout.bind(this);
    }

    async componentDidMount() {
        const {
            accountCode,
            clearLoginDetails,
            tokenExpiry,
            setBookmark,
            setAuthDetails,
            setApiUrl,
            setLoggingOut,
            setLogout,
            userId,
            featureToggles,
            apiToken,
        } = this.props;

        const shouldUseNoAccountCode =
            getShouldUseNoAccountCode(featureToggles);

        const { apiUrl, authBaseUrl, clientId, audience, redirectUrl } =
            getAuthEnv();

        clearInterval(this.state.sessionInterval);

        setLogout(false);
        setLoggingOut(false);

        setApiUrl(apiUrl);
        setAuthDetails(authBaseUrl, clientId, audience, redirectUrl);

        const authRedirect = isRedirectionPage();
        const autoLogout = isAutoLogoutPage();

        if (autoLogout) {
            this.handleLogout();
        } else if (
            !authRedirect &&
            (!apiToken || tokenHasExpired(tokenExpiry))
        ) {
            const bookmark = this.getBookmarkFromUrl();
            clearLoginDetails();
            setBookmark(bookmark);

            await this.redirectToAuth0Login(shouldUseNoAccountCode);
        } else if (authRedirect) {
            await this.handleLoginSuccess();
        } else if (tokenExpiry) {
            setInterval(this.checkSessionTimeout, 5000);
        }

        if (userId && accountCode) {
            try {
                await flagsmith.identify(userId);
                await flagsmith.setTrait('account_code', accountCode);
            } catch {
                console.log('Error identifying Flagsmith user');
            }
        }
    }

    async componentDidUpdate(prevProps: IAuthProps) {
        const { logout } = this.props;

        if (!prevProps.logout && logout) {
            await this.signOutOfOtherApps();
            this.handleLogout();
        }
    }

    async checkSessionTimeout() {
        let tokenExpiry = this.props.tokenExpiry;

        if (tokenExpiry) {
            tokenExpiry = dayjs(tokenExpiry).subtract(10, 'seconds').format();
        }

        if (tokenHasExpired(tokenExpiry)) {
            this.handleLogout();
        }
    }

    convertTimestampToDate(timestamp: number) {
        return dayjs.unix(timestamp).format();
    }

    decodeApiToken(token: string): IApiToken {
        let decodedToken;

        try {
            decodedToken = jwtDecode(token);
        } catch (error) {
            //
        }

        return decodedToken as IApiToken;
    }

    generateAuthStateParam() {
        return dayjs().valueOf() + Math.floor(Math.random() * 10000).toString();
    }

    generateLoginUrl(
        baseUrl: string,
        redirectUrl: string,
        clientId: string,
        audience: string,
        appUrl: string,
        stateParam: string
    ) {
        return (
            `${baseUrl}/authorize` +
            '?response_type=code' +
            `&client_id=${clientId}` +
            `&redirect_uri=${encodeURIComponent(
                redirectUrl + '?redirectUrl=' + appUrl + '/authRedirect'
            )}` +
            '&scope=openid' +
            `&audience=${audience}` +
            `&state=${stateParam}`
        );
    }

    getBookmarkFromUrl() {
        return window.location.href.replace(window.location.origin, '');
    }

    getUrlParams(): IUrlParams {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        return {
            apiToken: urlParams.get('jwt')!, // Fixme: null checks
            changeUser: urlParams.get('changeUser')!, // Fixme: null checks
        };
    }

    async handleLoginSuccess() {
        const {
            bookmark,
            propertyDetailsLoginCount,
            setPropertyDetailsLoginCount,
            setApiToken,
            setAuthMetadata,
            setBookmark,
            setLoginSuccess,
            setTokenExpiry,
            setSelectedStream,
        } = this.props;

        const { apiToken } = this.getUrlParams();

        await setApiToken(apiToken);

        const decodedToken = this.decodeApiToken(apiToken);

        await setTokenExpiry(this.convertTimestampToDate(decodedToken.exp));

        const authDetails = await AuthResource.getAuthMetadata();

        setAuthMetadata(
            authDetails.roles,
            authDetails.streams.sort(alphabetizeStreams),
            authDetails.user_uuid,
            decodedToken.accountCode,
            resolveCompanyFromEmail(decodedToken.sub)
        );

        await setSelectedStream(authDetails.streams[0]);

        await fetchUsers();

        await setBookmark('');

        await setLoginSuccess(true);

        await valuePersistedInLocalStorage('auth', 'tokenExpiry');

        setPropertyDetailsLoginCount(
            propertyDetailsLoginCount ? propertyDetailsLoginCount + 1 : 1
        );

        window.location.assign(
            `${window.location.origin}${bookmark ? bookmark : bookmark}`
        );
    }

    private async handleLogout() {
        const { clearLoginDetails, authUrl, clientId } = this.props;

        await clearInterval(this.state.sessionInterval);
        await clearLoginDetails();
        window.location.assign(generateLogoutUrl(authUrl, clientId));
    }

    signOutOfOtherApps() {
        const { setLoggingOut } = this.props;

        const { legacyUIUrl } = window.__APP_CONFIG__;

        return new Promise(async (resolve) => {
            const iframe = document.getElementById(
                AUTH0_LOGOUT_IFRAME_ID
            ) as any;
            await setLoggingOut(true);
            resolve(true);
            if (iframe) {
                iframe.src = `${legacyUIUrl}/login/logout`;

                iframe.addEventListener('load', async () => {
                    await setLoggingOut(false);
                    resolve(true);
                });
            } else {
                await setLoggingOut(false);
            }
        });
    }

    async redirectToAuth0Login(shouldUseNoAccountCode?: boolean) {
        if (shouldUseNoAccountCode) {
            await auth0Client.loginWithRedirect();
            return;
        }

        const { authUrl, clientId, audience, redirectUrl } = this.props;
        const appUrl = window.location.origin;
        const stateParam = this.generateAuthStateParam();
        const loginUrl = this.generateLoginUrl(
            authUrl,
            redirectUrl,
            clientId,
            audience,
            appUrl,
            stateParam
        );
        window.location.assign(loginUrl);
    }

    render() {
        const { apiToken, children } = this.props;
        return apiToken ? (
            <div className="auth">
                {children}
                <iframe
                    className="auth0-logout-iframe"
                    id={AUTH0_LOGOUT_IFRAME_ID}
                    title="auth0 logout iframe"
                />
            </div>
        ) : null;
    }
}

export default Auth;
