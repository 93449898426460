import * as React from 'react';
import ParagraphSuggestion from './ParagraphSuggestion/ParagraphSuggestion';
import {
    IParagraphSuggestionsProps,
    IParagraphSuggestionsState,
} from './ParagraphSuggestions-types';
import './ParagraphSuggestions.scss';
import { motion } from 'framer-motion';
import IconButton from '@mui/material/IconButton';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { HighlightIndexType } from '../../../../Contract/Contract-types';
import { Alert } from '@thought-river/ui-components';
import { animationProps } from '../../IssuePaneTabs-helpers';
import {
    BannerClauseType,
    BannerType,
} from '../../../../HeatmapPane/HeatmapPane-types';
import {
    fetchGetSuggestionsForProperties,
    SuggestionsV01,
} from '@thought-river/negotiations-common/dist/api/playbookManager';

export default class ParagraphSuggestions extends React.Component<
    IParagraphSuggestionsProps,
    IParagraphSuggestionsState
> {
    constructor(props: IParagraphSuggestionsProps) {
        super(props);

        this.state = {
            editedSuggestionIndex: null,
            suggestions: [],
            isLoading: false,
        };
    }

    async componentDidMount() {
        const { streamId, issue } = this.props;

        if (!issue.lexiblePropertyId) {
            return;
        }

        this.setState({
            isLoading: true,
        });

        const response = (await fetchGetSuggestionsForProperties({
            pathParams: {
                dealTypeUuid: streamId,
            },
            body: {
                property_uuids: [issue.lexiblePropertyId],
            },
        })) as SuggestionsV01;

        this.setState({
            suggestions: response.map((s) => s.content),
            isLoading: false,
        });
    }

    componentDidUpdate = (prevProps: IParagraphSuggestionsProps) => {
        const { clauseNumbers, highlightedParagraphs, setHighlightIndex } =
            this.props;

        if (
            clauseNumbers.toString() !== prevProps.clauseNumbers.toString() &&
            !clauseNumbers.find(
                (clauseNumber) =>
                    clauseNumber.originUuid ===
                    highlightedParagraphs[0]?.originUuid
            )
        ) {
            // The currently selected clause number has been removed from the issue, so we need to unselect that paragraph
            setHighlightIndex(-1, HighlightIndexType.START);
            setHighlightIndex(-1, HighlightIndexType.END);
        }
    };

    private navigateToLocation(delta: 1 | -1) {
        const {
            highlightedParagraphs,
            clauseNumbers,
            setBannerSettings,
            issue,
        } = this.props;

        const currentSelection = highlightedParagraphs[0];
        let targetIndex = currentSelection
            ? clauseNumbers.findIndex(
                  (cn) => cn.index === currentSelection.index
              ) + delta
            : 0;
        targetIndex = targetIndex >= clauseNumbers.length ? 0 : targetIndex;
        targetIndex = targetIndex < 0 ? clauseNumbers.length - 1 : targetIndex;

        if (clauseNumbers[targetIndex]) {
            this.scrollToParagraph(clauseNumbers[targetIndex].id);
        }

        setBannerSettings({
            type: BannerType.INFO,
            clauseType: issue.paragraphIds.length
                ? BannerClauseType.PROPERTY_LINKED
                : BannerClauseType.PROPERTY_RELATED,
            currentItem: clauseNumbers[targetIndex].id,
            items: clauseNumbers.map((clause) => clause.id),
        });
    }

    private scrollToParagraph(paragraphId: string) {
        const { paragraphs, setSelectedParagraph, setHighlightIndex } =
            this.props;

        const paragraph = paragraphs.find((p) => p.id === paragraphId);

        if (paragraph) {
            setHighlightIndex(paragraph.index, HighlightIndexType.START);
            setHighlightIndex(paragraph.index, HighlightIndexType.END);
            setSelectedParagraph(paragraph);
        }
    }

    private renderHeader() {
        const { clauseNumbers, highlightedParagraphs, paragraphs } = this.props;

        const { suggestions } = this.state;
        const count = suggestions.length;

        const currentLocation = clauseNumbers.findIndex(
            (cn) => cn.originUuid === highlightedParagraphs[0]?.originUuid
        );

        return (
            <>
                <div>
                    {count} Drafting option{count > 1 ? 's' : ''}
                </div>
                <div className="suggestions-locations">
                    {currentLocation !== -1 ? currentLocation + 1 : 0} of{' '}
                    {clauseNumbers.length} suggested locations
                    <IconButton
                        className="location-button"
                        disabled={!paragraphs.length}
                        onClick={() => this.navigateToLocation(-1)}
                    >
                        <KeyboardArrowUp />
                    </IconButton>
                    <IconButton
                        className="location-button"
                        disabled={!paragraphs.length}
                        onClick={() => this.navigateToLocation(1)}
                    >
                        <KeyboardArrowDown />
                    </IconButton>
                </div>
            </>
        );
    }

    render() {
        const {
            issue,
            selectedPropertySuggestionMap,
            setSelectedPropertySuggestion,
        } = this.props;

        const { editedSuggestionIndex, suggestions, isLoading } = this.state;

        const issuePropertyId = issue.lexiblePropertyId ?? '';

        return (
            <div className="paragraph-suggestions">
                {!isLoading ? (
                    suggestions.length ? (
                        <>
                            <motion.div
                                className="paragraph-suggestions-header"
                                {...animationProps(0.1)}
                            >
                                {this.renderHeader()}
                            </motion.div>
                            <div className="paragraph-suggestions-list">
                                {suggestions.map((suggestion, index) => (
                                    <motion.div
                                        key={`suggestion-${index}`}
                                        {...animationProps(0.2 + index / 10)}
                                    >
                                        <ParagraphSuggestion
                                            editing={
                                                editedSuggestionIndex === index
                                            }
                                            isSelected={
                                                selectedPropertySuggestionMap[
                                                    issuePropertyId
                                                ]
                                                    ? selectedPropertySuggestionMap[
                                                          issuePropertyId
                                                      ] === suggestion
                                                    : index === 0
                                            }
                                            onClick={(
                                                suggestionIndex: number
                                            ) => {
                                                this.setState({
                                                    editedSuggestionIndex:
                                                        suggestionIndex,
                                                });
                                            }}
                                            onSuggestionSelected={() => {
                                                setSelectedPropertySuggestion(
                                                    issuePropertyId,
                                                    suggestion
                                                );
                                            }}
                                            suggestion={suggestion}
                                            suggestionIndex={index}
                                        />
                                    </motion.div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <motion.div
                            className="missing-suggestions-alert"
                            {...animationProps(0.1)}
                        >
                            <Alert severity="info">
                                This property has not been configured with
                                drafting options. If you think that it should
                                be, please contact your administrator.
                            </Alert>
                        </motion.div>
                    )
                ) : (
                    <div className="loading-wrapper">
                        <span className="loader" />
                    </div>
                )}
            </div>
        );
    }
}
