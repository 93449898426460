import styles from './ReportsPage.module.scss';
import { Route, Switch } from 'react-router-dom';
import {
    CreateReportPage,
    EditReportPage,
    HomePage,
    LexibleProvider,
    ViewReportPage,
    Sidebar,
} from '@modules/reports/portfolioReports';

const ReportsPage = () => (
    <LexibleProvider>
        <div className={styles.reportsPage}>
            <Sidebar />
            <Route path="/reports" exact render={() => <HomePage />} />
            <Switch>
                <Route
                    path="/reports/create"
                    render={() => <CreateReportPage />}
                />
                <Route
                    path="/reports/:id/edit"
                    render={() => <EditReportPage />}
                />
                <Route path="/reports/:id" render={() => <ViewReportPage />} />
            </Switch>
        </div>
    </LexibleProvider>
);

export default ReportsPage;
