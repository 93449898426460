import { useEffect, useState } from 'react';
import semver from 'semver';

import { IDocumentViewerPaneProps } from './DocumentViewerPane.types';
import DocumentViewer from '../../modules/common/components/DocumentViewer/DocumentViewer';
import HeatmapPane from '../HeatmapPane/HeatmapPane-container';
import { ErrorBoundary } from '@modules/common/components/ErrorBoundary';

const MIN_NEW_PARSER_VERSION = '1.15.0';

const DocumentViewerPane = ({
    paragraphs,
    onReprocess,
    documentHTML,
}: IDocumentViewerPaneProps) => {
    const [isNewParser, setIsNewParser] = useState(false);

    useEffect(() => {
        if (!documentHTML) {
            return;
        }

        const domParser = new DOMParser();
        const parsedDom = domParser.parseFromString(documentHTML, 'text/html');

        const docxParserVersion = parsedDom.body.dataset.parserVersion;
        let isNewParser = false;
        try {
            isNewParser =
                !!docxParserVersion &&
                semver.gte(docxParserVersion, MIN_NEW_PARSER_VERSION);
        } catch {
            isNewParser = false;
        }
        setIsNewParser(isNewParser);

        console.log(`Using ${isNewParser ? 'new' : 'old'} parser`);
    }, [documentHTML]);

    return (
        <ErrorBoundary>
            <div>
                {isNewParser && documentHTML ? (
                    <DocumentViewer
                        paragraphs={paragraphs}
                        documentHTML={documentHTML}
                        onReprocess={onReprocess}
                    />
                ) : (
                    <HeatmapPane onReprocess={onReprocess} />
                )}
            </div>
        </ErrorBoundary>
    );
};

export default DocumentViewerPane;
