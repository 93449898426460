import {
    Icon,
    IconButton,
    OverflowMenu,
    OverflowMenuItem,
} from '@thought-river/ui-components';
import { ReportOverflowMenuProps } from './ReportOverflowMenu.types';
import { Delete } from '@mui/icons-material';
import { useState } from 'react';
import { ReducedReportDefinitionOut } from '@thought-river/negotiations-common/dist/api/playbookManager';
import { DeleteReportDialog } from './DeleteReportDialog';

const ReportOverflowMenu = ({
    classes,
    reportDefinition,
}: ReportOverflowMenuProps) => {
    const [activeReportDefinition, setActiveReportDefinition] =
        useState<ReducedReportDefinitionOut | null>(null);

    const overflowMenuItems: OverflowMenuItem[][] = [
        [
            {
                icon: <Delete />,
                label: 'Delete',
                onClick: () => {
                    setActiveReportDefinition(reportDefinition);
                },
                type: 'destructive',
            },
        ],
    ];
    return (
        <>
            <OverflowMenu
                className={classes?.root}
                button={
                    <IconButton className={classes?.button} size="small">
                        <Icon type="more-vert" />
                    </IconButton>
                }
                menuItems={overflowMenuItems}
            />
            {activeReportDefinition && (
                <DeleteReportDialog
                    reportDefinition={activeReportDefinition}
                    onClose={() => setActiveReportDefinition(null)}
                />
            )}
        </>
    );
};

export default ReportOverflowMenu;
