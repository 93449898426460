import {
    EVENT_ACTION_SUCCESSFUL_PROCESSING,
    EVENT_ACTION_UPLOAD_NEGOTIATION,
    EVENT_ACTION_UPLOAD_REVISION,
    EVENT_ACTION_VIEW_CONTRACT,
} from './Analytics-types';

export interface IMixpanelHelper {
    incrementProperty: (property: string) => void;
    registerFirstProperty: (property: string, customValue?: string) => void;
    registerLastProperty: (property: string, customValue?: string) => void;
}

export const profileEvents = [
    EVENT_ACTION_UPLOAD_NEGOTIATION,
    EVENT_ACTION_UPLOAD_REVISION,
    EVENT_ACTION_SUCCESSFUL_PROCESSING,
    EVENT_ACTION_VIEW_CONTRACT,
];
